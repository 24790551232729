/**
 * @file   src\store\slices\dashboardSlice.ts
 * @brief  This file is responsible for creating dashboard based slices to call actions and state management.
 * @date   August, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { createSlice } from '@reduxjs/toolkit';
import { IDashboard } from '../../interfaces/dashboardInterface';
import {
  getOrdersList,
  getTransactionsList,
  getNewRestaurantsList,
  getNewVendorsList,
  getOrdersGraphData,
  getRestaurantVendorGraphData,
  getTransactionsGraphData,
  getOrderDetails,
  getInvoiceDetails,
  generateInvoicePdf,
  generateOrderPdf,
  searchRestaurants,
  searchVendors,
  getChartDateLimit,
} from '../actions/dashboardActions';
import { useIntlActionMessages } from '../../utils/helper';

// Initial state
const dashboardDefaultState: IDashboard = {
  ordersList: [],
  ordersListTotalCount: 0,
  orderListTotalDetails: [],
  ordersListErrorCode: 0,
  ordersListLoading: false,
  ordersListSuccess: false,
  ordersListErrorMessage: '',
  transactionsList: [],
  transactionsListErrorCode: 0,
  transactionsListErrorMessage: '',
  transactionsListSuccess: false,
  transactionsListLoading: false,
  transactionListTotalCount: 0,
  vendorsList: [],
  vendorsListErrorCode: 0,
  vendorsListErrorMessage: '',
  vendorsListLoading: false,
  vendorsListSuccess: false,
  vendorListCount: 0,
  restaurantList: [],
  restaurantListErrorCode: 0,
  restaurantListErrorMessage: '',
  restaurantListLoading: false,
  restaurantListSuccess: false,
  restaurantListTotalCount: 0,
  orderGraphLoading: false,
  orderGraphData: [],
  orderGraphErrorCode: 0,
  orderGraphErrorMessage: '',
  orderGraphSuccess: false,
  transactionGraphData: [],
  transactionGraphErrorCode: 0,
  transactionGraphErrorMessage: '',
  transactionGraphLoading: false,
  transactionGraphSuccess: false,
  restaurantvendorGraphData: [],
  restaurantvendorGraphErrorCode: 0,
  restaurantvendorGraphErrorMessage: '',
  restaurantvendorGraphLoading: false,
  restaurantvendorGraphSuccess: false,
  orderDetails: {
    orderDetails: {},
    restaurantDetails: {},
    itemListDetails: [],
  },
  orderDetailsErrorCode: 0,
  orderDetailsErrorMessage: '',
  orderDetailsLoading: false,
  orderDetailsSuccess: false,
  invoiceDetails: {
    invoice: {},
    vendor: {},
    restaurant: {},
    items: [],
  },
  invoiceDetailsErrorCode: 0,
  invoiceDetailsErrorMessage: '',
  invoiceDetailsLoading: false,
  invoiceDetailsSuccess: false,
  orderPdfLoading: false,
  orderPdfSuccess: false,
  orderPdfErrorCode: 0,
  orderPdfErrorMessage: '',
  invoicePdfData: {
    invoiceGenerated: {
      fileName: '',
      url: '',
    },
  },
  orderPdfData: {
    FileDetails: {
      fileName: '',
      url: '',
    },
  },
  invoicePdfErrorCode: 0,
  invoicePdfErrorMessage: '',
  invoicePdfLoading: false,
  invoicePdfSuccess: false,
  restaurantSearchLoading: false,
  restaurantSearchSuccess: false,
  restaurantSearchData: [],
  restaurantSearchErrorCode: 0,
  restaurantSearchErrorMessage: '',
  vendorSearchLoading: false,
  vendorSearchSuccess: false,
  vendorSearchData: [],
  vendorSearchErrorCode: 0,
  vendorSearchErrorMessage: '',
  vendorSearchList: [],
  restaurantSearchList: [],
  chartDateLimitLoading: false,
  chartDateLimitSuccess: false,
  chartDateLimitErrorCode: 0,
  chartDateLimitErrorMessage: null,
  dateLimits: null,
};

// Dashboard slice
const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: dashboardDefaultState,
  reducers: {
    resetDashboard: (state) => {
      state.ordersList = [];
      state.ordersListErrorCode = 0;
      state.ordersListLoading = false;
      state.ordersListSuccess = false;
      state.ordersListErrorMessage = '';
      state.chartDateLimitLoading = false;
      state.chartDateLimitSuccess = false;
      state.chartDateLimitErrorCode = 0;
      state.chartDateLimitErrorMessage = null;
    },
    resetInvoicePdf: (state) => {
      state.invoicePdfData = {
        invoiceGenerated: {
          url: '',
          fileName: '',
        },
      };
      state.invoicePdfErrorCode = 0;
      state.invoicePdfErrorMessage = '';
      state.invoicePdfLoading = false;
      state.invoicePdfSuccess = false;
    },
    resetOrderPdf: (state) => {
      state.orderPdfData = {
        FileDetails: {
          url: '',
          fileName: '',
        },
      };
      state.orderPdfErrorCode = 0;
      state.orderPdfErrorMessage = '';
      state.orderPdfLoading = false;
      state.orderPdfSuccess = false;
    },
  },
  extraReducers(builder) {
    // get orders list api starts
    builder.addCase(getOrdersList.pending, (state) => {
      state.ordersListLoading = true;
      state.ordersListSuccess = false;
      state.ordersListErrorCode = 0;
      state.ordersListErrorMessage = '';
    });
    // get orders list api success
    builder.addCase(getOrdersList.fulfilled, (state, action) => {
      const error = action.payload?.error || 0;
      const data = action.payload?.data || {};
      state.ordersListLoading = false;
      state.ordersListSuccess = true;
      state.ordersList = data?.orders || [];
      state.ordersListErrorCode = error;
      state.ordersListTotalCount = data?.totalRows;
      state.orderListTotalDetails = data?.totalDetails;
      state.ordersListErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // get orders list api failure
    builder.addCase(getOrdersList.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.ordersListLoading = false;
      state.ordersListSuccess = false;
      state.ordersListErrorCode = error;
      state.ordersListErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // get transactions list api starts
    builder.addCase(getTransactionsList.pending, (state) => {
      state.transactionsListLoading = true;
      state.transactionsListSuccess = false;
      state.transactionsListErrorCode = 0;
      state.transactionsListErrorMessage = '';
    });
    // get transactions list api success
    builder.addCase(getTransactionsList.fulfilled, (state, action) => {
      const error = action.payload?.error || 0;
      const data = action.payload?.data || {};
      state.transactionsListLoading = false;
      state.transactionsListSuccess = true;
      state.transactionsList = data?.invoices || [];
      state.transactionListTotalCount = data?.totalRows;
      state.transactionsListErrorCode = error;
      state.transactionsListErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // get transactions list api failure
    builder.addCase(getTransactionsList.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.transactionsListLoading = false;
      state.transactionsListSuccess = false;
      state.transactionsListErrorCode = error;
      state.transactionsListErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // get restaurants list api starts
    builder.addCase(getNewRestaurantsList.pending, (state) => {
      state.restaurantListLoading = true;
      state.restaurantListSuccess = false;
      state.restaurantListErrorCode = 0;
      state.restaurantListErrorMessage = '';
    });
    // get restaurants list api success
    builder.addCase(getNewRestaurantsList.fulfilled, (state, { meta, payload }) => {
      const error = payload?.error || 0;
      const data = payload?.data || {};
      state.restaurantListLoading = false;
      state.restaurantListSuccess = true;
      if (meta.arg.searchText.length > 0 && meta.arg.restaurantId === 0) {
        state.restaurantSearchList = data?.restaurant || [];
      } else if (meta.arg.searchText.length === 0 || meta.arg.restaurantId !== 0) {
        state.restaurantList = data?.restaurant || [];
        state.restaurantListTotalCount = data?.totalCount;
      }
      state.restaurantListErrorCode = error;
      state.restaurantListErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // get restaurants list api failure
    builder.addCase(getNewRestaurantsList.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.restaurantListLoading = false;
      state.restaurantListSuccess = false;
      state.restaurantListErrorCode = error;
      state.restaurantListErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // get vendors list api starts
    builder.addCase(getNewVendorsList.pending, (state) => {
      state.vendorsListLoading = true;
      state.vendorsListSuccess = false;
      state.vendorsListErrorCode = 0;
      state.vendorsListErrorMessage = '';
    });
    // get vendors list api success
    builder.addCase(getNewVendorsList.fulfilled, (state, { meta, payload }) => {
      const error = payload?.error || 0;
      const data = payload?.data || {};
      state.vendorsListLoading = false;
      state.vendorsListSuccess = true;
      if (meta.arg.searchText.length > 0 && meta.arg.vendorId === 0) {
        state.vendorSearchList = data?.vendors || [];
      } else if (meta.arg.searchText.length === 0 || meta.arg.vendorId !== 0) {
        state.vendorsList = data?.vendors || [];
        state.vendorListCount = data?.totalCount;
      }
      state.vendorsListErrorCode = error;
      state.vendorsListErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // get vendors list api failure
    builder.addCase(getNewVendorsList.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.vendorsListLoading = false;
      state.vendorsListSuccess = false;
      state.vendorsListErrorCode = error;
      state.vendorsListErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // get order graph data api starts
    builder.addCase(getOrdersGraphData.pending, (state) => {
      state.orderGraphLoading = true;
      state.orderGraphSuccess = false;
      state.orderGraphErrorCode = 0;
      state.orderGraphErrorMessage = '';
    });
    // get order graph data api success
    builder.addCase(getOrdersGraphData.fulfilled, (state, action) => {
      const error = action.payload?.error || 0;
      const data = action.payload?.data || {};
      state.orderGraphLoading = false;
      state.orderGraphSuccess = true;
      state.orderGraphData = data?.orderDetails || [];
      state.orderGraphErrorCode = error;
      state.orderGraphErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // get order graph data api failure
    builder.addCase(getOrdersGraphData.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.orderGraphLoading = false;
      state.orderGraphSuccess = false;
      state.orderGraphErrorCode = error;
      state.orderGraphErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // get transaction graph data api starts
    builder.addCase(getTransactionsGraphData.pending, (state) => {
      state.transactionGraphLoading = true;
      state.transactionGraphSuccess = false;
      state.transactionsListErrorCode = 0;
      state.transactionGraphErrorMessage = '';
    });
    // get transaction graph data api success
    builder.addCase(getTransactionsGraphData.fulfilled, (state, action) => {
      const error = action.payload?.error || 0;
      const data = action.payload?.data || {};
      state.transactionGraphLoading = false;
      state.transactionGraphSuccess = true;
      state.transactionGraphData = data?.invoice || [];
      state.transactionGraphErrorCode = error;
      state.transactionGraphErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // get transaction graph data api failure
    builder.addCase(getTransactionsGraphData.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.transactionGraphLoading = false;
      state.transactionGraphSuccess = false;
      state.transactionGraphErrorCode = error;
      state.transactionGraphErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // get restaurant vendor graph data api starts
    builder.addCase(getRestaurantVendorGraphData.pending, (state) => {
      state.restaurantvendorGraphLoading = true;
      state.restaurantvendorGraphSuccess = false;
      state.restaurantvendorGraphErrorCode = 0;
      state.restaurantvendorGraphErrorMessage = '';
    });
    // get restaurant vendor graph data api success
    builder.addCase(getRestaurantVendorGraphData.fulfilled, (state, action) => {
      const error = action.payload?.error || 0;
      const data = action.payload?.data || {};
      state.restaurantvendorGraphLoading = false;
      state.restaurantvendorGraphSuccess = true;
      state.restaurantvendorGraphData = data?.user || [];
      state.restaurantvendorGraphErrorCode = error;
      state.restaurantvendorGraphErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // get restaurant vendor graph data api failure
    builder.addCase(getRestaurantVendorGraphData.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.restaurantvendorGraphLoading = false;
      state.restaurantvendorGraphSuccess = false;
      state.restaurantvendorGraphErrorCode = error;
      state.restaurantvendorGraphErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // get order details api starts
    builder.addCase(getOrderDetails.pending, (state) => {
      state.orderDetailsLoading = true;
      state.orderDetailsSuccess = false;
      state.orderDetailsErrorCode = 0;
      state.orderDetailsErrorMessage = '';
    });
    // get order details api success
    builder.addCase(getOrderDetails.fulfilled, (state, action) => {
      const error = action.payload?.error || 0;
      const data = action.payload?.data || {};
      state.orderDetailsLoading = false;
      state.orderDetailsSuccess = true;
      state.orderDetails = data;
      state.orderDetailsErrorCode = error;
      state.orderDetailsErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // get order details api failure
    builder.addCase(getOrderDetails.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.orderDetailsLoading = false;
      state.orderDetailsSuccess = false;
      state.orderDetailsErrorCode = error;
      state.orderDetailsErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // get invoice details api starts
    builder.addCase(getInvoiceDetails.pending, (state) => {
      state.invoiceDetailsLoading = true;
      state.invoiceDetailsSuccess = false;
      state.invoiceDetailsErrorCode = 0;
      state.invoiceDetailsErrorMessage = '';
    });
    // get invoice details api success
    builder.addCase(getInvoiceDetails.fulfilled, (state, action) => {
      const error = action.payload?.error || 0;
      const data = action.payload?.data || {};
      state.invoiceDetailsLoading = false;
      state.invoiceDetailsSuccess = true;
      state.invoiceDetails = data;
      state.invoiceDetailsErrorCode = error;
      state.invoiceDetailsErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // get invoice details api failure
    builder.addCase(getInvoiceDetails.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.invoiceDetailsLoading = false;
      state.invoiceDetailsSuccess = false;
      state.invoiceDetailsErrorCode = error;
      state.invoiceDetailsErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // generate order pdf api starts
    builder.addCase(generateOrderPdf.pending, (state) => {
      state.orderPdfLoading = true;
      state.orderPdfSuccess = false;
      state.orderPdfErrorCode = 0;
      state.orderPdfErrorMessage = '';
    });
    // generate order pdf api success
    builder.addCase(generateOrderPdf.fulfilled, (state, action) => {
      const error = action.payload?.error || 0;
      const data = action.payload?.data || {};
      state.orderPdfLoading = false;
      state.orderPdfSuccess = true;
      state.orderPdfData = data;
      state.orderPdfErrorCode = error;
      state.orderPdfErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // generate order pdf api failure
    builder.addCase(generateOrderPdf.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.orderPdfLoading = false;
      state.orderPdfSuccess = false;
      state.orderPdfErrorCode = error;
      state.orderPdfErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // generate invoice pdf api starts
    builder.addCase(generateInvoicePdf.pending, (state) => {
      state.invoicePdfLoading = true;
      state.invoicePdfSuccess = false;
      state.invoicePdfErrorCode = 0;
      state.invoicePdfErrorMessage = '';
    });
    // generate invoice pdf api success
    builder.addCase(generateInvoicePdf.fulfilled, (state, action) => {
      const error = action.payload?.error || 0;
      const data = action.payload?.data || {};
      state.invoicePdfLoading = false;
      state.invoicePdfSuccess = true;
      state.invoicePdfData = data;
      state.invoicePdfErrorCode = error;
      state.invoicePdfErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // generate invoice pdf api failure
    builder.addCase(generateInvoicePdf.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.invoicePdfLoading = false;
      state.invoicePdfSuccess = false;
      state.invoicePdfErrorCode = error;
      state.invoicePdfErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // restaurant search  api starts
    builder.addCase(searchRestaurants.pending, (state) => {
      state.restaurantSearchLoading = true;
      state.restaurantSearchSuccess = false;
      state.restaurantSearchErrorCode = 0;
      state.restaurantSearchErrorMessage = '';
    });
    // restaurant search api success
    builder.addCase(searchRestaurants.fulfilled, (state, action) => {
      const error = action.payload?.error || 0;
      const data = action.payload?.data || {};
      state.restaurantSearchLoading = false;
      state.restaurantSearchSuccess = true;
      state.restaurantSearchData = data?.restaurants || [];
      state.restaurantSearchErrorCode = error;
      state.restaurantSearchErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // restaurant search api failure
    builder.addCase(searchRestaurants.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.restaurantSearchLoading = false;
      state.restaurantSearchSuccess = false;
      state.restaurantSearchErrorCode = error;
      state.restaurantSearchErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // vendor search  api starts
    builder.addCase(searchVendors.pending, (state) => {
      state.vendorSearchLoading = true;
      state.vendorSearchSuccess = false;
      state.vendorSearchErrorCode = 0;
      state.vendorSearchErrorMessage = '';
    });
    // vendor search api success
    builder.addCase(searchVendors.fulfilled, (state, action) => {
      const error = action.payload?.error || 0;
      const data = action.payload?.data || {};
      state.vendorSearchLoading = false;
      state.vendorSearchSuccess = true;
      state.vendorSearchData = data?.vendors || [];
      state.vendorSearchErrorCode = error;
      state.vendorSearchErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // vendor search api failure
    builder.addCase(searchVendors.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.vendorSearchLoading = false;
      state.vendorSearchSuccess = false;
      state.vendorSearchErrorCode = error;
      state.vendorSearchErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Get chart limit api starts.
    builder.addCase(getChartDateLimit.pending, (state) => {
      state.chartDateLimitLoading = true;
      state.chartDateLimitSuccess = false;
      state.chartDateLimitErrorCode = 0;
      state.chartDateLimitErrorMessage = '';
    });
    // Get chart limit api success.
    builder.addCase(getChartDateLimit.fulfilled, (state, action) => {
      const error = action.payload?.error || 0;
      const data = action.payload?.data || null;
      state.chartDateLimitLoading = false;
      state.chartDateLimitSuccess = true;
      state.dateLimits = data?.chartDetails || null;
      state.chartDateLimitErrorCode = error;
      state.chartDateLimitErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // Get chart limit api failure.
    builder.addCase(getChartDateLimit.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.chartDateLimitLoading = false;
      state.chartDateLimitSuccess = false;
      state.chartDateLimitErrorCode = error;
      state.chartDateLimitErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
  },
});

// Export actions
export const { resetDashboard, resetInvoicePdf, resetOrderPdf } = dashboardSlice.actions;
// Export reducer.
export default dashboardSlice.reducer;
