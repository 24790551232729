/**
 * @file   src\components\Footer.tsx
 * @brief  Footer component.
 * @date   May, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import '../styles/Footer.scss';
import { Navbar } from '../utils/thirdpartyComponents';
import LogoFooter from '../assets/LogoFooter.svg';

const Footer = () => {
  return (
    <div className="footer-outer">
      <div className="footer mad-footer">
        <div className="content-nav d-md-flex  justify-content-between">
          <div className="footer-left d-flex align-items-center">
            <Navbar.Brand href="#home">
              <img src={LogoFooter} alt="MADCHEF" />
            </Navbar.Brand>
            {/* <Link to="#" onClick={() => window.alert('Not implemented')}>
              <FormattedMessage id="Footer.Privacy" />
            </Link> */}
          </div>
          <div className="footer-right d-flex align-items-center" />
        </div>
      </div>
    </div>
  );
};
export default Footer;
