/**
 * @file   src\features\RecoverPassword.tsx
 * @brief  Forgot Password page.
 * @date   May, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import ReCAPTCHA from 'react-google-recaptcha';
import { useEffect, useState, Container, Button, Row, Col, Modal, Link, FormattedMessage, useRef } from '../utils/thirdpartyComponents';
import '../styles/Onboard.scss';
import Logo from '../assets/Logo.svg';
import MailIcon from '../assets/icons/Mail';
import InputIcon from '../components/InputIcon';
import { useIntlMessages, setLocalStorage } from '../utils/helper';
import { validateForm } from '../utils/formValidation';
import { FORGOTPASSWORD_SCHEMA } from '../validations/userSchema';
import { validateByEmail } from '../store/actions/authAction';
import { resetAuthState } from '../store/slices/authSlice';
import { useAppDispatch, useAppSelector } from '../hooks';
import { RootState } from '../store';
import { MessageToaster } from '../utils/toastUtil';

// Toast object creation.
const toast = new MessageToaster();

const RecoverPassword = () => {
  // Declare action dispatch.
  const dispatch = useAppDispatch();
  const { forgotMailSentSuccess, forgptPasswordErrorCode, forgotPasswordErrorMessage } = useAppSelector((state: RootState) => state.auth);

  // Initialize component stat variables.
  const [email, setEmail] = useState<string>('');
  const [errorFields, setErrorFields] = useState<any>({});
  const [deActivated, deActivatedPop] = useState(false);
  const reCaptchaRef = useRef<ReCAPTCHA>(null);

  // Forgot password success state change.
  useEffect(() => {
    if (forgotMailSentSuccess && forgptPasswordErrorCode === 0) {
      setEmail('');
      deActivatedPop(true);
    }
    if (forgptPasswordErrorCode !== 0 && forgotPasswordErrorMessage) {
      toast.toastError(forgotPasswordErrorMessage);
    }
    dispatch(resetAuthState());
  }, [forgotMailSentSuccess, forgptPasswordErrorCode]);

  // Email input field change event.
  const onEmailChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEmail(value);
  };

  // Forgot password form submit event.
  const onSubmit = async () => {
    const formData = {
      email,
    };
    const errorresult = await validateForm(formData, FORGOTPASSWORD_SCHEMA, errorFields);
    setErrorFields(errorresult);
    if (Object.keys(errorresult).length === 0) {
      let recaptchaToken: any = '';
      if (reCaptchaRef.current) recaptchaToken = await reCaptchaRef.current.executeAsync();
      setLocalStorage('MCSA_UDT', JSON.stringify({ capToken: recaptchaToken }));
      dispatch(validateByEmail(formData));
    }
  };

  return (
    <Container fluid>
      <Row className="onboard-wrap">
        <Col lg="6" className="d-flex justify-content-center align-items-center logo-wrap">
          <img src={Logo} alt="MADCHEF" />
          <div className="pattern" />
        </Col>
        <Col lg="6" className="d-flex justify-content-center align-items-center form-container">
          <div className="form-wrap">
            <h1 className="text-center">
              <FormattedMessage id="Recover.Header" />
            </h1>
            <form name="recoverpassword">
              <InputIcon
                id="email"
                name="email"
                type="email"
                value={email}
                maxLength={200}
                placeholder={useIntlMessages('Recover.EnterEmail.Placeholder')}
                icon={<MailIcon />}
                errorMessage={errorFields?.email}
                onChange={onEmailChange}
              />
              <div className="login-actions d-flex justify-content-between">
                <Link to="/">
                  <FormattedMessage id="Recover.LogInstead" />
                </Link>
              </div>
              <Button variant="primary" className="w-100" onClick={onSubmit}>
                <FormattedMessage id="Button.Continue" />
              </Button>
              <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY || ''} size="invisible" ref={reCaptchaRef} />
            </form>
          </div>
        </Col>
      </Row>
      {/*  Recover mail confirmation popup */}
      <Modal show={deActivated} onHide={() => deActivatedPop(false)} centered className="mx-w-450">
        <Modal.Body>
          <p>
            <FormattedMessage id="Recover.EmailSent.Popup.Text" />
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              deActivatedPop(false);
              dispatch(resetAuthState());
            }}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default RecoverPassword;
