/**
 * @file   src\features\dashboard\Orders.tsx
 * @brief  Dashboard orders list page
 * @date   August, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { FormattedMessage, Link, Col, Row, Table, useEffect, useState, moment, DatePicker, Badge } from '../../utils/thirdpartyComponents';
import { formatNumberWithCommas, stringFormat, useIntlActionMessages, useIntlMessages } from '../../utils/helper';
import Select from '../../components/Select';
import Pagination from '../../components/Paginate';
import { getOrdersList } from '../../store/actions/dashboardActions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { OrderRequestParams, OrderRow } from '../../interfaces/dashboardInterface';
import { RootState } from '../../store';
import {
  PAGE_SIZE,
  DATE_FORMAT2,
  TIME_FORMAT,
  DATE_FORMAT,
  TEXT_WEEK,
  TEXT_MONTH,
  TIME_STARTS_FORMAT,
  TIME_END_FORMAT,
  TEXT_TODAY,
  TEXT_CUSTOM,
  TEXT_START,
  TEXT_END,
} from '../../utils/constants';
import RestaurantSearch from './RestaurantSearch';
import VendorSearch from './VendorSearch';
import { OrderStatus, DateFilterOptions, VendorRestaurantSearchType, OrderSortOptions, SortOrderType } from '../../utils/enums';
import { MessageToaster } from '../../utils/toastUtil';
import GifLoader from '../../components/GifLoader';

// Date select box options
const DateOptions = [
  { label: useIntlActionMessages('Reports.Filter.Today.Label'), value: DateFilterOptions.DAILY },
  { label: useIntlActionMessages('Reports.Filter.ThisWeek.Label'), value: DateFilterOptions.THIS_WEEK },
  { label: useIntlActionMessages('Reports.Filter.ThisMonth.Label'), value: DateFilterOptions.THIS_MONTH },
  { label: useIntlActionMessages('Reports.Filter.Custom.Label'), value: DateFilterOptions.CUSTOM },
];
// Toast object creation.
const toast = new MessageToaster();

// Get start and end date
const getDates = (dateOption: string) => {
  let fromDateInitial = '';
  let toDateInitial = '';
  if (dateOption === DateFilterOptions.DAILY) {
    fromDateInitial = moment(new Date(new Date())).format(DATE_FORMAT2);
    toDateInitial = moment(new Date()).format(DATE_FORMAT2);
  } else if (dateOption === DateFilterOptions.THIS_WEEK) {
    fromDateInitial = moment(new Date(moment().startOf(TEXT_WEEK).toDate())).format(DATE_FORMAT2);
    toDateInitial = moment(new Date()).format(DATE_FORMAT2);
  } else if (dateOption === DateFilterOptions.THIS_MONTH) {
    fromDateInitial = moment(new Date(moment().startOf(TEXT_MONTH).toDate())).format(DATE_FORMAT2);
    toDateInitial = moment(new Date()).format(DATE_FORMAT2);
  }

  const dateStringFrom = fromDateInitial.replace(/-/g, '/').replace('T', ' ');
  const dateStringTo = toDateInitial.replace(/-/g, '/').replace('T', ' ');
  const fromDateUTC = new Date(`${dateStringFrom} ${TIME_STARTS_FORMAT}`).toISOString();
  const toDateUTC = new Date(`${dateStringTo} ${TIME_END_FORMAT}`).toISOString();
  return [fromDateUTC, toDateUTC];
};

// Page size value for pagination
const pageItemCount: number = process.env.REACT_APP_LIST_PAGE_SIZE !== '' ? Number(process.env.REACT_APP_LIST_PAGE_SIZE) : PAGE_SIZE;

// Component that displays list of orders
const Orders = () => {
  // Create action dispatch
  const dispatch = useAppDispatch();
  // Access redux state variables
  const { ordersList, orderListTotalDetails, ordersListTotalCount, ordersListErrorMessage, ordersListErrorCode, ordersListLoading } = useAppSelector(
    (state: RootState) => state.dashboard,
  );

  // Component state variables
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [dateOption, setDateOption] = useState<string>(TEXT_TODAY);
  const [dateFilterValue, setDateFilterValue] = useState<any>(DateOptions[0]);
  const [startDate, endDate] = dateOption !== TEXT_CUSTOM ? getDates(dateOption) : ['', ''];
  const [vendorSelectedId, setVendorSelectedId] = useState<number>(0);
  const [restSelectedId, setRestaurantSelectedId] = useState<number>(0);
  const [startDateUtc, setStartDateUtc] = useState<string>('');
  const [endDateUtc, setEndDateUtc] = useState<string>('');
  const [startDateSelected, setStartDateSelected] = useState<string>('');
  const [endDateSelected, setEndDateSelected] = useState<string>('');

  // Call to fetch orders list
  const fetchOrders = () => {
    const params: OrderRequestParams = {
      searchVendor: '',
      searchRestaurant: '',
      searchSimilar: '',
      vendorId: vendorSelectedId,
      restaurantId: restSelectedId,
      fromDate: dateOption === TEXT_CUSTOM ? startDateUtc : startDate,
      toDate: dateOption === TEXT_CUSTOM ? endDateUtc : endDate,
      pageSize: pageItemCount,
      pageIndex: currentPage,
      sortField: OrderSortOptions.PLACEDON,
      sortOrder: SortOrderType.DESC,
    };
    dispatch(getOrdersList(params));
  };
  // fetch orders when the given dependencies change
  useEffect(() => {
    fetchOrders();
  }, [currentPage, dateOption, vendorSelectedId, restSelectedId, startDateUtc, endDateUtc]);

  // Show error messages upon api failure
  useEffect(() => {
    if (ordersListErrorCode > 0 && ordersListErrorMessage) {
      toast.toastError(ordersListErrorMessage);
    }
  }, [ordersListErrorCode, ordersListErrorMessage]);
  // handle select box change
  const handleDateSelectBox = (event: any) => {
    setDateFilterValue([event]);
    setDateOption(event[0].value);
  };
  // set restaurant id searched
  const setRestaurantId = (id: number) => {
    setRestaurantSelectedId(id);
    setCurrentPage(1);
  };
  // set vendor id searched
  const setVendorId = (id: number) => {
    setVendorSelectedId(id);
    setCurrentPage(1);
  };

  // handle date change
  const handleDateChange = (paramName: string, paramValue: any) => {
    if (paramName === TEXT_START) {
      if (paramValue !== '' && paramValue !== null) {
        setStartDateSelected(paramValue);
        const fromDateInitial = moment(new Date(paramValue)).format(DATE_FORMAT2);
        const fromDate = new Date(`${fromDateInitial} ${TIME_STARTS_FORMAT}`).toISOString();
        setStartDateUtc(fromDate);
        if (endDateUtc === '') {
          const toDateInitial = moment(new Date()).format(DATE_FORMAT2);
          const dateStringTo = toDateInitial.replace(/-/g, '/').replace('T', ' ');
          const toDateUTC = new Date(`${dateStringTo} ${TIME_END_FORMAT}`).toISOString();
          setEndDateUtc(toDateUTC);
          setEndDateSelected(toDateInitial);
        }
        setCurrentPage(1);
      } else {
        setStartDateSelected('');
        setStartDateUtc('');
      }
    }
    if (paramName === TEXT_END) {
      if (paramValue !== '' && paramValue !== null) {
        setEndDateSelected(paramValue);
        const toDateInitial = moment(new Date(paramValue)).format(DATE_FORMAT2);
        const toDate = new Date(`${toDateInitial} ${TIME_END_FORMAT}`).toISOString();
        setEndDateUtc(toDate);
        if (startDateUtc === '') {
          const dateStringFrom = toDateInitial.replace(/-/g, '/').replace('T', ' ');
          const fromDateUTC = new Date(`${dateStringFrom} ${TIME_STARTS_FORMAT}`).toISOString();
          setStartDateUtc(fromDateUTC);
          setStartDateSelected(toDateInitial);
        }
        setCurrentPage(1);
      } else {
        setEndDateSelected('');
        setEndDateUtc('');
      }
    }
  };
  // provides date label based on date option
  const getDateLabel = () => {
    let label;
    switch (dateOption) {
      case DateFilterOptions.DAILY:
        label = useIntlActionMessages('Reports.Filter.Today.Label')?.toLowerCase() || '';
        break;
      case DateFilterOptions.THIS_WEEK:
        label = useIntlActionMessages('Reports.Filter.ThisWeek.Label')?.toLowerCase() || '';
        break;
      case DateFilterOptions.THIS_MONTH:
        label = useIntlActionMessages('Reports.Filter.ThisMonth.Label')?.toLowerCase() || '';
        break;
      default:
        label = null;
        break;
    }
    return label;
  };
  // Function that returns the badge for order status
  function getOrderBadge(order: OrderRow) {
    switch (order.orderStatus) {
      case OrderStatus.Placed:
        return (
          <Badge bg="secondary" className="badge-due-paid">
            {OrderStatus[order.orderStatus]}
          </Badge>
        );
      case OrderStatus['Order in Progress']:
        return (
          <Badge bg="secondary" className="badge-inprogress">
            {OrderStatus[order.orderStatus]}
          </Badge>
        );
      case OrderStatus.Delivering:
        return (
          <Badge bg="secondary" className="badge-delivering">
            {OrderStatus[order.orderStatus]}
          </Badge>
        );
      case OrderStatus.Pending:
        return (
          <Badge bg="secondary" className="badge-pending">
            {OrderStatus[order.orderStatus]}
          </Badge>
        );
      case OrderStatus.Modified:
        return (
          <Badge bg="secondary" className="badge-modified">
            {OrderStatus[order.orderStatus]}
          </Badge>
        );
      case OrderStatus.Delivered:
        return (
          <Badge bg="secondary" className="badge-delivered">
            {OrderStatus[order.orderStatus]}
          </Badge>
        );
      case OrderStatus['Not Received (Disputed)']:
        return (
          <Badge bg="secondary" className="badge-not-received">
            {OrderStatus[order.orderStatus]}
          </Badge>
        );
      case OrderStatus['Under Discrepancy (Disputed)']:
        return (
          <Badge bg="secondary" className="badge-discrepancy">
            {OrderStatus[order.orderStatus]}
          </Badge>
        );
      case OrderStatus['Catch Weight']:
        return (
          <Badge bg="secondary" className="badge-catchweight">
            {OrderStatus[order.orderStatus]}
          </Badge>
        );
      case OrderStatus.Rejected:
        return (
          <Badge bg="secondary" className="badge-not-accepted">
            {OrderStatus[order.orderStatus]}
          </Badge>
        );
      case OrderStatus.Cancelled:
        return (
          <Badge bg="secondary" className="badge-cancelled">
            {OrderStatus[order.orderStatus]}
          </Badge>
        );
      default:
        return order.orderStatus;
    }
  }

  return (
    <div className="content-nav-sub">
      <div className="content-nav-header d-flex justify-content-between align-items-center">
        <h2>
          <FormattedMessage id="Dashboard.Orders" />
        </h2>
      </div>
      <div className="content-filter-main border-0">
        <Row className="justify-content-between align-items-center">
          <Col xl lg={12}>
            <Row>
              <Col xs={6} md={6} lg={4} xl={3} className="no-icon-select">
                <Select id="OrderFilter" name="Order Filter" options={DateOptions} placeholder="sample" dropdownGap={0} values={[dateFilterValue]} onChange={handleDateSelectBox} />
              </Col>
              <Col xs={12} md={6} lg={4} xl={4} className="no-margin search-auto">
                <VendorSearch setVendorId={setVendorId} searchType={VendorRestaurantSearchType.ORDER} />
              </Col>
              <Col xs={12} md={6} lg={4} xl={4} className="no-margin search-auto mt-mx-md-top">
                <RestaurantSearch setRestId={setRestaurantId} searchType={VendorRestaurantSearchType.ORDER} />
              </Col>
            </Row>
          </Col>
          <Col sm="auto" className="d-flex mt-mx-lg-top">
            <p className="mb-0 me-5">
              <FormattedMessage id="Dashboard.Totalorders" /> {getDateLabel()}: <span className="text-dark semi-bold ms-3">{ordersListTotalCount}</span>
            </p>
            <p className="mb-0">
              <FormattedMessage id="Dashboard.Totalamount" />:{' '}
              <span className="text-dark semi-bold ms-3">
                ${orderListTotalDetails && orderListTotalDetails[0] && formatNumberWithCommas(Number(orderListTotalDetails[0].TotalAmount))}
              </span>
            </p>
          </Col>
        </Row>
        {dateOption === TEXT_CUSTOM && (
          <Row className="mt-4">
            <Col xs={12} md={6} lg={4} xl="auto" className="no-margin custom-calendar">
              <DatePicker
                selected={startDateSelected !== '' ? new Date(startDateSelected) : null}
                onChange={(date: any) => handleDateChange('start', date)}
                placeholderText={useIntlMessages('Dashboard.StartDate')}
                maxDate={moment().toDate()}
                onChangeRaw={(e) => {
                  e.preventDefault();
                }}
              />
            </Col>
            <Col xs={12} md={6} lg={4} xl="auto" className="no-margin custom-calendar">
              <DatePicker
                selected={endDateSelected !== '' ? new Date(endDateSelected) : null}
                onChange={(date: any) => handleDateChange('end', date)}
                placeholderText={useIntlMessages('Dashboard.EndDate')}
                maxDate={moment().toDate()}
                minDate={startDateSelected !== '' ? new Date(startDateSelected) : null}
                onChangeRaw={(e) => {
                  e.preventDefault();
                }}
              />
            </Col>
          </Row>
        )}
      </div>
      <Table responsive>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="Dashboard.Order" />
            </th>
            <th>
              <FormattedMessage id="Vendor.VendorName" />
            </th>
            <th>
              <FormattedMessage id="Restaurant.RestaurantName" />
            </th>
            <th>
              <FormattedMessage id="Dashboard.Amount" />
            </th>
            <th>
              <FormattedMessage id="Dashboard.TotalItem" />
            </th>
            <th>
              <FormattedMessage id="Dashboard.Date" />
            </th>
            <th>
              <FormattedMessage id="Dashboard.Time" />
            </th>
            <th className="w-200">
              <FormattedMessage id="Dashboard.Status" />
            </th>
          </tr>
        </thead>
        <tbody>
          {ordersList.map((order: OrderRow) => (
            <tr>
              <td>
                <Link to={`/home/orderdetails/${order.orderGuid}`}>{order.orderNo}</Link>
              </td>
              <td>{order.vendorName}</td>
              <td>{order.restaurantName}</td>
              <td>{order.amount ? `$${formatNumberWithCommas(order.amount)}` : ''}</td>
              <td>{order.totalItems}</td>
              <td> {moment(order.orderPlacedOn).local().format(DATE_FORMAT)}</td>
              <td>{moment(order.orderPlacedOn).local().format(TIME_FORMAT)}</td>
              <td>{getOrderBadge(order)}</td>
            </tr>
          ))}
          {ordersList.length === 0 && (
            <tr>
              <td colSpan={8} className="text-center">
                <FormattedMessage id="Orders.Noorders.Message" />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <div className="content-filter-main d-lg-flex justify-content-lg-between">
        {ordersList.length > 0 && (
          <p className="m-0">
            {stringFormat(
              ordersListTotalCount === 1 ? useIntlActionMessages('Orders.Pagination.SingleItem.Text') : useIntlActionMessages('Orders.Pagination.Text'),
              ordersList.length > 0 ? ((ordersListTotalCount === 1 ? 1 : currentPage) - 1) * pageItemCount + 1 : 0,
              ((ordersListTotalCount === 1 ? 1 : currentPage) - 1) * pageItemCount + ordersList.length,
              ordersListTotalCount,
            )}
          </p>
        )}
        {ordersList.length > 0 && ordersListTotalCount > pageItemCount && (
          <Pagination
            totalCount={ordersListTotalCount}
            pageLimit={pageItemCount}
            setCurrentPage={(page: number) => setCurrentPage(page)}
            currentPage={currentPage - 1}
            prevPage={-1}
          />
        )}
      </div>
      {ordersListLoading && <GifLoader />}
    </div>
  );
};

export default Orders;
