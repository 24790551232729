import React from 'react';

const LogoutIcon = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.58464 20.1673C4.34152 20.1673 4.10836 20.0707 3.93645 19.8988C3.76455 19.7269 3.66797 19.4938 3.66797 19.2507V2.75065C3.66797 2.50754 3.76455 2.27438 3.93645 2.10247C4.10836 1.93056 4.34152 1.83398 4.58464 1.83398H17.418C17.6611 1.83398 17.8942 1.93056 18.0661 2.10247C18.2381 2.27438 18.3346 2.50754 18.3346 2.75065V5.50065H16.5013V3.66732H5.5013V18.334H16.5013V16.5007H18.3346V19.2507C18.3346 19.4938 18.2381 19.7269 18.0661 19.8988C17.8942 20.0707 17.6611 20.1673 17.418 20.1673H4.58464ZM16.5013 14.6673V11.9173H10.0846V10.084H16.5013V7.33398L21.0846 11.0007L16.5013 14.6673Z"
        fill="#63707E"
      />
    </svg>
  );
};

export default LogoutIcon;
