/**
 * @file   src\features\permission\PermissionSettings.tsx
 * @brief  Vendor/Restaurant permissions setting page.
 * @date   Aug, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import {
  FormattedMessage,
  Link,
  Tab,
  Tabs,
  Col,
  Row,
  Button,
  useState,
  useEffect,
  useParams,
  Select,
  NavDropdown,
  components,
  useLocation,
  useNavigate,
  OverlayTrigger,
  Tooltip,
  Modal,
} from '../../utils/thirdpartyComponents';
import BackIcon from '../../assets/icons/Back';
import InputCheck from '../../components/ChecBoxLabel';
import FirstName from '../../assets/icons/Profile';
import Admin from '../../assets/DropAdminIcon.svg';
import DropCustom from '../../assets/CustomRole.svg';
import SalesPerson from '../../assets/DropSalesPerson.svg';
import SalesManager from '../../assets/DropSalesManager.svg';
import Bookkeeper from '../../assets/DropAssistant.svg';
import CreditDepartment from '../../assets/DropCredit.svg';
import OrderingDepartment from '../../assets/OrderingDepartment.svg';
import { INotifications, IPermission, IPermissionJson, IRolePermissionSaveRequest } from '../../interfaces/rolePermissionInterface';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { RootState } from '../../store';
import GifLoader from '../../components/GifLoader';
import { deleteRolePermissions, getRoleBasedPermissions, savePermissions } from '../../store/actions/rolepermissionAction';
import { AppType, BusinessRoles, BusinessType, InterLinkedPermissions, MenuUrls, NotificationTypes, PermissionTabs } from '../../utils/enums';
import InputIcon from '../../components/InputIcon';
import { DELETE_KEY, INPUT_MAX_LENGTH_20 } from '../../utils/constants';
import { useIntlActionMessages, useIntlMessages, checkPathMatched, stringFormat } from '../../utils/helper';
import { validateForm } from '../../utils/formValidation';
import { USER_ROLE_SCHEMA } from '../../validations/userSchema';
import { MessageToaster } from '../../utils/toastUtil';
import Info from '../../assets/icons/Info';
import { resetRole } from '../../store/slices/rolepermissionSlice';
import InputLabel from '../../components/InputLabel';

// Customise select field options.
const { Option } = components;
const IconOption = (props: any) => (
  <Option {...props}>
    {/* eslint-disable-next-line react/destructuring-assignment */}
    <img src={props.data.icon} alt={props.data.value} />
  </Option>
);
// Toast object creation.
const toast = new MessageToaster();

const VendorDetails = () => {
  // Create location object to access location info.
  const location = useLocation();

  // Create route param object to access the route parameters.
  const params = useParams();

  // Navigate object creation.
  const navigate = useNavigate();

  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Initialize component state variables.
  const [roleId, setRoleId] = useState<number>(0);
  const [businessType, setBusinessType] = useState<number>(0);
  const [appType, setAppType] = useState<number>(0);
  const [roleName, setRoleName] = useState<string>('');
  const [iconText, setIconText] = useState<string>('');
  const [isContactChecked, setContactChecked] = useState<boolean>(false);
  const [iconData, setIconData] = useState<any>([]);
  const [errorFields, setErrorFields] = useState<any>({});
  const [previewIcon, setPreviewIcon] = useState<string>(Admin);
  const [selectedTab, setSelectedTab] = useState<string>(PermissionTabs.WEB);
  const [permissionWebDetails, setPermissionWebDetails] = useState<IPermissionJson[]>([]);
  const [permissionIpadDetails, setPermissionIpadDetails] = useState<IPermissionJson[]>([]);
  const [permissionIosDetails, setPermissionIosDetails] = useState<IPermissionJson[]>([]);
  const [selectedWebPermissions, setSelectedWebPermissions] = useState<IPermissionJson[]>([]);
  const [selectedIpadPermissions, setSelectedIpadPermissions] = useState<IPermissionJson[]>([]);
  const [selectedIosPermissions, setSelectedIosPermissions] = useState<IPermissionJson[]>([]);
  const [notificationList, setNotificationList] = useState<INotifications[]>([]);
  const [deleteRoleConfirmPopup, setDeleteRoleConfirmPopup] = useState<boolean>(false);
  const [deleteText, setDeleteText] = useState<string>('');
  const [roleDeleteErrorField, setRoleDeleteErrorField] = useState<any>({});

  // Access redux state variables.
  const {
    roleId: permRoleId,
    roleDetails,
    rolePermissions,
    notifications,
    rolePermissionsSuccess,
    rolePermissionsLoading,
    rolePermissionsErrorCode,
    rolePermissionsErrorMessage,
    saveRolePermissionsLoading,
    saveRolePermissionsSuccess,
    saveRolePermissionsErrorCode,
    saveRolePermissionsErrorMessage,
    deleteRolePermissionsLoading,
    deleteRolePermissionsSuccess,
    deleteRolePermissionsErrorCode,
    deleteRolePermissionsErrorMessage,
  } = useAppSelector((state: RootState) => state.roleperm);

  // Handle component side effects to call api's at page load.
  useEffect(() => {
    /* Access and set the location url parameters. */
    const rId: number = params.roleId ? Number(params.roleId) : 0;
    const busType: number = params.businessType ? Number(params.businessType) : 0;
    const apType: number = params.appType ? Number(params.appType) : 0;
    setRoleId(rId);
    setBusinessType(busType);
    setAppType(apType);
    return () => {
      dispatch(resetRole());
    };
  }, [dispatch, location.key]);

  // permissions fetch based on role, business type and app type state change.
  useEffect(() => {
    if (
      (businessType === BusinessType.VENDOR && (appType === AppType.VENDOR_RESTAURANT_WEB || appType === AppType.VENDOR_IPHONE)) ||
      (businessType === BusinessType.RESTAURANT && (appType === AppType.VENDOR_RESTAURANT_WEB || appType === AppType.RESTAURANT_IPAD || appType === AppType.RESTAURANT_IPHONE))
    ) {
      dispatch(getRoleBasedPermissions({ roleId, businessType }));
    }
  }, [roleId, businessType]);

  // Get enum keys based on values.
  function getKeyByValue(value: string) {
    const indexOfS = Object.values(BusinessRoles).indexOf(value as unknown as BusinessRoles);
    const key = Object.keys(BusinessRoles)[indexOfS];
    return key.toLowerCase().trim();
  }

  // Set Role select options.
  const options = [
    { value: getKeyByValue(BusinessRoles.ADMIN), label: BusinessRoles.ADMIN.toString(), icon: Admin },
    { value: getKeyByValue(BusinessRoles.SALES_PERSON), label: BusinessRoles.SALES_PERSON.toString(), icon: SalesPerson },
    { value: getKeyByValue(BusinessRoles.SALES_MANAGER), label: BusinessRoles.SALES_MANAGER.toString(), icon: SalesManager },
    { value: getKeyByValue(BusinessRoles.ASSISTANT), label: BusinessRoles.ASSISTANT.toString(), icon: Bookkeeper },
    { value: getKeyByValue(BusinessRoles.CREDIT_DEPARTMENT), label: BusinessRoles.CREDIT_DEPARTMENT.toString(), icon: CreditDepartment },
    { value: getKeyByValue(BusinessRoles.ORDERING_DEPARTMENT), label: BusinessRoles.ORDERING_DEPARTMENT.toString(), icon: OrderingDepartment },
    { value: getKeyByValue(BusinessRoles.CUSTOM_ROLE), label: BusinessRoles.CUSTOM_ROLE.toString(), icon: DropCustom },
  ];

  // Method to create parent child(nested) json from the permission result.
  const createParentChildNestedPermissionData = (permissions: IPermissionJson[]) => {
    const data: any = {};
    let node: IPermissionJson | null;
    const roots: IPermissionJson[] = [];
    let i;
    const permissionData = [...permissions];
    /* Loop through array permissions and set arrange permissions index value. */
    for (i = 0; i < permissionData.length; i += 1) {
      data[permissionData[i].permissionId] = i;
      const permDt = { ...permissionData[i] };
      permDt.children = [];
      permDt.count = 0;
      permissionData[i] = permDt;
    }
    let index = 0;
    let baseCount = 0;
    for (i = 0; i < permissionData.length; i += 1) {
      node = permissionData[i];
      baseCount += 1;
      if (node.parentId !== null) {
        // if you have more branches check that data[node.parentId] exists.
        if (!permissionData[data[node.parentId]].isEnabled) {
          node.isEnabled = false;
          node.isPermissionDisabled = true;
        } else {
          node.isPermissionDisabled = false;
        }
        permissionData[data[node.parentId]]?.children?.push(node);
      } else {
        permissionData[index].count = baseCount;
        roots.push(node);
        index = i;
        baseCount = 0;
      }
    }
    if (roots.find((rt: IPermissionJson) => rt.count && rt.count > 1)) {
      roots.sort((a, b) => {
        return (a.count || 0) - (b.count || 0);
      });
    }
    return roots;
  };

  // Handle save role permission response.
  useEffect(() => {
    if (saveRolePermissionsSuccess && saveRolePermissionsErrorCode === 0 && saveRolePermissionsErrorMessage) {
      toast.toastSuccess(saveRolePermissionsErrorMessage);
      navigate(`${businessType === BusinessType.VENDOR ? '/permissions/vendor' : '/permissions/restaurant'}`);
    } else if (saveRolePermissionsSuccess && saveRolePermissionsErrorCode > 0 && saveRolePermissionsErrorMessage) {
      toast.toastError(saveRolePermissionsErrorMessage);
    }
  }, [permRoleId, saveRolePermissionsSuccess, saveRolePermissionsErrorCode, saveRolePermissionsErrorMessage]);

  // Handle delete role permission response.
  useEffect(() => {
    if (deleteRolePermissionsSuccess && deleteRolePermissionsErrorCode === 0 && deleteRolePermissionsErrorMessage) {
      toast.toastSuccess(deleteRolePermissionsErrorMessage);
      navigate(`${businessType === BusinessType.VENDOR ? '/permissions/vendor' : '/permissions/restaurant'}`);
    } else if (deleteRolePermissionsErrorCode > 0 && deleteRolePermissionsErrorMessage) {
      toast.toastError(deleteRolePermissionsErrorMessage);
      dispatch(resetRole());
    }
  }, [deleteRolePermissionsSuccess, deleteRolePermissionsErrorCode, deleteRolePermissionsErrorMessage]);

  // Permissions checkbox configuration when web permissions result state change.
  useEffect(() => {
    const permissionOptions = createParentChildNestedPermissionData(selectedWebPermissions);
    setPermissionWebDetails(permissionOptions);
  }, [selectedWebPermissions]);

  // Permissions checkbox configuration when ipad permissions result state change.
  useEffect(() => {
    const permissionOptions = createParentChildNestedPermissionData(selectedIpadPermissions);
    setPermissionIpadDetails(permissionOptions);
  }, [selectedIpadPermissions]);

  // Permissions checkbox configuration when ios permissions result state change.
  useEffect(() => {
    const permissionOptions = createParentChildNestedPermissionData(selectedIosPermissions);
    setPermissionIosDetails(permissionOptions);
  }, [selectedIosPermissions]);

  // Role permission fetch success reducer state change handling.
  useEffect(() => {
    if (rolePermissionsSuccess && rolePermissionsErrorCode === 0 && roleDetails) {
      const selectedData = options.filter((opt: any) => opt.value === roleDetails.iconFile?.trim());
      setRoleId(roleDetails.roleId || 0);
      setRoleName(roleDetails.roleName || '');
      setIconData(selectedData);
      setIconText(roleDetails.iconFile || '');
      setPreviewIcon(selectedData[0]?.icon || Admin);
      setContactChecked(roleDetails.isContactPerson);
      if (businessType === BusinessType.VENDOR) {
        setSelectedWebPermissions(rolePermissions.filter((web: IPermissionJson) => web.appType === AppType.VENDOR_RESTAURANT_WEB));
        setSelectedIosPermissions(rolePermissions.filter((web: IPermissionJson) => web.appType === AppType.VENDOR_IPHONE));
      } else {
        setSelectedWebPermissions(rolePermissions.filter((web: IPermissionJson) => web.appType === AppType.VENDOR_RESTAURANT_WEB));
        setSelectedIosPermissions(rolePermissions.filter((web: IPermissionJson) => web.appType === AppType.RESTAURANT_IPHONE));
        setSelectedIpadPermissions(rolePermissions.filter((web: IPermissionJson) => web.appType === AppType.RESTAURANT_IPAD));
      }
      setNotificationList(notifications);
    } else if (rolePermissionsErrorCode > 0 && rolePermissionsErrorMessage) {
      toast.toastError(rolePermissionsErrorMessage);
    }
  }, [rolePermissionsSuccess, rolePermissionsErrorCode, rolePermissionsErrorMessage, roleDetails]);

  // Manage interlinked permissions handler.
  const manageInterlinkedPermissions = (newArray: IPermissionJson[], changedArr: IPermissionJson, isChecked: boolean, index: number) => {
    const modifiedPermissions = [...newArray];
    switch (changedArr.permissionId) {
      case InterLinkedPermissions.DELIVERIES: {
        const linkedPermissionIndex = modifiedPermissions.findIndex((x) => x.permissionId === InterLinkedPermissions.PICK_TICKETS);
        if (linkedPermissionIndex > -1) {
          const linkedPermission = { ...modifiedPermissions[linkedPermissionIndex] };
          if (isChecked) {
            linkedPermission.isEnabled = true;
            modifiedPermissions[linkedPermissionIndex] = linkedPermission;
          }
        }
        changedArr.isEnabled = !modifiedPermissions[index].isEnabled;
        modifiedPermissions[index] = changedArr;
        break;
      }
      case InterLinkedPermissions.PICK_TICKETS: {
        const linkedPermission = newArray.find((x) => x.permissionId === InterLinkedPermissions.DELIVERIES);
        if (linkedPermission?.isEnabled) {
          toast.toastError(useIntlActionMessages('RolePermission.picktickets.uncheck.warning'));
        } else {
          changedArr.isEnabled = !modifiedPermissions[index].isEnabled;
          modifiedPermissions[index] = changedArr;
        }
        break;
      }
      case InterLinkedPermissions.PURCHASING: {
        const linkedPermissionIndex = modifiedPermissions.findIndex((x) => x.permissionId === InterLinkedPermissions.PURCHASE_ORDERS);
        if (linkedPermissionIndex > -1) {
          const linkedPermission = { ...modifiedPermissions[linkedPermissionIndex] };
          if (isChecked) {
            linkedPermission.isEnabled = true;
            modifiedPermissions[linkedPermissionIndex] = linkedPermission;
          }
        }
        changedArr.isEnabled = !modifiedPermissions[index].isEnabled;
        modifiedPermissions[index] = changedArr;
        break;
      }
      case InterLinkedPermissions.PURCHASE_ORDERS: {
        const linkedPermission = newArray.find((x) => x.permissionId === InterLinkedPermissions.PURCHASING);
        if (linkedPermission?.isEnabled) {
          toast.toastError(useIntlActionMessages('RolePermission.puchaseorder.uncheck.warning'));
        } else {
          changedArr.isEnabled = !modifiedPermissions[index].isEnabled;
          modifiedPermissions[index] = changedArr;
        }
        break;
      }
      default:
        changedArr.isEnabled = !modifiedPermissions[index].isEnabled;
        modifiedPermissions[index] = changedArr;
        break;
    }
    return modifiedPermissions;
  };

  // Permissions checkbox change event.
  const onPermissionCheckChange = async (event: React.ChangeEvent<HTMLInputElement>, permArr: IPermissionJson[]) => {
    const { name, value, checked } = event.target;
    let newArray = [...permArr];
    /* Select permission using permission id. */
    const foundIndex = newArray.findIndex((x) => x.permissionId === Number(value.trim()));
    if (foundIndex > -1) {
      const changedArr = { ...newArray[foundIndex] };
      /* Manage inter-linked Permissions. */
      newArray = manageInterlinkedPermissions(newArray, changedArr, checked, foundIndex);
      /* changedArr.isEnabled = !newArray[foundIndex].isEnabled;
      newArray[foundIndex] = changedArr; */
      const { permissionId, sortOrder, isEnabled } = newArray[foundIndex];
      /* Get all sub permissions using sort order */
      const nestedData = newArray.filter((dt: IPermissionJson) => dt.permissionId !== permissionId && dt.sortOrder.startsWith(`${sortOrder}.`)).map((x) => x.permissionId);
      nestedData.forEach((nes: number) => {
        /* Update each sub permission enabled and permission disable value.  */
        const index = newArray.findIndex((x) => x.permissionId === nes);
        if (index > -1) {
          const nestedUpdateArr = { ...newArray[index] };
          if (!isEnabled) {
            nestedUpdateArr.isEnabled = false;
            nestedUpdateArr.isPermissionDisabled = true;
          } else {
            nestedUpdateArr.isPermissionDisabled = false;
          }
          newArray[index] = nestedUpdateArr;
        }
      });
      /* Set permission state based on app type. */
      if (appType === AppType.VENDOR_RESTAURANT_WEB) {
        setSelectedWebPermissions(newArray);
      } else if (appType === AppType.VENDOR_IPHONE || appType === AppType.RESTAURANT_IPHONE) {
        setSelectedIosPermissions(newArray);
      } else if (appType === AppType.RESTAURANT_IPAD) {
        setSelectedIpadPermissions(newArray);
      }
      /* Get all checked permission ids array for validation. */
      const selectedPermissionIds = newArray.filter((arr: IPermissionJson) => arr.isEnabled).map((enable: IPermissionJson) => enable.permissionId);
      const validateObj = {
        [name]: selectedPermissionIds,
      };
      const errorresult = await validateForm(validateObj, USER_ROLE_SCHEMA, errorFields);
      setErrorFields(errorresult);
    }
  };

  // Input field change event.
  const onInputHandleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setRoleName(value);
    const validateObj = {
      [name]: value?.trim() || '',
    };
    const errorresult = await validateForm(validateObj, USER_ROLE_SCHEMA, errorFields);
    setErrorFields(errorresult);
  };

  // Handle select icon change.
  const selectIconChange = async (selectedValue: any, fieldName: string) => {
    setIconData([selectedValue]);
    setIconText(selectedValue?.value);
    setPreviewIcon(selectedValue?.icon);
    const validateObj = {
      [fieldName]: selectedValue?.value,
    };
    const errorresult = await validateForm(validateObj, USER_ROLE_SCHEMA, errorFields);
    setErrorFields(errorresult);
  };

  // Handle contact enabled checkbox change event.
  const onContactCheckedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContactChecked(event.target.checked);
  };

  // Tab select change event.
  const handleSelect = (key: string | null) => {
    let type = 0;
    switch (key) {
      case PermissionTabs.WEB:
        type = AppType.VENDOR_RESTAURANT_WEB;
        break;
      case PermissionTabs.IPAD:
        type =
          checkPathMatched([MenuUrls.ROLE_PERMISSION_RESTAURANT_ADD, MenuUrls.ROLE_PERMISSION_RESTAURANT_EDIT], location?.pathname) && businessType === BusinessType.RESTAURANT
            ? AppType.RESTAURANT_IPAD
            : Number(key);
        break;
      case PermissionTabs.IPHONE:
        type = businessType === BusinessType.VENDOR ? AppType.VENDOR_IPHONE : AppType.RESTAURANT_IPHONE;
        break;
      default:
        type = AppType.VENDOR_RESTAURANT_WEB;
        break;
    }
    setAppType(type);
    setSelectedTab(key || '');
  };

  // Notifications checkbox change event.
  const onNotificationCheckedChange = (event: React.ChangeEvent<HTMLInputElement>, notificationTypeId: number) => {
    const { name, checked } = event.target;
    const notificationData = [...notificationList];
    const index = notificationData.findIndex((not: INotifications) => not.notificationTypeId === notificationTypeId);
    if (index > -1) {
      const selectedNotification = { ...notificationData[index], [name]: checked };
      notificationData[index] = selectedNotification;
      setNotificationList(notificationData);
    }
  };

  // Delete role input handler.
  const onDeleteRoleHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setDeleteText(value);
    const validateSchema = {
      activateText: value,
    };
    const errorresult = await validateForm(validateSchema, USER_ROLE_SCHEMA, roleDeleteErrorField);
    setErrorFields(errorresult);
  };

  // Delete popup confirmation cancel event.
  const onCancelDeletePopup = () => {
    setDeleteRoleConfirmPopup(false);
    setDeleteText('');
  };

  // Delete role permission.
  const deleteRolePermission = async () => {
    const validateSchema = {
      deleteText,
    };
    const errorresult = await validateForm(validateSchema, USER_ROLE_SCHEMA, roleDeleteErrorField);
    setRoleDeleteErrorField(errorresult);
    if (Object.keys(errorresult).length === 0) {
      if (deleteText.trim() === DELETE_KEY.toLocaleLowerCase()) {
        setDeleteRoleConfirmPopup(false);
        dispatch(deleteRolePermissions(roleId));
      } else {
        setRoleDeleteErrorField({ deleteText: useIntlActionMessages('Form.Text.Error') });
      }
    }
  };

  // Submit handler to add new role.
  const onSubmit = async () => {
    if (
      (businessType === BusinessType.VENDOR && (appType === AppType.VENDOR_RESTAURANT_WEB || appType === AppType.VENDOR_IPHONE)) ||
      (businessType === BusinessType.RESTAURANT && (appType === AppType.VENDOR_RESTAURANT_WEB || appType === AppType.RESTAURANT_IPAD || appType === AppType.RESTAURANT_IPHONE))
    ) {
      const selectedPermissionResult = [...selectedWebPermissions, ...selectedIosPermissions, ...selectedIpadPermissions].map((i: any) =>
        Object.fromEntries(['permissionId', 'isEnabled'].map((f) => [f, i[f]])),
      ) as IPermission[];
      const selectedNotificationResult = [...notificationList].map((i: any) =>
        Object.fromEntries(['notificationTypeId', 'sendText', 'sendEmail', 'sendPush'].map((f) => [f, i[f]])),
      ) as INotifications[];
      const selectedPermissionIds = selectedPermissionResult.filter((arr: IPermission) => arr.isEnabled).map((enable: IPermission) => enable.permissionId);
      const permissionSaveValidationRequest = {
        roleName,
        iconText,
        selectedPermissions: selectedPermissionIds,
      };
      const errorresult = await validateForm(permissionSaveValidationRequest, USER_ROLE_SCHEMA, errorFields);
      setErrorFields(errorresult);
      if (Object.keys(errorresult).length === 0) {
        const saveVendorRequest: IRolePermissionSaveRequest = {
          roleId,
          businessType,
          appType,
          roleName: roleName?.trim() || '',
          iconFile: iconText,
          isContactPerson: isContactChecked ? 1 : 0,
          permissionIds: selectedPermissionResult,
          notificationIds: selectedNotificationResult,
        };
        dispatch(savePermissions(saveVendorRequest));
      } else {
        toast.toastError(useIntlActionMessages('Enter.Mandatory'));
      }
    }
  };

  // Bind nested permission list items.
  const bindNestedPermissionListItems = (items: IPermissionJson[] | undefined, selectedPermArr: IPermissionJson[]) => {
    return items?.map((li: IPermissionJson) => (
      <li>
        <InputCheck
          key={li.permissionId}
          id={`node_${li.permissionId}`}
          name="selectedPermissions"
          label={li.desciption}
          value={li.permissionId}
          checked={li.isEnabled}
          {...(!li.isPermissionDisabled && { onChange: (e) => onPermissionCheckChange(e, selectedPermArr) })}
          disabled={li.isPermissionDisabled}
        />
        {li.children && li.children.length > 0 && bindNestedPermissionListItems(li.children, selectedPermArr)}
      </li>
    ));
  };

  // Bind Permissions checkboxes.
  const bindPermissionData = (selectedPermArr: IPermissionJson[], permDetails: IPermissionJson[]) => {
    return (
      <Row className="permission-settings New mt-4">
        {permDetails.length > 0 &&
          permDetails.map((perm: IPermissionJson) => (
            <Col xl={3} lg={4} md={6}>
              <ul>
                <InputCheck
                  key={perm.permissionId}
                  id={`node_${perm.permissionId}`}
                  name="selectedPermissions"
                  label={perm.desciption}
                  value={perm.permissionId}
                  checked={perm.isEnabled}
                  {...(!perm.isPermissionDisabled && { onChange: (e) => onPermissionCheckChange(e, selectedPermArr) })}
                  disabled={perm.isPermissionDisabled}
                />
                {bindNestedPermissionListItems(perm.children, selectedPermArr)}
              </ul>
            </Col>
          ))}
        <span className="error"> {errorFields?.selectedPermissions || ''}</span>
      </Row>
    );
  };

  // Configure icon to the role select box Option.
  const formatOptionLabel = ({ icon }: any) => (
    <div className="selcted-role-icon">
      <img src={icon} alt="Role icon" />
    </div>
  );

  // Notification tooltip info.
  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props} className="gray-lg-tooltip">
      <FormattedMessage id="RolePermission.Notification.Tooltip" />
    </Tooltip>
  );

  // Bind notification components.
  const bindNotifications = () => {
    return (
      notificationList &&
      notificationList.length > 0 &&
      notificationList.map((notification: INotifications) => (
        <Row key={notification.notificationTypeId}>
          <Col lg="4" md="5">
            <span className="notify-label">{notification.notificationTypeName || ''}</span>
          </Col>

          <Col lg="3" md="6">
            <Row className="mb-4">
              <Col md="4" className="order-form">
                {notification.notificationTypeId !== NotificationTypes.ORDER_PLACED && notification.notificationTypeId !== NotificationTypes.CREDIT_RATING_UPDATE && (
                  <div className="d-inline-flex">
                    <InputCheck id="sendText" name="sendText" checked={notification.sendText} onChange={(e) => onNotificationCheckedChange(e, notification.notificationTypeId)} />
                    {useIntlMessages('RolePermission.Notifications.CheckBox.Text')}
                  </div>
                )}
              </Col>
              <Col md="4" className="order-form">
                <div className="d-inline-flex">
                  <InputCheck id="sendEmail" name="sendEmail" checked={notification.sendEmail} onChange={(e) => onNotificationCheckedChange(e, notification.notificationTypeId)} />
                  {useIntlMessages('RolePermission.Notifications.CheckBox.Email')}
                </div>
              </Col>
              <Col md="4" className="order-form">
                {notification.notificationTypeId !== NotificationTypes.CREDIT_RATING_UPDATE && (
                  <div className="d-inline-flex">
                    <InputCheck id="sendPush" name="sendPush" checked={notification.sendPush} onChange={(e) => onNotificationCheckedChange(e, notification.notificationTypeId)} />
                    {useIntlMessages('RolePermission.Notifications.CheckBox.Push')}
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      ))
    );
  };

  return (
    <div className="content-nav contnt-container-margin">
      <div className="back-navigation">
        <Link to={`/permissions/${businessType === BusinessType.VENDOR ? 'vendor' : 'restaurant'}`} className="back-btn">
          <BackIcon />
          <FormattedMessage id="Link.Back" />
        </Link>
      </div>
      {((businessType === BusinessType.VENDOR && (appType === AppType.VENDOR_RESTAURANT_WEB || appType === AppType.VENDOR_IPHONE)) ||
        (businessType === BusinessType.RESTAURANT &&
          (appType === AppType.VENDOR_RESTAURANT_WEB || appType === AppType.RESTAURANT_IPAD || appType === AppType.RESTAURANT_IPHONE))) && (
        <div className="content-nav-sub">
          <div className="content-nav-header d-flex justify-content-between align-items-center">
            <h2>
              {checkPathMatched(MenuUrls.ROLE_PERMISSION_VENDOR_EDIT, location?.pathname) ? (
                <FormattedMessage id="HD.RolePermission.Vendor.Edit" />
              ) : checkPathMatched(MenuUrls.ROLE_PERMISSION_VENDOR_ADD, location?.pathname) ? (
                <FormattedMessage id="HD.RolePermission.Vendor.Add" />
              ) : checkPathMatched(MenuUrls.ROLE_PERMISSION_RESTAURANT_ADD, location?.pathname) ? (
                <FormattedMessage id="HD.RolePermission.Restaurant.Add" />
              ) : (
                <FormattedMessage id="HD.RolePermission.Restaurant.Edit" />
              )}
            </h2>
            <div>
              <Button variant="primary" onClick={onSubmit} className="ms-3">
                <FormattedMessage id="Button.Save" />
              </Button>
            </div>
          </div>
          <div className="content-container-padding">
            <p>
              <FormattedMessage id="Role.Fill" />
            </p>
            <Row>
              <Col xs={12} md={6} lg={5} xl={4}>
                <InputIcon
                  id="roleName"
                  name="roleName"
                  type="text"
                  value={roleName}
                  placeholder={useIntlMessages('PH.RolaName')}
                  icon={<FirstName />}
                  maxLength={INPUT_MAX_LENGTH_20}
                  onChange={onInputHandleChange}
                  errorMessage={errorFields?.roleName}
                />
              </Col>
              <Col md="auto">
                <div className="select-icon-outer position-relative choose-icon-drop">
                  <Select
                    id="iconText"
                    name="iconText"
                    options={options}
                    components={{ Option: IconOption }}
                    className="select-with-icon ps-15"
                    placeholder={useIntlMessages('PH.SelectIcon')}
                    classNamePrefix="select_icon"
                    isSearchable={false}
                    formatOptionLabel={formatOptionLabel}
                    value={iconData}
                    onChange={(values) => selectIconChange(values, 'iconText')}
                  />
                  <span className="error"> {errorFields?.iconText || ''}</span>
                </div>
              </Col>
              <Col xs={12} md={6} lg={5} xl={4} className="no-margin-formgroup">
                <InputCheck
                  id="isContactPerson"
                  name="isContactPerson"
                  label={useIntlMessages('Permissions.createrole.visiblecheck.label')}
                  checked={isContactChecked}
                  key="isContactPerson"
                  onChange={onContactCheckedChange}
                />
                <small className="text-lightgray">
                  <FormattedMessage id="Permissions.createrole.visiblecheck.info" />
                </small>
              </Col>
            </Row>
            <p className="mb-3 mt-mx-md-top">
              <FormattedMessage id="Role.Looklike" />
            </p>
            <p className="mb-0">
              <FormattedMessage id="Role.PreviewText" />
            </p>
            <div className="d-inline-flex align-items-center role-demo p-3 border">
              <img src={previewIcon} className="me-3" alt="Role icon" />
              <div>{roleName}</div>
            </div>
          </div>
          <NavDropdown.Divider />
          <div className="content-container-padding">
            <Tabs defaultActiveKey={selectedTab} id="uncontrolled-tab-example" className="mb-5 role-permission" onSelect={(key) => handleSelect(key)}>
              <Tab eventKey={PermissionTabs.WEB} title="Website">
                <p>Set up permissions for website</p>
                {selectedTab === PermissionTabs.WEB && bindPermissionData(selectedWebPermissions, permissionWebDetails)}
              </Tab>
              {businessType === BusinessType.RESTAURANT &&
                checkPathMatched([MenuUrls.ROLE_PERMISSION_RESTAURANT_ADD, MenuUrls.ROLE_PERMISSION_RESTAURANT_EDIT], location?.pathname) && (
                  <Tab eventKey={PermissionTabs.IPAD} title="iPad">
                    <p>Set up permissions for iPad</p>
                    {selectedTab === PermissionTabs.IPAD && bindPermissionData(selectedIpadPermissions, permissionIpadDetails)}
                  </Tab>
                )}
              <Tab eventKey={PermissionTabs.IPHONE} title="iPhone">
                <p>Set up permissions for iPhone</p>
                {selectedTab === PermissionTabs.IPHONE && bindPermissionData(selectedIosPermissions, permissionIosDetails)}
              </Tab>
            </Tabs>
          </div>
          <NavDropdown.Divider />
          <div className="content-container-padding border-top">
            {notificationList.length > 0 ? (
              <>
                <h5>
                  <FormattedMessage id="HD.RolePermission.Notification" />
                  <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
                    <Link to="#" className="info">
                      <Info />
                    </Link>
                  </OverlayTrigger>
                </h5>
                {bindNotifications()}
              </>
            ) : (
              <h5 className="text-center pt-4">
                <FormattedMessage id="RolePermission.Notifications.NoData" />
              </h5>
            )}
          </div>
        </div>
      )}
      {(rolePermissionsLoading || saveRolePermissionsLoading || deleteRolePermissionsLoading) && <GifLoader />}
      {/*  Delete role popup */}
      <Modal show={deleteRoleConfirmPopup} onHide={() => setDeleteRoleConfirmPopup(false)} centered size="lg" className="mx-w-725">
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-sm">{stringFormat(useIntlActionMessages('RolePermission.Delete.HD'), roleName)}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-3">
          <p>
            {stringFormat(
              businessType === BusinessType.VENDOR ? useIntlActionMessages('RolePermission.Delete.Vendor.Info') : useIntlActionMessages('RolePermission.Delete.Restaurant.Info'),
              roleName,
            )}
          </p>
          <Row className="d-flex justify-content-center">
            <p className="text-center mb-2">
              <FormattedMessage id="RolePermission.Delete.Confirm.Text" /> <i>{useIntlActionMessages('Button.Delete').toLowerCase()}</i>
            </p>
            <Col xl={4}>
              <div className="form-sm">
                <InputLabel
                  id="deleteText"
                  name="deleteText"
                  type="text"
                  value={deleteText}
                  onChange={(e) => onDeleteRoleHandler(e)}
                  errorMessage={roleDeleteErrorField?.deleteText}
                />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" className="btn-modal-footer" onClick={onCancelDeletePopup}>
            <FormattedMessage id="Button.Cancelthis" />
          </Button>
          <Button variant="primary" onClick={() => deleteRolePermission()} disabled={deleteText.trim() !== DELETE_KEY.toLocaleLowerCase()}>
            <FormattedMessage id="Button.Delete" />
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default VendorDetails;
