import { IButton } from '../../interfaces/generalInterface';

const CloseIcon = ({ title }: IButton) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13.169" height="13.169" viewBox="0 0 13.169 13.169">
      <title>{title}</title>
      <g id="CloseGray" transform="translate(-0.416 -0.416)">
        <path id="Path_1" data-name="Path 1" d="M12.585,13.584a1,1,0,0,1-.707-.293L.709,2.123A1,1,0,0,1,2.123.708L13.292,11.877a1,1,0,0,1-.707,1.707Z" fill="#63707e" />
        <path id="Path_2" data-name="Path 2" d="M1.416,13.584a1,1,0,0,1-.707-1.707L11.878.708a1,1,0,0,1,1.414,1.414L2.123,13.292A1,1,0,0,1,1.416,13.584Z" fill="#63707e" />
      </g>
    </svg>
  );
};

export default CloseIcon;
