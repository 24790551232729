/**
 * @file   src\features\restaurant\Merge.tsx
 * @brief  Merge restaurant page.
 * @date   June, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { FormattedMessage, useEffect, Table, useNavigate, useState, Button, Row, Col, Modal, Breadcrumb } from '../../utils/thirdpartyComponents';
import { useIntlMessages, stringFormat, useIntlActionMessages } from '../../utils/helper';
import UserType from '../../components/Select';
import SortIcon from '../../assets/icons/Sort';
import InputIcon from '../../components/InputIcon';
import Search from '../../assets/icons/Search';
import { RestaurantsTable } from './RestaurantTable';
import Pagination from '../../components/Paginate';
import InputLabel from '../../components/InputLabel';
import { SortOrderType, RestaurantSortField, IRestaurantSignupStatus, IRestaurantMergeStatus } from '../../utils/enums';
import { PAGE_SIZE, RESTAURANT_SUBSCRIPTION, DEFAULT_RESTAURANT_SUBSCRIPTION } from '../../utils/constants';
import { getRestaurantListForMerge, mergeRestaurants, getMergeRestaurantStatus, clearMergeStatus, getMergedRestaurants } from '../../store/actions/restaurantAction';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { RootState } from '../../store';
import { ISelectOptions } from '../../interfaces/generalInterface';
import { IRestaurantListMerge, IMergeRestaurants, IMergeStatusRow, IRestaurantMergeTableRow, IMergedRestaurantsData } from '../../interfaces/restaurantInterface';
import { MessageToaster } from '../../utils/toastUtil';
import GifLoader from '../../components/GifLoader';
import { resetMergeRestaurant } from '../../store/slices/restaurantSlice';

// Toast object creation.
const toast = new MessageToaster();

// Component to list restaurant for merging
const Merge = () => {
  const pageItemCount: number = process.env.REACT_APP_LIST_PAGE_SIZE !== '' ? Number(process.env.REACT_APP_LIST_PAGE_SIZE) : PAGE_SIZE;
  // Localization hook call.
  const paginationSingleText = useIntlMessages('Restaurant.Pagination.SingleItem.Text');
  const paginationText = useIntlMessages('Restaurant.Pagination.Text');
  // Declare action dispatch.
  const dispatch = useAppDispatch();
  // Creating navigation object
  const navigate = useNavigate();
  // Accessing redux state variables
  const {
    isLoading,
    isSuccess,
    restaurantListMerge,
    errorCode,
    errorMessage,
    mergeLoading,
    mergeSuccess,
    mergeStatusResult,
    mergeStatusSuccess,
    mergeErrorMessage,
    mergeErrorCode,
    mergedRestaurants,
    mergedRestaurantsLoading,
    mergedRestaurantsSuccess,
    mergedRestaurantsErrorCode,
    mergedRestaurantsErrorMessage,
  } = useAppSelector((state: RootState) => state.restaurant);
  const defaultListRequest = {
    restaurantId: 0,
    subscriptionPlanId: 0,
    searchText: '',
    pageSize: pageItemCount,
    pageOffset: 1,
    sortField: RestaurantSortField.RESTAURANT_NAME,
    sortOrder: SortOrderType.DESC,
  };

  // Component state variables
  const [MergeRest, MergeRestPop] = useState<boolean>(false);
  const [MergeConfirm, MergeConfirmPop] = useState<boolean>(false);
  const [restaurantList, setRestaurantList] = useState<IRestaurantMergeTableRow[]>([]);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [requestData, setRequestData] = useState<IRestaurantListMerge>(defaultListRequest);
  const [subscription, setSubscription] = useState<ISelectOptions[]>(DEFAULT_RESTAURANT_SUBSCRIPTION);
  const [signupRestId, setSignupRestId] = useState<number>(0);
  const [confirmText, setConfirmText] = useState<string>('');
  const [mergeStatusPopUp, setMergeStatusPopUp] = useState<boolean>(true);
  const [mergeResult, setMergeResult] = useState<any>([]);
  const [selectedZips, setSelectedZips] = useState<string[]>([]);
  const [mergedRestaurantsPopUp, setMergedRestaurantsPopUp] = useState<boolean>(false);
  const [selectedRestaurantId, setSelectedRestaurant] = useState<number>(0);
  const [mergedRestaurantsData, setMergedRestaurants] = useState<IMergedRestaurantsData[]>([]);

  // fetch restaurants list for merge api call
  const fetchRestaurants = (request: IRestaurantListMerge) => dispatch(getRestaurantListForMerge(request));
  const mergeSelectedRestaurants = (request: IMergeRestaurants) => dispatch(mergeRestaurants(request));
  const fetchMergeRestaurantStatus = () => dispatch(getMergeRestaurantStatus());
  // fetch api on intial loading
  useEffect(() => {
    if (requestData.searchText.length > 2) {
      fetchRestaurants(requestData);
    } else {
      setRestaurantList([]);
    }
    // Reset merge restaurant state values
    return () => {
      dispatch(resetMergeRestaurant());
    };
  }, [requestData.searchText, requestData.pageOffset, requestData.subscriptionPlanId]);
  // fetch merge status on initial loading
  useEffect(() => {
    fetchMergeRestaurantStatus();
  }, []);
  // Reset ids and zips when search text changes
  useEffect(() => {
    setSelectedIds([]);
    setSelectedZips([]);
    setSignupRestId(0);
  }, [requestData.searchText]);

  // Show error message while fetching merged restaurants api.
  useEffect(() => {
    if (!mergedRestaurantsSuccess && mergedRestaurantsErrorCode > 0 && mergedRestaurantsErrorMessage) {
      setMergedRestaurantsPopUp(false);
      toast.toastError(mergedRestaurantsErrorMessage);
    } else if (mergedRestaurantsSuccess && mergedRestaurantsErrorCode === 0) {
      const restaurantsMergedTo = restaurantListMerge?.restaurant?.find((restMerge: any) => restMerge.restaurantId === selectedRestaurantId);
      const originRestaurant: IMergedRestaurantsData = {
        restaurantId: restaurantsMergedTo.restaurantId,
        restaurantName: restaurantsMergedTo.restaurantName,
        address: restaurantsMergedTo.address,
        city: restaurantsMergedTo.city,
        state: restaurantsMergedTo.state,
        stateCode: restaurantsMergedTo.stateCode,
        zip: restaurantsMergedTo.zip,
        email: restaurantsMergedTo.email,
        isActive: restaurantsMergedTo.isActive,
        isLoggedIn: restaurantsMergedTo.isLoggedIn,
        isAddedFromAdminPortal: restaurantsMergedTo.isAddedFromAdminPortal,
        signupStatus: restaurantsMergedTo.signupStatus,
        vendorName: restaurantsMergedTo.vendorName,
      };
      const mergedRestaurantItems = [...mergedRestaurants];
      mergedRestaurantItems.unshift(originRestaurant);
      setMergedRestaurants(mergedRestaurantItems);
      setMergedRestaurantsPopUp(true);
    }
  }, [mergedRestaurantsSuccess, mergedRestaurantsErrorCode, mergedRestaurantsErrorMessage]);

  // set restaurant list data
  useEffect(() => {
    if (isSuccess && Object.keys(restaurantListMerge).length > 0) {
      const data = restaurantListMerge.restaurant.map((restaurant: any) => ({
        restaurantName: restaurant.restaurantName,
        checked: false,
        vendor: restaurant.vendorName,
        email: restaurant.email,
        phone: restaurant.phone,
        countryCode: restaurant.countryCode,
        address: restaurant.address,
        createdOn: restaurant.createdOn,
        restId: restaurant.restaurantId,
        signUpStatus: restaurant.signupStatus,
        zip: restaurant.zip,
        adminAdded: restaurant.isAddedFromAdminPortal,
        mergedStatus: restaurant.mergedStatus,
        isLoggedinOnce: restaurant.isLoggedinOnce,
      }));
      setRestaurantList(data);
    }
  }, [isLoading]);
  // Set merge restaurants status result
  useEffect(() => {
    const finalResult: any = [];
    mergeStatusResult.forEach((result: any) => {
      result.source.forEach((source: any) => {
        finalResult.push({ source: source.sourcecRestaurantName, status: source.mergingStatus, target: result.target.targetRestaurantName });
      });
    });
    setMergeResult(finalResult);
  }, [mergeStatusSuccess]);

  // show error message on api failure
  useEffect(() => {
    if (errorCode > 0) {
      toast.toastError(errorMessage || useIntlActionMessages('Unexpected.Error'));
    }
  }, [isLoading]);
  // Refresh restaurant list on merge success
  useEffect(() => {
    if (mergeSuccess && mergeErrorCode === 0) {
      MergeRestPop(true);
      setSignupRestId(0);
      setSelectedIds([]);
      setSelectedZips([]);
      setRequestData(defaultListRequest);
      setRestaurantList([]);
    }
  }, [mergeSuccess]);
  // Show error message when restaurants merge fail
  useEffect(() => {
    if (mergeErrorCode > 0 && mergeErrorMessage) {
      toast.toastError(mergeErrorMessage);
    }
  }, [mergeErrorCode]);

  // handle check box click event
  const handleCheckboxClick = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const restaurant: any = restaurantList[index];
    restaurant.checked = event.target.checked;
    if (event.target.checked && restaurant.signUpStatus === IRestaurantSignupStatus.SIGNED_UP) {
      if (signupRestId > 0) {
        toast.toastError(useIntlActionMessages('MergeList.Error4'));
      } else {
        setSignupRestId(restaurant.restId);
        setSelectedIds([...selectedIds, restaurant.restId]);
        setSelectedZips([...selectedZips, restaurant.zip]);
      }
    } else if (event.target.checked && restaurant.signUpStatus === IRestaurantSignupStatus.LOCAL && signupRestId > 0) {
      setSelectedIds([...selectedIds, restaurant.restId]);
      setSelectedZips([...selectedZips, restaurant.zip]);
    } else if (event.target.checked && restaurant.signUpStatus === IRestaurantSignupStatus.LOCAL && signupRestId === 0 && selectedIds.length === 0) {
      setSelectedIds([...selectedIds, restaurant.restId]);
      setSelectedZips([...selectedZips, restaurant.zip]);
    } else if (selectedIds.includes(restaurant.restId)) {
      const itemIndex = selectedIds.indexOf(restaurant.restId);
      const existingIds = [...selectedIds];
      existingIds.splice(itemIndex, 1);
      selectedZips.splice(itemIndex, 1);
      setSelectedIds(existingIds);
      if (restaurant.restId === signupRestId) {
        setSignupRestId(0);
      }
    } else {
      setSelectedIds([...selectedIds, restaurant.restId]);
      setSelectedZips([...selectedZips, restaurant.zip]);
    }
    const newArray: any = [...restaurantList];
    newArray[index] = restaurant;
    setRestaurantList(newArray);
  };
  // handle search text change
  const handleSearchText = (event: any) => {
    setRequestData({ ...requestData, searchText: event.target.value });
  };

  // select subscription change event.
  const onSubscriptionChange = (values: ISelectOptions[]) => {
    setSubscription(values);
    setRequestData({ ...requestData, subscriptionPlanId: values[0]?.value, pageOffset: 1 });
  };

  // handle merge confirm button in popup
  const handleMergeConfirm = () => {
    MergeConfirmPop(false);
    setConfirmText('');
    const idsArray = [...selectedIds];
    const index = idsArray.indexOf(signupRestId);
    idsArray.splice(index, 1);
    const request: IMergeRestaurants = {
      restaurantId: signupRestId,
      mergeRestaurantIds: idsArray,
    };
    mergeSelectedRestaurants(request);
  };
  // handle merge button
  const handleMerge = () => {
    if (selectedIds.length === 0 && signupRestId === 0) {
      toast.toastError(useIntlActionMessages('MergeList.Error1'));
    } else if (selectedIds.length > 0 && signupRestId === 0) {
      toast.toastError(useIntlActionMessages('MergeList.Error2'));
    } else if (selectedIds.length === 1 && selectedIds.includes(signupRestId)) {
      toast.toastError(useIntlActionMessages('MergeList.Error3'));
    } else if (!selectedZips.every((i) => i === selectedZips[0])) {
      toast.toastError(useIntlActionMessages('2062'));
    } else {
      MergeConfirmPop(true);
    }
  };
  // handle confirm text change event
  const handleConfirmTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmText(event.target.value);
  };
  // close merge status popup
  const closeMergeStatusPop = () => {
    setMergeStatusPopUp(false);
    const flag = mergeResult.every((item: any) => item.status === IRestaurantMergeStatus.Success);
    if (flag) {
      dispatch(clearMergeStatus());
    }
  };

  // fetch and show the merged restaurants based on restaurant id.
  const viewMergedRestaurantEvent = (restId: number) => {
    if (restId !== 0) {
      setSelectedRestaurant(restId);
      dispatch(getMergedRestaurants(restId));
    }
  };

  return (
    <>
      <div className="content-nav contnt-container-margin">
        <div className="back-navigation">
          <Breadcrumb>
            <Breadcrumb.Item href="#" onClick={() => navigate('/home')}>
              <FormattedMessage id="Menu.Home" />
            </Breadcrumb.Item>
            <Breadcrumb.Item href="#" onClick={() => navigate('/restaurants')}>
              <FormattedMessage id="Restaurant.PageHD" />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              <FormattedMessage id="MergeRestaurants.Heading" />
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="content-nav-sub">
          <div className="content-nav-header d-flex justify-content-between align-items-center">
            <h2>
              <FormattedMessage id="MergeRestaurants.Heading" />
            </h2>
            <div className="d-flex">
              <Button className="btn-light" onClick={() => navigate('/restaurants')}>
                <FormattedMessage id="Button.Cancel" />
              </Button>
              <Button variant="primary" className="me-3" onClick={handleMerge} disabled={restaurantList.length < 1}>
                <FormattedMessage id="Button.Merge" />
              </Button>
            </div>
          </div>
          <div className="content-filter-main border-0 table-filter pb-1">
            <Row>
              <Col xs={12} md={6} lg={4} xl={3}>
                <InputIcon
                  id="SearchRestaurant"
                  name="Search Restaurant"
                  type="text"
                  onChange={handleSearchText}
                  placeholder={useIntlMessages('Restaurant.SearchRestaurant.Placeholder')}
                  icon={<Search />}
                  value={requestData.searchText}
                />
              </Col>
              {isSuccess && (
                <Col xs={12} md={6} lg={4} xl={3}>
                  <UserType
                    id="Subscription"
                    name="Subscription"
                    options={RESTAURANT_SUBSCRIPTION}
                    dropdownGap={0}
                    values={subscription}
                    icon={<SortIcon />}
                    onChange={(values) => onSubscriptionChange(values)}
                  />
                </Col>
              )}
            </Row>
          </div>
          {isSuccess && (
            <RestaurantsTable
              handleCheckBox={handleCheckboxClick}
              viewMergedRestaurantHandleChange={(restaurantId: number) => viewMergedRestaurantEvent(restaurantId)}
              data={restaurantList}
              selectedIds={selectedIds}
            />
          )}

          <div className="content-filter-main d-lg-flex justify-content-lg-between">
            {restaurantListMerge.restaurant && restaurantListMerge.restaurant.length > 0 && (
              <p className="m-0">
                {stringFormat(
                  restaurantListMerge.totalCount === 1 ? paginationSingleText : paginationText,
                  restaurantListMerge.restaurant.length > 0 ? ((restaurantListMerge.totalCount === 1 ? 1 : requestData.pageOffset) - 1) * pageItemCount + 1 : 0,
                  ((restaurantListMerge.totalCount === 1 ? 1 : requestData.pageOffset) - 1) * pageItemCount + restaurantListMerge.restaurant.length,
                  restaurantListMerge.totalCount,
                )}
              </p>
            )}
            {restaurantListMerge.restaurant && restaurantListMerge.restaurant.length > 0 && restaurantListMerge.totalCount > pageItemCount && (
              <Pagination
                totalCount={restaurantListMerge.totalCount}
                pageLimit={pageItemCount}
                setCurrentPage={(page: number) => {
                  setRequestData({ ...requestData, pageOffset: page });
                }}
                currentPage={requestData.pageOffset - 1}
                prevPage={-1}
              />
            )}
          </div>
        </div>
      </div>
      {/*  Show loading state */}
      {(mergeLoading || mergedRestaurantsLoading) && <GifLoader />}
      {/*  Merge confirmation popup */}
      <Modal
        show={MergeConfirm}
        onHide={() => {
          MergeConfirmPop(false);
          setConfirmText('');
        }}
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <p>
            <FormattedMessage id="Restaurant.Merge.Text" />
          </p>
          <Row>
            <Col className="no-label-input">
              <InputLabel id="test" name="sku_id" type="text" value={confirmText} onChange={(e) => handleConfirmTextChange(e)} />
            </Col>
            <Col sm="auto">
              <Button variant="primary" onClick={() => handleMergeConfirm()} disabled={confirmText !== 'Yes, I understand'}>
                <FormattedMessage id="Button.OK" />
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {/*  Merge popup */}
      <Modal show={MergeRest} onHide={() => MergeRestPop(false)} centered>
        <Modal.Body>
          <FormattedMessage id="Restaurant.MergePopContent" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => MergeRestPop(false)}>
            <FormattedMessage id="Button.OK" />
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Merge status popup  */}
      <Modal show={mergeStatusPopUp && mergeResult.length > 0} onHide={() => setMergeStatusPopUp(false)} centered size="lg">
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="MergeStatus.Table.Heading" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table responsive>
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="MergeStatus.Source" />
                </th>
                <th>
                  <FormattedMessage id="MergeStatus.Target" />
                </th>
                <th>
                  <FormattedMessage id="MergeStatus.Status" />
                </th>
              </tr>
            </thead>
            <tbody>
              {mergeResult.map((rest: IMergeStatusRow) => (
                <tr>
                  <td>{rest.source}</td>
                  <td>{rest.target}</td>
                  <td>{IRestaurantMergeStatus[rest.status]}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => closeMergeStatusPop()}>
            <FormattedMessage id="Button.Ok" />
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Merged restaurants list popup  */}
      <Modal show={mergedRestaurantsPopUp && mergedRestaurantsErrorCode === 0 && mergedRestaurantsSuccess} onHide={() => setMergedRestaurantsPopUp(false)} centered size="xl">
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="MergedRestaurants.Table.Heading" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table responsive>
            <thead>
              <tr>
                <th className="w-220">
                  <FormattedMessage id="MergedRestaurants.Name" />
                </th>
                <th className="w-220">
                  <FormattedMessage id="MergedRestaurants.Address" />
                </th>
                <th>
                  <FormattedMessage id="MergedRestaurants.Createdby" />
                </th>
                <th className="w-220">
                  <FormattedMessage id="MergedRestaurants.VendorName" />
                </th>
                <th>
                  <FormattedMessage id="MergedRestaurants.Email" />
                </th>
              </tr>
            </thead>
            <tbody>
              {mergedRestaurantsData.length > 0 &&
                mergedRestaurantsData.map((res: IMergedRestaurantsData) => (
                  <tr>
                    <td>{res.restaurantName}</td>
                    <td>{`${res.city}, ${res.state}, ${res.zip}`}</td>
                    <td>
                      {res.isAddedFromAdminPortal && !res.isLoggedIn
                        ? useIntlMessages('SignupStatus.AdminCreated')
                        : res.signupStatus === IRestaurantSignupStatus.SIGNED_UP || (res.isAddedFromAdminPortal && res.isLoggedIn)
                        ? useIntlMessages('SignupStatus.Signedup')
                        : useIntlMessages('SignupStatus.Local')}
                    </td>
                    <td>{res.vendorName || '-'}</td>
                    <td>{res.email}</td>
                  </tr>
                ))}
              {mergedRestaurantsData.length <= 0 && (
                <tr>
                  <FormattedMessage id="MergedRestaurants.No.Data.Found" />
                </tr>
              )}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setMergedRestaurantsPopUp(false)}>
            <FormattedMessage id="Button.Ok" />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Merge;
