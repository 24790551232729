/**
 * @file   src\features\vendor\Edit.tsx
 * @brief  Vendor edit page.
 * @date   June, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { useEffect, useState, FormattedMessage, Col, Row, Breadcrumb, Button, Modal, useRef, useNavigate, useParams } from '../../utils/thirdpartyComponents';
import { stringFormat, useIntlActionMessages, useIntlMessages } from '../../utils/helper';
import InputLabel from '../../components/InputLabel';
import InputMaskLabel from '../../components/InputMaskLabel';
import BackIcon from '../../assets/icons/Back';
import LocationSearch from '../../components/LocationSearch';
import SearchIcon from '../../assets/icons/Search';
import SearchRemoveIcon from '../../assets/icons/SearchRemove';
import { IVendorAddEdit, IVendorAddEditRequest, IvendorActivateRequest } from '../../interfaces/vendorInterface';
import { DEFAULT_VENDOR_PRICE_TIER, INPUT_MAX_LENGTH_100 } from '../../utils/constants';
import { VENDOR_ADD_EDIT_FORM_SCHEMA, VENDOR_ACTIVATE_DEACTIVATE_FORM_SCHEMA } from '../../validations/vendorSchema';
import { validateForm } from '../../utils/formValidation';
import { getPlans } from '../../store/actions/commonAction';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { RootState } from '../../store';
import { IPlan, ISelectOptions } from '../../interfaces/generalInterface';
import { vendorDetails, saveVendor, updateVendorActivateDeactivateStatus } from '../../store/actions/vendorAction';
import { CountryCodes, ActivateDeactivateText } from '../../utils/enums';
import { MessageToaster } from '../../utils/toastUtil';
import GifLoader from '../../components/GifLoader';
import { resetVendorState } from '../../store/slices/vendorSlice';

// Vendor component form default state.
const vendorInitialData: IVendorAddEdit = {
  vendorName: '',
  address: '',
  zip: '',
  city: '',
  state: '',
  stateCode: '',
  businessEmail: '',
  businessPhone: '',
  pricingTiers: DEFAULT_VENDOR_PRICE_TIER,
  tierOptions: DEFAULT_VENDOR_PRICE_TIER,
  selectedTier: 0,
  location: {},
  locationChange: false,
  formattedAddress: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
};

// Toast object creation.
const toast = new MessageToaster();

const VendorAdd = () => {
  // Create route param object to access the route parameters.
  const params = useParams();
  // Navigate object creation.
  const navigate = useNavigate();
  // Declare action dispatch.
  const dispatch = useAppDispatch();
  // Declare ref variable for error fields.
  const errRef = useRef<any>(null);

  // Localization hook call.
  const activeTextValidationError = useIntlMessages('Vendor.Activate.Form.Text.Error');

  // Access redux state variables.
  const { isSuccess, plans } = useAppSelector((state: RootState) => state.common);
  const {
    isLoading,
    isSuccess: vendorFetchSuccess,
    vendors,
    saveVendorLoading,
    saveVendorSuccess,
    isActivateLoading,
    isActivateSuccess,
    errorCode,
    errorMessage,
  } = useAppSelector((state: RootState) => state.vendor);

  // Initialize component state variables
  const [vendorInfo, setVendorInfo] = useState<IVendorAddEdit>(vendorInitialData);
  const [oldVendorInfo, setOldVendorInfo] = useState<IVendorAddEdit>(vendorInitialData);
  const [vendorId, setVendorId] = useState<number>(0);
  const [vendorGuId, setVendorGuId] = useState<string>('');
  const [vendorName, setVendorName] = useState<string>('');
  const [errorFields, setErrorFields] = useState<any>({});
  const [deActivate, deActivatePop] = useState(false);
  const [deActivated, deActivatedPop] = useState(false);
  const [activate, activatePop] = useState(false);
  const [activated, activatedPop] = useState(false);
  const [isActive, setActiveStatus] = useState<boolean>(false);
  const [activateText, setActivateText] = useState<string>('');
  const [activateErrorField, setActivateErrorField] = useState<any>({});

  // Handle initial loading sideEffects.
  useEffect(() => {
    /* Access and set the location url parameters. */
    const vId: number = params.vendorId ? Number(params.vendorId) : 0;
    dispatch(getPlans());
    dispatch(vendorDetails(vId));
    return () => {
      dispatch(resetVendorState());
    };
  }, [dispatch]);

  // Clear add form fields.
  const clearForm = () => {
    setVendorInfo((info) => ({
      ...info,
      vendorName: '',
      address: '',
      zip: '',
      city: '',
      state: '',
      stateCode: '',
      businessEmail: '',
      businessPhone: '',
      pricingTiers: DEFAULT_VENDOR_PRICE_TIER,
      tierOptions: DEFAULT_VENDOR_PRICE_TIER,
      selectedTier: 0,
      location: {},
      locationChange: false,
      formattedAddress: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    }));
  };

  // Update vendor api response state change.
  useEffect(() => {
    if (saveVendorSuccess && errorCode === 0 && errorMessage) {
      clearForm();
      toast.toastSuccess(errorMessage);
      navigate(`/vendors/details/${vendorId}`);
    } else if (errorCode > 0 && errorMessage) {
      toast.toastError(errorMessage);
      dispatch(resetVendorState());
    }
  }, [saveVendorSuccess, errorCode]);

  // Map plans to select tier options.
  const mapTiers = (input: IPlan): ISelectOptions => {
    return {
      label: input.planName,
      value: input.planId,
    };
  };

  // Handle fetch plans result state change.
  useEffect(() => {
    if (isSuccess) {
      const selectedPlans = plans.map((m) => mapTiers(m));
      selectedPlans.unshift(DEFAULT_VENDOR_PRICE_TIER[0]);
      setVendorInfo((info) => ({
        ...info,
        tierOptions: selectedPlans,
      }));
      /* Keep previous vendor form data to compare and enable/disable save button */
      setOldVendorInfo((oldInfo) => ({
        ...oldInfo,
        tierOptions: selectedPlans,
      }));
    }
  }, [isSuccess, plans]);

  // Activate, deactivate vendor api response state change.
  useEffect(() => {
    if (isActivateSuccess && errorCode === 0 && errorMessage) {
      setActiveStatus(!isActive);
      setActivateText('');
      setActivateErrorField({});
      activatePop(false);
      activatedPop(false);
      deActivatePop(false);
      deActivatedPop(false);
      toast.toastSuccess(errorMessage);
      navigate(`/vendors/details/${vendorId}`);
    } else if (errorCode > 0 && errorMessage) {
      toast.toastError(errorMessage);
    }
  }, [isActivateSuccess, errorCode]);

  // Set vendor details to default state.
  const setVendorDetails = () => {
    if (vendors[0]) {
      setVendorId(vendors[0].vendorId);
      setVendorName(vendors[0]?.vendorName);
      setVendorGuId(vendors[0].vendorGuid);
      setActiveStatus(vendors[0].isActive);
      /* Keep previous vendor form data to compare and enable/disable save button */
      const existingVendorInfo = { ...vendorInfo };
      existingVendorInfo.vendorName = vendors[0].vendorName;
      existingVendorInfo.address = vendors[0].address;
      existingVendorInfo.zip = vendors[0].zip;
      existingVendorInfo.city = vendors[0].city;
      existingVendorInfo.state = vendors[0].state;
      existingVendorInfo.stateCode = vendors[0].stateCode;
      existingVendorInfo.businessEmail = vendors[0].email;
      existingVendorInfo.businessPhone = vendors[0].phone;
      existingVendorInfo.pricingTiers = vendors[0].subscriptionPlanId ? [{ label: vendors[0].planName, value: vendors[0].subscriptionPlanId }] : DEFAULT_VENDOR_PRICE_TIER;
      existingVendorInfo.selectedTier = vendors[0].subscriptionPlanId || 0;
      existingVendorInfo.locationChange = false;
      existingVendorInfo.formattedAddress = vendors[0].address;
      existingVendorInfo.firstName = vendors[0].adminFirstName;
      existingVendorInfo.lastName = vendors[0].adminLastName;
      existingVendorInfo.email = vendors[0].adminEmail;
      existingVendorInfo.phone = vendors[0].adminPhone;
      existingVendorInfo.location = {
        lat: vendors[0].latitude,
        lng: vendors[0].longitude,
        address: vendors[0].address,
        city: vendors[0].city,
        state: vendors[0].state,
        stateCode: vendors[0].stateCode,
        zip: vendors[0].zip,
        name: vendors[0].vendorName,
        locationName: vendors[0].vendorName,
      };
      setVendorInfo((info) => ({
        ...info,
        vendorName: vendors[0].vendorName,
        address: vendors[0].address,
        zip: vendors[0].zip,
        city: vendors[0].city,
        state: vendors[0].state,
        stateCode: vendors[0].stateCode,
        businessEmail: vendors[0].email,
        businessPhone: vendors[0].phone,
        pricingTiers: vendors[0].subscriptionPlanId ? [{ label: vendors[0].planName, value: vendors[0].subscriptionPlanId }] : DEFAULT_VENDOR_PRICE_TIER,
        selectedTier: vendors[0].subscriptionPlanId || 0,
        locationChange: false,
        formattedAddress: vendors[0].address,
        firstName: vendors[0].adminFirstName,
        lastName: vendors[0].adminLastName,
        email: vendors[0].adminEmail,
        phone: vendors[0].adminPhone,
        location: {
          lat: vendors[0].latitude,
          lng: vendors[0].longitude,
          address: vendors[0].address,
          city: vendors[0].city,
          state: vendors[0].state,
          stateCode: vendors[0].stateCode,
          zip: vendors[0].zip,
          name: vendors[0].vendorName,
          locationName: vendors[0].vendorName,
        },
      }));
      setOldVendorInfo(existingVendorInfo);
    }
  };

  // Bind vendor data on details result state change.
  useEffect(() => {
    if (vendorFetchSuccess && errorCode === 0) {
      setVendorDetails();
    }
  }, [vendorFetchSuccess]);

  // Clear location button click event.
  const onRemoveBtnClick = () => {
    setVendorInfo((info) => ({
      ...info,
      location: {},
      address: '',
      city: '',
      zip: '',
      state: '',
      stateCode: '',
      formattedAddress: '',
      locationChange: false,
    }));
  };

  // Location select event. This method used to update the location info to the location object.
  const onLocationSelect = async (place: any) => {
    setVendorInfo((info) => ({
      ...info,
      location: {
        lat: place.lat,
        lng: place.lng,
        address: place.address,
        city: place.city,
        state: place.state,
        stateCode: place.stateCode,
        zip: place.zip,
        name: place.name,
        locationName: place.name,
      },
      address: place.address,
      formattedAddress: place.address,
      city: place.city,
      zip: place.zip,
      state: place.state,
      stateCode: place.stateCode,
    }));
    const locationInfo = {
      address: place.address,
      city: place.city,
      zip: place.zip,
      state: place.state,
      stateCode: place.stateCode,
    };
    const errorresult = await validateForm(locationInfo, VENDOR_ADD_EDIT_FORM_SCHEMA, errRef.current);
    setErrorFields(errorresult);
  };

  // Location change event.
  const onLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const errorObj = { ...errorFields };
    setVendorInfo((info) => ({
      ...info,
      locationChange: value !== '',
      location: {},
      address: value,
      formattedAddress: value,
      city: '',
      zip: '',
      state: '',
      stateCode: '',
    }));
    errRef.current = errorObj;
  };

  // Input field change event.
  const onInputHandleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setVendorInfo((info) => ({
      ...info,
      [name]: value,
    }));
    const validateObj = {
      [name]: value?.trim() || '',
    };
    const errorresult = await validateForm(validateObj, VENDOR_ADD_EDIT_FORM_SCHEMA, errorFields);
    setErrorFields(errorresult);
  };

  // Phone input field change event.
  const onPhoneFieldHandleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const inputVal = value ? value.replace(/\D/g, '') : '';
    setVendorInfo((info) => ({
      ...info,
      [name]: inputVal,
    }));
    const validateObj = {
      [name]: inputVal?.trim() || '',
    };
    const errorresult = await validateForm(validateObj, VENDOR_ADD_EDIT_FORM_SCHEMA, errorFields);
    setErrorFields(errorresult);
  };

  // Select input change event.
  /* const onSelectChange = async (name: string, values: any, selectedFieldName: string) => {
    const fieldName: string = selectedFieldName;
    setVendorInfo((info) => ({
      ...info,
      [name]: values,
      [fieldName]: values[0]?.value,
    }));
    const validateObj = {
      [name]: values,
      [fieldName]: values[0]?.value,
    };
    const errorresult = await validateForm(validateObj, VENDOR_ADD_EDIT_FORM_SCHEMA, errorFields);
    setErrorFields(errorresult);
  }; */

  // Submit handler to update modified vendor information.
  const onSubmit = async () => {
    const vendorValidationRequest = {
      vendorName: vendorInfo.vendorName,
      address: vendorInfo.address,
      zip: vendorInfo.zip,
      city: vendorInfo.city,
      state: vendorInfo.state,
      businessEmail: vendorInfo.businessEmail,
      businessPhone: vendorInfo.businessPhone,
      firstName: vendorInfo.firstName,
      lastName: vendorInfo.lastName,
      email: vendorInfo.email,
      phone: vendorInfo.phone,
    };
    const errorresult = await validateForm(vendorValidationRequest, VENDOR_ADD_EDIT_FORM_SCHEMA, errorFields);
    setErrorFields(errorresult);
    if (Object.keys(errorresult).length === 0) {
      if (Object.keys(vendorInfo.location).length > 0 && vendorInfo.location?.stateCode !== '') {
        /* Check admin firstName, lastName, phone modified or not. */
        const isAdminFirstNameModified = vendors[0] && vendors[0].adminFirstName?.trim() !== vendorInfo.firstName.trim();
        const isAdminLastNameModified = vendors[0] && vendors[0].adminLastName?.trim() !== vendorInfo.lastName.trim();
        const isPhoneModified = vendors[0] && vendors[0].adminPhone?.trim() !== vendorInfo.phone.trim();
        const saveVendorRequest: IVendorAddEditRequest = {
          vendorId,
          vendorName: vendorInfo.vendorName?.trim() || '',
          address: vendorInfo.address?.trim() || '',
          zip: vendorInfo.zip?.trim() || '',
          city: vendorInfo.city?.trim() || '',
          state: vendorInfo.state?.trim() || '',
          vendorEmail: vendorInfo.businessEmail?.trim() || '',
          phone: vendorInfo.businessPhone?.trim() || '',
          countryCode: CountryCodes.US,
          subscriptionPlanId: vendorInfo.selectedTier,
          adminFirstName: vendorInfo.firstName?.trim() || '',
          adminLastName: vendorInfo.lastName?.trim() || '',
          adminEmail: vendorInfo.email?.trim() || '',
          adminPhone: vendorInfo.phone?.trim() || '',
          adminCountryCode: CountryCodes.US,
          stateCode: vendorInfo.stateCode || '',
          latitude: Number(vendorInfo.location?.lat),
          longitude: Number(vendorInfo.location?.lng),
          isAdminFirstNameModified: Number(isAdminFirstNameModified),
          isAdminLastNameModified: Number(isAdminLastNameModified),
          isPhoneModified: Number(isPhoneModified),
        };
        dispatch(saveVendor(saveVendorRequest));
      } else {
        toast.toastError(useIntlActionMessages('Vendor.Autocomplete.Select.Error'));
      }
    } else {
      setVendorInfo((info) => ({
        ...info,
        locationChange: false,
      }));
      toast.toastError(useIntlActionMessages('Enter.Mandatory'));
    }
  };

  // Activate/deactivate input field change event.
  const onActivateInputHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setActivateText(value);
    const validateObj = {
      activateText: value,
    };
    const errorresult = await validateForm(validateObj, VENDOR_ACTIVATE_DEACTIVATE_FORM_SCHEMA, errorFields);
    setErrorFields(errorresult);
  };

  // Cancel deactivate popup handler
  const onCancelDeactivatePopup = () => {
    deActivatePop(false);
    setActivateText('');
  };

  // Cancel activate popup handler
  const onCancelActivatePopup = () => {
    activatePop(false);
    setActivateText('');
  };

  // Aactivate, deactivate vendor submit handler.
  const onActivateDeactivateHandler = async (isActivate: boolean) => {
    const validateObj = {
      activateText,
    };
    const errorresult = await validateForm(validateObj, VENDOR_ACTIVATE_DEACTIVATE_FORM_SCHEMA, errorFields);
    setActivateErrorField(errorresult);
    if (Object.keys(errorresult).length === 0) {
      if (
        (isActivate && activateText.trim().toLowerCase() === ActivateDeactivateText.ACTIVATE) ||
        (!isActivate && activateText.trim().toLowerCase() === ActivateDeactivateText.DEACTIVATE)
      ) {
        const vendorActivateRequest: IvendorActivateRequest = {
          vendorGuid: vendorGuId,
          activeStatus: Object.keys(ActivateDeactivateText).indexOf(activateText.toUpperCase()),
        };
        activatedPop(false);
        deActivatedPop(false);
        dispatch(updateVendorActivateDeactivateStatus(vendorActivateRequest));
      } else {
        setActivateErrorField({ activateText: activeTextValidationError });
      }
    }
  };
  return (
    <div className="content-nav contnt-container-margin">
      <div className="back-navigation">
        <Breadcrumb>
          <Breadcrumb.Item href="#" onClick={() => navigate('/home')}>
            <FormattedMessage id="Menu.Home" />
          </Breadcrumb.Item>
          <Breadcrumb.Item href="#" onClick={() => navigate('/vendors')}>
            <FormattedMessage id="Vendor.PageHD" />
          </Breadcrumb.Item>
          <Breadcrumb.Item href="#" onClick={() => navigate(`/vendors/details/${vendorId}`)}>
            {vendorName}
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
            <FormattedMessage id="Vendor.Edit.Heading" />
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="content-nav-sub">
        <div className="content-nav-header d-flex justify-content-between align-items-center">
          <h2>
            <a href="#" className="btn-icon" onClick={() => navigate(-1)}>
              <BackIcon />
            </a>
            <FormattedMessage id="Button.Edit" />
            {` ${vendorName}`}
          </h2>
        </div>
        <div className="content-container-padding">
          <h6>
            <FormattedMessage id="HD.BasicInformation" />
          </h6>
          <Row className="form-main">
            <Col lg="5">
              <InputLabel
                id="vendorName"
                name="vendorName"
                type="text"
                label={useIntlMessages('Vendor.VendorName')}
                placeholder={useIntlMessages('Vendor.Placeholder.Vendorname')}
                value={vendorInfo.vendorName}
                maxLength={INPUT_MAX_LENGTH_100}
                isMandatory
                onChange={(e) => onInputHandleChange(e)}
                errorMessage={errorFields?.vendorName}
              />
            </Col>
            <Col lg="5">
              <LocationSearch
                label={useIntlMessages('Vendor.Address')}
                id="address"
                name="address"
                types={['geocode', 'establishment']}
                placeholder={useIntlMessages('Vendor.Placeholder.Address')}
                values={vendorInfo.location}
                address={vendorInfo.formattedAddress ? vendorInfo.formattedAddress : ''}
                locationChange={vendorInfo.locationChange ? vendorInfo.locationChange : false}
                icon={<SearchIcon />}
                isMandatory
                closeicon={<SearchRemoveIcon onClick={() => onRemoveBtnClick()} value={vendorInfo.formattedAddress} />}
                onLocationSelect={(place: any) => onLocationSelect(place)}
                onChange={(e) => onLocationChange(e)}
                errorMessage={errorFields?.address}
              />
            </Col>
            <Col lg="5">
              <InputLabel
                id="city"
                name="city"
                type="text"
                label={useIntlMessages('Add.City')}
                placeholder={useIntlMessages('Vendor.Placeholder.City')}
                value={vendorInfo.city}
                maxLength={INPUT_MAX_LENGTH_100}
                isMandatory
                onChange={(e) => onInputHandleChange(e)}
                errorMessage={errorFields?.city}
                disabled
              />
            </Col>
            <Col lg="5">
              <InputLabel
                id="state"
                name="state"
                type="text"
                label={useIntlMessages('Add.State')}
                placeholder={useIntlMessages('Vendor.Placeholder.State')}
                value={vendorInfo.state}
                maxLength={INPUT_MAX_LENGTH_100}
                isMandatory
                onChange={(e) => onInputHandleChange(e)}
                errorMessage={errorFields?.state}
                disabled
              />
            </Col>
            <Col lg="5">
              <InputLabel
                id="zip"
                name="zip"
                type="text"
                label={useIntlMessages('Add.ZipCode')}
                placeholder={useIntlMessages('Vendor.Placeholder.Zip')}
                value={vendorInfo.zip}
                maxLength={10}
                isMandatory
                onChange={(e) => onInputHandleChange(e)}
                errorMessage={errorFields?.zip}
              />
            </Col>
            <Col lg="5">
              <InputMaskLabel
                id="businessPhone"
                name="businessPhone"
                type="phone"
                mask="(999) 999-9999"
                label={useIntlMessages('Add.BusinessPhone')}
                placeholder={useIntlMessages('Vendor.Placeholder.BusinessPhone')}
                value={vendorInfo.businessPhone}
                maxLength={20}
                isMandatory
                onChange={(e) => onPhoneFieldHandleChange(e)}
                errorMessage={errorFields?.businessPhone}
              />
            </Col>
            <Col lg="5">
              <InputLabel
                id="businessEmail"
                name="businessEmail"
                type="text"
                label={useIntlMessages('Add.BusinessEmail')}
                placeholder={useIntlMessages('Vendor.Placeholder.BusinessEmail')}
                value={vendorInfo.businessEmail}
                maxLength={INPUT_MAX_LENGTH_100}
                isMandatory
                onChange={(e) => onInputHandleChange(e)}
                disabled
                errorMessage={errorFields?.businessEmail}
              />
            </Col>
            {/* <Col lg={5}>
              <Select
                id="pricingTiers"
                name="pricingTiers"
                type="select"
                options={vendorInfo.tierOptions}
                dropdownGap={0}
                values={vendorInfo.pricingTiers}
                label={useIntlMessages('Add.PricingTyre')}
                placeholder={useIntlMessages('Add.PricingTyre')}
                dropdownPosition="auto"
                isMandatory
                errorMessage={errorFields?.selectedTier}
                className=""
                onChange={(values) => onSelectChange('pricingTiers', values, 'selectedTier')}
              />
            </Col> */}
          </Row>
          <h6>
            <FormattedMessage id="HD.AdminInformation" />
          </h6>
          <Row className="form-main">
            <Col lg="5">
              <InputLabel
                id="firstName"
                name="firstName"
                type="text"
                label={useIntlMessages('Add.FirstName')}
                placeholder={useIntlMessages('Vendor.Placeholder.Firstname')}
                value={vendorInfo.firstName}
                maxLength={INPUT_MAX_LENGTH_100}
                isMandatory
                onChange={(e) => onInputHandleChange(e)}
                errorMessage={errorFields?.firstName}
              />
            </Col>
            <Col lg="5">
              <InputLabel
                id="lastName"
                name="lastName"
                type="text"
                label={useIntlMessages('Add.LastName')}
                placeholder={useIntlMessages('Vendor.Placeholder.Lastname')}
                value={vendorInfo.lastName}
                maxLength={INPUT_MAX_LENGTH_100}
                isMandatory
                onChange={(e) => onInputHandleChange(e)}
                errorMessage={errorFields?.lastName}
              />
            </Col>
            <Col lg="5">
              <InputLabel
                id="email"
                name="email"
                type="text"
                label={useIntlMessages('Add.Email')}
                placeholder={useIntlMessages('Vendor.Placeholder.Email')}
                value={vendorInfo.email}
                maxLength={INPUT_MAX_LENGTH_100}
                isMandatory
                disabled
                onChange={(e) => onInputHandleChange(e)}
                errorMessage={errorFields?.email}
              />
            </Col>
            <Col lg="5">
              <InputMaskLabel
                id="phone"
                name="phone"
                type="phone"
                mask="(999) 999-9999"
                label={useIntlMessages('Add.Phone')}
                placeholder={useIntlMessages('Vendor.Placeholder.Phone')}
                value={vendorInfo.phone}
                maxLength={20}
                isMandatory
                onChange={(e) => onPhoneFieldHandleChange(e)}
                errorMessage={errorFields?.phone}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} className="mt-4">
              {isActive && (
                <Button variant="primary" className="btn-red" onClick={() => deActivatePop(true)}>
                  <FormattedMessage id="Button.Deactivate" />
                </Button>
              )}
              {!isActive && (
                <Button variant="primary" className="btn-green" onClick={() => activatePop(true)}>
                  <FormattedMessage id="Button.Activate" />
                </Button>
              )}
            </Col>
            <Col lg={5} className="text-end mt-4">
              <Button variant="primary" onClick={onSubmit} disabled={JSON.stringify(vendorInfo) === JSON.stringify(oldVendorInfo) || Object.keys(errorFields).length > 0}>
                <FormattedMessage id="Button.Save" />
              </Button>
            </Col>
          </Row>
        </div>
        {(isLoading || saveVendorLoading || isActivateLoading) && <GifLoader />}
      </div>
      {/*  deActivate popup */}
      <Modal show={deActivate} onHide={() => deActivatePop(false)} centered size="lg" className="mx-w-725">
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-sm">{stringFormat(useIntlActionMessages('Vendor.Deactivate.HD'), vendorInfo.vendorName)}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-3">
          <p>{stringFormat(useIntlActionMessages('Vendor.Deactivate.Paragraph'), vendorInfo.vendorName)}</p>
          <Row className="d-flex justify-content-center">
            <p className="text-center mb-2">
              <FormattedMessage id="Vendor.Popup.InputLabel" />{' '}
              <i>
                <FormattedMessage id="Vendor.Deactivate.Deactivate" />
              </i>
            </p>
            <Col xl={4}>
              <div className="form-sm">
                <InputLabel
                  id="activateText"
                  name="activateText"
                  type="text"
                  value={activateText}
                  onChange={(e) => onActivateInputHandler(e)}
                  errorMessage={activateErrorField?.activateText}
                />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" className="btn-modal-footer" onClick={onCancelDeactivatePopup}>
            <FormattedMessage id="Button.Cancelthis" />
          </Button>
          <Button variant="primary" onClick={() => onActivateDeactivateHandler(false)} disabled={activateText.trim() !== ActivateDeactivateText.DEACTIVATE}>
            <FormattedMessage id="Button.Deactivate" />
          </Button>
        </Modal.Footer>
      </Modal>

      {/*  deActivated Confirmation popup */}
      <Modal show={deActivated} onHide={() => deActivatedPop(false)} centered className="mx-w-350">
        <Modal.Body>
          <p>
            <FormattedMessage id="Vendor.Deactivated.Text" />
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary">
            <FormattedMessage id="Button.OK" />
          </Button>
        </Modal.Footer>
      </Modal>

      {/*  Activate popup */}
      <Modal show={activate} onHide={() => activatePop(false)} centered size="lg" className="mx-w-725">
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-sm">{stringFormat(useIntlActionMessages('Vendor.Activate.HD'), vendorInfo.vendorName)}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-3">
          <p>{stringFormat(useIntlActionMessages('Vendor.Activate.Paragraph'), vendorInfo.vendorName)}</p>
          <Row className="d-flex justify-content-center">
            <p className="text-center mb-2">
              <FormattedMessage id="Vendor.Popup.InputLabel" />{' '}
              <i>
                <FormattedMessage id="Vendor.Activate.Activate" />
              </i>
            </p>
            <Col xl={4}>
              <div className="form-sm">
                <InputLabel
                  id="activateText"
                  name="activateText"
                  type="text"
                  value={activateText}
                  onChange={(e) => onActivateInputHandler(e)}
                  errorMessage={activateErrorField?.activateText}
                />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" className="btn-modal-footer" onClick={onCancelActivatePopup}>
            <FormattedMessage id="Button.Cancelthis" />
          </Button>
          <Button variant="primary" onClick={() => onActivateDeactivateHandler(true)} disabled={activateText.trim() !== ActivateDeactivateText.ACTIVATE}>
            <FormattedMessage id="Button.Activate" />
          </Button>
        </Modal.Footer>
      </Modal>

      {/*  Activated Confirmation popup */}
      <Modal show={activated} onHide={() => activatedPop(false)} centered className="mx-w-350">
        <Modal.Body>
          <p>
            <FormattedMessage id="Vendor.Activated.Text" />
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary">
            <FormattedMessage id="Button.OK" />
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default VendorAdd;
