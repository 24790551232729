import React from 'react';

const MenuUser = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.9982 15.582C14.355 15.582 17.2911 17.0258 18.888 19.1799L17.1995 19.9784C15.8996 18.4384 13.608 17.4154 10.9982 17.4154C8.38846 17.4154 6.09679 18.4384 4.79696 19.9784L3.10938 19.179C4.70621 17.0249 7.64137 15.582 10.9982 15.582ZM10.9982 1.83203C12.2138 1.83203 13.3796 2.31492 14.2391 3.17446C15.0987 4.034 15.5815 5.19979 15.5815 6.41536V9.16536C15.5815 10.3465 15.1255 11.4819 14.3086 12.335C13.4917 13.1881 12.3771 13.6929 11.1971 13.7441L10.9982 13.7487C9.78263 13.7487 8.61684 13.2658 7.7573 12.4063C6.89776 11.5467 6.41487 10.3809 6.41487 9.16536V6.41536C6.41494 5.23427 6.87095 4.0988 7.68781 3.24574C8.50467 2.39267 9.61931 1.88787 10.7993 1.83661L10.9982 1.83203ZM10.9982 3.66536C10.2968 3.66533 9.62182 3.93333 9.11147 4.41454C8.60112 4.89575 8.29394 5.5538 8.25279 6.25403L8.24821 6.41536V9.16536C8.24752 9.88119 8.52598 10.5691 9.02442 11.0829C9.52285 11.5966 10.202 11.8958 10.9175 11.9168C11.633 11.9378 12.3285 11.679 12.8563 11.1954C13.384 10.7117 13.7023 10.0413 13.7436 9.3267L13.7482 9.16536V6.41536C13.7482 5.68602 13.4585 4.98655 12.9428 4.47082C12.427 3.9551 11.7276 3.66536 10.9982 3.66536Z"
        fill="#63707E"
      />
    </svg>
  );
};

export default MenuUser;
