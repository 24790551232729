/**
 * @file   src\store\index.ts
 * @brief  This file is responsible for configuring the redux store.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import userReducer from './slices/userSlice';
import vendorReducer from './slices/vendorSlice';
import loaderReducer from './slices/loaderSlice';
import commonReducer from './slices/commonSlice';
import restaurantReducer from './slices/restaurantSlice';
import productReducer from './slices/productSlice';
import roleReducer from './slices/roleSlice';
import subscriptionReducer from './slices/subscriptionSlice';
import dashboardReducer from './slices/dashboardSlice';
import onboardingReducer from './slices/onboardingSlice';
import rolePermissionReducer from './slices/rolepermissionSlice';
import settingsReducer from './slices/settingsSlice';

export const store = configureStore({
  reducer: {
    // Add reducer slices.
    auth: authReducer,
    user: userReducer,
    vendor: vendorReducer,
    loader: loaderReducer,
    common: commonReducer,
    restaurant: restaurantReducer,
    product: productReducer,
    role: roleReducer,
    subscription: subscriptionReducer,
    dashboard: dashboardReducer,
    onboarding: onboardingReducer,
    roleperm: rolePermissionReducer,
    settings: settingsReducer,
  },
  // Adding the api middlewares enables caching , invalidation, polling and other useful features of rtk query.
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

// Infer the `RootState` and `AppDispatch` types from the store itself.
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
