/**
 * @file   src\features\restaurant\List.tsx
 * @brief  Restaurant list page.
 * @date   May, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import {
  useState,
  Link,
  Col,
  Row,
  Breadcrumb,
  Table,
  Dropdown,
  Modal,
  Button,
  FormattedMessage,
  useEffect,
  moment,
  useRef,
  createRef,
  Typeahead,
  useNavigate,
} from '../../utils/thirdpartyComponents';
import { stringFormat, useIntlMessages, formatPhoneNumber, getRestaurantAddress, useIntlActionMessages } from '../../utils/helper';
import Select from '../../components/Select';
import SortIcon from '../../assets/icons/Sort';
import Search from '../../assets/icons/Search';
import Add from '../../assets/icons/Add';
import EmailInvitation from '../../assets/icons/EmailInvitation';
import InputCheck from '../../components/ChecBoxLabel';
import InputLabel from '../../components/InputLabel';
import { inviteRestaurants, restaurantList, searchRestaurants } from '../../store/actions/restaurantAction';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { RootState } from '../../store';
import { IRestaurantInviteRequest, IRestaurantRequest, IRestaurantData } from '../../interfaces/restaurantInterface';
import Pagination from '../../components/Paginate';
import GifLoader from '../../components/GifLoader';
import { ISelectOptions } from '../../interfaces/generalInterface';
import {
  DEFAULT_RESTAURANT_SUBSCRIPTION,
  RESTAURANT_SUBSCRIPTION,
  ENTER_KEY,
  PAGE_SIZE,
  SEARCH_MIN_CHAR_LENGTH,
  DEFAULT_SORT_FIELD,
  DEFAULT_PAGE_INDEX,
  DEFAULT_DEACTIVATE_STATUS,
  INPUT_MAX_LENGTH_200,
  INPUT_MAX_LENGTH_100,
  DATE_FORMAT,
} from '../../utils/constants';
import UpArrow from '../../assets/icons/UpArrow';
import DownArrow from '../../assets/icons/DownArrow';
import UpDownArrow from '../../assets/icons/UpDownArrow';
import { SortOrderType, RestaurantSortField } from '../../utils/enums';
import { validateForm } from '../../utils/formValidation';
import { INVITE_RESTAURANT_SCHEMA } from '../../validations/restaurantSchema';
import { MessageToaster } from '../../utils/toastUtil';
import { resetRestaurantState } from '../../store/slices/restaurantSlice';
import { resendInvitation } from '../../store/actions/userAction';
import { resetUserInvite } from '../../store/slices/userSlice';

// Toast object creation.
const toast = new MessageToaster();

// Declare page item count to be displayed per page.
const pageItemCount: number = process.env.REACT_APP_LIST_PAGE_SIZE !== '' ? Number(process.env.REACT_APP_LIST_PAGE_SIZE) : PAGE_SIZE;
const RestaurantList = () => {
  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Navigation object creation
  const navigate = useNavigate();

  // Access redux state variables.
  const { isLoading, totalCount, restaurants, inviteRestaurantLoading, inviteRestaurantSuccess, errorCode, errorMessage, searchRestaurantList } = useAppSelector(
    (state: RootState) => state.restaurant,
  );
  const { resendInvitationLoading, resendInvitationErrorMessage, resendInvitationSuccess, resendInvitationErrorCode } = useAppSelector((state: RootState) => state.user);
  // Initialize search field ref.
  const oldSearchText = useRef<string>('');

  // Create a ref on search input.
  const inputRef = createRef<any>();

  // Initialize component state variables.
  const [searchField, setSearchField] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const [typeField, setTypeField] = useState<string>('');
  const [subscriptionPlanId, setSubscriptionPlanId] = useState<number>(0);
  const [showDeactivateOnly, setDeactivatedOnly] = useState<number>(DEFAULT_DEACTIVATE_STATUS);
  const [pageSize] = useState<number>(pageItemCount);
  const [pageIndex, setPageIndex] = useState<number>(DEFAULT_PAGE_INDEX);
  const [sortField, setSortField] = useState<number>(DEFAULT_SORT_FIELD);
  const [sortOrder, setSortOrder] = useState<number>(SortOrderType.ASC);
  const [emailInvite, setEmailInvitePop] = useState<boolean>(false);
  const [emailSent, setEmailSentPop] = useState<boolean>(false);
  const [planList] = useState<ISelectOptions[]>(RESTAURANT_SUBSCRIPTION);
  const [selectedPlan, setSelectedPlan] = useState<ISelectOptions[]>(DEFAULT_RESTAURANT_SUBSCRIPTION);
  const [inviteName, setInviteName] = useState<string>('');
  const [inviteEmail, setInviteEmail] = useState<string>('');
  const [selectedRestaurantId, setSelectedRestaurantId] = useState<number>(0);
  const [errorFields, setErrorFields] = useState<any>({});
  const [resendPopUp, setResendPopUp] = useState<boolean>(false);
  const [currentRestaurant, setCurrentRestaurant] = useState<any>({});

  // Fetch restaurants action call.
  const fetchRestaurants = () => {
    const restaurantRequest: IRestaurantRequest = {
      restaurantId: selectedRestaurantId,
      searchText: selectedRestaurantId !== 0 ? '' : searchText,
      subscriptionPlanId,
      showDeactivateOnly,
      pageSize,
      pageIndex,
      sortField,
      sortOrder,
    };
    dispatch(restaurantList(restaurantRequest));
  };

  // Restaurants list api call on pageIndex, showDeactivateOnly, subscriptionPlanId, sortField, sortOrder state change.
  useEffect(() => {
    fetchRestaurants();
    // Reset state during component unmount
    return () => {
      dispatch(resetRestaurantState());
    };
  }, [pageIndex, showDeactivateOnly, subscriptionPlanId, searchText, sortField, sortOrder]);

  // Search field state change handling for list restaurants.
  useEffect(() => {
    if (searchField.length >= SEARCH_MIN_CHAR_LENGTH && selectedRestaurantId === 0) {
      const restaurantSearchRequest: IRestaurantRequest = {
        restaurantId: 0,
        searchText: searchField,
        subscriptionPlanId,
        showDeactivateOnly,
        pageSize,
        pageIndex: 1,
        sortField,
        sortOrder,
      };
      dispatch(searchRestaurants(restaurantSearchRequest));
    }
  }, [searchField]);
  // Show success/error message for resend invitation api
  useEffect(() => {
    if (resendInvitationSuccess && resendInvitationErrorCode === 0) {
      toast.toastSuccess(resendInvitationErrorMessage);
      dispatch(resetUserInvite());
    } else if (resendInvitationErrorCode > 0) {
      toast.toastError(resendInvitationErrorMessage);
      dispatch(resetUserInvite());
    }
  }, [resendInvitationSuccess, resendInvitationErrorCode]);

  // Close invite restaurant form popup
  const closeRestaurantInviteForm = () => {
    setEmailInvitePop(false);
    setInviteName('');
    setInviteEmail('');
    setErrorFields({});
  };
  // Invite restaurants api response state change.
  useEffect(() => {
    if (inviteRestaurantSuccess && errorCode === 0) {
      closeRestaurantInviteForm();
      setEmailSentPop(true);
      dispatch(resetRestaurantState());
    } else if (errorCode > 0 && errorMessage) {
      toast.toastError(errorMessage);
      dispatch(resetRestaurantState());
    }
  }, [inviteRestaurantSuccess, errorCode]);

  // Handle show deactivated restaurants check box change event.
  const onDeactivateCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeactivatedOnly(event.target.checked ? 1 : 0);
    setPageIndex(1);
  };

  // select tier change event.
  const onPlanChange = (values: ISelectOptions[]) => {
    setSelectedPlan(values);
    setSubscriptionPlanId(values[0]?.value);
    setPageIndex(1);
  };

  // Sort header change event.
  const changeSortField = (field: number) => {
    let newSortOrder = SortOrderType.ASC;
    if (field === sortField) {
      newSortOrder = sortOrder === SortOrderType.ASC ? SortOrderType.DESC : SortOrderType.ASC;
    }
    setSortField(field);
    setSortOrder(newSortOrder);
  };

  // Invitor name input field change event.
  const onNameChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInviteName(value);
    const nameValidation = {
      name: inviteName.trim(),
    };
    const errorResult = await validateForm(nameValidation, INVITE_RESTAURANT_SCHEMA, errorFields);
    setErrorFields(errorResult);
  };
  // Invitor email input field change event.
  const onEmailChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInviteEmail(value);
    const emailValidation = {
      email: inviteEmail.trim(),
    };
    const errorResult = await validateForm(emailValidation, INVITE_RESTAURANT_SCHEMA, errorFields);
    setErrorFields(errorResult);
  };
  // Invite submit handler.
  const onInviteSubmit = async () => {
    const inviteForm: IRestaurantInviteRequest = {
      name: inviteName.trim(),
      email: inviteEmail.trim(),
    };
    const errorresult = await validateForm(inviteForm, INVITE_RESTAURANT_SCHEMA, errorFields);
    setErrorFields(errorresult);
    if (Object.keys(errorresult).length === 0) {
      dispatch(inviteRestaurants(inviteForm));
    }
  };

  // Resturant search input keydown event.
  const onRestaurantHandleKeyDown = (event: any) => {
    const { value } = event.target;
    if (event.key === ENTER_KEY) {
      if (inputRef.current) inputRef.current.blur();
      setPageIndex(1);
      setSearchText(value?.trim());
      setSearchField(searchField.trim());
    }
  };
  // Resturant textbox selected change event.
  const onRestaurantHandleChange = (value: any) => {
    if (value[0]) {
      oldSearchText.current = value[0].restaurantName;
      setSelectedRestaurantId(value[0].restaurantId);
      setSearchText(value[0].restaurantName);
      setPageIndex(1);
      setSearchField(value[0].restaurantName);
      if (inputRef.current) inputRef.current.blur();
    }
  };
  // Restaurant textbox search input change event.
  const onRestaurantInputHandleChange = (value: any) => {
    if (value.trim().length >= SEARCH_MIN_CHAR_LENGTH) {
      /* Search call will be fired when character length greater than 2 */
      setSearchField(value);
    } else if (value.trim().length === 0) {
      setSearchField('');
      setSearchText('');
    }
    oldSearchText.current = value;
    setTypeField(value);
    setSelectedRestaurantId(0);
  };

  const loadRestaurantAdd = () => {
    navigate('/restaurants/add');
  };
  // Resend invitation to restaurant api call
  const resendEmailInvitation = () => {
    setResendPopUp(false);
    const request = {
      email: currentRestaurant.email.trim(),
      firstName: currentRestaurant.firstName.trim(),
      lastName: currentRestaurant.lastName.trim(),
    };
    dispatch(resendInvitation(request));
  };
  return (
    <div className="content-nav contnt-container-margin">
      <div className="back-navigation">
        <Breadcrumb>
          <Breadcrumb.Item href="#" onClick={() => navigate('/home')}>
            <FormattedMessage id="Menu.Home" />
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
            <FormattedMessage id="Restaurant.PageHD" />
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="content-nav-sub">
        <div className="content-nav-header d-flex justify-content-between align-items-center">
          <h2>
            <FormattedMessage id="Restaurant.PageHD" />
          </h2>
          <div className="d-flex">
            <Button variant="outline-primary" className="me-3" onClick={() => navigate('/restaurants/merge')}>
              <FormattedMessage id="Button.MergeRestaurants" />
            </Button>
            <Dropdown className="button-dropdown">
              <Dropdown.Toggle variant="primary" id="dropdown-basic" className="button-dropdownmenu">
                <Add /> <FormattedMessage id="Restaurant.Add.Button.text" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="#" onClick={loadRestaurantAdd}>
                  <Add /> <FormattedMessage id="Restaurant.AddNew" />
                </Dropdown.Item>
                <Dropdown.Item href="#/action-2" onClick={() => setEmailInvitePop(true)}>
                  <EmailInvitation /> <FormattedMessage id="Vendor.SendEmail" />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="content-filter-main border-0 pb-2">
          <Row className="align-items-center">
            <Col xs={12} md={6} lg={5} xl={3} className="no-margin search-auto">
              <Typeahead
                id="restaurantSearch"
                ref={inputRef}
                onInputChange={onRestaurantInputHandleChange}
                onChange={onRestaurantHandleChange}
                onKeyDown={onRestaurantHandleKeyDown}
                options={searchRestaurantList}
                placeholder={useIntlMessages('Restaurant.SearchVendor.Placeholder')}
                selected={searchRestaurantList.filter((x: any) => x.restaurantId === selectedRestaurantId)}
                filterBy={['restaurantName']}
                minLength={oldSearchText.current.length > typeField.length ? oldSearchText.current.length : SEARCH_MIN_CHAR_LENGTH}
                labelKey={(restaurant: any) => `${restaurant.restaurantName}`}
                renderMenuItemChildren={(restaurant: any) => <div>{restaurant.restaurantName}</div>}
                inputProps={{
                  maxLength: INPUT_MAX_LENGTH_100,
                }}
              >
                <Search />
              </Typeahead>
            </Col>
            <Col xs={12} md={6} lg={5} xl={3} className="no-margin">
              <Select
                id="userType"
                name="userType"
                options={planList}
                placeholder="sample"
                dropdownGap={0}
                values={selectedPlan}
                icon={<SortIcon />}
                onChange={(values) => onPlanChange(values)}
              />
            </Col>
          </Row>
          <Col className="no-margin d-flex justify-content-end">
            <InputCheck id="global" name="Produce" label={useIntlMessages('Restaurant.ShowDeactivated')} checked={showDeactivateOnly === 1} onChange={onDeactivateCheckBoxChange} />
          </Col>
        </div>
        <Table responsive>
          <thead>
            <tr>
              <th className="w-250" onClick={() => changeSortField(RestaurantSortField.RESTAURANT_NAME)} style={{ cursor: 'pointer' }}>
                <FormattedMessage id="Restaurant.RestaurantName" />
                {sortField !== RestaurantSortField.RESTAURANT_NAME && <UpDownArrow />}
                {sortField === RestaurantSortField.RESTAURANT_NAME && sortOrder === SortOrderType.DESC && <DownArrow />}
                {sortField === RestaurantSortField.RESTAURANT_NAME && sortOrder === SortOrderType.ASC && <UpArrow />}
              </th>
              <th>
                <FormattedMessage id="Restaurant.Subscription" />
              </th>
              <th className="w-250">
                <FormattedMessage id="Restaurant.Email" />
              </th>
              <th className="w-150">
                <FormattedMessage id="Restaurant.PhoneNumber" />
              </th>
              <th className="w-250">
                <FormattedMessage id="Restaurant.Address" />
              </th>
              <th className="w-150">
                <FormattedMessage id="Restaurant.SignupDate" />
              </th>
              <th className="w-250"> </th>
            </tr>
          </thead>
          <tbody>
            {restaurants &&
              restaurants.length > 0 &&
              restaurants.map((restaurant: IRestaurantData) => (
                <tr key={restaurant.restaurantId} className={!restaurant.isActive ? 'error-item' : ''}>
                  <td>
                    <Link to={`/restaurants/details/${restaurant.restaurantId}`}>{restaurant.restaurantName}</Link>
                  </td>
                  <td>{restaurant.subscription}</td>
                  <td>{restaurant.email}</td>
                  <td>{`${restaurant.countryCode} ${formatPhoneNumber(restaurant.phone)}`}</td>
                  <td>{getRestaurantAddress(restaurant.address)}</td>
                  <td>{moment(restaurant.createdOn).local().format(DATE_FORMAT)}</td>
                  <td>
                    {restaurant.isActive && restaurant.isAddedFromAdminPortal && !restaurant.isLoggedIn && (
                      <Button
                        variant="outline-secondary"
                        onClick={() => {
                          setResendPopUp(true);
                          setCurrentRestaurant(restaurant);
                        }}
                      >
                        <FormattedMessage id="Button.ResendInvitation" />
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            {!restaurants ||
              (restaurants && restaurants.length === 0 && (
                <tr>
                  <td colSpan={7} className="text-center">
                    <FormattedMessage id="Restaurant.table.emptymessage" />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <div className="content-filter-main d-lg-flex justify-content-lg-between">
          {restaurants.length > 0 && (
            <p className="m-0">
              {stringFormat(
                totalCount === 1 ? useIntlActionMessages('Restaurant.Pagination.SingleItem.Text') : useIntlActionMessages('Restaurant.Pagination.Text'),
                restaurants.length > 0 ? ((totalCount === 1 ? 1 : pageIndex) - 1) * pageSize + 1 : 0,
                ((totalCount === 1 ? 1 : pageIndex) - 1) * pageSize + restaurants.length,
                totalCount,
              )}
            </p>
          )}
          {restaurants.length > 0 && totalCount > pageSize && (
            <Pagination totalCount={totalCount} pageLimit={pageSize} setCurrentPage={(page: number) => setPageIndex(page)} currentPage={pageIndex - 1} prevPage={-1} />
          )}
        </div>
      </div>
      {(isLoading || inviteRestaurantLoading || resendInvitationLoading) && <GifLoader />}
      {/*  Email Invitation popup */}
      <Modal show={emailInvite} onHide={() => closeRestaurantInviteForm()} centered className="mx-w-600" size="lg">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            <FormattedMessage id="Restaurant.EmailInvite.HD" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <small>
              <FormattedMessage id="Restaurant.EmailInvite.text" />
            </small>
          </p>
          <Col lg="12">
            <InputLabel
              id="test"
              name="sku_id"
              type="text"
              value={inviteName}
              maxLength={INPUT_MAX_LENGTH_100}
              label={useIntlMessages('Restaurant.RestaurantName')}
              errorMessage={errorFields?.name}
              onChange={onNameChange}
            />
          </Col>
          <Col lg="12">
            <InputLabel
              id="test"
              name="sku_id"
              type="text"
              value={inviteEmail}
              maxLength={INPUT_MAX_LENGTH_200}
              label={useIntlMessages('Vendor.Email')}
              errorMessage={errorFields?.email}
              onChange={onEmailChange}
            />
          </Col>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" className="btn-modal-footer" onClick={() => closeRestaurantInviteForm()}>
            <FormattedMessage id="Button.Cancel" />
          </Button>
          <Button variant="primary" onClick={onInviteSubmit} disabled={Object.keys(errorFields).length > 0 || inviteName.trim().length === 0 || inviteEmail.trim().length === 0}>
            <FormattedMessage id="Button.Confirm" />
          </Button>
        </Modal.Footer>
      </Modal>

      {/*  Email Sent popup */}
      <Modal show={emailSent} onHide={() => setEmailSentPop(false)} centered>
        <Modal.Body>
          <FormattedMessage id="Restaurant.EmailInvite.SentText" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setEmailSentPop(false)}>
            <FormattedMessage id="Button.OK" />
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Resend email invitation confirmation popup */}
      <Modal show={resendPopUp} onHide={() => setResendPopUp(false)} centered size="lg">
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-sm">
            <FormattedMessage id="HD.ResendInvitation" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <FormattedMessage id="User.InvitationAgain" />
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setResendPopUp(false)}>
            <FormattedMessage id="Button.Cancel" />
          </Button>
          <Button variant="primary" onClick={() => resendEmailInvitation()}>
            <FormattedMessage id="Button.OK" />
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default RestaurantList;
