/**
 * @file   src\features\Login.tsx
 * @brief  Login page.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import ReCAPTCHA from 'react-google-recaptcha';
import { useState, useEffect, Container, Button, Row, Col, Link, FormattedMessage, useNavigate, useCallback, useRef } from '../utils/thirdpartyComponents';
import '../styles/Onboard.scss';
import InputIcon from '../components/InputIcon';
import MailIcon from '../assets/icons/Mail';
import PasswordIcon from '../assets/icons/Password';
import Logo from '../assets/Logo.svg';
import PassowrdIcon from '../components/PasswordIcon';
import ShowPassIcon from '../assets/icons/ShowPassword';
import HidePassIcon from '../assets/icons/HidePassword';
import { validateForm } from '../utils/formValidation';
import { SIGNIN_SCHEMA, SEND_OTP_SCHEMA } from '../validations/authSchema';
import { useIntlMessages, setLocalStorage } from '../utils/helper';
import { validateUser, sendLoginOtp } from '../store/actions/authAction';
import { useAppDispatch, useAppSelector } from '../hooks';
import { RootState } from '../store';
import { IAuthRequest } from '../interfaces/authInterface';
import { MessageToaster } from '../utils/toastUtil';
import GifLoader from '../components/GifLoader';
import { MenuItems } from '../utils/menuHelper';
import { SUPERADMIN_TYPE } from '../utils/constants';
import { MenuUrls } from '../utils/enums';
import Timer from '../components/Timer';
import { IMenuItems } from '../interfaces/generalInterface';

// Toast object creation.
const toast = new MessageToaster();

// Login method.
const Login = () => {
  // Declare action dispatch.
  const dispatch = useAppDispatch();
  // Navigate object creation.
  const navigate = useNavigate();

  // Access auth redux state variables.
  const { isLoading, isSuccess, userInfo, errorCode, errorMessage, isOtpLoading, isOtpSuccess, otpErrorCode, otpErrorMessage } = useAppSelector((state: RootState) => state.auth);

  // Access url parameters.
  const queryParams = new URLSearchParams(window.location.search);
  const paramType = queryParams && queryParams.get('type') ? queryParams.get('type') : null;

  // Initialize component stat variables.
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errorFields, setErrorFields] = useState<any>({});
  const [showPassword, setShowPassword] = useState(false);
  const [showOtpTimer, setOtpTimerStatus] = useState<boolean>(false);
  const reCaptchaRef = useRef<ReCAPTCHA>(null);

  // Configure user login redirection.
  const configureNavigation = (info: any) => {
    const userType = info ? info.userType || 0 : userInfo?.userType || 0;
    const permissions = info ? info.permissions || [] : userInfo?.permissions || [];
    if (userType !== SUPERADMIN_TYPE) {
      return MenuItems.find((perm: IMenuItems) => perm.permissionId === permissions[0])?.path || MenuUrls.PROFILE;
    }
    return MenuItems[0]?.path;
  };

  // Handle side effect.
  useEffect(() => {
    localStorage.clear();
  }, []);

  // Show toast error message if login failed.
  useEffect(() => {
    if (errorCode !== 0 && errorMessage) {
      setPassword('');
      toast.toastError(errorMessage);
    } else if (errorCode === 0 && errorMessage) {
      setPassword('');
      toast.toastSuccess(errorMessage);
    }
  }, [errorCode, errorMessage]);

  // Show toast error message if login failed.
  useEffect(() => {
    if (isOtpSuccess && otpErrorCode !== 0 && otpErrorMessage) {
      toast.toastError(otpErrorMessage);
    } else if (otpErrorCode === 0 && otpErrorMessage) {
      setOtpTimerStatus(true);
      toast.toastSuccess(otpErrorMessage);
    }
  }, [isOtpSuccess, otpErrorCode, otpErrorMessage]);

  // Navigate user to dashboard after authentication sucess.
  useEffect(() => {
    if (isSuccess && userInfo?.accessToken) {
      setLocalStorage('MCSA_UDT', JSON.stringify(userInfo));
      setTimeout(() => {
        const newPath = configureNavigation(null);
        if (newPath) {
          navigate(newPath);
        }
      }, 2000);
    }
  }, [isSuccess]);

  // Show/hide password.
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  // Email input field change event.
  const onEmailChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEmail(value);
  };

  // Password input field change event.
  const onPasswordChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setPassword(value);
  };

  // Login form submit event.
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const loginForm: IAuthRequest = {
      email,
      password,
    };
    const errorresult = await validateForm(loginForm, SIGNIN_SCHEMA, errorFields);
    setErrorFields(errorresult);
    if (Object.keys(errorresult).length === 0) {
      let recaptchaToken: any = '';
      if (reCaptchaRef.current) recaptchaToken = await reCaptchaRef.current.executeAsync();
      setLocalStorage('MCSA_UDT', JSON.stringify({ capToken: recaptchaToken }));
      dispatch(validateUser(loginForm));
    }
  };

  // Method to send otp to the mail address.
  const onOtpSend = async () => {
    const otpRequest: IAuthRequest = {
      email,
    };
    const errorresult = await validateForm(otpRequest, SEND_OTP_SCHEMA, errorFields);
    setErrorFields(errorresult);
    if (Object.keys(errorresult).length === 0) {
      let recaptchaToken: any = '';
      if (reCaptchaRef.current) recaptchaToken = await reCaptchaRef.current.executeAsync();
      setLocalStorage('MCSA_UDT', JSON.stringify({ capToken: recaptchaToken }));
      dispatch(sendLoginOtp(otpRequest));
    }
  };

  // Bind timer element
  const configureTimer = useCallback(() => {
    return (
      showOtpTimer && (
        <Timer
          timeLimit={process.env.REACT_APP_OTP_RESEND_TIME ? Number(process.env.REACT_APP_OTP_RESEND_TIME) : 0}
          isActive={showOtpTimer}
          handleOtpLink={(buttonStatus: boolean) => setOtpTimerStatus(buttonStatus)}
        />
      )
    );
  }, [showOtpTimer]);

  return (
    <Container fluid>
      <Row className="onboard-wrap">
        <Col lg="6" className="d-flex justify-content-center align-items-center logo-wrap">
          <img src={Logo} alt="MADCHEF" />
          <div className="pattern" />
        </Col>
        <Col lg="6" className="d-flex justify-content-center align-items-center form-container">
          <div className="form-wrap">
            {paramType === 'snout' && (
              <p className="text-center error text-red">
                <FormattedMessage id="General.SessionOut.Message" />
              </p>
            )}
            <h1 className="text-center">
              <FormattedMessage id="Login.Header" />
            </h1>
            <form name="loginform" onSubmit={onSubmit}>
              <InputIcon
                id="email"
                name="email"
                type="email"
                placeholder={useIntlMessages('Login.Email.Placeholder')}
                icon={<MailIcon />}
                value={email}
                maxLength={200}
                errorMessage={errorFields?.email}
                onChange={onEmailChange}
              />
              <PassowrdIcon
                id="password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                placeholder={useIntlMessages('Login.Password.Placeholder')}
                icon={<PasswordIcon />}
                showpass={showPassword ? <HidePassIcon togglePassword={() => togglePassword()} /> : <ShowPassIcon togglePassword={() => togglePassword()} />}
                value={password}
                maxLength={100}
                errorMessage={errorFields?.password}
                onChange={onPasswordChange}
              />
              <div className="login-actions d-flex justify-content-between">
                <Link to="/recover">
                  <FormattedMessage id="Login.ForgotPassword" />
                </Link>
                {!showOtpTimer && (
                  <Link to="#" onClick={onOtpSend}>
                    <FormattedMessage id="Login.OnetimePassword" />
                  </Link>
                )}
                {configureTimer()}
              </div>
              <Button variant="primary" className="w-100" type="submit">
                <FormattedMessage id="Button.Login" />
              </Button>
              <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY || ''} size="invisible" ref={reCaptchaRef} />
            </form>
            {(isLoading || isOtpLoading) && <GifLoader />}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
