/**
 * @file   src\store\actions\settingsAction.ts
 * @brief  This file is responsible for creating asynchronous admin settings based api call.
 * @date   Dec, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { SettingsApi } from '../../utils/apiUrls';
import axios from '../../config/axiosConfig';
import { IEmailUpdateRequest } from '../../interfaces/settingsInterface';

// Get contact emails api
export const getAllContactEmails = createAsyncThunk('/settings/getAllContactEmails', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(SettingsApi.GET_ALL_CONTACT_EMAILS);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Save contact emails api
export const saveContactEmails = createAsyncThunk('/settings/saveContactEmails', async (request: IEmailUpdateRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(SettingsApi.SAVE_EMAILS, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});
