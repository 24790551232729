/**
 * @file   src\features\common\Subscriptions.tsx
 * @brief  Subscriptions listing page
 * @date   Dec, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { useState, Breadcrumb, FormattedMessage, Row, Col, DatePicker, Table, useNavigate, useParams, useEffect, useLocation, moment } from '../../utils/thirdpartyComponents';
import { checkPathMatched, stringFormat, useIntlActionMessages, useIntlMessages } from '../../utils/helper';
import Pagination from '../../components/Paginate';
import UpDownArrow from '../../assets/icons/UpDownArrow';
import DownArrow from '../../assets/icons/DownArrow';
import UpArrow from '../../assets/icons/UpArrow';
import GifLoader from '../../components/GifLoader';
import { BusinessType, MenuUrls, SortOrderType, VendorRestaurantSubscriptionSortField } from '../../utils/enums';
import { DATE_FORMAT2, DEFAULT_PAGE_INDEX, DEFAULT_SORT_FIELD, PAGE_SIZE, TIME_END_FORMAT, TIME_STARTS_FORMAT } from '../../utils/constants';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { RootState } from '../../store';
import { IUserSubscriptionRequest, IVendorRestaurantSubscriptions } from '../../interfaces/generalInterface';
import { getUserSubscriptions } from '../../store/actions/commonAction';

// Declare page item count to be displayed per page.
const pageItemCount: number = process.env.REACT_APP_LIST_PAGE_SIZE !== '' ? Number(process.env.REACT_APP_LIST_PAGE_SIZE) : PAGE_SIZE;

const Subscriptions = () => {
  // Create location object to access location info.
  const location = useLocation();

  // Navigation object creation
  const navigate = useNavigate();

  // Create route param object to access the route parameters.
  const params = useParams();

  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Access redux state variables.
  const { isSubscriptionLoading, subscriptions, totalSubscriptions } = useAppSelector((state: RootState) => state.common);

  // Initialize component states.
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [startDateUtc, setStartDateUtc] = useState<string>('');
  const [endDateUtc, setEndDateUtc] = useState<string>('');
  const [pageSize] = useState<number>(pageItemCount);
  const [pageIndex, setPageIndex] = useState<number>(DEFAULT_PAGE_INDEX);
  const [sortField, setSortField] = useState<number>(DEFAULT_SORT_FIELD);
  const [sortOrder, setSortOrder] = useState<number>(SortOrderType.ASC);

  // Vendor subscriptions api calling method.
  const getSubscriptionList = (id: number, type: number) => {
    const subscriptionRequest: IUserSubscriptionRequest = {
      userId: id,
      startDate: startDateUtc,
      endDate: endDateUtc,
      pageSize,
      pageIndex,
      sortField,
      sortOrder,
      userType: type,
    };
    if (startDateUtc !== '' && endDateUtc !== '') {
      dispatch(getUserSubscriptions(subscriptionRequest));
    }
  };

  // Component initial loading.
  useEffect(() => {
    const fetchCurrentDate = new Date();
    fetchCurrentDate.setDate(fetchCurrentDate.getDate() - 1);
    const fromDateInitial = moment(new Date(fetchCurrentDate)).format('YYYY-MM-DD');
    const toDateInitial = moment(new Date()).format('YYYY-MM-DD');
    const dateStringFrom = fromDateInitial.replace(/-/g, '/').replace('T', ' ');
    const dateStringTo = toDateInitial.replace(/-/g, '/').replace('T', ' ');
    const fromDateUTC = new Date(`${dateStringFrom} 00:00:00`).toISOString();
    const toDateUTC = new Date(`${dateStringTo} 23:59:59`).toISOString();
    setStartDate(dateStringFrom);
    setEndDate(dateStringTo);
    setStartDateUtc(fromDateUTC);
    setEndDateUtc(toDateUTC);
  }, []);

  // State change sideeffect handling.
  useEffect(() => {
    if (checkPathMatched(MenuUrls.SUBSCRIPTION_LIST_VENDOR, location?.pathname)) {
      /* Access the location url parameters. */
      const vId = params.vendorId ? Number(params.vendorId) : 0;
      getSubscriptionList(vId, BusinessType.VENDOR);
    } else if (checkPathMatched(MenuUrls.SUBSCRIPTION_LIST_RESTAURANT, location?.pathname)) {
      /* Access the location url parameters. */
      const restId = params.restaurantId ? Number(params.restaurantId) : 0;
      getSubscriptionList(restId, BusinessType.RESTAURANT);
    }
  }, [pageIndex, sortField, sortOrder, startDate, endDate]);

  // Sort header change event.
  const changeSortField = (field: number) => {
    let newSortOrder = SortOrderType.ASC;
    if (field === sortField) {
      newSortOrder = sortOrder === SortOrderType.ASC ? SortOrderType.DESC : SortOrderType.ASC;
    }
    setSortField(field);
    setSortOrder(newSortOrder);
  };

  // Handle date filter change event.
  const handleDateChange = (dateType: number, paramValue: any) => {
    if (dateType === 0) {
      if (paramValue !== '' && paramValue !== null) {
        setStartDate(paramValue);
        const fromDateInitial = moment(new Date(paramValue)).format(DATE_FORMAT2);
        const fromDate = new Date(`${fromDateInitial} ${TIME_STARTS_FORMAT}`).toISOString();
        setStartDateUtc(fromDate);
        if (endDateUtc === '') {
          const toDateInitial = moment(new Date()).format(DATE_FORMAT2);
          const dateStringTo = toDateInitial.replace(/-/g, '/').replace('T', ' ');
          const toDateUTC = new Date(`${dateStringTo} ${TIME_END_FORMAT}`).toISOString();
          setEndDate(toDateInitial);
          setEndDateUtc(toDateUTC);
        }
        setPageIndex(DEFAULT_PAGE_INDEX);
      } else {
        setStartDate('');
        setStartDateUtc('');
      }
    }
    if (dateType === 1) {
      if (paramValue !== '' && paramValue !== null) {
        setEndDate(paramValue);
        const toDateInitial = moment(new Date(paramValue)).format(DATE_FORMAT2);
        const toDate = new Date(`${toDateInitial} ${TIME_END_FORMAT}`).toISOString();
        setEndDateUtc(toDate);
        if (startDateUtc === '') {
          const dateStringFrom = toDateInitial.replace(/-/g, '/').replace('T', ' ');
          const fromDateUTC = new Date(`${dateStringFrom} ${TIME_STARTS_FORMAT}`).toISOString();
          setStartDateUtc(fromDateUTC);
          setStartDate(toDateInitial);
        }
        setPageIndex(DEFAULT_PAGE_INDEX);
      } else {
        setEndDate('');
        setEndDateUtc('');
      }
    }
  };

  return (
    <div className="content-nav contnt-container-margin">
      <div className="back-navigation">
        <Breadcrumb>
          <Breadcrumb.Item href="#" onClick={() => navigate('/home')}>
            <FormattedMessage id="Menu.Home" />
          </Breadcrumb.Item>
          {checkPathMatched(MenuUrls.SUBSCRIPTION_LIST_VENDOR, location?.pathname) && (
            <Breadcrumb.Item href="#" onClick={() => navigate('/vendors')}>
              <FormattedMessage id="Vendor.PageHD" />
            </Breadcrumb.Item>
          )}
          {checkPathMatched(MenuUrls.SUBSCRIPTION_LIST_RESTAURANT, location?.pathname) && (
            <Breadcrumb.Item href="#" onClick={() => navigate('/restaurants')}>
              <FormattedMessage id="Restaurant.PageHD" />
            </Breadcrumb.Item>
          )}
          <Breadcrumb.Item active>
            <FormattedMessage id="Subscriptions.Breadcrumbs.Heading" />
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="content-nav-sub">
        <div className="content-nav-header d-flex justify-content-between align-items-center">
          <h2>
            <FormattedMessage id="Subscriptions.Link.Label" />
          </h2>
        </div>
        <div className="content-filter-main border-0">
          <Row className="align-items-center">
            <Col xs={12} md={6} lg={4} xl="auto" className="no-margin custom-calendar">
              <DatePicker
                selected={startDate !== '' ? new Date(startDate) : null}
                onChange={(date: any) => handleDateChange(0, date)}
                placeholderText={useIntlMessages('Dashboard.StartDate')}
                maxDate={moment().toDate()}
                onChangeRaw={(e) => {
                  e.preventDefault();
                }}
              />
            </Col>
            <Col xs={12} md={6} lg={4} xl="auto" className="no-margin custom-calendar">
              <DatePicker
                selected={endDate !== '' ? new Date(endDate) : null}
                onChange={(date: any) => handleDateChange(1, date)}
                placeholderText={useIntlMessages('Dashboard.EndDate')}
                maxDate={moment().toDate()}
                minDate={startDate !== '' ? new Date(startDate) : null}
                onChangeRaw={(e) => {
                  e.preventDefault();
                }}
              />
            </Col>
          </Row>
        </div>
        <Table responsive>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="Subscriptions.Table.Header.Slno" />
              </th>
              <th onClick={() => changeSortField(VendorRestaurantSubscriptionSortField.TRANSACTION_ID)} style={{ cursor: 'pointer' }}>
                <FormattedMessage id="Subscriptions.Table.Header.TransactionId" />
                {sortField !== VendorRestaurantSubscriptionSortField.TRANSACTION_ID && <UpDownArrow />}
                {sortField === VendorRestaurantSubscriptionSortField.TRANSACTION_ID && sortOrder === SortOrderType.DESC && <DownArrow />}
                {sortField === VendorRestaurantSubscriptionSortField.TRANSACTION_ID && sortOrder === SortOrderType.ASC && <UpArrow />}
              </th>
              <th>
                <FormattedMessage id="Subscriptions.Table.Header.Subscription" />
              </th>
              <th>
                <FormattedMessage id="Subscriptions.Table.Header.Amount" />
              </th>
              <th onClick={() => changeSortField(VendorRestaurantSubscriptionSortField.FROM_DATE)} style={{ cursor: 'pointer' }}>
                <FormattedMessage id="Subscriptions.Table.Header.FromDate" />
                {sortField !== VendorRestaurantSubscriptionSortField.FROM_DATE && <UpDownArrow />}
                {sortField === VendorRestaurantSubscriptionSortField.FROM_DATE && sortOrder === SortOrderType.DESC && <DownArrow />}
                {sortField === VendorRestaurantSubscriptionSortField.FROM_DATE && sortOrder === SortOrderType.ASC && <UpArrow />}
              </th>
              <th onClick={() => changeSortField(VendorRestaurantSubscriptionSortField.TO_DATE)} style={{ cursor: 'pointer' }}>
                <FormattedMessage id="Subscriptions.Table.Header.ToDate" />
                {sortField !== VendorRestaurantSubscriptionSortField.TO_DATE && <UpDownArrow />}
                {sortField === VendorRestaurantSubscriptionSortField.TO_DATE && sortOrder === SortOrderType.DESC && <DownArrow />}
                {sortField === VendorRestaurantSubscriptionSortField.TO_DATE && sortOrder === SortOrderType.ASC && <UpArrow />}
              </th>
              <th onClick={() => changeSortField(VendorRestaurantSubscriptionSortField.TRANSACTION_DATE)} style={{ cursor: 'pointer' }}>
                <FormattedMessage id="Subscriptions.Table.Header.TransactionDate" />
                {sortField !== VendorRestaurantSubscriptionSortField.TRANSACTION_DATE && <UpDownArrow />}
                {sortField === VendorRestaurantSubscriptionSortField.TRANSACTION_DATE && sortOrder === SortOrderType.DESC && <DownArrow />}
                {sortField === VendorRestaurantSubscriptionSortField.TRANSACTION_DATE && sortOrder === SortOrderType.ASC && <UpArrow />}
              </th>
            </tr>
          </thead>
          <tbody>
            {subscriptions.length > 0 &&
              subscriptions.map((list: IVendorRestaurantSubscriptions, index: number) => (
                <tr key={list.transactionID}>
                  <td>{index + 1}</td>
                  <td>{list.transactionID}</td>
                  <td>{list.subscription}</td>
                  <td>{list.amount && list.amount !== 0 ? `$${list.amount}` : 0}</td>
                  <td>{list.startDate ? moment(list.startDate).local().format('MM/DD/YYYY') : ''}</td>
                  <td>{list.endDate ? moment(list.endDate).local().format('MM/DD/YYYY') : ''}</td>
                  <td>{list.transactionDate ? moment(list.transactionDate).local().format('MM/DD/YYYY') : ''}</td>
                </tr>
              ))}
            {subscriptions.length === 0 && (
              <tr>
                <td colSpan={7} className="text-center">
                  <FormattedMessage id="Subscriptions.table.emptymessage" />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <div className="content-filter-main d-lg-flex justify-content-lg-between">
          {subscriptions && subscriptions.length > 0 && (
            <p className="m-0">
              {stringFormat(
                totalSubscriptions === 1 ? useIntlActionMessages('Subscriptions.Pagination.SingleItem.Text') : useIntlActionMessages('Subscriptions.Pagination.Text'),
                subscriptions.length > 0 ? ((totalSubscriptions === 1 ? 1 : pageIndex) - 1) * pageSize + 1 : 0,
                ((totalSubscriptions === 1 ? 1 : pageIndex) - 1) * pageSize + subscriptions.length,
                totalSubscriptions,
              )}
            </p>
          )}
          {subscriptions.length > 0 && totalSubscriptions > pageSize && (
            <Pagination totalCount={totalSubscriptions} pageLimit={pageSize} setCurrentPage={(page: number) => setPageIndex(page)} currentPage={pageIndex - 1} prevPage={-1} />
          )}
        </div>
      </div>
      {isSubscriptionLoading && <GifLoader />}
    </div>
  );
};

export default Subscriptions;
