import React from 'react';

const EditIcon = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.4173 8.8794L13.1212 7.58323L4.58333 16.1211V17.4172H5.8795L14.4173 8.8794ZM15.7135 7.58323L17.0097 6.28706L15.7135 4.9909L14.4173 6.28706L15.7135 7.58323ZM6.6385 19.2506H2.75V15.3611L15.0654 3.04573C15.2373 2.87388 15.4704 2.77734 15.7135 2.77734C15.9566 2.77734 16.1897 2.87388 16.3616 3.04573L18.9548 5.63898C19.1267 5.81088 19.2232 6.044 19.2232 6.28706C19.2232 6.53013 19.1267 6.76325 18.9548 6.93515L6.63942 19.2506H6.6385Z" />
    </svg>
  );
};

export default EditIcon;
