/**
 * @file   src\features\dashboard\VendorRestaurants.tsx
 * @brief  Component lists vendors and restaurants
 * @date   August, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { useState, FormattedMessage, Col, Row, Table, Tab, Tabs, DatePicker, moment, useEffect } from '../../utils/thirdpartyComponents';
import { useIntlMessages, stringFormat, useIntlActionMessages, formatPhoneNumber } from '../../utils/helper';
import Pagination from '../../components/Paginate';
import { getNewRestaurantsList, getNewVendorsList } from '../../store/actions/dashboardActions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { IDashboardRestaurantRequest, IDashboardVendorRequest, IRestaurantRow, IVendorRow } from '../../interfaces/dashboardInterface';
import { RootState } from '../../store';
import { DATE_FORMAT2, PAGE_SIZE, TAB_RESTAURANT_KEY, TAB_VENDOR_KEY, TEXT_END, TEXT_START, TIME_END_FORMAT, TIME_STARTS_FORMAT } from '../../utils/constants';
import RestaurantSearch from './RestaurantSearch';
import VendorSearch from './VendorSearch';
import GifLoader from '../../components/GifLoader';
import { MessageToaster } from '../../utils/toastUtil';
import { VendorRestaurantSearchType } from '../../utils/enums';

// Page size value for pagination
const pageItemCount: number = process.env.REACT_APP_LIST_PAGE_SIZE !== '' ? Number(process.env.REACT_APP_LIST_PAGE_SIZE) : PAGE_SIZE;

// Toast object creation.
const toast = new MessageToaster();

const VendorsAndRestaurants = () => {
  // Create action dispatch
  const dispatch = useAppDispatch();
  // Component state variables
  const [startDateUtc, setStartDateUtc] = useState<string>('');
  const [endDateUtc, setEndDateUtc] = useState<string>('');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [restSelectedId, setRestaurantSelectedId] = useState<number>(0);
  const [vendorSelectedId, setVendorSelectedId] = useState<number>(0);
  const [tabKey, setTabKey] = useState<string>(TAB_RESTAURANT_KEY);

  // Accessing redux state variables
  const {
    restaurantList,
    vendorsList,
    vendorListCount,
    restaurantListTotalCount,
    restaurantListLoading,
    vendorsListErrorCode,
    vendorsListErrorMessage,
    restaurantListErrorCode,
    restaurantListErrorMessage,
    vendorsListLoading,
  } = useAppSelector((state: RootState) => state.dashboard);
  // Api call to fetch restaurants list
  const fetchRestaurants = () => {
    const params: IDashboardRestaurantRequest = {
      restaurantId: restSelectedId,
      searchText: '',
      fromDate: startDateUtc,
      toDate: endDateUtc,
      pageSize: pageItemCount,
      pageIndex: currentPage,
      sortField: 0,
      sortOrder: 1,
    };
    dispatch(getNewRestaurantsList(params));
  };
  // Api call to fetch vendors list
  const fetchVendors = () => {
    const params: IDashboardVendorRequest = {
      vendorId: vendorSelectedId,
      searchText: '',
      fromDate: startDateUtc,
      toDate: endDateUtc,
      pageSize: pageItemCount,
      pageIndex: currentPage,
      sortField: 0,
      sortOrder: 1,
    };
    dispatch(getNewVendorsList(params));
  };
  // fetch restaurants when dependencies changes and on intial loading
  useEffect(() => {
    if (tabKey === TAB_RESTAURANT_KEY) {
      fetchRestaurants();
    }
  }, [currentPage, restSelectedId, startDateUtc, endDateUtc, tabKey]);
  // fetch vendors when dependencies changes and on intial loading
  useEffect(() => {
    if (tabKey === TAB_VENDOR_KEY) {
      fetchVendors();
    }
  }, [currentPage, vendorSelectedId, startDateUtc, endDateUtc, tabKey]);

  // Show error messages upon api failure
  useEffect(() => {
    if (vendorsListErrorCode > 0 && vendorsListErrorMessage) {
      toast.toastError(vendorsListErrorMessage);
    }
  }, [vendorsListErrorCode, vendorsListErrorMessage]);

  // Show error messages upon api failure
  useEffect(() => {
    if (restaurantListErrorCode > 0 && restaurantListErrorMessage) {
      toast.toastError(vendorsListErrorMessage);
    }
  }, [restaurantListErrorCode, restaurantListErrorMessage]);

  // handle date change
  const handleDateChange = (paramName: string, paramValue: any) => {
    if (paramName === TEXT_START) {
      if (paramValue !== '' && paramValue !== null) {
        setStartDate(paramValue);
        const fromDateInitial = moment(new Date(paramValue)).format(DATE_FORMAT2);
        const fromDate = new Date(`${fromDateInitial} ${TIME_STARTS_FORMAT}`).toISOString();
        setStartDateUtc(fromDate);
        if (endDateUtc === '') {
          const toDateInitial = moment(new Date()).format(DATE_FORMAT2);
          const dateStringTo = toDateInitial.replace(/-/g, '/').replace('T', ' ');
          const toDateUTC = new Date(`${dateStringTo} ${TIME_END_FORMAT}`).toISOString();
          setEndDateUtc(toDateUTC);
          setEndDate(toDateInitial);
        }
      } else {
        setStartDate('');
        setStartDateUtc('');
      }
    }
    if (paramName === TEXT_END) {
      if (paramValue !== '' && paramValue !== null) {
        setEndDate(paramValue);
        const toDateInitial = moment(new Date(paramValue)).format('YYYY-MM-DD');
        const toDate = new Date(`${toDateInitial} ${TIME_END_FORMAT}`).toISOString();
        setEndDateUtc(toDate);
        if (startDateUtc === '') {
          const dateStringFrom = toDateInitial.replace(/-/g, '/').replace('T', ' ');
          const fromDateUTC = new Date(`${dateStringFrom} ${TIME_STARTS_FORMAT}`).toISOString();
          setStartDateUtc(fromDateUTC);
          setStartDate(toDateInitial);
        }
      } else {
        setEndDate('');
        setEndDateUtc('');
      }
    }
  };

  // set restaurant id searched
  const setRestaurantId = (id: number) => {
    setRestaurantSelectedId(id);
  };
  // set vendor id searched
  const setVendorId = (id: number) => {
    setVendorSelectedId(id);
  };
  // reset values when tabs change
  const resetValues = (key: string | null) => {
    setTabKey(key || TAB_RESTAURANT_KEY);
    setStartDate('');
    setStartDateUtc('');
    setEndDate('');
    setEndDateUtc('');
    setCurrentPage(1);
  };

  return (
    <div className="content-nav-sub">
      <Tabs defaultActiveKey={TAB_RESTAURANT_KEY} id="uncontrolled-tab-example" className="mb-3 secondary-tab" onSelect={(key) => resetValues(key)}>
        <Tab eventKey={TAB_RESTAURANT_KEY} title={useIntlMessages('Dashboard.Restaurants')}>
          <div className="content-filter-main border-0">
            <Row className="align-items-center">
              <Col xs={12} md={6} lg={5} xl={3} className="no-margin search-auto">
                <RestaurantSearch setRestId={setRestaurantId} searchType={VendorRestaurantSearchType.RESTAURANTS} />
              </Col>
              <Col xs={12} md={6} lg={4} xl="auto" className="no-margin custom-calendar">
                <DatePicker
                  selected={startDate !== '' ? new Date(startDate) : null}
                  onChange={(date: any) => handleDateChange('start', date)}
                  placeholderText={useIntlMessages('Dashboard.StartDate')}
                  maxDate={endDate !== '' ? new Date(endDate) : null}
                  onChangeRaw={(e) => {
                    e.preventDefault();
                  }}
                />
              </Col>
              <Col xs={12} md={6} lg={4} xl="auto" className="no-margin custom-calendar">
                <DatePicker
                  selected={endDate !== '' ? new Date(endDate) : null}
                  onChange={(date: any) => handleDateChange('end', date)}
                  placeholderText={useIntlMessages('Dashboard.EndDate')}
                  minDate={startDate !== '' ? new Date(startDate) : null}
                  onChangeRaw={(e) => {
                    e.preventDefault();
                  }}
                />
              </Col>
            </Row>
          </div>
          <Table responsive>
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="Restaurant.RestaurantName" />
                </th>
                <th>
                  <FormattedMessage id="Dashboard.WhoSigned" />
                </th>
                <th>
                  <FormattedMessage id="Restaurant.Email" />
                </th>
                <th className="w-200">
                  <FormattedMessage id="Dashboard.Cellphone" />
                </th>
              </tr>
            </thead>
            <tbody>
              {restaurantList.length > 0 &&
                restaurantList.map((restaurant: IRestaurantRow, index: number) => (
                  <tr key={`${restaurant.restaurantName}_${index.toString()}`}>
                    <td>{restaurant.restaurantName}</td>
                    <td>{restaurant.adminName}</td>
                    <td>{restaurant.email}</td>
                    <td>{restaurant.phone && `${restaurant.countryCode} ${formatPhoneNumber(restaurant.phone)}`}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <div className="content-filter-main d-lg-flex justify-content-lg-between">
            <p className="m-0">
              {stringFormat(
                restaurantListTotalCount === 1 ? useIntlActionMessages('Restaurant.Pagination.SingleItem.Text') : useIntlActionMessages('Restaurant.Pagination.Text'),
                restaurantList.length > 0 ? ((restaurantListTotalCount === 1 ? 1 : currentPage) - 1) * pageItemCount + 1 : 0,
                ((restaurantListTotalCount === 1 ? 1 : currentPage) - 1) * pageItemCount + restaurantList.length,
                restaurantListTotalCount,
              )}
            </p>
            {restaurantList.length > 0 && restaurantListTotalCount > pageItemCount && (
              <Pagination
                totalCount={restaurantListTotalCount}
                pageLimit={pageItemCount}
                setCurrentPage={(page: number) => setCurrentPage(page)}
                currentPage={currentPage - 1}
                prevPage={-1}
              />
            )}
          </div>
        </Tab>
        <Tab eventKey={TAB_VENDOR_KEY} title={useIntlMessages('Dashboard.Vendors')}>
          <div className="content-filter-main border-0">
            <Row className="align-items-center">
              <Col xs={12} md={6} lg={5} xl={3} className="no-margin search-auto">
                <VendorSearch setVendorId={setVendorId} searchType={VendorRestaurantSearchType.VENDORS} />
              </Col>
              <Col xs={12} md={6} lg={4} xl="auto" className="no-margin custom-calendar">
                <DatePicker
                  selected={startDate !== '' ? new Date(startDate) : null}
                  onChange={(date: any) => handleDateChange('start', date)}
                  placeholderText={useIntlMessages('Dashboard.StartDate')}
                  onChangeRaw={(e) => {
                    e.preventDefault();
                  }}
                />
              </Col>
              <Col xs={12} md={6} lg={4} xl="auto" className="no-margin custom-calendar">
                <DatePicker
                  selected={endDate !== '' ? new Date(endDate) : null}
                  onChange={(date: any) => handleDateChange('end', date)}
                  placeholderText={useIntlMessages('Dashboard.EndDate')}
                  onChangeRaw={(e) => {
                    e.preventDefault();
                  }}
                />
              </Col>
            </Row>
          </div>
          <Table responsive>
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="Restaurant.VendorName" />
                </th>
                <th>
                  <FormattedMessage id="Dashboard.WhoSigned" />
                </th>
                <th>
                  <FormattedMessage id="Restaurant.Email" />
                </th>
                <th className="w-200">
                  <FormattedMessage id="Dashboard.Cellphone" />
                </th>
              </tr>
            </thead>
            <tbody>
              {vendorsList.length > 0 &&
                vendorsList.map((vendor: IVendorRow) => (
                  <tr key={vendor.vendorName}>
                    <td>{vendor.vendorName}</td>
                    <td>{vendor.adminName}</td>
                    <td>{vendor.email}</td>
                    <td>{vendor.phone && `${vendor.countrycode} ${formatPhoneNumber(vendor.phone)}`}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <div className="content-filter-main d-lg-flex justify-content-lg-between">
            <p className="m-0">
              {stringFormat(
                vendorListCount === 1 ? useIntlActionMessages('Vendor.Pagination.SingleItem.Text') : useIntlActionMessages('Vendor.Pagination.Text'),
                vendorsList.length > 0 ? ((vendorListCount === 1 ? 1 : currentPage) - 1) * pageItemCount + 1 : 0,
                ((vendorListCount === 1 ? 1 : currentPage) - 1) * pageItemCount + vendorsList.length,
                vendorListCount,
              )}
            </p>
            {vendorsList.length > 0 && vendorListCount > pageItemCount && (
              <Pagination
                totalCount={vendorListCount}
                pageLimit={pageItemCount}
                setCurrentPage={(page: number) => setCurrentPage(page)}
                currentPage={currentPage - 1}
                prevPage={-1}
              />
            )}
          </div>
        </Tab>
      </Tabs>
      {(restaurantListLoading || vendorsListLoading) && <GifLoader />}
    </div>
  );
};

export default VendorsAndRestaurants;
