import React from 'react';

const DragIcon = () => {
  return (
    <svg width="48" height="48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="m32 26 13.928 8.124-5.946 1.7 4.25 7.362-3.464 2-4.25-7.36-4.446 4.3L32 26Zm-4-14h4v4h10a2 2 0 0 1 2 2v8h-4v-6H20v20h8v4H18a2 2 0 0 1-2-2V32h-4v-4h4V18a2 2 0 0 1 2-2h10v-4ZM8 28v4H4v-4h4Zm0-8v4H4v-4h4Zm0-8v4H4v-4h4Zm0-8v4H4V4h4Zm8 0v4h-4V4h4Zm8 0v4h-4V4h4Zm8 0v4h-4V4h4Z" />
    </svg>
  );
};

export default DragIcon;
