/**
 * @file   src\features\dashboard\Transactions.tsx
 * @brief  Dashboard transactions list page
 * @date   August, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { useState, FormattedMessage, Link, Col, Row, Table, Badge, DatePicker, useEffect, moment } from '../../utils/thirdpartyComponents';
import { useIntlMessages, stringFormat, useIntlActionMessages, formatNumberWithCommas } from '../../utils/helper';
import Pagination from '../../components/Paginate';
import { getTransactionsList } from '../../store/actions/dashboardActions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import RestaurantSearch from './RestaurantSearch';
import VendorSearch from './VendorSearch';
import { TransactionRequestParams, ITransactionRow } from '../../interfaces/dashboardInterface';
import { RootState } from '../../store';
import { PAGE_SIZE, DATE_FORMAT2, DATE_TIME_FORMAT, TEXT_START, TIME_STARTS_FORMAT, TIME_END_FORMAT, TEXT_END } from '../../utils/constants';
import { InvoiceStatus, VendorRestaurantSearchType, InvoiceListSortOptions, SortOrderType } from '../../utils/enums';
import { MessageToaster } from '../../utils/toastUtil';
import GifLoader from '../../components/GifLoader';

// Toast object creation.
const toast = new MessageToaster();
// page size
const pageItemCount: number = process.env.REACT_APP_LIST_PAGE_SIZE !== '' ? Number(process.env.REACT_APP_LIST_PAGE_SIZE) : PAGE_SIZE;

// Component that displays list of transactions
const Transactions = () => {
  // Create action dispatch
  const dispatch = useAppDispatch();
  // Access Redux state variables
  const { transactionsList, transactionListTotalCount, transactionsListErrorCode, transactionsListErrorMessage, transactionsListLoading } = useAppSelector(
    (state: RootState) => state.dashboard,
  );
  // Component state variables
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [startDateUtc, setStartDateUtc] = useState<string>('');
  const [endDateUtc, setEndDateUtc] = useState<string>('');
  const [vendorSelectedId, setVendorSelectedId] = useState<number>(0);
  const [restSelectedId, setRestaurantSelectedId] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  // Call to fetch transactions list
  const fetchTransactions = () => {
    const params: TransactionRequestParams = {
      searchVendor: '',
      searchRestaurant: '',
      searchSimilar: '',
      vendorId: vendorSelectedId,
      restaurantId: restSelectedId,
      fromDate: startDateUtc,
      toDate: endDateUtc,
      pageSize: pageItemCount,
      pageIndex: currentPage,
      sortField: InvoiceListSortOptions.GENERATEDON,
      sortOrder: SortOrderType.DESC,
    };
    dispatch(getTransactionsList(params));
  };
  // fetch transactions when the given dependencies change
  useEffect(() => {
    fetchTransactions();
  }, [currentPage, startDateUtc, endDateUtc, vendorSelectedId, restSelectedId]);

  // Show error messages upon api failure
  useEffect(() => {
    if (transactionsListErrorCode > 0 && transactionsListErrorMessage) {
      toast.toastError(transactionsListErrorMessage);
    }
  }, [transactionsListErrorCode, transactionsListErrorMessage]);

  // set restaurant id searched
  const setRestaurantId = (id: number) => {
    setRestaurantSelectedId(id);
    setCurrentPage(1);
  };
  // set vendor id searched
  const setVendorId = (id: number) => {
    setVendorSelectedId(id);
    setCurrentPage(1);
  };
  // handle date change
  const handleDateChange = (paramName: string, paramValue: any) => {
    if (paramName === TEXT_START) {
      if (paramValue !== '' && paramValue !== null) {
        setStartDate(paramValue);
        const fromDateInitial = moment(new Date(paramValue)).format(DATE_FORMAT2);
        const fromDate = new Date(`${fromDateInitial} ${TIME_STARTS_FORMAT}`).toISOString();
        setStartDateUtc(fromDate);
        if (endDateUtc === '') {
          const toDateInitial = moment(new Date()).format(DATE_FORMAT2);
          const dateStringTo = toDateInitial.replace(/-/g, '/').replace('T', ' ');
          const toDateUTC = new Date(`${dateStringTo} ${TIME_END_FORMAT}`).toISOString();
          setEndDateUtc(toDateUTC);
          setEndDate(toDateInitial);
        }
        setCurrentPage(1);
      } else {
        setStartDate('');
        setStartDateUtc('');
      }
    }
    if (paramName === TEXT_END) {
      if (paramValue !== '' && paramValue !== null) {
        setEndDate(paramValue);
        const toDateInitial = moment(new Date(paramValue)).format(DATE_FORMAT2);
        const toDate = new Date(`${toDateInitial} ${TIME_END_FORMAT}`).toISOString();
        setEndDateUtc(toDate);
        if (startDateUtc === '') {
          const dateStringFrom = toDateInitial.replace(/-/g, '/').replace('T', ' ');
          const fromDateUTC = new Date(`${dateStringFrom} ${TIME_STARTS_FORMAT}`).toISOString();
          setStartDateUtc(fromDateUTC);
          setStartDate(toDateInitial);
        }
        setCurrentPage(1);
      } else {
        setEndDate('');
        setEndDateUtc('');
      }
    }
  };

  return (
    <div className="content-nav-sub">
      <div className="content-nav-header d-flex justify-content-between align-items-center">
        <h2>
          <FormattedMessage id="Dashboard.Transactions" />
        </h2>
      </div>
      <div className="content-filter-main border-0">
        <Row>
          <Col xs={12} md={6} lg={4} xl={3} className="no-margin search-auto">
            <VendorSearch setVendorId={setVendorId} searchType={VendorRestaurantSearchType.TRANSACTIONS} />
          </Col>
          <Col xs={12} md={6} lg={4} xl={3} className="no-margin search-auto">
            <RestaurantSearch setRestId={setRestaurantId} searchType={VendorRestaurantSearchType.TRANSACTIONS} />
          </Col>
          <Col xs={12} md={6} lg={4} xl="auto" className="no-margin custom-calendar">
            <DatePicker
              selected={startDate !== '' ? new Date(startDate) : null}
              onChange={(date: any) => handleDateChange('start', date)}
              placeholderText={useIntlMessages('Dashboard.StartDate')}
              maxDate={moment().toDate()}
              onChangeRaw={(e) => {
                e.preventDefault();
              }}
            />
          </Col>
          <Col xs={12} md={6} lg={4} xl={3} className="no-margin custom-calendar">
            <DatePicker
              selected={endDate !== '' ? new Date(endDate) : null}
              onChange={(date: any) => handleDateChange('end', date)}
              placeholderText={useIntlMessages('Dashboard.EndDate')}
              maxDate={moment().toDate()}
              minDate={startDate !== '' ? new Date(startDate) : null}
              onChangeRaw={(e) => {
                e.preventDefault();
              }}
            />
          </Col>
        </Row>
      </div>
      <Table responsive>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="Dashboard.Invoice" />
            </th>
            <th className="w-300">
              <FormattedMessage id="Restaurant.RestaurantName" />
            </th>
            <th>
              <FormattedMessage id="Dashboard.Amount" />
            </th>
            <th className="w-350">
              <FormattedMessage id="Vendor.VendorName" />
            </th>
            <th className="w-300">
              <FormattedMessage id="Dashboard.DateOnly" />
            </th>
            <th className="w-165">
              <FormattedMessage id="Dashboard.InvoiceStatus" />
            </th>
          </tr>
        </thead>
        <tbody>
          {transactionsList.map((transaction: ITransactionRow, index: number) => (
            <tr key={`${transaction.invoiceNo}${index.toString()}`}>
              <td>
                <Link to={`/home/transactiondetails/${transaction.invoiceGuid}`}>{transaction.invoiceNo}</Link>
              </td>
              <td>{transaction.restaurantName}</td>
              <td>{transaction.amount ? `$${formatNumberWithCommas(transaction.amount)}` : ''}</td>
              <td>{transaction.vendorName}</td>
              <td> {transaction.generatedOn && moment(transaction.generatedOn).local().format(DATE_TIME_FORMAT)} </td>
              <td>
                <Badge
                  bg="secondary"
                  className={
                    transaction.invoiceStatus === InvoiceStatus.DUE
                      ? transaction.dueDate === null || new Date(transaction.dueDate) > new Date()
                        ? 'badge-due-low'
                        : 'badge-due-high'
                      : 'badge-due-paid'
                  }
                >
                  {transaction.invoiceStatus === InvoiceStatus.DUE ? useIntlMessages('InvoiceStatus.Due') : useIntlMessages('InvoiceStatus.Paid')}
                </Badge>
              </td>
            </tr>
          ))}
          {transactionsList.length === 0 && (
            <tr>
              <td colSpan={8} className="text-center">
                <FormattedMessage id="Transactions.Notransactions.Message" />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <div className="content-filter-main d-lg-flex justify-content-lg-between">
        {transactionsList.length > 0 && (
          <p className="m-0">
            {stringFormat(
              transactionListTotalCount === 1 ? useIntlActionMessages('Transactions.Pagination.SingleItem.Text') : useIntlActionMessages('Transactions.Pagination.Text'),
              transactionsList.length > 0 ? ((transactionListTotalCount === 1 ? 1 : currentPage) - 1) * pageItemCount + 1 : 0,
              ((transactionListTotalCount === 1 ? 1 : currentPage) - 1) * pageItemCount + transactionsList.length,
              transactionListTotalCount,
            )}
          </p>
        )}
        {transactionsList.length > 0 && transactionListTotalCount > pageItemCount && (
          <Pagination
            totalCount={transactionListTotalCount}
            pageLimit={pageItemCount}
            setCurrentPage={(page: number) => setCurrentPage(page)}
            currentPage={currentPage - 1}
            prevPage={-1}
          />
        )}
      </div>
      {transactionsListLoading && <GifLoader />}
    </div>
  );
};

export default Transactions;
