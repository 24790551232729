import React from 'react';

const SearchIcon = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.0345 17.1408L22.3174 21.5586L20.9034 23.0171L16.6215 18.5993C14.8243 20.0803 12.5456 20.7952 10.2533 20.5973C7.96108 20.3995 5.82928 19.3038 4.29575 17.5353C2.76223 15.7668 1.94338 13.4598 2.0074 11.088C2.07141 8.71624 3.01341 6.45977 4.63994 4.78202C6.26648 3.10428 8.45407 2.13261 10.7534 2.06659C13.0528 2.00056 15.2894 2.84518 17.0039 4.42699C18.7184 6.0088 19.7807 8.20772 19.9725 10.5721C20.1644 12.9366 19.4712 15.287 18.0355 17.1408H18.0345ZM16.0285 16.3754C17.3148 15.0078 18.0248 13.1711 18.0038 11.2657C17.9828 9.36035 17.2325 7.54079 15.9164 6.20365C14.6003 4.86652 12.8251 4.12022 10.9778 4.12744C9.13045 4.13466 7.3608 4.89481 6.05454 6.24219C4.74829 7.58957 4.01134 9.41494 4.00434 11.3204C3.99735 13.2259 4.72086 15.057 6.01718 16.4145C7.31351 17.7721 9.07753 18.546 10.9247 18.5677C12.7719 18.5893 14.5526 17.8569 15.8785 16.5301L16.0285 16.3754V16.3754Z" />
    </svg>
  );
};

export default SearchIcon;
