/**
 * @file   src\store\slices\restaurantSlice.ts
 * @brief  This file is responsible for creating restaurant slices to call actions and state management.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { createSlice } from '@reduxjs/toolkit';
import {
  restaurantList,
  inviteRestaurants,
  searchRestaurants,
  getRestaurantDetails,
  saveRestaurant,
  restaurantActivateDeactivate,
  getRestaurantListForMerge,
  mergeRestaurants,
  getRestaurantUsers,
  getMappedRestaurants,
  getMergeRestaurantStatus,
  clearMergeStatus,
  getMergedRestaurants,
  getCobrowseToken,
  getRestaurantTransactions,
} from '../actions/restaurantAction';
import { IRestaurants } from '../../interfaces/restaurantInterface';
import { useIntlActionMessages } from '../../utils/helper';

// Initial state.
const restaurantState: IRestaurants = {
  restaurants: [],
  searchRestaurantList: [],
  searchedTotalCount: 0,
  searchErrorCode: 0,
  searchErrorMessage: null,
  searchLoading: false,
  searchSuccess: false,
  totalCount: 0,
  errorCode: 0,
  errorMessage: '',
  isSuccess: false,
  isLoading: false,
  inviteRestaurantSuccess: false,
  inviteRestaurantLoading: false,
  addRestaurantLoading: false,
  addRestaurantSuccess: false,
  restaurantDetail: {},
  updateStatusRestaurantLoading: false,
  updateStatusRestaurantSuccess: false,
  restaurantListMerge: [],
  mergeLoading: false,
  mergeSuccess: false,
  mergeErrorCode: 0,
  mergeErrorMessage: '',
  users: [],
  mappedRestaurants: [],
  isMappedRestaurantLoading: false,
  isMappedRestaurantSuccess: false,
  mappedRestaurantErrorCode: 0,
  mappedRestaurantErrorMessage: '',
  mergeStatusLoading: false,
  mergeStatusErrorCode: 0,
  mergeStatusSuccess: false,
  mergeStatusErrorMessage: '',
  mergeStatusResult: [],
  clearStatusSuccess: false,
  clearStatusErrorCode: 0,
  mergedRestaurants: [],
  mergedRestaurantsLoading: false,
  mergedRestaurantsSuccess: false,
  mergedRestaurantsErrorCode: 0,
  mergedRestaurantsErrorMessage: '',
  cobrowseTokenLoading: false,
  cobrowseTokenSuccess: false,
  cobrowseTokenData: {},
  cobrowseTokenErrorCode: 0,
  transactions: [],
  totalTransactions: 0,
  isTransactionLoading: false,
  isTransactionSuccess: false,
  transactionErrorCode: 0,
  transactionErrorMessage: null,
};

// Config restaurant slice.
const restaurantSlice = createSlice({
  name: 'restaurant',
  initialState: restaurantState,
  reducers: {
    resetRestaurantState: (state) => {
      state.errorCode = 0;
      state.errorMessage = '';
      state.isSuccess = false;
      state.isLoading = false;
      state.searchErrorCode = 0;
      state.searchErrorMessage = null;
      state.searchSuccess = false;
      state.searchLoading = false;
      state.inviteRestaurantSuccess = false;
      state.inviteRestaurantLoading = false;
      state.addRestaurantLoading = false;
      state.addRestaurantSuccess = false;
      state.updateStatusRestaurantLoading = false;
      state.updateStatusRestaurantSuccess = false;
      state.restaurantDetail = {};
      state.isTransactionLoading = false;
      state.isTransactionSuccess = false;
      state.transactionErrorCode = 0;
      state.transactionErrorMessage = null;
    },
    resetMergeRestaurant: (state) => {
      state.mergeStatusResult = [];
      state.mergeLoading = false;
      state.mergeSuccess = false;
      state.mergeStatusLoading = false;
      state.mergeStatusErrorMessage = '';
      state.mergeStatusErrorCode = 0;
      state.mergeStatusSuccess = false;
      state.restaurantListMerge = [];
      state.mergeErrorCode = 0;
      state.mergeErrorMessage = '';
      state.mergedRestaurants = [];
      state.mergedRestaurantsLoading = false;
      state.mergedRestaurantsSuccess = false;
      state.mergedRestaurantsErrorCode = 0;
      state.mergedRestaurantsErrorMessage = '';
    },
  },
  extraReducers(builder) {
    // Start restaurantList request.
    builder.addCase(restaurantList.pending, (state) => {
      state.isSuccess = false;
      state.isLoading = true;
      state.errorCode = 0;
      state.errorMessage = '';
    });
    // restaurantList request success.
    builder.addCase(restaurantList.fulfilled, (state, action) => {
      const data = action.payload?.data || null;
      const error = action.payload?.error || 0;
      state.restaurants = data?.restaurant || [];
      state.totalCount = data?.totalCount;
      state.isLoading = false;
      state.isSuccess = true;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // restaurantList error.
    builder.addCase(restaurantList.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.isSuccess = false;
      state.isLoading = false;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Invite restaurant request
    builder.addCase(inviteRestaurants.pending, (state) => {
      state.inviteRestaurantLoading = true;
      state.inviteRestaurantSuccess = false;
      state.errorCode = 0;
      state.errorMessage = '';
    });
    // Invite restaurant request success.
    builder.addCase(inviteRestaurants.fulfilled, (state, action) => {
      const error = action.payload?.error || 0;
      state.inviteRestaurantLoading = false;
      state.inviteRestaurantSuccess = true;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // Invite restaurant error.
    builder.addCase(inviteRestaurants.rejected, (state, action: any) => {
      const { error } = action.payload;
      state.inviteRestaurantSuccess = false;
      state.inviteRestaurantLoading = false;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Start search restaurants request.
    builder.addCase(searchRestaurants.pending, (state) => {
      state.searchSuccess = false;
      state.searchLoading = true;
      state.searchErrorCode = 0;
      state.searchErrorMessage = '';
    });
    // Search restaurants request success.
    builder.addCase(searchRestaurants.fulfilled, (state, action) => {
      const data = action.payload?.data || null;
      const error = action.payload?.error || 0;
      state.searchRestaurantList = data?.restaurant || [];
      state.searchedTotalCount = data?.totalCount;
      state.searchLoading = false;
      state.searchSuccess = true;
      state.searchErrorCode = error;
      state.searchErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // Search restaurants error.
    builder.addCase(searchRestaurants.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.searchSuccess = false;
      state.searchLoading = false;
      state.searchErrorCode = error;
      state.searchErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });

    // start restaurant detail api
    builder.addCase(getRestaurantDetails.pending, (state) => {
      state.isLoading = true;
    });

    // restaurant detail api success
    builder.addCase(getRestaurantDetails.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      state.restaurantDetail = data;
      state.isSuccess = true;
      state.isLoading = false;
    });

    // restaurant detail api failure
    builder.addCase(getRestaurantDetails.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.isLoading = false;
      state.isSuccess = false;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // start add restaurant api
    builder.addCase(saveRestaurant.pending, (state) => {
      state.addRestaurantLoading = true;
      state.addRestaurantSuccess = false;
      state.errorMessage = '';
    });

    // add restaurant api success
    builder.addCase(saveRestaurant.fulfilled, (state, { meta, payload }) => {
      const error = payload?.error || 0;
      state.addRestaurantLoading = false;
      state.addRestaurantSuccess = true;
      state.errorCode = payload?.error;
      state.errorMessage = useIntlActionMessages(error !== 0 ? error.toString() : meta.arg.restaurantId !== 0 ? 'Restaurant.Edit.Success' : 'Restaurant.Add.Success');
    });

    // add restaurant api failure
    builder.addCase(saveRestaurant.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.addRestaurantLoading = false;
      state.addRestaurantSuccess = false;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // start deactivate restaurant api
    builder.addCase(restaurantActivateDeactivate.pending, (state) => {
      state.updateStatusRestaurantLoading = true;
    });
    // deactivate restaurant api success
    builder.addCase(restaurantActivateDeactivate.fulfilled, (state, action) => {
      const error = action.payload?.error || 0;
      state.updateStatusRestaurantLoading = false;
      state.updateStatusRestaurantSuccess = true;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // deactivate restaurant api failure
    builder.addCase(restaurantActivateDeactivate.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.updateStatusRestaurantLoading = false;
      state.updateStatusRestaurantSuccess = false;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // get restaurants list for merge api starts
    builder.addCase(getRestaurantListForMerge.pending, (state) => {
      state.isLoading = true;
    });
    // get restaurants list for merge success
    builder.addCase(getRestaurantListForMerge.fulfilled, (state, action) => {
      const data = action.payload?.data;
      const error = action.payload?.error || 0;
      state.isLoading = false;
      state.isSuccess = true;
      state.restaurantListMerge = data;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // get restaurants list for merge failure
    builder.addCase(getRestaurantListForMerge.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.isLoading = true;
      state.isSuccess = false;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // merge restaurants api starts
    builder.addCase(mergeRestaurants.pending, (state) => {
      state.mergeLoading = true;
      state.mergeSuccess = false;
      state.mergeErrorMessage = '';
      state.mergeErrorCode = 0;
    });
    // merge restaurants api success
    builder.addCase(mergeRestaurants.fulfilled, (state, action) => {
      const error = action.payload?.error || 0;
      state.mergeLoading = false;
      state.mergeSuccess = true;
      state.mergeErrorCode = error;
      state.mergeErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // merge restaurants api failure
    builder.addCase(mergeRestaurants.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.mergeLoading = false;
      state.mergeSuccess = false;
      state.mergeErrorCode = error;
      state.mergeErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Get restaurant users request start
    builder.addCase(getRestaurantUsers.pending, (state) => {
      state.isSuccess = false;
      state.isLoading = true;
      state.errorCode = 0;
      state.errorMessage = '';
    });
    // Get restaurant users request success.
    builder.addCase(getRestaurantUsers.fulfilled, (state, action) => {
      const data = action.payload?.data || null;
      const error = action.payload?.error || 0;
      state.users = data?.users || [];
      state.isLoading = false;
      state.isSuccess = true;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // Get restaurant users error.
    builder.addCase(getRestaurantUsers.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.isSuccess = false;
      state.isLoading = false;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Start mapped restaurants search request.
    builder.addCase(getMappedRestaurants.pending, (state) => {
      state.isMappedRestaurantSuccess = false;
      state.isMappedRestaurantLoading = true;
      state.mappedRestaurantErrorCode = 0;
      state.mappedRestaurantErrorMessage = '';
    });
    // Mapped restaurants request success.
    builder.addCase(getMappedRestaurants.fulfilled, (state, action) => {
      const data = action.payload?.data || null;
      const error = action.payload?.error || 0;
      state.mappedRestaurants = data?.restaurant || [];
      state.isMappedRestaurantLoading = false;
      state.isMappedRestaurantSuccess = true;
      state.mappedRestaurantErrorCode = error;
      state.mappedRestaurantErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // Mapped restaurants error.
    builder.addCase(getMappedRestaurants.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.isMappedRestaurantSuccess = false;
      state.isMappedRestaurantLoading = false;
      state.mappedRestaurantErrorCode = error;
      state.mappedRestaurantErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Merge restaurant status api starts
    builder.addCase(getMergeRestaurantStatus.pending, (state) => {
      state.mergeStatusLoading = true;
      state.mergeStatusErrorCode = 0;
      state.mergeStatusSuccess = false;
      state.mergeStatusErrorMessage = '';
    });
    // Merge restaurant status api success
    builder.addCase(getMergeRestaurantStatus.fulfilled, (state, action) => {
      const error = action.payload?.error || 0;
      state.mergeStatusLoading = false;
      state.mergeStatusErrorCode = error;
      state.mergeStatusSuccess = true;
      state.mergeStatusResult = action.payload.data.mappedStatus;
      state.mergeStatusErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Merge restaurant status api failure
    builder.addCase(getMergeRestaurantStatus.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.mergeStatusLoading = false;
      state.mergeStatusErrorCode = error;
      state.mergeStatusSuccess = false;
      state.mergeStatusErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });

    // clear status api starts
    builder.addCase(clearMergeStatus.pending, (state) => {
      state.clearStatusSuccess = false;
      state.clearStatusErrorCode = 0;
    });
    // clear status api success
    builder.addCase(clearMergeStatus.fulfilled, (state, action) => {
      const error = action.payload?.error || 0;
      state.clearStatusErrorCode = error;
      state.clearStatusSuccess = true;
    });
    // clear status api failure
    builder.addCase(clearMergeStatus.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.clearStatusErrorCode = error;
      state.clearStatusSuccess = false;
    });
    // Get merged restaurants request start.
    builder.addCase(getMergedRestaurants.pending, (state) => {
      state.mergedRestaurantsSuccess = false;
      state.mergedRestaurantsLoading = true;
      state.mergedRestaurantsErrorCode = 0;
      state.mergedRestaurantsErrorMessage = '';
    });
    // Get merged restaurants request success.
    builder.addCase(getMergedRestaurants.fulfilled, (state, action) => {
      const data = action.payload?.data || null;
      const error = action.payload?.error || 0;
      state.mergedRestaurants = data?.restaurant || [];
      state.mergedRestaurantsLoading = false;
      state.mergedRestaurantsSuccess = true;
      state.mergedRestaurantsErrorCode = error;
      state.mergedRestaurantsErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // Get merged restaurants error.
    builder.addCase(getMergedRestaurants.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.mergedRestaurantsSuccess = false;
      state.mergedRestaurantsLoading = false;
      state.mergedRestaurantsErrorCode = error;
      state.mergedRestaurantsErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // get cobrowse token api starts
    builder.addCase(getCobrowseToken.pending, (state) => {
      state.cobrowseTokenData = {};
      state.cobrowseTokenLoading = true;
      state.cobrowseTokenSuccess = false;
      state.cobrowseTokenErrorCode = 0;
    });
    // get cobrowse token api success
    builder.addCase(getCobrowseToken.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error || 0;
      state.cobrowseTokenData = data;
      state.cobrowseTokenLoading = false;
      state.cobrowseTokenSuccess = true;
      state.cobrowseTokenErrorCode = error;
    });
    // get cobrowse token api failure
    builder.addCase(getCobrowseToken.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.cobrowseTokenData = {};
      state.cobrowseTokenLoading = false;
      state.cobrowseTokenSuccess = false;
      state.cobrowseTokenErrorCode = error;
    });
    // Start restaurant transactions request.
    builder.addCase(getRestaurantTransactions.pending, (state) => {
      state.isTransactionSuccess = false;
      state.isTransactionLoading = true;
      state.transactionErrorCode = 0;
      state.transactionErrorMessage = '';
    });
    // Restaurant transactions request success.
    builder.addCase(getRestaurantTransactions.fulfilled, (state, action) => {
      const data = action.payload?.data || null;
      const error = action.payload?.error || 0;
      state.transactions = data?.payments || [];
      state.totalTransactions = data?.totalCount;
      state.isTransactionLoading = false;
      state.isTransactionSuccess = true;
      state.transactionErrorCode = error;
      state.transactionErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // Restaurant transactions error.
    builder.addCase(getRestaurantTransactions.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.isTransactionSuccess = false;
      state.isTransactionLoading = false;
      state.transactionErrorCode = error;
      state.transactionErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
  },
});
// Export restaurant actions.
export const { resetRestaurantState, resetMergeRestaurant } = restaurantSlice.actions;
// Export reducer.
export default restaurantSlice.reducer;
