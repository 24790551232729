/**
 * @file   src\store\slices\vendorSlice.ts
 * @brief  This file is responsible for creating vendor slices to call actions and state management.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { createSlice } from '@reduxjs/toolkit';
import { vendorList, inviteVendor, searchVendors, vendorDetails, saveVendor, updateVendorActivateDeactivateStatus, getVendorTransactions } from '../actions/vendorAction';
import { IVendor } from '../../interfaces/vendorInterface';
import { useIntlActionMessages } from '../../utils/helper';

// Initial state.
const vendorState: IVendor = {
  vendors: [],
  totalCount: 0,
  searchVendorList: [],
  searchedTotalCount: 0,
  searchErrorCode: 0,
  searchErrorMessage: null,
  searchLoading: false,
  searchSuccess: false,
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
  inviteVendorSuccess: false,
  inviteVendorLoading: false,
  saveVendorSuccess: false,
  saveVendorLoading: false,
  isActivateSuccess: false,
  isActivateLoading: false,
  transactions: [],
  totalTransactions: 0,
  isTransactionLoading: false,
  isTransactionSuccess: false,
  transactionErrorCode: 0,
  transactionErrorMessage: null,
};

// Config vendor slice.
const vendorSlice = createSlice({
  name: 'vendor',
  initialState: vendorState,
  reducers: {
    resetVendorState: (state) => {
      state.errorCode = 0;
      state.errorMessage = null;
      state.isSuccess = false;
      state.isLoading = false;
      state.inviteVendorSuccess = false;
      state.inviteVendorLoading = false;
      state.searchErrorCode = 0;
      state.searchErrorMessage = null;
      state.searchSuccess = false;
      state.searchLoading = false;
      state.saveVendorSuccess = false;
      state.saveVendorLoading = false;
      state.isActivateLoading = false;
      state.isActivateSuccess = false;
      state.isTransactionLoading = false;
      state.isTransactionSuccess = false;
      state.transactionErrorCode = 0;
      state.transactionErrorMessage = null;
    },
  },
  extraReducers(builder) {
    // Start vendorList request.
    builder.addCase(vendorList.pending, (state) => {
      state.isSuccess = false;
      state.isLoading = true;
      state.errorCode = 0;
      state.errorMessage = '';
    });
    // vendorList request success.
    builder.addCase(vendorList.fulfilled, (state, action) => {
      const data = action.payload?.data || null;
      const error = action.payload?.error || 0;
      state.vendors = data?.vendors || [];
      state.totalCount = data?.totalCount;
      state.isLoading = false;
      state.isSuccess = true;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // vendorList error.
    builder.addCase(vendorList.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.isSuccess = false;
      state.isLoading = false;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Invite vendor request
    builder.addCase(inviteVendor.pending, (state) => {
      state.inviteVendorLoading = true;
      state.inviteVendorSuccess = false;
      state.errorCode = 0;
      state.errorMessage = '';
    });
    // Invite vendor request success.
    builder.addCase(inviteVendor.fulfilled, (state, action) => {
      const error = action.payload?.error || 0;
      state.inviteVendorLoading = false;
      state.inviteVendorSuccess = true;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // Invite vendor error.
    builder.addCase(inviteVendor.rejected, (state, action: any) => {
      const { error } = action.payload;
      state.inviteVendorSuccess = false;
      state.inviteVendorLoading = false;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Start search vendor request.
    builder.addCase(searchVendors.pending, (state) => {
      state.searchSuccess = false;
      state.searchLoading = true;
      state.searchErrorCode = 0;
      state.searchErrorMessage = '';
    });
    // Search vendor request success.
    builder.addCase(searchVendors.fulfilled, (state, action) => {
      const data = action.payload?.data || null;
      const error = action.payload?.error || 0;
      state.searchVendorList = data?.vendors || [];
      state.searchedTotalCount = data?.totalCount;
      state.searchLoading = false;
      state.searchSuccess = true;
      state.searchErrorCode = error;
      state.searchErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // Search vendor error.
    builder.addCase(searchVendors.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.searchSuccess = false;
      state.searchLoading = false;
      state.searchErrorCode = error;
      state.searchErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Start vendor details request.
    builder.addCase(vendorDetails.pending, (state) => {
      state.vendors = [];
      state.isSuccess = false;
      state.isLoading = true;
      state.errorCode = 0;
      state.errorMessage = '';
    });
    // Vendor details request success.
    builder.addCase(vendorDetails.fulfilled, (state, action) => {
      const data = action.payload?.data || null;
      const error = action.payload?.error || 0;
      state.vendors = data?.vendor ? [data.vendor] : [];
      state.isLoading = false;
      state.isSuccess = true;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // Vendor details error.
    builder.addCase(vendorDetails.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.isSuccess = false;
      state.isLoading = false;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Add, Edit vendor request
    builder.addCase(saveVendor.pending, (state) => {
      state.saveVendorSuccess = false;
      state.saveVendorLoading = true;
      state.errorCode = 0;
      state.errorMessage = '';
    });
    // Add, edit vendor request success.
    builder.addCase(saveVendor.fulfilled, (state, { meta, payload }) => {
      const error = payload?.error || 0;
      state.saveVendorSuccess = true;
      state.saveVendorLoading = false;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error !== 0 ? error.toString() : meta.arg.vendorId !== 0 ? 'Vendor.Edit.Success' : 'Vendor.Add.Success');
    });
    // Add, edit vendor error.
    builder.addCase(saveVendor.rejected, (state, action: any) => {
      const { error } = action.payload;
      state.saveVendorSuccess = false;
      state.saveVendorLoading = false;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Activate, deactivate vendor request
    builder.addCase(updateVendorActivateDeactivateStatus.pending, (state) => {
      state.isActivateLoading = true;
      state.isActivateSuccess = false;
      state.errorCode = 0;
      state.errorMessage = '';
    });
    // Activate, deactivate vendor request success.
    builder.addCase(updateVendorActivateDeactivateStatus.fulfilled, (state, { meta, payload }) => {
      const error = payload?.error || 0;
      state.isActivateLoading = false;
      state.isActivateSuccess = true;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(
        error !== 0 ? error.toString() : meta.arg.activeStatus !== 0 ? 'Vendor.Activate.Status.Success' : 'Vendor.Deactivate.Status.Success',
      );
    });
    // Activate, deactivate vendor error.
    builder.addCase(updateVendorActivateDeactivateStatus.rejected, (state, action: any) => {
      const { error } = action.payload;
      state.isActivateLoading = false;
      state.isActivateSuccess = false;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Start vendor transactions request.
    builder.addCase(getVendorTransactions.pending, (state) => {
      state.isTransactionSuccess = false;
      state.isTransactionLoading = true;
      state.transactionErrorCode = 0;
      state.transactionErrorMessage = '';
    });
    // Vendor transactions request success.
    builder.addCase(getVendorTransactions.fulfilled, (state, action) => {
      const data = action.payload?.data || null;
      const error = action.payload?.error || 0;
      state.transactions = data?.payments || [];
      state.totalTransactions = data?.totalCount;
      state.isTransactionLoading = false;
      state.isTransactionSuccess = true;
      state.transactionErrorCode = error;
      state.transactionErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // Vendor transactions error.
    builder.addCase(getVendorTransactions.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.isTransactionSuccess = false;
      state.isTransactionLoading = false;
      state.transactionErrorCode = error;
      state.transactionErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
  },
});
// Export vendor actions.
export const { resetVendorState } = vendorSlice.actions;
// Export reducer.
export default vendorSlice.reducer;
