/**
 * @file   src\features\settings\Menu.tsx
 * @brief  Settings side menu component
 * @date   Dec, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { FormattedMessage, NavLink } from '../../utils/thirdpartyComponents';
import EmailIcon from '../../assets/icons/Mail';

const Menu = () => {
  return (
    <div className="menu-wrap">
      <NavLink to="/settings" className={({ isActive }) => (isActive ? 'current' : '')} end>
        <EmailIcon />
        <FormattedMessage id="Settings.SideMenu.EmailManagement.Label" />
      </NavLink>
    </div>
  );
};

export default Menu;
