/**
 * @file   src\store\slices\settingsSlice.ts
 * @brief  This file is responsible for creating settings based slices to call actions and state management.
 * @date   Dec, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { createSlice } from '@reduxjs/toolkit';
import { ISettingsState } from '../../interfaces/settingsInterface';
import { getAllContactEmails, saveContactEmails } from '../actions/settingsAction';
import { useIntlActionMessages } from '../../utils/helper';

// Initial state
const settingsDefaultState: ISettingsState = {
  isContactEmailsLoading: false,
  isContactEmailsSuccess: false,
  contactEmailsErrorCode: 0,
  contactEmailsErrorMessage: null,
  contactEmails: [],
  saveEmailsLoading: false,
  saveEmailsSuccess: false,
  inviteVendorInvalidEmails: [],
  saveEmailsErrorCode: 0,
  saveEmailsErrorMessage: null,
};

// Settings slice
const settingsSlice = createSlice({
  name: 'settings',
  initialState: settingsDefaultState,
  reducers: {
    resetSettings: (state) => {
      state.isContactEmailsLoading = false;
      state.isContactEmailsSuccess = false;
      state.contactEmailsErrorCode = 0;
      state.contactEmailsErrorMessage = '';
      state.saveEmailsLoading = false;
      state.saveEmailsSuccess = false;
      state.saveEmailsErrorCode = 0;
      state.saveEmailsErrorMessage = null;
      state.inviteVendorInvalidEmails = [];
    },
  },
  extraReducers(builder) {
    // get all contact emails api starts.
    builder.addCase(getAllContactEmails.pending, (state) => {
      state.isContactEmailsLoading = true;
      state.isContactEmailsSuccess = false;
      state.contactEmailsErrorCode = 0;
      state.contactEmailsErrorMessage = '';
    });
    // get all contact emails api success
    builder.addCase(getAllContactEmails.fulfilled, (state, action) => {
      const error = action.payload?.error || 0;
      const data = action.payload?.data || {};
      state.isContactEmailsLoading = false;
      state.isContactEmailsSuccess = true;
      state.contactEmails = data?.email || null;
      state.contactEmailsErrorCode = error;
      state.contactEmailsErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // get all contact emails api failure
    builder.addCase(getAllContactEmails.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.isContactEmailsLoading = false;
      state.isContactEmailsSuccess = false;
      state.contactEmailsErrorCode = error;
      state.contactEmailsErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Save all contact emails api starts.
    builder.addCase(saveContactEmails.pending, (state) => {
      state.saveEmailsLoading = true;
      state.saveEmailsSuccess = false;
      state.saveEmailsErrorCode = 0;
      state.saveEmailsErrorMessage = '';
    });
    // Save all contact emails api success
    builder.addCase(saveContactEmails.fulfilled, (state, action) => {
      const error = action.payload?.error || 0;
      const data = action.payload?.data || null;
      state.saveEmailsLoading = false;
      state.saveEmailsSuccess = true;
      state.saveEmailsErrorCode = error;
      state.inviteVendorInvalidEmails = data?.invalidEmails || [];
      state.saveEmailsErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : 'Settings.EmailManagement.SaveEmail.Api.SuccessMessage');
    });
    // Save all contact emails api failure
    builder.addCase(saveContactEmails.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.saveEmailsLoading = false;
      state.saveEmailsSuccess = false;
      state.saveEmailsErrorCode = error;
      state.saveEmailsErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
  },
});
// Export actions
export const { resetSettings } = settingsSlice.actions;
// Export reducer.
export default settingsSlice.reducer;
