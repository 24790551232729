/**
 * @file   MadChef_Web\src\components\WentWrong.tsx
 * @author ZCO Engineer
 * @date   Feb, 2024
 * @brief  This file is used to catch errors while rendering the component.
 *
 */
import { FormattedMessage, Button, Col, Row } from '../utils/thirdpartyComponents';
import WentWrongImg from '../assets/WentWrong.svg';
import { useIntlActionMessages } from '../utils/helper';

const ErrorFallbackComponent = () => {
  return (
    <div className="content-nav contnt-container-margin not-found-wrap my-4">
      <div className="content-nav-sub text-center pb-5">
        <img src={WentWrongImg} alt="Something went wrong" className="wentwrong" />
        <h1 className="text-blue mb-3">{useIntlActionMessages('ErrorPage.Heading')}</h1>
        <Row className="d-flex justify-content-center">
          <Col lg={7}>
            <h6 className="text-dark">{useIntlActionMessages('ErrorPage.Content')}</h6>
          </Col>
        </Row>
        <Col>
          <Button
            variant="primary"
            className="mt-2 mb-2"
            onClick={() => {
              window.location.href = '/home';
            }}
          >
            <FormattedMessage id="ErrorPage.Button" />
          </Button>
        </Col>
      </div>
    </div>
  );
};

export default ErrorFallbackComponent;
