import React from 'react';

const PrevIcon = () => {
  return (
    <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.38664 0.369391C8.27055 0.252343 8.13243 0.159441 7.98026 0.0960411C7.82808 0.0326416 7.66486 0 7.5 0C7.33514 0 7.17192 0.0326416 7.01974 0.0960411C6.86757 0.159441 6.72945 0.252343 6.61336 0.369391L0.369391 6.61336C0.252343 6.72945 0.15944 6.86757 0.0960408 7.01974C0.0326413 7.17192 0 7.33514 0 7.5C0 7.66486 0.0326413 7.82808 0.0960408 7.98026C0.15944 8.13243 0.252343 8.27055 0.369391 8.38664L6.61336 14.6306C6.72945 14.7477 6.86757 14.8406 7.01974 14.904C7.17192 14.9674 7.33514 15 7.5 15C7.66486 15 7.82808 14.9674 7.98026 14.904C8.13243 14.8406 8.27055 14.7477 8.38664 14.6306C8.50369 14.5145 8.59659 14.3764 8.65999 14.2242C8.72339 14.072 8.75603 13.9088 8.75603 13.744C8.75603 13.5791 8.72339 13.4159 8.65999 13.2637C8.59659 13.1115 8.50369 12.9734 8.38664 12.8573L3.01683 7.5L8.38664 2.14268C8.50369 2.02659 8.59659 1.88847 8.65999 1.73629C8.72339 1.58411 8.75603 1.42089 8.75603 1.25603C8.75603 1.09118 8.72339 0.927954 8.65999 0.775777C8.59659 0.6236 8.50369 0.485482 8.38664 0.369391Z"
        fill="#489CFF"
      />
    </svg>
  );
};

export default PrevIcon;
