import React from 'react';

const EmailInvitationIcon = () => {
  return (
    <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.3333 14.6725C18.3318 14.8914 18.2443 15.1008 18.0896 15.2557C17.9349 15.4105 17.7255 15.4983 17.5067 15.5H2.49333C2.27401 15.4998 2.06375 15.4125 1.90874 15.2573C1.75374 15.1022 1.66667 14.8918 1.66667 14.6725V13.8333H16.6667V4.08333L10 10.0833L1.66667 2.58333V1.33333C1.66667 1.11232 1.75446 0.900358 1.91074 0.744078C2.06702 0.587797 2.27899 0.5 2.5 0.5H17.5C17.721 0.5 17.933 0.587797 18.0893 0.744078C18.2455 0.900358 18.3333 1.11232 18.3333 1.33333V14.6725ZM3.695 2.16667L10 7.84167L16.305 2.16667H3.695ZM0 10.5H6.66667V12.1667H0V10.5ZM0 6.33333H4.16667V8H0V6.33333Z"
        fill="#63707E"
      />
    </svg>
  );
};

export default EmailInvitationIcon;
