/**
 * @file   src\components\CheckboxLabel.tsx
 * @brief  Checkbox component.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import '../styles/ChecBoxLabel.scss';
import { Form } from 'react-bootstrap';
import { ChecBoxProperty } from '../interfaces/generalInterface';

const InputCheck = ({ id, type, name, label, checked, value, disabled, title, errorMessage, onChange }: ChecBoxProperty) => {
  return (
    <div className="form-group">
      <div className="checkbox-group">
        <span className="label d-block">{title}</span>
        <div className="check-wrap d-flex align-items-center">
          <Form.Check id={id} type={type} name={name} label={label} checked={checked} onChange={onChange} value={value} disabled={disabled} />
          {errorMessage && errorMessage !== '' && <span className="error">{errorMessage}</span>}
        </div>
      </div>
    </div>
  );
};

export default InputCheck;
