/**
 * @file   src\utils\apiUrls.ts
 * @brief  This file is responsible for defining api methods.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

// Authorization api urls.
export enum AuthApi {
  LOGIN = '/admin/validateAdminUser',
  FORGOT_PASSWORD = '/admin/forgotPassword',
  RESET_PASSWORD_TOKEN_VALIDATION = '/admin/validateResetPasswordToken',
  SEND_LOGIN_OTP = '/admin/updateOtpForLogin',
  REFRESH_TOKEN = '/admin/refreshToken',
}

// Uer api urls.
export enum UserApi {
  LOGOUT = '/admin/logout',
  RESET_PASSWORD = '/admin/resetPassword',
  ADMIN_PROFILE = '/admin/profile',
  SAVE_ADMIN_PROFILE = '/admin/updateProfile',
  MODIFY_PASSWORD = '/admin/modifyPassword',
  GET_USERS_LIST = '/admin/users',
  ADD_USER = '/admin/saveUser',
  DELETE_USER = '/admin/removeUser',
  RESEND_EMAIL = '/admin/resendPasswordLinkEmail',
  GET_USER_DETAILS = '/admin/userProfile/',
}

// Vendor api urls.
export enum VendorApi {
  VENDOR_LIST = '/admin/vendorListing',
  VENDOR_INVITE = '/admin/vendorInvite',
  VENDOR_DETAILS = '/admin/vendor',
  VENDOR_ADD = '/admin/vendor',
  VENDOR_ACTIVATE_DEACTIVATE = '/admin/vendorUpdateActiveStatus',
  VENDOR_TRANSACTIONS_LIST = '/admin/vendorPayments',
}

// Common api urls.
export enum CommonApi {
  SUBSCRIPTION_PLANS = '/admin/plans',
  USER_SUBSCRPTIONS = 'admin/user/subscriptions',
}

// Restaurant api urls.
export enum RestaurantApi {
  RESTAURANT_LIST = '/admin/restaurants',
  RESTAURANT_INVITE = '/admin/restaurantInvite',
  RESTAURANT_DETAILS = '/admin/restaurant',
  RESTAURANT_ADD = '/admin/saveRestaurant',
  RESTAURANT_ACTIVATE_DEACTIVATE = '/admin/resturantUpdateActiveStatus',
  RESTAURANT_LIST_MERGE = '/admin/restaurantsForMerging',
  RESTAURANT_USERS = '/admin/restaurantUsers',
  RESTAURANT_MAPPED = '/admin/mappedRestaurants',
  RESTAURANT_MERGE = '/admin/restaurantsMerge',
  RESTAURANT_MERGE_STATUS = '/admin/mappedRestaurantsStatus',
  CLEAR_MERGE_STATUS = '/admin/removeRestaurantsMerge',
  MERGED_RESTAURANTS = '/admin/mergedRestaurants',
  GET_COBROWSE_TOKEN = '/admin/cobrowseToken',
  RESTAURANT_TRANSACTIONS_LIST = '/admin/restaurantPayments',
}

// Product api urls.
export enum ProductApi {
  PRODUCT_LIST = '/admin/itemsList',
  PRODUCT_GROUPS = '/admin/productGroups',
  PRODUCT_ORIGINS = '/admin/originCountries',
  PRODUCT_DETAILS = '/admin/itemDetails',
  PRODUCT_MAPPED_ITEMS = '/admin/similarItemsForMapping',
  PRODUCT_MAPPED_DETAILS = '/admin/similarItemMappingDetails',
  USER_MAPPED_ITEMS_LIST = '/admin/similarItemsForMappingByUser',
  USER_MAPPED_ORIGINAL_ITEMS = '/admin/originalitems',
  USER_MAPPED_SIMILAR_ITEMS = '/admin/similarItems',
  APPROVE_DECLINE_MAP_ITEMS = '/admin/changeItemMappingStatus',
}

// Role based api urls.
export enum RoleApi {
  ROLES_LIST = '/admin/roles',
  ROLES_SAVE = '/admin/saveRole',
  ROLES_DELETE = '/admin/removeRole',
  ROLE_PERMISSIONS = '/admin/permissions',
  ROLE_NOTIFICATIONS = '/admin/notifications',
  ROLE_DETAILS = '/admin/role',
}

// Subscription based api urls
export enum SubscriptionApi {
  SUBSCRIPTION_SETTINGS = '/admin/setting',
  UPDATE_SETTINGS = '/admin/updateSetting',
  INCENTIVES_LIST = '/admin/similarItemIncentive',
  MAPPED_USERS = '/admin/similarItemIncentiveUsers',
  INCENTIVES_PAYMENT = '/admin/itemIncentivePay',
}

// Dashboard based api urls
export enum DashboardApi {
  ORDERS_LIST = '/admin/order/list',
  TRANSACTIONS_LIST = '/admin/invoice/list',
  ORDER_DETAILS = '/admin/order/details',
  INVOICE_DETAILS = '/admin/invoice/details/',
  INVOICE_PDF = '/admin/invoice/generatePdf/',
  ORDER_PDF = '/admin/order/generatePdf/',
  ORDER_GRAPH = '/admin/order/dashboardChart',
  INVOICE_GRAPH = '/admin/invoice/dashboardChart',
  RESTAURANT_VENDOR_GRAPH = '/admin/user/dashboardChart',
  RESTAURANT_LIST = '/admin/dashboardRestaurants',
  VENDORS_LIST = '/admin/dashboardVendors',
  SEARCH_VENDORS = '/admin/searchVendors',
  SEARCH_RESTAURANTS = '/admin/searchRestaurants',
  DASHBOARD_CHART_DATE_LIMIT = '/admin/user/dashboardChartDateLimit',
}

// Onboarding based api urls
export enum OnbaordingApi {
  ONBOARDING_LIST = '/admin/onboardingList',
  ONBAORDING_VENDORS = '/admin/onboarding/vendorList',
  ONBAORDING_MANAGERS = '/admin/onboarding/managerList',
  ONBOARDING_ASSIGN_MANAGER = '/admin/onboarding/assignManager',
  ONBOARDING_UPDATE_STATUS = '/admin/onboarding/changeStatus',
  ONBOARDING_DELETE_VENDOR = '/admin/onboarding/removeDemoVendor',
}

// Role permissions based api urls.
export enum RolePermissionsApi {
  ROLES_LIST = '/admin/role/list',
  ROLES_PERMISSIONS = '/admin/roleDetails',
  SAVE_PERMISSIONS = '/admin/role/save',
  REMOVE_PERMISSIONS = '/admin/role/remove',
}

// Settings based api urls
export enum SettingsApi {
  GET_ALL_CONTACT_EMAILS = '/admin/emailSetting',
  SAVE_EMAILS = '/admin/saveEmailSetting',
}
