/**
 * @file   src\components\ModalWindow.tsx
 * @brief Component to show modal window
 * @date   October, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { Modal, FormattedMessage, Button } from '../utils/thirdpartyComponents';

const ModalWindow = ({ modalShow, onHide, className, size, title, body, onCancel, onOkay }: any) => {
  return (
    <Modal show={modalShow} onHide={() => onHide()} centered className={className} size={size}>
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-sm">
          <FormattedMessage id={title} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormattedMessage id={body} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" className="btn-modal-footer" onClick={() => onCancel()}>
          <FormattedMessage id="Button.Cancel" />
        </Button>
        <Button variant="primary" onClick={() => onOkay()}>
          <FormattedMessage id="Button.OK" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalWindow;
