/**
 * @file   src\store\actions\vendorAction.ts
 * @brief  This file is responsible for creating asynchronous vendor api call.
 * @date   May, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../config/axiosConfig';
import { IvendorRequest, IVendorInviteRequest, IVendorAddEditRequest, IvendorActivateRequest, IVendorTransactionRequest } from '../../interfaces/vendorInterface';
import { VendorApi } from '../../utils/apiUrls';

// List all vendors api call.
export const vendorList = createAsyncThunk('/vendor/vendorList', async (request: IvendorRequest, { rejectWithValue }) => {
  try {
    let param = '';
    param += request.searchText ? `searchText=${encodeURIComponent(request.searchText.replace(/\\/g, '\\\\').replace(/_/g, '\\_').replace(/%/g, '\\%'))}&` : '';
    param += `vendorId=${request.vendorId}&`;
    param += `subscriptionPlanId=${request.subscriptionPlanId}&`;
    param += `showDeactivateOnly=${request.showDeactivateOnly}&`;
    param += `pageSize=${request.pageSize}&`;
    param += `pageIndex=${request.pageIndex}&`;
    param += `sortField=${request.sortField}&`;
    param += `sortOrder=${request.sortOrder}`;
    const { data } = await axios.get(`${VendorApi.VENDOR_LIST}?${param}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});
// Invite vendor api call.
export const inviteVendor = createAsyncThunk('/vendor/inviteVendor', async (request: IVendorInviteRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(VendorApi.VENDOR_INVITE, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});
// Search vendors api call.
export const searchVendors = createAsyncThunk('/vendor/searchVendors', async (request: IvendorRequest, { rejectWithValue }) => {
  try {
    let param = '';
    param += request.searchText ? `searchText=${request.searchText}&` : '';
    param += `vendorId=${request.vendorId}&`;
    param += `subscriptionPlanId=${request.subscriptionPlanId}&`;
    param += `showDeactivateOnly=${request.showDeactivateOnly}&`;
    param += `pageSize=${request.pageSize}&`;
    param += `pageIndex=${request.pageIndex}&`;
    param += `sortField=${request.sortField}&`;
    param += `sortOrder=${request.sortOrder}`;
    const { data } = await axios.get(`${VendorApi.VENDOR_LIST}?${param}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});
// Get vendor details api call.
export const vendorDetails = createAsyncThunk('/vendor/vendorDetails', async (vendorId: number, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${VendorApi.VENDOR_DETAILS}/${vendorId}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});
// Add, edit vendor api call.
export const saveVendor = createAsyncThunk('/vendor/saveVendor', async (vendorRequest: IVendorAddEditRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(VendorApi.VENDOR_ADD, vendorRequest);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});
// Activate, deactivate vendor api call.
export const updateVendorActivateDeactivateStatus = createAsyncThunk(
  '/vendor/updateVendorActivateDeactivateStatus',
  async (vendorRequest: IvendorActivateRequest, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(VendorApi.VENDOR_ACTIVATE_DEACTIVATE, vendorRequest);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  },
);

// List all vendor transactions api call.
export const getVendorTransactions = createAsyncThunk('/vendor/getVendorTransactions', async (request: IVendorTransactionRequest, { rejectWithValue }) => {
  try {
    let param = '';
    param += `?vendorId=${request.vendorId}&`;
    param += `startDate=${request.startDate}&`;
    param += `endDate=${request.endDate}&`;
    param += `pageSize=${request.pageSize}&`;
    param += `pageIndex=${request.pageIndex}&`;
    param += `sortField=${request.sortField}&`;
    param += `sortOrder=${request.sortOrder}`;
    const { data } = await axios.get(`${VendorApi.VENDOR_TRANSACTIONS_LIST}?${param}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});
