/**
 * @file   src\features\NewPassword.tsx
 * @brief  This file is responsible for reset password.
 * @date   May, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import ReCAPTCHA from 'react-google-recaptcha';
import { useState, useEffect, useSearchParams, Container, Button, Row, Col, Link, FormattedMessage, React, useNavigate, useRef } from '../utils/thirdpartyComponents';
import '../styles/Onboard.scss';
import { useIntlMessages, setLocalStorage } from '../utils/helper';
import PasswordIcon from '../assets/icons/Password';
import Logo from '../assets/Logo.svg';
import PassowrdIcon from '../components/PasswordIcon';
import ShowPassIcon from '../assets/icons/ShowPassword';
import HidePassIcon from '../assets/icons/HidePassword';
import { validateResetPasswordToken } from '../store/actions/authAction';
import { resetPassword } from '../store/actions/userAction';
import { useAppDispatch, useAppSelector } from '../hooks';
import { RootState } from '../store';
import { IResetValidationRequest } from '../interfaces/authInterface';
import { IResetRequest } from '../interfaces/userInterface';
import GifLoader from '../components/GifLoader';
import { validateForm } from '../utils/formValidation';
import { RESET_PASSWORD_SCHEMA } from '../validations/userSchema';
import { MessageToaster } from '../utils/toastUtil';

// Toast object creation.
const toast = new MessageToaster();

const NewPassword = () => {
  // Navigate object creation.
  const navigate = useNavigate();

  // Access url params.
  const [searchParams] = useSearchParams();
  const id = searchParams.get('userId');
  const token = searchParams.get('token');
  const reCaptchaRef = useRef<ReCAPTCHA>(null);

  // Declare action dispatch.
  const dispatch = useAppDispatch();
  const { userId, isLoading, isSuccess, errorCode, errorMessage } = useAppSelector((state: RootState) => state.auth);
  const { resetLoading, resetSuccess, errorCode: resetError, errorMessage: resetMessage } = useAppSelector((state: RootState) => state.user);

  // Initialize component stat variables.
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [errorFields, setErrorFields] = useState<any>({});

  // Handle component side effects. Validate reset password token action call.
  useEffect(() => {
    if (id && token) {
      dispatch(validateResetPasswordToken({ userId: id, resetToken: token } as IResetValidationRequest));
    }
  }, []);

  // Handle side effects based on reset status state change.
  useEffect(() => {
    if (resetSuccess && resetError === 0 && resetMessage) {
      setNewPassword('');
      setConfirmPassword('');
      toast.toastSuccess(resetMessage);
      navigate('/');
    } else if (resetError > 0 && resetMessage) {
      toast.toastError(resetMessage);
    }
  }, [resetSuccess, resetError]);

  // Show/hide password text.
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  // Show/hide confirmation password text.
  const toggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  // New password text change event.
  const onPasswordChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const resetFormData = {
      [name]: value,
    };
    if (confirmPassword !== '') {
      Object.assign(resetFormData, {
        confirmPassword,
      });
    }
    const errorresult = await validateForm(resetFormData, RESET_PASSWORD_SCHEMA, errorFields);
    setNewPassword(value);
    setErrorFields(errorresult);
  };

  // Confirm password text change event.
  const onConfirmPasswordChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const resetFormData = {
      [name]: value,
    };
    if (newPassword !== '') {
      Object.assign(resetFormData, {
        newPassword,
      });
    }
    const errorresult = await validateForm(resetFormData, RESET_PASSWORD_SCHEMA, errorFields);
    setConfirmPassword(value);
    setErrorFields(errorresult);
  };

  // Reset password submit function.
  const onSubmit = async () => {
    const resetFormData = {
      newPassword,
      confirmPassword,
    };
    const errorresult = await validateForm(resetFormData, RESET_PASSWORD_SCHEMA, errorFields);
    setErrorFields(errorresult);
    if (Object.keys(errorresult).length === 0) {
      let recaptchaToken: any = '';
      if (reCaptchaRef.current) recaptchaToken = await reCaptchaRef.current.executeAsync();
      setLocalStorage('MCSA_UDT', JSON.stringify({ capToken: recaptchaToken }));
      dispatch(resetPassword({ userId, password: newPassword, resetToken: token } as IResetRequest));
    }
  };

  return (
    <Container fluid>
      <Row className="onboard-wrap">
        <Col lg="6" className="d-flex justify-content-center align-items-center logo-wrap">
          <img src={Logo} alt="MADCHEF" />
          <div className="pattern" />
        </Col>
        <Col lg="6" className="d-flex justify-content-center align-items-center form-container">
          {!isLoading && isSuccess && errorCode === 0 && (
            <div className="form-wrap">
              <h1 className="text-center">
                <FormattedMessage id="Recover.HDNewPassword" />
              </h1>
              <form name="newpassword">
                <PassowrdIcon
                  id="newPassword"
                  name="newPassword"
                  type={showPassword ? 'text' : 'password'}
                  placeholder={useIntlMessages('Recover.NewPassword.Placeholder')}
                  icon={<PasswordIcon />}
                  value={newPassword}
                  showpass={showPassword ? <HidePassIcon togglePassword={() => togglePassword()} /> : <ShowPassIcon togglePassword={() => togglePassword()} />}
                  onChange={(e) => onPasswordChange(e)}
                  errorMessage={errorFields?.newPassword}
                />
                <PassowrdIcon
                  id="confirmPassword"
                  name="confirmPassword"
                  type={showConfirmPassword ? 'text' : 'password'}
                  placeholder={useIntlMessages('Recover.ReenterPassword.Placeholder')}
                  icon={<PasswordIcon />}
                  value={confirmPassword}
                  showpass={showPassword ? <HidePassIcon togglePassword={() => toggleConfirmPassword()} /> : <ShowPassIcon togglePassword={() => toggleConfirmPassword()} />}
                  onChange={(e) => onConfirmPasswordChange(e)}
                  errorMessage={errorFields?.confirmPassword}
                />
                <div className="login-actions d-flex justify-content-between">
                  <Link to="/">
                    <FormattedMessage id="Recover.Backto" />
                  </Link>
                </div>
                <Button variant="primary" className="w-100" onClick={onSubmit}>
                  <FormattedMessage id="Button.Confirm" />
                </Button>
                <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY || ''} size="invisible" ref={reCaptchaRef} />
              </form>
            </div>
          )}
          {!isLoading && isSuccess && errorCode > 0 && <div>{errorMessage}</div>}
        </Col>
      </Row>
      {(isLoading || resetLoading) && <GifLoader />}
    </Container>
  );
};

export default NewPassword;
