import React from 'react';

const BMIcon = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.8335 16.4998H8.25016V18.3332H1.8335V16.4998ZM1.8335 10.0832H10.0835V11.9165H1.8335V10.0832ZM1.8335 3.6665H20.1668V5.49984H1.8335V3.6665ZM18.9513 11.9394L20.011 11.581L20.9277 13.1687L20.088 13.9066C20.1937 14.4077 20.1937 14.9253 20.088 15.4264L20.9277 16.1643L20.011 17.752L18.9513 17.3936C18.5755 17.7328 18.1282 17.994 17.6359 18.1544L17.4168 19.2498H15.5835L15.3635 18.1535C14.877 17.9945 14.4292 17.7353 14.049 17.3927L12.9893 17.752L12.0727 16.1643L12.9123 15.4264C12.8066 14.9253 12.8066 14.4077 12.9123 13.9066L12.0727 13.1687L12.9893 11.581L14.049 11.9394C14.4248 11.6003 14.8722 11.339 15.3644 11.1786L15.5835 10.0832H17.4168L17.6368 11.1795C18.1282 11.339 18.5755 11.6012 18.9513 11.9403V11.9394ZM16.5002 16.4998C16.9864 16.4998 17.4527 16.3067 17.7965 15.9629C18.1403 15.619 18.3335 15.1527 18.3335 14.6665C18.3335 14.1803 18.1403 13.714 17.7965 13.3701C17.4527 13.0263 16.9864 12.8332 16.5002 12.8332C16.0139 12.8332 15.5476 13.0263 15.2038 13.3701C14.86 13.714 14.6668 14.1803 14.6668 14.6665C14.6668 15.1527 14.86 15.619 15.2038 15.9629C15.5476 16.3067 16.0139 16.4998 16.5002 16.4998Z"
        fill="#63707E"
      />
    </svg>
  );
};

export default BMIcon;
