/**
 * @file   src\store\slices\roleSlice.ts
 * @brief  This file is responsible for creating role based slices to call actions and state management.
 * @date   July, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { createSlice } from '@reduxjs/toolkit';
import { getRoles, saveRole, deleteRole, getPermissions, getNotifications, getRoleById } from '../actions/roleAction';
import { IRolesState } from '../../interfaces/roleInterface';
import { useIntlActionMessages } from '../../utils/helper';

// Initial state.
const roleState: IRolesState = {
  roles: [],
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
  deleteRoleErrorCode: 0,
  deleteRoleErrorMessage: null,
  deleteRoleLoading: false,
  deleteRoleSuccess: false,
  permissions: [],
  isPermissionLoading: false,
  isPermissionSuccess: false,
  permissionErrorCode: 0,
  permissionErrorMessage: '',
  notifications: [],
  isNotificationLoading: false,
  isNotificationSuccess: false,
  notificationErrorCode: 0,
  notificationErrorMessage: '',
  roleDetails: null,
  isDetailsLoading: false,
  isDetailsSuccess: false,
  detailsErrorCode: 0,
  detailsErrorMessage: '',
  saveRoleSuccess: false,
  saveRoleErrorCode: 0,
  saveRoleErrorMessage: '',
  users: [],
};

// Config role slice.
const roleSlice = createSlice({
  name: 'role',
  initialState: roleState,
  reducers: {
    resetRole: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorCode = 0;
      state.errorMessage = '';
      state.deleteRoleLoading = false;
      state.deleteRoleSuccess = false;
      state.deleteRoleErrorCode = 0;
      state.deleteRoleErrorMessage = '';
      state.isPermissionLoading = false;
      state.isPermissionSuccess = false;
      state.permissionErrorCode = 0;
      state.permissionErrorMessage = '';
      state.isNotificationLoading = false;
      state.isNotificationSuccess = false;
      state.notificationErrorCode = 0;
      state.notificationErrorMessage = '';
      state.isDetailsLoading = false;
      state.isDetailsSuccess = false;
      state.detailsErrorCode = 0;
      state.detailsErrorMessage = '';
      state.saveRoleSuccess = false;
      state.saveRoleErrorCode = 0;
      state.saveRoleErrorMessage = '';
      state.roleDetails = null;
      state.users = [];
    },
  },
  extraReducers(builder) {
    // Start get user roles request.
    builder.addCase(getRoles.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.errorCode = 0;
      state.errorMessage = '';
    });
    // Get user roles reqest success.
    builder.addCase(getRoles.fulfilled, (state, action) => {
      const error = action.payload?.error || 0;
      const data = action.payload?.data || [];
      state.isLoading = false;
      state.isSuccess = true;
      state.roles = data?.roles || [];
      state.roleDetails = null;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // Get user roles error.
    builder.addCase(getRoles.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.isLoading = false;
      state.isSuccess = false;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Start save/update roles request.
    builder.addCase(saveRole.pending, (state) => {
      state.isLoading = true;
      state.saveRoleSuccess = false;
      state.saveRoleErrorCode = 0;
      state.saveRoleErrorMessage = '';
    });
    // Save/update roles reqest success.
    builder.addCase(saveRole.fulfilled, (state, { meta, payload }) => {
      const error = payload?.error || 0;
      state.isLoading = false;
      state.saveRoleSuccess = true;
      state.saveRoleErrorCode = error;
      state.saveRoleErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : meta.arg.roleId !== 0 ? 'Role.Text.Edited' : '');
    });
    // Save/update roles error.
    builder.addCase(saveRole.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.isLoading = false;
      state.saveRoleSuccess = false;
      state.saveRoleErrorCode = error;
      state.saveRoleErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Start delete user roles request.
    builder.addCase(deleteRole.pending, (state) => {
      state.deleteRoleLoading = true;
      state.deleteRoleSuccess = false;
      state.deleteRoleErrorCode = 0;
      state.deleteRoleErrorMessage = '';
    });
    // Delete user roles reqest success.
    builder.addCase(deleteRole.fulfilled, (state, action) => {
      const error = action.payload?.error || 0;
      const data = action.payload?.data || [];
      state.deleteRoleLoading = false;
      state.deleteRoleSuccess = true;
      state.users = data?.users || [];
      state.deleteRoleErrorCode = error;
      state.deleteRoleErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // Delete user roles error.
    builder.addCase(deleteRole.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.deleteRoleLoading = false;
      state.deleteRoleSuccess = false;
      state.deleteRoleErrorCode = error;
      state.deleteRoleErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Start get role permissions request.
    builder.addCase(getPermissions.pending, (state) => {
      state.isPermissionLoading = true;
      state.isPermissionSuccess = false;
      state.permissionErrorCode = 0;
      state.permissionErrorMessage = '';
    });
    // Get role permissions reqest success.
    builder.addCase(getPermissions.fulfilled, (state, action) => {
      const error = action.payload?.error || 0;
      const data = action.payload?.data || [];
      state.isPermissionLoading = false;
      state.isPermissionSuccess = true;
      state.permissions = data?.permissions || [];
      state.permissionErrorCode = error;
      state.permissionErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // Get role permissions error.
    builder.addCase(getPermissions.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.isPermissionLoading = false;
      state.isPermissionSuccess = false;
      state.permissionErrorCode = error;
      state.permissionErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Start get role notifications request.
    builder.addCase(getNotifications.pending, (state) => {
      state.isNotificationLoading = true;
      state.isNotificationSuccess = false;
      state.notificationErrorCode = 0;
      state.notificationErrorMessage = '';
    });
    // Get role notifications reqest success.
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      const error = action.payload?.error || 0;
      const data = action.payload?.data || [];
      state.isNotificationLoading = false;
      state.isNotificationSuccess = true;
      state.notifications = data?.notifications || [];
      state.notificationErrorCode = error;
      state.notificationErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // Get role notifications error.
    builder.addCase(getNotifications.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.isNotificationLoading = false;
      state.isNotificationSuccess = false;
      state.notificationErrorCode = error;
      state.notificationErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Start get role details request.
    builder.addCase(getRoleById.pending, (state) => {
      state.isDetailsLoading = true;
      state.isDetailsSuccess = false;
      state.detailsErrorCode = 0;
      state.detailsErrorMessage = '';
    });
    // Get role details reqest success.
    builder.addCase(getRoleById.fulfilled, (state, action) => {
      const error = action.payload?.error || 0;
      state.isDetailsLoading = false;
      state.isDetailsSuccess = true;
      state.roleDetails = action.payload?.data || {};
      state.detailsErrorCode = error;
      state.detailsErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // Get role details error.
    builder.addCase(getRoleById.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.isDetailsLoading = false;
      state.isDetailsSuccess = false;
      state.detailsErrorCode = error;
      state.detailsErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
  },
});
// Export role actions.
export const { resetRole } = roleSlice.actions;
// Export reducer.
export default roleSlice.reducer;
