/**
 * @file   src\features\dashboard\TransactionAreaGraph.tsx
 * @brief  Transactions Area Graph Component
 * @date   August, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { useState, useEffect } from '../../utils/thirdpartyComponents';
import { getDayMonth, getMonth, getColor, getWeekDay } from '../../utils/helper';
import { DateFilterOptions } from '../../utils/enums';
import { IGraphComponent, IGraphDataItem } from '../../interfaces/dashboardInterface';

const TransactionAreaGraph = ({ graphData, filter }: IGraphComponent) => {
  // data for the graph
  const [data, setData] = useState<any>({
    series: [
      {
        name: 'Transactions',
        data: [],
      },
    ],
  });
  // Options for area graph .Options specify color,theme,data labels,tooltip etc...
  const [options, setOptions] = useState<ApexOptions>({
    chart: {
      height: 350,
      animations: {
        enabled: false,
      },
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
    },
    markers: {
      size: 4,
      hover: {
        size: 9,
      },
    },
    tooltip: {
      intersect: true,
      shared: false,
    },
    theme: {
      palette: 'palette1',
    },
    yaxis: {
      labels: {
        formatter(value) {
          return `$${value}`;
        },
      },
    },
    noData: {
      text: 'No data available',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: 'Red',
        fontSize: '14px',
        fontFamily: 'Montserrat',
      },
    },
    colors: ['#2E93fA'],
  });
  // Set data for graph
  useEffect(() => {
    if (filter === DateFilterOptions.YEAR) {
      setData({
        series: [
          {
            name: 'Transactions',
            data: graphData.map((item: IGraphDataItem) => ({
              x: getMonth(item.month),
              y: item.amount,
            })),
          },
        ],
      });
    } else if (filter === DateFilterOptions.THIS_MONTH) {
      setData({
        series: [
          {
            name: 'Transactions',
            data: graphData.map((item: IGraphDataItem) => ({
              x: getDayMonth(item.date),
              y: item.amount,
            })),
          },
        ],
      });
    } else {
      setData({
        series: [
          {
            name: 'Transactions',
            data: graphData.map((item: IGraphDataItem) => ({
              x: getWeekDay(item.date),
              y: item.amount,
            })),
          },
        ],
      });
    }
    setOptions({ ...options, colors: [getColor(filter)] });
  }, [graphData]);
  return <ReactApexChart options={options} series={data.series} type="area" height={350} />;
};

export default TransactionAreaGraph;
