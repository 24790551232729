/**
 * @file   src\validations\settingsSchema.ts
 * @brief  This file is responsible for defining settings section validation schemas.
 * @date   Dec, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import * as yup from 'yup';
import { useIntlActionMessages } from '../utils/helper';
import { EMAIL_REGEX } from '../utils/constants';

// Settings - Add email form validation schema.
export const SETTINGS_ADDEMAIL_FORM_SCHEMA = yup.object({
  newEmail: yup
    .string()
    .trim()
    .matches(EMAIL_REGEX, useIntlActionMessages('Form.Email.Valid'))
    .max(200, useIntlActionMessages('Form.Email.MaxLength'))
    .required(useIntlActionMessages('Email.Required')),
});
