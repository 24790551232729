/**
 * @file   src\features\dashboard\OrdersBarGraph.tsx
 * @brief  Orders Bar Graph Component
 * @date   August, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { useState, useEffect } from '../../utils/thirdpartyComponents';
import { getDayMonth, getMonth, getColor, getWeekDay } from '../../utils/helper';
import { IGraphComponent, IGraphDataItem } from '../../interfaces/dashboardInterface';
import { DateFilterOptions } from '../../utils/enums';

const OrderBarGraph = ({ graphData, filter }: IGraphComponent) => {
  const [data, setData] = useState<any>({
    series: [
      {
        name: 'Orders',
        data: [],
      },
    ],
  });
  // Options for displaying the graph
  const [options, setOptions] = useState<ApexOptions>({
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 3,
        dataLabels: {
          position: 'top', // top, center, bottom
        },
        columnWidth: '8px',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: [],
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: (val) => {
          return `${val}`;
        },
      },
    },
    noData: {
      text: 'No data available',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: 'Red',
        fontSize: '14px',
        fontFamily: 'Montserrat',
      },
    },
    colors: [],
  });
  // Set data for the graph
  useEffect(() => {
    setData({
      series: [
        {
          name: 'Orders',
          data: graphData.map((item: IGraphDataItem) => item.count),
        },
      ],
    });
    if (filter === DateFilterOptions.YEAR) {
      setOptions({
        ...options,
        xaxis: {
          categories: graphData.map((item: IGraphDataItem) => getMonth(item.month)),
        },
        colors: [getColor(filter)],
      });
    } else if (filter === DateFilterOptions.THIS_MONTH) {
      setOptions({
        ...options,
        xaxis: {
          categories: graphData.map((item: IGraphDataItem) => getDayMonth(item.date)),
        },
        colors: [getColor(filter)],
      });
    } else {
      setOptions({
        ...options,
        xaxis: {
          categories: graphData.map((item: IGraphDataItem) => getWeekDay(item.date)),
        },
        colors: [getColor(filter)],
      });
    }
  }, [graphData]);
  return <ReactApexChart options={options} series={data.series} type="bar" height={350} />;
};

export default OrderBarGraph;
