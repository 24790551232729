/**
 * @file   src\features\dashboard\TransactionDetails.tsx
 * @brief  Transactions Details Page
 * @date   August, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { FormattedMessage, Table, moment, Button, OverlayTrigger, Tooltip, useEffect, useParams, Link } from '../../utils/thirdpartyComponents';
import { RootState } from '../../store';
import { useIntlMessages, formatPhoneNumber } from '../../utils/helper';
import FileIcon from '../../assets/icons/File';
import { IInvoiceOrderItem } from '../../interfaces/dashboardInterface';
import { getInvoiceDetails, generateInvoicePdf } from '../../store/actions/dashboardActions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import GifLoader from '../../components/GifLoader';
import { MessageToaster } from '../../utils/toastUtil';
import { resetInvoicePdf } from '../../store/slices/dashboardSlice';
import { DATE_FORMAT } from '../../utils/constants';
import BackIcon from '../../assets/icons/Back';

// Toast object creation.
const toast = new MessageToaster();

const TransactionDetails = () => {
  // Route information variable
  const params = useParams();
  // Create action dispatch
  const dispatch = useAppDispatch();
  // Access redux state variables
  const {
    invoiceDetails,
    invoiceDetailsLoading,
    invoiceDetailsErrorCode,
    invoicePdfErrorCode,
    invoiceDetailsErrorMessage,
    invoicePdfLoading,
    invoicePdfData,
    invoicePdfSuccess,
    invoicePdfErrorMessage,
  } = useAppSelector((state: RootState) => state.dashboard);

  // Scroll to top position when page loads
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // Fetch invoice details on initial loading
  useEffect(() => {
    if (params.invoiceGuid) {
      dispatch(getInvoiceDetails(params.invoiceGuid));
    }
  }, []);
  // Open pdf generated in new page
  useEffect(() => {
    if (invoicePdfSuccess) {
      const fileUrl = invoicePdfData.invoiceGenerated.url;
      window.open(fileUrl, '_blank', 'noopener,noreferrer');
      dispatch(resetInvoicePdf());
    }
  }, [invoicePdfSuccess]);
  // Show error message when api fails
  useEffect(() => {
    if (invoiceDetailsErrorCode > 0 && invoiceDetailsErrorMessage) {
      toast.toastError(invoiceDetailsErrorMessage);
    }
    if (invoicePdfErrorCode > 0 && invoicePdfErrorMessage) {
      toast.toastError(invoicePdfErrorMessage);
    }
  }, [invoiceDetailsErrorCode, invoicePdfErrorCode, invoiceDetailsErrorMessage, invoicePdfErrorMessage]);
  // Create invoice pdf
  const createPdf = () => {
    const timeoffset = moment().utcOffset();
    dispatch(generateInvoicePdf(`${params.invoiceGuid}/${timeoffset}`));
  };
  return (
    <div className="content-nav contnt-container-margin">
      <div className="back-navigation">
        <Link to="/home" className="back-btn">
          <BackIcon />
          <FormattedMessage id="Link.Back" />
        </Link>
      </div>
      <div className="content-nav-sub pb-4">
        <div className="content-nav-header d-flex justify-content-between align-items-center">
          <div>
            <h2 className="mb-1">
              <FormattedMessage id="Invoice.Invoicedetails.Sideheading.Label" /> #{invoiceDetails.invoice && invoiceDetails.invoice.invoiceNo}{' '}
              <span
                className={
                  invoiceDetails.invoice && invoiceDetails.invoice.invoiceStatus === 1
                    ? invoiceDetails.invoice.dueDate == null || new Date(invoiceDetails.invoice.dueDate) > new Date()
                      ? 'text-yellow ms-2'
                      : 'text-red ms-2'
                    : 'text-green ms-2'
                }
              >
                ({invoiceDetails.invoice && invoiceDetails.invoice.invoiceStatus === 1 ? useIntlMessages('InvoiceStatus.Due') : useIntlMessages('InvoiceStatus.Paid')})
              </span>
            </h2>
            <p className="mb-0">
              <b>
                <FormattedMessage id="Datelabel" />:
              </b>{' '}
              {invoiceDetails.invoice && moment(invoiceDetails.invoice.createdOn).local().format(DATE_FORMAT)}
            </p>
          </div>
          <div>
            <Button
              className="btn-icononly"
              onClick={() => {
                createPdf();
              }}
            >
              <OverlayTrigger
                overlay={
                  <Tooltip id="tooltip">
                    <FormattedMessage id="PDF.Tooltip" />
                  </Tooltip>
                }
                placement="top"
              >
                <div>
                  <FileIcon />
                </div>
              </OverlayTrigger>
            </Button>
          </div>
        </div>
        <div className="content-nav-header d-flex justify-content-between">
          <div className="margin-pb-10-container">
            <h5>
              <FormattedMessage id="Invoice.Invoicedetails.From.Label" />: {invoiceDetails.vendor && invoiceDetails.vendor.vendorName}
            </h5>
            <p>{invoiceDetails.vendor && invoiceDetails.vendor.address}</p>
            <p>
              <b>
                <FormattedMessage id="Invoice.Invoicedetails.Email.Label" />:
              </b>{' '}
              {invoiceDetails.vendor && invoiceDetails.vendor.email}
            </p>
            <p>
              <b>
                <FormattedMessage id="Invoice.Invoicedetails.Phone.Label" />:
              </b>{' '}
              {invoiceDetails.vendor && invoiceDetails.vendor.phone && `${invoiceDetails.vendor.countrycode || ''} ${formatPhoneNumber(invoiceDetails.vendor.phone)}`}
            </p>
          </div>
          <div className="margin-pb-10-container">
            <h5>
              <FormattedMessage id="Invoice.Invoicedetails.To.Label" />: {invoiceDetails.restaurant && invoiceDetails.restaurant.restaurantName}
            </h5>
            <p>{invoiceDetails.restaurant && invoiceDetails.restaurant.address}</p>
            <p>
              <b>
                <FormattedMessage id="Invoice.Invoicedetails.Email.Label" />:
              </b>{' '}
              {invoiceDetails.restaurant && invoiceDetails.restaurant.restaurantEmail}
            </p>
            <p>
              <b>
                <FormattedMessage id="Invoice.Invoicedetails.Phone.Label" />:
              </b>{' '}
              {invoiceDetails.restaurant &&
                invoiceDetails.restaurant.phone &&
                `${invoiceDetails.restaurant.countryCode || ''} ${formatPhoneNumber(invoiceDetails.restaurant.phone)}`}
            </p>
          </div>
        </div>
        <div className="content-filter-main d-flex justify-content-between">
          <div className="margin-pb-10-container">
            <p>
              <b>
                <FormattedMessage id="Invoice.Invoicedetails.Order.Label" />:
              </b>{' '}
              {invoiceDetails.invoice && invoiceDetails.invoice.orderNo}
            </p>
            <p>
              <b>
                <FormattedMessage id="Invoice.Invoicedetails.Duedate.Label" />:{' '}
              </b>{' '}
              {invoiceDetails.invoice && invoiceDetails.invoice.dueDate !== null && moment(invoiceDetails.invoice.dueDate).local().format(DATE_FORMAT)}
            </p>
            <p>
              <b>
                <FormattedMessage id="Invoice.Invoicedetails.Terms.Label" />:
              </b>{' '}
              {invoiceDetails.invoice && invoiceDetails.invoice.paymentTerm}
            </p>
          </div>
        </div>
        <Table responsive>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="Invoice.Orderdetails.Table.Itemname.Label" />
              </th>
              <th>
                <FormattedMessage id="Invoice.Orderdetails.Table.Quantity.Label" />
              </th>
              <th>
                <FormattedMessage id="Invoice.Orderdetails.Table.Itemprice.Label" />
              </th>
              <th>
                <FormattedMessage id="Invoice.Orderdetails.Table.Pack.Label" />
              </th>
              <th>
                <FormattedMessage id="Invoice.Orderdetails.Table.Size.Label" />
              </th>
              <th>
                <FormattedMessage id="Invoice.Orderdetails.Table.Priceperlb.Label" />
              </th>
              <th>
                <FormattedMessage id="Invoice.Orderdetails.Table.Actualweight.Label" />
              </th>
              <th className="w-170">
                <FormattedMessage id="Invoice.Orderdetails.Table.Credits.Label" />
              </th>
              <th className="w-170 text-end">
                <FormattedMessage id="Invoice.Orderdetails.Table.Toatlprice.Label" />
              </th>
            </tr>
          </thead>
          <tbody>
            {invoiceDetails.items &&
              invoiceDetails.items.map((item: IInvoiceOrderItem) => (
                <tr key={item.itemName}>
                  <td>{item.itemName}</td>
                  <td>{item.quantity}</td>
                  <td>{!item.isVariableWeight ? `$${parseFloat(item.unitPrice).toFixed(2)}` : '-'}</td>
                  <td>{item.pack}</td>
                  <td>
                    {item.size} {item.unitName}
                  </td>
                  <td>{item.pricePerLb === 0 ? '-' : `$${item.pricePerLb.toFixed(2)}`}</td>
                  <td>
                    {item.isVariableWeight && parseFloat(item.actualWeight).toFixed(2)} {item.isVariableWeight && item.unitName} {!item.isVariableWeight && '-'}{' '}
                  </td>
                  <td>${item.credits.toFixed(2)}</td>
                  <td className="text-end">${parseFloat(item.totalPrice).toFixed(2)}</td>
                </tr>
              ))}
          </tbody>
          <tfoot>
            <tr>
              <td rowSpan={3} colSpan={7} className="align-top">
                {invoiceDetails.invoice && invoiceDetails.invoice.footerMessage ? (
                  <div className="d-inline-flex" role="alert">
                    <p className="m-0 text-lightblue">{invoiceDetails.invoice && invoiceDetails.invoice.footerMessage}</p>
                  </div>
                ) : (
                  ''
                )}
              </td>
              <td className="text-lightgray">
                <FormattedMessage id="Invoice.Orderdetails.Statetax.Label" /> ({invoiceDetails.invoice && invoiceDetails.invoice.stateTaxPercentage}
                %):
              </td>
              <td className="dark_bold text-end">
                ${invoiceDetails.invoice && invoiceDetails.invoice.stateTaxAmount && parseFloat(invoiceDetails.invoice.stateTaxAmount).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td className="text-lightgray">
                <FormattedMessage id="Invoice.Orderdetails.Fuelsurcharge.Label" />:
              </td>
              <td className="dark_bold text-end">
                ${invoiceDetails.invoice && invoiceDetails.invoice.fuelSurcharge && parseFloat(invoiceDetails.invoice.fuelSurcharge).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td className="text-lightgray">
                <FormattedMessage id="Invoice.Orderdetails.Invoicetotal.Label" />:
              </td>
              <td className="dark_bold text-end">${invoiceDetails.invoice && invoiceDetails.invoice.invoiceTotal && parseFloat(invoiceDetails.invoice.invoiceTotal).toFixed(2)}</td>
            </tr>
          </tfoot>
        </Table>
      </div>
      {(invoicePdfLoading || invoiceDetailsLoading) && <GifLoader />}
    </div>
  );
};

export default TransactionDetails;
