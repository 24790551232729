/**
 * @file   src\features\dashboard\OrderDetails.tsx
 * @brief  Dashboard Details Page
 * @date   August, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import '../../styles/Order.scss';
import { format } from 'date-fns';
import { FormattedMessage, Col, Row, Table, useEffect, moment, Form, useParams, useState, Button, Link } from '../../utils/thirdpartyComponents';
import { getOrderDetails, generateOrderPdf } from '../../store/actions/dashboardActions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { RootState } from '../../store';
import { OrderItemsSortOptions, SortOrderType, OrderStatus } from '../../utils/enums';
import UpArrow from '../../assets/icons/UpArrow';
import DownArrow from '../../assets/icons/DownArrow';
import UpDownArrow from '../../assets/icons/UpDownArrow';
import File from '../../assets/icons/File';
import GifLoader from '../../components/GifLoader';
import { MessageToaster } from '../../utils/toastUtil';
import { resetOrderPdf } from '../../store/slices/dashboardSlice';
import BackIcon from '../../assets/icons/Back';

// Toast object creation.
const toast = new MessageToaster();

const OrderDetails = () => {
  // Route information variable
  const params = useParams();
  // Create action dispatch
  const dispatch = useAppDispatch();

  // Access redux state variables
  const {
    orderDetails,
    orderDetailsLoading,
    orderDetailsErrorCode,
    orderDetailsErrorMessage,
    orderPdfData,
    orderPdfLoading,
    orderPdfSuccess,
    orderPdfErrorCode,
    orderPdfErrorMessage,
  } = useAppSelector((state: RootState) => state.dashboard);
  // Component state variables
  const [sortField, setSortField] = useState<number>(OrderItemsSortOptions.ITEMNAME);
  const [sortOrder, setSortOrder] = useState<number>(SortOrderType.ASC);
  // fetch order details request
  const fetchOrderDetails = () => {
    const request = {
      orderGuid: params.orderGuid,
      sortField,
      sortOrder,
    };
    dispatch(getOrderDetails(request));
  };
  // Scroll to top position when page loads
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // Fetching order details
  useEffect(() => {
    fetchOrderDetails();
  }, [sortOrder, sortField]);
  // Open pdf generated in new page
  useEffect(() => {
    if (orderPdfSuccess) {
      window.open(orderPdfData.FileDetails.url, '_blank', 'noopener,noreferrer');
      dispatch(resetOrderPdf());
    }
  }, [orderPdfSuccess]);
  // Show error message when api fails
  useEffect(() => {
    if (orderDetailsErrorCode > 0 && orderDetailsErrorMessage) {
      toast.toastError(orderDetailsErrorMessage);
    }
    if (orderPdfErrorCode > 0) {
      toast.toastError(orderPdfErrorMessage);
    }
  }, [orderDetailsErrorCode, orderDetailsErrorMessage, orderPdfErrorCode]);

  // Sort header change event.
  const changeSortField = (field: number) => {
    let newSortOrder = SortOrderType.ASC;
    if (field === sortField) {
      newSortOrder = sortOrder === SortOrderType.ASC ? SortOrderType.DESC : SortOrderType.ASC;
    }
    setSortField(field);
    setSortOrder(newSortOrder);
  };
  // Create order pdf
  const createPdf = () => {
    const timeoffset = moment().utcOffset();
    dispatch(generateOrderPdf(`${params.orderGuid}/${timeoffset}`));
  };
  // Function that returns order status with color
  const statusDisplay = (status: string) => {
    switch (status) {
      case OrderStatus[3]:
        return <span className="text-green ms-2">{orderDetails ? OrderStatus[orderDetails.orderDetails.orderStatus] : 'NIL'}</span>;
      case OrderStatus[6]:
        return <span className="btn lbl-rejected d-flex align-items-center">Rejected</span>;
      default:
        return <span className="text-blue ms-2">{orderDetails ? OrderStatus[orderDetails.orderDetails.orderStatus] : 'NIL'}</span>;
    }
  };

  return (
    <div className="content-nav contnt-container-margin">
      <div className="back-navigation">
        <Link to="/home" className="back-btn">
          <BackIcon />
          <FormattedMessage id="Link.Back" />
        </Link>
      </div>
      <div className="content-nav-sub pb-4">
        <div className="content-nav-header">
          <div className="d-xl-flex justify-content-between align-items-center">
            <div>
              <h2 className="mb-2">{orderDetails && orderDetails.orderDetails && orderDetails.orderDetails.orderName}</h2>
              <h5 className="mb-0 text-lightgray">{orderDetails && orderDetails.orderDetails && moment(orderDetails.orderDetails.orderPlacedOn).local().format('dddd MMMM Do')}</h5>
            </div>
            <Button className="btn-icononly" title="Export" onClick={() => createPdf()}>
              <File />
            </Button>
          </div>
        </div>
        <div className="content-container-padding">
          <Row>
            <Col xl={6} className="order-form">
              <div className="form-fields">
                <Form.Group className="d-inline-flex align-items-center">
                  <Form.Label>
                    <FormattedMessage id="Order.OrderNumber" />:
                  </Form.Label>
                  <span className="text-blue ps-2">{orderDetails && orderDetails.orderDetails && orderDetails.orderDetails.orderNo}</span>
                </Form.Group>
              </div>
              <div className="form-fields">
                <Form.Group className="d-inline-flex align-items-center">
                  <Form.Label>
                    <FormattedMessage id="Order.Restaurantname" />:
                  </Form.Label>
                  <span className="text-blue ps-2">{orderDetails && orderDetails.restaurantDetails && orderDetails.restaurantDetails.restaurantName}</span>
                </Form.Group>
              </div>
              <div className="form-fields">
                <Form.Group className="d-inline-flex align-items-center">
                  <Form.Label>
                    <FormattedMessage id="Order.Address" />:
                  </Form.Label>
                  <span className="text-blue ps-2">
                    {orderDetails &&
                      orderDetails.restaurantDetails &&
                      `${orderDetails.restaurantDetails.address}, ${orderDetails.restaurantDetails.city}, ${orderDetails.restaurantDetails.stateCode}
                ${orderDetails.restaurantDetails.zip !== '' ? ` ${orderDetails.restaurantDetails.zip}` : ''}`}
                  </span>
                </Form.Group>
              </div>
              <div className="form-fields">
                <Form.Group className="d-inline-flex align-items-center">
                  <Form.Label>
                    <FormattedMessage id="Order.placementtime" />:
                  </Form.Label>
                  <span className="text-blue ps-2">
                    {orderDetails && orderDetails.orderDetails && moment(orderDetails.orderDetails.orderPlacedOn).local().format('MM-DD-YYYY, hh:mm:ss A')}
                  </span>
                </Form.Group>
              </div>
              <div className="form-fields">
                <Form.Group className="d-inline-flex align-items-center">
                  <Form.Label>
                    <FormattedMessage id="Order.Requested.DeliveryDate" />:
                  </Form.Label>
                  <span className="text-blue ps-2">
                    {orderDetails &&
                      orderDetails.orderDetails &&
                      orderDetails.orderDetails.deliveryExpectedOn &&
                      format(new Date(orderDetails.orderDetails.deliveryExpectedOn), 'EEEE, MMMM d, yyyy')}
                  </span>
                </Form.Group>
              </div>
            </Col>
            <Col className="order-form d-flex align-items-end justify-content-end">
              <div className="form-fields">
                <Form.Group className="d-inline-flex align-items-center">
                  <Form.Label>
                    <FormattedMessage id="TH.Status" />:
                  </Form.Label>
                  {orderDetails && orderDetails.orderDetails && statusDisplay(OrderStatus[orderDetails.orderDetails.orderStatus])}
                </Form.Group>
              </div>
            </Col>
          </Row>
        </div>
        <Table responsive>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="OrderTable.Slno" />
              </th>
              <th style={{ cursor: 'pointer' }} onClick={() => changeSortField(OrderItemsSortOptions.ITEMNAME)}>
                <FormattedMessage id="OrderTable.Itemname" />
                {sortField !== OrderItemsSortOptions.ITEMNAME && <UpDownArrow />}
                {sortField === OrderItemsSortOptions.ITEMNAME && sortOrder === SortOrderType.DESC && <DownArrow />}
                {sortField === OrderItemsSortOptions.ITEMNAME && sortOrder === SortOrderType.ASC && <UpArrow />}
              </th>
              <th style={{ cursor: 'pointer' }} onClick={() => changeSortField(OrderItemsSortOptions.PACK)}>
                <FormattedMessage id="OrderTable.Pack" />
                {sortField !== OrderItemsSortOptions.PACK && <UpDownArrow />}
                {sortField === OrderItemsSortOptions.PACK && sortOrder === SortOrderType.DESC && <DownArrow />}
                {sortField === OrderItemsSortOptions.PACK && sortOrder === SortOrderType.ASC && <UpArrow />}
              </th>
              <th style={{ cursor: 'pointer' }} onClick={() => changeSortField(OrderItemsSortOptions.SIZE)}>
                <FormattedMessage id="OrderTable.Size" />
                {sortField !== OrderItemsSortOptions.SIZE && <UpDownArrow />}
                {sortField === OrderItemsSortOptions.SIZE && sortOrder === SortOrderType.DESC && <DownArrow />}
                {sortField === OrderItemsSortOptions.SIZE && sortOrder === SortOrderType.ASC && <UpArrow />}
              </th>
              <th style={{ cursor: 'pointer' }} onClick={() => changeSortField(OrderItemsSortOptions.QUANTITY)}>
                <FormattedMessage id="OrderTable.Quantity" />
                {sortField !== OrderItemsSortOptions.QUANTITY && <UpDownArrow />}
                {sortField === OrderItemsSortOptions.QUANTITY && sortOrder === SortOrderType.DESC && <DownArrow />}
                {sortField === OrderItemsSortOptions.QUANTITY && sortOrder === SortOrderType.ASC && <UpArrow />}
              </th>
              <th style={{ cursor: 'pointer' }} onClick={() => changeSortField(OrderItemsSortOptions.PRICE)}>
                <FormattedMessage id="OrderTable.Itemprice" />
                {sortField !== OrderItemsSortOptions.PRICE && <UpDownArrow />}
                {sortField === OrderItemsSortOptions.PRICE && sortOrder === SortOrderType.DESC && <DownArrow />}
                {sortField === OrderItemsSortOptions.PRICE && sortOrder === SortOrderType.ASC && <UpArrow />}
              </th>
              <th style={{ cursor: 'pointer' }} onClick={() => changeSortField(OrderItemsSortOptions.TOTALPRICE)}>
                <FormattedMessage id="OrderTable.TotalPrice" />
                {sortField !== OrderItemsSortOptions.TOTALPRICE && <UpDownArrow />}
                {sortField === OrderItemsSortOptions.TOTALPRICE && sortOrder === SortOrderType.DESC && <DownArrow />}
                {sortField === OrderItemsSortOptions.TOTALPRICE && sortOrder === SortOrderType.ASC && <UpArrow />}
              </th>
            </tr>
          </thead>
          <tbody>
            {orderDetails &&
              orderDetails.itemListDetails &&
              orderDetails.itemListDetails.map((item: any, index: number) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{item.itemName}</td>
                  <td>{item.pack}</td>
                  <td>
                    {item.size?.toFixed(2)} {item.unitName}
                  </td>
                  <td>{item.quantity}</td>
                  <td>${item.isVariableWeight ? `${item.pricePerLb?.toFixed(2)}/lb` : item.price?.toFixed(2)}</td>
                  <td>${item.totalPrice?.toFixed(2)}</td>
                </tr>
              ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={5} />
              <td className="text-end">
                <FormattedMessage id="Order.Totalitems" /> :
              </td>
              <td className="dark_bold">{orderDetails && orderDetails.orderDetails && orderDetails.orderDetails.totalItems}</td>
            </tr>
            <tr>
              <td colSpan={5} />
              <td className="text-end">
                <FormattedMessage id="Order.Salestax" /> {orderDetails.orderDetails?.stateTaxPer ? `(${parseFloat(orderDetails.orderDetails.stateTaxPer).toFixed(2)}%)` : ''} :
              </td>
              <td className="dark_bold">${orderDetails && orderDetails.orderDetails && orderDetails.orderDetails.stateTaxAmount?.toFixed(2)}</td>
            </tr>
            <tr>
              <td colSpan={5} />
              <td className="text-end">
                <FormattedMessage id="Order.Fuelsurcharge" /> :
              </td>
              <td className="dark_bold">${orderDetails && orderDetails.orderDetails && orderDetails.orderDetails.fuelSurchargeAmt?.toFixed(2)}</td>
            </tr>
            <tr>
              <td colSpan={5} />
              <td className="text-end">
                <FormattedMessage id="Order.Totalprice" /> :
              </td>
              <td className="dark_bold">${orderDetails && orderDetails.orderDetails && orderDetails.orderDetails.totalAmount?.toFixed(2)}</td>
            </tr>
          </tfoot>
        </Table>
      </div>
      {(orderDetailsLoading || orderPdfLoading) && <GifLoader />}
    </div>
  );
};

export default OrderDetails;
