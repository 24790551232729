/**
 * @file   src\features\restaurant\Add.tsx
 * @brief  Restaurant Add Page With Add Form
 * @date   June, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import {
  FormattedMessage,
  useNavigate,
  useEffect,
  useState,
  Col,
  Row,
  Breadcrumb,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  Modal,
  Link,
  useParams,
  useRef,
} from '../../utils/thirdpartyComponents';
import { useIntlMessages, useIntlActionMessages, setCutOffTimeIntervals, getFromLocalStorage, timeConvertToAmPm } from '../../utils/helper';
import InputLabel from '../../components/InputLabel';
import Select from '../../components/SelectLabel';
import InputCheck from '../../components/ChecBoxLabel';
import BackIcon from '../../assets/icons/Back';
import LocationSearch from '../../components/LocationSearch';
import InputMaskLabel from '../../components/InputMaskLabel';
import SearchIcon from '../../assets/icons/Search';
import SearchRemoveIcon from '../../assets/icons/SearchRemove';
import { IRestaurantAddForm, IRestaurantAddRequest, IActivateRestaurant } from '../../interfaces/restaurantInterface';
import { STARTING_HOUR, ENDING_HOUR, STATE_MAX_LENGTH, EMAIL_MAX_LENGTH, ZIP_MAX_LENGTH } from '../../utils/constants';
import { validateForm } from '../../utils/formValidation';
import { RESTAURANT_ADD_EDIT_SCHEMA } from '../../validations/restaurantSchema';
import { RootState } from '../../store';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { saveRestaurant, getRestaurantDetails, restaurantActivateDeactivate } from '../../store/actions/restaurantAction';
import GifLoader from '../../components/GifLoader';
import { resetRestaurantState } from '../../store/slices/restaurantSlice';
import { CountryCodes, YesOrNoValues, ActivateDeactivateText, ActivateStatus, DeliveryDays } from '../../utils/enums';
import { MessageToaster } from '../../utils/toastUtil';
// Restaurant form initial state
const restaurantFormDefaultData: IRestaurantAddForm = {
  restaurantId: 0,
  countryCode: CountryCodes.US,
  adminCountryCode: CountryCodes.US,
  name: '',
  address: '',
  zip: '',
  city: '',
  state: '',
  stateCode: '',
  location: {},
  locationChange: false,
  latitude: '',
  longitude: '',
  formattedAddress: '',
  adminPhone: '',
  adminFirstName: '',
  adminLastName: '',
  adminEmail: '',
  email: '',
  deliveryDays: [],
  phone: '',
  startTime: '',
  endTime: '',
  isEPaymentEnabled: true,
  isEStatementEnabled: true,
  startTimeOptions: STARTING_HOUR,
  endTimeOptions: ENDING_HOUR,
  startTimeObj: STARTING_HOUR,
  endTimeObj: ENDING_HOUR,
};
// Toast object creation.
const toast = new MessageToaster();

// component to add restaurant from super admin
const RestaurantAdd = () => {
  // Declare action dispatch
  const dispatch = useAppDispatch();
  // Navigation object creation
  const navigate = useNavigate();
  const params = useParams();
  // Access redux state variables
  const { addRestaurantLoading, addRestaurantSuccess, isLoading, restaurantDetail, updateStatusRestaurantLoading, updateStatusRestaurantSuccess, errorCode, errorMessage } =
    useAppSelector((state: RootState) => state.restaurant);

  // Component state variables
  const [deActivate, deActivatePop] = useState<boolean>(false);
  const [deActivated, deActivatedPop] = useState<boolean>(false);
  const [Activate, ActivatePop] = useState<boolean>(false);
  const [Activated, ActivatedPop] = useState<boolean>(false);
  const [errorFields, setErrorFields] = useState<any>({});
  const [restaurantAdded, setRestaurantAdded] = useState<boolean>(false);
  const [restaurantInfo, setRestaurantInfo] = useState<IRestaurantAddForm>(restaurantFormDefaultData);
  const [cutOffTimeOptions, setCutOffTimeOtions] = useState<any>([]);
  const [deactivateText, setDeactivateText] = useState<string>('');
  const [activateText, setActivateText] = useState<string>('');
  const [currentStatus, setCurrentStatus] = useState<number>(ActivateStatus.DEACTIVATE);
  const [errorVisible, setErrorVisible] = useState<boolean>(false);
  const [restaurantName, setRestaurantName] = useState<string>('');
  const errRef = useRef<any>(null);

  // Add restaurant action call
  const addRestaurant = (addData: IRestaurantAddRequest) => dispatch(saveRestaurant(addData));
  const updateStatus = (updateData: IActivateRestaurant) => dispatch(restaurantActivateDeactivate(updateData));
  const getRestaurantInfo = () => dispatch(getRestaurantDetails(Number(params.restaurantId)));

  // setting cutoff time drop down values
  useEffect(() => {
    (async () => {
      const cutOffTimeResult = await setCutOffTimeIntervals();
      setRestaurantInfo((restaurantAddInfo) => ({
        ...restaurantAddInfo,
        startTimeOptions: cutOffTimeResult,
        endTimeOptions: cutOffTimeResult,
      }));
      setCutOffTimeOtions(cutOffTimeResult);
    })();
    if (Object.keys(params).length > 0) {
      getRestaurantInfo();
    }
  }, []);

  // show add success popup and reset redux state
  useEffect(() => {
    if (addRestaurantSuccess && errorCode === 0 && restaurantInfo.restaurantId === 0) {
      toast.toastSuccess(errorMessage);
      navigate('/restaurants');
    } else if (addRestaurantSuccess && errorCode === 0 && restaurantInfo.restaurantId > 0) {
      navigate(`/restaurants/details/${restaurantInfo.restaurantId}`);
    } else if (addRestaurantSuccess && errorCode > 0) {
      toast.toastError(errorMessage || useIntlActionMessages('Unexpected.Error'));
    } else if (!addRestaurantSuccess && errorCode > 0) {
      toast.toastError(errorMessage);
    }

    if (updateStatusRestaurantSuccess) {
      if (currentStatus === ActivateStatus.ACTIVATE) {
        ActivatedPop(true);
      } else {
        deActivatedPop(true);
      }
    }

    return () => {
      dispatch(resetRestaurantState());
    };
  }, [addRestaurantSuccess, updateStatusRestaurantSuccess, errorCode]);
  // activate deactivate restaurant toast error message when api fails
  useEffect(() => {
    if (errorCode > 0 && !updateStatusRestaurantSuccess) {
      toast.toastError(errorMessage || useIntlActionMessages('Unexpected.Error'));
    }
  }, [updateStatusRestaurantLoading]);

  // set data in the form for restaurant edit
  useEffect(() => {
    if (Object.keys(restaurantDetail).length > 0) {
      const startTime = restaurantDetail.restaurant.deliveryStartTime ? timeConvertToAmPm(restaurantDetail.restaurant.deliveryStartTime) : '';
      const endTime = restaurantDetail.restaurant.deliveryEndTime ? timeConvertToAmPm(restaurantDetail.restaurant.deliveryEndTime) : '';
      const editData = {
        restaurantId: restaurantDetail.restaurant.restaurantId,
        name: restaurantDetail.restaurant.restaurantName,
        address: restaurantDetail.restaurant.address,
        city: restaurantDetail.restaurant.city,
        state: restaurantDetail.restaurant.state,
        stateCode: restaurantDetail.restaurant.stateCode,
        zip: restaurantDetail.restaurant.zip,
        countryCode: restaurantDetail.restaurant.countryCode,
        phone: restaurantDetail.restaurant.phone,
        email: restaurantDetail.restaurant.email,
        deliveryDays: restaurantDetail.restaurant.deliveryDaysInfo,
        adminFirstName: restaurantDetail.restaurant.adminFirstName,
        adminLastName: restaurantDetail.restaurant.adminLastName,
        adminPhone: restaurantDetail.restaurant.adminPhone,
        adminEmail: restaurantDetail.restaurant.adminEmail,
        adminCountryCode: CountryCodes.US,
        startTime,
        endTime,
        latitude: restaurantDetail.restaurant.latitude,
        longitude: restaurantDetail.restaurant.longitude,
        isEPaymentEnabled: restaurantDetail.restaurant.isEpaymentEnabled,
        isEStatementEnabled: restaurantDetail.restaurant.isEstatementEnabled,
        formattedAddress: restaurantDetail.restaurant.address,
        locationChange: false,
        location: {
          lat: restaurantDetail.restaurant.latitude ? parseFloat(restaurantDetail.restaurant.latitude) : '',
          lng: restaurantDetail.restaurant.longitude ? parseFloat(restaurantDetail.restaurant.longitude) : '',
          address: restaurantDetail.restaurant.address ? restaurantDetail.restaurant.address : '',
          city: restaurantDetail.restaurant.city ? restaurantDetail.restaurant.city : '',
          state: restaurantDetail.restaurant.state ? restaurantDetail.restaurant.state : '',
          stateCode: restaurantDetail.restaurant.stateCode ? restaurantDetail.restaurant.stateCode : '',
          name: restaurantDetail.restaurant.address ? restaurantDetail.restaurant.address : '',
          locationName: restaurantDetail.restaurant.address ? restaurantDetail.restaurant.address : '',
        },
        startTimeObj: restaurantDetail.restaurant.deliveryStartTime ? [{ label: startTime, value: startTime }] : STARTING_HOUR,
        endTimeObj: restaurantDetail.restaurant.deliveryEndTime ? [{ label: endTime, value: endTime }] : ENDING_HOUR,
        startTimeOptions: cutOffTimeOptions,
        endTimeOptions: cutOffTimeOptions,
      };
      setRestaurantInfo(editData);
      setRestaurantName(restaurantDetail.restaurant.restaurantName);
    }
  }, [isLoading]);

  // handle input validation
  const onInputHandleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setRestaurantInfo((info) => ({
      ...info,
      [name]: value,
    }));
    const validateObj = {
      [name]: value,
    };
    const errorresult = await validateForm(validateObj, RESTAURANT_ADD_EDIT_SCHEMA, errorFields);
    setErrorFields(errorresult);
  };

  // handle location change
  const onLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const errorObj = { ...errorFields };
    setRestaurantInfo((info) => ({
      ...info,
      locationChange: value !== '',
      location: {},
      address: value,
      formattedAddress: value,
      city: '',
      zip: '',
      state: '',
      stateCode: '',
    }));
    errRef.current = errorObj;
  };
  // handle remove button in location search
  const onRemoveBtnClick = () => {
    setRestaurantInfo((info) => ({
      ...info,
      location: {},
      address: '',
      city: '',
      zip: '',
      state: '',
      stateCode: '',
      formattedAddress: '',
      locationChange: false,
    }));
  };

  // handle delivery days toggle
  const onToggleChange = async (val: any) => {
    setRestaurantInfo((info) => ({
      ...info,
      deliveryDays: val,
    }));
    const validateObj = {
      deliveryDays: val,
    };
    const errorresult = await validateForm(validateObj, RESTAURANT_ADD_EDIT_SCHEMA, errorFields);
    setErrorFields(errorresult);
  };
  // handle select box changes
  const onSelectChange = async (name: string, values: any, selectedFieldName: string) => {
    const fieldName: string = selectedFieldName;
    setRestaurantInfo((info) => ({
      ...info,
      [name]: values,
      [fieldName]: values[0]?.value,
    }));
    const validateObj = {
      [name]: values,
      [fieldName]: values[0]?.value,
    };
    const errorresult = await validateForm(validateObj, RESTAURANT_ADD_EDIT_SCHEMA, errorFields);
    setErrorFields(errorresult);
  };
  // handle phone number field change
  const onPhoneFieldHandleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const inputVal = value ? value.replace(/\D/g, '') : '';
    setRestaurantInfo((info) => ({
      ...info,
      [name]: inputVal,
    }));
    const validateObj = {
      [name]: inputVal,
    };
    const errorresult = await validateForm(validateObj, RESTAURANT_ADD_EDIT_SCHEMA, errorFields);
    setErrorFields(errorresult);
  };
  // handle checkboxes
  const onCheckedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRestaurantInfo((info) => ({
      ...info,
      [event.target.name]: event.target.checked,
    }));
  };
  // handle location search location select
  const onLocationSelect = async (place: any) => {
    setRestaurantInfo((info) => ({
      ...info,
      location: {
        lat: place.lat,
        lng: place.lng,
        address: place.address,
        city: place.city,
        state: place.state,
        stateCode: place.stateCode,
        zip: place.zip,
        name: place.name,
        locationName: place.name,
      },
      address: place.address,
      formattedAddress: place.address,
      city: place.city,
      zip: place.zip,
      state: place.state,
      stateCode: place.stateCode,
    }));
    const locationInfo = {
      address: place.address,
      city: place.city,
      zip: place.zip,
      state: place.state,
    };
    const errorresult = await validateForm(locationInfo, RESTAURANT_ADD_EDIT_SCHEMA, errRef.current);
    setErrorFields(errorresult);
  };

  // handle form submit
  const onSubmit = async () => {
    setErrorVisible(true);
    const addFormData = {
      restaurantId: restaurantInfo.restaurantId,
      name: restaurantInfo.name.trim(),
      address: restaurantInfo.address,
      city: restaurantInfo.city,
      state: restaurantInfo.state,
      zip: restaurantInfo.zip,
      email: restaurantInfo.email.trim(),
      phone: restaurantInfo.phone.trim(),
      deliveryDays: restaurantInfo.deliveryDays,
      adminPhone: restaurantInfo.adminPhone.trim(),
      adminFirstName: restaurantInfo.adminFirstName.trim(),
      adminLastName: restaurantInfo.adminLastName.trim(),
      adminEmail: restaurantInfo.email.trim(),
      startTime: restaurantInfo.startTime,
      endTime: restaurantInfo.endTime,
      adminCountryCode: restaurantInfo.adminCountryCode,
      countryCode: restaurantInfo.countryCode,
    };
    const errorresult = await validateForm(addFormData, RESTAURANT_ADD_EDIT_SCHEMA, errorFields);
    if (Object.keys(errorresult).length === 0) {
      if (Object.keys(restaurantInfo.location).length > 0 && restaurantInfo.location?.stateCode !== '') {
        const addRequestData: any = addFormData;
        addRequestData.latitude = restaurantInfo.location.lat;
        addRequestData.longitude = restaurantInfo.location.lng;
        addRequestData.isEpaymentEnabled = restaurantInfo.isEPaymentEnabled;
        addRequestData.isEstatementEnabled = restaurantInfo.isEStatementEnabled;
        addRequestData.stateCode = restaurantInfo.stateCode;
        const userData = getFromLocalStorage('MCSA_UDT');
        addRequestData.loggedinUserId = userData.userId;
        if (Object.keys(restaurantDetail).length > 0) {
          addRequestData.isAdminFirstNameModified = restaurantInfo.adminFirstName !== restaurantDetail.restaurant.adminFirstName ? YesOrNoValues.YES : YesOrNoValues.NO;
          addRequestData.isAdminLastNameModified = restaurantInfo.adminLastName !== restaurantDetail.restaurant.adminLastName ? YesOrNoValues.YES : YesOrNoValues.NO;
          addRequestData.isPhoneModified = restaurantInfo.adminPhone !== restaurantDetail.restaurant.adminPhone ? YesOrNoValues.YES : YesOrNoValues.NO;
        }
        addRestaurant(addRequestData);
      } else {
        toast.toastError(useIntlActionMessages('Restaurant.Autocomplete.Select.Error'));
      }
    } else {
      toast.toastError(useIntlActionMessages('Enter.Mandatory'));
      setErrorFields(errorresult);
    }
  };
  // handle restaurant add success popup ok button
  const handleSuccessPopUpOk = () => {
    setRestaurantAdded(false);
    navigate('/restaurants');
  };
  // get deactivate button
  const getDeactivateBtn = () => {
    if (Object.keys(params).length > 0 && Object.keys(restaurantDetail).length > 0 && restaurantDetail.restaurant.isActive) {
      return (
        <Button variant="primary" className="btn-red" onClick={() => deActivatePop(true)}>
          <FormattedMessage id="Button.DeactivateRestaurant" />
        </Button>
      );
    }
    return null;
  };
  // get activate button
  const getActivateBtn = () => {
    if (Object.keys(params).length > 0 && Object.keys(restaurantDetail).length > 0 && !restaurantDetail.restaurant.isActive) {
      return (
        <Button variant="primary" className="btn-green" onClick={() => ActivatePop(true)}>
          <FormattedMessage id="Button.ActivateRestaurant" />
        </Button>
      );
    }
    return null;
  };
  // handle input change in modal popup
  const onTextChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === ActivateDeactivateText.DEACTIVATE) {
      setDeactivateText(value);
    } else if (name === ActivateDeactivateText.ACTIVATE) {
      setActivateText(value);
    }
  };
  // hide deactivate popup
  const hideDeactivatePopup = () => {
    setDeactivateText('');
    deActivatePop(false);
  };
  // hide activate popup
  const hideActivatePopup = () => {
    setActivateText('');
    ActivatePop(false);
  };
  // deactivate restaurant api call
  const deActiavateRestaurant = () => {
    deActivatePop(false);
    setCurrentStatus(ActivateStatus.DEACTIVATE);
    const requestBody = {
      restaurantGuid: restaurantDetail.restaurant.restaurantGuid,
      activeStatus: YesOrNoValues.NO,
    };
    updateStatus(requestBody);
  };
  // activate restaurant api call
  const activateRestaurant = () => {
    ActivatePop(false);
    setCurrentStatus(ActivateStatus.ACTIVATE);
    const requestBody = {
      restaurantGuid: restaurantDetail.restaurant.restaurantGuid,
      activeStatus: YesOrNoValues.YES,
    };
    updateStatus(requestBody);
  };
  // Get page heading
  const getHeading = () => {
    if (Object.keys(params).length > 0 && Object.keys(restaurantDetail).length > 0) {
      return `Edit ${restaurantDetail.restaurant.restaurantName}`;
    }
    return <FormattedMessage id="Restaurant.Add.Button.text" />;
  };

  return (
    <div className="content-nav contnt-container-margin">
      <div className="back-navigation">
        <Breadcrumb>
          <Breadcrumb.Item href="#" onClick={() => navigate('/home')}>
            <FormattedMessage id="Menu.Home" />
          </Breadcrumb.Item>
          <Breadcrumb.Item href="#" onClick={() => navigate('/restaurants')}>
            <FormattedMessage id="Restaurant.PageHD" />
          </Breadcrumb.Item>
          {Object.keys(params).length > 0 && Object.keys(restaurantDetail).length > 0 && (
            <Breadcrumb.Item href="#" onClick={() => navigate(`/restaurants/details/${params.restaurantId}`)}>
              {restaurantDetail.restaurant.restaurantName}
            </Breadcrumb.Item>
          )}
          <Breadcrumb.Item active>
            {Object.keys(params).length > 0 ? <FormattedMessage id="Restaurant.Edit.Heading" /> : <FormattedMessage id="Restaurant.Add.Button.text" />}
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="content-nav-sub">
        <div className="content-nav-header d-flex justify-content-between align-items-center">
          <h2>
            <Link to="/restaurants" className="btn-icon">
              <BackIcon />
            </Link>
            {getHeading()}
          </h2>
        </div>
        <div className="content-container-padding">
          <h6>
            <FormattedMessage id="HD.BasicInformation" />
          </h6>
          <Row className="form-main">
            <Col lg="5">
              <InputLabel
                id="test"
                name="name"
                type="text"
                value={restaurantInfo.name}
                label={useIntlMessages('Restaurant.RestaurantName')}
                errorMessage={errorVisible ? errorFields?.name : ''}
                onChange={(e) => onInputHandleChange(e)}
                placeholder={useIntlMessages('Restaurant.Placeholder.Name')}
                maxLength={STATE_MAX_LENGTH}
                isMandatory
              />
            </Col>
            <Col lg="5">
              <LocationSearch
                label={useIntlMessages('Restaurant.Address')}
                id="address"
                name="address"
                types={['geocode', 'establishment']}
                placeholder={useIntlMessages('Restaurant.Placeholder.Address')}
                values={restaurantInfo.location}
                address={restaurantInfo.formattedAddress ? restaurantInfo.formattedAddress : ''}
                locationChange={restaurantInfo.locationChange ? restaurantInfo.locationChange : false}
                icon={<SearchIcon />}
                isMandatory
                closeicon={<SearchRemoveIcon onClick={() => onRemoveBtnClick()} value={restaurantInfo.formattedAddress} />}
                onLocationSelect={(place: any) => {
                  onLocationSelect(place);
                }}
                onChange={(e) => onLocationChange(e)}
                errorMessage={errorVisible ? errorFields?.address : ''}
              />
            </Col>
            <Col lg="5">
              <InputLabel
                id="test"
                name="city"
                type="text"
                value={restaurantInfo.city}
                label={useIntlMessages('Add.City')}
                errorMessage={errorVisible ? errorFields?.city : ''}
                onChange={(e) => onInputHandleChange(e)}
                placeholder={useIntlMessages('Restaurant.Placeholder.City')}
                maxLength={STATE_MAX_LENGTH}
                isMandatory
                disabled
              />
            </Col>
            <Col lg="5">
              <InputLabel
                id="test"
                name="state"
                type="text"
                value={restaurantInfo.state}
                label={useIntlMessages('Add.State')}
                errorMessage={errorVisible ? errorFields?.state : ''}
                onChange={(e) => onInputHandleChange(e)}
                placeholder={useIntlMessages('Restaurant.Placeholder.State')}
                maxLength={STATE_MAX_LENGTH}
                isMandatory
                disabled
              />
            </Col>
            <Col lg="5">
              <InputLabel
                id="test"
                name="zip"
                type="text"
                value={restaurantInfo.zip}
                label={useIntlMessages('Add.ZipCode')}
                errorMessage={errorVisible ? errorFields?.zip : ''}
                onChange={(e) => onInputHandleChange(e)}
                placeholder={useIntlMessages('Restaurant.Placeholder.Zip')}
                maxLength={ZIP_MAX_LENGTH}
                isMandatory
              />
            </Col>
            <Col lg="5">
              <InputMaskLabel
                label={useIntlMessages('Add.BusinessPhone')}
                id="phone"
                name="phone"
                type="phone"
                mask="(999) 999-9999"
                value={restaurantInfo.phone}
                onChange={(e) => onPhoneFieldHandleChange(e)}
                errorMessage={errorVisible ? errorFields?.phone : ''}
                placeholder={useIntlMessages('Restaurant.Placeholder.Phone')}
              />
            </Col>
            <Col lg="5">
              <InputLabel
                id="test"
                name="email"
                type="text"
                value={restaurantInfo.email}
                label={useIntlMessages('Add.BusinessEmail')}
                errorMessage={errorVisible ? errorFields?.email : ''}
                onChange={(e) => onInputHandleChange(e)}
                placeholder={useIntlMessages('Restaurant.Placeholder.Email')}
                maxLength={EMAIL_MAX_LENGTH}
                disabled={Object.keys(params).length > 0}
                isMandatory
              />
            </Col>
            <Col lg="5" className="delivery-days">
              <span className="inputlabel">
                <FormattedMessage id="Restaurant.AcceptDelivery.Days" />
              </span>
              <ToggleButtonGroup
                type="checkbox"
                id="deliveryDays"
                name="deliveryDays"
                onChange={onToggleChange}
                className={errorFields?.deliveryDays && errorFields?.deliveryDays !== '' ? 'error' : restaurantInfo.deliveryDays.length > 0 ? 'active' : ''}
                value={restaurantInfo.deliveryDays}
              >
                <ToggleButton id="tbg-check-1" value={DeliveryDays.MON} variant="success">
                  <FormattedMessage id="Day.Mon" />
                </ToggleButton>
                <ToggleButton id="tbg-check-2" value={DeliveryDays.TUE} variant="success">
                  <FormattedMessage id="Day.Tue" />
                </ToggleButton>
                <ToggleButton id="tbg-check-3" value={DeliveryDays.WED} variant="success">
                  <FormattedMessage id="Day.Wed" />
                </ToggleButton>
                <ToggleButton id="tbg-check-4" value={DeliveryDays.THU} variant="success">
                  <FormattedMessage id="Day.Thu" />
                </ToggleButton>
                <ToggleButton id="tbg-check-5" value={DeliveryDays.FRI} variant="success">
                  <FormattedMessage id="Day.Fri" />
                </ToggleButton>
                <ToggleButton id="tbg-check-6" value={DeliveryDays.SAT} variant="success">
                  <FormattedMessage id="Day.Sat" />
                </ToggleButton>
                <ToggleButton id="tbg-check-7" value={DeliveryDays.SUN} variant="success">
                  <FormattedMessage id="Day.Sun" />
                </ToggleButton>
              </ToggleButtonGroup>
              {errorFields?.deliveryDays !== '' && <span className="error">{errorFields?.deliveryDays}</span>}
            </Col>
          </Row>
          <Row className="mb-4 form-main">
            <Col lg="5" className="delivery-days">
              <span className="inputlabel">
                <FormattedMessage id="Add.DeliveryWindow" />
              </span>
              <Row>
                <Col className="no-label-input">
                  <Select
                    id="DeliveryWindow"
                    type="select"
                    name="Delivery window"
                    options={restaurantInfo.startTimeOptions}
                    placeholder="Starting hour"
                    dropdownGap={0}
                    values={restaurantInfo.startTimeObj}
                    dropdownPosition="auto"
                    isMandatory={false}
                    errorMessage={errorFields?.startTime}
                    className=""
                    onChange={(values) => onSelectChange('startTimeObj', values, 'startTime')}
                  />
                </Col>
                <Col className="no-label-input">
                  <Select
                    id="DeliveryWindow"
                    type="select"
                    name="Delivery window"
                    options={restaurantInfo.endTimeOptions}
                    placeholder="Ending hour"
                    dropdownGap={0}
                    values={restaurantInfo.endTimeObj}
                    dropdownPosition="auto"
                    isMandatory={false}
                    errorMessage={errorFields?.endTime}
                    className=""
                    onChange={(values) => onSelectChange('endTimeObj', values, 'endTime')}
                  />
                </Col>
              </Row>
            </Col>

            <Col lg={5} className="d-flex">
              <Col md="auto" className="me-4">
                <InputCheck
                  id="accEPayment"
                  name="isEPaymentEnabled"
                  title={useIntlMessages('Add.E-pay')}
                  label="Yes"
                  checked={restaurantInfo.isEPaymentEnabled}
                  onChange={(e) => onCheckedChange(e)}
                />
              </Col>
              <Col md="auto" className="me-4">
                <InputCheck
                  id="accEPayment"
                  name="isEStatementEnabled"
                  title={useIntlMessages('Add.ElectronicStatement')}
                  label="Yes"
                  checked={restaurantInfo.isEStatementEnabled}
                  onChange={(e) => onCheckedChange(e)}
                />
              </Col>
            </Col>
          </Row>
          <h6>
            <FormattedMessage id="HD.AdminInformation" />
          </h6>
          <Row className="form-main">
            <Col lg="5">
              <InputLabel
                id="test"
                name="adminFirstName"
                type="text"
                value={restaurantInfo.adminFirstName}
                label={useIntlMessages('Add.FirstName')}
                errorMessage={errorVisible ? errorFields?.adminFirstName : ''}
                onChange={(e) => onInputHandleChange(e)}
                placeholder={useIntlMessages('Restaurant.Placeholder.FirstName')}
                maxLength={STATE_MAX_LENGTH}
                isMandatory
              />
            </Col>
            <Col lg="5">
              <InputLabel
                id="test"
                name="adminLastName"
                type="text"
                value={restaurantInfo.adminLastName}
                label={useIntlMessages('Add.LastName')}
                errorMessage={errorVisible ? errorFields?.adminLastName : ''}
                onChange={(e) => onInputHandleChange(e)}
                placeholder={useIntlMessages('Restaurant.Placeholder.LastName')}
                maxLength={STATE_MAX_LENGTH}
                isMandatory
              />
            </Col>
            <Col lg="5">
              <InputLabel
                id="test"
                name="adminEmail"
                type="text"
                value={restaurantInfo.email}
                label={useIntlMessages('Add.Email')}
                errorMessage=""
                onChange={(e) => onInputHandleChange(e)}
                placeholder={useIntlMessages('Restaurant.Placeholder.Email')}
                maxLength={EMAIL_MAX_LENGTH}
                disabled
                isMandatory
              />
            </Col>

            <Col lg="5">
              <InputMaskLabel
                label={useIntlMessages('Add.Phone')}
                id="phone"
                name="adminPhone"
                type="phone"
                mask="(999) 999-9999"
                value={restaurantInfo.adminPhone}
                onChange={(e) => onPhoneFieldHandleChange(e)}
                errorMessage={errorVisible ? errorFields?.adminPhone : ''}
                placeholder={useIntlMessages('Restaurant.Placeholder.Phone')}
                isMandatory
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} className="mt-4">
              {getDeactivateBtn()}
              {getActivateBtn()}
            </Col>
            <Col lg={5} className="text-end mt-4">
              <Button variant="primary" onClick={onSubmit}>
                <FormattedMessage id="Button.Save" />
              </Button>
            </Col>
          </Row>
        </div>
      </div>
      {/* Show loading state */}
      {addRestaurantLoading && <GifLoader />}
      {updateStatusRestaurantLoading && <GifLoader />}
      {/*  deActivate popup */}
      <Modal show={deActivate} onHide={() => hideDeactivatePopup()} centered size="lg" className="mx-w-725">
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-sm">
            <FormattedMessage id="Restaurant.Deactivate.HD" /> {restaurantName} ?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-3">
          <p>
            <FormattedMessage id="Restaurant.Deactivate.Text.Line1" /> {restaurantName}. <FormattedMessage id="Restaurant.Deactivate.Text.Line2" />
          </p>
          <Row className="d-flex justify-content-center">
            <p className="text-center mb-2">
              <FormattedMessage id="Vendor.Popup.InputLabel" />{' '}
              <i>
                <FormattedMessage id="Vendor.Deactivate.Deactivate" />
              </i>
            </p>
            <Col xl={4}>
              <div className="form-sm">
                <InputLabel id="test" name="deactivate" type="text" value={deactivateText} onChange={(e) => onTextChange(e)} />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" className="btn-modal-footer" onClick={hideDeactivatePopup}>
            <FormattedMessage id="Button.Cancelthis" />
          </Button>
          <Button variant="primary" onClick={deActiavateRestaurant} disabled={deactivateText !== ActivateDeactivateText.DEACTIVATE}>
            <FormattedMessage id="Button.DeactivateRestaurant" />
          </Button>
        </Modal.Footer>
      </Modal>

      {/*  deActivated Confirmation popup */}
      <Modal show={deActivated} onHide={() => deActivatedPop(false)} centered className="mx-w-350">
        <Modal.Body>
          <p>
            {restaurantName} <FormattedMessage id="Restaurant.Deactivated.Text" />
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              deActivatedPop(false);
              navigate(`/restaurants/details/${params.restaurantId}`);
            }}
          >
            <FormattedMessage id="Button.OK" />
          </Button>
        </Modal.Footer>
      </Modal>

      {/*  Activate popup */}
      <Modal show={Activate} onHide={() => hideActivatePopup()} centered size="lg" className="mx-w-725">
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-sm">
            <FormattedMessage id="Restaurant.Activate.HD" /> {restaurantName} ?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-3">
          <p>
            <FormattedMessage id="Restaurant.Activate.Text1" /> {restaurantName}. <FormattedMessage id="Restaurant.Activate.Text2" />
          </p>
          <Row className="d-flex justify-content-center">
            <p className="text-center mb-2">
              <FormattedMessage id="Vendor.Popup.InputLabel" />{' '}
              <i>
                <FormattedMessage id="Vendor.Activate.Activate" />
              </i>
            </p>
            <Col xl={4}>
              <div className="form-sm">
                <InputLabel id="test" name="activate" type="text" value={activateText} onChange={(e) => onTextChange(e)} />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" className="btn-modal-footer" onClick={hideActivatePopup}>
            <FormattedMessage id="Button.Cancelthis" />
          </Button>
          <Button variant="primary" onClick={activateRestaurant} disabled={activateText !== ActivateDeactivateText.ACTIVATE}>
            <FormattedMessage id="Button.ActivateRestaurant" />
          </Button>
        </Modal.Footer>
      </Modal>

      {/*  Activated Confirmation popup */}
      <Modal show={Activated} onHide={() => ActivatedPop(false)} centered className="mx-w-350">
        <Modal.Body>
          <p>
            {restaurantName} <FormattedMessage id="Restaurant.activated.Text" />
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              ActivatedPop(false);
              navigate(`/restaurants/details/${params.restaurantId}`);
            }}
          >
            <FormattedMessage id="Button.OK" />
          </Button>
        </Modal.Footer>
      </Modal>

      {/*  Restaurant Added Confirmation popup */}
      <Modal show={restaurantAdded} onHide={() => setRestaurantAdded(false)} centered className="mx-w-350">
        <Modal.Body>
          <p>
            <FormattedMessage id="Restaurant.Added.Text" />
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSuccessPopUpOk}>
            <FormattedMessage id="Button.OK" />
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default RestaurantAdd;
