/**
 * @file   src\store\slices\authSlices.ts
 * @brief  This file is responsible for creating auth slices to call actions and state management.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { createSlice } from '@reduxjs/toolkit';
import { validateUser, validateByEmail, validateResetPasswordToken, sendLoginOtp } from '../actions/authAction';
import { Iauth } from '../../interfaces/authInterface';
import { useIntlActionMessages } from '../../utils/helper';
import { userLogout } from '../actions/userAction';

// Initial state.
const authState: Iauth = {
  userInfo: {},
  userId: null,
  userToken: null,
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
  forgotMailSentSuccess: false,
  forgptPasswordErrorCode: 0,
  forgotPasswordErrorMessage: null,
  isOtpLoading: false,
  isOtpSuccess: false,
  otpErrorCode: 0,
  otpErrorMessage: null,
};

// Config user slice.
export const authSlice = createSlice({
  name: 'auth',
  initialState: authState,
  reducers: {
    resetAuthState: () => authState,
  },
  extraReducers(builder) {
    // Start validate user request.
    builder.addCase(validateUser.pending, (state) => {
      state.isSuccess = false;
      state.isLoading = true;
      state.errorCode = 0;
      state.errorMessage = '';
      state.forgotMailSentSuccess = false;
    });
    // Validate user request success.
    builder.addCase(validateUser.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error || 0;
      state.isLoading = error === 0;
      state.isSuccess = true;
      state.userInfo = data;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // Validate user error.
    builder.addCase(validateUser.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.isSuccess = false;
      state.isLoading = false;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Start forgot password validation request.
    builder.addCase(validateByEmail.pending, (state) => {
      state.isSuccess = false;
      state.isLoading = true;
      state.forgptPasswordErrorCode = 0;
      state.forgotPasswordErrorMessage = null;
      state.forgotMailSentSuccess = false;
    });
    // Forgot password validation request success.
    builder.addCase(validateByEmail.fulfilled, (state, action) => {
      const error = action.payload?.error || 0;
      state.forgotMailSentSuccess = true;
      state.forgptPasswordErrorCode = error;
      state.forgotPasswordErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // Forgot password validation error.
    builder.addCase(validateByEmail.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.forgotMailSentSuccess = false;
      state.isLoading = false;
      state.forgptPasswordErrorCode = error;
      state.forgotPasswordErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Start validate token request.
    builder.addCase(validateResetPasswordToken.pending, (state) => {
      state.isSuccess = false;
      state.isLoading = true;
      state.forgptPasswordErrorCode = 0;
      state.forgotPasswordErrorMessage = null;
      state.forgotMailSentSuccess = false;
    });
    // Validate token request success.
    builder.addCase(validateResetPasswordToken.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error || 0;
      state.isLoading = false;
      state.isSuccess = true;
      state.userId = data?.userId;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // Validate token error.
    builder.addCase(validateResetPasswordToken.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.isSuccess = false;
      state.isLoading = false;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Start send otp request.
    builder.addCase(sendLoginOtp.pending, (state) => {
      state.isOtpSuccess = false;
      state.isOtpLoading = true;
      state.otpErrorCode = 0;
      state.otpErrorMessage = null;
    });
    // Send otp request success.
    builder.addCase(sendLoginOtp.fulfilled, (state, action) => {
      const error = action.payload?.error || 0;
      state.isOtpLoading = false;
      state.isOtpSuccess = true;
      state.otpErrorCode = error;
      state.otpErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : 'Login.Otp.Sent.Success');
    });
    // Send otp token error.
    builder.addCase(sendLoginOtp.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.isOtpSuccess = false;
      state.isOtpLoading = false;
      state.otpErrorCode = error;
      state.otpErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Update auth state while user logout action fullfilled.
    builder.addCase(userLogout.fulfilled, (state) => {
      state.userInfo = {};
      state.userId = null;
      state.userToken = null;
      state.errorCode = 0;
      state.errorMessage = null;
      state.isSuccess = false;
      state.isLoading = false;
      state.forgotMailSentSuccess = false;
      state.forgptPasswordErrorCode = 0;
      state.forgotPasswordErrorMessage = null;
    });
  },
});
// Export auth actions.
export const { resetAuthState } = authSlice.actions;
// Export reducer.
export default authSlice.reducer;
