/**
 * @file   src\components\CreateUser.tsx
 * @brief  Create User Form Component
 * @date   June, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { useState, useEffect, Button, Row, Col, Modal, Link, FormattedMessage, useNavigate, Select, components } from '../utils/thirdpartyComponents';
import DropAdmin from '../assets/DropAdminIcon.svg';
import InputIcon from './InputIcon';
import FirstName from '../assets/icons/Profile';
import Email from '../assets/icons/Mail';
import InputMaskLabel from './InputMaskLabel';
import Role from '../assets/icons/Role';
import { IAddUserRequest, IDeleteUserRequest, IRoleOption } from '../interfaces/userInterface';
import { USER_SCHEMA } from '../validations/userSchema';
import { useIntlMessages, getIcon } from '../utils/helper';
import { validateForm } from '../utils/formValidation';
import { addUser, deleteUser } from '../store/actions/userAction';
import { useAppDispatch, useAppSelector } from '../hooks';
import { RootState } from '../store';
import { CountryCodes } from '../utils/enums';
import { DEFAULT_ROLE_VALUE } from '../utils/constants';

const { Option } = components;
const IconOption = (props: any) => (
  <Option {...props}>
    {/* eslint-disable-next-line react/destructuring-assignment */}
    <img src={props.data.icon} alt={props.data.label} />
    {/* eslint-disable-next-line react/destructuring-assignment */}
    {props.data.label}
  </Option>
);

// Default user form data
const defaultUserData = {
  userId: 0,
  roleId: 0,
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  countryCode: CountryCodes.US,
};

const CreateUser = ({ rolesList, editData, editActive, setFormVisible, refreshUsers, setEditActive, isRoleLinkVisible }: any) => {
  // Create action dispatch
  const dispatch = useAppDispatch();

  // Create navigation object
  const navigate = useNavigate();

  // Actions to call apis
  const addAdminUser = (request: IAddUserRequest) => dispatch(addUser(request));
  const deleteAdminUser = (request: IDeleteUserRequest) => dispatch(deleteUser(request));

  // Accessing redux state variables
  const { addUserSuccess, deleteUserSuccess, addUserErrorCode, deleteUserErrorCode } = useAppSelector((state: RootState) => state.user);

  // Component state variables
  const [userAddedPopUp, setUserAddedPopUp] = useState<boolean>(false);
  const [deleteUserPop, setDeleteUserPop] = useState<boolean>(false);
  const [deleteConfirm, deleteConfirmPop] = useState<boolean>(false);
  const [roleOptions, setRoleOptions] = useState<IRoleOption[]>([]);
  const [addUserData, setAddUserData] = useState<IAddUserRequest>(defaultUserData);
  const [errorFields, setErrorFields] = useState<any>({});
  const [roleError, setRoleError] = useState<string>(useIntlMessages('User.AddForm.SelectRole'));
  const [errorVisible, setErrorVisible] = useState<boolean>(false);
  const [role, setRole] = useState<any>([DEFAULT_ROLE_VALUE]);
  const [editConfirmPopup, setEditConfirmPopup] = useState<boolean>(false);
  const [deleteUserGuid, setUserGuid] = useState<string>('');

  // Set Role Options For Select Box .Set data for user edit
  useEffect(() => {
    if (rolesList && rolesList.length > 0) {
      const options = rolesList.map((roles: any) => ({ value: roles.roleId, label: roles.roleName, icon: getIcon(roles.iconFile) }));
      setRoleOptions(options);
    }
    if (editActive && Object.keys(editData).length > 0) {
      setAddUserData({
        userId: editData.userId,
        roleId: editData.roleId,
        firstName: editData.firstName,
        lastName: editData.lastName,
        email: editData.email,
        phone: editData.phone,
        countryCode: editData.countryCode,
      });
      setRole({ value: editData.roleId, label: editData.roleName, icon: DropAdmin });
      setRoleError('');
      setUserGuid(editData.userGuid);
    }
  }, []);

  // show add user success popup
  useEffect(() => {
    if (addUserSuccess && !editActive && addUserErrorCode === 0) {
      setUserAddedPopUp(true);
    } else if (addUserSuccess && editActive && addUserErrorCode === 0) {
      setEditConfirmPopup(true);
    }
  }, [addUserSuccess]);
  // show delete user success popup
  useEffect(() => {
    if (deleteUserSuccess && deleteUserErrorCode === 0) {
      deleteConfirmPop(true);
    }
  }, [deleteUserSuccess]);

  // handle form fields validation
  const onInputHandleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setAddUserData((info) => ({
      ...info,
      [name]: value,
    }));
    const validateObj = {
      [name]: value,
    };
    const errorresult = await validateForm(validateObj, USER_SCHEMA, errorFields);
    setErrorFields(errorresult);
  };
  // handle phone field input change
  const onPhoneFieldHandleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const inputVal = value ? value.replace(/\D/g, '') : '';
    setAddUserData((info) => ({
      ...info,
      [name]: inputVal,
    }));
    const validateObj = {
      [name]: inputVal,
    };
    const errorresult = await validateForm(validateObj, USER_SCHEMA, errorFields);
    setErrorFields(errorresult);
  };
  // handle role select box change
  const onRoleSelectChange = (event: IRoleOption) => {
    setAddUserData({ ...addUserData, roleId: event.value });
    setRole(event);
    setRoleError('');
  };
  // handle form submit
  const onSubmit = async () => {
    setErrorVisible(true);
    const formData = {
      firstName: addUserData.firstName,
      lastName: addUserData.lastName,
      email: addUserData.email,
      phone: addUserData.phone,
    };
    const errorresult = await validateForm(formData, USER_SCHEMA, errorFields);
    setErrorFields(errorresult);
    if (Object.keys(errorresult).length === 0) {
      const userRequest = { ...addUserData };
      userRequest.email = userRequest.email.trim();
      userRequest.firstName = userRequest.firstName.trim();
      userRequest.lastName = userRequest.lastName.trim();
      userRequest.phone = userRequest.phone.trim();
      addAdminUser(userRequest);
    }
  };
  // handle add user cancel button
  const handleCancel = () => {
    setFormVisible(false);
    setAddUserData(defaultUserData);
    setEditActive(false);
  };
  // handle ok button in popup
  const handlePopupOk = () => {
    setUserAddedPopUp(false);
    setEditConfirmPopup(false);
    setFormVisible(false);
    deleteConfirmPop(false);
    refreshUsers();
  };
  //  delete user api call
  const deleteUserConfirm = () => {
    setDeleteUserPop(false);
    const request = { userGuid: deleteUserGuid };
    deleteAdminUser(request);
  };

  return (
    <div>
      <div className="content-filter-main">
        <p>
          <FormattedMessage id="User.AddForm.Title" />
        </p>
        <Row className="mb-4 align-items-center">
          <Col xs md={6} lg={5} xl={4}>
            <div className="select-icon-outer position-relative">
              <Select
                options={roleOptions}
                components={{ Option: IconOption }}
                className="select-with-icon"
                placeholder={useIntlMessages('User.AddForm.SelectRole.Placeholder')}
                classNamePrefix="select_icon"
                onChange={onRoleSelectChange}
                value={role}
              />
              {errorVisible && <span className="error"> {roleError} </span>}
              <Role />
            </div>
          </Col>
          {isRoleLinkVisible && (
            <Col xs="auto" className="p-xs-0">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/users/roles/add');
                }}
              >
                <FormattedMessage id="User.AddForm.AddRole.Link" />
              </a>
            </Col>
          )}
        </Row>
        <Row>
          <Col xs={12} md={6} lg={5} xl={4}>
            <InputIcon
              id="FirstName"
              name="firstName"
              type="text"
              placeholder={useIntlMessages('User.AddForm.Firstname')}
              icon={<FirstName />}
              onChange={(e) => onInputHandleChange(e)}
              value={addUserData.firstName}
              errorMessage={errorVisible && errorFields?.firstName}
              maxLength={21}
            />
          </Col>
          <Col xs={12} md={6} lg={5} xl={4}>
            <InputIcon
              id="LastName"
              name="lastName"
              type="text"
              placeholder={useIntlMessages('User.AddForm.Lastname')}
              icon={<FirstName />}
              onChange={(e) => onInputHandleChange(e)}
              value={addUserData.lastName}
              errorMessage={errorVisible && errorFields?.lastName}
              maxLength={21}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6} lg={5} xl={4}>
            <InputIcon
              id="EmailAddress"
              name="email"
              type="text"
              placeholder={useIntlMessages('User.AddForm.Email')}
              icon={<Email />}
              onChange={(e) => onInputHandleChange(e)}
              value={addUserData.email}
              errorMessage={errorVisible && errorFields?.email}
              maxLength={201}
            />
          </Col>
          <Col xs={12} md={6} lg={5} xl={4}>
            <InputMaskLabel
              id="phone"
              name="phone"
              type="phone"
              placeholder={useIntlMessages('Add.Phone')}
              mask="(999) 999-9999"
              maxLength={10}
              onChange={(e) => onPhoneFieldHandleChange(e)}
              value={addUserData.phone}
              errorMessage={errorVisible && errorFields?.phone}
            />
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col xs="auto">
            <Button variant="primary" onClick={onSubmit}>
              <FormattedMessage id="Button.Save" />
            </Button>
          </Col>
          <Col xs="auto">
            <Button variant="secondary" onClick={() => handleCancel()}>
              <FormattedMessage id="Button.Cancel" />
            </Button>
          </Col>
          {editActive && (
            <Col xs="auto" className="p-xs-0">
              <Link to="#" onClick={() => setDeleteUserPop(true)} className="red-link">
                <FormattedMessage id="User.Deleteuser.Link" />
              </Link>
            </Col>
          )}
        </Row>
        {/* User Add Popup */}
        <Modal show={userAddedPopUp} onHide={() => setUserAddedPopUp(false)} centered>
          <Modal.Body>
            <FormattedMessage id="UserAdded.Pop.Line1" />
            {addUserData.firstName}
            <FormattedMessage id="UserAdded.Pop.Line2" />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => handlePopupOk()}>
              <FormattedMessage id="Button.Ok" />
            </Button>
          </Modal.Footer>
        </Modal>
        {/* User delete popup */}
        <Modal show={deleteUserPop} onHide={() => setDeleteUserPop(false)} centered size="lg">
          <Modal.Header>
            <Modal.Title id="example-modal-sizes-title-sm">
              <FormattedMessage id="DeleteUser.Pop.Headline" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormattedMessage id="DeleteUser.Pop.Content" />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" className="btn-modal-footer" onClick={() => setDeleteUserPop(false)}>
              <FormattedMessage id="DeleteUser.Pop.Cancel" />
            </Button>
            <Button variant="primary" onClick={() => deleteUserConfirm()}>
              <FormattedMessage id="DeleteUser.Pop.Delete" />
            </Button>
          </Modal.Footer>
        </Modal>
        {/* User delete Confirmation popup */}
        <Modal show={deleteConfirm} onHide={() => deleteConfirmPop(false)} centered>
          <Modal.Body>
            <FormattedMessage id="UserDelete.Pop.Line2" />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => handlePopupOk()}>
              <FormattedMessage id="Button.Ok" />
            </Button>
          </Modal.Footer>
        </Modal>
        {/* User Edit Confirmation popup */}
        <Modal show={editConfirmPopup} onHide={() => setEditConfirmPopup(false)} centered>
          <Modal.Body>
            <FormattedMessage id="UserEdit.Pop.Line2" />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => handlePopupOk()}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default CreateUser;
