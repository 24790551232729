/**
 * @file   src\store\slices\userSlices.ts
 * @brief  This file is responsible for creating user slices to call actions and state management.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { createSlice } from '@reduxjs/toolkit';
import { userLogout, resetPassword, adminPasswordUpdate, getAdminProfile, updateAdminProfile, getUsersList, addUser, deleteUser, resendInvitation } from '../actions/userAction';
import { IUser } from '../../interfaces/userInterface';
import { getFromLocalStorage, useIntlActionMessages } from '../../utils/helper';
import { validateUser } from '../actions/authAction';

// Initial state.
const userState: IUser = {
  userInfo: {},
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
  isLogoutSuccess: false,
  resetLoading: false,
  resetSuccess: false,
  adminProfile: {},
  profileUpdateLoading: false,
  profileUpdateSuccess: false,
  pwdUpdateLoading: false,
  pwdUpdateSuccess: false,
  userList: [],
  addUserLoading: false,
  addUserSuccess: false,
  addUserErrorMessage: '',
  deleteUserLoading: false,
  deleteUserSuccess: false,
  addUserErrorCode: 0,
  deleteUserErrorCode: 0,
  deleteUserErrorMessage: '',
  roleListErrorCode: 0,
  userListCount: 0,
  resendInvitationErrorCode: 0,
  resendInvitationLoading: false,
  resendInvitationSuccess: false,
  resendInvitationErrorMessage: '',
};

// Config user slice.
const userSlice = createSlice({
  name: 'user',
  initialState: userState,
  reducers: {
    getUserInfo: (state) => {
      state.userInfo = getFromLocalStorage('MCSA_UDT');
    },
    resetProfileData: (state) => {
      state.pwdUpdateLoading = false;
      state.pwdUpdateSuccess = false;
      state.profileUpdateLoading = false;
      state.profileUpdateSuccess = false;
      state.resetLoading = false;
      state.resetSuccess = false;
      state.errorCode = 0;
    },
    resetAddUser: (state) => {
      state.addUserErrorCode = 0;
      state.addUserLoading = false;
      state.addUserSuccess = false;
      state.deleteUserErrorCode = 0;
      state.deleteUserLoading = false;
      state.deleteUserSuccess = false;
      state.deleteUserErrorMessage = '';
      state.addUserErrorMessage = '';
    },
    resetUserInvite: (state) => {
      state.resendInvitationErrorCode = 0;
      state.resendInvitationErrorMessage = '';
      state.resendInvitationLoading = false;
      state.resendInvitationSuccess = false;
    },
  },
  extraReducers(builder) {
    // Start userLogout request.
    builder.addCase(userLogout.pending, (state) => {
      state.isLoading = true;
      state.errorCode = 0;
      state.errorMessage = '';
    });
    // userLogout reqest success.
    builder.addCase(userLogout.fulfilled, (state, action) => {
      const error = action.payload?.error || 0;
      state.isLoading = false;
      state.isLogoutSuccess = true;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // userLogout error.
    builder.addCase(userLogout.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.isLoading = false;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    builder.addCase(resetPassword.pending, (state) => {
      state.resetLoading = true;
      state.errorCode = 0;
      state.errorMessage = '';
    });
    // userLogout reqest success.
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      const error = action.payload?.error || 0;
      state.resetLoading = false;
      state.resetSuccess = true;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error !== 0 ? error.toString() : 'ResetPassword.Api.Success');
    });
    // userLogout error.
    builder.addCase(resetPassword.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.resetLoading = false;
      state.resetSuccess = false;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Update user state while validate user action fullfilled.
    builder.addCase(validateUser.fulfilled, (state) => {
      state.userInfo = {};
      state.errorCode = 0;
      state.errorMessage = null;
      state.isSuccess = false;
      state.isLoading = false;
      state.isLogoutSuccess = false;
      state.resetLoading = false;
      state.resetSuccess = false;
    });
    // Get admin profile api starts
    builder.addCase(getAdminProfile.pending, (state) => {
      state.isLoading = true;
    });
    // Get admin profile api success
    builder.addCase(getAdminProfile.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      state.adminProfile = data;
      state.isSuccess = true;
      state.isLoading = false;
    });
    // Get admin profile api failure
    builder.addCase(getAdminProfile.rejected, (state) => {
      state.isLoading = false;
      state.isSuccess = false;
    });
    // Update admin profile starts
    builder.addCase(updateAdminProfile.pending, (state) => {
      state.profileUpdateLoading = true;
      state.errorCode = 0;
      state.profileUpdateSuccess = false;
    });
    // Update admin profile success
    builder.addCase(updateAdminProfile.fulfilled, (state, action) => {
      const error = action.payload?.error || 0;
      state.profileUpdateLoading = false;
      state.profileUpdateSuccess = true;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : 'AdminProfile.Edit.Success');
    });
    // Update admin profile failure
    builder.addCase(updateAdminProfile.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.profileUpdateLoading = false;
      state.profileUpdateSuccess = false;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // admin password update api starts
    builder.addCase(adminPasswordUpdate.pending, (state) => {
      state.pwdUpdateLoading = true;
      state.errorCode = 0;
      state.pwdUpdateSuccess = false;
    });
    // admin password update api success
    builder.addCase(adminPasswordUpdate.fulfilled, (state, action) => {
      const error = action.payload?.error || 0;
      state.pwdUpdateLoading = false;
      state.pwdUpdateSuccess = true;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : 'Admin.Password.Edit.Success');
    });
    // admin password update api failure
    builder.addCase(adminPasswordUpdate.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.pwdUpdateLoading = false;
      state.pwdUpdateSuccess = false;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // get users list api starts
    builder.addCase(getUsersList.pending, (state) => {
      state.isLoading = true;
      state.errorCode = 0;
      state.isSuccess = false;
    });
    // get users list api success
    builder.addCase(getUsersList.fulfilled, (state, action) => {
      const error = action.payload?.error || 0;
      const data = action.payload?.data || null;
      state.userList = data?.users || [];
      state.isLoading = false;
      state.isSuccess = true;
      state.errorCode = error;
      state.userListCount = action.payload.data.totalCount;
      state.errorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // get users list api failure
    builder.addCase(getUsersList.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.isLoading = false;
      state.isSuccess = false;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // add user api starts
    builder.addCase(addUser.pending, (state) => {
      state.addUserLoading = true;
      state.addUserSuccess = false;
      state.addUserErrorCode = 0;
      state.addUserErrorMessage = '';
    });
    // add user api success
    builder.addCase(addUser.fulfilled, (state, action) => {
      const error = action.payload?.error || 0;
      state.addUserLoading = false;
      state.addUserSuccess = true;
      state.addUserErrorCode = error;
      state.addUserErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // add user api failure
    builder.addCase(addUser.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.addUserLoading = false;
      state.addUserSuccess = false;
      state.addUserErrorCode = error;
      state.addUserErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // delete user api starts
    builder.addCase(deleteUser.pending, (state) => {
      state.deleteUserLoading = true;
      state.deleteUserErrorCode = 0;
      state.deleteUserSuccess = false;
      state.deleteUserErrorMessage = '';
    });
    // delete user api success
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      const error = action.payload?.error || 0;
      state.deleteUserLoading = false;
      state.deleteUserSuccess = true;
      state.deleteUserErrorCode = error;
      state.deleteUserErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // delete user api failure
    builder.addCase(deleteUser.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.deleteUserLoading = false;
      state.deleteUserSuccess = false;
      state.deleteUserErrorCode = error;
      state.deleteUserErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // get roles list api starts
    builder.addCase(resendInvitation.pending, (state) => {
      state.resendInvitationLoading = true;
      state.resendInvitationSuccess = false;
      state.resendInvitationErrorCode = 0;
      state.resendInvitationErrorMessage = '';
    });
    // get roles list api success
    builder.addCase(resendInvitation.fulfilled, (state, action) => {
      const error = action.payload?.error || 0;
      state.resendInvitationLoading = false;
      state.resendInvitationSuccess = true;
      state.resendInvitationErrorCode = error;
      state.resendInvitationErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : 'ResendInvitation.Success');
    });
    // get roles list api failure
    builder.addCase(resendInvitation.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.resendInvitationLoading = false;
      state.resendInvitationSuccess = false;
      state.resendInvitationErrorCode = error;
      state.resendInvitationErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
  },
});
export const { getUserInfo, resetProfileData, resetAddUser, resetUserInvite } = userSlice.actions;
// Export reducer.
export default userSlice.reducer;
