/**
 * @file   src\store\actions\roleAction.ts
 * @brief  This file is responsible for creating asynchronous role based api call.
 * @date   July, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../config/axiosConfig';
import { RoleApi } from '../../utils/apiUrls';
import { IRoleAddEditRequest } from '../../interfaces/roleInterface';

// Get all admin roles api call.
export const getRoles = createAsyncThunk('/role/getRoles', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(RoleApi.ROLES_LIST);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Add/edit role api call.
export const saveRole = createAsyncThunk('/role/saveRole', async (roleRequest: IRoleAddEditRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(RoleApi.ROLES_SAVE, roleRequest);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Delete role api call.
export const deleteRole = createAsyncThunk('/role/deleteRole', async (roleId: number, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(RoleApi.ROLES_DELETE, { roleId });
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Get all role permissions api call.
export const getPermissions = createAsyncThunk('/role/getPermissions', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(RoleApi.ROLE_PERMISSIONS);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Get all role notifications api call.
export const getNotifications = createAsyncThunk('/role/getNotifications', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(RoleApi.ROLE_NOTIFICATIONS);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Get all role by id api call.
export const getRoleById = createAsyncThunk('/role/getRoleById', async (roleId: number, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${RoleApi.ROLE_DETAILS}/${roleId}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});
