import React from 'react';

interface ISearchRemoveInput {
  onClick: () => void;
  value: any;
}
const SearchRemoveIcon = ({ value, onClick }: ISearchRemoveInput) => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick} display={value !== '' ? 'block' : 'none'}>
      <title>Clear Location</title>
      <path d="M22.364 20.95L27.314 16L28.728 17.414L23.778 22.364L28.728 27.314L27.314 28.728L22.364 23.778L17.414 28.728L16 27.314L20.95 22.364L16 17.414L17.414 16L22.364 20.95Z" />
    </svg>
  );
};

export default SearchRemoveIcon;
