/**
 * @file   src\features\restaurant\SupportMode.tsx
 * @brief  Support mode page
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { useEffect, FormattedMessage, useNavigate, useState, useLocation, useParams } from '../../utils/thirdpartyComponents';
import { getCobrowseToken } from '../../store/actions/restaurantAction';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { RootState } from '../../store';
import BackIcon from '../../assets/icons/Back';
import GifLoader from '../../components/GifLoader';
import { checkPathMatched } from '../../utils/helper';
import { MenuUrls } from '../../utils/enums';

const CoBrowse = () => {
  // create action dispatch
  const dispatch = useAppDispatch();
  // navigation object
  const navigate = useNavigate();
  // location object
  const location = useLocation();
  // route params object
  const params = useParams();
  // accessing redux state variables
  const { cobrowseTokenData, cobrowseTokenSuccess } = useAppSelector((state: RootState) => state.restaurant);

  const [showLoading, setShowLoading] = useState<boolean>(true);
  // call to get cobrowse token on intial loading
  useEffect(() => {
    dispatch(getCobrowseToken());
  }, []);
  // function to load previous page
  const loadBackPage = (event: any) => {
    event.preventDefault();
    if (checkPathMatched(MenuUrls.VENDOR_COBROWSE, location?.pathname)) {
      navigate(`/vendors/details/${Number(params.id)}`);
    } else {
      navigate(`/restaurants/details/${Number(params.id)}`);
    }
  };
  return (
    <div className="content-nav contnt-container-margin">
      <div className="back-navigation">
        <a href="#" onClick={(event) => loadBackPage(event)}>
          <BackIcon />
          <FormattedMessage id="Link.Back" />
        </a>
      </div>
      <div className="content-nav-sub">
        {showLoading && <GifLoader />}
        {cobrowseTokenSuccess && (
          <iframe
            src={`https://cobrowse.io/dashboard?token=${cobrowseTokenData.cobrowseToken}`}
            width="100%"
            height="700px"
            title="Cobrowse"
            onLoad={() => setShowLoading(false)}
          />
        )}
      </div>
    </div>
  );
};

export default CoBrowse;
