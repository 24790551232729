/**
 * @file   src\store\slices\subscriptionSlice.ts
 * @brief  This file is responsible for creating subscription based slices to call actions and state management.
 * @date   July, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { createSlice } from '@reduxjs/toolkit';
import { ISubscription } from '../../interfaces/subscriptionInterface';
import { getSubscriptionSettings, updateSubscriptionSettings, getIncentives, getMappedIncentiveUsers, updatePayment } from '../actions/subscriptionAction';
import { useIntlActionMessages } from '../../utils/helper';

// Initial state
const subscriptionDefaultState: ISubscription = {
  subscriptionSettings: {},
  subscriptionSettingsLoading: false,
  subscriptionSettingsError: 0,
  subscriptionSettingsMessage: '',
  subscriptionSettingsSuccess: false,
  updateSettingsError: 0,
  updateSettingsLoading: false,
  updateSettingsMessgae: '',
  updateSettingsSuccess: false,
  incentives: [],
  incentivesCount: 0,
  isLoading: false,
  isSuccess: false,
  errorCode: 0,
  errorMessage: '',
  mappedUsers: [],
  mappedUserLoading: false,
  mappedUserSuccess: false,
  mappedErrorCode: 0,
  mappedErrorMessage: '',
  incentivePaymentLoading: false,
  incentivePaymentSuccess: false,
  incentivePaymentErrorCode: 0,
  incentivePaymentErrorMessage: '',
};

// Subscription slice
const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState: subscriptionDefaultState,
  reducers: {
    resetSubscription: (state) => {
      state.subscriptionSettings = {};
      state.subscriptionSettingsLoading = false;
      state.subscriptionSettingsError = 0;
      state.subscriptionSettingsMessage = '';
      state.subscriptionSettingsSuccess = false;
      state.updateSettingsError = 0;
      state.updateSettingsLoading = false;
      state.updateSettingsMessgae = '';
      state.updateSettingsSuccess = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.errorCode = 0;
      state.errorMessage = '';
      state.mappedUserLoading = false;
      state.mappedUserSuccess = false;
      state.mappedErrorCode = 0;
      state.mappedErrorMessage = '';
      state.incentivePaymentLoading = false;
      state.incentivePaymentSuccess = false;
      state.incentivePaymentErrorCode = 0;
      state.incentivePaymentErrorMessage = '';
    },
  },
  extraReducers(builder) {
    // get subscription settings api starts
    builder.addCase(getSubscriptionSettings.pending, (state) => {
      state.subscriptionSettingsLoading = true;
      state.subscriptionSettingsSuccess = false;
      state.subscriptionSettingsError = 0;
      state.subscriptionSettingsMessage = '';
    });
    // get subscription settings api success
    builder.addCase(getSubscriptionSettings.fulfilled, (state, action) => {
      const error = action.payload?.error || 0;
      const data = action.payload?.data || {};
      state.subscriptionSettingsLoading = false;
      state.subscriptionSettingsSuccess = true;
      state.subscriptionSettings = data?.setting || {};
      state.subscriptionSettingsError = error;
      state.subscriptionSettingsMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // get subscription settings api failure
    builder.addCase(getSubscriptionSettings.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.subscriptionSettingsLoading = false;
      state.subscriptionSettingsSuccess = false;
      state.subscriptionSettingsError = error;
      state.subscriptionSettingsMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Update subscription api pending
    builder.addCase(updateSubscriptionSettings.pending, (state) => {
      state.updateSettingsLoading = true;
      state.updateSettingsSuccess = false;
      state.updateSettingsError = 0;
      state.updateSettingsMessgae = '';
    });
    // Update subscription api success
    builder.addCase(updateSubscriptionSettings.fulfilled, (state, action) => {
      const error = action.payload?.error || 0;
      state.updateSettingsLoading = false;
      state.updateSettingsSuccess = true;
      state.updateSettingsError = error;
      state.updateSettingsMessgae = useIntlActionMessages(error !== 0 ? error.toString() : 'Subscription.SettingsUpdated');
    });
    // Update subscription api failure
    builder.addCase(updateSubscriptionSettings.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.updateSettingsLoading = false;
      state.updateSettingsSuccess = false;
      state.updateSettingsError = error;
      state.updateSettingsMessgae = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Start incentives list request.
    builder.addCase(getIncentives.pending, (state) => {
      state.isSuccess = false;
      state.isLoading = true;
      state.errorCode = 0;
      state.errorMessage = '';
    });
    // Incentives list request success.
    builder.addCase(getIncentives.fulfilled, (state, action) => {
      const data = action.payload?.data || null;
      const error = action.payload?.error || 0;
      state.incentives = data?.items || [];
      state.incentivesCount = data?.totalCount;
      state.isLoading = false;
      state.isSuccess = true;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // Incentives list error.
    builder.addCase(getIncentives.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.isSuccess = false;
      state.isLoading = false;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Start mapped by users list request.
    builder.addCase(getMappedIncentiveUsers.pending, (state) => {
      state.mappedUserSuccess = false;
      state.mappedUserLoading = true;
      state.mappedErrorCode = 0;
      state.mappedErrorMessage = '';
    });
    // Mapped by users request success.
    builder.addCase(getMappedIncentiveUsers.fulfilled, (state, action) => {
      const data = action.payload?.data || null;
      const error = action.payload?.error || 0;
      state.mappedUsers = data?.users || [];
      state.mappedUserLoading = false;
      state.mappedUserSuccess = true;
      state.mappedErrorCode = error;
      state.mappedErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // Mapped by users list error.
    builder.addCase(getMappedIncentiveUsers.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.mappedUserSuccess = false;
      state.mappedUserLoading = false;
      state.mappedErrorCode = error;
      state.mappedErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Pay incentive request start.
    builder.addCase(updatePayment.pending, (state) => {
      state.incentivePaymentLoading = true;
      state.incentivePaymentSuccess = false;
      state.incentivePaymentErrorCode = 0;
      state.incentivePaymentErrorMessage = '';
    });
    // Pay incentive request success.
    builder.addCase(updatePayment.fulfilled, (state, { payload }) => {
      const error = payload?.error || 0;
      state.incentivePaymentLoading = false;
      state.incentivePaymentSuccess = true;
      state.incentivePaymentErrorCode = error;
      state.incentivePaymentErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : 'Incentives.Payment.Success');
    });
    // Pay incentive error.
    builder.addCase(updatePayment.rejected, (state, action: any) => {
      const { error } = action.payload;
      state.incentivePaymentLoading = false;
      state.incentivePaymentSuccess = false;
      state.incentivePaymentErrorCode = error;
      state.incentivePaymentErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
  },
});
// Export actions
export const { resetSubscription } = subscriptionSlice.actions;
// Export reducer.
export default subscriptionSlice.reducer;
