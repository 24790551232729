/**
 * @file   src\components\Teaxtarea.tsx
 * @brief  Html Textarea component.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { useEffect, useRef } from 'react';
import '../styles/Textarea.scss';
import { ITextAreaProperty } from '../interfaces/generalInterface';

const Textarea = ({ rows, label, errorMessage, value, id, name, placeholder, disabled, maxLength, helptext, autoFocus, onChange }: ITextAreaProperty) => {
  const textareaRef: any = useRef(null);

  useEffect(() => {
    if (autoFocus) {
      textareaRef.current.focus();
    }
  }, [autoFocus]);

  return (
    <div className="form-group">
      <div className="textarea-group">
        <label htmlFor="label">{label}</label>
        <textarea
          className={errorMessage && errorMessage !== '' ? 'form-control error' : value && value !== '' ? 'form-control active' : 'form-control'}
          id={id}
          name={name}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          maxLength={maxLength}
          onChange={(event) => onChange && onChange(event)}
          ref={textareaRef}
          rows={rows}
        />
        <small className="help-text">{helptext}</small>
        {errorMessage && errorMessage !== '' && <span className="error">{errorMessage}</span>}
      </div>
    </div>
  );
};

export default Textarea;
