/**
 * @file   src\features\product\SimilarItems.tsx
 * @brief  Similar items details page.
 * @date   July, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { Table, Button, FormattedMessage, useEffect, useLocation, useNavigate, useState, Modal, Col } from '../../utils/thirdpartyComponents';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { RootState } from '../../store';
import { getMappedItemDetails, updateMappedItemStatus } from '../../store/actions/productAction';
import { useIntlActionMessages, useIntlMessages } from '../../utils/helper';
import BackIcon from '../../assets/icons/Back';
import Textarea from '../../components/Textarea';
import { SimilarItemProcessStatus } from '../../utils/enums';
import { IMappedItemStatusUpdateRequest } from '../../interfaces/productInterface';
import { validateForm } from '../../utils/formValidation';
import { SIMILARITEMS_DECLINE_FORM_SCHEMA } from '../../validations/productSchema';
import { resetProductState } from '../../store/slices/productSlice';
import { MessageToaster } from '../../utils/toastUtil';
import GifLoader from '../../components/GifLoader';

// Toast object creation.
const toast = new MessageToaster();
const SimilarItems = () => {
  // Create current location object.
  const location = useLocation();

  // Navigate object creation.
  const navigate = useNavigate();

  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Access redux state variables.
  const { isLoading, similarItemDetails, updateMappedItemStatusLoading, updateMappedItemStatusSuccess, updateMappedItemStatusErrorCode, updateMappedItemStatusErrorMessage } =
    useAppSelector((state: RootState) => state.product);

  // Initialize component state variables.
  const [declinePopUp, setdeclinePopUp] = useState<boolean>(false);
  const [approveConfirmPopUp, setApproveConfirmPopUp] = useState<boolean>(false);
  const [description, setDescription] = useState<string>('');
  const [errorFields, setErrorFields] = useState<any>({});

  // Fetch similar item info at initial loading.
  useEffect(() => {
    /* Access and set the location url parameters. */
    const mappingId: number = location.state?.id || 0;
    dispatch(getMappedItemDetails(mappingId));
  }, []);

  // Show success/error message for approve/declined request.
  useEffect(() => {
    if (updateMappedItemStatusSuccess && updateMappedItemStatusErrorCode === 0 && updateMappedItemStatusErrorMessage) {
      toast.toastSuccess(updateMappedItemStatusErrorMessage);
      dispatch(resetProductState());
      setApproveConfirmPopUp(false);
      setdeclinePopUp(false);
      navigate(-1);
    } else if (updateMappedItemStatusErrorCode > 0 && updateMappedItemStatusErrorMessage) {
      toast.toastError(updateMappedItemStatusErrorMessage);
      dispatch(resetProductState());
      setApproveConfirmPopUp(false);
      setdeclinePopUp(false);
    }
  }, [updateMappedItemStatusSuccess, updateMappedItemStatusErrorCode]);

  // Approve/decline mapping request.
  const handleMappingRequest = async (mappingStatus: number) => {
    const mappingId: number = location.state?.id || 0;
    const updateRequest: IMappedItemStatusUpdateRequest = {
      mappingId,
      approveStatus: mappingStatus,
      remarks: description?.trim(),
    };
    if (mappingId !== 0 && mappingStatus === SimilarItemProcessStatus.APPROVED) {
      dispatch(updateMappedItemStatus(updateRequest));
      setApproveConfirmPopUp(false);
    } else if (mappingId !== 0 && mappingStatus === SimilarItemProcessStatus.DECLINED) {
      const mappedValidationRequest = {
        description: description?.trim(),
      };
      const errorresult = await validateForm(mappedValidationRequest, SIMILARITEMS_DECLINE_FORM_SCHEMA, errorFields);
      setErrorFields(errorresult);
      if (Object.keys(errorresult).length === 0) {
        dispatch(updateMappedItemStatus(updateRequest));
        setdeclinePopUp(false);
        setDescription('');
      }
    }
  };

  // Description input change event.
  const onInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    setDescription(value);
  };

  return (
    <div className="content-nav contnt-container-margin">
      <div className="back-navigation">
        <a href="#" className="back-btn" onClick={() => navigate(-1)}>
          <BackIcon />
          <FormattedMessage id="Link.Back" />
        </a>
      </div>
      <div className="content-nav-sub">
        <div className="content-nav-header border-0 position-relative">
          <div className="text-center">
            <h2 className="d-block">
              <FormattedMessage id="SimilarItems.Details.Heading" />
            </h2>
            <small className="text-lightgray">
              {useIntlActionMessages('SimilarItems.Details.MarkedBy')} {similarItemDetails?.mappingInfo?.restaurantName || ''}
            </small>
          </div>
          {location.state?.from === 'usermappeditems' && similarItemDetails?.mappingInfo?.approvalStatus === SimilarItemProcessStatus.PENDING && (
            <div className="similar-items-btnnav">
              <Button variant="primary" className="btn-sm btn-red-link text-decoration-none" onClick={() => setdeclinePopUp(true)}>
                Decline
              </Button>
              <Button variant="primary" className="btn-sm btn-light-green" onClick={() => setApproveConfirmPopUp(true)}>
                Approve
              </Button>
            </div>
          )}
        </div>
        <Table responsive className="table-striped similaritems mt-5 min-w-100per">
          <tbody>
            {similarItemDetails && Object.keys(similarItemDetails).length > 0 && (
              <>
                <tr className="bg-white vertical-top">
                  <td> </td>
                  <td className="w-35per">
                    <p className="me-5 mb-2 text-blue text-center">Original item</p>
                    <div className="similar-items-img p-5 me-5">
                      <img src={similarItemDetails.itemDetails?.imageUrl || '/no-item-image.jpg'} alt="ProductImage" className="w-100" />
                    </div>
                  </td>
                  <td className="w-35per">
                    <p className="me-5 mb-2 text-blue text-center">Similar item</p>
                    <div className="similar-items-img p-5 me-5">
                      <img src={similarItemDetails.similarItem?.imageUrl || '/no-item-image.jpg'} alt="ProductImage" className="w-100" />
                    </div>
                  </td>
                  <td> </td>
                </tr>
                <tr>
                  <td className="w-225">
                    <FormattedMessage id="SimilarItems.Details.Features" />
                  </td>
                  <td> </td>
                  <td className="bold-text text-end" colSpan={2}>
                    {useIntlActionMessages('SimilarItems.Details.Rank')}: {similarItemDetails.mappingInfo?.rank}
                  </td>
                </tr>
                <tr>
                  <td>
                    <FormattedMessage id="SimilarItems.Details.ItemName" />
                  </td>
                  <td>{similarItemDetails.itemDetails?.itemName}</td>
                  <td>{similarItemDetails.similarItem?.itemName}</td>
                  <td> </td>
                </tr>
                <tr>
                  <td>
                    <FormattedMessage id="SimilarItems.Details.Description" />
                  </td>
                  <td>{similarItemDetails.itemDetails?.description}</td>
                  <td>{similarItemDetails.similarItem?.description}</td>
                  <td> </td>
                </tr>
                <tr>
                  <td>
                    <FormattedMessage id="SimilarItems.Details.ProductGroup" />
                  </td>
                  <td>{similarItemDetails.itemDetails?.masterProductGroupName}</td>
                  <td>{similarItemDetails.similarItem?.masterProductGroupName}</td>
                  <td> </td>
                </tr>
                <tr>
                  <td>
                    <FormattedMessage id="SimilarItems.Details.Origin" />
                  </td>
                  <td>{similarItemDetails.itemDetails?.originCountryName || similarItemDetails.itemDetails?.originStateName || ''}</td>
                  <td>{similarItemDetails.similarItem?.originCountryName || similarItemDetails.similarItem?.originStateName || ''}</td>
                  <td> </td>
                </tr>
                <tr>
                  <td>
                    <FormattedMessage id="SimilarItems.Details.Pack" />
                  </td>
                  <td>{similarItemDetails.itemDetails?.pack || 0}</td>
                  <td>{similarItemDetails.similarItem?.pack || 0}</td>
                  <td> </td>
                </tr>
                <tr>
                  <td>
                    <FormattedMessage id="SimilarItems.Details.Size" />
                  </td>
                  <td>{`${similarItemDetails.itemDetails?.size ? `${similarItemDetails.itemDetails?.size.toFixed(2)} ${similarItemDetails.itemDetails?.unitName || ''}` : 0}`}</td>
                  <td>{`${similarItemDetails.similarItem?.size ? `${similarItemDetails.similarItem?.size.toFixed(2)} ${similarItemDetails.similarItem?.unitName || ''}` : 0}`}</td>
                  <td> </td>
                </tr>
                <tr>
                  <td>
                    <FormattedMessage id="SimilarItems.Details.Vendor" />
                  </td>
                  <td>{similarItemDetails.itemDetails?.vendorName || ''}</td>
                  <td>{similarItemDetails.similarItem?.vendorName || ''}</td>
                  <td> </td>
                </tr>
                {similarItemDetails.itemDetails?.isVariableWeight && (
                  <tr>
                    <td>
                      <FormattedMessage id="SimilarItems.Details.Price/lb" />
                    </td>
                    <td>{`$${similarItemDetails.itemDetails?.pricePerLb ? similarItemDetails.itemDetails?.pricePerLb.toFixed(2) : 0}`}</td>
                    <td>{`$${similarItemDetails.similarItem?.pricePerLb ? similarItemDetails.similarItem?.pricePerLb.toFixed(2) : 0}`}</td>
                    <td> </td>
                  </tr>
                )}
                {!similarItemDetails.itemDetails?.isVariableWeight && (
                  <tr>
                    <td>
                      <FormattedMessage id="SimilarItems.Details.Price" />
                    </td>
                    <td>{`$${similarItemDetails.itemDetails?.price ? similarItemDetails.itemDetails?.price.toFixed(2) : 0}`}</td>
                    <td>{`$${similarItemDetails.similarItem?.price ? similarItemDetails.similarItem?.price.toFixed(2) : 0}`}</td>
                    <td> </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </Table>
        <div className="content-filter-main border-0 text-end">
          <Button variant="primary" onClick={() => navigate(-1)}>
            <FormattedMessage id="SimilarItems.Details.Back" />
          </Button>
        </div>
      </div>
      {/* Decline reason popup */}
      <Modal show={declinePopUp} onHide={() => setdeclinePopUp(false)} centered>
        <Modal.Body className="pb-0">
          <Col className="min-height-auto">
            <Textarea
              id="Description"
              name="description"
              label={useIntlMessages('SimilarItems.Decline.Input.Label')}
              placeholder={useIntlMessages('SimilarItems.Decline.Input.Placeholder')}
              rows={3}
              value={description}
              maxLength={100}
              errorMessage={errorFields?.description}
              onChange={onInputChange}
            />
          </Col>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              setDescription('');
              setdeclinePopUp(false);
            }}
          >
            <FormattedMessage id="Button.Cancel" />
          </Button>
          <Button variant="primary" onClick={() => handleMappingRequest(SimilarItemProcessStatus.DECLINED)}>
            <FormattedMessage id="Button.Submit" />
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Approve mapped item request confirm popup */}
      <Modal show={approveConfirmPopUp} onHide={() => setApproveConfirmPopUp(false)} size="lg" centered>
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-sm">
            <FormattedMessage id="HD.SimilarItems.Approve.Confirm" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-3">
            <FormattedMessage id="SimilarItems.Approve.Confirm.Text" />
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="" onClick={() => setApproveConfirmPopUp(false)}>
            <FormattedMessage id="Button.Cancel" />
          </Button>
          <Button variant="primary" onClick={() => handleMappingRequest(SimilarItemProcessStatus.APPROVED)}>
            <FormattedMessage id="Button.OK" />
          </Button>
        </Modal.Footer>
      </Modal>
      {(isLoading || updateMappedItemStatusLoading) && <GifLoader />}
    </div>
  );
};

export default SimilarItems;
