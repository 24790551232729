/**
 * @file   src\store\slices\onboardingSlice.ts
 * @brief  This file is responsible for creating onboarding slices to call actions and state management.
 * @date   Aug, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { createSlice } from '@reduxjs/toolkit';
import { getOnboardingList, getOnboardingVendors, getOnboardingManagers, assignManager, updateOnboardingStatus, deleteOnboardingUser } from '../actions/onboardingAction';
import { IOnboardingFormData } from '../../interfaces/onboardingInterface';
import { useIntlActionMessages } from '../../utils/helper';

// Onboarding slice Initial state.
const onboardingState: IOnboardingFormData = {
  onboardingList: [],
  totalCount: 0,
  isSuccess: false,
  isLoading: false,
  errorCode: 0,
  errorMessage: null,
  onboardingVendors: [],
  onboardingVendorLoading: false,
  onboardingVendorSuccess: false,
  onboardingVendorErrorCode: 0,
  onboardingVendorErrorMessage: null,
  onboardingManagers: [],
  onboardingManagersLoading: false,
  onboardingManagersSuccess: false,
  onboardingVManagersErrorCode: 0,
  onboardingManagersErrorMessage: null,
  assignManagerLoading: false,
  assignManagerSuccess: false,
  assignManagerErrorCode: 0,
  assignManagerErrorMessage: null,
  updateOnboardingStatusLoading: false,
  updateOnboardingStatusSuccess: false,
  updateOnboardingStatusErrorCode: 0,
  updateOnboardingStatusErrorMessage: null,
  deleteOnboardingUserLoading: false,
  deleteOnboardingUserSuccess: false,
  deleteOnboardingUserErrorCode: 0,
  deleteOnboardingUserErrorMessage: null,
};

// Config onboarding slice.
const onBoardingSlice = createSlice({
  name: 'onboarding',
  initialState: onboardingState,
  reducers: {
    resetOnboardingState: (state) => {
      state.errorCode = 0;
      state.errorMessage = null;
      state.isSuccess = false;
      state.isLoading = false;
      state.onboardingVendorLoading = false;
      state.onboardingVendorSuccess = false;
      state.onboardingVendorErrorCode = 0;
      state.onboardingVendorErrorMessage = null;
      state.onboardingManagersLoading = false;
      state.onboardingManagersSuccess = false;
      state.onboardingVManagersErrorCode = 0;
      state.onboardingManagersErrorMessage = null;
      state.assignManagerLoading = false;
      state.assignManagerSuccess = false;
      state.assignManagerErrorCode = 0;
      state.assignManagerErrorMessage = null;
      state.updateOnboardingStatusLoading = false;
      state.updateOnboardingStatusSuccess = false;
      state.updateOnboardingStatusErrorCode = 0;
      state.updateOnboardingStatusErrorMessage = null;
      state.deleteOnboardingUserLoading = false;
      state.deleteOnboardingUserSuccess = false;
      state.deleteOnboardingUserErrorCode = 0;
      state.deleteOnboardingUserErrorMessage = null;
    },
  },
  extraReducers(builder) {
    // Start onboarding list request.
    builder.addCase(getOnboardingList.pending, (state) => {
      state.isSuccess = false;
      state.isLoading = true;
      state.errorCode = 0;
      state.errorMessage = '';
    });
    // Onboarding list request success.
    builder.addCase(getOnboardingList.fulfilled, (state, action) => {
      const data = action.payload?.data || null;
      const error = action.payload?.error || 0;
      state.onboardingList = data?.onboardingList || [];
      state.totalCount = data?.totalRows;
      state.isLoading = false;
      state.isSuccess = true;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // Onboarding list error.
    builder.addCase(getOnboardingList.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.isSuccess = false;
      state.isLoading = false;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Start onboarding vendors list request.
    builder.addCase(getOnboardingVendors.pending, (state) => {
      state.onboardingVendorSuccess = false;
      state.onboardingVendorLoading = true;
      state.onboardingVendorErrorCode = 0;
      state.onboardingVendorErrorMessage = '';
    });
    // Onboarding vendors list request success.
    builder.addCase(getOnboardingVendors.fulfilled, (state, action) => {
      const data = action.payload?.data || null;
      const error = action.payload?.error || 0;
      state.onboardingVendors = data?.vendors || [];
      state.onboardingVendorLoading = false;
      state.onboardingVendorSuccess = true;
      state.onboardingVendorErrorCode = error;
      state.onboardingVendorErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // Onboarding vendors list error.
    builder.addCase(getOnboardingVendors.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.onboardingVendorSuccess = false;
      state.onboardingVendorLoading = false;
      state.onboardingVendorErrorCode = error;
      state.onboardingVendorErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Start onboarding vendors list request.
    builder.addCase(getOnboardingManagers.pending, (state) => {
      state.onboardingManagersSuccess = false;
      state.onboardingManagersLoading = true;
      state.onboardingVManagersErrorCode = 0;
      state.onboardingManagersErrorMessage = '';
    });
    // Onboarding vendors list request success.
    builder.addCase(getOnboardingManagers.fulfilled, (state, action) => {
      const data = action.payload?.data || null;
      const error = action.payload?.error || 0;
      state.onboardingManagers = data?.managers || [];
      state.onboardingManagersLoading = false;
      state.onboardingManagersSuccess = true;
      state.onboardingVManagersErrorCode = error;
      state.onboardingManagersErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // Onboarding vendors list error.
    builder.addCase(getOnboardingManagers.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.onboardingManagersSuccess = false;
      state.onboardingManagersLoading = false;
      state.onboardingVManagersErrorCode = error;
      state.onboardingManagersErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Start assign manager request
    builder.addCase(assignManager.pending, (state) => {
      state.assignManagerLoading = true;
      state.assignManagerSuccess = false;
      state.assignManagerErrorCode = 0;
      state.assignManagerErrorMessage = '';
    });
    // Assign manager request success.
    builder.addCase(assignManager.fulfilled, (state, { payload }) => {
      const error = payload?.error || 0;
      state.assignManagerLoading = false;
      state.assignManagerSuccess = true;
      state.assignManagerErrorCode = error;
      state.assignManagerErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : 'Onboarding.Manager.Assigned.Success');
    });
    // Assign Manager error.
    builder.addCase(assignManager.rejected, (state, action: any) => {
      const { error } = action.payload;
      state.assignManagerLoading = false;
      state.assignManagerSuccess = false;
      state.assignManagerErrorCode = error;
      state.assignManagerErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Start onboarding status update request
    builder.addCase(updateOnboardingStatus.pending, (state) => {
      state.updateOnboardingStatusLoading = true;
      state.updateOnboardingStatusSuccess = false;
      state.updateOnboardingStatusErrorCode = 0;
      state.updateOnboardingStatusErrorMessage = '';
    });
    // Onboarding status update request success.
    builder.addCase(updateOnboardingStatus.fulfilled, (state, { payload }) => {
      const error = payload?.error || 0;
      state.updateOnboardingStatusLoading = false;
      state.updateOnboardingStatusSuccess = true;
      state.updateOnboardingStatusErrorCode = error;
      state.updateOnboardingStatusErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : 'Onboarding.Status.Updated.Success');
    });
    // Onboarding status update error.
    builder.addCase(updateOnboardingStatus.rejected, (state, action: any) => {
      const { error } = action.payload;
      state.updateOnboardingStatusLoading = false;
      state.updateOnboardingStatusSuccess = false;
      state.updateOnboardingStatusErrorCode = error;
      state.updateOnboardingStatusErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Start delete onboarding user request
    builder.addCase(deleteOnboardingUser.pending, (state) => {
      state.deleteOnboardingUserLoading = true;
      state.deleteOnboardingUserSuccess = false;
      state.deleteOnboardingUserErrorCode = 0;
      state.deleteOnboardingUserErrorMessage = '';
    });
    // Onboarding user delete request success.
    builder.addCase(deleteOnboardingUser.fulfilled, (state, { payload }) => {
      const error = payload?.error || 0;
      state.deleteOnboardingUserLoading = false;
      state.deleteOnboardingUserSuccess = true;
      state.deleteOnboardingUserErrorCode = error;
      state.deleteOnboardingUserErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : 'Onboarding.User.Delete.Success');
    });
    // Onboarding user delete request error.
    builder.addCase(deleteOnboardingUser.rejected, (state, action: any) => {
      const { error } = action.payload;
      state.deleteOnboardingUserLoading = false;
      state.deleteOnboardingUserSuccess = false;
      state.deleteOnboardingUserErrorCode = error;
      state.deleteOnboardingUserErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
  },
});
// Export onboarding actions.
export const { resetOnboardingState } = onBoardingSlice.actions;
// Export reducer.
export default onBoardingSlice.reducer;
