/**
 * @file   src\components\ImageUpload.tsx
 * @brief Component to upload picture
 * @date   June, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { useDropzone } from 'react-dropzone';
import { useCallback, useEffect, useState, FormattedMessage } from '../utils/thirdpartyComponents';
import DragIcon from '../assets/icons/Drag';
import { UploaderProps } from '../utils/enums';

// props interface for component
interface IFileUploadDropZone {
  setError: any;
  setPicture: any;
  imageFile: any;
  acceptedFileTypes: any;
  maxSize?: number;
  invalidTypeMessage: string;
  invalidSizeMessage: string;
}
// Component to upload image
const DropzoneImageUpload = ({ setError, setPicture, imageFile, maxSize, acceptedFileTypes, invalidTypeMessage, invalidSizeMessage }: IFileUploadDropZone) => {
  const [files, setFiles] = useState([]);
  const [error, setUploadError] = useState('');
  // handle image select
  const onDrop = useCallback((acceptedFiles: any, rejectedFiles: any) => {
    setFiles(
      acceptedFiles.map((item: any) =>
        Object.assign(item, {
          preview: URL.createObjectURL(item),
        }),
      ),
    );
    if (Object.keys(rejectedFiles).length !== 0) {
      rejectedFiles.forEach((file: any) => {
        if (file.errors[0].code === 'file-too-large') {
          setUploadError(invalidSizeMessage);
        } else {
          setUploadError(invalidTypeMessage);
        }
      });
    } else {
      setUploadError('');
    }
  }, []);
  // set props
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
    multiple: false,
    maxSize,
  });
  // show image preview
  const imagepreview = () => {
    if (imageFile.length > 0) {
      return (
        <div key="img">
          <img src={imageFile} alt="profile" />
        </div>
      );
    }
    return files.map((item: any) => (
      <div key={item.name}>
        <img src={item.preview} alt={item.name} />
      </div>
    ));
  };
  // set picture
  useEffect(() => {
    if (files.length === 1) {
      setPicture(files[0]);
    }
  }, [files]);
  // Set error
  useEffect(() => {
    if (error.length > 0) {
      setError(error);
    } else {
      setError('');
    }
  }, [error]);
  return (
    <section className="image-uploader">
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div>
          {files.length === 0 && imageFile.length === 0 && (
            <div className="text-center">
              <div className="drag-icon">
                <DragIcon />
              </div>
              <div className="or"> </div>
              <div className="upload">
                <FormattedMessage id="UploadPicture.Text" />
              </div>
            </div>
          )}
          {imagepreview()}
        </div>
      </div>
    </section>
  );
};

export default DropzoneImageUpload;
DropzoneImageUpload.defaultProps = {
  maxSize: UploaderProps.PROFILE_IMAGE_MAX_SIZE,
};
