/**
 * @file   src\components\MainMenu.tsx
 * @brief  Admin Menu component.
 * @date   May, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import '../styles/MainMenu.scss';
import { NavLink, Nav, NavDropdown, useNavigate, matchPath, useLocation } from '../utils/thirdpartyComponents';
import { getFromLocalStorage } from '../utils/helper';
import { SUPERADMIN_TYPE } from '../utils/constants';
import { MenuItems, RolePermissionUrls } from '../utils/menuHelper';
import { IMenuItems, IPermissionUrls, ISubMenuItems } from '../interfaces/generalInterface';

const MainMenu = () => {
  // Create location object to access location info.
  const location = useLocation();
  // Navigate object creation.
  const navigate = useNavigate();

  // Configure menu items based on permissions.
  const configureMenu = () => {
    const userInfo = getFromLocalStorage('MCSA_UDT');
    const permissions = userInfo?.permissions || [];
    const userType = userInfo?.userType || 0;
    return MenuItems.map((item: IMenuItems, index: number) => {
      if (userType !== SUPERADMIN_TYPE) {
        return (
          permissions.includes(item.permissionId) && (
            <NavLink key={item.permissionId} to={item.path} className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}>
              <item.icon /> {item?.label}
            </NavLink>
          )
        );
      }
      return (
        /* Check whether the menu item index not same as Role Permission */
        index !== 6 ? (
          <NavLink key={item.permissionId} to={item.path} className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}>
            <item.icon /> {item.label}
          </NavLink>
        ) : (
          <NavDropdown
            key={item.permissionId}
            title={
              <div className="profile-dropdown">
                <item.icon />
                {item.label}
              </div>
            }
            id="basic-nav-dropdown"
            className="main-menu-drop "
            active={!!RolePermissionUrls.find((route: IPermissionUrls) => matchPath(route.path, location?.pathname))}
          >
            {item.subMenu &&
              item.subMenu.map((sub: ISubMenuItems) => {
                return (
                  <NavDropdown.Item
                    key={sub.label}
                    href="#"
                    onClick={() => navigate(sub.path)}
                    active={!!RolePermissionUrls.find((route: IPermissionUrls) => sub.permissionId === route.permissionId && matchPath(route.path, location?.pathname))}
                  >
                    {sub.label}
                  </NavDropdown.Item>
                );
              })}
          </NavDropdown>
        )
      );
    });
  };
  return (
    <Nav className="me-auto main-menu">
      <div className="content-nav menu-list d-flex">{configureMenu()}</div>
    </Nav>
  );
};
export default MainMenu;
