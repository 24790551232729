/**
 * @file   src\features\permission\VendorRoles.tsx
 * @brief  Vendor roles listing page
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { BusinessType } from '../../utils/enums';
import Roles from './Roles';

const VendorRoles = () => {
  return <Roles businessType={BusinessType.VENDOR} />;
};
export default VendorRoles;
