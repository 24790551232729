/**
 * @file   src\components\RoleList.tsx
 * @brief  Role list component
 * @date   June, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import '../styles/RoleList.scss';
import { Link } from '../utils/thirdpartyComponents';
import { RoleProperty } from '../interfaces/generalInterface';

const RoleList = ({ image, label, link, path = '', roleId, isEditable }: RoleProperty) => {
  return (
    <div className="rolelist-border d-flex align-items-center justify-content-between mb-4">
      <div className="d-flex align-items-center">
        <div className="role-icon ps-3">
          <img src={image} className="me-3 " alt="Role" />
        </div>
        <div>{label}</div>
      </div>
      {isEditable && (
        <div>
          <Link to={path} state={{ roleId }} className="back-btn">
            {link}
          </Link>
        </div>
      )}
    </div>
  );
};
export default RoleList;
