/**
 * @file   src\utils\constants.ts
 * @brief  This file is responsible define constants.
 * @date   May, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

export const DEFAULT_SUBSCRIPTION_TIER = [{ label: 'Filter by tier', value: 0 }];
export const DEFAULT_RESTAURANT_SUBSCRIPTION = [{ label: 'Subscription', value: 0 }];
export const RESTAURANT_SUBSCRIPTION = [
  { label: 'Subscription', value: 0 },
  { label: 'Free', value: 1 },
];
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PASSWORD_REGEX = /^(?=.*\d)(?=.*[!@#$%^-_&*?])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
export const ZIP_REGEX = /^[0-9]{5}(?:-[0-9]{4})?$/;
export const PHONE_NUMBER_REGEX = /^\d{10}$/;
export const ENTER_KEY = 'Enter';
export const PAGE_SIZE = 50;
export const SEARCH_MIN_CHAR_LENGTH = 3;
export const DEFAULT_SORT_FIELD = 0;
export const DEFAULT_PAGE_INDEX = 1;
export const DEFAULT_DEACTIVATE_STATUS = 0;
export const NOERROR = 0;
export const STATUS_200 = 200;
export const STATUS_401 = 401;
export const STATUS_403 = 403;
export const STATUS_500 = 500;
export const STATUS_5000 = 5000;
export const API_TIMEOUT = 30000;
export const API_MAXCONTENTLENGTH = 1000;
export const API_REFRESH_TOKEN_ERROR = 2005;
export const API_REDIRECTS = 5;
export const DEFAULT_CUTOFFTIME_VALUES = [{ label: 'Select Cutoff Time', value: '' }];

export const STARTING_HOUR = [{ label: 'Starting hour', value: '' }];
export const ENDING_HOUR = [{ label: 'Ending hour', value: '' }];

export const AUTH_NOT_REQUIRED_URLS = [
  '/admin/validateAdminUser',
  '/admin/forgotPassword',
  '/admin/validateResetPasswordToken',
  '/admin/updateOtpForLogin',
  '/admin/refreshToken',
  '/admin/resetPassword',
];
export const PROFILE_IMAGE_TYPES_SUPPORTED = {
  'image/png': ['.png'],
  'image/jpg': ['.jpg', '.jpeg'],
};
export const STATE_MAX_LENGTH = 101;
export const EMAIL_MAX_LENGTH = 201;
export const ZIP_MAX_LENGTH = 6;
export const PASSWORD_MAX_LENGTH = 30;
export const ADMIN_EMAIL = 'email';
export const BUSINESS_EMAIL = 'businessEmail';
export const DEFAULT_VENDOR_PRICE_TIER = [{ label: 'Select Pricing Tier', value: 0 }];
export const SEARCH_PAGESIZE = 100;
export const DEFAULT_MAPPED_RESTAURANT_USER_DATA = [{ label: 'Select User', value: 0 }];
export const DOT_KEY = 190;
export const NUMBER_KEY_START = 48;
export const NUMBER_KEY_END = 57;
export const SUPERADMIN_TYPE = 1;
export const DEFAULT_ROLE_VALUE = { value: 0, label: 'Select a user role', icon: '' };
export const DECIMAL_PLACE_FIELDS_MAX_LENGTH = 9;
export const MAX_LENGTH_WITHOUT_DECIMALS = 6;
export const NO_OF_DECIMAL_PLACES = 2;
export const DECIMAL_REGEX = /^[0-9]*(\.[0-9]{0,2})?$/;
export const DEFAULT_FILTER_STATUS = [{ label: 'Filter by Status', value: 0 }];
export const DECIMAL_REGEX_WITH_MAXLENGTH = /^((?!0)\d{1,5}|0|\.\d{1,2})($|\.$|\.\d{1,2}$)/;
export const DEFAULT_INCENTIVE_PROCESS_STATUS = [{ label: 'All', value: 0 }];
export const DATE_FORMAT = 'MM/DD/YYYY';
export const DATE_FORMAT2 = 'YYYY-MM-DD';
export const MONTHS_SHORT_NAMES = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const INPUT_MAX_LENGTH_200 = 200;
export const INPUT_MAX_LENGTH_100 = 100;
export const INPUT_MAX_LENGTH_10 = 10;
export const INPUT_MAX_LENGTH_15 = 15;
export const INPUT_MAX_LENGTH_20 = 20;
export const WEB_TYPE = 1;
export const DELETE_KEY = 'Delete';
export const DEFAULT_OTP_TIMER = 60;
export const TIME_FORMAT = 'hh:mm:ss A';
export const TAB_RESTAURANT_KEY = 'Restaurant';
export const TAB_VENDOR_KEY = 'Vendor';
export const DATE_TIME_FORMAT = 'MM/DD/YYYY hh:mm:ss A';
export const GraphColors = {
  week: '#489CFF',
  month: '#4296C1',
  year: '#11399F',
};
export const WEEK_DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const DEFAULT_ONBOARDING_MANAGER_OPTION = [{ label: 'Filter by onboarding manager', value: 0 }];
export const DEFAULT_ONBOARDING_MANAGER_SELECTION_OPTION = [{ label: 'Select a manager', value: 0 }];
export const RECAPTCHA_URLS = ['/admin/validateAdminUser', '/admin/resetPassword', '/admin/updateOtpForLogin', '/admin/forgotPassword'];
export const TIME_STARTS_FORMAT = '00:00:00';
export const TIME_END_FORMAT = '23:59:59';
export const TEXT_WEEK = 'week';
export const TEXT_MONTH = 'month';
export const TEXT_YEAR = 'year';
export const TEXT_START = 'start';
export const TEXT_END = 'end';
export const TEXT_WEEKLY = 'Weekly';
export const TEXT_MONTHLY = 'Monthly';
export const TEXT_YEARLY = 'Yearly';
export const TEXT_CUSTOM = 'custom';
export const TEXT_TODAY = 'today';
