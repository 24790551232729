import React from 'react';
import NumericInput from 'react-numeric-input';
import { InputNumericProperty } from '../interfaces/generalInterface';

interface IInputNumericProps extends InputNumericProperty {
  onChange: (str: number | null) => void;
}

const InputNumeric = ({ id, name, min, max, step, precision, value, title, currency, style, onChange, errorMessage }: IInputNumericProps) => {
  const onInputChange = (val: number | null) => {
    if (onChange) onChange(val);
  };
  return (
    <div className="cust-numeric-group">
      <label htmlFor="dvnumeric" className="d-block title">
        {title}
      </label>
      <div className="control-wrap position-relative" id="dvnumeric">
        <NumericInput
          className={errorMessage && errorMessage !== '' ? 'error' : value !== undefined && min !== undefined && value >= min ? 'active' : ''}
          id={id}
          name={name}
          min={min}
          max={max}
          step={step}
          precision={precision}
          value={value}
          style={style}
          mobile
          strict
          onChange={onInputChange}
        />
        <div className="dollar d-flex justify-content-center align-items-center">{currency}</div>
        {errorMessage && errorMessage !== '' && <span className="error">{errorMessage}</span>}
      </div>
    </div>
  );
};

export default InputNumeric;
