import React from 'react';

const NextIcon = () => {
  return (
    <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.369216 14.6306C0.485308 14.7477 0.623425 14.8406 0.775602 14.904C0.927779 14.9674 1.091 15 1.25586 15C1.42071 15 1.58394 14.9674 1.73612 14.904C1.88829 14.8406 2.02641 14.7477 2.1425 14.6306L8.38647 8.38664C8.50352 8.27055 8.59642 8.13243 8.65982 7.98026C8.72322 7.82808 8.75586 7.66486 8.75586 7.5C8.75586 7.33514 8.72322 7.17192 8.65982 7.01974C8.59642 6.86757 8.50352 6.72945 8.38647 6.61336L2.1425 0.369391C2.02641 0.252344 1.88829 0.159439 1.73612 0.0960398C1.58394 0.0326395 1.42071 0 1.25586 0C1.091 0 0.927779 0.0326395 0.775602 0.0960398C0.623425 0.159439 0.485308 0.252344 0.369216 0.369391C0.252169 0.485483 0.159266 0.6236 0.0958662 0.775777C0.0324669 0.927955 -0.000174522 1.09118 -0.000174522 1.25603C-0.000174522 1.42089 0.0324669 1.58411 0.0958662 1.73629C0.159266 1.88847 0.252169 2.02658 0.369216 2.14268L5.73903 7.5L0.369216 12.8573C0.252169 12.9734 0.159266 13.1115 0.0958662 13.2637C0.0324669 13.4159 -0.000174522 13.5791 -0.000174522 13.744C-0.000174522 13.9088 0.0324669 14.072 0.0958662 14.2242C0.159266 14.3764 0.252169 14.5145 0.369216 14.6306Z"
        fill="#489CFF"
      />
    </svg>
  );
};

export default NextIcon;
