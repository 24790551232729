/**
 * @file   src\validations\userSchema.ts
 * @brief  This file is responsible for defining user validation schemas.
 * @date   May, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import * as yup from 'yup';
import { useIntlActionMessages } from '../utils/helper';
import { EMAIL_REGEX, PASSWORD_REGEX, PHONE_NUMBER_REGEX } from '../utils/constants';

// Forgot password form validation schema.
export const FORGOTPASSWORD_SCHEMA = yup.object({
  email: yup
    .string()
    .trim()
    .matches(EMAIL_REGEX, useIntlActionMessages('Form.Email.Valid'))
    .max(200, useIntlActionMessages('Form.Email.MaxLength'))
    .required(useIntlActionMessages('Form.Email.Required')),
});
// Reset password form validation schema.
export const RESET_PASSWORD_SCHEMA = yup.object({
  newPassword: yup.string().trim().required(useIntlActionMessages('Form.Password.Required')).matches(PASSWORD_REGEX, useIntlActionMessages('Form.Password.Criteria')),
  confirmPassword: yup
    .string()
    .required(useIntlActionMessages('Form.Password.Required'))
    .oneOf([yup.ref('newPassword')], useIntlActionMessages('Form.Password.NotMatch')),
});

// Update profile password form validation schema
export const UPDATE_PASSWORD_SCHEMA = yup.object({
  oldPassword: yup.string().trim().required(useIntlActionMessages('Form.Password.Required')),
  newPassword: yup.string().trim().required(useIntlActionMessages('Form.Password.Required')).matches(PASSWORD_REGEX, useIntlActionMessages('Form.Password.Criteria')),
  newPasswordConfirm: yup
    .string()
    .required(useIntlActionMessages('Form.Password.Required'))
    .oneOf([yup.ref('newPassword')], useIntlActionMessages('Form.Password.NotMatch')),
});

// Admin profile update schema
export const ADMIN_PROFILE_SCHEMA = yup.object({
  firstName: yup
    .string()
    .trim()
    .required(useIntlActionMessages('Restaurant.Form.AdminFirstName.Required'))
    .min(3, useIntlActionMessages('UserForm.FirstName.Min'))
    .max(20, useIntlActionMessages('UserForm.FirstName.Max')),
  lastName: yup
    .string()
    .trim()
    .required(useIntlActionMessages('Restaurant.Form.AdminLastName.Required'))
    .min(2, useIntlActionMessages('UserForm.LastName.Min'))
    .max(20, useIntlActionMessages('UserForm.LastName.Max')),
  phone: yup
    .string()
    .required(useIntlActionMessages('Restaurant.Form.PhoneNumber.Required'))
    .matches(PHONE_NUMBER_REGEX, useIntlActionMessages('Restaurant.Form.PhoneNumber.Invalid')),
});

// User schema
export const USER_SCHEMA = yup.object({
  firstName: yup
    .string()
    .trim()
    .required(useIntlActionMessages('Restaurant.Form.AdminFirstName.Required'))
    .min(3, useIntlActionMessages('UserForm.FirstName.Min'))
    .max(20, useIntlActionMessages('UserForm.FirstName.Max')),
  lastName: yup
    .string()
    .trim()
    .required(useIntlActionMessages('Restaurant.Form.AdminLastName.Required'))
    .min(2, useIntlActionMessages('UserForm.LastName.Min'))
    .max(20, useIntlActionMessages('UserForm.LastName.Max')),
  phone: yup
    .string()
    .required(useIntlActionMessages('Restaurant.Form.PhoneNumber.Required'))
    .matches(PHONE_NUMBER_REGEX, useIntlActionMessages('Restaurant.Form.PhoneNumber.Invalid')),
  email: yup
    .string()
    .trim()
    .matches(EMAIL_REGEX, useIntlActionMessages('Form.Email.Valid'))
    .max(200, useIntlActionMessages('Form.Email.MaxLength'))
    .required(useIntlActionMessages('Form.Email.Required')),
});
// User role schema
export const USER_ROLE_SCHEMA = yup.object({
  roleName: yup.string().trim().required(useIntlActionMessages('Role.Form.RoleName.Required')),
  iconText: yup.string().trim().required(useIntlActionMessages('Role.Form.Icon.Required')),
  selectedPermissions: yup.array().required(useIntlActionMessages('Role.Form.Permissions.Required')).min(1, useIntlActionMessages('Role.Form.Permissions.Required')),
  selectedNotifications: yup.array().required(useIntlActionMessages('Role.Form.Notifications.Required')).min(1, useIntlActionMessages('Role.Form.Notifications.Required')),
  deleteText: yup.string().trim().required(useIntlActionMessages('Form.Text.Required')),
});
