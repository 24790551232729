/**
 * @file   src\store\actions\authAction.ts
 * @brief  This file is responsible for creating asynchronous api call for auth slice.
 * @date   May, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../config/axiosConfig';
import { IAuthRequest, IResetValidationRequest } from '../../interfaces/authInterface';
import { AuthApi } from '../../utils/apiUrls';

// User authentication api call.
export const validateUser = createAsyncThunk('/auth/validateUser', async (loginRequest: IAuthRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(AuthApi.LOGIN, loginRequest);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Api call for forgot password validation by email and sent mail with reset password link.
export const validateByEmail = createAsyncThunk('/auth/validateByEmail', async (validateRequest: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(AuthApi.FORGOT_PASSWORD, { businessEmail: validateRequest?.email });
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Api call for validating token for reset password.
export const validateResetPasswordToken = createAsyncThunk('/auth/validateResetPasswordToken', async (validateRequest: IResetValidationRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(AuthApi.RESET_PASSWORD_TOKEN_VALIDATION, validateRequest);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Api call for senting otp to the specified email address.
export const sendLoginOtp = createAsyncThunk('/auth/sendLoginOtp', async (request: IAuthRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(AuthApi.SEND_LOGIN_OTP, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});
