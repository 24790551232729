/**
 * @file   src\store\slices\rolePermissionSlice.ts
 * @brief  This file is responsible for creating role permission based slices to call actions and state management.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { createSlice } from '@reduxjs/toolkit';
import { getBusinessRoles, getRoleBasedPermissions, savePermissions, deleteRolePermissions } from '../actions/rolepermissionAction';
import { IRolePermState } from '../../interfaces/rolePermissionInterface';
import { useIntlActionMessages } from '../../utils/helper';

// Initial state.
const rolePermissionState: IRolePermState = {
  roles: [],
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
  rolePermissions: [],
  roleDetails: null,
  notifications: [],
  rolePermissionsLoading: false,
  rolePermissionsSuccess: false,
  rolePermissionsErrorCode: 0,
  rolePermissionsErrorMessage: null,
  roleId: 0,
  saveRolePermissionsLoading: false,
  saveRolePermissionsSuccess: false,
  saveRolePermissionsErrorCode: 0,
  saveRolePermissionsErrorMessage: null,
  deleteRolePermissionsLoading: false,
  deleteRolePermissionsSuccess: false,
  deleteRolePermissionsErrorCode: 0,
  deleteRolePermissionsErrorMessage: null,
};

// Config role slice.
const rolePermissionSlice = createSlice({
  name: 'roleperm',
  initialState: rolePermissionState,
  reducers: {
    resetRole: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorCode = 0;
      state.errorMessage = null;
      state.rolePermissions = [];
      state.notifications = [];
      state.roleDetails = null;
      state.rolePermissionsLoading = false;
      state.rolePermissionsSuccess = false;
      state.rolePermissionsErrorCode = 0;
      state.rolePermissionsErrorMessage = null;
      state.roleId = 0;
      state.saveRolePermissionsLoading = false;
      state.saveRolePermissionsSuccess = false;
      state.saveRolePermissionsErrorCode = 0;
      state.saveRolePermissionsErrorMessage = null;
      state.deleteRolePermissionsLoading = false;
      state.deleteRolePermissionsSuccess = false;
      state.deleteRolePermissionsErrorCode = 0;
      state.deleteRolePermissionsErrorMessage = null;
    },
  },
  extraReducers(builder) {
    // Start get business roles request.
    builder.addCase(getBusinessRoles.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.errorCode = 0;
      state.errorMessage = '';
    });
    // Get business roles reqest success.
    builder.addCase(getBusinessRoles.fulfilled, (state, action) => {
      const error = action.payload?.error || 0;
      const data = action.payload?.data || [];
      state.isLoading = false;
      state.isSuccess = true;
      state.roles = data?.roles || [];
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // Get business roles error.
    builder.addCase(getBusinessRoles.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.isLoading = false;
      state.isSuccess = false;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Start get all role permissions request.
    builder.addCase(getRoleBasedPermissions.pending, (state) => {
      state.rolePermissionsLoading = true;
      state.rolePermissionsSuccess = false;
      state.rolePermissionsErrorCode = 0;
      state.rolePermissionsErrorMessage = '';
    });
    // Get all role permissions reqest success.
    builder.addCase(getRoleBasedPermissions.fulfilled, (state, action) => {
      const error = action.payload?.error || 0;
      const data = action.payload?.data || [];
      state.rolePermissionsLoading = false;
      state.rolePermissionsSuccess = true;
      state.rolePermissions = data?.permissions || [];
      state.roleDetails = data?.role || null;
      state.notifications = data?.notifications || [];
      state.rolePermissionsErrorCode = error;
      state.rolePermissionsErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // Get all role permissions error.
    builder.addCase(getRoleBasedPermissions.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.rolePermissionsLoading = false;
      state.rolePermissionsSuccess = false;
      state.rolePermissionsErrorCode = error;
      state.rolePermissionsErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Start save role permissions request.
    builder.addCase(savePermissions.pending, (state) => {
      state.saveRolePermissionsLoading = true;
      state.saveRolePermissionsSuccess = false;
      state.saveRolePermissionsErrorCode = 0;
      state.saveRolePermissionsErrorMessage = '';
    });
    // Save role permissions reqest success.
    builder.addCase(savePermissions.fulfilled, (state, action) => {
      const error = action.payload?.error || 0;
      state.roleId = action.payload?.data?.roleId || 0;
      state.saveRolePermissionsLoading = false;
      state.saveRolePermissionsSuccess = true;
      state.saveRolePermissionsErrorCode = error;
      state.saveRolePermissionsErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : 'RolePermission.Save.Success');
    });
    // Save role permissions error.
    builder.addCase(savePermissions.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.saveRolePermissionsLoading = false;
      state.saveRolePermissionsSuccess = false;
      state.saveRolePermissionsErrorCode = error;
      state.saveRolePermissionsErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // delete role permissions api starts
    builder.addCase(deleteRolePermissions.pending, (state) => {
      state.deleteRolePermissionsLoading = true;
      state.deleteRolePermissionsErrorCode = 0;
      state.deleteRolePermissionsSuccess = false;
      state.deleteRolePermissionsErrorMessage = '';
    });
    // delete role permissions api success
    builder.addCase(deleteRolePermissions.fulfilled, (state, action) => {
      const error = action.payload?.error || 0;
      state.deleteRolePermissionsLoading = false;
      state.deleteRolePermissionsSuccess = true;
      state.deleteRolePermissionsErrorCode = error;
      state.deleteRolePermissionsErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : 'RolePermission.Delete.Success');
    });
    // delete role permissions api failure
    builder.addCase(deleteRolePermissions.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.deleteRolePermissionsLoading = false;
      state.deleteRolePermissionsSuccess = false;
      state.deleteRolePermissionsErrorCode = error;
      state.deleteRolePermissionsErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
  },
});
// Export role permission actions.
export const { resetRole } = rolePermissionSlice.actions;
// Export reducer.
export default rolePermissionSlice.reducer;
