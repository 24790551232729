/**
 * @file   src\store\actions\productAction.ts
 * @brief  This file is responsible for creating asynchronous product api call.
 * @date   June, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../config/axiosConfig';
import {
  IMappedItemStatusUpdateRequest,
  IMappedItemsByUserRequest,
  IMappedOriginalOrSimilarItemRequest,
  IProductListRequest,
  IProductMappedListRequest,
} from '../../interfaces/productInterface';
import { ProductApi } from '../../utils/apiUrls';

// List all products api call.
export const getProductList = createAsyncThunk('/product/getProductList', async (request: IProductListRequest, { rejectWithValue }) => {
  try {
    let param = '';
    param += request.searchText ? `searchText=${encodeURIComponent(request.searchText.replace(/\\/g, '\\\\').replace(/_/g, '\\_').replace(/%/g, '\\%'))}&` : '';
    param += `itemId=${request.itemId}&`;
    param += `vendorIds=[${request.vendorIds}]&`;
    param += `countryIds=[${request.countryIds}]&`;
    param += `productGroupIds=[${request.productGroupIds}]&`;
    param += `pageSize=${request.pageSize}&`;
    param += `pageIndex=${request.pageIndex}&`;
    param += `sortField=${request.sortField}&`;
    param += `sortOrder=${request.sortOrder}`;
    const { data } = await axios.get(`${ProductApi.PRODUCT_LIST}?${param}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Get product groups api call.
export const getProductGroups = createAsyncThunk('/product/getProductGroups', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(ProductApi.PRODUCT_GROUPS);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Search products api call.
export const searchProducts = createAsyncThunk('/product/searchProducts', async (request: IProductListRequest, { rejectWithValue }) => {
  try {
    let param = '';
    param += request.searchText ? `searchText=${request.searchText}&` : '';
    param += `itemId=${request.itemId}&`;
    param += `vendorIds=[${request.vendorIds}]&`;
    param += `countryIds=[${request.countryIds}]&`;
    param += `productGroupIds=[${request.productGroupIds}]&`;
    param += `pageSize=${request.pageSize}&`;
    param += `pageIndex=${request.pageIndex}&`;
    param += `sortField=${request.sortField}&`;
    param += `sortOrder=${request.sortOrder}`;
    const { data } = await axios.get(`${ProductApi.PRODUCT_LIST}?${param}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Get all product origin list api call.
export const getProductOrigins = createAsyncThunk('/product/getProductOrigins', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(ProductApi.PRODUCT_ORIGINS);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Get product details api call.
export const getProductDetails = createAsyncThunk('/product/getProductDetails', async (itemId: number, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${ProductApi.PRODUCT_DETAILS}/${itemId}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

// List mapped products api call.
export const getMappedItems = createAsyncThunk('/product/getMappedItems', async (request: IProductMappedListRequest, { rejectWithValue }) => {
  try {
    let param = '';
    param += request.searchText ? `searchText=${encodeURIComponent(request.searchText.replace(/\\/g, '\\\\').replace(/_/g, '\\_').replace(/%/g, '\\%'))}&` : '';
    param += `itemId=${request.itemId}&`;
    param += `userIds=[${request.userIds}]&`;
    param += `rank=[${request.rank}]&`;
    param += `pageSize=${request.pageSize}&`;
    param += `pageIndex=${request.pageIndex}&`;
    param += `sortField=${request.sortField}&`;
    param += `sortOrder=${request.sortOrder}`;
    const { data } = await axios.get(`${ProductApi.PRODUCT_MAPPED_ITEMS}?${param}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Fetch original items api call.
export const getOriginalItems = createAsyncThunk('/product/getOriginalItems', async (request: IProductMappedListRequest, { rejectWithValue }) => {
  try {
    let param = '';
    param += request.searchText ? `searchText=${request.searchText}&` : '';
    param += `itemId=${request.itemId}&`;
    param += `userIds=[${request.userIds}]&`;
    param += `rank=[${request.rank}]&`;
    param += `pageSize=${request.pageSize}&`;
    param += `pageIndex=${request.pageIndex}&`;
    param += `sortField=${request.sortField}&`;
    param += `sortOrder=${request.sortOrder}`;
    const { data } = await axios.get(`${ProductApi.PRODUCT_MAPPED_ITEMS}?${param}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Get mapped items details api call.
export const getMappedItemDetails = createAsyncThunk('/product/getMappedItemDetails', async (mappingId: number, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${ProductApi.PRODUCT_MAPPED_DETAILS}/${mappingId}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

// List mapped items by user api call.
export const getMappedItemsByUser = createAsyncThunk('/product/getMappedItemsByUser', async (request: IMappedItemsByUserRequest, { rejectWithValue }) => {
  try {
    let param = '';
    param += request.searchOriginal ? `searchOriginal=${request.searchOriginal}&` : '';
    param += request.searchSimilar ? `searchSimilar=${request.searchSimilar}&` : '';
    param += `mappedUserId=${request.mappedUserId}&`;
    param += `originalItemId=${request.originalItemId}&`;
    param += `similarItemId=${request.similarItemId}&`;
    param += `approveStatus=${request.approveStatus}&`;
    param += `pageSize=${request.pageSize}&`;
    param += `pageIndex=${request.pageIndex}&`;
    param += `sortField=${request.sortField}&`;
    param += `sortOrder=${request.sortOrder}`;
    const { data } = await axios.get(`${ProductApi.USER_MAPPED_ITEMS_LIST}?${param}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Get mapped original items api call.
export const getOriginalItemsByUser = createAsyncThunk('/product/getOriginalItemsByUser', async (request: IMappedOriginalOrSimilarItemRequest, { rejectWithValue }) => {
  try {
    let param = '';
    param += request.searchText ? `searchText=${request.searchText}&` : '';
    param += `userId=${request.userId}&`;
    param += `pageOffset=${request.pageOffset}`;
    const { data } = await axios.get(`${ProductApi.USER_MAPPED_ORIGINAL_ITEMS}?${param}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Get mapped similar items api call.
export const getSimilarItemsByUser = createAsyncThunk('/product/getSimilarItemsByUser', async (request: IMappedOriginalOrSimilarItemRequest, { rejectWithValue }) => {
  try {
    let param = '';
    param += request.searchText ? `searchText=${encodeURIComponent(request.searchText.replace(/\\/g, '\\\\').replace(/_/g, '\\_').replace(/%/g, '\\%'))}&` : '';
    param += `userId=${request.userId}&`;
    param += `pageOffset=${request.pageOffset}`;
    const { data } = await axios.get(`${ProductApi.USER_MAPPED_SIMILAR_ITEMS}?${param}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Update user mapped item status(Approve/Reject) api.
export const updateMappedItemStatus = createAsyncThunk('/product/updateMappedItemStatus', async (request: IMappedItemStatusUpdateRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(ProductApi.APPROVE_DECLINE_MAP_ITEMS, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});
