import React from 'react';

const DeleteIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17.407" height="20.085" viewBox="0 0 17.407 20.085">
      <g id="Layer_17" data-name="Layer 17" transform="translate(-3 -1)">
        <path
          id="Path_1"
          data-name="Path 1"
          d="M17.721,23.4H7.009A2.009,2.009,0,0,1,5,21.39V8.67a.67.67,0,0,1,1.339,0V21.39a.67.67,0,0,0,.67.67H17.721a.67.67,0,0,0,.67-.67V8.67a.67.67,0,0,1,1.339,0V21.39A2.009,2.009,0,0,1,17.721,23.4Z"
          transform="translate(-0.661 -2.313)"
          fill="#63707e"
        />
        <path id="Path_2" data-name="Path 2" d="M19.738,6.339H3.67A.67.67,0,1,1,3.67,5H19.738a.67.67,0,1,1,0,1.339Z" transform="translate(0 -1.322)" fill="#63707e" />
        <path
          id="Path_3"
          data-name="Path 3"
          d="M17.026,5.017a.669.669,0,0,1-.67-.67V2.339H12.339V4.348a.67.67,0,0,1-1.339,0V1.67A.67.67,0,0,1,11.67,1h5.356a.67.67,0,0,1,.67.67V4.348A.67.67,0,0,1,17.026,5.017Z"
          transform="translate(-2.644)"
          fill="#63707e"
        />
        <path
          id="Path_4"
          data-name="Path 4"
          d="M15.67,20.712a.67.67,0,0,1-.67-.669V10.67a.67.67,0,0,1,1.339,0v9.373A.67.67,0,0,1,15.67,20.712Z"
          transform="translate(-3.966 -2.974)"
          fill="#63707e"
        />
        <path
          id="Path_5"
          data-name="Path 5"
          d="M20.67,20.034a.67.67,0,0,1-.67-.67v-6.7a.67.67,0,1,1,1.339,0v6.7A.67.67,0,0,1,20.67,20.034Z"
          transform="translate(-5.618 -3.635)"
          fill="#63707e"
        />
        <path
          id="Path_6"
          data-name="Path 6"
          d="M10.67,20.034a.67.67,0,0,1-.67-.67v-6.7a.67.67,0,1,1,1.339,0v6.7A.67.67,0,0,1,10.67,20.034Z"
          transform="translate(-2.313 -3.635)"
          fill="#63707e"
        />
      </g>
    </svg>
  );
};

export default DeleteIcon;
