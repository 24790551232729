/**
 * @file   src\store\actions\dashboardActions.ts
 * @brief  This file is responsible for creating asynchronous dashboard based api call.
 * @date   August, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { DashboardApi } from '../../utils/apiUrls';
import axios from '../../config/axiosConfig';
import {
  OrderRequestParams,
  TransactionRequestParams,
  IOrderGraphRequest,
  IDashboardRestaurantRequest,
  IDashboardVendorRequest,
  IVendorRestaurantSearchRequest,
} from '../../interfaces/dashboardInterface';
import { buildQueryString } from '../../utils/helper';

// Api call to get list of orders
export const getOrdersList = createAsyncThunk('/dashboard/orderslist', async (orderListRequest: OrderRequestParams, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${DashboardApi.ORDERS_LIST}?${buildQueryString(orderListRequest)}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Api call to get list of transactions
export const getTransactionsList = createAsyncThunk('/dashboard/transactionslist', async (transactionListRequest: TransactionRequestParams, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${DashboardApi.TRANSACTIONS_LIST}?${buildQueryString(transactionListRequest)}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Api call to get list of vendors
export const getNewVendorsList = createAsyncThunk('/dashboard/vendorslist', async (request: IDashboardVendorRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${DashboardApi.VENDORS_LIST}?${buildQueryString(request)}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Api call to get list of restaurants
export const getNewRestaurantsList = createAsyncThunk('/dashboard/restaurantslist', async (request: IDashboardRestaurantRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${DashboardApi.RESTAURANT_LIST}?${buildQueryString(request)}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Api call to get data for transactions graph
export const getTransactionsGraphData = createAsyncThunk('/dashboard/transactiongraphdata', async (request: IOrderGraphRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(DashboardApi.INVOICE_GRAPH, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Api call to get data for transactions graph
export const getOrdersGraphData = createAsyncThunk('/dashboard/ordersgraphdata', async (request: IOrderGraphRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(DashboardApi.ORDER_GRAPH, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Api call to get data for transactions graph
export const getRestaurantVendorGraphData = createAsyncThunk('/dashboard/restaurantvendorsgraphdata', async (request: IOrderGraphRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(DashboardApi.RESTAURANT_VENDOR_GRAPH, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Api call to get order details
export const getOrderDetails = createAsyncThunk('/dashboard/orderdetails', async (orderDetailsRequest: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${DashboardApi.ORDER_DETAILS}?${buildQueryString(orderDetailsRequest)}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Api call to get invoice details
export const getInvoiceDetails = createAsyncThunk('/dashboard/invoicedetails', async (invoiceGuid: string, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${DashboardApi.INVOICE_DETAILS}${invoiceGuid}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Api call to generate invoice pdf
export const generateInvoicePdf = createAsyncThunk('/dashboard/invoicepdf', async (params: string, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${DashboardApi.INVOICE_PDF}${params}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Api call to generate order pdf
export const generateOrderPdf = createAsyncThunk('/dashboard/orderpdf', async (params: string, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${DashboardApi.ORDER_PDF}${params}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Api call to search for restaurants
export const searchRestaurants = createAsyncThunk('/dashboard/searchRestaurants', async (request: IVendorRestaurantSearchRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${DashboardApi.SEARCH_RESTAURANTS}?${buildQueryString(request)}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Api call to search for vendors
export const searchVendors = createAsyncThunk('/dashboard/searchVendors', async (request: IVendorRestaurantSearchRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${DashboardApi.SEARCH_VENDORS}?${buildQueryString(request)}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Api call to get chart's date limit.
export const getChartDateLimit = createAsyncThunk('/dashboard/getChartDateLimit', async (request: string, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${DashboardApi.DASHBOARD_CHART_DATE_LIMIT}?timeZone=${request}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});
