/**
 * @file   src\assets\icons\Password.tsx
 * @brief  Password icon component.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

const PasswordIcon = () => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.917 13A6.002 6.002 0 0 1 1 12a6 6 0 0 1 11.917-1H23v2h-2v4h-2v-4h-2v4h-2v-4h-2.083ZM7 16a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z" />
    </svg>
  );
};

export default PasswordIcon;
