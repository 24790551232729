/**
 * @file   src\features\product\UserMappedItems.tsx
 * @brief  This page is responsible for listing similar items mapped by specific user.
 * @date   Aug, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import {
  Row,
  Col,
  Table,
  Typeahead,
  FormattedMessage,
  Badge,
  Link,
  useRef,
  createRef,
  useState,
  useParams,
  useEffect,
  useLocation,
  useNavigate,
} from '../../utils/thirdpartyComponents';
import Search from '../../assets/icons/Search';
import Select from '../../components/Select';
import Pagination from '../../components/Paginate';
import SortIcon from '../../assets/icons/Sort';
import BackIcon from '../../assets/icons/Back';
import {
  DEFAULT_INCENTIVE_PROCESS_STATUS,
  DEFAULT_PAGE_INDEX,
  DEFAULT_SORT_FIELD,
  ENTER_KEY,
  INPUT_MAX_LENGTH_200,
  PAGE_SIZE,
  SEARCH_MIN_CHAR_LENGTH,
  SEARCH_PAGESIZE,
} from '../../utils/constants';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { RootState } from '../../store';
import { SimilarItemProcessStatus, SortOrderType, UserMappedItemsSortField } from '../../utils/enums';
import UpArrow from '../../assets/icons/UpArrow';
import DownArrow from '../../assets/icons/DownArrow';
import UpDownArrow from '../../assets/icons/UpDownArrow';
import { IMappedItemsByUserRequest, IMappedOriginalOrSimilarItemRequest, IUserMappedItemsList } from '../../interfaces/productInterface';
import { stringFormat, useIntlActionMessages, useIntlMessages } from '../../utils/helper';
import GifLoader from '../../components/GifLoader';
import { ISelectOptions } from '../../interfaces/generalInterface';
import { getMappedItemsByUser, getOriginalItemsByUser, getSimilarItemsByUser } from '../../store/actions/productAction';

// Declare page item count to be displayed per page.
const pageItemCount: number = process.env.REACT_APP_LIST_PAGE_SIZE !== '' ? Number(process.env.REACT_APP_LIST_PAGE_SIZE) : PAGE_SIZE;
const UserMappedItems = () => {
  // Create current location object.
  const location = useLocation();

  // Navigate object creation.
  const navigate = useNavigate();

  // Route information variable
  const params = useParams();

  // Initialize search field ref for original items search field and restaurant search field.
  const previousOriginalItemSearchText = useRef('');
  const previousSimilarItemSearchText = useRef('');

  // Create a ref on search input for original items search field and restaurant search input.
  const originalItemSearchRef = createRef<any>();
  const similarItemSearchRef = createRef<any>();

  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Access redux state variables.
  const { userMappedItemsLoading, userMappedItems, userMappedItemsTotalCount, originalItemsByUser, similarItemsByUser } = useAppSelector((state: RootState) => state.product);

  // Initialize component state variables.
  const [originalItemsSearchField, setOriginalItemsSearchField] = useState<string>('');
  const [originalItemsSearchText, setOriginalItemsSearchText] = useState<string>('');
  const [originalItemsTypeField, setOriginalItemsTypeField] = useState<string>('');
  const [similarItemsSearchField, setSimilarItemsSearchField] = useState<string>('');
  const [similarItemsSearchText, setSimilarItemsSearchText] = useState<string>('');
  const [similarItemsTypeField, setSimilarItemsTypeField] = useState<string>('');
  const [originalItemId, setOriginalItemId] = useState<number>(0);
  const [similarItemId, setSimilarItemId] = useState<number>(0);
  const [approveStatus, setApprovedStatus] = useState<ISelectOptions[]>(DEFAULT_INCENTIVE_PROCESS_STATUS);
  const [pageSize] = useState<number>(pageItemCount);
  const [pageIndex, setPageIndex] = useState<number>(DEFAULT_PAGE_INDEX);
  const [sortField, setSortField] = useState<number>(DEFAULT_SORT_FIELD);
  const [sortOrder, setSortOrder] = useState<number>(SortOrderType.ASC);

  /* Access and set the location url parameters. */
  const mappedByUser = location.state?.userName || '';

  // Fetch user mapped items action call.
  const fetchMappedItemsByUser = () => {
    const mappedItemsFetchRequest: IMappedItemsByUserRequest = {
      mappedUserId: Number(params.mappedUserId),
      searchOriginal: originalItemId !== 0 ? '' : originalItemsSearchText,
      searchSimilar: similarItemId !== 0 ? '' : similarItemsSearchText,
      originalItemId,
      similarItemId,
      approveStatus: approveStatus[0]?.value || 0,
      pageSize,
      pageIndex,
      sortField,
      sortOrder,
    };
    dispatch(getMappedItemsByUser(mappedItemsFetchRequest));
  };

  // Product mapped items list api call on pageIndex, showDeactivateOnly, subscriptionPlanId, sortField, sortOrder state change.
  useEffect(() => {
    fetchMappedItemsByUser();
  }, [pageIndex, originalItemsSearchText, similarItemsSearchText, sortField, sortOrder, approveStatus]);

  // Fetch original items api call on searchField state change.
  useEffect(() => {
    if (originalItemsSearchField.length >= SEARCH_MIN_CHAR_LENGTH && originalItemId === 0) {
      const originalItemSearchRequest: IMappedOriginalOrSimilarItemRequest = {
        userId: Number(params.mappedUserId),
        searchText: originalItemsSearchField,
        pageOffset: SEARCH_PAGESIZE,
      };
      dispatch(getOriginalItemsByUser(originalItemSearchRequest));
    }
  }, [originalItemsSearchField]);

  // Fetch similar items api call on searchField state change.
  useEffect(() => {
    if (similarItemsSearchField.length > 2 && originalItemId === 0) {
      const similarItemSearchRequest: IMappedOriginalOrSimilarItemRequest = {
        userId: Number(params.mappedUserId),
        searchText: similarItemsSearchField,
        pageOffset: SEARCH_PAGESIZE,
      };
      dispatch(getSimilarItemsByUser(similarItemSearchRequest));
    }
  }, [similarItemsSearchField]);

  // Mapped items search input keydown event.
  const onOriginalItemsSearchHandleKeyDown = (event: any) => {
    const { value } = event.target;
    if (event.key === ENTER_KEY) {
      if (originalItemSearchRef.current) {
        originalItemSearchRef.current.blur();
      }
      setOriginalItemsSearchText(value?.trim());
      setOriginalItemsSearchField(originalItemsSearchField.trim());
    }
  };

  // Mapped items search textbox selected change event.
  const onOriginalItemsSearchHandleChange = (value: any) => {
    if (value[0]) {
      previousOriginalItemSearchText.current = value[0].itemName;
      setOriginalItemId(value[0].itemId);
      setOriginalItemsSearchText(value[0].itemName);
      setPageIndex(1);
      setOriginalItemsSearchField(value[0].itemName);
      if (originalItemSearchRef.current) {
        originalItemSearchRef.current.blur();
      }
    }
  };

  // Mapped items search textbox search input change event.
  const onOriginalItemsSearchInputHandleChange = (value: any) => {
    if (value.trim().length >= SEARCH_MIN_CHAR_LENGTH) {
      /* Search call will be fired when character length greater than 2 */
      setOriginalItemsSearchField(value);
    } else if (value.trim().length === 0) {
      setOriginalItemsSearchField('');
      setOriginalItemsSearchText('');
    }
    previousOriginalItemSearchText.current = value;
    setOriginalItemsTypeField(value);
    setOriginalItemId(0);
  };

  // Mapped items search input keydown event.
  const onSimilarItemsSearchHandleKeyDown = (event: any) => {
    const { value } = event.target;
    if (event.key === ENTER_KEY) {
      if (similarItemSearchRef.current) {
        similarItemSearchRef.current.blur();
      }
      setSimilarItemsSearchText(value?.trim());
      setSimilarItemsSearchField(similarItemsSearchField.trim());
    }
  };

  // Mapped items search textbox selected change event.
  const onSimilarItemsSearchHandleChange = (value: any) => {
    if (value[0]) {
      previousSimilarItemSearchText.current = value[0].itemName;
      setSimilarItemId(value[0].itemId);
      setSimilarItemsSearchText(value[0].itemName);
      setPageIndex(1);
      setSimilarItemsSearchField(value[0].itemName);
      if (similarItemSearchRef.current) {
        similarItemSearchRef.current.blur();
      }
    }
  };

  // Mapped items search textbox search input change event.
  const onSimilarItemsSearchInputHandleChange = (value: any) => {
    if (value.trim().length >= SEARCH_MIN_CHAR_LENGTH) {
      /* Search call will be fired when character length greater than 2 */
      setSimilarItemsSearchField(value);
    } else if (value.trim().length === 0) {
      setSimilarItemsSearchField('');
      setSimilarItemsSearchText('');
    }
    previousSimilarItemSearchText.current = value;
    setSimilarItemsTypeField(value);
    setSimilarItemId(0);
  };

  // Sort header change event.
  const changeSortField = (field: number) => {
    let newSortOrder = SortOrderType.ASC;
    if (field === sortField) {
      newSortOrder = sortOrder === SortOrderType.ASC ? SortOrderType.DESC : SortOrderType.ASC;
    }
    setSortField(field);
    setSortOrder(newSortOrder);
  };

  // Filter incentives process status change event.
  const onStatusChange = (values: ISelectOptions[]) => {
    setApprovedStatus(values);
    setPageIndex(1);
  };

  // Navigation to similar item details.
  const navigateToSimilarItems = (evt: React.MouseEvent<HTMLAnchorElement>, mappingId: number, itemName: string, similarItemName: string) => {
    evt.preventDefault();
    navigate(`/products/similarItems/${itemName.replace(/[^a-zA-Z0-9-]/g, '')}-${similarItemName.replace(/[^a-zA-Z0-9-]/g, '')}`, {
      state: { id: mappingId, from: 'usermappeditems' },
    });
  };

  // Bind incentive status filter elements.
  const setIncentiveProcessStatusFilter = () => {
    const inectiveStatusFilterOptions: ISelectOptions[] = [];
    Object.values(SimilarItemProcessStatus)
      // eslint-disable-next-line no-restricted-globals
      .filter((v) => !isNaN(Number(v)))
      .forEach((val) => {
        inectiveStatusFilterOptions.push({
          label: `${SimilarItemProcessStatus[Number(val)].at(0)}${SimilarItemProcessStatus[Number(val)].slice(1).toLowerCase()}`,
          value: Number(val),
        } as ISelectOptions);
      });
    return (
      <Select
        id="status"
        name="status"
        options={inectiveStatusFilterOptions}
        placeholder={useIntlMessages('Filter.Show')}
        dropdownGap={0}
        values={approveStatus}
        icon={<SortIcon />}
        onChange={(values) => onStatusChange(values)}
      />
    );
  };

  return (
    <div className="content-nav contnt-container-margin">
      <div className="back-navigation">
        <Link to="/subscription/incentives" className="back-btn">
          <BackIcon />
          <FormattedMessage id="Link.Back" />
        </Link>
      </div>
      <div className="content-nav-header mb-4">
        <div className="d-xl-flex justify-content-between align-items-center">
          <h2>
            <FormattedMessage id="Incentives.MappedItems.List.Header" /> {mappedByUser}
          </h2>
        </div>
      </div>
      <div className="content-nav-sub">
        <div className="content-filter-main border-0 pb-5">
          <Row>
            <Col xs={12} md={6} lg={5} xl={3} className="no-margin search-auto">
              <Typeahead
                id="originalItemSearchInput"
                ref={originalItemSearchRef}
                onInputChange={onOriginalItemsSearchInputHandleChange}
                onChange={onOriginalItemsSearchHandleChange}
                onKeyDown={onOriginalItemsSearchHandleKeyDown}
                options={originalItemsByUser}
                placeholder={useIntlMessages('Incentives.MappedItems.Original.Search.PlaceHolder')}
                selected={originalItemsByUser.filter((x: any) => x.itemId === originalItemId)}
                filterBy={['itemId', 'itemName']}
                minLength={previousOriginalItemSearchText.current.length > originalItemsTypeField.length ? previousOriginalItemSearchText.current.length : SEARCH_MIN_CHAR_LENGTH}
                labelKey={(item: any) => `${item.itemName}`}
                inputProps={{
                  maxLength: INPUT_MAX_LENGTH_200,
                }}
                renderMenuItemChildren={(item: any) => (
                  <div>
                    {item.itemName}
                    <div>
                      <small>{item.vendorName}</small>
                    </div>
                  </div>
                )}
              >
                <Search />
              </Typeahead>
            </Col>
            <Col xs={12} md={6} lg={5} xl={3} className="no-margin search-auto">
              <Typeahead
                id="similarItemSearchInput"
                ref={similarItemSearchRef}
                onInputChange={onSimilarItemsSearchInputHandleChange}
                onChange={onSimilarItemsSearchHandleChange}
                onKeyDown={onSimilarItemsSearchHandleKeyDown}
                options={similarItemsByUser}
                placeholder={useIntlMessages('Incentives.MappedItems.Similar.Search.PlaceHolder')}
                selected={similarItemsByUser.filter((x: any) => x.itemId === similarItemId)}
                filterBy={['itemId', 'itemName']}
                minLength={previousSimilarItemSearchText.current.length > similarItemsTypeField.length ? previousSimilarItemSearchText.current.length : SEARCH_MIN_CHAR_LENGTH}
                labelKey={(item: any) => `${item.itemName}`}
                inputProps={{
                  maxLength: INPUT_MAX_LENGTH_200,
                }}
                renderMenuItemChildren={(item: any) => (
                  <div>
                    {item.itemName}
                    <div>
                      <small>{item.vendorName}</small>
                    </div>
                  </div>
                )}
              >
                <Search />
              </Typeahead>
            </Col>
            <Col xs={12} md={5} lg={4} xl={2} className="no-margin">
              {setIncentiveProcessStatusFilter()}
            </Col>
          </Row>
        </div>
        <Table responsive>
          <thead>
            <tr>
              <th className="w-350" onClick={() => changeSortField(UserMappedItemsSortField.ORIGINAL_ITEM_NAME)} style={{ cursor: 'pointer' }}>
                <FormattedMessage id="TH.OriginalItemName" />
                {sortField !== UserMappedItemsSortField.ORIGINAL_ITEM_NAME && <UpDownArrow />}
                {sortField === UserMappedItemsSortField.ORIGINAL_ITEM_NAME && sortOrder === SortOrderType.DESC && <DownArrow />}
                {sortField === UserMappedItemsSortField.ORIGINAL_ITEM_NAME && sortOrder === SortOrderType.ASC && <UpArrow />}
              </th>
              <th className="w-250" onClick={() => changeSortField(UserMappedItemsSortField.ORIGINAL_VENDOR)} style={{ cursor: 'pointer' }}>
                <FormattedMessage id="TH.OriginalVendor" />
                {sortField !== UserMappedItemsSortField.ORIGINAL_VENDOR && <UpDownArrow />}
                {sortField === UserMappedItemsSortField.ORIGINAL_VENDOR && sortOrder === SortOrderType.DESC && <DownArrow />}
                {sortField === UserMappedItemsSortField.ORIGINAL_VENDOR && sortOrder === SortOrderType.ASC && <UpArrow />}
              </th>
              <th className="w-350" onClick={() => changeSortField(UserMappedItemsSortField.SIMILAR_ITEM_NAME)} style={{ cursor: 'pointer' }}>
                <FormattedMessage id="TH.SimilarItemName" />
                {sortField !== UserMappedItemsSortField.SIMILAR_ITEM_NAME && <UpDownArrow />}
                {sortField === UserMappedItemsSortField.SIMILAR_ITEM_NAME && sortOrder === SortOrderType.DESC && <DownArrow />}
                {sortField === UserMappedItemsSortField.SIMILAR_ITEM_NAME && sortOrder === SortOrderType.ASC && <UpArrow />}
              </th>
              <th className="w-250" onClick={() => changeSortField(UserMappedItemsSortField.SIMILAR_VENDOR)} style={{ cursor: 'pointer' }}>
                <FormattedMessage id="TH.SimilarVendor" />
                {sortField !== UserMappedItemsSortField.SIMILAR_VENDOR && <UpDownArrow />}
                {sortField === UserMappedItemsSortField.SIMILAR_VENDOR && sortOrder === SortOrderType.DESC && <DownArrow />}
                {sortField === UserMappedItemsSortField.SIMILAR_VENDOR && sortOrder === SortOrderType.ASC && <UpArrow />}
              </th>
              <th className="w-120" onClick={() => changeSortField(UserMappedItemsSortField.RANK)} style={{ cursor: 'pointer' }}>
                <FormattedMessage id="TH.Rank" />
                {sortField !== UserMappedItemsSortField.RANK && <UpDownArrow />}
                {sortField === UserMappedItemsSortField.RANK && sortOrder === SortOrderType.DESC && <DownArrow />}
                {sortField === UserMappedItemsSortField.RANK && sortOrder === SortOrderType.ASC && <UpArrow />}
              </th>
              <th className="w-220"> </th>
            </tr>
          </thead>
          <tbody>
            {userMappedItems &&
              userMappedItems.map((item: IUserMappedItemsList) => (
                <tr key={item.itemId} className={item.approvalStatus === SimilarItemProcessStatus.APPROVED ? 'approved' : ''}>
                  <td>
                    <a href="#" onClick={(e) => navigateToSimilarItems(e, item.mappingId, item.itemName, item.similarItemName)}>
                      {item.itemName}
                    </a>
                  </td>
                  <td>{item.vendorName}</td>
                  <td>{item.similarItemName}</td>
                  <td>{item.similarVendorName}</td>
                  <td>{item.rank}</td>
                  <td>
                    {item.approvalStatus === SimilarItemProcessStatus.APPROVED && (
                      <Badge className="badge-due-paid">
                        <FormattedMessage id="Status.Approved" />
                      </Badge>
                    )}
                    {item.approvalStatus === SimilarItemProcessStatus.DECLINED && (
                      <Badge className="badge-due-high">
                        <FormattedMessage id="Status.Declined" />
                      </Badge>
                    )}
                    {item.approvalStatus === SimilarItemProcessStatus.PENDING && (
                      <Badge className="badge-pending">
                        <FormattedMessage id="Status.Pending" />
                      </Badge>
                    )}
                  </td>
                </tr>
              ))}
            {!userMappedItems ||
              (userMappedItems && userMappedItems.length === 0 && (
                <tr>
                  <td colSpan={6} className="text-center">
                    <FormattedMessage id="MappedItems.table.emptymessage" />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <div className="content-filter-main d-lg-flex justify-content-lg-between">
          {userMappedItems && userMappedItems.length > 0 && (
            <p className="m-0">
              {stringFormat(
                userMappedItemsTotalCount === 1 ? useIntlActionMessages('MappedItems.Pagination.SingleItem.Text') : useIntlActionMessages('MappedItems.Pagination.Text'),
                userMappedItems.length > 0 ? ((userMappedItemsTotalCount === 1 ? 1 : pageIndex) - 1) * pageSize + 1 : 0,
                ((userMappedItemsTotalCount === 1 ? 1 : pageIndex) - 1) * pageSize + userMappedItems.length,
                userMappedItemsTotalCount,
              )}
            </p>
          )}
          {userMappedItems.length > 0 && userMappedItemsTotalCount > pageSize && (
            <Pagination
              totalCount={userMappedItemsTotalCount}
              pageLimit={pageSize}
              setCurrentPage={(page: number) => setPageIndex(page)}
              currentPage={pageIndex - 1}
              prevPage={-1}
            />
          )}
        </div>
      </div>
      {userMappedItemsLoading && <GifLoader />}
    </div>
  );
};

export default UserMappedItems;
