/**
 * @file   src\components\SelectLabel.tsx
 * @brief  Html Select with label component.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import '../styles/SelectLabel.scss';
import Select from 'react-dropdown-select';
import { ISelectLabel } from '../interfaces/generalInterface';

const Selectlabel = ({
  onChange,
  className,
  label,
  isMandatory,
  errorMessage,
  values,
  placeholder,
  options,
  dropdownPosition,
  help,
  id,
  name,
  searchable,
  multi,
  icon,
}: ISelectLabel) => {
  return (
    <div className="form-group">
      <div className={`cust-label-group ${className}`}>
        <div className="label-wrap">
          <label htmlFor="label">
            {label}
            {isMandatory && <span className="mandatory">*</span>}
          </label>
          <span>{help}</span>
        </div>
        <Select
          name={name}
          className={errorMessage && errorMessage !== '' ? 'cutom-select error' : values[0] && values[0] !== '' ? 'cutom-select active' : 'cutom-select'}
          placeholder={placeholder}
          options={options}
          dropdownGap={-3}
          values={values}
          dropdownPosition={dropdownPosition}
          onChange={onChange}
          searchable={searchable}
          multi={multi}
          additionalProps={{
            id,
          }}
        />
        {icon && <span className="icon-wrap">{icon}</span>}
        {errorMessage && errorMessage !== '' && <span className="error">{errorMessage}</span>}
      </div>
    </div>
  );
};

export default Selectlabel;
