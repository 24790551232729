/**
 * @file   src\components\Layout.tsx
 * @brief  This component used as a layout for the content which can be replaced.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { Outlet } from '../utils/thirdpartyComponents';
import Header from './Header';
import Footer from './Footer';

const Layout = () => {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

export default Layout;
