/**
 * @file   src\utils\toastUtil.ts
 * @brief  This file is responsible for configuring toast message popup.
 * @date   May, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { toast } from 'react-toastify';

export class MessageToaster {
  /**
   * Provides methods to toast error and success messages.
   * uses react-toastify package
   * toastProperties : determines where the toast appears on the screen and other properties
   */
  toastProperties: any;

  constructor() {
    this.toastProperties = {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    };
  }

  toastError(message: string) {
    toast.error(message, this.toastProperties);
  }

  toastSuccess(message: string) {
    toast.success(message, this.toastProperties);
  }
}
