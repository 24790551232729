import React from 'react';

const HomeIcon = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9167 19.25V10.0833H19.25V19.25H11.9167ZM2.75 11.9167V2.75H10.0833V11.9167H2.75ZM8.25 10.0833V4.58333H4.58333V10.0833H8.25ZM2.75 19.25V13.75H10.0833V19.25H2.75ZM4.58333 17.4167H8.25V15.5833H4.58333V17.4167ZM13.75 17.4167H17.4167V11.9167H13.75V17.4167ZM11.9167 2.75H19.25V8.25H11.9167V2.75ZM13.75 4.58333V6.41667H17.4167V4.58333H13.75Z"
        fill="#63707E"
      />
    </svg>
  );
};

export default HomeIcon;
