/**
 * @file   src\features\subscription\Charges.tsx
 * @brief  Subscription charges and incentives page
 * @date   July, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { Col, Button, useEffect, useState, FormattedMessage } from '../../utils/thirdpartyComponents';
import InputLabel from '../../components/InputLabel';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getSubscriptionSettings, updateSubscriptionSettings } from '../../store/actions/subscriptionAction';
import { RootState } from '../../store';
import { DOT_KEY, DECIMAL_PLACE_FIELDS_MAX_LENGTH, MAX_LENGTH_WITHOUT_DECIMALS, DECIMAL_REGEX } from '../../utils/constants';
import GifLoader from '../../components/GifLoader';
import { MessageToaster } from '../../utils/toastUtil';
import { resetSubscription } from '../../store/slices/subscriptionSlice';
import { ISubscriptionErrors } from '../../interfaces/subscriptionInterface';
import { useIntlActionMessages } from '../../utils/helper';

const toast = new MessageToaster();
const Charges = () => {
  // Declare action dispatch.
  const dispatch = useAppDispatch();
  // Accessing Redux state variables
  const {
    subscriptionSettings,
    subscriptionSettingsLoading,
    subscriptionSettingsSuccess,
    subscriptionSettingsError,
    subscriptionSettingsMessage,
    updateSettingsLoading,
    updateSettingsError,
    updateSettingsMessgae,
    updateSettingsSuccess,
  } = useAppSelector((state: RootState) => state.subscription);

  // Component state variables
  const [settingsData, setSettingsData] = useState<any>({
    restaurantPremium: '0',
    identicalItems: '0',
    similiarItems: '0',
    eligibleForIncentive: '0',
    incentiveAmount: '0',
  });
  const [settingsErrors, setSettingsErrors] = useState<ISubscriptionErrors>({
    restaurantPremiumError: '',
    identicalItemsError: '',
    similiarItemsError: '',
    eligibleForIncentiveError: '',
    incentiveAmountError: '',
  });
  const [editActive, setEditActive] = useState<boolean>(false);
  // Api call on intial loading
  useEffect(() => {
    dispatch(getSubscriptionSettings());
    // Reset data on component unmount
    return () => {
      dispatch(resetSubscription());
    };
  }, []);
  // Set subscription settings data
  useEffect(() => {
    if (subscriptionSettingsSuccess) {
      setSettingsData({
        restaurantPremium: subscriptionSettings.restaurantPremiumSubscription,
        identicalItems: subscriptionSettings.identicalItemsChoosingCharge,
        similiarItems: subscriptionSettings.similarItemsChoosingCharge,
        eligibleForIncentive: subscriptionSettings.similarItemsIncentiveEligibility ? Number(subscriptionSettings.similarItemsIncentiveEligibility) : 0,
        incentiveAmount: subscriptionSettings.similarItemsIncentiveAmount,
      });
    }
  }, [subscriptionSettingsSuccess]);
  // Show success message when settings is updated
  useEffect(() => {
    if (updateSettingsSuccess) {
      toast.toastSuccess(updateSettingsMessgae);
      setEditActive(false);
    }
  }, [updateSettingsSuccess]);
  // show error message when update/get settings fails
  useEffect(() => {
    if (updateSettingsError > 0) {
      toast.toastError(updateSettingsMessgae);
    }
    if (subscriptionSettingsError > 0) {
      toast.toastError(subscriptionSettingsMessage);
    }
  }, [updateSettingsError, subscriptionSettingsError]);

  // Handle inputs fields validation
  const handleInputsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (DECIMAL_REGEX.test(value) && value.includes('.') && value.length <= DECIMAL_PLACE_FIELDS_MAX_LENGTH) {
      setSettingsData({ ...settingsData, [name]: value });
    } else if (DECIMAL_REGEX.test(value) && !value.includes('.') && value.length <= MAX_LENGTH_WITHOUT_DECIMALS) {
      setSettingsData({ ...settingsData, [name]: value });
    }

    if (value === '') {
      const errorName = `${name}Error`;
      setSettingsErrors({ ...settingsErrors, [errorName]: 'Enter value' });
    } else if ((name === 'identicalItems' || name === 'similiarItems') && parseFloat(value) > 100) {
      const errorName = `${name}Error`;
      setSettingsErrors({ ...settingsErrors, [errorName]: useIntlActionMessages('Maximum100.Percentage.Error') });
    } else {
      const errorName = `${name}Error`;
      setSettingsErrors({ ...settingsErrors, [errorName]: '' });
    }
  };

  const keyPressHandler = (event: any) => {
    if (event.which === DOT_KEY) {
      event.preventDefault();
    }
  };
  // call api to update subscription settings
  const updateSettings = () => {
    if (
      settingsErrors.eligibleForIncentiveError.length > 0 ||
      settingsErrors.identicalItemsError.length > 0 ||
      settingsErrors.restaurantPremiumError.length > 0 ||
      settingsErrors.incentiveAmountError.length > 0 ||
      settingsErrors.similiarItemsError.length > 0
    ) {
      toast.toastError(useIntlActionMessages('EnterMissingInfo.Error'));
    } else {
      const request = {
        restaurantPremiumSubscription: settingsData.restaurantPremium.toString(),
        identicalItemsChoosingCharge: settingsData.identicalItems.toString(),
        similarItemsChoosingCharge: settingsData.similiarItems.toString(),
        similarItemsIncentiveEligibility: Number(settingsData.eligibleForIncentive),
        similarItemsIncentiveAmount: settingsData.incentiveAmount.toString(),
      };
      dispatch(updateSubscriptionSettings(request));
    }
  };

  return (
    <div>
      <Col className="mb-5">
        <h6>
          <FormattedMessage id="Subscription.Premium" />
        </h6>
        <h6 className="d-flex align-items-center text-lightgray">
          <FormattedMessage id="Subscription.Monthly" />
          <div className="w-150 no-label-input form-md txt-center ps-2 sm-error-td">
            <InputLabel
              type="text"
              id="Quantity"
              name="restaurantPremium"
              value={settingsData.restaurantPremium}
              onChange={(e) => handleInputsChange(e)}
              disabled={!editActive}
              errorMessage={settingsErrors.restaurantPremiumError}
            />
          </div>
        </h6>
      </Col>
      <Col className="mb-5">
        <h6>
          <FormattedMessage id="Subscription.Identical" />
        </h6>
        <h6 className="d-flex align-items-center text-lightgray">
          <FormattedMessage id="Subscription.Identical.Line1" />
          <br /> <FormattedMessage id="Subscription.Identical.Line2" />
          <div className="w-150 no-label-input form-md txt-center ps-2 sm-error-td">
            <InputLabel
              type="text"
              id="Quantity"
              name="identicalItems"
              value={settingsData.identicalItems}
              onChange={(e) => handleInputsChange(e)}
              disabled={!editActive}
              errorMessage={settingsErrors.identicalItemsError}
            />
          </div>
          <p className="text-small fw-normal m-0 ps-2">
            <FormattedMessage id="Subscription.Identical.Line3" />
          </p>
        </h6>
      </Col>
      <Col className="mb-5">
        <h6>
          <FormattedMessage id="Subscription.SimiliarItems" />
        </h6>
        <h6 className="d-flex align-items-center text-lightgray">
          <FormattedMessage id="Subscription.Identical.Line1" />
          <br /> <FormattedMessage id="Subscription.SimiliarItems.Line2" />
          <div className="w-150 no-label-input form-md txt-center ps-2 sm-error-td">
            <InputLabel
              type="text"
              id="Quantity"
              name="similiarItems"
              value={settingsData.similiarItems}
              onChange={(e) => handleInputsChange(e)}
              disabled={!editActive}
              errorMessage={settingsErrors.similiarItemsError}
            />
          </div>
          <p className="text-small fw-normal m-0 ps-2">
            <FormattedMessage id="Subscription.Identical.Line3" />
          </p>
        </h6>
      </Col>
      <Col className="mb-5">
        <h6>
          <FormattedMessage id="Subscription.Itemsmapping" />
        </h6>
        <h6 className="d-flex align-items-center text-lightgray mb-2">
          <FormattedMessage id="Subscription.EligibleIncentive" />
          <div className="w-125px no-label-input form-md txt-center ps-2 sm-error-td">
            <InputLabel
              type="text"
              id="Quantity"
              name="eligibleForIncentive"
              value={settingsData.eligibleForIncentive}
              onChange={(e) => handleInputsChange(e)}
              disabled={!editActive}
              onKeyPress={keyPressHandler}
              errorMessage={settingsErrors.eligibleForIncentiveError}
            />
          </div>
        </h6>
        <h6 className="d-flex align-items-center text-lightgray">
          <FormattedMessage id="Subscription.IncentiveAmount" />
          <div className="w-150 no-label-input form-md txt-center ps-2 sm-error-td">
            <InputLabel
              type="text"
              id="Quantity"
              name="incentiveAmount"
              value={settingsData.incentiveAmount}
              onChange={(e) => handleInputsChange(e)}
              disabled={!editActive}
              errorMessage={settingsErrors.incentiveAmountError}
            />
          </div>
        </h6>
      </Col>
      <Col className="text-end">
        {editActive ? null : (
          <Button variant="outline-primary" onClick={() => setEditActive(true)}>
            <FormattedMessage id="Button.Edit" />
          </Button>
        )}
        {editActive ? (
          <Button variant="primary" className="ms-3" onClick={() => updateSettings()}>
            <FormattedMessage id="Button.Save" />
          </Button>
        ) : null}
      </Col>
      {(subscriptionSettingsLoading || updateSettingsLoading) && <GifLoader />}
    </div>
  );
};

export default Charges;
