import '../styles/ProductStock.scss';
import { Row, Col, Form, FormattedMessage } from '../utils/thirdpartyComponents';
import { IItemInfo } from '../interfaces/productInterface';
import { formatNumberWithCommas } from '../utils/helper';

const ProductPopup = ({ item }: IItemInfo) => {
  return (
    <Row>
      <Col xl={4} md={12} className="product-img-container position-relative">
        {/* <img src={item.imageUrl || '/no-item-image.jpg'} alt="ProductImage" className="w-90per" /> */}
        <img
          src={item.imageUrl || '/no-item-image.jpg'}
          alt="ProductImage"
          className="w-90per"
          onError={(e) => {
            e.currentTarget.src = '/no-item-image.jpg';
          }}
        />
      </Col>
      <Col className="product-details ps-5">
        <h3>{item.itemName}</h3>
        <Row className="my-4">
          <Col className="d-flex align-items-center">
            <h5>
              <FormattedMessage id="Products.inventory.heading" />:<span>{item.onHandInventory || 0}</span>
            </h5>
          </Col>
        </Row>
        <p className="pb-4 border-bottom">{item.description || ''}</p>
        <Form className="productdetails-points">
          <Form.Group as={Row}>
            <Col className="product-label">
              <FormattedMessage id="Product.Lbl.SKU" />:
            </Col>
            <Col className="product-value">{item.skuNo}</Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col className="product-label">
              <FormattedMessage id="Product.Lbl.WarehouseLocation" />:
            </Col>
            <Col className="product-value">{item.location}</Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col className="product-label">
              <FormattedMessage id="Product.Lbl.ProductGroup" />:
            </Col>
            <Col className="product-value">{item.masterProductGroupName || ''}</Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col className="product-label">
              <FormattedMessage id="Product.Lbl.Origin" />:
            </Col>
            <Col className="product-value">{item.originStateName || item.originCountryName || ''}</Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col className="product-label">
              <FormattedMessage id="Product.Lbl.Pack" />:
            </Col>
            <Col className="product-value">{item.pack || 0}</Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col className="product-label">
              <FormattedMessage id="Product.Lbl.Size" />:
            </Col>
            <Col className="product-value">{`${item.size ? `${item.size.toFixed(2)} ${item.unitName || ''}` : 0}`}</Col>
          </Form.Group>
          {item.isVariableWeight && (
            <Form.Group as={Row}>
              <Col className="product-label">
                <FormattedMessage id="Product.Lbl.Price/lb" />:
              </Col>
              <Col className="product-value d-flex">{`$${item.pricePerLb ? formatNumberWithCommas(item.pricePerLb) : 0}`}</Col>
            </Form.Group>
          )}
          {!item.isVariableWeight && (
            <Form.Group as={Row}>
              <Col className="product-label">
                <FormattedMessage id="Product.Lbl.Price" />:
              </Col>
              <Col className="product-value d-flex">{`$${item.price ? formatNumberWithCommas(item.price) : 0}`}</Col>
            </Form.Group>
          )}
          <Form.Group as={Row}>
            <Col className="product-label">
              <FormattedMessage id="Product.Lbl.LowInventory" />:
            </Col>
            <Col className="product-value d-flex align-items-center">{item.lowInventory || 0}</Col>
          </Form.Group>
        </Form>
      </Col>
    </Row>
  );
};
export default ProductPopup;
