/**
 * @file   src\ProtectedRoute.tsx
 * @brief  This component used for manage and protect unauthorized/permission based url access.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { Navigate, useLocation, matchPath } from '../utils/thirdpartyComponents';
import { getFromLocalStorage } from '../utils/helper';
import { PermissionUrls } from '../utils/menuHelper';
import { IPermissionUrls } from '../interfaces/generalInterface';
import { SUPERADMIN_TYPE } from '../utils/constants';
import { DefaultAdminRoles, MenuUrls } from '../utils/enums';

interface Props {
  children: JSX.Element;
  // any props that come into the component
}
const ProtectedRoute = ({ children }: Props) => {
  /* Create location object to access location info. */
  const location = useLocation();
  const storageData = getFromLocalStorage('MCSA_UDT');
  const userInfo = storageData && storageData !== null ? storageData : null;
  const isAuthenticated = userInfo !== null && userInfo?.accessToken !== '';

  /* Check whether the user is authenticated or not and redirected to login page. */
  if (!isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  /* Url access settings based on role permissions and user type. */
  if (userInfo?.userType === SUPERADMIN_TYPE) {
    /* Prevent super admin user from accessing profile page. */
    if (location?.pathname === MenuUrls.PROFILE) {
      return <Navigate to={MenuUrls.USERS} state={{ from: location }} replace />;
    }
    return children;
  }
  /* Redirect to profile page if no permissions given. */
  if (!userInfo?.permissions && location?.pathname !== MenuUrls.PROFILE) {
    return <Navigate to={MenuUrls.PROFILE} state={{ from: location }} replace />;
  }

  // Match current location pathname with permission enum path.
  const result = PermissionUrls.find((route: IPermissionUrls) => matchPath(route.path, location?.pathname));
  /* Check whether the permission id included in the authorized permission id. */
  if (userInfo?.permissions && userInfo.permissions.length > 0 && userInfo?.permissions.includes(result?.permissionId)) {
    return children;
  }
  /* If no permission set up for the user redirect to profile page. */
  if (userInfo?.permissions && userInfo.permissions.length > 0) {
    if (location?.pathname === MenuUrls.PROFILE) {
      return children;
    }
    // Check cobrowse permission for admin, onboarding, customer support users/roles.
    if (
      [MenuUrls.VENDOR_COBROWSE, MenuUrls.RESTAURANT_COBROWSE].find((url: string) => matchPath(url, location?.pathname)) &&
      (userInfo?.userType === SUPERADMIN_TYPE ||
        userInfo?.defaultRoleId === DefaultAdminRoles.ADMIN ||
        userInfo?.defaultRoleId === DefaultAdminRoles.CUSTOMER_SUPPORT ||
        userInfo?.defaultRoleId === DefaultAdminRoles.ONBOARDING)
    ) {
      return children;
    }
    const navigationObj = PermissionUrls.find((nav: IPermissionUrls) => nav.permissionId === userInfo?.permissions[0]);
    return <Navigate to={navigationObj?.path || MenuUrls.PROFILE} state={{ from: location }} replace />;
  }
  return children;
};

export default ProtectedRoute;
