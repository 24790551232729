import { useEffect, useState, ReactPaginate } from '../utils/thirdpartyComponents';
import { IPaginate } from '../interfaces/generalInterface';

const Paginate = ({ currentPage, prevPage, pageLimit, setCurrentPage, isPageChanged, totalCount }: IPaginate) => {
  // Component state.
  const [forceVal, setInitialVal] = useState(0);
  // Page change state.
  useEffect(() => {
    setInitialVal(currentPage);
    // Disable previous and next pagination button based on data.
    const prevTag = document.querySelector('.pagination .previous');
    if (prevTag && currentPage === 0) {
      (prevTag as HTMLElement).setAttribute('style', 'display: none;');
    } else if (prevTag && currentPage > 0) {
      (prevTag as HTMLElement).setAttribute('style', 'display: block;');
    }
    const nextTag = document.querySelector('.pagination .next');
    if (nextTag && currentPage === Math.ceil(totalCount / pageLimit) - 1) {
      (nextTag as HTMLElement).setAttribute('style', 'display: none;');
    } else if (nextTag && currentPage < Math.ceil(totalCount / pageLimit) - 1) {
      (nextTag as HTMLElement).setAttribute('style', 'display: block;');
    }
  }, [currentPage]);

  // State change for inventory confirmation: Page reset if any pending unsaved inventory.
  useEffect(() => {
    if (isPageChanged !== '') {
      if (prevPage >= 0) {
        setInitialVal(prevPage);
      }
    }
  }, [isPageChanged, prevPage]);

  // Page click event.
  const handlePageClick = (event: any) => {
    setInitialVal(event.selected);
    setCurrentPage(event.selected + 1);
  };

  return (
    <ReactPaginate
      previousLabel="Previous"
      nextLabel="Next"
      pageCount={Math.ceil(totalCount / pageLimit)}
      onPageChange={handlePageClick}
      forcePage={forceVal}
      containerClassName="pagination"
      previousLinkClassName="pagination__link"
      nextLinkClassName="pagination__link"
      disabledClassName="pagination__link--disabled"
      activeClassName="pagination__link--active"
    />
  );
};

export default Paginate;
