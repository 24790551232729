/**
 * @file   src\features\product\List.tsx
 * @brief  Product listing page
 * @date   June, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import {
  useState,
  useEffect,
  Button,
  Row,
  Col,
  Table,
  Collapse,
  Typeahead,
  FormattedMessage,
  Modal,
  useRef,
  createRef,
  AsyncTypeahead,
  useCallback,
  Breadcrumb,
  useNavigate,
} from '../../utils/thirdpartyComponents';
import { useAppDispatch, useAppSelector } from '../../hooks';
import Filter from '../../assets/icons/Filter';
import Arrow from '../../assets/icons/Arrow';
import Search from '../../assets/icons/Search';
import Select from '../../components/SelectLabel';
import Pagination from '../../components/Paginate';
import StockPop from '../../components/ProductPop';
import { formatNumberWithCommas, stringFormat, useIntlActionMessages, useIntlMessages } from '../../utils/helper';
import { RootState } from '../../store';
import { getProductOrigins, getProductGroups, getProductList, searchProducts, getProductDetails } from '../../store/actions/productAction';
import { resetProductState } from '../../store/slices/productSlice';
import { ICountryOrigin, IProductGroup, ISelectOptions } from '../../interfaces/generalInterface';
import { IItem, IProductFilter, IProductListRequest } from '../../interfaces/productInterface';
import { DEFAULT_PAGE_INDEX, DEFAULT_SORT_FIELD, ENTER_KEY, PAGE_SIZE, SEARCH_MIN_CHAR_LENGTH, SEARCH_PAGESIZE, INPUT_MAX_LENGTH_100 } from '../../utils/constants';
import { SortOrderType, ProductSortField } from '../../utils/enums';
import GifLoader from '../../components/GifLoader';
import UpDownArrow from '../../assets/icons/UpDownArrow';
import DownArrow from '../../assets/icons/DownArrow';
import UpArrow from '../../assets/icons/UpArrow';
import { IvendorRequest } from '../../interfaces/vendorInterface';
import { searchVendors } from '../../store/actions/vendorAction';
import 'react-bootstrap-typeahead/css/Typeahead.css';

// Filter initial state.
const defaultProductFilterState: IProductFilter = {
  productGroupOptions: [],
  productGroups: [],
  selectedGroups: [],
  selectedVendors: [],
};

// Declare page item count to be displayed per page.
const pageItemCount: number = process.env.REACT_APP_LIST_PAGE_SIZE !== '' ? Number(process.env.REACT_APP_LIST_PAGE_SIZE) : PAGE_SIZE;
const OrderList = () => {
  // Navigate object creation.
  const navigate = useNavigate();

  // Initialize search field ref for product, vendor.
  const oldSearchText = useRef<string>('');
  const oldVendorSearchText = useRef<string>('');

  // Create a ref on search input for product, vendor.
  const inputRef = createRef<any>();
  const inputVendorRef = createRef<any>();

  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Access redux state variables.
  const {
    productGroupSuccess,
    productGroups: productGroupList,
    productOriginsSuccess,
    productOrigins: productOriginList,
    searchProductList,
    isLoading,
    totalCount,
    products,
    productDetails,
  } = useAppSelector((state: RootState) => state.product);
  const { searchVendorList } = useAppSelector((state: RootState) => state.vendor);

  // Initialize component state variables.
  const [open, setOpen] = useState(false);
  const [inventory, inventoryPop] = useState(false);
  const [productFilters, setProductFilters] = useState<IProductFilter>(defaultProductFilterState);
  const [searchField, setSearchField] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const [typeheadUniqueFilter, setTypeheadUniqueFilter] = useState<string>('');
  const [typeField, setTypeField] = useState<string>('');
  const [searchVendorField, setSearchVendorField] = useState<string>('');
  const [searchVendorText, setSearchVendorText] = useState<string>('');
  const [pageSize] = useState<number>(pageItemCount);
  const [pageIndex, setPageIndex] = useState<number>(DEFAULT_PAGE_INDEX);
  const [sortField, setSortField] = useState<number>(DEFAULT_SORT_FIELD);
  const [sortOrder, setSortOrder] = useState<number>(SortOrderType.ASC);
  const [selectedItemId, setSelectedItemId] = useState<number>(0);
  const [filterCount, setFilterCount] = useState<number>(0);

  // handle component side effects to call api's at page load.
  useEffect(() => {
    dispatch(getProductOrigins());
    dispatch(getProductGroups());
  }, [dispatch]);

  // Product origins fetch success state change.
  useEffect(() => {
    if (productOriginsSuccess) {
      const selectedOrigins = productOriginList.map(
        (origin: ICountryOrigin): ISelectOptions => ({
          label: origin.countryName,
          value: origin.countryId,
        }),
      );
      setProductFilters((filter) => ({
        ...filter,
        productOriginOptions: selectedOrigins,
      }));
    }
  }, [productOriginsSuccess]);

  // Product groups fetch success state change.
  useEffect(() => {
    if (productGroupSuccess) {
      const selectedGroups = productGroupList.map(
        (origin: IProductGroup): ISelectOptions => ({
          label: origin.productGroupName,
          value: origin.productGroupId,
        }),
      );
      setProductFilters((filter) => ({
        ...filter,
        productGroupOptions: selectedGroups,
      }));
    }
  }, [productGroupSuccess]);

  // Fetch products action call.
  const fetchProducts = () => {
    const productFilterRequest: IProductListRequest = {
      itemId: selectedItemId,
      searchText: selectedItemId !== 0 ? '' : searchText,
      vendorIds: productFilters.selectedVendors,
      countryIds: [],
      productGroupIds: productFilters.selectedGroups,
      pageSize,
      pageIndex,
      sortField,
      sortOrder,
    };
    dispatch(getProductList(productFilterRequest));
  };

  // Search field state change handling for list products.
  useEffect(() => {
    if (searchField.length >= SEARCH_MIN_CHAR_LENGTH && selectedItemId === 0) {
      const productSearchRequest: IProductListRequest = {
        itemId: 0,
        searchText: searchField,
        vendorIds: [],
        countryIds: [],
        productGroupIds: [],
        pageSize: SEARCH_PAGESIZE,
        pageIndex: DEFAULT_PAGE_INDEX,
        sortField,
        sortOrder,
      };
      dispatch(searchProducts(productSearchRequest));
    }
  }, [searchField]);

  // Product list api call on pageIndex, showDeactivateOnly, subscriptionPlanId, sortField, sortOrder state change.
  useEffect(() => {
    fetchProducts();
  }, [pageIndex, searchText, sortField, sortOrder, typeheadUniqueFilter, searchVendorText, productFilters.selectedGroups]);

  // Handle product filter state change.
  useEffect(() => {
    const appliedFilterCount = productFilters.selectedGroups.length + productFilters.selectedVendors.length;
    setFilterCount(appliedFilterCount);
  }, [productFilters.selectedGroups, productFilters.selectedVendors]);

  // Select input(product group) change event.
  const onSelectChange = async (name: string, values: ISelectOptions[], selectedFieldName: string) => {
    const fieldName = selectedFieldName;
    setPageIndex(1);
    setProductFilters((filter) => ({
      ...filter,
      [name]: values,
      [fieldName]: values.map((arr) => arr.value),
    }));
  };

  // Product search input keydown event.
  const onProductSearchHandleKeyDown = (event: any) => {
    const { value } = event.target;
    if (event.key === ENTER_KEY) {
      if (inputRef.current) inputRef.current.blur();
      setPageIndex(1);
      setSearchText(value?.trim());
      setSearchField(searchField.trim());
    }
  };

  // Product search textbox selected change event.
  const onProductSearchHandleChange = (value: any) => {
    if (value[0]) {
      oldSearchText.current = value[0].itemName;
      setSelectedItemId(value[0].itemId);
      setSearchText(value[0].itemName);
      setTypeheadUniqueFilter(value[0].itemGuid);
      setPageIndex(1);
      setSearchField(value[0].itemName);
      if (inputRef.current) inputRef.current.blur();
    }
  };

  // Product search textbox search input change event.
  const onProductSearchInputHandleChange = (value: any) => {
    if (value.trim().length >= SEARCH_MIN_CHAR_LENGTH && value.trim().length >= oldSearchText.current.length) {
      setSearchField(value);
    } else if (value.trim().length === 0) {
      setSearchField('');
      setSearchText('');
      setTypeheadUniqueFilter('');
    }
    oldSearchText.current = value;
    setTypeField(value);
    setSelectedItemId(0);
  };

  // Vendor search input keydown event.
  const onVendorHandleKeyDown = (event: any) => {
    if (event.key === ENTER_KEY) {
      if (inputVendorRef.current) inputVendorRef.current.blur();
      setSearchVendorField(searchVendorField.trim());
    }
  };

  // Vendor textbox selected change event.
  const onVendorHandleChange = (value: any) => {
    if (value[0]) {
      oldVendorSearchText.current = value[0].vendorName;
      setSearchVendorText(value[0].vendorName);
      setPageIndex(1);
      setSearchVendorField(value[0].vendorName);
      setProductFilters((filter) => ({
        ...filter,
        selectedVendors: value.map((arr: any) => arr.vendorId),
      }));
      if (inputVendorRef.current) inputVendorRef.current.blur();
    }
  };

  // Vendor textbox search input change event.
  const onVendorInputHandleChange = (value: any) => {
    if (value.trim().length >= SEARCH_MIN_CHAR_LENGTH) {
      /* Search call will be fired when character length greater than 2 */
      setSearchVendorField(value);
    } else if (value.trim().length === 0) {
      setSearchVendorField('');
      setSearchVendorText('');
      setProductFilters((filter) => ({
        ...filter,
        selectedVendors: [],
      }));
    }
  };

  // Vendor search trigger event.
  const onVendorHandleSearch = useCallback((query: string) => {
    const vendorSearchRequest: IvendorRequest = {
      vendorId: 0,
      searchText: query,
      subscriptionPlanId: 0,
      showDeactivateOnly: 0,
      pageSize,
      pageIndex: DEFAULT_PAGE_INDEX,
      sortField: 1,
      sortOrder: 1,
    };
    if (query.trim().length >= SEARCH_MIN_CHAR_LENGTH) {
      /* Search call will be fired when character length greater than 2 */
      dispatch(searchVendors(vendorSearchRequest));
    }
    oldVendorSearchText.current = query;
  }, []);

  // Sort header change event.
  const changeSortField = (field: number) => {
    let newSortOrder = SortOrderType.ASC;
    if (field === sortField) {
      newSortOrder = sortOrder === SortOrderType.ASC ? SortOrderType.DESC : SortOrderType.ASC;
    }
    setSortField(field);
    setSortOrder(newSortOrder);
  };

  // Filter toggle event.
  const onFilterHandle = () => {
    if (productFilters.productGroupOptions.length > 0) {
      setOpen(!open);
    }
  };

  // Clear filter method.
  const clearFilters = () => {
    setFilterCount(0);
    setSearchVendorField('');
    setSearchVendorText('');
    inputVendorRef.current?.clear();
    setProductFilters((filter) => ({
      ...filter,
      productGroups: [],
      selectedGroups: [],
      selectedVendors: [],
    }));
  };

  // Set filter label.
  const setFilterLabel = () => {
    if (filterCount > 0) {
      return `${filterCount} ${filterCount > 1 ? useIntlMessages('Filter.Applied.Multiple') : useIntlMessages('Filter.Applied.Single')}`;
    }
    return open ? useIntlMessages('Filter.Hide') : useIntlMessages('Filter.Show');
  };

  // Item details view click handler.
  const onItemViewHandler = (itemId: number) => {
    dispatch(getProductDetails(itemId));
    inventoryPop(true);
  };

  // Clear inventory details & close popup.
  const clearInventoryDetails = () => {
    inventoryPop(false);
    dispatch(resetProductState());
  };

  return (
    <div className="content-nav contnt-container-margin">
      <div className="back-navigation">
        <Breadcrumb>
          <Breadcrumb.Item href="#" onClick={() => navigate('/home')}>
            <FormattedMessage id="Menu.Home" />
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
            <FormattedMessage id="Product.PageHD" />
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="content-nav-sub">
        <div className="content-nav-header">
          <div className="d-xl-flex justify-content-between align-items-center">
            <h2>
              <FormattedMessage id="ProductList.HD" />
            </h2>
            <a href="#" onClick={() => navigate('/products/mappedItems')}>
              <FormattedMessage id="ProductList.SimilarMapping" />
            </a>
          </div>
        </div>
        <div className="content-filter-main border-0 pb-5">
          <Row>
            <Col xs={12} md={6} lg={5} xl={3} className="no-margin search-auto">
              <Typeahead
                id="productSearchInput"
                ref={inputRef}
                onInputChange={onProductSearchInputHandleChange}
                onChange={onProductSearchHandleChange}
                onKeyDown={onProductSearchHandleKeyDown}
                options={searchProductList}
                placeholder={useIntlMessages('Product.Filter.Search.Placeholder')}
                selected={searchProductList.filter((x: any) => x.itemId === selectedItemId)}
                filterBy={['itemId', 'itemName']}
                minLength={oldSearchText.current.length > typeField.length ? oldSearchText.current.length : SEARCH_MIN_CHAR_LENGTH}
                labelKey={(item: any) => `${item.itemName}`}
                inputProps={{
                  maxLength: INPUT_MAX_LENGTH_100,
                }}
                renderMenuItemChildren={(item: any) => (
                  <div>
                    {item.itemName}
                    <div>
                      <small>{item.vendorName}</small>
                    </div>
                  </div>
                )}
              >
                <Search />
              </Typeahead>
            </Col>

            <Col className="d-inline-flex me-4">
              <Button variant="link" onClick={onFilterHandle} aria-expanded={open} className="btn-filter">
                <span className="mb-0 me-2">
                  <Filter />
                </span>
                {setFilterLabel()}
                <span>
                  <Arrow />
                </span>
              </Button>
            </Col>
          </Row>

          <Collapse in={open}>
            <div className="table-filter py-0">
              <h4 className="mt-4 mb-5">
                <FormattedMessage id="Filter.Product" />
              </h4>
              <Col xl={8} lg={10} md={12} className="mb-5">
                <Row className="mt-mx-lg-top align-items-center mb-4">
                  <Col xl={2} lg={3} md={4}>
                    <FormattedMessage id="Filter.Vendor" />
                  </Col>
                  <Col xs={12} md={6} lg={5} xl={5} className="no-margin search-auto no-label-input ms-4 permission-settings inline-checkbox">
                    <AsyncTypeahead
                      id="vendorSearch"
                      ref={inputVendorRef}
                      onInputChange={onVendorInputHandleChange}
                      onSearch={onVendorHandleSearch}
                      onChange={onVendorHandleChange}
                      onKeyDown={onVendorHandleKeyDown}
                      options={searchVendorList}
                      placeholder={useIntlMessages('Vendor.SearchVendor.Placeholder')}
                      filterBy={['vendorName']}
                      minLength={SEARCH_MIN_CHAR_LENGTH}
                      maxResults={pageSize}
                      labelKey={(vendor: any) => `${vendor.vendorName}`}
                      renderMenuItemChildren={(vendor: any) => <div>{vendor.vendorName}</div>}
                      clearButton
                      paginate
                      isLoading={false}
                      inputProps={{
                        maxLength: INPUT_MAX_LENGTH_100,
                      }}
                    >
                      <Search />
                    </AsyncTypeahead>
                  </Col>
                </Row>
                <Row className="mt-mx-lg-top align-items-center">
                  <Col xl={2} lg={3} md={4}>
                    <FormattedMessage id="Filter.ProductGroup" />
                  </Col>
                  <Col className="no-margin no-label-input ms-4 permission-settings inline-checkbox drop-min-225 drop-max-350">
                    <Select
                      id="productGroups"
                      type="productGroups"
                      name="productGroups"
                      options={productFilters.productGroupOptions}
                      placeholder={useIntlMessages('Product.Select.Group.PlaceHolder')}
                      dropdownGap={0}
                      values={productFilters.productGroups}
                      dropdownPosition="auto"
                      isMandatory={false}
                      errorMessage=""
                      className=""
                      multi
                      onChange={(values) => onSelectChange('productGroups', values, 'selectedGroups')}
                    />
                  </Col>
                </Row>
              </Col>
              <Button variant="outline-secondary" onClick={clearFilters} disabled={filterCount <= 0}>
                <FormattedMessage id="Filter.Clear" />
              </Button>
            </div>
          </Collapse>
        </div>
        <Table responsive>
          <thead>
            <tr>
              <th className="w-350" onClick={() => changeSortField(ProductSortField.ITEM_NAME)} style={{ cursor: 'pointer' }}>
                <FormattedMessage id="TH.ItemName" />
                {sortField !== ProductSortField.ITEM_NAME && <UpDownArrow />}
                {sortField === ProductSortField.ITEM_NAME && sortOrder === SortOrderType.DESC && <DownArrow />}
                {sortField === ProductSortField.ITEM_NAME && sortOrder === SortOrderType.ASC && <UpArrow />}
              </th>
              <th className="w-350" onClick={() => changeSortField(ProductSortField.VENDOR_NAME)} style={{ cursor: 'pointer' }}>
                <FormattedMessage id="TH.Vendor" />
                {sortField !== ProductSortField.VENDOR_NAME && <UpDownArrow />}
                {sortField === ProductSortField.VENDOR_NAME && sortOrder === SortOrderType.DESC && <DownArrow />}
                {sortField === ProductSortField.VENDOR_NAME && sortOrder === SortOrderType.ASC && <UpArrow />}
              </th>
              <th className="w-200" onClick={() => changeSortField(ProductSortField.PRODUCT_GROUP)} style={{ cursor: 'pointer' }}>
                <FormattedMessage id="TH.ProductGroup" />
                {sortField !== ProductSortField.PRODUCT_GROUP && <UpDownArrow />}
                {sortField === ProductSortField.PRODUCT_GROUP && sortOrder === SortOrderType.DESC && <DownArrow />}
                {sortField === ProductSortField.PRODUCT_GROUP && sortOrder === SortOrderType.ASC && <UpArrow />}
              </th>
              <th className="w-120">
                <FormattedMessage id="TH.Pack" />
              </th>
              <th className="w-200">
                <FormattedMessage id="TH.Size" />
              </th>
              <th onClick={() => changeSortField(ProductSortField.PRICE)} style={{ cursor: 'pointer' }}>
                <FormattedMessage id="TH.Price" />
                {sortField !== ProductSortField.PRICE && <UpDownArrow />}
                {sortField === ProductSortField.PRICE && sortOrder === SortOrderType.DESC && <DownArrow />}
                {sortField === ProductSortField.PRICE && sortOrder === SortOrderType.ASC && <UpArrow />}
              </th>
            </tr>
          </thead>
          <tbody>
            {products &&
              products.map((item: IItem) => (
                <tr key={item.itemId}>
                  <td>
                    <a href="#" onClick={() => onItemViewHandler(item.itemId)}>
                      {item.itemName}
                    </a>
                  </td>
                  <td>{item.vendorName}</td>
                  <td>{item.productGroupName}</td>
                  <td>{item.pack}</td>
                  <td>{`${item.size ? item.size.toFixed(2) : 0} ${item.unitName}`}</td>
                  <td>{`$${item.price ? formatNumberWithCommas(item.price) : 0}${item.isVariableWeight ? '/lb' : ''}`}</td>
                </tr>
              ))}
            {!products ||
              (products && products.length === 0 && (
                <tr>
                  <td colSpan={7} className="text-center">
                    <FormattedMessage id="Product.table.emptymessage" />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <div className="content-filter-main d-lg-flex justify-content-lg-between">
          {products && products.length > 0 && (
            <p className="m-0">
              {stringFormat(
                totalCount === 1 ? useIntlActionMessages('Product.Pagination.SingleItem.Text') : useIntlActionMessages('Product.Pagination.Text'),
                products.length > 0 ? ((totalCount === 1 ? 1 : pageIndex) - 1) * pageSize + 1 : 0,
                ((totalCount === 1 ? 1 : pageIndex) - 1) * pageSize + products.length,
                totalCount,
              )}
            </p>
          )}
          {products.length > 0 && totalCount > pageSize && (
            <Pagination totalCount={totalCount} pageLimit={pageSize} setCurrentPage={(page: number) => setPageIndex(page)} currentPage={pageIndex - 1} prevPage={-1} />
          )}
        </div>
      </div>
      {isLoading && <GifLoader />}
      {/* Inventory */}
      {productDetails && (
        <Modal show={inventory} onHide={() => clearInventoryDetails()} centered size="xl">
          <Modal.Header closeButton />
          <Modal.Body>
            <StockPop item={productDetails} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary min-w-200 ms-4" onClick={() => clearInventoryDetails()}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default OrderList;
