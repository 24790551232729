/**
 * @file   Source\MadChef-Web\src\components\Timer.tsx
 * @author ZCO Engineer
 * @date   AUG, 2023
 * @brief  This file is responsible for display timer while clicking otp login.
 *
 */

import React, { useState, useEffect } from 'react';
import { IOtpTimer } from '../interfaces/generalInterface';
import { DEFAULT_OTP_TIMER } from '../utils/constants';

const Timer = ({ timeLimit, isActive, handleOtpLink }: IOtpTimer) => {
  const [seconds, setSeconds] = useState<number>(timeLimit ? timeLimit - 1 : DEFAULT_OTP_TIMER - 1);
  useEffect(() => {
    let interval: any = null;
    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds((second) => second - 1);
      }, 1000);
    } else {
      clearInterval(interval);
      handleOtpLink(false);
      if (seconds <= 0) setSeconds(timeLimit);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  return (
    <div className="time">
      {/* Show timer string in the format mm:ss based on the seconds value */}
      {'Resend OTP in '}
      <span className="text-red">{new Date(seconds * 1000).toISOString().slice(14, 19)}</span>
    </div>
  );
};

export default Timer;
