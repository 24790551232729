import React from 'react';

const PMIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.11988 19.6199C7.94988 19.6199 8.61988 20.2899 8.61988 21.1199C8.61988 21.9499 7.94988 22.6199 7.11988 22.6199C6.28988 22.6199 5.61988 21.9499 5.61988 21.1199C5.61988 20.2899 6.28988 19.6199 7.11988 19.6199ZM20.1199 19.6199C20.9499 19.6199 21.6199 20.2899 21.6199 21.1199C21.6199 21.9499 20.9499 22.6199 20.1199 22.6199C19.2899 22.6199 18.6199 21.9499 18.6199 21.1199C18.6199 20.2899 19.2899 19.6199 20.1199 19.6199ZM3.78988 1.37988L7.61988 5.20988V16.6199H21.6199V18.6199H6.61988C6.06988 18.6199 5.61988 18.1699 5.61988 17.6199V6.02988L2.37988 2.78988L3.78988 1.37988Z"
        fill="#63707E"
      />
      <path
        d="M13.9098 3.24012C14.6898 3.08012 15.4998 3.08012 16.2798 3.24012C16.3898 3.94012 16.7998 4.55012 17.4098 4.90012C18.0198 5.25012 18.7598 5.31012 19.4198 5.05012C19.9498 5.65012 20.3598 6.35012 20.6098 7.11012C20.0598 7.55012 19.7398 8.22012 19.7398 8.92012C19.7398 9.65012 20.0798 10.3101 20.6098 10.7301C20.3598 11.4901 19.9598 12.1901 19.4198 12.7901C18.7598 12.5301 18.0298 12.5901 17.4198 12.9401C16.8098 13.2901 16.3898 13.9001 16.2898 14.6001C15.5098 14.7601 14.6998 14.7701 13.9198 14.6001C13.8098 13.9001 13.3998 13.2901 12.7898 12.9401C12.1798 12.5901 11.4398 12.5301 10.7798 12.7901C10.2498 12.1901 9.83984 11.4901 9.58984 10.7301C10.1398 10.2901 10.4598 9.62012 10.4598 8.92012C10.4598 8.21012 10.1398 7.55012 9.58984 7.11012C9.83984 6.35012 10.2398 5.65012 10.7798 5.05012C11.4398 5.31012 12.1698 5.25012 12.7798 4.90012C13.3898 4.55012 13.8098 3.94012 13.9098 3.24012ZM15.0998 10.6601C16.0598 10.6601 16.8398 9.88012 16.8398 8.92012C16.8398 7.96012 16.0598 7.18012 15.0998 7.18012C14.1398 7.18012 13.3598 7.96012 13.3598 8.92012C13.3598 9.88012 14.1398 10.6601 15.0998 10.6601Z"
        fill="#63707E"
      />
    </svg>
  );
};

export default PMIcon;
