/**
 * @file   src\features\Home.tsx
 * @brief  Landing page.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import moment from 'moment-timezone';
import { FormattedMessage, Col, Row, Breadcrumb, moment as moment2, useState, useEffect, useNavigate } from '../../utils/thirdpartyComponents';
import Orders from './Orders';
import Transactions from './Transactions';
import VendorsAndRestaurants from './VendorsRestaurants';
import Select from '../../components/SelectLabel';
import { DateFilterOptions, GraphFilterOptions, Graphs, DateContext } from '../../utils/enums';
import TransactionAreaGraph from './TransactionAreaGraph';
import RestVendorGraph from './RestaurantVendorsGraph';
import OrderBarGraph from './OrdersBarGraph';
import { getOrdersGraphData, getTransactionsGraphData, getRestaurantVendorGraphData, getChartDateLimit } from '../../store/actions/dashboardActions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { RootState } from '../../store';
import { DATE_FORMAT2, MONTHS_SHORT_NAMES, DATE_FORMAT } from '../../utils/constants';
import Previous from '../../assets/icons/Prev';
import Next from '../../assets/icons/Next';

// Get start and end date
const getDates = (dateOption: string, state: string, indexCnt: number) => {
  let fromDateInitial = '';
  let toDateInitial = '';
  switch (true) {
    case dateOption === DateFilterOptions.DAILY && state === DateContext.CURRENT:
      fromDateInitial = moment2(new Date(new Date())).format(DATE_FORMAT2);
      toDateInitial = moment2(new Date()).format(DATE_FORMAT2);
      break;
    case dateOption === DateFilterOptions.THIS_WEEK && (state === DateContext.CURRENT || indexCnt === 0):
      fromDateInitial = moment2(new Date(moment2().startOf('week').toDate())).format(DATE_FORMAT2);
      toDateInitial = moment2(new Date()).format(DATE_FORMAT2);
      break;
    case dateOption === DateFilterOptions.THIS_MONTH && (state === DateContext.CURRENT || indexCnt === 0):
      fromDateInitial = moment2(new Date(moment2().startOf('month').toDate())).format(DATE_FORMAT2);
      toDateInitial = moment2(new Date()).format(DATE_FORMAT2);
      break;
    case dateOption === DateFilterOptions.YEAR && (state === DateContext.CURRENT || indexCnt === 0):
      fromDateInitial = moment2(new Date(moment2().startOf('year').toDate())).format(DATE_FORMAT2);
      toDateInitial = moment2(new Date()).format(DATE_FORMAT2);
      break;
    case dateOption === DateFilterOptions.THIS_WEEK && (state === DateContext.PREVIOUS || state === DateContext.NEXT):
      fromDateInitial = moment2(new Date(moment2().startOf('week').toDate()))
        .subtract(indexCnt, 'w')
        .format(DATE_FORMAT2);
      toDateInitial = moment2(new Date(moment2(fromDateInitial).endOf('week').toDate())).format(DATE_FORMAT2);
      break;
    case dateOption === DateFilterOptions.THIS_MONTH && (state === DateContext.PREVIOUS || state === DateContext.NEXT):
      fromDateInitial = moment2(new Date(moment2().startOf('month').toDate()))
        .subtract(indexCnt, 'M')
        .format(DATE_FORMAT2);
      toDateInitial = moment2(new Date(moment2(fromDateInitial).endOf('month').toDate())).format(DATE_FORMAT2);
      break;
    case dateOption === DateFilterOptions.YEAR && (state === DateContext.PREVIOUS || state === DateContext.NEXT):
      fromDateInitial = moment2(new Date(moment2().startOf('year').toDate()))
        .subtract(indexCnt, 'y')
        .format(DATE_FORMAT2);
      toDateInitial = moment2(new Date(moment2(fromDateInitial).endOf('year').toDate())).format(DATE_FORMAT2);
      break;

    default:
      fromDateInitial = '';
      toDateInitial = '';
  }

  const dateStringFrom = fromDateInitial.replace(/-/g, '/').replace('T', ' ');
  const dateStringTo = toDateInitial.replace(/-/g, '/').replace('T', ' ');
  const fromDateUTC = new Date(`${dateStringFrom} 00:00:00`).toISOString();
  const toDateUTC = new Date(`${dateStringTo} 23:59:59`).toISOString();
  return [fromDateUTC, toDateUTC];
};

// Date filter select box options
const SelectSort = [
  { label: 'Weekly', value: DateFilterOptions.THIS_WEEK },
  { label: 'Monthly', value: DateFilterOptions.THIS_MONTH },
  { label: 'Yearly', value: DateFilterOptions.YEAR },
];
const SelectSortValue = [{ label: 'Weekly', value: 'week' }];

const Home = () => {
  // Navigate object creation.
  const navigate = useNavigate();
  // Create action dispatch
  const dispatch = useAppDispatch();
  // Component state variables
  const [orderFilter, setOrderFilter] = useState<string>(DateFilterOptions.THIS_WEEK);
  const [transactionFilter, setTransactionFilter] = useState<string>(DateFilterOptions.THIS_WEEK);
  const [restVendorFilter, setRestVendorFilter] = useState<string>(DateFilterOptions.THIS_WEEK);
  const [graphSelected, setGraphSelected] = useState<string>('transactions');
  const [orderState, setOrderState] = useState<string>(DateContext.CURRENT);
  const [transactionState, setTransactionState] = useState<string>(DateContext.CURRENT);
  const [restVendorState, setRestVendorState] = useState<string>(DateContext.CURRENT);
  const [orderIndexCount, setOrderIndexCount] = useState<number>(0);
  const [transactionIndexCount, setTransactionIndexCount] = useState<number>(0);
  const [vendorIndexCount, setVendorIndexCount] = useState<number>(0);
  const [orderStartDate, orderEndDate] = getDates(orderFilter, orderState, orderIndexCount);
  const [transactionStartDate, transactionEndDate] = getDates(transactionFilter, transactionState, transactionIndexCount);
  const [restVendorStartDate, restVendorEndDate] = getDates(restVendorFilter, restVendorState, vendorIndexCount);
  const [minDateSignUp, setMinDateSignUp] = useState<string>('');
  const [minDateOrders, setMinDateOrders] = useState<string>('');
  const [minDateTransactions, setMinDateTransactions] = useState<string>('');

  // Access redux state variables
  const { orderGraphData, transactionGraphData, restaurantvendorGraphData } = useAppSelector((state: RootState) => state.dashboard);
  const { dateLimits } = useAppSelector((state: RootState) => state.dashboard);

  // Get chart type param for api from filter type
  const getChartType = (filter: string) => {
    let option;
    if (filter === DateFilterOptions.THIS_WEEK) {
      option = GraphFilterOptions.WEEKLY;
    } else if (filter === DateFilterOptions.THIS_MONTH) {
      option = GraphFilterOptions.MONTHLY;
    } else {
      option = GraphFilterOptions.YEARLY;
    }
    return option;
  };
  // call to api to get order graph data
  const fetchOrderGraphData = () => {
    const orderGraphRequest = {
      chartType: getChartType(orderFilter),
      fromDate: orderStartDate,
      toDate: orderEndDate,
      timezone: moment.tz.guess(),
    };
    dispatch(getOrdersGraphData(orderGraphRequest));
  };
  // call to api to get transaction graph data
  const fetchTransactionGraphData = () => {
    const transactionGraphRequest = {
      chartType: getChartType(transactionFilter),
      fromDate: transactionStartDate,
      toDate: transactionEndDate,
      timezone: moment.tz.guess(),
    };
    dispatch(getTransactionsGraphData(transactionGraphRequest));
  };
  // call to api to get vendor graph data
  const fetchRestVendorGraphData = () => {
    const restVendorGraphRequest = {
      chartType: getChartType(restVendorFilter),
      fromDate: restVendorStartDate,
      toDate: restVendorEndDate,
      timezone: moment.tz.guess(),
    };
    dispatch(getRestaurantVendorGraphData(restVendorGraphRequest));
  };

  // Component initial loading.
  useEffect(() => {
    const timeZone = moment.tz.guess();
    dispatch(getChartDateLimit(timeZone));
  }, []);
  // call transaction graph data api when date filter changes
  useEffect(() => {
    fetchTransactionGraphData();
  }, [transactionFilter, transactionIndexCount]);
  // call  order graph data api when date filter changes
  useEffect(() => {
    fetchOrderGraphData();
  }, [orderFilter, orderIndexCount]);
  // call restaurant vendor graph data api when date filter changes
  useEffect(() => {
    fetchRestVendorGraphData();
  }, [restVendorFilter, vendorIndexCount]);

  // Fetch date limit response state change.
  useEffect(() => {
    if (dateLimits) {
      setMinDateSignUp(dateLimits.minDateSignUp);
      setMinDateOrders(dateLimits.minDateOrders);
      setMinDateTransactions(dateLimits.minDateTransactions);
    }
  }, [dateLimits]);

  // get style class name for graph legend
  const getClassName = (filter: string) => {
    let className = '';
    switch (filter) {
      case DateFilterOptions.THIS_WEEK:
        className = 'graph-category graph-weekly';
        break;
      case DateFilterOptions.THIS_MONTH:
        className = 'graph-category graph-monthly';
        break;
      case DateFilterOptions.YEAR:
        className = 'graph-category graph-yearly';
        break;
      default:
        className = '';
        break;
    }
    return className;
  };
  // function that gives the week date range
  const getCurrentWeek = (arg: number) => {
    let fromDateInitial;
    let toDateInitial;
    if (arg <= 0) {
      fromDateInitial = moment2(new Date(moment2().startOf('week').toDate())).format(DATE_FORMAT);
      toDateInitial = moment2(new Date()).format(DATE_FORMAT);
    } else {
      fromDateInitial = moment2(new Date(moment2().startOf('week').toDate()))
        .subtract(arg, 'w')
        .format(DATE_FORMAT);
      toDateInitial = moment2(new Date(moment2(fromDateInitial).endOf('week').toDate())).format(DATE_FORMAT);
    }
    return `${fromDateInitial} - ${toDateInitial}`;
  };
  // function that gives the month
  const getMonth = (arg: number) => {
    const startingDate = moment2(new Date(moment2().startOf('month').toDate())).subtract(arg, 'M');
    const monthNo = startingDate.month();
    return `${MONTHS_SHORT_NAMES[monthNo]} ${startingDate.year()}`;
  };
  // function that gives the year
  const getYear = (arg: number) => {
    const date = new Date();
    const year = date.getFullYear();
    return year - arg;
  };
  // function that returns legend for the graphs
  const getLegend = (state: string, filter: string, indexCnt: number) => {
    let legend;
    switch (true) {
      case filter === DateFilterOptions.THIS_WEEK:
        legend = getCurrentWeek(indexCnt);
        break;
      case filter === DateFilterOptions.THIS_MONTH:
        legend = getMonth(indexCnt);
        break;
      case filter === DateFilterOptions.YEAR:
        legend = getYear(indexCnt);
        break;
      default:
        legend = '';
    }
    return legend;
  };

  return (
    <div className="content-nav contnt-container-margin">
      <div className="back-navigation">
        <Breadcrumb>
          <Breadcrumb.Item href="#" onClick={() => navigate('/home')}>
            <FormattedMessage id="Menu.Home" />
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
            <FormattedMessage id="Menu.Dashboard" />
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="content-nav-sub mb-4">
        <div className="content-nav-header d-flex justify-content-between align-items-center">
          <h2>
            <FormattedMessage id="Menu.Dashboard" />
          </h2>
        </div>
        <div className="content-container-padding">
          <Row className="dash-details">
            <Col className="position-relative col" sm={12} md={6} lg={6} xl={4}>
              <div className="form-sm d-flex justify-content-between align-items-center no-margin mb-5 chart-header position-absolute">
                <h4 className="text-dark">
                  <FormattedMessage id="Dashboard.Transactions" />
                </h4>
                <div className="drop-min-150">
                  <Select
                    id="pauseTime"
                    type="select"
                    name="pauseTime"
                    options={SelectSort}
                    placeholder="Pause Time"
                    dropdownGap={0}
                    values={SelectSortValue}
                    dropdownPosition="auto"
                    isMandatory={false}
                    errorMessage=""
                    className=""
                    onChange={(event) => {
                      setTransactionFilter(event[0].value);
                      setTransactionIndexCount(0);
                    }}
                  />
                </div>
              </div>
              <Col className={graphSelected === Graphs.TRANSACTIONS ? 'active' : ''} onClick={() => setGraphSelected(Graphs.TRANSACTIONS)} style={{ cursor: 'pointer' }}>
                <TransactionAreaGraph graphData={transactionGraphData} filter={transactionFilter} />
              </Col>
              <div className="d-flex graph-btn-nav align-items-center">
                <Col>
                  <p className={getClassName(transactionFilter)}>
                    <span>{getLegend(transactionState, transactionFilter, transactionIndexCount)}</span>
                  </p>
                </Col>
                <Col className="text-end next_prev">
                  <a
                    href="#"
                    onClick={(event) => {
                      event.preventDefault();
                      setTransactionState(DateContext.PREVIOUS);
                      setTransactionIndexCount(transactionIndexCount + 1);
                    }}
                    className={moment(transactionStartDate).isAfter(moment(minDateTransactions)) ? 'pe-4' : 'pe-4 disabled-link'}
                  >
                    <Previous />
                    <FormattedMessage id="PageIndex.Button.Previous" />
                  </a>
                  <a
                    href="#"
                    onClick={(event) => {
                      event.preventDefault();
                      setTransactionState(DateContext.NEXT);
                      setTransactionIndexCount(transactionIndexCount > 0 ? transactionIndexCount - 1 : 0);
                    }}
                    className={transactionIndexCount <= 0 ? 'disabled-link' : ''}
                  >
                    <FormattedMessage id="PageIndex.Button.Next" />
                    <Next />
                  </a>
                </Col>
              </div>
            </Col>
            <Col className="position-relative col" sm={12} md={6} lg={6} xl={4}>
              <div className="form-sm d-flex justify-content-between align-items-center no-margin mb-5 chart-header position-absolute">
                <h4 className="text-dark">
                  <FormattedMessage id="Dashboard.Orders" />
                </h4>
                <div className="drop-min-150">
                  <Select
                    id="pauseTime"
                    type="select"
                    name="pauseTime"
                    options={SelectSort}
                    placeholder="Pause Time"
                    dropdownGap={0}
                    values={SelectSortValue}
                    dropdownPosition="auto"
                    isMandatory={false}
                    errorMessage=""
                    className=""
                    onChange={(event) => {
                      setOrderFilter(event[0].value);
                      setOrderIndexCount(0);
                    }}
                  />
                </div>
              </div>
              <Col className={graphSelected === Graphs.ORDERS ? 'active' : ''} onClick={() => setGraphSelected(Graphs.ORDERS)} style={{ cursor: 'pointer' }}>
                <OrderBarGraph graphData={orderGraphData} filter={orderFilter} />
              </Col>
              <div className="d-flex graph-btn-nav align-items-center">
                <Col>
                  <p className={getClassName(orderFilter)}>
                    <span>{getLegend(orderState, orderFilter, orderIndexCount)}</span>
                  </p>
                </Col>
                <Col className="text-end next_prev">
                  <a
                    href="#"
                    onClick={(event) => {
                      event.preventDefault();
                      setOrderState(DateContext.PREVIOUS);
                      setOrderIndexCount(orderIndexCount + 1);
                    }}
                    className={moment(orderStartDate).isAfter(moment(minDateOrders)) ? 'pe-3' : 'pe-3 disabled-link'}
                  >
                    <Previous />
                    <FormattedMessage id="PageIndex.Button.Previous" />
                  </a>
                  <a
                    href="#"
                    onClick={(event) => {
                      event.preventDefault();
                      setOrderState(DateContext.NEXT);
                      setOrderIndexCount(orderIndexCount > 0 ? orderIndexCount - 1 : 0);
                    }}
                    className={`${orderIndexCount <= 0 ? 'disabled-link' : ''}`}
                  >
                    <FormattedMessage id="PageIndex.Button.Next" />
                    <Next />
                  </a>
                </Col>
              </div>
            </Col>
            <Col className="position-relative col" sm={12} md={6} lg={6} xl={4}>
              <div className="form-sm d-flex justify-content-between align-items-center no-margin mb-5 chart-header position-absolute">
                <h4 className="text-dark">
                  <FormattedMessage id="Dashboard.Graph.Newrestaurants" />
                </h4>
                <div className="drop-min-150">
                  <Select
                    id="pauseTime"
                    type="select"
                    name="pauseTime"
                    options={SelectSort}
                    placeholder="Pause Time"
                    dropdownGap={0}
                    values={SelectSortValue}
                    dropdownPosition="auto"
                    isMandatory={false}
                    errorMessage=""
                    className=""
                    onChange={(event) => {
                      setRestVendorFilter(event[0].value);
                      setVendorIndexCount(0);
                    }}
                  />
                </div>
              </div>
              <Col className={graphSelected === Graphs.RESTVENDORS ? 'active' : ''} onClick={() => setGraphSelected(Graphs.RESTVENDORS)} style={{ cursor: 'pointer' }}>
                <RestVendorGraph graphData={restaurantvendorGraphData} filter={restVendorFilter} />
              </Col>
              <div className="d-flex graph-btn-nav align-items-center">
                <Col>
                  <p className={getClassName(restVendorFilter)}>
                    <span>{getLegend(restVendorState, restVendorFilter, vendorIndexCount)}</span>
                  </p>
                </Col>
                <Col className="text-end next_prev">
                  <a
                    href="#"
                    onClick={(event) => {
                      event.preventDefault();
                      setRestVendorState(DateContext.PREVIOUS);
                      setVendorIndexCount(vendorIndexCount + 1);
                    }}
                    className={moment(restVendorStartDate).isAfter(moment(minDateSignUp)) ? 'pe-4' : 'pe-4 disabled-link'}
                  >
                    <Previous />
                    <FormattedMessage id="PageIndex.Button.Previous" />
                  </a>
                  <a
                    href="#"
                    onClick={(event) => {
                      event.preventDefault();
                      setRestVendorState(DateContext.NEXT);
                      setVendorIndexCount(vendorIndexCount > 0 ? vendorIndexCount - 1 : 0);
                    }}
                    className={`${vendorIndexCount <= 0 ? 'disabled-link' : ''}`}
                  >
                    <FormattedMessage id="PageIndex.Button.Next" />
                    <Next />
                  </a>
                </Col>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {/* Orders Tab */}
      {graphSelected === Graphs.ORDERS && <Orders />}

      {/* Transaction Tab */}
      {graphSelected === Graphs.TRANSACTIONS && <Transactions />}

      {/* New Restaurants & Vendors Tab */}
      {graphSelected === Graphs.RESTVENDORS && <VendorsAndRestaurants />}
    </div>
  );
};

export default Home;
