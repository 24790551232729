/**
 * @file   src\components\InputLabel.tsx
 * @brief  Input with label component.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import '../styles/InputLabel.scss';
import { InputProperty } from '../interfaces/generalInterface';

const InputLabel = ({
  onChange,
  onBlur,
  onFocus,
  onKeyPress,
  label,
  isMandatory,
  errorMessage,
  value,
  id,
  name,
  type,
  accept,
  pattern,
  placeholder,
  disabled,
  maxLength,
  step,
}: InputProperty) => {
  // const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
  //   if (onBlur) onBlur(e);
  // };

  // const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
  //   if (onFocus) onFocus(e);
  // };

  return (
    <div className="form-group">
      <div className="cust-label-group">
        <label htmlFor="label">
          {label}
          {isMandatory && <span className="mandatory">*</span>}
        </label>
        <input
          className={errorMessage && errorMessage !== '' ? 'form-control error' : value !== '' ? 'form-control active' : 'form-control'}
          id={id}
          name={name}
          type={type}
          accept={accept}
          value={value}
          pattern={pattern}
          placeholder={placeholder}
          disabled={disabled}
          maxLength={maxLength}
          onChange={(event) => onChange && onChange(event)}
          onBlur={(event) => onBlur && onBlur(event)}
          step={step}
          onFocus={(event) => onFocus && onFocus(event)}
          onKeyDown={(event) => onKeyPress && onKeyPress(event)}
        />
      </div>
      {errorMessage && errorMessage !== '' && <span className="error">{errorMessage}</span>}
    </div>
  );
};

export default InputLabel;
