/**
 * @file   src\components\PasswordIcon.tsx
 * @brief  Html password component.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React from 'react';
import '../styles/PasswordIcon.scss';
import { InputProperty } from '../interfaces/generalInterface';

interface PassowrdIconInterface extends InputProperty {
  onChange: (str: React.ChangeEvent<HTMLInputElement>) => void;
  showpass: JSX.Element;
}

const PassowrdIcon = ({ onChange, id, name, type, value, placeholder, disabled, maxLength, icon, showpass, errorMessage }: PassowrdIconInterface) => {
  const onIconChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e);
  };
  return (
    <div className="form-group">
      <div className="pass-ic-group position-relative">
        <input
          className={errorMessage && errorMessage !== '' ? 'form-control error' : value && value !== '' ? 'form-control active' : 'form-control'}
          id={id}
          name={name}
          type={type}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          maxLength={maxLength}
          onChange={(event) => onIconChange(event)}
        />
        <span className="icon-wrap">{icon}</span>
        <span className="icon-pass">{showpass}</span>
        {errorMessage && errorMessage !== '' && <span className="error">{errorMessage}</span>}
      </div>
    </div>
  );
};

export default PassowrdIcon;
