/**
 * @file   src\features\GenericNotFound.tsx
 * @brief  404 page.
 * @date   May, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { Button, useNavigate, FormattedMessage } from '../utils/thirdpartyComponents';

const GenericNotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="content-nav contnt-container-margin not-found-wrap">
      <div className="content-nav-sub not-found">
        <h3>
          <FormattedMessage id="ErrorPage.NotFound.Heading" />
        </h3>
        <p>
          <FormattedMessage id="ErrorPage.NotFound.Description" />
        </p>
        <Button variant="primary" className="mt-2 mb-2" onClick={() => navigate('/')}>
          <FormattedMessage id="Generic.Back.Login" />
        </Button>
      </div>
    </div>
  );
};

export default GenericNotFound;
