/**
 * @file   src\store\actions\commonAction.ts
 * @brief  This file is responsible for creating asynchronous api call for auth slice.
 * @date   May, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../config/axiosConfig';
import { CommonApi } from '../../utils/apiUrls';
import { IUserSubscriptionRequest } from '../../interfaces/generalInterface';

// Get all plans api call.
export const getPlans = createAsyncThunk('/common/getPlans', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(CommonApi.SUBSCRIPTION_PLANS);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// List all user subscription api call.
export const getUserSubscriptions = createAsyncThunk('/common/getUserSubscriptions', async (request: IUserSubscriptionRequest, { rejectWithValue }) => {
  try {
    let param = '';
    param += `userId=${request.userId}&`;
    param += `fromDate=${request.startDate}&`;
    param += `toDate=${request.endDate}&`;
    param += `pageSize=${request.pageSize}&`;
    param += `pageIndex=${request.pageIndex}&`;
    param += `sortField=${request.sortField}&`;
    param += `sortOrder=${request.sortOrder}&`;
    param += `userType=${request.userType}`;
    const { data } = await axios.get(`${CommonApi.USER_SUBSCRPTIONS}?${param}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});
