/**
 * @file   src\features\vendor\Add.tsx
 * @brief  Vendor add page.
 * @date   May, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { useEffect, useState, FormattedMessage, Col, Row, Breadcrumb, Button, ToggleButtonGroup, ToggleButton, useRef, useNavigate } from '../../utils/thirdpartyComponents';
import { useIntlMessages, setCutOffTimeIntervals, useIntlActionMessages } from '../../utils/helper';
import InputLabel from '../../components/InputLabel';
import InputNumeric from '../../components/InputNumeric';
import InputMaskLabel from '../../components/InputMaskLabel';
import Select from '../../components/SelectLabel';
import BackIcon from '../../assets/icons/Back';
import LocationSearch from '../../components/LocationSearch';
import SearchIcon from '../../assets/icons/Search';
import SearchRemoveIcon from '../../assets/icons/SearchRemove';
import { IVendorAddEdit, IVendorAddEditRequest } from '../../interfaces/vendorInterface';
import {
  DEFAULT_VENDOR_PRICE_TIER,
  DEFAULT_CUTOFFTIME_VALUES,
  ADMIN_EMAIL,
  BUSINESS_EMAIL,
  INPUT_MAX_LENGTH_20,
  INPUT_MAX_LENGTH_100,
  INPUT_MAX_LENGTH_10,
} from '../../utils/constants';
import { VENDOR_ADD_EDIT_FORM_SCHEMA } from '../../validations/vendorSchema';
import { validateForm } from '../../utils/formValidation';
import { getPlans } from '../../store/actions/commonAction';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { RootState } from '../../store';
import { IPlan, ISelectOptions } from '../../interfaces/generalInterface';
import { saveVendor } from '../../store/actions/vendorAction';
import { CountryCodes } from '../../utils/enums';
import { MessageToaster } from '../../utils/toastUtil';
import GifLoader from '../../components/GifLoader';
import { resetVendorState } from '../../store/slices/vendorSlice';

// Vendor component form default state.
const vendorInitialData: IVendorAddEdit = {
  vendorName: '',
  address: '',
  zip: '',
  city: '',
  state: '',
  stateCode: '',
  businessEmail: '',
  businessPhone: '',
  minDeliveryAmt: 0,
  deliveryDays: [],
  cutOffTimeData: DEFAULT_CUTOFFTIME_VALUES,
  cutOffTime: '',
  cutOffOptions: DEFAULT_CUTOFFTIME_VALUES,
  pricingTiers: DEFAULT_VENDOR_PRICE_TIER,
  tierOptions: DEFAULT_VENDOR_PRICE_TIER,
  selectedTier: 0,
  location: {},
  locationChange: false,
  formattedAddress: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
};

// Toast object creation.
const toast = new MessageToaster();

const VendorAdd = () => {
  // Navigate object creation.
  const navigate = useNavigate();
  // Declare action dispatch.
  const dispatch = useAppDispatch();
  // Declare ref variable for error fields.
  const errRef = useRef<any>(null);
  // Access redux state variables.
  const { isSuccess, plans } = useAppSelector((state: RootState) => state.common);
  const { saveVendorSuccess, saveVendorLoading, errorCode, errorMessage } = useAppSelector((state: RootState) => state.vendor);
  // Initialize component state variables
  const [vendorInfo, setVendorInfo] = useState<IVendorAddEdit>(vendorInitialData);
  const [errorFields, setErrorFields] = useState<any>({});

  // Handle initial loading sideEffects.
  useEffect(() => {
    dispatch(getPlans());
    (async () => {
      const cutOffTimeResult = await setCutOffTimeIntervals();
      cutOffTimeResult.unshift(DEFAULT_CUTOFFTIME_VALUES[0]);
      setVendorInfo((info) => ({
        ...info,
        cutOffOptions: cutOffTimeResult,
      }));
    })();
    return () => {
      dispatch(resetVendorState());
    };
  }, [dispatch]);

  // Clear add form fields.
  const clearForm = () => {
    setVendorInfo((info) => ({
      ...info,
      vendorName: '',
      address: '',
      zip: '',
      city: '',
      state: '',
      stateCode: '',
      businessEmail: '',
      businessPhone: '',
      minDeliveryAmt: 0,
      deliveryDays: [],
      cutOffTimeData: DEFAULT_CUTOFFTIME_VALUES,
      cutOffTime: '',
      pricingTiers: DEFAULT_VENDOR_PRICE_TIER,
      selectedTier: 0,
      location: {},
      locationChange: false,
      formattedAddress: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    }));
  };

  // Add vendor api response state change.
  useEffect(() => {
    if (saveVendorSuccess && errorCode === 0 && errorMessage) {
      clearForm();
      toast.toastSuccess(errorMessage);
      navigate('/vendors');
    } else if (errorCode > 0 && errorMessage) {
      toast.toastError(errorMessage);
      dispatch(resetVendorState());
    }
  }, [saveVendorSuccess, errorCode]);

  // Map plans to select options.
  const mapTiers = (input: IPlan): ISelectOptions => {
    return {
      label: input.planName,
      value: input.planId,
    };
  };

  // Handle fetch plans result state change.
  useEffect(() => {
    if (isSuccess) {
      const selectedPlans = plans.map((m) => mapTiers(m));
      selectedPlans.unshift(DEFAULT_VENDOR_PRICE_TIER[0]);
      setVendorInfo((info) => ({
        ...info,
        tierOptions: selectedPlans,
      }));
    }
  }, [isSuccess, plans]);

  // Clear location button click event.
  const onRemoveBtnClick = () => {
    setVendorInfo((info) => ({
      ...info,
      location: {},
      address: '',
      city: '',
      zip: '',
      state: '',
      stateCode: '',
      formattedAddress: '',
      locationChange: false,
    }));
  };

  // Location select event. This method used to update the location info to the location object.
  const onLocationSelect = async (place: any) => {
    setVendorInfo((info) => ({
      ...info,
      location: {
        lat: place.lat,
        lng: place.lng,
        address: place.address,
        city: place.city,
        state: place.state,
        stateCode: place.stateCode,
        zip: place.zip,
        name: place.name,
        locationName: place.name,
      },
      address: place.address,
      formattedAddress: place.address,
      city: place.city,
      zip: place.zip,
      state: place.state,
      stateCode: place.stateCode,
    }));
    const locationInfo = {
      address: place.address,
      city: place.city,
      zip: place.zip,
      state: place.state,
      stateCode: place.stateCode,
    };
    const errorresult = await validateForm(locationInfo, VENDOR_ADD_EDIT_FORM_SCHEMA, errRef.current);
    setErrorFields(errorresult);
  };

  // Location change event.
  const onLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const errorObj = { ...errorFields };
    setVendorInfo((info) => ({
      ...info,
      locationChange: value !== '',
      location: {},
      address: value,
      formattedAddress: value,
      city: '',
      zip: '',
      state: '',
      stateCode: '',
    }));
    errRef.current = errorObj;
  };

  // Input field change event.
  const onInputHandleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name !== ADMIN_EMAIL) {
      if (name === BUSINESS_EMAIL) {
        setVendorInfo((info) => ({
          ...info,
          [name]: value,
          [ADMIN_EMAIL]: value,
        }));
      } else {
        setVendorInfo((info) => ({
          ...info,
          [name]: value,
        }));
      }
      const validateObj = {
        [name]: value?.trim() || '',
      };
      const errorresult = await validateForm(validateObj, VENDOR_ADD_EDIT_FORM_SCHEMA, errorFields);
      setErrorFields(errorresult);
    }
  };

  // Phone input field change event.
  const onPhoneFieldHandleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const inputVal = value ? value.replace(/\D/g, '') : '';
    setVendorInfo((info) => ({
      ...info,
      [name]: inputVal,
    }));
    const validateObj = {
      [name]: inputVal?.trim() || '',
    };
    const errorresult = await validateForm(validateObj, VENDOR_ADD_EDIT_FORM_SCHEMA, errorFields);
    setErrorFields(errorresult);
  };

  // Delivery amount input change event.
  const onNumericChange = async (name: string, value: any) => {
    setVendorInfo((info) => ({
      ...info,
      [name]: value,
    }));
    const validateObj = {
      [name]: value,
    };
    const errorresult = await validateForm(validateObj, VENDOR_ADD_EDIT_FORM_SCHEMA, errorFields);
    setErrorFields(errorresult);
  };

  // Delivery days toggle event.
  const onToggleChange = async (val: any) => {
    setVendorInfo((info) => ({
      ...info,
      deliveryDays: val,
    }));
    const validateObj = {
      deliveryDays: val,
    };
    const errorresult = await validateForm(validateObj, VENDOR_ADD_EDIT_FORM_SCHEMA, errorFields);
    setErrorFields(errorresult);
  };

  // Select input change event.
  const onSelectChange = async (name: string, values: any, selectedFieldName: string) => {
    const fieldName: string = selectedFieldName;
    setVendorInfo((info) => ({
      ...info,
      [name]: values,
      [fieldName]: values[0]?.value,
    }));
    const validateObj = {
      [name]: values,
      [fieldName]: values[0]?.value,
    };
    const errorresult = await validateForm(validateObj, VENDOR_ADD_EDIT_FORM_SCHEMA, errorFields);
    setErrorFields(errorresult);
  };

  // Submit handler to add a new vendor.
  const onSubmit = async () => {
    const vendorValidationRequest: IVendorAddEdit = {
      vendorName: vendorInfo.vendorName,
      address: vendorInfo.address,
      zip: vendorInfo.zip,
      city: vendorInfo.city,
      state: vendorInfo.state,
      businessEmail: vendorInfo.businessEmail,
      businessPhone: vendorInfo.businessPhone,
      minDeliveryAmt: vendorInfo.minDeliveryAmt,
      deliveryDays: vendorInfo.deliveryDays,
      cutOffTimeData: vendorInfo.cutOffTimeData,
      cutOffTime: vendorInfo.cutOffTime,
      pricingTiers: vendorInfo.pricingTiers,
      selectedTier: vendorInfo.selectedTier,
      firstName: vendorInfo.firstName,
      lastName: vendorInfo.lastName,
      email: vendorInfo.email,
      phone: vendorInfo.phone,
    };
    const errorresult = await validateForm(vendorValidationRequest, VENDOR_ADD_EDIT_FORM_SCHEMA, errorFields);
    setErrorFields(errorresult);
    if (Object.keys(errorresult).length === 0) {
      if (Object.keys(vendorInfo.location).length > 0 && vendorInfo.location?.stateCode !== '') {
        const saveVendorRequest: IVendorAddEditRequest = {
          vendorId: 0,
          vendorName: vendorInfo.vendorName?.trim() || '',
          address: vendorInfo.address?.trim() || '',
          zip: vendorInfo.zip?.trim() || '',
          city: vendorInfo.city?.trim() || '',
          state: vendorInfo.state?.trim() || '',
          vendorEmail: vendorInfo.businessEmail?.trim() || '',
          phone: vendorInfo.businessPhone?.trim() || '',
          countryCode: CountryCodes.US,
          minDeliveryAmt: vendorInfo.minDeliveryAmt || 0,
          deliveryDays: vendorInfo.deliveryDays,
          cutOffTime: vendorInfo.cutOffTime,
          subscriptionPlanId: vendorInfo.selectedTier,
          adminFirstName: vendorInfo.firstName?.trim() || '',
          adminLastName: vendorInfo.lastName?.trim() || '',
          adminEmail: vendorInfo.email?.trim() || '',
          adminPhone: vendorInfo.phone?.trim() || '',
          adminCountryCode: CountryCodes.US,
          stateCode: vendorInfo.stateCode || '',
          latitude: vendorInfo.location?.lat,
          longitude: vendorInfo.location?.lng,
        };
        dispatch(saveVendor(saveVendorRequest));
      } else {
        toast.toastError(useIntlActionMessages('Vendor.Autocomplete.Select.Error'));
      }
    } else {
      setVendorInfo((info) => ({
        ...info,
        locationChange: false,
      }));
      toast.toastError(useIntlActionMessages('Enter.Mandatory'));
    }
  };

  return (
    <div className="content-nav contnt-container-margin">
      <div className="back-navigation">
        <Breadcrumb>
          <Breadcrumb.Item href="#" onClick={() => navigate('/home')}>
            <FormattedMessage id="Menu.Home" />
          </Breadcrumb.Item>
          <Breadcrumb.Item href="#" onClick={() => navigate('/vendors')}>
            <FormattedMessage id="Vendor.PageHD" />
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
            <FormattedMessage id="Button.AddVendor" />
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="content-nav-sub">
        <div className="content-nav-header d-flex justify-content-between align-items-center">
          <h2>
            <a href="#" className="btn-icon" onClick={() => navigate(-1)}>
              <BackIcon />
            </a>
            <FormattedMessage id="Button.AddVendor" />
          </h2>
        </div>
        <div className="content-container-padding">
          <h6>
            <FormattedMessage id="HD.BasicInformation" />
          </h6>
          <Row className="form-main">
            <Col lg="5">
              <InputLabel
                id="vendorName"
                name="vendorName"
                type="text"
                label={useIntlMessages('Vendor.VendorName')}
                placeholder={useIntlMessages('Vendor.Placeholder.Vendorname')}
                maxLength={INPUT_MAX_LENGTH_100}
                isMandatory
                value={vendorInfo.vendorName}
                onChange={(e) => onInputHandleChange(e)}
                errorMessage={errorFields?.vendorName}
              />
            </Col>
            <Col lg="5">
              <LocationSearch
                label={useIntlMessages('Vendor.Address')}
                id="address"
                name="address"
                types={['geocode', 'establishment']}
                placeholder={useIntlMessages('Vendor.Placeholder.Address')}
                values={vendorInfo.location}
                isMandatory
                address={vendorInfo.formattedAddress ? vendorInfo.formattedAddress : ''}
                locationChange={vendorInfo.locationChange ? vendorInfo.locationChange : false}
                icon={<SearchIcon />}
                closeicon={<SearchRemoveIcon onClick={() => onRemoveBtnClick()} value={vendorInfo.formattedAddress} />}
                onLocationSelect={(place: any) => onLocationSelect(place)}
                onChange={(e) => onLocationChange(e)}
                errorMessage={errorFields?.address}
              />
            </Col>
            <Col lg="5">
              <InputLabel
                id="city"
                name="city"
                type="text"
                label={useIntlMessages('Add.City')}
                placeholder={useIntlMessages('Vendor.Placeholder.City')}
                value={vendorInfo.city}
                maxLength={INPUT_MAX_LENGTH_100}
                isMandatory
                onChange={(e) => onInputHandleChange(e)}
                errorMessage={errorFields?.city}
                disabled
              />
            </Col>
            <Col lg="5">
              <InputLabel
                id="state"
                name="state"
                type="text"
                label={useIntlMessages('Add.State')}
                placeholder={useIntlMessages('Vendor.Placeholder.State')}
                value={vendorInfo.state}
                maxLength={INPUT_MAX_LENGTH_100}
                isMandatory
                onChange={(e) => onInputHandleChange(e)}
                errorMessage={errorFields?.state}
                disabled
              />
            </Col>
            <Col lg="5">
              <InputLabel
                id="zip"
                name="zip"
                type="text"
                label={useIntlMessages('Add.ZipCode')}
                placeholder={useIntlMessages('Vendor.Placeholder.Zip')}
                value={vendorInfo.zip}
                maxLength={INPUT_MAX_LENGTH_10}
                isMandatory
                onChange={(e) => onInputHandleChange(e)}
                errorMessage={errorFields?.zip}
              />
            </Col>
            <Col lg="5">
              <InputMaskLabel
                id="businessPhone"
                name="businessPhone"
                type="phone"
                mask="(999) 999-9999"
                label={useIntlMessages('Add.BusinessPhone')}
                placeholder={useIntlMessages('Vendor.Placeholder.BusinessPhone')}
                value={vendorInfo.businessPhone}
                maxLength={INPUT_MAX_LENGTH_20}
                isMandatory
                onChange={(e) => onPhoneFieldHandleChange(e)}
                errorMessage={errorFields?.businessPhone}
              />
            </Col>
            <Col lg="5">
              <InputLabel
                id="businessEmail"
                name="businessEmail"
                type="text"
                label={useIntlMessages('Add.BusinessEmail')}
                placeholder={useIntlMessages('Vendor.Placeholder.BusinessEmail')}
                value={vendorInfo.businessEmail}
                maxLength={INPUT_MAX_LENGTH_100}
                isMandatory
                onChange={(e) => onInputHandleChange(e)}
                errorMessage={errorFields?.businessEmail}
              />
            </Col>
          </Row>
          <Row className="mb-4 form-main">
            <Col lg="5" className="delivery-days">
              <InputNumeric
                id="minDeliveryAmt"
                precision={0}
                name="minDeliveryAmt"
                value={vendorInfo.minDeliveryAmt}
                step={10}
                currency="$"
                min={0}
                max={100000}
                title={useIntlMessages('Add.minAmount')}
                onChange={(value) => onNumericChange('minDeliveryAmt', value)}
                errorMessage={errorFields?.minDeliveryAmt}
              />
            </Col>
            <Col lg="5" className="delivery-days">
              <span className="inputlabel">
                <FormattedMessage id="Add.DeliveryDays" />
                <span className="text-red">*</span>
              </span>
              <ToggleButtonGroup
                type="checkbox"
                id="deliveryDays"
                name="deliveryDays"
                className={errorFields?.deliveryDays && errorFields?.deliveryDays !== '' ? 'error' : vendorInfo.deliveryDays.length > 0 ? 'active' : ''}
                value={vendorInfo.deliveryDays}
                onChange={onToggleChange}
              >
                <ToggleButton id="tbg-check-1" value={2} variant="success">
                  <FormattedMessage id="Day.Mon" />
                </ToggleButton>
                <ToggleButton id="tbg-check-2" value={3} variant="success">
                  <FormattedMessage id="Day.Tue" />
                </ToggleButton>
                <ToggleButton id="tbg-check-3" value={4} variant="success">
                  <FormattedMessage id="Day.Wed" />
                </ToggleButton>
                <ToggleButton id="tbg-check-4" value={5} variant="success">
                  <FormattedMessage id="Day.Thu" />
                </ToggleButton>
                <ToggleButton id="tbg-check-5" value={6} variant="success">
                  <FormattedMessage id="Day.Fri" />
                </ToggleButton>
                <ToggleButton id="tbg-check-6" value={7} variant="success">
                  <FormattedMessage id="Day.Sat" />
                </ToggleButton>
                <ToggleButton id="tbg-check-7" value={1} variant="success">
                  <FormattedMessage id="Day.Sun" />
                </ToggleButton>
              </ToggleButtonGroup>
              {errorFields?.deliveryDays && errorFields?.deliveryDays !== '' && <span className="error">{errorFields?.deliveryDays}</span>}
            </Col>
            <Col lg="5" className="no-icon-select">
              <Select
                id="cutOffTimeData"
                name="cutOffTimeData"
                type="select"
                options={vendorInfo.cutOffOptions}
                dropdownPosition="auto"
                label={useIntlMessages('Add.CutofTime')}
                placeholder={useIntlMessages('Add.CutofTime')}
                dropdownGap={0}
                values={vendorInfo.cutOffTimeData}
                onChange={(values) => onSelectChange('cutOffTimeData', values, 'cutOffTime')}
                isMandatory={false}
                className=""
                errorMessage={errorFields?.cutOffTime}
                searchable={false}
              />
            </Col>
            <Col lg={5}>
              <Select
                id="pricingTiers"
                name="pricingTiers"
                type="select"
                options={vendorInfo.tierOptions}
                dropdownGap={0}
                values={vendorInfo.pricingTiers}
                label={useIntlMessages('Add.PricingTyre')}
                placeholder={useIntlMessages('Add.PricingTyre')}
                dropdownPosition="auto"
                isMandatory
                errorMessage={errorFields?.selectedTier}
                className=""
                onChange={(values) => onSelectChange('pricingTiers', values, 'selectedTier')}
              />
            </Col>
          </Row>
          <h6>
            <FormattedMessage id="HD.AdminInformation" />
          </h6>
          <Row className="form-main">
            <Col lg="5">
              <InputLabel
                id="firstName"
                name="firstName"
                type="text"
                label={useIntlMessages('Add.FirstName')}
                placeholder={useIntlMessages('Vendor.Placeholder.Firstname')}
                value={vendorInfo.firstName}
                maxLength={INPUT_MAX_LENGTH_100}
                isMandatory
                onChange={(e) => onInputHandleChange(e)}
                errorMessage={errorFields?.firstName}
              />
            </Col>
            <Col lg="5">
              <InputLabel
                id="lastName"
                name="lastName"
                type="text"
                label={useIntlMessages('Add.LastName')}
                placeholder={useIntlMessages('Vendor.Placeholder.Lastname')}
                value={vendorInfo.lastName}
                maxLength={INPUT_MAX_LENGTH_100}
                isMandatory
                onChange={(e) => onInputHandleChange(e)}
                errorMessage={errorFields?.lastName}
              />
            </Col>
            <Col lg="5">
              <InputLabel
                id="email"
                name="email"
                type="text"
                label={useIntlMessages('Add.Email')}
                placeholder={useIntlMessages('Vendor.Placeholder.Email')}
                value={vendorInfo.email}
                disabled
                maxLength={INPUT_MAX_LENGTH_100}
                isMandatory
                onChange={(e) => onInputHandleChange(e)}
                errorMessage={errorFields?.email}
              />
            </Col>
            <Col lg="5">
              <InputMaskLabel
                id="phone"
                name="phone"
                type="phone"
                mask="(999) 999-9999"
                label={useIntlMessages('Add.Phone')}
                placeholder={useIntlMessages('Vendor.Placeholder.Phone')}
                value={vendorInfo.phone}
                maxLength={INPUT_MAX_LENGTH_20}
                isMandatory
                onChange={(e) => onPhoneFieldHandleChange(e)}
                errorMessage={errorFields?.phone}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} className="mt-4" />
            <Col lg={5} className="text-end mt-4">
              <Button variant="primary" onClick={onSubmit} disabled={Object.keys(errorFields).length > 0}>
                <FormattedMessage id="Button.Save" />
              </Button>
            </Col>
          </Row>
        </div>
        {saveVendorLoading && <GifLoader />}
      </div>
    </div>
  );
};

export default VendorAdd;
