/**
 * @file   src\features\onboarding\List.tsx
 * @brief  Onboarding listing page
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import {
  useState,
  useEffect,
  Link,
  Col,
  Row,
  Breadcrumb,
  Table,
  Modal,
  Button,
  FormattedMessage,
  useRef,
  createRef,
  moment,
  Typeahead,
  Badge,
  Dropdown,
  useNavigate,
} from '../../utils/thirdpartyComponents';
import { getDaysFromTwoDates, stringFormat, useIntlActionMessages, useIntlMessages } from '../../utils/helper';
import Select from '../../components/Select';
import SelectLabel from '../../components/SelectLabel';
import Pagination from '../../components/Paginate';
import SortIcon from '../../assets/icons/Sort';
import Search from '../../assets/icons/Search';
import Delete from '../../assets/icons/Delete';
import {
  DATE_FORMAT,
  DEFAULT_FILTER_STATUS,
  DEFAULT_ONBOARDING_MANAGER_OPTION,
  DEFAULT_ONBOARDING_MANAGER_SELECTION_OPTION,
  DEFAULT_PAGE_INDEX,
  DEFAULT_SORT_FIELD,
  DEFAULT_SUBSCRIPTION_TIER,
  ENTER_KEY,
  INPUT_MAX_LENGTH_100,
  PAGE_SIZE,
  SEARCH_MIN_CHAR_LENGTH,
} from '../../utils/constants';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { RootState } from '../../store';
import { assignManager, getOnboardingList, getOnboardingManagers, updateOnboardingStatus, deleteOnboardingUser, getOnboardingVendors } from '../../store/actions/onboardingAction';
import { IOnbaordingList, IOnboardingListRequest, IOnboardingManagers } from '../../interfaces/onboardingInterface';
import { OnboardingSelections, OnboardingSortField, OnboardingStatus, SortOrderType } from '../../utils/enums';
import { MessageToaster } from '../../utils/toastUtil';
import GifLoader from '../../components/GifLoader';
import { IPlan, ISelectOptions } from '../../interfaces/generalInterface';
import { getPlans } from '../../store/actions/commonAction';
import UpDownArrow from '../../assets/icons/UpDownArrow';
import DownArrow from '../../assets/icons/DownArrow';
import UpArrow from '../../assets/icons/UpArrow';
import Add from '../../assets/icons/Add';
import { resetOnboardingState } from '../../store/slices/onboardingSlice';

// Toast object creation.
const toast = new MessageToaster();
// Declare page item count to be displayed per page.
const pageItemCount: number = process.env.REACT_APP_LIST_PAGE_SIZE !== '' ? Number(process.env.REACT_APP_LIST_PAGE_SIZE) : PAGE_SIZE;
const Onboarding = () => {
  // Navigation object creation
  const navigate = useNavigate();

  // Initialize search field ref for onboarding vendor.
  const oldSearchText = useRef<string>('');

  // Create a ref on search input for onboarding vendor.
  const inputRef = createRef<any>();

  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Access redux state variables.
  const {
    isLoading,
    isSuccess,
    onboardingList,
    totalCount,
    errorCode,
    errorMessage,
    onboardingManagersSuccess,
    onboardingManagers,
    onboardingVendors,
    updateOnboardingStatusLoading,
    updateOnboardingStatusSuccess,
    updateOnboardingStatusErrorCode,
    updateOnboardingStatusErrorMessage,
    assignManagerLoading,
    assignManagerSuccess,
    assignManagerErrorCode,
    assignManagerErrorMessage,
    deleteOnboardingUserLoading,
    deleteOnboardingUserSuccess,
    deleteOnboardingUserErrorCode,
    deleteOnboardingUserErrorMessage,
  } = useAppSelector((state: RootState) => state.onboarding);
  const { isSuccess: planSuccess, plans } = useAppSelector((state: RootState) => state.common);

  // Initialize component state variables.
  const [onboardingData, setOnboardingData] = useState<IOnbaordingList[]>([]);
  const [searchField, setSearchField] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const [typeField, setTypeField] = useState<string>('');
  const [pageSize] = useState<number>(pageItemCount);
  const [pageIndex, setPageIndex] = useState<number>(DEFAULT_PAGE_INDEX);
  const [sortField, setSortField] = useState<number>(DEFAULT_SORT_FIELD);
  const [sortOrder, setSortOrder] = useState<number>(SortOrderType.ASC);
  const [selectedVendorId, setSelectedVendorId] = useState<string>('');
  const [subscriptionPlanId, setSubscriptionPlanId] = useState<number>(0);
  const [onboardingManagerOptions, setOnboardingManagerOptions] = useState<ISelectOptions[]>([]);
  const [onboardingManager, setOnboardingmanager] = useState<ISelectOptions[]>(DEFAULT_ONBOARDING_MANAGER_OPTION);
  const [onboardingStatusOptions, setOnboardingStatusOptions] = useState<ISelectOptions[]>([]);
  const [onboardingStatus, setOnboardingStatus] = useState<ISelectOptions[]>(DEFAULT_FILTER_STATUS);
  const [planList, setPlans] = useState<ISelectOptions[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<ISelectOptions[]>(DEFAULT_SUBSCRIPTION_TIER);
  const [managersDropdownOption, setManagersDropdownOptions] = useState<ISelectOptions[]>([]);
  const [onboardingSelectedVendor, setOnboardingSelectedVendor] = useState<string | null>(null);
  const [onboardingSelectedManager, setOnboardingSelectedManager] = useState<number>(0);
  const [onboardingSelectedStatus, setOnboardingSelectedStatus] = useState<number>(0);
  const [assignManagerConfirm, setManagerConfirmPopup] = useState<boolean>(false);
  const [updateStatusConfirm, setUpdateStatusConfirmPopup] = useState<boolean>(false);
  const [deleteOnboardingUserConfirmPopup, setDeleteOnboardingUserConfirmPopup] = useState<boolean>(false);

  // Method to fetch onboarding data.
  const fetchOnboardingList = () => {
    const onboardingFilterRequest: IOnboardingListRequest = {
      vendorId: selectedVendorId,
      subscriptionPlanId,
      onboardingManagerId: onboardingManager[0].value,
      onboardingStatus: onboardingStatus[0].value,
      searchText: selectedVendorId !== '' ? '' : searchText,
      pageSize,
      pageIndex,
      sortField,
      sortOrder,
    };
    dispatch(getOnboardingList(onboardingFilterRequest));
  };

  // Search field state change handling for vendor listing.
  useEffect(() => {
    if (searchField.length >= SEARCH_MIN_CHAR_LENGTH && selectedVendorId === '') {
      dispatch(getOnboardingVendors(searchField));
    }
  }, [searchField]);

  // Bind onboarding status filter elements.
  const bindOnboardingStatusDropdown = () => {
    const onboardingOptions: ISelectOptions[] = [];
    Object.values(OnboardingStatus)
      // eslint-disable-next-line no-restricted-globals
      .filter((v) => !isNaN(Number(v)))
      .forEach((val) => {
        onboardingOptions.push({
          label: `${OnboardingStatus[Number(val)].at(0)}${OnboardingStatus[Number(val)].slice(1).toLowerCase()}`,
          value: Number(val),
        } as ISelectOptions);
      });
    onboardingOptions.unshift(DEFAULT_FILTER_STATUS[0]);
    setOnboardingStatusOptions(onboardingOptions);
  };

  // Handle component side effects to call api's at page load.
  useEffect(() => {
    // Get subscription tiers.
    dispatch(getPlans());
    // Get onboarding managers.
    dispatch(getOnboardingManagers());
    // Bind onboarding status filter.
    bindOnboardingStatusDropdown();
    // Get onboarding list.
    fetchOnboardingList();
    return () => {
      dispatch(resetOnboardingState());
    };
  }, []);

  // Fetch onboarding data when the dependent field changes.
  useEffect(() => {
    // Get onboarding list.
    fetchOnboardingList();
  }, [pageIndex, searchText, sortField, sortOrder, subscriptionPlanId, onboardingManager, onboardingStatus]);

  // List onboarding api response state error handling.
  useEffect(() => {
    if (isSuccess) {
      setOnboardingData(onboardingList);
    } else if (!isSuccess && errorCode > 0 && errorMessage) {
      toast.toastError(errorMessage);
    }
  }, [isSuccess, errorCode, errorMessage, onboardingList]);

  // Show success/error message for onboarding status update request.
  useEffect(() => {
    if (updateOnboardingStatusSuccess && updateOnboardingStatusErrorCode === 0 && updateOnboardingStatusErrorMessage) {
      fetchOnboardingList();
      toast.toastSuccess(updateOnboardingStatusErrorMessage);
      setUpdateStatusConfirmPopup(false);
    } else if (updateOnboardingStatusErrorCode > 0 && updateOnboardingStatusErrorMessage) {
      toast.toastError(updateOnboardingStatusErrorMessage);
      setUpdateStatusConfirmPopup(false);
    }
  }, [updateOnboardingStatusSuccess, updateOnboardingStatusErrorCode, updateOnboardingStatusErrorMessage]);

  // Show success/error message for onboarding manager assignment request.
  useEffect(() => {
    if (assignManagerSuccess && assignManagerErrorCode === 0 && assignManagerErrorMessage) {
      fetchOnboardingList();
      toast.toastSuccess(assignManagerErrorMessage);
      setManagerConfirmPopup(false);
    } else if (assignManagerErrorCode > 0 && assignManagerErrorMessage) {
      toast.toastError(assignManagerErrorMessage);
      setManagerConfirmPopup(false);
    }
  }, [assignManagerSuccess, assignManagerErrorCode, assignManagerErrorMessage]);

  // Show success/error message for onboarding user delete request.
  useEffect(() => {
    if (deleteOnboardingUserSuccess && deleteOnboardingUserErrorCode === 0 && deleteOnboardingUserErrorMessage) {
      if (totalCount % pageIndex > 0) {
        setPageIndex(pageIndex - 1);
      } else {
        fetchOnboardingList();
      }
      toast.toastSuccess(deleteOnboardingUserErrorMessage);
      setDeleteOnboardingUserConfirmPopup(false);
    } else if (deleteOnboardingUserErrorCode > 0 && deleteOnboardingUserErrorMessage) {
      toast.toastError(deleteOnboardingUserErrorMessage);
      setDeleteOnboardingUserConfirmPopup(false);
    }
  }, [deleteOnboardingUserSuccess, deleteOnboardingUserErrorCode, deleteOnboardingUserErrorMessage]);

  // Map plans to select options.
  const mapTiers = (input: IPlan): ISelectOptions => {
    return {
      label: input.planName,
      value: input.planId,
    };
  };

  // Handle fetch plans result state change.
  useEffect(() => {
    if (planSuccess) {
      const selectedPlans = plans.map((m) => mapTiers(m));
      selectedPlans.unshift(DEFAULT_SUBSCRIPTION_TIER[0]);
      setPlans(selectedPlans);
    }
  }, [planSuccess, plans]);

  // select tier change event.
  const onPlanChange = (values: ISelectOptions[]) => {
    setSelectedPlan(values);
    setSubscriptionPlanId(values[0]?.value);
    setPageIndex(1);
  };

  // Map onboarding manager data to select options.
  const mapManagers = (input: IOnboardingManagers): ISelectOptions => {
    return {
      label: `${input.firstName} ${input.lastName}`,
      value: input.userId,
    };
  };

  // Handle fetch onboarding managers result state change.
  useEffect(() => {
    if (onboardingManagersSuccess) {
      const selectedManagersList = onboardingManagers.map((m) => mapManagers(m));
      const managersDropdownData = [...selectedManagersList];
      selectedManagersList.unshift(DEFAULT_ONBOARDING_MANAGER_OPTION[0]);
      setOnboardingManagerOptions(selectedManagersList);
      setManagersDropdownOptions(managersDropdownData);
    }
  }, [onboardingManagersSuccess, onboardingManagers]);

  // select manager filter change event.
  const onManagerFilterChange = (values: ISelectOptions[]) => {
    setOnboardingmanager(values);
    setPageIndex(1);
  };

  // Onboarding status change event.
  const onStatusChange = (values: ISelectOptions[]) => {
    setOnboardingStatus(values);
    setPageIndex(1);
  };

  // Vendor search input keydown event.
  const onVendorSearchHandleKeyDown = (event: any) => {
    const { value } = event.target;
    if (event.key === ENTER_KEY) {
      if (inputRef.current) inputRef.current.blur();
      setPageIndex(1);
      setSearchText(value?.trim());
      setSearchField(searchField.trim());
    }
  };

  // Vendor search textbox selected change event.
  const onVendorSearchHandleChange = (value: any) => {
    if (value[0]) {
      oldSearchText.current = value[0].vendorName;
      setSelectedVendorId(value[0].vendorGuid);
      setSearchText(value[0].vendorName);
      setPageIndex(1);
      setSearchField(value[0].vendorName);
      if (inputRef.current) inputRef.current.blur();
    }
  };

  // Vendor search textbox search input change event.
  const onVendorSearchInputHandleChange = (value: any) => {
    if (value.trim().length >= SEARCH_MIN_CHAR_LENGTH && value.trim().length >= oldSearchText.current.length) {
      setSearchField(value);
    } else if (value.trim().length === 0) {
      setSearchField('');
      setSearchText('');
    }
    oldSearchText.current = value;
    setTypeField(value);
    setSelectedVendorId('');
  };

  // Sort header change event.
  const changeSortField = (field: number) => {
    let newSortOrder = SortOrderType.ASC;
    if (field === sortField) {
      newSortOrder = sortOrder === SortOrderType.ASC ? SortOrderType.DESC : SortOrderType.ASC;
    }
    setSortField(field);
    setSortOrder(newSortOrder);
  };

  // Modify onboarding list array used for dropdown section binding.
  const modifyOnboardingSelectionData = (values: ISelectOptions[], vendorGuid: string, selectionType: number) => {
    const onboardingNewList = [...onboardingList];
    const index = onboardingNewList.findIndex((x: IOnbaordingList) => x.vendorGuid === vendorGuid);
    if (index > -1) {
      const onboardingItem = { ...onboardingNewList[index] };
      if (selectionType === OnboardingSelections.STATUS && onboardingItem.onboardingStatus !== values[0].value) {
        onboardingItem.onboardingStatus = values[0].value;
      } else if (
        selectionType === OnboardingSelections.MANAGER &&
        onboardingItem.onboardingManagerUserId !== values[0].value &&
        values[0].value !== DEFAULT_ONBOARDING_MANAGER_SELECTION_OPTION[0].value
      ) {
        onboardingItem.onboardingManagerUserId = values[0].value;
        onboardingItem.onboardingManagerName = values[0].label;
      } else {
        return null;
      }
      onboardingNewList[index] = onboardingItem;
    }
    return onboardingNewList;
  };

  // Vendor manager select event.
  const onVendorManagerChange = (values: ISelectOptions[], vendorGuid: string) => {
    const modifiedData = modifyOnboardingSelectionData(values, vendorGuid, OnboardingSelections.MANAGER);
    if (modifiedData) {
      setOnboardingSelectedManager(values[0].value);
      setOnboardingData(modifiedData);
      setOnboardingSelectedVendor(vendorGuid);
      setManagerConfirmPopup(true);
    }
  };

  // Assign manager to the vendor confirmed event.
  const assignVendorManager = () => {
    if (onboardingSelectedVendor) {
      setManagerConfirmPopup(false);
      dispatch(assignManager({ vendorGuid: onboardingSelectedVendor, managerUserId: onboardingSelectedManager }));
    }
  };

  // Onboarding status select event.
  const onOnboardingStatusChange = (values: ISelectOptions[], vendorGuid: string) => {
    const modifiedData = modifyOnboardingSelectionData(values, vendorGuid, OnboardingSelections.STATUS);
    if (modifiedData) {
      setOnboardingSelectedStatus(values[0].value);
      setOnboardingData(modifiedData);
      setOnboardingSelectedVendor(vendorGuid);
      setUpdateStatusConfirmPopup(true);
    }
  };

  // Onboarding status change confirmed event.
  const onStatusChangeConfirmed = () => {
    if (onboardingSelectedVendor) {
      dispatch(updateOnboardingStatus({ vendorGuid: onboardingSelectedVendor, onboardStatus: onboardingSelectedStatus }));
      setUpdateStatusConfirmPopup(false);
      setOnboardingSelectedVendor(null);
    }
  };

  // Onboarding status change cancel event.
  const onStatusChangeCancelled = () => {
    setOnboardingData(onboardingList);
    setUpdateStatusConfirmPopup(false);
    setOnboardingSelectedVendor(null);
  };

  // Manager assignment cancel event.
  const onCancelManagerAssignment = () => {
    setOnboardingData(onboardingList);
    setManagerConfirmPopup(false);
    setOnboardingSelectedVendor(null);
  };

  // Delete user button click handler
  const onUserDeleteClickHandler = (vendorGuid: string) => {
    setOnboardingSelectedVendor(vendorGuid);
    setDeleteOnboardingUserConfirmPopup(true);
  };

  // Onboarding user delete confirmed event.
  const onDeleteOnboardingUserConfirmed = () => {
    if (onboardingSelectedVendor) {
      dispatch(deleteOnboardingUser(onboardingSelectedVendor));
      setDeleteOnboardingUserConfirmPopup(false);
      setOnboardingSelectedVendor(null);
    }
  };

  // Onboarding user delete cancel event.
  const onDeleteOnboardingUserCancelled = () => {
    setDeleteOnboardingUserConfirmPopup(false);
    setOnboardingSelectedVendor(null);
  };

  // Bind onboarding status select component.
  const bindOnbaordingStatusComponent = (obFilterData: IOnbaordingList | undefined) => {
    return (
      obFilterData && (
        <div className="select-border-none d-inline-block onboardstatus-dropdown form-sm">
          <SelectLabel
            key={`status_${obFilterData.vendorGuid}_${obFilterData.onboardingStatus}`}
            id="onboardingStatus"
            type="select"
            name="onboardingStatus"
            options={[...onboardingStatusOptions].filter((_, index) => index > 1)}
            placeholder="Pause Time"
            dropdownGap={0}
            values={[
              {
                label: `${OnboardingStatus[obFilterData.onboardingStatus].at(0)}${OnboardingStatus[obFilterData.onboardingStatus].slice(1).toLowerCase()}`,
                value: obFilterData.onboardingStatus,
              },
            ]}
            dropdownPosition="auto"
            isMandatory={false}
            errorMessage=""
            className="min-w-drop"
            onChange={(values) => onOnboardingStatusChange(values, obFilterData.vendorGuid)}
          />
        </div>
      )
    );
  };

  // Bind onboarding manager select component.
  const bindOnbaordingManagerComponent = (obManagerData: IOnbaordingList | undefined) => {
    const dropDownOptions = [...managersDropdownOption];
    dropDownOptions.unshift(DEFAULT_ONBOARDING_MANAGER_SELECTION_OPTION[0]);
    const mngOptions = obManagerData?.onboardingManagerUserId && obManagerData?.onboardingManagerUserId > 0 ? managersDropdownOption : dropDownOptions;

    return (
      obManagerData && (
        <div className="select-border-none d-inline-block max-w-150">
          <SelectLabel
            key={`manager_${obManagerData.vendorGuid}_${obManagerData.onboardingManagerUserId}`}
            id="managers"
            type="select"
            name="managers"
            options={mngOptions}
            placeholder=""
            dropdownGap={0}
            values={
              obManagerData.onboardingManagerUserId && obManagerData.onboardingManagerUserId > 0
                ? [{ label: obManagerData.onboardingManagerName, value: obManagerData.onboardingManagerUserId }]
                : DEFAULT_ONBOARDING_MANAGER_SELECTION_OPTION
            }
            dropdownPosition="bottom"
            isMandatory={false}
            errorMessage=""
            className="drop-min-225"
            onChange={(values) => onVendorManagerChange(values, obManagerData.vendorGuid)}
          />
        </div>
      )
    );
  };

  return (
    <div className="content-nav contnt-container-margin">
      <div className="back-navigation">
        <Breadcrumb>
          <Breadcrumb.Item href="#" onClick={() => navigate('/home')}>
            <FormattedMessage id="Menu.Home" />
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
            <FormattedMessage id="HD.OnboardingManagement" />
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="content-nav-sub">
        <div className="content-nav-header d-flex justify-content-between align-items-center">
          <h2>
            <FormattedMessage id="HD.OnboardingList" />
          </h2>
          <div>
            <Dropdown className="button-dropdown" onClick={() => navigate('/vendors/add')}>
              <Dropdown.Toggle variant="primary" id="dropdown-basic">
                <Add /> <FormattedMessage id="Button.AddVendor" />
              </Dropdown.Toggle>
            </Dropdown>
          </div>
        </div>
        <div className="content-filter-main border-0">
          <Row className="align-items-center">
            <Col xs={12} md={6} lg={5} xl={3} className="no-margin search-auto mt-mx-lg-top">
              <Typeahead
                id="vendorSearchInput"
                ref={inputRef}
                onInputChange={onVendorSearchInputHandleChange}
                onChange={onVendorSearchHandleChange}
                onKeyDown={onVendorSearchHandleKeyDown}
                options={onboardingVendors}
                placeholder={useIntlMessages('Vendor.SearchVendor.Placeholder')}
                selected={onboardingVendors.filter((x: any) => x.vendorGuid === selectedVendorId)}
                filterBy={['vendorGuid', 'vendorName']}
                minLength={oldSearchText.current.length > typeField.length ? oldSearchText.current.length : SEARCH_MIN_CHAR_LENGTH}
                labelKey={(item: any) => `${item.vendorName}`}
                inputProps={{
                  maxLength: INPUT_MAX_LENGTH_100,
                }}
                renderMenuItemChildren={(item: any) => <div>{item.vendorName}</div>}
              >
                <Search />
              </Typeahead>
            </Col>
            <Col xs={12} md={6} lg={5} xl={3} className="no-margin mt-mx-lg-top">
              <Select
                id="tier"
                name="tier"
                type="select"
                options={planList}
                placeholder="Filter by tier"
                dropdownGap={0}
                values={selectedPlan}
                icon={<SortIcon />}
                onChange={(values) => onPlanChange(values)}
                dropdownPosition="auto"
                errorMessage=""
                isMandatory={false}
                className=""
              />
            </Col>
            <Col xs={12} md={6} lg={5} xl={3} className="no-margin mt-mx-lg-top">
              <Select
                id="onboardingmanager"
                name="onboardingmanager"
                type="select"
                options={onboardingManagerOptions}
                placeholder="sample"
                dropdownGap={0}
                values={onboardingManager}
                icon={<SortIcon />}
                onChange={(values) => onManagerFilterChange(values)}
                dropdownPosition="auto"
                errorMessage=""
                isMandatory={false}
                className=""
              />
            </Col>
            <Col xs={12} md={6} lg={5} xl={3} className="no-margin mt-mx-lg-top">
              <Select
                id="status"
                name="status"
                type="select"
                options={onboardingStatusOptions}
                placeholder="sample"
                dropdownGap={0}
                values={onboardingStatus}
                icon={<SortIcon />}
                onChange={(values) => onStatusChange(values)}
                dropdownPosition="auto"
                errorMessage=""
                isMandatory={false}
                className=""
              />
            </Col>
          </Row>
        </div>
        <Table responsive>
          <thead>
            <tr>
              <th className="w-220" onClick={() => changeSortField(OnboardingSortField.VENDOR_NAME)} style={{ cursor: 'pointer' }}>
                <FormattedMessage id="Vendor.VendorName" />
                {sortField !== OnboardingSortField.VENDOR_NAME && <UpDownArrow />}
                {sortField === OnboardingSortField.VENDOR_NAME && sortOrder === SortOrderType.DESC && <DownArrow />}
                {sortField === OnboardingSortField.VENDOR_NAME && sortOrder === SortOrderType.ASC && <UpArrow />}
              </th>
              <th className="w-220">
                <FormattedMessage id="Vendor.Address" />
              </th>
              <th className="w-120" onClick={() => changeSortField(OnboardingSortField.PLAN_NAME)} style={{ cursor: 'pointer' }}>
                <FormattedMessage id="Vendor.Tier" />
                {sortField !== OnboardingSortField.PLAN_NAME && <UpDownArrow />}
                {sortField === OnboardingSortField.PLAN_NAME && sortOrder === SortOrderType.DESC && <DownArrow />}
                {sortField === OnboardingSortField.PLAN_NAME && sortOrder === SortOrderType.ASC && <UpArrow />}
              </th>
              <th>
                <FormattedMessage id="TH.Email" />
              </th>
              <th className="w-170" onClick={() => changeSortField(OnboardingSortField.SIGNEDUPON)} style={{ cursor: 'pointer' }}>
                <FormattedMessage id="TH.DateSigned" />
                {sortField !== OnboardingSortField.SIGNEDUPON && <UpDownArrow />}
                {sortField === OnboardingSortField.SIGNEDUPON && sortOrder === SortOrderType.DESC && <DownArrow />}
                {sortField === OnboardingSortField.SIGNEDUPON && sortOrder === SortOrderType.ASC && <UpArrow />}
              </th>
              <th>
                <FormattedMessage id="TH.OnboardingManager" />
              </th>
              <th className="w-180">
                <FormattedMessage id="TH.Status" />
              </th>
              <th className="w-100px">
                <FormattedMessage id="TH.DaysStatus" />
              </th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {onboardingList &&
              onboardingList.length > 0 &&
              onboardingList.map((obData: IOnbaordingList) => (
                <tr key={obData.vendorId}>
                  <td>
                    <Link to={`/vendors/details/${obData.vendorId}`}>{obData.vendorName}</Link>
                  </td>
                  <td>{`${obData.city}${obData.state ? `, ${obData.state}` : ''}`}</td>
                  <td>{obData.planName}</td>
                  <td>{obData.email}</td>
                  <td>{moment(obData.signedupOn).local().format(DATE_FORMAT)}</td>
                  <td>{bindOnbaordingManagerComponent(onboardingData.find((dt: IOnbaordingList) => dt.vendorGuid === obData.vendorGuid))}</td>
                  <td>
                    {obData.onboardingStatus === OnboardingStatus.ONBOARDING &&
                      bindOnbaordingStatusComponent(onboardingData.find((dt: IOnbaordingList) => dt.vendorGuid === obData.vendorGuid))}
                    {obData.onboardingStatus === OnboardingStatus.DEMO && <Badge className="badge-review">Demo</Badge>}
                    {obData.onboardingStatus === OnboardingStatus.LIVE && <Badge className="badge-accepted">Live</Badge>}
                  </td>
                  <td>{getDaysFromTwoDates(moment(obData.onboardingStatusChangedOn).local().format(DATE_FORMAT), moment().local().format(DATE_FORMAT))}</td>
                  <td>
                    {obData.onboardingStatus === OnboardingStatus.DEMO && (
                      <Button className="btn-icon" onClick={() => onUserDeleteClickHandler(obData.vendorGuid)}>
                        <Delete />
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            {!onboardingList ||
              (onboardingList && onboardingList.length === 0 && (
                <tr>
                  <td colSpan={7} className="text-center">
                    <FormattedMessage id="Onboarding.table.emptymessage" />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <div className="content-filter-main d-lg-flex justify-content-lg-between">
          {onboardingList.length > 0 && (
            <p className="m-0">
              {stringFormat(
                totalCount === 1 ? useIntlActionMessages('Onboarding.Pagination.SingleItem.Text') : useIntlActionMessages('Onboarding.Pagination.Text'),
                onboardingList.length > 0 ? ((totalCount === 1 ? 1 : pageIndex) - 1) * pageSize + 1 : 0,
                ((totalCount === 1 ? 1 : pageIndex) - 1) * pageSize + onboardingList.length,
                totalCount,
              )}
            </p>
          )}
          {onboardingList.length > 0 && totalCount > pageSize && (
            <Pagination totalCount={totalCount} pageLimit={pageSize} setCurrentPage={(page: number) => setPageIndex(page)} currentPage={pageIndex - 1} prevPage={-1} />
          )}
        </div>
      </div>
      {(isLoading || assignManagerLoading || updateOnboardingStatusLoading || deleteOnboardingUserLoading) && <GifLoader />}
      {/*  Assign manager confirmation popup */}
      <Modal show={assignManagerConfirm} onHide={() => onCancelManagerAssignment()} size="lg" centered>
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-sm">
            <FormattedMessage id="HD.Onboarding.Assign.Manager.Confirmation" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-3">
            <FormattedMessage id="Onboarding.Assignment.Confirm.Text" />
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="" onClick={() => onCancelManagerAssignment()}>
            <FormattedMessage id="Button.Cancel" />
          </Button>
          <Button variant="primary" onClick={() => assignVendorManager()}>
            <FormattedMessage id="Button.OK" />
          </Button>
        </Modal.Footer>
      </Modal>
      {/*  Update onboarding status confirmation popup */}
      <Modal show={updateStatusConfirm} onHide={() => onStatusChangeCancelled()} size="lg" centered>
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-sm">
            <FormattedMessage id="HD.Onboarding.Status.Update.Confirmation" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-3">
            <FormattedMessage id="Onboarding.Update.Status.Confirm.Text" />
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="" onClick={() => onStatusChangeCancelled()}>
            <FormattedMessage id="Button.Cancel" />
          </Button>
          <Button variant="primary" onClick={() => onStatusChangeConfirmed()}>
            <FormattedMessage id="Button.OK" />
          </Button>
        </Modal.Footer>
      </Modal>
      {/*  Delete onboarding user confirmation popup */}
      <Modal show={deleteOnboardingUserConfirmPopup} onHide={() => onDeleteOnboardingUserCancelled()} size="lg" centered>
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-sm">
            <FormattedMessage id="HD.Onboarding.User.Delete.Confirmation" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-3">
            <FormattedMessage id="Onboarding.User.Delete.Confirm.Text" />
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="" onClick={() => onDeleteOnboardingUserCancelled()}>
            <FormattedMessage id="Button.Cancel" />
          </Button>
          <Button variant="primary" onClick={() => onDeleteOnboardingUserConfirmed()}>
            <FormattedMessage id="Button.OK" />
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Onboarding;
