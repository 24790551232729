/**
 * @file   src\components\LocationSearch.tsx
 * @brief  Location search component.
 * @date   May, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import GoogleAutocomplete from 'react-google-autocomplete';
import { React, useEffect, useRef } from '../utils/thirdpartyComponents';
import '../styles/LocationSearch.scss';
import { LocationProperty } from '../interfaces/generalInterface';

interface IVendorLocation extends LocationProperty {
  onLocationSelect: (place: any) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isMandatory: boolean;
}
const LocationSearch = ({
  onLocationSelect,
  onChange,
  label,
  address,
  errorMessage,
  locationChange,
  id,
  placeholder,
  types,
  icon,
  closeicon,
  values,
  isMandatory,
}: IVendorLocation) => {
  // Declare ref variable for google autocomplete.
  const inputRef = useRef<any>(null);

  // Google place select change event.
  const onPlaceSelected = (place: any) => {
    const latitude = place.geometry.location.lat();
    const longitude = place.geometry.location.lng();
    let city = '';
    let subLocality = '';
    let state = '';
    let stateCode = '';
    let country = '';
    let zip = '';
    let premises = '';
    let route = '';

    place.address_components.map((addressField: any) => {
      route = addressField.types.includes('route') ? addressField.long_name : route;
      city = addressField.types.includes('locality') ? addressField.long_name : city;
      subLocality = addressField.types.includes('sublocality') ? addressField.long_name : subLocality;
      state = addressField.types.includes('administrative_area_level_1') ? addressField.long_name : state;
      stateCode = addressField.types.includes('administrative_area_level_1') ? addressField.short_name : stateCode;
      country = addressField.types.includes('country') ? addressField.long_name : country;
      zip = addressField.types.includes('postal_code') ? addressField.long_name : zip;
      return null;
    });

    premises = place.name;

    const fullAddress = document.getElementById('address') as HTMLInputElement | null;
    const value = fullAddress?.value;

    onLocationSelect({
      /* Return callback function with data. */
      address: value,
      lat: latitude,
      lng: longitude,
      city: city !== '' ? city : subLocality,
      state,
      stateCode,
      country,
      zip,
      formattedAddress: premises !== '' ? `${premises}${route !== '' ? `, ${route}` : ''}` : route,
      name: premises,
    });
  };

  // Reset autocomplete textfield values when address changes.
  useEffect(() => {
    if (inputRef.current) inputRef.current.value = address;
  }, [address]);

  return (
    <div className="form-group">
      <span className="inputlabel">
        {label}
        {isMandatory && <span className="text-red">*</span>}
      </span>
      <div
        className={
          errorMessage && errorMessage !== '' && !locationChange
            ? 'cust-location-group position-relative error'
            : Object.keys(values).length > 0
            ? 'cust-location-group position-relative active'
            : 'cust-location-group position-relative'
        }
      >
        <GoogleAutocomplete
          id={id}
          placeholder={placeholder}
          className="form-control"
          apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
          options={{
            fields: ['formatted_address', 'address_components', 'geometry', 'name'],
            strictBounds: false,
            types,
          }}
          onPlaceSelected={(place) => {
            onPlaceSelected(place);
          }}
          onChange={onChange}
          ref={inputRef}
        />
        <div className="icon-wrap">{icon}</div>
        <div className="close-icon-wrap">{closeicon}</div>
        {errorMessage && errorMessage !== '' && !locationChange && <span className="error">{errorMessage}</span>}
      </div>
    </div>
  );
};

export default LocationSearch;
