/**
 * @file   src\features\restaurant\RestaurantTable.tsx
 * @brief  Restaurant Table Component - Displays restaurant list
 * @date   June, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import InputCheck from '../../components/ChecBoxLabel';
import { FormattedMessage, OverlayTrigger, Table, Tooltip, moment } from '../../utils/thirdpartyComponents';
import { formatPhoneNumber, useIntlActionMessages, useIntlMessages } from '../../utils/helper';
import { IRestaurantSignupStatus } from '../../utils/enums';
import { IRestaurantMergeTableRow, IMergeTable } from '../../interfaces/restaurantInterface';

// Component that displays reaturant list as table
export const RestaurantsTable = ({ handleCheckBox, viewMergedRestaurantHandleChange, data, selectedIds }: IMergeTable) => {
  // Get table rows data
  const getTableRows = () => {
    const rows = data.map((restaurant: IRestaurantMergeTableRow, index: number) => (
      <tr key={`restaurant.restaurantName_${restaurant.restId}`}>
        <td>
          <div className="no-margin">
            <InputCheck id="Users" name="Users" label="" checked={selectedIds.includes(restaurant.restId)} onChange={(e) => handleCheckBox(e, index)} />
          </div>
        </td>
        <td>
          {restaurant.mergedStatus ? (
            <a href="#" onClick={() => viewMergedRestaurantHandleChange(restaurant.restId)}>
              {restaurant.restaurantName}
            </a>
          ) : (
            <span>{restaurant.restaurantName}</span>
          )}
        </td>
        <td>
          {restaurant.adminAdded && !restaurant.isLoggedinOnce ? (
            useIntlMessages('SignupStatus.AdminCreated')
          ) : restaurant.signUpStatus === IRestaurantSignupStatus.SIGNED_UP || (restaurant.adminAdded && restaurant.isLoggedinOnce) ? (
            useIntlMessages('SignupStatus.Signedup')
          ) : (
            <OverlayTrigger overlay={<Tooltip id="tooltip">{restaurant.vendor || useIntlActionMessages('Merge.Tooltip.Vendor.Name.NotFound')}</Tooltip>} placement="top">
              <div className="cursor-pointer hover-effect">{useIntlMessages('SignupStatus.Local')}</div>
            </OverlayTrigger>
          )}
        </td>
        <td>{restaurant.email}</td>
        <td>{restaurant.phone && `${restaurant.countryCode} ${formatPhoneNumber(restaurant.phone)}`}</td>
        <td>{restaurant.address}</td>
        <td>{restaurant.zip}</td>
        <td>{moment(restaurant.createdOn).local().format('MM/DD/YYYY')}</td>
      </tr>
    ));
    return rows;
  };
  return (
    <Table responsive>
      <thead>
        <tr>
          <th className="w-50px"> </th>
          <th>
            <FormattedMessage id="Restaurant.RestaurantName" />
          </th>
          <th>
            <FormattedMessage id="Restaurant.Createdby" />
          </th>
          <th>
            <FormattedMessage id="Restaurant.Email" />
          </th>
          <th>
            <FormattedMessage id="Restaurant.PhoneNumber" />
          </th>
          <th className="w-350">
            <FormattedMessage id="Restaurant.Address" />
          </th>
          <th>
            <FormattedMessage id="Restaurant.zipcode" />
          </th>
          <th>
            <FormattedMessage id="Restaurant.SignupDate" />
          </th>
        </tr>
      </thead>
      <tbody>{getTableRows()}</tbody>
    </Table>
  );
};
