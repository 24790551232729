/**
 * @file   src\features\user\User.tsx
 * @brief  Users listing page
 * @date   June, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { Button, Col, Table, Modal, FormattedMessage, useNavigate, useState, useEffect, useLocation, Link } from '../../utils/thirdpartyComponents';
import ManageRole from '../../assets/icons/MenuSettings';
import NewUser from '../../assets/icons/Add';
import CreateUser from '../../components/CreateUser';
import { RootState } from '../../store';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { IListUserRequest, IUserData } from '../../interfaces/userInterface';
import { getUsersList, resendInvitation } from '../../store/actions/userAction';
import { formatPhoneNumber, getFromLocalStorage, stringFormat, useIntlMessages, getIcon } from '../../utils/helper';
import { PAGE_SIZE, SUPERADMIN_TYPE } from '../../utils/constants';
import GifLoader from '../../components/GifLoader';
import { getRoles } from '../../store/actions/roleAction';
import Descending from '../../assets/icons/DownArrow';
import NotClicked from '../../assets/icons/UpDownArrow';
import Ascending from '../../assets/icons/UpArrow';
import { UserSortFields, SortOrderType, MenuPermissions } from '../../utils/enums';
import { MessageToaster } from '../../utils/toastUtil';
import { resetAddUser, resetUserInvite } from '../../store/slices/userSlice';
import Pagination from '../../components/Paginate';
import BackIcon from '../../assets/icons/Back';

// Toast object creation.
const toast = new MessageToaster();

const UsersList = () => {
  // Required constants
  const pageItemCount: number = process.env.REACT_APP_LIST_PAGE_SIZE !== '' ? Number(process.env.REACT_APP_LIST_PAGE_SIZE) : PAGE_SIZE;
  const paginationSingleText = useIntlMessages('User.Pagination.SingleItem.Text');
  const paginationText = useIntlMessages('User.Pagination.Text');
  // Navigate object creation.
  const navigate = useNavigate();
  // Create action dispatch
  const dispatch = useAppDispatch();
  // Location infor object
  const location = useLocation();

  // Access redux state variables.
  const {
    isLoading,
    userList,
    errorCode,
    errorMessage,
    addUserLoading,
    addUserErrorCode,
    userListCount,
    deleteUserLoading,
    resendInvitationLoading,
    addUserErrorMessage,
    resendInvitationErrorMessage,
    resendInvitationSuccess,
    resendInvitationErrorCode,
    deleteUserErrorCode,
    deleteUserErrorMessage,
  } = useAppSelector((state: RootState) => state.user);
  const { roles } = useAppSelector((state: RootState) => state.role);

  // Default request for user list api
  const deafaultListRequest = {
    inactiveUsers: 0,
    pageIndex: 1,
    pageSize: pageItemCount,
    sortField: 0,
    sortOrder: SortOrderType.DESC,
  };
  // Request for inactive users
  const inactiveUsersRequest = {
    inactiveUsers: 1,
    pageIndex: 1,
    pageSize: pageItemCount,
    sortField: 0,
    sortOrder: SortOrderType.DESC,
  };

  // Component state variables
  const [listRequestData, setListRequestData] = useState<IListUserRequest>(deafaultListRequest);
  const [userFormVisible, setUserFormVisible] = useState<boolean>(false);
  const [editUserData, setEditUserData] = useState<any>({});
  const [editActive, setEditActive] = useState<boolean>(false);
  const [editPopUp, setEditPopUp] = useState<boolean>(false);
  const [resendPopUp, setResendPopUp] = useState<boolean>(false);
  const [userData, setUserData] = useState<any>(null);

  // call list apis on initial loading and path change
  useEffect(() => {
    if (location.pathname === '/users') {
      dispatch(getUsersList(deafaultListRequest));
      setListRequestData(deafaultListRequest);
    } else {
      dispatch(getUsersList(inactiveUsersRequest));
      setListRequestData(inactiveUsersRequest);
    }
    dispatch(getRoles());
    const storageData = getFromLocalStorage('MCSA_UDT');
    const userInfo = storageData && storageData !== null ? storageData : null;
    setUserData(userInfo);
    // Reset user redux state variables on component unmount
    return () => {
      dispatch(resetUserInvite());
      dispatch(resetAddUser());
    };
  }, [location.pathname]);

  // call user list api when sortfield,sort order and page changes
  useEffect(() => {
    dispatch(getUsersList(listRequestData));
  }, [listRequestData.sortField, listRequestData.sortOrder, listRequestData.pageIndex]);

  // Show error message on user adding failed
  useEffect(() => {
    if (addUserErrorCode > 0) {
      toast.toastError(addUserErrorMessage);
    }
    if (errorCode > 0 && errorMessage) {
      toast.toastError(errorMessage);
    }
  }, [addUserErrorCode, errorCode]);
  // show error message on user delete failed
  useEffect(() => {
    if (deleteUserErrorCode > 0) {
      toast.toastError(deleteUserErrorMessage);
    }
  }, [deleteUserErrorCode, deleteUserErrorMessage]);
  // Show success/error message for resend invitation api
  useEffect(() => {
    if (resendInvitationSuccess && resendInvitationErrorCode === 0) {
      toast.toastSuccess(resendInvitationErrorMessage);
    } else if (resendInvitationErrorCode > 0) {
      toast.toastError(resendInvitationErrorMessage);
    }
  }, [resendInvitationSuccess, resendInvitationErrorCode]);

  // Set data for edit
  const setEditData = (data: any) => {
    setEditActive(true);
    setEditUserData(data);
  };

  // Get User List Table Rows
  const getUserTableRows = () => {
    let rows;
    if (userList.length > 0) {
      rows = userList.map((user: IUserData, index: number) => (
        <tr key={`${user.firstName}_${index.toString()}`}>
          <td>
            <div className="d-flex align-items-center">
              <div>
                <span className="bg-span me-3">
                  <img src={getIcon(user.roleIcon)} alt="Admin" />
                </span>
              </div>
              <div>{user.roleName}</div>
            </div>
          </td>
          <td>
            {user.firstName} {user.lastName}
          </td>
          <td>{user.email}</td>
          <td>
            {user.countryCode} {formatPhoneNumber(user.phone)}
          </td>
          {location.pathname !== '/users/inactiveusers' && (
            <td>
              {(userData?.userType === SUPERADMIN_TYPE || (userData?.permissions && userData?.permissions.includes(MenuPermissions.USER_CREATE))) && (
                <Button
                  variant="outline-secondary"
                  onClick={() => {
                    setEditPopUp(true);
                    setEditData(user);
                  }}
                >
                  <FormattedMessage id="Button.EditUser" />
                </Button>
              )}

              {user.isActive && !user.isLoggedIn && (
                <Button
                  variant="outline-secondary"
                  className="ms-4"
                  onClick={() => {
                    setResendPopUp(true);
                    setEditUserData(user);
                  }}
                >
                  <FormattedMessage id="Button.ResendInvitation" />
                </Button>
              )}
            </td>
          )}
        </tr>
      ));
    } else if (userList.length === 0) {
      rows = (
        <tr>
          <td colSpan={5} className="text-center">
            <FormattedMessage id="Table.NoUsers.Message" />
          </td>
        </tr>
      );
    }
    return rows;
  };
  // load inactive users page
  const loadInactiveUsersPage = (event: any) => {
    event.preventDefault();
    navigate('/users/inactiveusers');
  };
  // handle sort field change and sort order
  const changeSortField = (field: number) => {
    const newSortOrder = listRequestData.sortOrder === SortOrderType.ASC ? SortOrderType.DESC : SortOrderType.ASC;
    setListRequestData({ ...listRequestData, sortField: field, sortOrder: newSortOrder });
  };
  // call to refresh user listing
  const reloadUserList = () => {
    dispatch(getUsersList(deafaultListRequest));
    dispatch(resetAddUser());
  };
  // call to resend invitation mail api
  const resendEmailInvitation = () => {
    setResendPopUp(false);
    const request = {
      email: editUserData?.email?.trim() || '',
      firstName: editUserData?.firstName?.trim() || '',
      lastName: editUserData?.lastName?.trim() || '',
    };
    dispatch(resendInvitation(request));
  };

  return (
    <div className="content-nav contnt-container-margin">
      {location.pathname === '/users/inactiveusers' && (
        <div className="back-navigation">
          <Link to="/users" className="back-btn">
            <BackIcon />
            <FormattedMessage id="Link.Back" />
          </Link>
        </div>
      )}
      <div className="content-nav-sub">
        <div className="content-nav-header d-lg-flex justify-content-between align-items-center">
          <h2>
            {location.pathname === '/users/inactiveusers' ? (
              <FormattedMessage id="InactiveUsers.Heading" />
            ) : editActive ? (
              <FormattedMessage id="EditUser.Heading" />
            ) : (
              <FormattedMessage id="HD.Users" />
            )}
          </h2>
          <div className="mt-mx-md-top">
            {(userData?.userType === SUPERADMIN_TYPE || (userData?.permissions && userData?.permissions.includes(MenuPermissions.ROLES))) &&
              location.pathname !== '/users/inactiveusers' && (
                <Button variant="secondary" onClick={() => navigate('/users/roles')}>
                  <ManageRole />
                  <FormattedMessage id="Button.ManageRoles" />
                </Button>
              )}
            {(userData?.userType === SUPERADMIN_TYPE || (userData?.permissions && userData?.permissions.includes(MenuPermissions.USER_CREATE))) &&
              !userFormVisible &&
              location.pathname !== '/users/inactiveusers' && (
                <Button variant="primary" className="ms-4" onClick={() => setUserFormVisible(true)}>
                  <NewUser />
                  <FormattedMessage id="Button.NewUser" />
                </Button>
              )}
          </div>
        </div>
        {/* Show or Hide User Add Form */}
        {userFormVisible && (
          <CreateUser
            rolesList={roles}
            editData={editUserData}
            editActive={editActive}
            setFormVisible={setUserFormVisible}
            refreshUsers={reloadUserList}
            setEditActive={setEditActive}
            isRoleLinkVisible={userData?.userType === SUPERADMIN_TYPE}
          />
        )}
        {/* Show loading state  */}
        {(isLoading || addUserLoading || deleteUserLoading || resendInvitationLoading) && <GifLoader />}

        <div className="content-filter-main border-0">
          <div className="d-flex justify-content-between">
            <Col xs={12} md={6} lg={5} xl={3} className="d-flex" />
            {location.pathname !== '/users/inactiveusers' && (
              <a href="#" onClick={loadInactiveUsersPage}>
                <FormattedMessage id="Link.ShowInactive" />
              </a>
            )}
          </div>
        </div>
        <Table responsive className="table-second">
          <thead>
            <tr>
              <th style={{ cursor: 'pointer' }} onClick={() => changeSortField(UserSortFields.USER_TYPE)}>
                <FormattedMessage id="TH.UserType" />
                {listRequestData.sortField !== UserSortFields.USER_TYPE && <NotClicked />}
                {listRequestData.sortField === UserSortFields.USER_TYPE && listRequestData.sortOrder === SortOrderType.ASC && <Ascending />}
                {listRequestData.sortField === UserSortFields.USER_TYPE && listRequestData.sortOrder === SortOrderType.DESC && <Descending />}
              </th>
              <th style={{ cursor: 'pointer' }} onClick={() => changeSortField(UserSortFields.NAME)}>
                <FormattedMessage id="TH.Name" />
                {listRequestData.sortField !== UserSortFields.NAME && <NotClicked />}
                {listRequestData.sortField === UserSortFields.NAME && listRequestData.sortOrder === SortOrderType.ASC && <Ascending />}
                {listRequestData.sortField === UserSortFields.NAME && listRequestData.sortOrder === SortOrderType.DESC && <Descending />}
              </th>
              <th>
                <FormattedMessage id="TH.EmailAddress" />
              </th>
              <th className="w-220">
                <FormattedMessage id="TH.Phone" />
              </th>
              {location.pathname !== '/users/inactiveusers' && (
                <th className="w-420">
                  <FormattedMessage id="TH.Actions" />
                </th>
              )}
            </tr>
          </thead>
          <tbody>{getUserTableRows()}</tbody>
        </Table>
        <div className="content-filter-main d-lg-flex justify-content-lg-between">
          {userList && userList.length > 0 && (
            <p className="m-0">
              {stringFormat(
                userListCount === 1 ? paginationSingleText : paginationText,
                userList.length > 0 ? ((userListCount === 1 ? 1 : listRequestData.pageIndex) - 1) * pageItemCount + 1 : 0,
                ((userListCount === 1 ? 1 : listRequestData.pageIndex) - 1) * pageItemCount + userList.length,
                userListCount,
              )}
            </p>
          )}
          {userList && userList.length > 0 && userListCount > pageItemCount && (
            <Pagination
              totalCount={userListCount}
              pageLimit={pageItemCount}
              setCurrentPage={(page: number) => {
                setListRequestData({ ...listRequestData, pageIndex: page });
              }}
              currentPage={listRequestData.pageIndex - 1}
              prevPage={-1}
            />
          )}
        </div>
      </div>
      {/* User Edit Confirmation Popup */}
      <Modal show={editPopUp} onHide={() => setEditPopUp(false)} centered size="lg">
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-sm">
            <FormattedMessage id="EditUser.Popup.Heading" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormattedMessage id="EditUser.Popup.Message" />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            className="btn-modal-footer"
            onClick={() => {
              setEditPopUp(false);
              setEditUserData({});
              setEditActive(false);
            }}
          >
            <FormattedMessage id="Button.Cancel" />
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setEditPopUp(false);
              setUserFormVisible(true);
              setTimeout(() => {
                window.scrollTo(0, 0);
              }, 200);
            }}
          >
            <FormattedMessage id="Button.Ok" />
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Resend email invitation confirmation popup */}
      <Modal show={resendPopUp} onHide={() => setResendPopUp(false)} centered size="lg">
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-sm">
            <FormattedMessage id="HD.ResendInvitation" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <FormattedMessage id="User.InvitationAgain" />
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setResendPopUp(false)}>
            <FormattedMessage id="Button.Cancel" />
          </Button>
          <Button variant="primary" onClick={() => resendEmailInvitation()}>
            <FormattedMessage id="Button.OK" />
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UsersList;
