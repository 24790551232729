/**
 * @file   src\components\Select.tsx
 * @brief  Html Select component.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { useEffect, useState } from 'react';
import '../styles/Select.scss';
import Select from 'react-dropdown-select';
import { ISelect } from '../interfaces/generalInterface';

const SelectCust = ({ isSortChanged, onChange, errorMessage, values, dropdownPosition, placeholder, options, multi, icon }: ISelect) => {
  const [newValue, setNewValue] = useState('');

  useEffect(() => {
    if (isSortChanged && isSortChanged !== '') {
      setNewValue(isSortChanged);
    }
  }, [isSortChanged]);

  const onSelectChange = (e: any) => {
    if (onChange) onChange(e);
  };

  return (
    <div className="cust-ic-group position-relative">
      <Select
        className={errorMessage && errorMessage !== '' ? 'cutom-select error' : values[0].value !== 0 ? 'cutom-select active' : 'cutom-select'}
        key={newValue}
        dropdownPosition={dropdownPosition}
        placeholder={placeholder}
        options={options}
        dropdownGap={-3}
        values={values}
        onChange={onSelectChange}
        multi={multi}
        searchable={false}
        backspaceDelete={false}
      />
      <span className="icon-wrap">{icon}</span>
      {errorMessage && errorMessage !== '' && <span className="error">{errorMessage}</span>}
    </div>
  );
};

export default SelectCust;
