/**
 * @file   src\validations\productSchema.ts
 * @brief  This file is responsible for defining product validation schemas.
 * @date   Aug, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import * as yup from 'yup';
import { useIntlActionMessages } from '../utils/helper';

// Similar item mapping decline form validation schema.
export const SIMILARITEMS_DECLINE_FORM_SCHEMA = yup.object({
  description: yup.string().trim().required(useIntlActionMessages('SimilarItems.Decline.Form.Text.Required')),
});
