/**
 * @file   src\features\dashboard\RestaurantSearch.tsx
 * @brief  Provides Restaurant Search Component With Autocomplete
 * @date   August, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { useEffect, useState, useRef, createRef, Typeahead } from '../../utils/thirdpartyComponents';
import { searchRestaurants, getNewRestaurantsList } from '../../store/actions/dashboardActions';
import { RootState } from '../../store';
import { PAGE_SIZE, ENTER_KEY, SEARCH_MIN_CHAR_LENGTH, INPUT_MAX_LENGTH_100 } from '../../utils/constants';
import { useAppDispatch, useAppSelector } from '../../hooks';
import Search from '../../assets/icons/Search';
import { useIntlMessages } from '../../utils/helper';
import { IVendorRestaurantSearchRequest } from '../../interfaces/dashboardInterface';
import { VendorRestaurantSearchType } from '../../utils/enums';

const pageItemCount: number = process.env.REACT_APP_LIST_PAGE_SIZE !== '' ? Number(process.env.REACT_APP_LIST_PAGE_SIZE) : PAGE_SIZE;
// Restaurant search autocomplete component
const RestaurantSearch = ({ setRestId, searchType }: any) => {
  // Create action dispatch
  const dispatch = useAppDispatch();

  // Initialize search field ref.
  const restaurantOldSearchText = useRef<string>('');
  // Create a ref on search input.
  const restaurantInputRef = createRef<any>();
  // Access redux state variables
  const { restaurantSearchData, restaurantSearchList } = useAppSelector((state: RootState) => state.dashboard);

  // Component state variables
  const [restaurantSearchField, setRestaurantSearchField] = useState<string>('');
  const [restaurantTypeField, setRestaurantTypeField] = useState<string>('');
  const [selectedRestaurantId, setSelectedRestaurantId] = useState<number>(0);
  // Search for Restaurant
  useEffect(() => {
    if (
      restaurantSearchField.length > 2 &&
      selectedRestaurantId === 0 &&
      (searchType === VendorRestaurantSearchType.ORDER || searchType === VendorRestaurantSearchType.TRANSACTIONS)
    ) {
      const restaurantSearchRequest: IVendorRestaurantSearchRequest = {
        type: searchType,
        searchText: restaurantSearchField,
      };
      dispatch(searchRestaurants(restaurantSearchRequest));
    } else if (restaurantSearchField.length >= SEARCH_MIN_CHAR_LENGTH && selectedRestaurantId === 0 && searchType === VendorRestaurantSearchType.RESTAURANTS) {
      const params = {
        restaurantId: 0,
        searchText: restaurantSearchField,
        fromDate: '',
        toDate: '',
        pageSize: pageItemCount,
        pageIndex: 1,
        sortField: 0,
        sortOrder: 1,
      };
      dispatch(getNewRestaurantsList(params));
    }
  }, [restaurantSearchField]);

  // Resturant search input keydown event.
  const onRestaurantHandleKeyDown = (event: any) => {
    if (event.key === ENTER_KEY) {
      if (restaurantInputRef.current) restaurantInputRef.current.blur();
      setRestaurantSearchField(restaurantSearchField.trim());
    }
  };
  // Resturant textbox selected change event.
  const onRestaurantHandleChange = (value: any) => {
    if (value[0]) {
      restaurantOldSearchText.current = value[0].restaurantName;
      setSelectedRestaurantId(value[0].restaurantId);
      setRestId(value[0].restaurantId);
      setRestaurantSearchField(value[0].restaurantName);
      if (restaurantInputRef.current) restaurantInputRef.current.blur();
    }
  };
  // Restaurant textbox search input change event.
  const onRestaurantInputHandleChange = (value: any) => {
    if (value.trim().length >= SEARCH_MIN_CHAR_LENGTH) {
      setRestaurantSearchField(value);
    } else if (value.trim().length === 0) {
      setRestaurantSearchField('');
      setRestId(0);
    }
    restaurantOldSearchText.current = value;
    setRestaurantTypeField(value);
    setSelectedRestaurantId(0);
  };

  return (
    <Typeahead
      id="restaurantSearch"
      ref={restaurantInputRef}
      onInputChange={onRestaurantInputHandleChange}
      onChange={onRestaurantHandleChange}
      onKeyDown={onRestaurantHandleKeyDown}
      options={searchType === VendorRestaurantSearchType.RESTAURANTS ? restaurantSearchList : restaurantSearchData}
      placeholder={useIntlMessages('Restaurant.SearchVendor.Placeholder')}
      selected={
        searchType === VendorRestaurantSearchType.RESTAURANTS
          ? restaurantSearchList.filter((x: any) => x.restaurantId === selectedRestaurantId)
          : restaurantSearchData.filter((x: any) => x.restaurantId === selectedRestaurantId)
      }
      filterBy={['restaurantName']}
      minLength={restaurantOldSearchText.current.length > restaurantTypeField.length ? restaurantOldSearchText.current.length : SEARCH_MIN_CHAR_LENGTH}
      labelKey={(restaurant: any) => `${restaurant.restaurantName}`}
      renderMenuItemChildren={(restaurant: any) => <div>{restaurant.restaurantName}</div>}
      inputProps={{
        maxLength: INPUT_MAX_LENGTH_100,
      }}
    >
      <Search />
    </Typeahead>
  );
};

export default RestaurantSearch;
