/**
 * @file   src\store\actions\subscriptionAction.ts
 * @brief  This file is responsible for creating asynchronous subscription based api call.
 * @date   July, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { SubscriptionApi } from '../../utils/apiUrls';
import axios from '../../config/axiosConfig';
import { IIncentivePaymentRequest, IIncentiveRequest, IIncentiveUsersRequest } from '../../interfaces/subscriptionInterface';

// Get subscription details api
export const getSubscriptionSettings = createAsyncThunk('/subscription/subscriptionSettings', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(SubscriptionApi.SUBSCRIPTION_SETTINGS);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Update subscription settings api
export const updateSubscriptionSettings = createAsyncThunk('/subscription/updateSettings', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(SubscriptionApi.UPDATE_SETTINGS, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// List incentives api call.
export const getIncentives = createAsyncThunk('/subscription/getIncentives', async (request: IIncentiveRequest, { rejectWithValue }) => {
  try {
    let param = '';
    param += request.searchText ? `searchText=${encodeURIComponent(request.searchText.replace(/\\/g, '\\\\').replace(/_/g, '\\_').replace(/%/g, '\\%'))}&` : '';
    param += `userId=${request.userId}&`;
    param += `status=${request.status}&`;
    param += `pageSize=${request.pageSize}&`;
    param += `pageIndex=${request.pageIndex}&`;
    param += `sortField=${request.sortField}&`;
    param += `sortOrder=${request.sortOrder}`;
    const { data } = await axios.get(`${SubscriptionApi.INCENTIVES_LIST}?${param}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// List similar items mapped users api call.
export const getMappedIncentiveUsers = createAsyncThunk('/subscription/getMappedIncentiveUsers', async (request: IIncentiveUsersRequest, { rejectWithValue }) => {
  try {
    let param = '';
    param += request.searchText ? `searchText=${request.searchText}&` : '';
    param += `pageSize=${request.pageSize}`;
    const { data } = await axios.get(`${SubscriptionApi.MAPPED_USERS}?${param}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Update payment status api call.
export const updatePayment = createAsyncThunk('/subscription/updatePayment', async (paymentRequest: IIncentivePaymentRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(SubscriptionApi.INCENTIVES_PAYMENT, paymentRequest);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});
