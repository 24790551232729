/**
 * @file   src\utils\enums.ts
 * @brief  This file is responsible define enums.
 * @date   May, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

// Vendor sort field enum.
export enum VendorSortField {
  VENDOR_NAME = 1,
  ITEMS_CARRIED = 2,
  TIER = 3,
}

// Restaurant sort field enum.
export enum RestaurantSortField {
  RESTAURANT_NAME = 1,
}

// Sort order type enum.
export enum SortOrderType {
  'ASC' = 1,
  'DESC' = 2,
}

// Search field special characters.
export enum searchCharacters {
  CHAR_1 = '%',
  CHAR_2 = '\\',
  CHAR_3 = '_',
}

// Delivery days.
export enum DeliveryDays {
  SUN = 1,
  MON = 2,
  TUE = 3,
  WED = 4,
  THU = 5,
  FRI = 6,
  SAT = 7,
}

// Country code.
export enum CountryCodes {
  US = '+1',
}

// Yes, No Enum
export enum YesOrNoValues {
  NO = 0,
  YES = 1,
}

// Activate, deactivate text
export enum ActivateDeactivateText {
  DEACTIVATE = 'deactivate',
  ACTIVATE = 'activate',
}

// phone no modified enum
export enum PhoneModified {
  YES = '1',
  NO = '0',
}

// Product sort fields enum.
export enum ProductSortField {
  ITEM_NAME = 1,
  PRODUCT_GROUP = 2,
  VENDOR_NAME = 3,
  PRICE = 4,
}

// Activate, deactivate enum for restaurant popup handling.
export enum ActivateStatus {
  ACTIVATE = 1,
  DEACTIVATE = 2,
}

// Mapped items sort fields enum
export enum MappedItemsSortField {
  ORIGINAL_ITEM_NAME = 1,
  ORIGINAL_VENDOR = 2,
  SIMILAR_ITEM_NAME = 3,
  SIMILAR_VENDOR = 4,
  MARKED_BY = 5,
  USER = 6,
  RANK = 7,
}

// Similar item mapping rank enum
export enum ItemRank {
  ORIGINAL = '1',
  SIMILAR = '2',
  EXTRA = '3',
}

// Restaurant signup status enum
export enum IRestaurantSignupStatus {
  'SIGNED_UP' = 3,
  'LOCAL' = 5,
}

// Restaurant merge status enum
export enum IRestaurantMergeStatus {
  'In Progress' = 1,
  'Success' = 2,
  'Failure' = 3,
}
// User list sort fields enum
export enum UserSortFields {
  NAME = 1,
  USER_TYPE = 2,
}
// User Roles.
export enum UserRoles {
  ADMIN = 'Admin',
  ONBOARDING = 'Onboarding',
  CUSTOMER_SUPPORT = 'Customer Support',
  CUSTOM_ROLE = 'Custom Role',
}

// Role access Status.
export enum RoleAccess {
  ACCESS = 1,
  DENIED,
}

// Menu items.
export enum MenuPermissions {
  DASHBOARD = 601,
  USERS = 602,
  USER_CREATE = 603,
  ROLES = 604,
  ROLES_CREATE = 605,
  VENDOR_MANAGEMENT = 606,
  RESTAURANT_MANAGEMENT = 607,
  ONBOARDING = 608,
  PRODUCT_MANAGEMENT = 609,
  SUBSCRIPTION = 610,
  ROLE_PERMISSIONS = 611,
  ROLE_VENDOR_PERMISSION = 612,
  ROLE_RESTAURANT_PERMISSION = 613,
}

// Menu Urls
export enum MenuUrls {
  LOGIN = '/',
  FORGOT_PASSWORD = '/recover',
  RESET_PASSWORD = '/newpassword',
  DASHBOARD = '/home',
  DASHBOARD_ORDER_DETAILS = '/home/orderdetails/:orderGuid',
  DASHBOARD_TRANSACTION_DETAILS = '/home/transactiondetails/:invoiceGuid',
  ORDER_VIEW = '/home/orderview',
  USERS = '/users',
  USERS_INACTIVE = '/users/inactiveusers',
  USER_CREATE = '/users/add',
  USER_EDIT = '/users/edit',
  ROLE = '/users/roles',
  ROLE_CREATE = '/users/roles/add',
  ROLE_EDIT = '/users/roles/edit/:roleId',
  VENDORS = '/vendors',
  VENDORS_ADD = '/vendors/add',
  VENDORS_EDIT = '/vendors/edit/:vendorId',
  VENDORS_DETAILS = '/vendors/details/:vendorId',
  RESTAURANTS = '/restaurants',
  RESTAURANTS_MERGE = '/restaurants/merge',
  RESTAURANTS_ADD = '/restaurants/add',
  RESTAURANTS_EDIT = 'restaurants/edit/:restaurantId',
  RESTAURANTS_DETAILS = '/restaurants/details/:restaurantId',
  ONBOARDING = '/onboarding',
  PRODUCTS = '/products',
  PRODUCTS_MAPPED = '/products/mappeditems',
  PRODUCTS_SIMILAR_ITEMS = '/products/similaritems/:mappingId',
  SUBSCRIPTIONS = '/subscription',
  PROFILE = '/profile',
  ROLE_PERMISSION = '#',
  ROLE_PERMISSION_VENDOR = '/permissions/vendor',
  ROLE_PERMISSION_RESTAURANT = '/permissions/restaurant',
  ROLE_PERMISSION_VENDOR_ADD = '/permissions/vendor/add/:businessType/:appType',
  ROLE_PERMISSION_VENDOR_EDIT = '/permissions/vendor/edit/:roleId/:businessType/:appType',
  ROLE_PERMISSION_RESTAURANT_ADD = '/permissions/restaurant/add/:businessType/:appType',
  ROLE_PERMISSION_RESTAURANT_EDIT = '/permissions/restaurant/edit/:roleId/:businessType/:appType',
  VENDOR_COBROWSE = '/vendors/cobrowse/:id',
  RESTAURANT_COBROWSE = '/restaurants/cobrowse/:id',
  SUBSCRIPTION_LIST_VENDOR = '/vendors/subscriptions/:vendorId',
  SUBSCRIPTION_LIST_RESTAURANT = '/restaurants/subscriptions/:restaurantId',
}

// Electronic payment status.
export enum EpayStatus {
  YES = 1,
  NO = 0,
}

// Super admin default roles.
export enum DefaultAdminRoles {
  ADMIN = 50,
  ONBOARDING = 51,
  CUSTOMER_SUPPORT = 52,
}

// Incentive status.
export enum IncentiveStatus {
  DUE = 1,
  PAID = 2,
}

// Incentives sort fields enum.
export enum IncentivesSortField {
  SIMILAR_ITEM = 1,
  COUNT = 2,
  INCENTIVE = 3,
  RESTAURANT = 4,
  STATUS = 5,
  DATE = 6,
}

// Order status enum
export enum OrderStatus {
  'Order in Progress' = 1,
  'Pending' = 2,
  'Placed' = 3,
  'Cancelled' = 4,
  'Modified' = 5,
  'Rejected' = 6,
  'Delivering' = 7,
  'Delivered' = 8,
  'Not Received (Disputed)' = 9,
  'Under Discrepancy (Disputed)' = 10,
  'Catch Weight' = 11,
}

// Invoice status
export enum InvoiceStatus {
  DUE = 1,
  PAID = 2,
}

// User Mapped items sort fields enum
export enum UserMappedItemsSortField {
  ORIGINAL_ITEM_NAME = 1,
  ORIGINAL_VENDOR = 2,
  SIMILAR_ITEM_NAME = 3,
  SIMILAR_VENDOR = 4,
  MARKED_BY = 5,
  USER = 6,
  RANK = 7,
}

// Similar item processing status.
export enum SimilarItemProcessStatus {
  ALL = 0,
  PENDING = 1,
  APPROVED = 2,
  DECLINED = 3,
}

// Order items sort options enums
export enum OrderItemsSortOptions {
  ITEMNAME = 1,
  PACK,
  SIZE,
  QUANTITY,
  PRICE,
  TOTALPRICE,
  STOCKSTATUS,
}

// Date filter options
export enum DateFilterOptions {
  DAILY = 'today',
  THIS_WEEK = 'week',
  THIS_MONTH = 'month',
  CUSTOM = 'custom',
  YEAR = 'year',
}

// Graph data filter options
export enum GraphFilterOptions {
  WEEKLY = 1,
  MONTHLY = 2,
  YEARLY = 3,
}

// Dashboard graphs enum
export enum Graphs {
  TRANSACTIONS = 'transactions',
  ORDERS = 'orders',
  RESTVENDORS = 'restvendors',
}
// Search type enum for vendor and restaurant search
export enum VendorRestaurantSearchType {
  ORDER = 1,
  TRANSACTIONS = 2,
  RESTAURANTS = 3,
  VENDORS = 4,
}
// Invoice list sort options enum
export enum InvoiceListSortOptions {
  INVOICENO = 1,
  VENDOR = 2,
  RESTAURANT = 3,
  GENERATEDON = 4,
  AMOUNT = 5,
}
// Order list sort options
export enum OrderSortOptions {
  ORDERNO = 1,
  VENDOR = 2,
  RESTAURANT = 3,
  PLACEDON = 4,
  AMOUNT = 5,
}

// Onboarding status enum
export enum OnboardingStatus {
  DEMO = 1,
  ONBOARDING = 3,
  LIVE = 4,
}

// Product sort fields enum.
export enum OnboardingSortField {
  VENDOR_NAME = 1,
  PLAN_NAME = 2,
  SIGNEDUPON = 3,
}

// Onboarding form selection fields enum
export enum OnboardingSelections {
  MANAGER = 1,
  STATUS = 2,
}

// Business type enum.
export enum BusinessType {
  VENDOR = 1,
  RESTAURANT = 2,
}

// Vendor and Restaurant Roles.
export enum BusinessRoles {
  ADMIN = 'Admin',
  SALES_PERSON = 'Salesperson',
  SALES_MANAGER = 'Sales Manager',
  ASSISTANT = 'Assistant',
  CREDIT_DEPARTMENT = 'Credit Department',
  ORDERING_DEPARTMENT = 'Ordering Department',
  WAREHOUSE_MANAGER = 'Warehouse Manager',
  WAREHOUSE_EMPLOYEE = 'Warehouse Employee',
  DELIVERY_DRIVER = 'Delivery Driver',
  BUYER = 'Buyer',
  CHEF = 'chef',
  SOUS_CHEF = 'Sous Chef',
  GENERAL_MANAGER = 'General Manager',
  BOOKKEEPER = 'Bookkeeper',
  CUSTOM_ROLE = 'Custom Role',
}

// App type enum.
export enum AppType {
  VENDOR_RESTAURANT_WEB = 1,
  RESTAURANT_IPAD = 2,
  VENDOR_IPHONE = 3,
  RESTAURANT_IPHONE = 4,
  ADMIN_WEB = 5,
}

// Role permission tab enum
export enum PermissionTabs {
  WEB = 'web',
  IPAD = 'ipad',
  IPHONE = 'iphone',
}

// Notification types for role permissions.
export enum NotificationTypes {
  ORDER_PLACED = 1,
  CREDIT_RATING_UPDATE = 5,
}

// Inter-linked role permissions interface.
export enum InterLinkedPermissions {
  DELIVERIES = 107,
  PURCHASING = 111,
  PICK_TICKETS = 113,
  PURCHASE_ORDERS = 114,
}

export enum UploaderProps {
  PROFILE_IMAGE_TYPES_SUPPORTED = 'image/jpeg, image/png,image/jpg',
  PROFILE_IMAGE_MAX_SIZE = 5242880,
}

// Date context enum
export enum DateContext {
  CURRENT = 'current',
  PREVIOUS = 'previous',
  NEXT = 'next',
}

// Vendor/Restaurant transactions sort field enum.
export enum VendorRestaurantSubscriptionSortField {
  TRANSACTION_ID = 1,
  FROM_DATE = 2,
  TO_DATE = 3,
  TRANSACTION_DATE = 4,
}
