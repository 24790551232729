/**
 * @file   src\features\user\Role.tsx
 * @brief  User roles listing page
 * @date   June, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { useNavigate, useEffect, Button, Row, Col, FormattedMessage, Link, useState } from '../../utils/thirdpartyComponents';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getFromLocalStorage, useIntlActionMessages } from '../../utils/helper';
import NewUser from '../../assets/icons/Add';
import BackIcon from '../../assets/icons/Back';
import RoleList from '../../components/RoleList';
import Admin from '../../assets/DropAdminIcon.svg';
import Onboarding from '../../assets/Onboarding.svg';
import DropCustom from '../../assets/CustomRole.svg';
import CustomerSupport from '../../assets/CustomerSupport.svg';
import { getRoles } from '../../store/actions/roleAction';
import { resetRole } from '../../store/slices/roleSlice';
import { RootState } from '../../store';
import GifLoader from '../../components/GifLoader';
import { MessageToaster } from '../../utils/toastUtil';
import { UserRoles } from '../../utils/enums';
import { IUserRoles } from '../../interfaces/roleInterface';
import { SUPERADMIN_TYPE } from '../../utils/constants';

// Toast object creation.
const toast = new MessageToaster();

const Role = () => {
  // Navigate object creation.
  const navigate = useNavigate();

  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Access redux state variables.
  const { isLoading, roles, errorCode, errorMessage } = useAppSelector((state: RootState) => state.role);

  // Initialize component stat variables.
  const [userData, setUserData] = useState<any>(null);

  // handle component side effects to call api's at page load.
  useEffect(() => {
    dispatch(getRoles());
    const storageData = getFromLocalStorage('MCSA_UDT');
    const userInfo = storageData && storageData !== null ? storageData : null;
    setUserData(userInfo);
    return () => {
      dispatch(resetRole());
    };
  }, [dispatch]);

  // show error message on api failure
  useEffect(() => {
    if (errorCode > 0 && errorMessage) {
      toast.toastError(errorMessage);
    }
  }, [errorCode]);

  // Get enum keys based on values.
  function getKeyByValue(value: string) {
    const indexOfS = Object.values(UserRoles).indexOf(value as unknown as UserRoles);
    const key = Object.keys(UserRoles)[indexOfS];
    return key.toLowerCase();
  }

  // Get icon for each role
  const getIcon = (iconName: string) => {
    let icon;
    switch (iconName) {
      case getKeyByValue(UserRoles.ADMIN):
        icon = Admin;
        break;
      case getKeyByValue(UserRoles.CUSTOMER_SUPPORT):
        icon = CustomerSupport;
        break;
      case getKeyByValue(UserRoles.ONBOARDING):
        icon = Onboarding;
        break;
      case getKeyByValue(UserRoles.CUSTOM_ROLE):
        icon = Onboarding;
        break;
      default:
        icon = DropCustom;
        break;
    }
    return icon;
  };

  return (
    <div className="content-nav contnt-container-margin">
      <div className="back-navigation">
        <Link to="/users" className="back-btn">
          <BackIcon />
          <FormattedMessage id="Link.Back" />
        </Link>
      </div>
      <div className="content-nav-sub">
        <div className="content-nav-header d-flex justify-content-between align-items-center">
          <h2>
            <FormattedMessage id="HD.UserRoles" />
          </h2>
          <div>
            {userData?.userType === SUPERADMIN_TYPE && (
              <Button variant="primary" onClick={() => navigate('/users/roles/add')}>
                <NewUser />
                <FormattedMessage id="Button.NewRole" />
              </Button>
            )}
          </div>
        </div>
        <div className="content-container-padding">
          <Row>
            {roles &&
              roles.map((role: IUserRoles) => (
                <Col key={role.roleId} xl={4} lg={6} md={12} sm={12}>
                  <RoleList
                    label={role.roleName}
                    image={getIcon(role.iconFile?.trim())}
                    link={useIntlActionMessages('Link.EditRole')}
                    path={`/users/roles/edit/${role.roleId}`}
                    roleId={role.roleId}
                    isEditable={userData?.userType === SUPERADMIN_TYPE}
                  />
                </Col>
              ))}
          </Row>
        </div>
      </div>
      {isLoading && <GifLoader />}
    </div>
  );
};
export default Role;
