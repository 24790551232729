/**
 * @file   src\store\actions\onboardingAction.ts
 * @brief  This file is responsible for creating asynchronous onboarding api call.
 * @date   Aug, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../config/axiosConfig';
import { OnbaordingApi } from '../../utils/apiUrls';
import { IAssignOnboardingManagerRequest, IOnboardingListRequest, IOnboardingStatusUpdateRequest } from '../../interfaces/onboardingInterface';

// List all onbaording list api call.
export const getOnboardingList = createAsyncThunk('/onboarding/getOnboardingList', async (request: IOnboardingListRequest, { rejectWithValue }) => {
  try {
    let param = '';
    param += request.searchText ? `searchText=${encodeURIComponent(request.searchText.replace(/\\/g, '\\\\').replace(/_/g, '\\_').replace(/%/g, '\\%'))}&` : '';
    param += `vendorGuid=${request.vendorId}&`;
    param += `subscriptionPlanId=${request.subscriptionPlanId}&`;
    param += `onboardingManagerId=${request.onboardingManagerId}&`;
    param += `onboardingStatus=${request.onboardingStatus}&`;
    param += `pageSize=${request.pageSize}&`;
    param += `pageIndex=${request.pageIndex}&`;
    param += `sortField=${request.sortField}&`;
    param += `sortOrder=${request.sortOrder}`;
    const { data } = await axios.get(`${OnbaordingApi.ONBOARDING_LIST}?${param}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// List all vendors for onbaording filter api call.
export const getOnboardingVendors = createAsyncThunk('/onboarding/getOnboardingVendors', async (searchText: string, { rejectWithValue }) => {
  try {
    const param = searchText ? `searchText=${encodeURIComponent(searchText.replace(/\\/g, '\\\\').replace(/_/g, '\\_').replace(/%/g, '\\%'))}` : '';
    const { data } = await axios.get(`${OnbaordingApi.ONBAORDING_VENDORS}?${param}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// List all onbaording managers api call.
export const getOnboardingManagers = createAsyncThunk('/onboarding/getOnboardingManagers', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(OnbaordingApi.ONBAORDING_MANAGERS);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Assign onbaording manager api call.
export const assignManager = createAsyncThunk('/onboarding/assignManager', async (request: IAssignOnboardingManagerRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(OnbaordingApi.ONBOARDING_ASSIGN_MANAGER, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Update onbaording status api call.
export const updateOnboardingStatus = createAsyncThunk('/onboarding/updateOnboardingStatus', async (request: IOnboardingStatusUpdateRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(OnbaordingApi.ONBOARDING_UPDATE_STATUS, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Delete onboarding demo user api call.
export const deleteOnboardingUser = createAsyncThunk('/onboarding/deleteOnboardingUser', async (vendorGuid: string, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${OnbaordingApi.ONBOARDING_DELETE_VENDOR}/${vendorGuid}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});
