/**
 * @file   src\config\axiosConfig.ts
 * @brief  This file is responsible for axios client configurations.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import axios, { AxiosRequestConfig } from 'axios';
import { getFromLocalStorage, setLocalStorage } from '../utils/helper';
import { AuthApi } from '../utils/apiUrls';
import { STATUS_401, API_TIMEOUT, API_MAXCONTENTLENGTH, API_REDIRECTS, WEB_TYPE, RECAPTCHA_URLS, API_REFRESH_TOKEN_ERROR } from '../utils/constants';

// Craete axios instance.
const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Set axios headers.
const headers: any = {};
headers['Access-Control-Allow-Origin'] = '*';
headers['Access-Control-Allow-Headers'] = '*';
headers['Access-Control-Allow-Credentials'] = true;
headers['Access-Control-Allow-Methods'] = 'GET, POST, PUT, PATCH, DELETE';
headers['Content-Type'] = 'application/json';
headers.Accept = 'application/json';
headers['Cache-Control'] = 'no-cache';
headers['x-api-key'] = process.env.REACT_APP_API_KEY;
headers['x-app-type'] = WEB_TYPE;

// Renew access token using refresh token.
export const renewToken = async () => {
  try {
    const storageData = getFromLocalStorage('MCSA_UDT');
    const token = storageData ? storageData.accessToken : '';
    headers['x-session-token'] = token;
    const { data } = await axiosClient.get(AuthApi.REFRESH_TOKEN);
    return data;
  } catch (error: any) {
    return Promise.reject(error.message);
  }
};

// Assign default values.
axiosClient.defaults.baseURL = process.env.REACT_APP_API_URL;
axiosClient.defaults.headers = headers;
axiosClient.defaults.timeout = API_TIMEOUT;
axiosClient.defaults.maxContentLength = API_MAXCONTENTLENGTH;
axiosClient.defaults.maxRedirects = API_REDIRECTS;
axiosClient.defaults.withCredentials = true;

// Define the structure of a retry queue item.
interface RetryQueueItem {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
  config: AxiosRequestConfig;
}

// Create a list to hold the request queue.
const refreshAndRetryQueue: RetryQueueItem[] = [];

// Flag to prevent multiple token refresh requests.
let isRefreshing = false;

// Handle interceptor request.
axiosClient.interceptors.request.use(
  async (config: any) => {
    const storageData = getFromLocalStorage('MCSA_UDT');
    const capToken = storageData ? storageData.capToken : '';
    const token = storageData ? storageData.accessToken : '';

    // Modify the request.
    config.headers['x-session-token'] = token;
    if (RECAPTCHA_URLS.includes(config.url)) {
      config.headers.recaptcha_token = capToken;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

// Handle interceptor response.
axiosClient.interceptors.response.use(
  (response) => {
    if (response.data?.error === API_REFRESH_TOKEN_ERROR) {
      window.location.href = `${process.env.REACT_APP_SITE_URL}?type=snout`;
    }
    return response;
  },
  async (error) => {
    const originalRequest: AxiosRequestConfig = error.config;
    if (error.response && error.response.status === STATUS_401) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          // Refresh the access token
          const res = await renewToken();
          const newAccessToken = res?.data?.accessToken || '';
          // Update the request headers with the new access token
          error.config.headers['x-session-token'] = newAccessToken;
          const storageData = getFromLocalStorage('MCSA_UDT');
          storageData.accessToken = newAccessToken;
          localStorage.removeItem('MCSA_UDT');
          setLocalStorage('MCSA_UDT', JSON.stringify(storageData));
          // Retry all requests in the queue with the new token
          refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
            axiosClient
              .request(config)
              .then((response) => resolve(response))
              .catch((err) => reject(err));
          });

          // Clear the queue
          refreshAndRetryQueue.length = 0;

          // Retry the original request
          return axiosClient(originalRequest);
          // eslint-disable-next-line no-useless-catch
        } catch (refreshError) {
          localStorage.clear();
          window.location.href = `${process.env.REACT_APP_SITE_URL}?type=snout`;
          throw refreshError;
        } finally {
          isRefreshing = false;
        }
      }

      // Add the original request to the queue
      return new Promise<void>((resolve, reject) => {
        refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
      });
    }

    // Return a Promise rejection if the status code is not 401
    return Promise.reject(error.response);
  },
);

// Export default methods.
export default {
  get: axiosClient.get,
  post: axiosClient.post,
  put: axiosClient.put,
  patch: axiosClient.patch,
  delete: axiosClient.delete,
};
