/**
 * @file   src\components\Header.tsx
 * @brief  Header component.
 * @date   May, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import '../styles/Header.scss';
import { useState, useLocation, Navbar, Nav, NavLink, NavDropdown, Offcanvas, Modal, Button, useEffect, useNavigate, FormattedMessage } from '../utils/thirdpartyComponents';
import LogoText from '../assets/LogoText.svg';
import LogoutIcon from '../assets/icons/Logout';
import ProfilePicture from '../assets/ProfilePicture.svg';
import MainMenu from './MainMenu';
import { userLogout } from '../store/actions/userAction';
import { useAppDispatch, useAppSelector } from '../hooks';
import { RootState } from '../store';
import { getFromLocalStorage, setLocalStorage } from '../utils/helper';
import { MessageToaster } from '../utils/toastUtil';
import ProfileIcon from '../assets/icons/Profile';
import { SUPERADMIN_TYPE } from '../utils/constants';
import Settings from '../assets/icons/Settings';

// Toast object creation.
const toast = new MessageToaster();

const Header = () => {
  // Create location object to access location info.
  const location = useLocation();
  // Declare action dispatch.
  const dispatch = useAppDispatch();
  // Navigate object creation.
  const navigate = useNavigate();

  const { isSuccess, isLogoutSuccess, errorCode, errorMessage, adminProfile } = useAppSelector((state: RootState) => state.user);

  // initialize Component state.
  const [showModal, setShowModal] = useState<boolean>(false);
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const [userName, setUserName] = useState<string>('');
  const [userType, setUserType] = useState<number>(0);

  // Handle header component side effect.
  useEffect(() => {
    const userInfo = getFromLocalStorage('MCSA_UDT');
    if (userInfo?.profileImage) {
      setProfileImage(userInfo?.profileImage || '');
    }
    setUserName(userInfo?.firstName || '');
    setUserType(userInfo?.userType || 0);
  }, [location]);

  // Navigate user to login page after sucessfull logout.
  useEffect(() => {
    if (isLogoutSuccess && errorCode === 0) {
      localStorage.clear();
      setShowModal(false);
      navigate('/');
    } else if (!isLogoutSuccess && errorCode > 0 && errorMessage) {
      toast.toastError(errorMessage);
    }
  }, [isLogoutSuccess]);

  // Admin profile data state change.
  useEffect(() => {
    if (isSuccess && Object.keys(adminProfile).length > 0 && adminProfile.user?.profileImage !== null && adminProfile.user?.firstName) {
      setProfileImage(adminProfile.user?.profileImage === null ? '' : adminProfile.user?.profileImage);
      setUserName(adminProfile.user?.firstName);
      const storageData = getFromLocalStorage('MCSA_UDT');
      if (storageData) {
        storageData.profileImage = adminProfile.user?.profileImage || storageData.profileImage;
        storageData.firstName = adminProfile.user?.firstName || storageData.firstName;
        storageData.lastName = adminProfile.user?.lastName || storageData.lastName;
        localStorage.removeItem('MCSA_UDT');
        setLocalStorage('MCSA_UDT', JSON.stringify(storageData));
      }
    }
  }, [isSuccess, adminProfile]);

  // Show logout confirmation.
  const logoutConfirm = () => {
    setShowModal(true);
  };

  // Logout functionality.
  const logout = () => {
    const data = getFromLocalStorage('MCSA_UDT');
    if (data && data.accessToken) {
      dispatch(userLogout());
    }
  };

  return (
    <Navbar fixed="top" className="d-block p-0" expand={false}>
      <div className="navbar-top">
        <div className="d-flex content-nav justify-content-between">
          <Navbar.Brand href="#home">
            <img src={LogoText} alt="MADCHEF" />
          </Navbar.Brand>
          <div className="d-flex align-items-center navbar-expand nav-right header-search">
            {userType === SUPERADMIN_TYPE && (
              <NavLink to="/settings" className="settings">
                <Settings />
                <FormattedMessage id="Header.MenuSettings" />
              </NavLink>
            )}
            <Nav>
              <NavDropdown
                className="profile-menu"
                id="nav-dropdown-dark-example"
                title={
                  <div className="profile-dropdown">
                    <img className="thumbnail-image" src={profileImage || ProfilePicture} alt="user pic" />
                    {userName}
                  </div>
                }
              >
                {userType !== SUPERADMIN_TYPE && (
                  <NavDropdown.Item href="#" onClick={() => navigate('/profile')}>
                    <ProfileIcon />
                    <FormattedMessage id="Header.MenuProfile" />
                  </NavDropdown.Item>
                )}
                <NavDropdown.Item href="#action/3.2" onClick={logoutConfirm}>
                  <LogoutIcon />
                  <FormattedMessage id="Header.MenuLogout" />
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <Navbar.Toggle aria-controls="offcanvasNavbar" />
          </div>
        </div>
        <Navbar.Offcanvas id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel" placement="start">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="offcanvasNavbarLabel" className="w-100">
              <div className="d-flex mb-4 mt-3">
                <img className="thumbnail-image" src={profileImage || ProfilePicture} alt="user pic" />
                <div className="mob-profile-details">
                  {userName}
                  <Navbar>
                    <Nav>
                      {userType !== SUPERADMIN_TYPE && (
                        <Nav.Item>
                          <Nav.Link href="#" onClick={() => navigate('/profile')}>
                            <FormattedMessage id="Header.MenuProfile" />
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      <Nav.Item>
                        <Nav.Link href="#" onClick={logoutConfirm}>
                          <FormattedMessage id="Header.MenuLogout" />
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Navbar>
                </div>
              </div>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <MainMenu />
          </Offcanvas.Body>
        </Navbar.Offcanvas>
        <Modal show={showModal} onHide={() => setShowModal(false)} centered backdrop="static">
          <Modal.Header closeButton />
          <Modal.Body>
            <p className="text-center">
              <FormattedMessage id="Header.LogoutConfirmation" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" className="w-100" onClick={logout}>
              <FormattedMessage id="Button.OK" />
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <MainMenu />
    </Navbar>
  );
};
export default Header;
