/**
 * @file   src\features\vendor\List.tsx
 * @brief  Vendor listing page.
 * @date   May, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import {
  useState,
  Col,
  Row,
  Breadcrumb,
  Table,
  Dropdown,
  Modal,
  Button,
  FormattedMessage,
  useEffect,
  useRef,
  createRef,
  Typeahead,
  useNavigate,
} from '../../utils/thirdpartyComponents';
import { stringFormat, useIntlMessages, formatPhoneNumber, getRestaurantAddress, useIntlActionMessages } from '../../utils/helper';
import Select from '../../components/Select';
import SortIcon from '../../assets/icons/Sort';
import Search from '../../assets/icons/Search';
import Add from '../../assets/icons/Add';
import EmailInvitation from '../../assets/icons/EmailInvitation';
import InputCheck from '../../components/ChecBoxLabel';
import InputLabel from '../../components/InputLabel';
import { inviteVendor, vendorList, searchVendors } from '../../store/actions/vendorAction';
import { getPlans } from '../../store/actions/commonAction';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { RootState } from '../../store';
import { IVendorInviteRequest, IvendorRequest, IVendorData } from '../../interfaces/vendorInterface';
import Pagination from '../../components/Paginate';
import GifLoader from '../../components/GifLoader';
import { IPlan, ISelectOptions } from '../../interfaces/generalInterface';
import {
  DEFAULT_SUBSCRIPTION_TIER,
  ENTER_KEY,
  PAGE_SIZE,
  SEARCH_MIN_CHAR_LENGTH,
  DEFAULT_SORT_FIELD,
  DEFAULT_PAGE_INDEX,
  DEFAULT_DEACTIVATE_STATUS,
  INPUT_MAX_LENGTH_200,
  INPUT_MAX_LENGTH_100,
} from '../../utils/constants';
import UpArrow from '../../assets/icons/UpArrow';
import DownArrow from '../../assets/icons/DownArrow';
import UpDownArrow from '../../assets/icons/UpDownArrow';
import { SortOrderType, VendorSortField } from '../../utils/enums';
import { validateForm } from '../../utils/formValidation';
import { INVITE_VENDOR_SCHEMA } from '../../validations/vendorSchema';
import { MessageToaster } from '../../utils/toastUtil';
import { resetVendorState } from '../../store/slices/vendorSlice';
import { resendInvitation } from '../../store/actions/userAction';
import { resetUserInvite } from '../../store/slices/userSlice';

// Toast object creation.
const toast = new MessageToaster();
// Declare page item count to be displayed per page.
const pageItemCount: number = process.env.REACT_APP_LIST_PAGE_SIZE !== '' ? Number(process.env.REACT_APP_LIST_PAGE_SIZE) : PAGE_SIZE;
const VendorList = () => {
  // Navigate object creation.
  const navigate = useNavigate();

  // Initialize search field ref.
  const oldSearchText = useRef<string>('');

  // Create a ref on search input.
  const inputRef = createRef<any>();

  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Access redux state variables.
  const { isLoading, totalCount, vendors, inviteVendorLoading, inviteVendorSuccess, errorCode, errorMessage, searchVendorList } = useAppSelector(
    (state: RootState) => state.vendor,
  );
  const { isSuccess, plans } = useAppSelector((state: RootState) => state.common);
  const { resendInvitationLoading, resendInvitationErrorMessage, resendInvitationSuccess, resendInvitationErrorCode } = useAppSelector((state: RootState) => state.user);

  // Initialize component state variables.
  const [searchField, setSearchField] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const [typeField, setTypeField] = useState<string>('');
  const [subscriptionPlanId, setSubscriptionPlanId] = useState<number>(0);
  const [showDeactivateOnly, setDeactivatedOnly] = useState<number>(DEFAULT_DEACTIVATE_STATUS);
  const [pageSize] = useState<number>(pageItemCount);
  const [pageIndex, setPageIndex] = useState<number>(DEFAULT_PAGE_INDEX);
  const [sortField, setSortField] = useState<number>(DEFAULT_SORT_FIELD);
  const [sortOrder, setSortOrder] = useState<number>(SortOrderType.ASC);
  const [emailInvite, setEmailInvitePop] = useState<boolean>(false);
  const [emailSent, setEmailSentPop] = useState<boolean>(false);
  const [planList, setPlans] = useState<ISelectOptions[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<ISelectOptions[]>(DEFAULT_SUBSCRIPTION_TIER);
  const [inviteName, setInviteName] = useState<string>('');
  const [inviteEmail, setInviteEmail] = useState<string>('');
  const [selectedVendorId, setSelectedVendorId] = useState<number>(0);
  const [errorFields, setErrorFields] = useState<any>({});
  const [resendPopUp, setResendPopUp] = useState<boolean>(false);
  const [selectedVendor, setSelectedVendor] = useState<any>({});

  // Fetch vendors action call.
  const fetchVendors = () => {
    const vendorRequest: IvendorRequest = {
      vendorId: selectedVendorId,
      searchText: selectedVendorId !== 0 ? '' : searchText,
      subscriptionPlanId,
      showDeactivateOnly,
      pageSize,
      pageIndex,
      sortField,
      sortOrder,
    };
    dispatch(vendorList(vendorRequest));
  };

  // Handle component side effect to fetch all plans.
  useEffect(() => {
    dispatch(getPlans());
    return () => {
      dispatch(resetVendorState());
    };
  }, []);

  // Vendor list api call on pageIndex, showDeactivateOnly, subscriptionPlanId, sortField, sortOrder state change.
  useEffect(() => {
    fetchVendors();
  }, [pageIndex, showDeactivateOnly, subscriptionPlanId, searchText, sortField, sortOrder]);

  // Search field state change handling for list vendors.
  useEffect(() => {
    if (searchField.length >= SEARCH_MIN_CHAR_LENGTH && selectedVendorId === 0) {
      const vendorSearchRequest: IvendorRequest = {
        vendorId: 0,
        searchText: searchField,
        subscriptionPlanId,
        showDeactivateOnly,
        pageSize,
        pageIndex: 1,
        sortField,
        sortOrder,
      };
      dispatch(searchVendors(vendorSearchRequest));
    }
  }, [searchField]);

  // Show success/error message for resend invitation api
  useEffect(() => {
    if (resendInvitationSuccess && resendInvitationErrorCode === 0) {
      toast.toastSuccess(resendInvitationErrorMessage);
      dispatch(resetUserInvite());
    } else if (resendInvitationErrorCode > 0) {
      toast.toastError(resendInvitationErrorMessage);
      dispatch(resetUserInvite());
    }
  }, [resendInvitationSuccess, resendInvitationErrorCode]);

  // Map plans to select options.
  const mapTiers = (input: IPlan): ISelectOptions => {
    return {
      label: input.planName,
      value: input.planId,
    };
  };

  // Handle fetch plans result state change.
  useEffect(() => {
    if (isSuccess) {
      const selectedPlans = plans.map((m) => mapTiers(m));
      selectedPlans.unshift(DEFAULT_SUBSCRIPTION_TIER[0]);
      setPlans(selectedPlans);
    }
  }, [isSuccess, plans]);

  // Close invite vendor form popup
  const closeVendorInviteForm = () => {
    setEmailInvitePop(false);
    setInviteName('');
    setInviteEmail('');
    setErrorFields({});
  };

  // Invite vendor api response state change.
  useEffect(() => {
    if (inviteVendorSuccess && errorCode === 0) {
      closeVendorInviteForm();
      setEmailSentPop(true);
      dispatch(resetVendorState());
    } else if (errorCode > 0 && errorMessage) {
      toast.toastError(errorMessage);
      dispatch(resetVendorState());
    }
  }, [inviteVendorSuccess, errorCode]);

  // Handle show deactivated vendors check box change event.
  const onDeactivateCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeactivatedOnly(event.target.checked ? 1 : 0);
    setPageIndex(1);
  };

  // select tier change event.
  const onPlanChange = (values: ISelectOptions[]) => {
    setSelectedPlan(values);
    setSubscriptionPlanId(values[0]?.value);
    setPageIndex(1);
  };

  // Sort header change event.
  const changeSortField = (field: number) => {
    let newSortOrder = SortOrderType.ASC;
    if (field === sortField) {
      newSortOrder = sortOrder === SortOrderType.ASC ? SortOrderType.DESC : SortOrderType.ASC;
    }
    setSortField(field);
    setSortOrder(newSortOrder);
  };

  // Invitor name input field change event.
  const onNameChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInviteName(value);
    const nameValidation = {
      name: value.trim(),
    };
    const errorresult = await validateForm(nameValidation, INVITE_VENDOR_SCHEMA, errorFields);
    setErrorFields(errorresult);
  };

  // Invitor email input field change event.
  const onEmailChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInviteEmail(value);
    const emailValidation = {
      email: inviteEmail.trim(),
    };
    const errorresult = await validateForm(emailValidation, INVITE_VENDOR_SCHEMA, errorFields);
    setErrorFields(errorresult);
  };

  // Invite submit handler.
  const onInviteSubmit = async () => {
    const inviteForm: IVendorInviteRequest = {
      name: inviteName.trim(),
      email: inviteEmail.trim(),
    };
    const errorresult = await validateForm(inviteForm, INVITE_VENDOR_SCHEMA, errorFields);
    setErrorFields(errorresult);
    if (Object.keys(errorresult).length === 0) {
      dispatch(inviteVendor(inviteForm));
    }
  };

  // Vendor search input keydown event.
  const onVendorHandleKeyDown = (event: any) => {
    const { value } = event.target;
    if (event.key === ENTER_KEY) {
      if (inputRef.current) inputRef.current.blur();
      setPageIndex(1);
      setSearchText(value?.trim());
      setSearchField(searchField.trim());
    }
  };

  // Vendor textbox selected change event.
  const onVendorHandleChange = (value: any) => {
    if (value[0]) {
      oldSearchText.current = value[0].vendorName;
      setSearchText(value[0].vendorName);
      setSelectedVendorId(value[0].vendorId);
      setPageIndex(1);
      setSearchField(value[0].vendorName);
      if (inputRef.current) inputRef.current.blur();
    }
  };

  // Vendor textbox search input change event.
  const onVendorInputHandleChange = (value: any) => {
    if (value.trim().length >= SEARCH_MIN_CHAR_LENGTH) {
      /* Search call will be fired when character length greater than 2 */
      setSearchField(value);
    } else if (value.trim().length === 0) {
      setSearchField('');
      setSearchText('');
    }
    oldSearchText.current = value;
    setTypeField(value);
    setSelectedVendorId(0);
  };

  // Navigation to vendor details
  const navigateToVendorDetails = (evt: React.MouseEvent<HTMLAnchorElement>, vendorId: number) => {
    evt.preventDefault();
    navigate(`/vendors/details/${vendorId}`);
  };

  // Navigation to add vendor screen
  const navigateToVendorAdd = (evt: React.MouseEvent<HTMLElement>) => {
    evt.preventDefault();
    navigate('/vendors/add');
  };
  // Resend invitation mail api call
  const resendEmailInvitation = () => {
    setResendPopUp(false);
    const request = {
      email: selectedVendor.email.trim(),
      firstName: selectedVendor.firstName.trim(),
      lastName: selectedVendor.lastName.trim(),
    };
    dispatch(resendInvitation(request));
  };

  return (
    <div className="content-nav contnt-container-margin">
      <div className="back-navigation">
        <Breadcrumb>
          <Breadcrumb.Item href="#" onClick={() => navigate('/home')}>
            <FormattedMessage id="Menu.Home" />
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
            <FormattedMessage id="Vendor.PageHD" />
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="content-nav-sub">
        <div className="content-nav-header d-flex justify-content-between align-items-center">
          <h2>
            <FormattedMessage id="Vendor.PageHD" />
          </h2>
          <div>
            <Dropdown className="button-dropdown">
              <Dropdown.Toggle variant="primary" id="dropdown-basic" className="button-dropdownmenu">
                <Add /> <FormattedMessage id="Vendor.Add.Button.text" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="#" onClick={(e) => navigateToVendorAdd(e)}>
                  <Add /> <FormattedMessage id="Vendor.AddNew" />
                </Dropdown.Item>
                <Dropdown.Item href="#" onClick={() => setEmailInvitePop(true)}>
                  <EmailInvitation /> <FormattedMessage id="Vendor.SendEmail" />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="content-filter-main border-0 pb-2">
          <Row className="align-items-center">
            <Col xs={12} md={6} lg={5} xl={3} className="no-margin search-auto">
              <Typeahead
                id="vendorSearch"
                ref={inputRef}
                onInputChange={onVendorInputHandleChange}
                onChange={onVendorHandleChange}
                onKeyDown={onVendorHandleKeyDown}
                options={searchVendorList}
                placeholder={useIntlMessages('Vendor.SearchVendor.Placeholder')}
                selected={searchVendorList.filter((x: any) => x.vendorId === selectedVendorId)}
                filterBy={['vendorName']}
                minLength={oldSearchText.current.length > typeField.length ? oldSearchText.current.length : SEARCH_MIN_CHAR_LENGTH}
                labelKey={(vendor: any) => `${vendor.vendorName}`}
                renderMenuItemChildren={(vendor: any) => <div>{vendor.vendorName}</div>}
                inputProps={{
                  maxLength: INPUT_MAX_LENGTH_100,
                }}
              >
                <Search />
              </Typeahead>
            </Col>
            <Col xs={12} md={6} lg={5} xl={3} className="no-margin">
              <Select
                id="userType"
                name="userType"
                options={planList}
                placeholder="sample"
                dropdownGap={0}
                values={selectedPlan}
                icon={<SortIcon />}
                onChange={(values) => onPlanChange(values)}
              />
            </Col>
          </Row>
          <Col className="no-margin d-flex justify-content-end">
            <InputCheck id="global" name="Produce" label={useIntlMessages('Vendor.ShowDeactivated')} checked={showDeactivateOnly === 1} onChange={onDeactivateCheckBoxChange} />
          </Col>
        </div>
        <Table responsive>
          <thead>
            <tr>
              <th className="w-350" onClick={() => changeSortField(VendorSortField.VENDOR_NAME)} style={{ cursor: 'pointer' }}>
                <FormattedMessage id="Vendor.VendorName" />
                {sortField !== VendorSortField.VENDOR_NAME && <UpDownArrow />}
                {sortField === VendorSortField.VENDOR_NAME && sortOrder === SortOrderType.DESC && <DownArrow />}
                {sortField === VendorSortField.VENDOR_NAME && sortOrder === SortOrderType.ASC && <UpArrow />}
              </th>
              <th className="w-200" onClick={() => changeSortField(VendorSortField.ITEMS_CARRIED)} style={{ cursor: 'pointer' }}>
                <FormattedMessage id="Vendor.ItemsCarried" />
                {sortField !== VendorSortField.ITEMS_CARRIED && <UpDownArrow />}
                {sortField === VendorSortField.ITEMS_CARRIED && sortOrder === SortOrderType.DESC && <DownArrow />}
                {sortField === VendorSortField.ITEMS_CARRIED && sortOrder === SortOrderType.ASC && <UpArrow />}
              </th>
              <th className="w-350">
                <FormattedMessage id="Vendor.Address" />
              </th>
              <th className="w-150" onClick={() => changeSortField(VendorSortField.TIER)} style={{ cursor: 'pointer' }}>
                <FormattedMessage id="Vendor.Tier" />
                {sortField !== VendorSortField.TIER && <UpDownArrow />}
                {sortField === VendorSortField.TIER && sortOrder === SortOrderType.DESC && <DownArrow />}
                {sortField === VendorSortField.TIER && sortOrder === SortOrderType.ASC && <UpArrow />}
              </th>
              <th className="w-170">
                <FormattedMessage id="Vendor.Contact" />
              </th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {vendors &&
              vendors.length > 0 &&
              vendors.map((vendor: IVendorData) => (
                <tr key={vendor.vendorId} className={!vendor.isActive ? 'error-item' : ''}>
                  <td>
                    <a href="#" onClick={(e) => navigateToVendorDetails(e, vendor.vendorId)}>
                      {vendor.vendorName}
                    </a>
                  </td>
                  <td>{vendor.itemsCarried}</td>
                  <td>{getRestaurantAddress(vendor.address)}</td>
                  <td>{vendor.planName}</td>
                  <td>{`${vendor.countrycode} ${formatPhoneNumber(vendor.phone)}`}</td>
                  <td>
                    {vendor.isActive && vendor.isAddedFromAdminPortal && !vendor.isLoggedIn && (
                      <Button
                        variant="outline-secondary"
                        onClick={() => {
                          setResendPopUp(true);
                          setSelectedVendor(vendor);
                        }}
                      >
                        <FormattedMessage id="Button.ResendInvitation" />
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            {!vendors ||
              (vendors && vendors.length === 0 && (
                <tr>
                  <td colSpan={7} className="text-center">
                    <FormattedMessage id="Vendor.table.emptymessage" />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <div className="content-filter-main d-lg-flex justify-content-lg-between">
          {vendors.length > 0 && (
            <p className="m-0">
              {stringFormat(
                totalCount === 1 ? useIntlActionMessages('Vendor.Pagination.SingleItem.Text') : useIntlActionMessages('Vendor.Pagination.Text'),
                vendors.length > 0 ? ((totalCount === 1 ? 1 : pageIndex) - 1) * pageSize + 1 : 0,
                ((totalCount === 1 ? 1 : pageIndex) - 1) * pageSize + vendors.length,
                totalCount,
              )}
            </p>
          )}
          {vendors.length > 0 && totalCount > pageSize && (
            <Pagination totalCount={totalCount} pageLimit={pageSize} setCurrentPage={(page: number) => setPageIndex(page)} currentPage={pageIndex - 1} prevPage={-1} />
          )}
        </div>
      </div>
      {(isLoading || inviteVendorLoading || resendInvitationLoading) && <GifLoader />}
      {/*  Email Invitation popup */}
      <Modal show={emailInvite} onHide={() => closeVendorInviteForm()} centered className="mx-w-550" size="lg">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            <FormattedMessage id="Vendor.EmailInvite.HD" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <small>
              <FormattedMessage id="Vendor.EmailInvite.text" />
            </small>
          </p>
          <Col lg="11">
            <InputLabel
              id="inviteName"
              name="inviteName"
              type="text"
              value={inviteName}
              maxLength={INPUT_MAX_LENGTH_100}
              label={useIntlMessages('Vendor.VendorName')}
              errorMessage={errorFields?.name}
              onChange={onNameChange}
            />
          </Col>
          <Col lg="11">
            <InputLabel
              id="inviteEmail"
              name="inviteEmail"
              type="text"
              value={inviteEmail}
              maxLength={INPUT_MAX_LENGTH_200}
              label={useIntlMessages('Vendor.Email')}
              errorMessage={errorFields?.email}
              onChange={onEmailChange}
            />
          </Col>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" className="btn-modal-footer" onClick={() => closeVendorInviteForm()}>
            <FormattedMessage id="Button.Cancel" />
          </Button>
          <Button variant="primary" onClick={onInviteSubmit} disabled={Object.keys(errorFields).length > 0 || inviteName.trim().length === 0 || inviteEmail.trim().length === 0}>
            <FormattedMessage id="Button.Confirm" />
          </Button>
        </Modal.Footer>
      </Modal>

      {/*  Email Sent popup */}
      <Modal show={emailSent} onHide={() => setEmailSentPop(false)} centered>
        <Modal.Body>
          <FormattedMessage id="Vendor.EmailInvite.SentText" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setEmailSentPop(false)}>
            <FormattedMessage id="Button.OK" />
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Resend email invitation confirmation popup */}
      <Modal show={resendPopUp} onHide={() => setResendPopUp(false)} centered size="lg">
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-sm">
            <FormattedMessage id="HD.ResendInvitation" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <FormattedMessage id="User.InvitationAgain" />
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setResendPopUp(false)}>
            <FormattedMessage id="Button.Cancel" />
          </Button>
          <Button variant="primary" onClick={() => resendEmailInvitation()}>
            <FormattedMessage id="Button.OK" />
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default VendorList;
