/**
 * @file   src\store\actions\userAction.ts
 * @brief  This file is responsible for creating asynchronous user api call.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios2 from 'axios';
import axios from '../../config/axiosConfig';
import { UserApi } from '../../utils/apiUrls';
import { IResetRequest, IAddUserRequest, IDeleteUserRequest, IListUserRequest, IResendInvitationRequest } from '../../interfaces/userInterface';
import { getFromLocalStorage } from '../../utils/helper';

// User logout api call.
export const userLogout = createAsyncThunk('/user/userLogout', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(UserApi.LOGOUT);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Reset password api call.
export const resetPassword = createAsyncThunk('/user/resetPassword', async (resetRequest: IResetRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(UserApi.RESET_PASSWORD, resetRequest);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Update admin password api call.
export const adminPasswordUpdate = createAsyncThunk('/user/adminPasswordUpdate', async (updateRequest: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(UserApi.MODIFY_PASSWORD, updateRequest);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Get admin profile details api call.

export const getAdminProfile = createAsyncThunk('/admin/adminProfile', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(UserApi.ADMIN_PROFILE);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});
// Update admin profile api call.
export const updateAdminProfile = createAsyncThunk('/admin/updateProfile', async (updateRequest: any, { rejectWithValue }) => {
  try {
    const storageData = getFromLocalStorage('MCSA_UDT');
    const token = storageData ? storageData.accessToken : '';
    const apiBase = process.env.REACT_APP_API_URL;
    const apiKey = process.env.REACT_APP_API_KEY;
    const { data } = await axios2({
      method: 'post',
      url: `${apiBase}${UserApi.SAVE_ADMIN_PROFILE}`,
      data: updateRequest,
      headers: {
        'Content-Type': 'multipart/form-data',
        'x-api-key': apiKey,
        'x-session-token': token,
      },
    });
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Get list of users api call
export const getUsersList = createAsyncThunk('/admin/getUsersList', async (requestData: IListUserRequest, { rejectWithValue }) => {
  try {
    let param = '?';
    param += `inactiveUsers=${requestData.inactiveUsers}&`;
    param += `&pageIndex=${requestData.pageIndex}`;
    param += `&pageSize=${requestData.pageSize}`;
    param += `&sortField=${requestData.sortField}`;
    param += `&sortOrder=${requestData.sortOrder}`;

    const { data } = await axios.get(`${UserApi.GET_USERS_LIST}${param}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Add user api call
export const addUser = createAsyncThunk('/admin/addUser', async (addRequest: IAddUserRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(UserApi.ADD_USER, addRequest);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Delete user api call
export const deleteUser = createAsyncThunk('/admin/deleteUser', async (addRequest: IDeleteUserRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(UserApi.DELETE_USER, addRequest);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Resend invitation to user api call
export const resendInvitation = createAsyncThunk('/admin/resendInvitation', async (request: IResendInvitationRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(UserApi.RESEND_EMAIL, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});
