/**
 * @file   src\features\product\Mappeditems.tsx
 * @brief  This page is responsible for listing similar items mapped.
 * @date   June, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import {
  useState,
  Button,
  Row,
  Col,
  Table,
  Collapse,
  Typeahead,
  FormattedMessage,
  useEffect,
  useRef,
  createRef,
  AsyncTypeahead,
  useCallback,
  useNavigate,
  Link,
} from '../../utils/thirdpartyComponents';
import { useAppDispatch, useAppSelector } from '../../hooks';
import InputCheck from '../../components/ChecBoxLabel';
import Filter from '../../assets/icons/Filter';
import Arrow from '../../assets/icons/Arrow';
import Search from '../../assets/icons/Search';
import Select from '../../components/SelectLabel';
import Pagination from '../../components/Paginate';
import { stringFormat, useIntlActionMessages, useIntlMessages } from '../../utils/helper';
import {
  DEFAULT_PAGE_INDEX,
  DEFAULT_SORT_FIELD,
  ENTER_KEY,
  INPUT_MAX_LENGTH_200,
  INPUT_MAX_LENGTH_100,
  PAGE_SIZE,
  SEARCH_MIN_CHAR_LENGTH,
  SEARCH_PAGESIZE,
} from '../../utils/constants';
import { RootState } from '../../store';
import { ItemRank, MappedItemsSortField, SortOrderType } from '../../utils/enums';
import { getMappedItems, getOriginalItems } from '../../store/actions/productAction';
import { getMappedRestaurants, getRestaurantUsers } from '../../store/actions/restaurantAction';
import { IProductMappedListRequest } from '../../interfaces/productInterface';
import GifLoader from '../../components/GifLoader';
import UpDownArrow from '../../assets/icons/UpDownArrow';
import DownArrow from '../../assets/icons/DownArrow';
import UpArrow from '../../assets/icons/UpArrow';
import { IMappedRestaurantsListRequest, IMappedRestaurantUsers } from '../../interfaces/restaurantInterface';
import { ISelectOptions } from '../../interfaces/generalInterface';
import BackIcon from '../../assets/icons/Back';

// Declare page item count to be displayed per page.
const pageItemCount: number = process.env.REACT_APP_LIST_PAGE_SIZE !== '' ? Number(process.env.REACT_APP_LIST_PAGE_SIZE) : PAGE_SIZE;
const Mapping = () => {
  // Navigate object creation.
  const navigate = useNavigate();

  // Initialize search field ref for original items search field and restaurant search field.
  const oldSearchText = useRef('');
  const oldRestaurantSearchText = useRef('');

  // Create a ref on search input for original items search field and restaurant search input.
  const inputRef = createRef<any>();
  const inputRestaurantRef = createRef<any>();

  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Access redux state variables.
  const { isLoading, mappedItemsCount, mappedItems, originalItems } = useAppSelector((state: RootState) => state.product);
  const { mappedRestaurants, users, isSuccess: isUserSuccess, isMappedRestaurantLoading } = useAppSelector((state: RootState) => state.restaurant);

  // Initialize component state variables.
  const [open, setOpen] = useState<boolean>(false);
  const [pageSize] = useState<number>(pageItemCount);
  const [searchField, setSearchField] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const [typeField, setTypeField] = useState<string>('');
  const [typeheadUniqueFilter, setTypeheadUniqueFilter] = useState<string>('');
  const [searchRestaurantField, setSearchRestaurantField] = useState<string>('');
  const [pageIndex, setPageIndex] = useState<number>(DEFAULT_PAGE_INDEX);
  const [sortField, setSortField] = useState<number>(DEFAULT_SORT_FIELD);
  const [sortOrder, setSortOrder] = useState<number>(SortOrderType.ASC);
  const [selectedItemId, setSelectedItemId] = useState<number>(0);
  const [selectedRestaurantId, setSelectedRestaurantId] = useState<number>(0);
  const [userList, setUserList] = useState<ISelectOptions[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<ISelectOptions[]>([]);
  const [userIds, setSelectedUserIds] = useState<number[]>([]);
  const [rankList, setRankList] = useState<ISelectOptions[]>([]);
  const [ranks, setSelectedRanks] = useState<number[]>([]);
  const [filterCount, setFilterCount] = useState<number>(0);

  // Fetch mapped items action call.
  const fetchMappedItems = () => {
    const mappedItemsFilterRequest: IProductMappedListRequest = {
      itemId: selectedItemId,
      searchText: selectedItemId !== 0 ? '' : searchText,
      userIds,
      rank: ranks,
      pageSize,
      pageIndex,
      sortField,
      sortOrder,
    };
    dispatch(getMappedItems(mappedItemsFilterRequest));
  };

  // Filter section rank configuration setting at initial loading.
  useEffect(() => {
    const mappedItemRanks: ISelectOptions[] = [];
    Object.values(ItemRank).forEach((val) => {
      const indexOfVal = Object.values(ItemRank).indexOf(val as unknown as ItemRank);
      mappedItemRanks.push({ label: Object.keys(ItemRank)[indexOfVal], value: Number(val) } as ISelectOptions);
    });
    setRankList(mappedItemRanks);
  }, []);

  // Product mapped items list api call on pageIndex, showDeactivateOnly, subscriptionPlanId, sortField, sortOrder state change.
  useEffect(() => {
    fetchMappedItems();
  }, [pageIndex, searchText, sortField, sortOrder, typeheadUniqueFilter, userIds, ranks]);

  // Fetch original items list api call on searchField state change.
  useEffect(() => {
    if (searchField.length >= SEARCH_MIN_CHAR_LENGTH && selectedItemId === 0) {
      const originalItemSearchRequest: IProductMappedListRequest = {
        itemId: 0,
        searchText: searchField,
        userIds: [],
        rank: [],
        pageSize,
        pageIndex,
        sortField,
        sortOrder,
      };
      dispatch(getOriginalItems(originalItemSearchRequest));
    }
  }, [searchField]);

  // Fetch users when restaurant is selected.
  useEffect(() => {
    dispatch(getRestaurantUsers(selectedRestaurantId));
  }, [selectedRestaurantId]);

  // Mapped restaurant users fetch result state change.
  useEffect(() => {
    if (isUserSuccess) {
      /* Map users data to the select field option. */
      const userOptions = users.map(
        (user: IMappedRestaurantUsers): ISelectOptions => ({
          label: `${user.firstName} ${user.lastName}`,
          value: user.userId,
        }),
      );
      setUserList(userOptions);
    }
  }, [isUserSuccess, users]);

  // Handle mapped filter state change.
  useEffect(() => {
    const appliedFilterCount = ranks.length + userIds.length;
    setFilterCount(appliedFilterCount);
  }, [ranks, userIds]);

  // Sort header change event.
  const changeSortField = (field: number) => {
    let newSortOrder = SortOrderType.ASC;
    if (field === sortField) {
      newSortOrder = sortOrder === SortOrderType.ASC ? SortOrderType.DESC : SortOrderType.ASC;
    }
    setSortField(field);
    setSortOrder(newSortOrder);
  };

  // Mapped items search input keydown event.
  const onProductSearchHandleKeyDown = (event: any) => {
    const { value } = event.target;
    if (event.key === ENTER_KEY) {
      if (inputRef.current) {
        inputRef.current.blur();
      }
      setSearchText(value?.trim());
      setSearchField(searchField.trim());
    }
  };

  // Mapped items search textbox selected change event.
  const onProductSearchHandleChange = (value: any) => {
    if (value[0]) {
      oldSearchText.current = value[0].itemName;
      setSelectedItemId(value[0].itemId);
      setSearchText(value[0].itemName);
      setTypeheadUniqueFilter(value[0].itemGuid);
      setPageIndex(1);
      setSearchField(value[0].itemName);
      if (inputRef.current) {
        inputRef.current.blur();
      }
    }
  };

  // Mapped items search textbox search input change event.
  const onProductSearchInputHandleChange = (value: any) => {
    if (value.trim().length >= SEARCH_MIN_CHAR_LENGTH) {
      /* Search call will be fired when character length greater than 2 */
      setSearchField(value);
    } else if (value.trim().length === 0) {
      setSearchField('');
      setSearchText('');
      setTypeheadUniqueFilter('');
    }
    oldSearchText.current = value;
    setTypeField(value);
    setSelectedItemId(0);
  };

  // Restaurant search input keydown event.
  const onRestaurantHandleKeyDown = (event: any) => {
    if (event.key === ENTER_KEY) {
      if (inputRestaurantRef.current) {
        inputRestaurantRef.current.blur();
      }
      setSearchRestaurantField(searchRestaurantField.trim());
    }
  };

  // Restaurant textbox selected change event.
  const onRestaurantHandleChange = (value: any) => {
    if (value[0]) {
      oldRestaurantSearchText.current = value[0].vendorName;
      setPageIndex(1);
      setSearchRestaurantField(value[0].restaurantName);
      setSelectedRestaurantId(value[0]?.restaurantId || 0);
      if (inputRestaurantRef.current) {
        inputRestaurantRef.current.blur();
      }
    }
  };

  // Restaurant textbox search input change event.
  const onRestaurantInputHandleChange = (value: any) => {
    if (value.trim().length >= SEARCH_MIN_CHAR_LENGTH) {
      /* Search call will be fired when character length greater than 2 */
      setSearchRestaurantField(value);
    } else if (value.trim().length === 0) {
      setSearchRestaurantField('');
      setSelectedRestaurantId(0);
      setSelectedUsers([]);
      setSelectedUserIds([]);
    }
  };

  // Restaurant search trigger event.
  const onRestaurantHandleSearch = useCallback((query: string) => {
    const restaurantSearchRequest: IMappedRestaurantsListRequest = {
      searchText: query,
      pageSize: SEARCH_PAGESIZE,
    };
    if (query.trim().length >= SEARCH_MIN_CHAR_LENGTH) {
      /* Search call will be fired when character length greater than 2 */
      dispatch(getMappedRestaurants(restaurantSearchRequest));
    }
    oldRestaurantSearchText.current = query;
  }, []);

  // Select input(product group) change event.
  const onSelectChange = async (values: ISelectOptions[]) => {
    setSelectedUsers(values);
    setSelectedUserIds(values.map((arr) => arr.value));
  };

  // Rank checkbox change event.
  const onRankFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    let newArray = [...ranks];
    if (ranks.includes(Number(value))) {
      const itemIndex = ranks.indexOf(Number(value));
      newArray = ranks.filter((_, i) => i !== itemIndex);
      setSelectedRanks(newArray);
    } else {
      newArray.push(Number(value));
      setSelectedRanks(newArray);
    }
  };

  // Set filter label.
  const setFilterLabel = () => {
    if (filterCount > 0) {
      return `${filterCount} ${filterCount > 1 ? useIntlMessages('Filter.Applied.Multiple') : useIntlMessages('Filter.Applied.Single')}`;
    }
    return open ? useIntlMessages('Filter.Hide') : useIntlMessages('Filter.Show');
  };

  // Clear filter method.
  const clearFilters = () => {
    setFilterCount(0);
    setSearchRestaurantField('');
    inputRestaurantRef.current?.clear();
    setSelectedUsers([]);
    setSelectedUserIds([]);
    setSelectedRestaurantId(0);
    setSelectedRanks([]);
  };

  // Navigation to similar item details.
  const navigateToSimilarItems = (evt: React.MouseEvent<HTMLAnchorElement>, mappingId: number, itemName: string, similarItemName: string) => {
    evt.preventDefault();
    navigate(`/products/similarItems/${itemName.replace(/[^a-zA-Z0-9-]/g, '')}-${similarItemName.replace(/[^a-zA-Z0-9-]/g, '')}`, {
      state: { id: mappingId, from: 'mappeditems' },
    });
  };

  return (
    <div className="content-nav contnt-container-margin">
      <div className="back-navigation">
        <Link to="/products" className="back-btn">
          <BackIcon />
          <FormattedMessage id="Link.Back" />
        </Link>
      </div>
      <div className="content-nav-sub">
        <div className="content-nav-header">
          <div className="d-xl-flex justify-content-between align-items-center">
            <h2>
              <FormattedMessage id="HD.Mapping" />
            </h2>
          </div>
        </div>
        <div className="content-filter-main border-0 pb-5">
          <Row>
            <Col xs={12} md={6} lg={5} xl={3} className="no-margin search-auto">
              <Typeahead
                id="itemSearchInput"
                ref={inputRef}
                onInputChange={onProductSearchInputHandleChange}
                onChange={onProductSearchHandleChange}
                onKeyDown={onProductSearchHandleKeyDown}
                options={originalItems}
                placeholder={useIntlMessages('Search.OriginalItem')}
                selected={originalItems.filter((x: any) => x.itemId === selectedItemId)}
                filterBy={['itemId', 'itemName']}
                minLength={oldSearchText.current.length > typeField.length ? oldSearchText.current.length : SEARCH_MIN_CHAR_LENGTH}
                labelKey={(item: any) => `${item.itemName}`}
                inputProps={{
                  maxLength: INPUT_MAX_LENGTH_200,
                }}
                renderMenuItemChildren={(item: any) => (
                  <div>
                    {item.itemName}
                    <div>
                      <small>{item.vendorName}</small>
                    </div>
                  </div>
                )}
              >
                <Search />
              </Typeahead>
            </Col>

            <Col className="d-inline-flex me-4 mt-mx-lg-top">
              <Button variant="link" onClick={() => setOpen(!open)} aria-expanded={open} className="btn-filter">
                <span className="mb-0 me-2">
                  <Filter />
                </span>
                {setFilterLabel()}
                <span>
                  <Arrow />
                </span>
              </Button>
            </Col>
          </Row>

          <Collapse in={open}>
            <div className="table-filter py-0">
              <h4 className="mt-4 mb-5">
                <FormattedMessage id="Filter.Product" />
              </h4>
              <Col xl={5} lg={6} md={7} className="mb-5">
                <Row className="mt-mx-lg-top align-items-center mb-4">
                  <Col className="d-inline-flex mt-mx-lg-top align-items-center">
                    <Col xl={4} lg={5} md="auto">
                      <FormattedMessage id="MappedItem.Filter.Select.Restaurants" />
                    </Col>
                    <Col className="no-margin search-auto no-label-input permission-settings inline-checkbox">
                      <AsyncTypeahead
                        id="restaurantSearch"
                        ref={inputRestaurantRef}
                        onInputChange={onRestaurantInputHandleChange}
                        onSearch={onRestaurantHandleSearch}
                        onChange={onRestaurantHandleChange}
                        onKeyDown={onRestaurantHandleKeyDown}
                        options={mappedRestaurants}
                        placeholder={useIntlMessages('Search.Placeholder')}
                        filterBy={['restaurantName']}
                        minLength={SEARCH_MIN_CHAR_LENGTH}
                        maxResults={pageSize}
                        labelKey={(restaurant: any) => `${restaurant.restaurantName}`}
                        renderMenuItemChildren={(restaurant: any) => <div>{restaurant.restaurantName}</div>}
                        clearButton
                        paginate
                        isLoading={isMappedRestaurantLoading}
                        inputProps={{
                          maxLength: INPUT_MAX_LENGTH_100,
                        }}
                      >
                        <Search />
                      </AsyncTypeahead>
                    </Col>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-inline-flex mt-mx-lg-top align-items-center">
                    <Col xl={4} lg={5} md="auto">
                      <FormattedMessage id="Filter.User" />:
                    </Col>
                    <Col className="no-margin no-label-input drop-min-100per">
                      <Select
                        id="User"
                        type="select"
                        name="User"
                        options={userList}
                        placeholder="Select User"
                        dropdownGap={0}
                        values={selectedUsers}
                        multi
                        dropdownPosition="auto"
                        isMandatory={false}
                        errorMessage=""
                        className=""
                        onChange={(values) => onSelectChange(values)}
                      />
                    </Col>
                  </Col>
                </Row>
              </Col>
              <Col xl={5} lg={6} md={7}>
                <Row className="mb-3">
                  <Col className="d-flex">
                    <Col xl={4} lg={5} md="auto">
                      <FormattedMessage id="Filter.Rank" />:
                    </Col>
                    <Col className="permission-settings inline-checkbox">
                      {rankList.map((rnk: ISelectOptions) => (
                        <InputCheck
                          key={`rank_${rnk.label}`}
                          id={`rank_${rnk.label}`}
                          name={`rnk_${rnk.label}`}
                          label={rnk.value.toString()}
                          value={rnk.value}
                          onChange={onRankFilterChange}
                          checked={ranks.includes(Number(rnk.value))}
                        />
                      ))}
                    </Col>
                  </Col>
                </Row>
              </Col>
              <Button variant="outline-secondary" onClick={clearFilters} disabled={filterCount <= 0 && selectedRestaurantId === 0}>
                <FormattedMessage id="Filter.Clear" />
              </Button>
            </div>
          </Collapse>
        </div>
        <Table responsive>
          <thead>
            <tr>
              <th className="w-350" onClick={() => changeSortField(MappedItemsSortField.ORIGINAL_ITEM_NAME)} style={{ cursor: 'pointer' }}>
                <FormattedMessage id="TH.OriginalItemName" />
                {sortField !== MappedItemsSortField.ORIGINAL_ITEM_NAME && <UpDownArrow />}
                {sortField === MappedItemsSortField.ORIGINAL_ITEM_NAME && sortOrder === SortOrderType.DESC && <DownArrow />}
                {sortField === MappedItemsSortField.ORIGINAL_ITEM_NAME && sortOrder === SortOrderType.ASC && <UpArrow />}
              </th>
              <th className="w-250" onClick={() => changeSortField(MappedItemsSortField.ORIGINAL_VENDOR)} style={{ cursor: 'pointer' }}>
                <FormattedMessage id="TH.OriginalVendor" />
                {sortField !== MappedItemsSortField.ORIGINAL_VENDOR && <UpDownArrow />}
                {sortField === MappedItemsSortField.ORIGINAL_VENDOR && sortOrder === SortOrderType.DESC && <DownArrow />}
                {sortField === MappedItemsSortField.ORIGINAL_VENDOR && sortOrder === SortOrderType.ASC && <UpArrow />}
              </th>
              <th className="w-350" onClick={() => changeSortField(MappedItemsSortField.SIMILAR_ITEM_NAME)} style={{ cursor: 'pointer' }}>
                <FormattedMessage id="TH.SimilarItemName" />
                {sortField !== MappedItemsSortField.SIMILAR_ITEM_NAME && <UpDownArrow />}
                {sortField === MappedItemsSortField.SIMILAR_ITEM_NAME && sortOrder === SortOrderType.DESC && <DownArrow />}
                {sortField === MappedItemsSortField.SIMILAR_ITEM_NAME && sortOrder === SortOrderType.ASC && <UpArrow />}
              </th>
              <th className="w-250" onClick={() => changeSortField(MappedItemsSortField.SIMILAR_VENDOR)} style={{ cursor: 'pointer' }}>
                <FormattedMessage id="TH.SimilarVendor" />
                {sortField !== MappedItemsSortField.SIMILAR_VENDOR && <UpDownArrow />}
                {sortField === MappedItemsSortField.SIMILAR_VENDOR && sortOrder === SortOrderType.DESC && <DownArrow />}
                {sortField === MappedItemsSortField.SIMILAR_VENDOR && sortOrder === SortOrderType.ASC && <UpArrow />}
              </th>
              <th className="w-350" onClick={() => changeSortField(MappedItemsSortField.MARKED_BY)} style={{ cursor: 'pointer' }}>
                <FormattedMessage id="TH.Markedby" />
                {sortField !== MappedItemsSortField.MARKED_BY && <UpDownArrow />}
                {sortField === MappedItemsSortField.MARKED_BY && sortOrder === SortOrderType.DESC && <DownArrow />}
                {sortField === MappedItemsSortField.MARKED_BY && sortOrder === SortOrderType.ASC && <UpArrow />}
              </th>
              <th className="w-250" onClick={() => changeSortField(MappedItemsSortField.USER)} style={{ cursor: 'pointer' }}>
                <FormattedMessage id="TH.User" />
                {sortField !== MappedItemsSortField.USER && <UpDownArrow />}
                {sortField === MappedItemsSortField.USER && sortOrder === SortOrderType.DESC && <DownArrow />}
                {sortField === MappedItemsSortField.USER && sortOrder === SortOrderType.ASC && <UpArrow />}
              </th>
              <th className="w-120" onClick={() => changeSortField(MappedItemsSortField.RANK)} style={{ cursor: 'pointer' }}>
                <FormattedMessage id="TH.Rank" />
                {sortField !== MappedItemsSortField.RANK && <UpDownArrow />}
                {sortField === MappedItemsSortField.RANK && sortOrder === SortOrderType.DESC && <DownArrow />}
                {sortField === MappedItemsSortField.RANK && sortOrder === SortOrderType.ASC && <UpArrow />}
              </th>
            </tr>
          </thead>
          <tbody>
            {mappedItems &&
              mappedItems.map((item: any) => (
                <tr key={item.mappingId}>
                  <td>
                    <a href="#" onClick={(e) => navigateToSimilarItems(e, item.mappingId, item.itemName, item.similarItemName)}>
                      {item.itemName}
                    </a>
                  </td>
                  <td>{item.vendorName}</td>
                  <td>{item.similarItemName}</td>
                  <td>{item.similarVendorName}</td>
                  <td>{item.restaurantName}</td>
                  <td>{item.createdUserName}</td>
                  <td>{item.rank}</td>
                </tr>
              ))}
            {!mappedItems ||
              (mappedItems && mappedItems.length === 0 && (
                <tr>
                  <td colSpan={7} className="text-center">
                    <FormattedMessage id="MappedItems.table.emptymessage" />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <div className="content-filter-main d-lg-flex justify-content-lg-between">
          {mappedItems && mappedItems.length > 0 && (
            <p className="m-0">
              {stringFormat(
                mappedItemsCount === 1 ? useIntlActionMessages('MappedItems.Pagination.SingleItem.Text') : useIntlActionMessages('MappedItems.Pagination.Text'),
                mappedItems.length > 0 ? ((mappedItemsCount === 1 ? 1 : pageIndex) - 1) * pageSize + 1 : 0,
                ((mappedItemsCount === 1 ? 1 : pageIndex) - 1) * pageSize + mappedItems.length,
                mappedItemsCount,
              )}
            </p>
          )}
          {mappedItems.length > 0 && mappedItemsCount > pageSize && (
            <Pagination totalCount={mappedItemsCount} pageLimit={pageSize} setCurrentPage={(page: number) => setPageIndex(page)} currentPage={pageIndex - 1} prevPage={-1} />
          )}
        </div>
      </div>
      {isLoading && <GifLoader />}
    </div>
  );
};

export default Mapping;
