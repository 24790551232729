/**
 * @file   src\validations\restaurantSchema.ts
 * @brief  This file is responsible for defining restaurant validation schemas.
 * @date   May, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import * as yup from 'yup';
import { useIntlActionMessages } from '../utils/helper';
import { EMAIL_REGEX, ZIP_REGEX, PHONE_NUMBER_REGEX } from '../utils/constants';

// Invite restaurant form validation schema.
export const INVITE_RESTAURANT_SCHEMA = yup.object({
  name: yup.string().trim().max(200, useIntlActionMessages('Restaurant.Name.MaxLength')).required(useIntlActionMessages('Restaurant.Name.Required')),
  email: yup
    .string()
    .trim()
    .matches(EMAIL_REGEX, useIntlActionMessages('Form.Email.Valid'))
    .max(200, useIntlActionMessages('Form.Email.MaxLength'))
    .required(useIntlActionMessages('Email.Required')),
});
// Restaurant add and edit form validation schema
export const RESTAURANT_ADD_EDIT_SCHEMA = yup.object({
  name: yup.string().trim().required(useIntlActionMessages('Restaurant.Form.Name.Required')).max(100, useIntlActionMessages('Restaurant.Form.Firstname.MaxLength')),
  address: yup.string().trim().required(useIntlActionMessages('Restaurant.Form.Address.Required')),
  zip: yup.string().trim().matches(ZIP_REGEX, useIntlActionMessages('Restaurant.Form.Zip.Invalid')).required(useIntlActionMessages('Vendor.Form.Zip.Required')),
  city: yup.string().trim().required(useIntlActionMessages('Restaurant.Form.City.Required')).max(100, useIntlActionMessages('Restaurant.Form.Firstname.MaxLength')),
  state: yup.string().trim().required(useIntlActionMessages('Restaurant.Form.State.Required')).max(100, useIntlActionMessages('Restaurant.Form.Firstname.MaxLength')),
  email: yup
    .string()
    .trim()
    .matches(EMAIL_REGEX, useIntlActionMessages('Form.Email.Valid'))
    .max(200, useIntlActionMessages('Form.Email.MaxLength'))
    .required(useIntlActionMessages('Email.Required')),
  phone: yup.string(),
  deliveryDays: yup.array(),
  adminFirstName: yup
    .string()
    .trim()
    .required(useIntlActionMessages('Restaurant.Form.AdminFirstName.Required'))
    .max(100, useIntlActionMessages('Restaurant.Form.Firstname.MaxLength')),
  adminLastName: yup
    .string()
    .trim()
    .required(useIntlActionMessages('Restaurant.Form.AdminLastName.Required'))
    .max(100, useIntlActionMessages('Restaurant.Form.Firstname.MaxLength')),
  adminEmail: yup
    .string()
    .trim()
    .matches(EMAIL_REGEX, useIntlActionMessages('Form.Email.Valid'))
    .max(200, useIntlActionMessages('Form.Email.MaxLength'))
    .required(useIntlActionMessages('Email.Required')),
  adminPhone: yup
    .string()
    .required(useIntlActionMessages('Restaurant.Form.PhoneNumber.Required'))
    .matches(PHONE_NUMBER_REGEX, useIntlActionMessages('Restaurant.Form.PhoneNumber.Invalid')),
  startTime: yup.string(),
  endTime: yup.string(),
});
