/**
 * @file   src\features\vendor\Details.tsx
 * @brief  Vendor Details page.
 * @date   May, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { FormattedMessage, useEffect, Col, Row, Breadcrumb, Button, useNavigate, Badge, useParams, useState } from '../../utils/thirdpartyComponents';
import '../../styles/Vendor.scss';
import BackIcon from '../../assets/icons/Back';
import { vendorDetails } from '../../store/actions/vendorAction';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { RootState } from '../../store';
import { MessageToaster } from '../../utils/toastUtil';
import GifLoader from '../../components/GifLoader';
import { DeliveryDays, EpayStatus, DefaultAdminRoles } from '../../utils/enums';
import { formatPhoneNumber, getFromLocalStorage } from '../../utils/helper';
import { resetVendorState } from '../../store/slices/vendorSlice';
import ModalWindow from '../../components/ModalWindow';

// Toast object creation.
const toast = new MessageToaster();

const VendorDetails = () => {
  // Create route param object to access the route parameters.
  const params = useParams();

  // Navigate object creation.
  const navigate = useNavigate();

  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Access redux state variables.
  const { isLoading, vendors, errorCode, errorMessage } = useAppSelector((state: RootState) => state.vendor);
  const userData = getFromLocalStorage('MCSA_UDT');

  const [showModal, setShowModal] = useState<boolean>(false);

  // Vendor details fetching on component loading.
  useEffect(() => {
    /* Access and set the location url parameters. */
    const vId: number = params.vendorId ? Number(params.vendorId) : 0;
    dispatch(vendorDetails(vId));
    return () => {
      dispatch(resetVendorState());
    };
  }, [dispatch]);

  // Show toast messages when error occures.
  useEffect(() => {
    if (errorCode !== 0 && errorMessage) {
      toast.toastError(errorMessage);
    }
  }, [errorCode, errorMessage]);

  // Navigation to edit vendor screen
  const navigateToVendorEdit = (evt: React.MouseEvent<HTMLElement>) => {
    evt.preventDefault();
    navigate(`/vendors/edit/${params.vendorId}`);
  };

  // Modify delivery days to display.
  const setDeliveryDays = (days: string[] = []) => {
    const sunday = `${DeliveryDays[DeliveryDays.SUN].at(0)}${DeliveryDays[DeliveryDays.SUN].slice(1).toLowerCase()}`;
    if (days.includes(sunday)) {
      const deliveryDays = [...days].splice(1);
      deliveryDays.push(sunday);
      return deliveryDays.join(', ');
    }
    return days.join(', ');
  };
  // redirect to support mode page
  const loadSupportPage = () => {
    navigate(`/vendors/cobrowse/${params.vendorId}`);
  };

  return (
    <div className="content-nav contnt-container-margin">
      <div className="back-navigation">
        <Breadcrumb>
          <Breadcrumb.Item href="#" onClick={() => navigate('/home')}>
            <FormattedMessage id="Menu.Home" />
          </Breadcrumb.Item>
          <Breadcrumb.Item href="#" onClick={() => navigate('/vendors')}>
            <FormattedMessage id="Vendor.PageHD" />
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{vendors[0]?.vendorName || ''}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="content-nav-sub">
        <div className="content-nav-header d-flex justify-content-between align-items-center">
          <h2 className="align-items-center">
            <a href="#" className="btn-icon" onClick={() => navigate('/vendors')}>
              <BackIcon />
            </a>
            {vendors[0]?.vendorName || ''}
            {vendors[0] && !vendors[0]?.isActive && (
              <Badge className="badge-due-high ms-3">
                <FormattedMessage id="Badge.Deactivated" />
              </Badge>
            )}
          </h2>
          <div>
            <Button variant="outline-primary" className="me-3" onClick={() => navigate(`/vendors/subscriptions/${Number(params.vendorId)}`)}>
              <FormattedMessage id="Subscriptions.Link.Label" />
            </Button>
            {(userData.defaultRoleId === null ||
              userData.defaultRoleId === DefaultAdminRoles.ADMIN ||
              userData.defaultRoleId === DefaultAdminRoles.CUSTOMER_SUPPORT ||
              userData.defaultRoleId === DefaultAdminRoles.ONBOARDING) && (
              <Button variant="primary" className="btn-orange" onClick={() => setShowModal(true)}>
                <FormattedMessage id="Button.EnterSupportMode" />
              </Button>
            )}
          </div>
        </div>
        <div className="content-container-padding  d-flex justify-content-center">
          <Col xl={10} lg={10} md={11}>
            <Col className="vendordetails">
              <div className="content-nav-header d-flex justify-content-between align-items-center">
                <h5>
                  <FormattedMessage id="Vendor.Details.VendorInfoHD" />
                </h5>
                <a href="#" onClick={(e) => navigateToVendorEdit(e)}>
                  <FormattedMessage id="Vendor.Details.LinkEdit" />
                </a>
              </div>
              <div className="content-container-padding">
                <Row>
                  <Col>
                    <FormattedMessage id="Vendor.Details.AddressLbl" />:
                  </Col>
                  <Col>{vendors[0]?.address || ''}</Col>
                </Row>
                <Row>
                  <Col>
                    <FormattedMessage id="Vendor.Details.MinLbl" />:
                  </Col>
                  <Col>{`$${vendors[0]?.minDeliveryAmt || 0}`}</Col>
                </Row>
                <Row>
                  <Col>
                    <FormattedMessage id="Vendor.Details.DeliveryDaysLbl" />:
                  </Col>
                  <Col>
                    {setDeliveryDays(
                      vendors[0]?.deliveryDaysJson?.Day &&
                        vendors[0]?.deliveryDaysJson?.Day.map((day: number) => {
                          return day !== 0 && DeliveryDays[day] && `${DeliveryDays[day].at(0)}${DeliveryDays[day].slice(1).toLowerCase()}`;
                        }),
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormattedMessage id="Vendor.Details.BusinessEmail" />:
                  </Col>
                  <Col>{vendors[0]?.email || ''}</Col>
                </Row>
                <Row>
                  <Col>
                    <FormattedMessage id="Vendor.Details.BusinessPhone" />:
                  </Col>
                  <Col>{formatPhoneNumber(vendors[0]?.phone || '')}</Col>
                </Row>
                <Row>
                  <Col>
                    <FormattedMessage id="Vendor.Details.PriceTier" />:
                  </Col>
                  <Col>{vendors[0]?.planName || ''}</Col>
                </Row>
                <Row>
                  <Col>
                    <FormattedMessage id="Vendor.Details.EPayLbl" />:
                  </Col>
                  <Col>
                    {EpayStatus[vendors[0]?.isEPaymentEnabled] &&
                      `${EpayStatus[vendors[0]?.isEPaymentEnabled].at(0)}${EpayStatus[vendors[0]?.isEPaymentEnabled].slice(1).toLowerCase()}`}
                  </Col>
                </Row>
                <Col className="mt-5">
                  <h6>
                    <FormattedMessage id="HD.Details.AdminInformation" />
                  </h6>
                </Col>
                <Row>
                  <Col>
                    <FormattedMessage id="Vendor.Details.Admin.FirstName" />:
                  </Col>
                  <Col>{vendors[0]?.adminFirstName || ''}</Col>
                </Row>
                <Row>
                  <Col>
                    <FormattedMessage id="Vendor.Details.Admin.LastName" />:
                  </Col>
                  <Col>{vendors[0]?.adminLastName || ''}</Col>
                </Row>
                <Row>
                  <Col>
                    <FormattedMessage id="Vendor.Details.Admin.Email" />:
                  </Col>
                  <Col>{vendors[0]?.adminEmail || ''}</Col>
                </Row>
                <Row>
                  <Col>
                    <FormattedMessage id="Vendor.Details.Admin.Phone" />:
                  </Col>
                  <Col>{formatPhoneNumber(vendors[0]?.adminPhone || '')}</Col>
                </Row>
              </div>
            </Col>
          </Col>
        </div>
      </div>
      {/* Enter support mode confirmation popup */}
      <ModalWindow
        modalShow={showModal}
        className="mx-w-550"
        size="lg"
        onHide={() => {
          setShowModal(false);
        }}
        title="SupportMode.Title"
        body="SupportMode.Body"
        onCancel={() => {
          setShowModal(false);
        }}
        onOkay={() => {
          loadSupportPage();
        }}
      />
      {isLoading && <GifLoader />}
    </div>
  );
};

export default VendorDetails;
