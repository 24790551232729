/**
 * @file   src\validations\subscriptionSchema.ts
 * @brief  This file is responsible for defining subscription validation schemas.
 * @date   Aug, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import * as yup from 'yup';
import { useIntlActionMessages } from '../utils/helper';

// Incentive payment form validation schema.
export const INCENTIVE_PAYMENT_SCHEMA = yup.object({
  incentiveToPay: yup
    .number()
    .required(useIntlActionMessages('Incentives.Form.Payment.IncentiveAmount.Required'))
    .min(1, useIntlActionMessages('Incentives.Form.Payment.IncentiveAmount.Required')),
});
