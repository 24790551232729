import { useState, useEffect, Modal } from '../utils/thirdpartyComponents';
import { useAppDispatch, useAppSelector } from '../hooks';
import { RootState } from '../store';
import { setPreviousGif } from '../store/slices/loaderSlice';

function randomItem(items: any, loaderData: any) {
  if (loaderData.previousGif === '') {
    const gif = items[Math.floor(Math.random() * items.length)];
    return gif;
  }
  const index = items.indexOf(loaderData.previousGif);
  items.splice(index, 1);
  const newGif = items[Math.floor(Math.random() * items.length)];
  return newGif;
}

const GifLoader = () => {
  const images = ['/gagnam_style.gif', '/hammer.gif', '/floss.gif', '/whip.gif'];
  const [currentImage, setCurrentImage] = useState('');
  const loaderData = useAppSelector((state: RootState) => state.loader);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const gif = randomItem(images, loaderData);
    setCurrentImage(gif);
    dispatch(setPreviousGif(gif));
  }, []);

  return (
    <Modal show centered backdrop="static" className="gifloader">
      <Modal.Body>
        <img src={currentImage} alt="loading" width="25%" />
      </Modal.Body>
    </Modal>
  );
};

export default GifLoader;
