/**
 * @file   src\components\CheckboxLabel.tsx
 * @brief  Checkbox component.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { Row, Col, Button } from 'react-bootstrap';
import Close from '../assets/icons/Close';
import { IEmailCardProps } from '../interfaces/settingsInterface';

const EmailCard = ({ id, email, onDeleteClickHandler }: IEmailCardProps) => {
  return (
    <div className=" email-card">
      <Row className="align-items-center">
        <Col className="emailid">{email}</Col>
        <Col xs="auto">
          <Button variant="link" className="btn-icontrans" onClick={() => onDeleteClickHandler(id, email)}>
            <Close />
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default EmailCard;
