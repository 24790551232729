/**
 * @file   src\features\subscription\Incentives.tsx
 * @brief  SImilar item incentive list tab
 * @date   Aug, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { Link } from 'react-router-dom';
import {
  Col,
  Row,
  FormattedMessage,
  Table,
  Badge,
  useRef,
  createRef,
  useState,
  useNavigate,
  Typeahead,
  useEffect,
  Breadcrumb,
  Button,
  Modal,
  moment,
} from '../../utils/thirdpartyComponents';
import { handleDecimalInputValue, stringFormat, useIntlActionMessages, useIntlMessages } from '../../utils/helper';
import Select from '../../components/Select';
import Pagination from '../../components/Paginate';
import SortIcon from '../../assets/icons/Sort';
import Search from '../../assets/icons/Search';
import {
  DEFAULT_PAGE_INDEX,
  DEFAULT_FILTER_STATUS,
  DEFAULT_SORT_FIELD,
  PAGE_SIZE,
  SEARCH_MIN_CHAR_LENGTH,
  ENTER_KEY,
  SEARCH_PAGESIZE,
  INPUT_MAX_LENGTH_200,
  DELETE_KEY,
} from '../../utils/constants';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { RootState } from '../../store';
import { IncentiveStatus, IncentivesSortField, SortOrderType } from '../../utils/enums';
import { ISelectOptions } from '../../interfaces/generalInterface';
import { getIncentives, getMappedIncentiveUsers, updatePayment } from '../../store/actions/subscriptionAction';
import { IIncentivePaymentRequest, IIncentiveRequest, IIncentiveUsersRequest, IIncentivesList, IMappedUsers } from '../../interfaces/subscriptionInterface';
import UpDownArrow from '../../assets/icons/UpDownArrow';
import DownArrow from '../../assets/icons/DownArrow';
import UpArrow from '../../assets/icons/UpArrow';
import GifLoader from '../../components/GifLoader';
import Menu from './Menu';
import OffcanvasMenu from './OffCanvas';
import InputLabel from '../../components/InputLabel';
import { validateForm } from '../../utils/formValidation';
import { INCENTIVE_PAYMENT_SCHEMA } from '../../validations/subscriptionSchema';
import { resetSubscription } from '../../store/slices/subscriptionSlice';
import { MessageToaster } from '../../utils/toastUtil';

// Toast object creation.
const toast = new MessageToaster();
// Declare page item count to be displayed per page.
const pageItemCount: number = process.env.REACT_APP_LIST_PAGE_SIZE !== '' ? Number(process.env.REACT_APP_LIST_PAGE_SIZE) : PAGE_SIZE;
const IncentivesList = () => {
  // Navigate object creation.
  const navigate = useNavigate();

  // Initialize search field ref for marked by user.
  const oldSearchText = useRef<string>('');

  // Create a ref on search input for marked by user.
  const inputRef = createRef<any>();

  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Access redux state variables.
  const { isLoading, incentives, incentivesCount, mappedUsers, incentivePaymentLoading, incentivePaymentSuccess, incentivePaymentErrorCode, incentivePaymentErrorMessage } =
    useAppSelector((state: RootState) => state.subscription);

  // Initialize component state variables.
  const [searchField, setSearchField] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const [typeField, setTypeField] = useState<string>('');
  const [pageSize] = useState<number>(pageItemCount);
  const [pageIndex, setPageIndex] = useState<number>(DEFAULT_PAGE_INDEX);
  const [sortField, setSortField] = useState<number>(DEFAULT_SORT_FIELD);
  const [sortOrder, setSortOrder] = useState<number>(SortOrderType.ASC);
  const [selectedUserId, setSelectedUserId] = useState<number>(0);
  const [selectedStatus, setSelectedStatus] = useState<ISelectOptions[]>(DEFAULT_FILTER_STATUS);
  const [payIncentivePopUp, setpayIncentivePopUp] = useState<boolean>(false);
  const [paymentConfirmPopUp, setPaymentConfirmPopUp] = useState<boolean>(false);
  const [incentiveToPay, setIncentiveToPay] = useState<string>('');
  const [mappedUserName, setMappedUserName] = useState<string>('');
  const [paymentIncentiveId, setPaymentIncentiveId] = useState<number>(0);
  const [eventKey, setEventKey] = useState<string>('');
  const [errorFields, setErrorFields] = useState<any>({});

  // Fetch incentives list action call.
  const fetchIncentives = () => {
    const incentivesListRequest: IIncentiveRequest = {
      searchText: selectedUserId !== 0 ? '' : searchText,
      userId: selectedUserId,
      status: selectedStatus[0]?.value || 0,
      pageSize,
      pageIndex,
      sortField,
      sortOrder,
    };
    dispatch(getIncentives(incentivesListRequest));
  };

  // Incentives list fetch method call on pageIndex, searchText, sortField, sortOrder, payment status and unique search identifier state change.
  useEffect(() => {
    fetchIncentives();
  }, [pageIndex, searchText, sortField, sortOrder, selectedStatus]);

  // Search field state change handling for list mapped users.
  useEffect(() => {
    if (searchField.length >= SEARCH_MIN_CHAR_LENGTH && selectedUserId === 0) {
      const mappedUsersSearchRequest: IIncentiveUsersRequest = {
        searchText: searchField,
        pageSize: SEARCH_PAGESIZE,
      };
      dispatch(getMappedIncentiveUsers(mappedUsersSearchRequest));
    }
  }, [searchField]);

  // Incentive payment api response state change.
  useEffect(() => {
    if (incentivePaymentSuccess && incentivePaymentErrorCode === 0 && incentivePaymentErrorMessage) {
      setpayIncentivePopUp(false);
      toast.toastSuccess(incentivePaymentErrorMessage);
      dispatch(resetSubscription());
      fetchIncentives();
    } else if (incentivePaymentErrorCode > 0 && incentivePaymentErrorMessage) {
      toast.toastError(incentivePaymentErrorMessage);
      dispatch(resetSubscription());
    }
  }, [incentivePaymentSuccess, incentivePaymentErrorCode]);

  // User search input keydown event.
  const onUserSearchHandleKeyDown = (event: any) => {
    const { value } = event.target;
    if (event.key === ENTER_KEY) {
      if (inputRef.current) inputRef.current.blur();
      setSearchText(value?.trim());
      setSearchField(searchField.trim());
    }
  };

  // User search textbox selected change event.
  const onUserSearchHandleChange = (value: any) => {
    if (value[0]) {
      oldSearchText.current = `${value[0].firstName} ${value[0].laststName}`;
      setSelectedUserId(value[0].userId);
      setSearchText(`${value[0].firstName} ${value[0].laststName}`);
      setPageIndex(1);
      setSearchField(`${value[0].firstName} ${value[0].laststName}`);
      if (inputRef.current) inputRef.current.blur();
    }
  };

  // User search textbox search input change event.
  const onUserSearchInputHandleChange = (value: any) => {
    if (value.trim().length >= SEARCH_MIN_CHAR_LENGTH) {
      setSearchField(value);
    } else if (value.trim().length === 0) {
      setSearchField('');
      setSearchText('');
    }
    oldSearchText.current = value;
    setTypeField(value);
    setSelectedUserId(0);
  };

  // Filter status change event.
  const onStatusChange = (values: ISelectOptions[]) => {
    setSelectedStatus(values);
    setPageIndex(1);
  };

  // Sort header change event.
  const changeSortField = (field: number) => {
    let newSortOrder = SortOrderType.ASC;
    if (field === sortField) {
      newSortOrder = sortOrder === SortOrderType.ASC ? SortOrderType.DESC : SortOrderType.ASC;
    }
    setSortField(field);
    setSortOrder(newSortOrder);
  };

  // Payment button click event.
  const onPaymentButtonHandle = (incentiveId: number) => {
    setErrorFields({});
    const selectedIncentiveData = incentives.find((inc: IIncentivesList) => inc.incentiveId === incentiveId);
    setMappedUserName(selectedIncentiveData?.createdUserName || '');
    setIncentiveToPay(selectedIncentiveData?.dueAmount?.toString() || '');
    setPaymentIncentiveId(selectedIncentiveData?.incentiveId || 0);
    setpayIncentivePopUp(true);
  };

  // Handle input key press event.
  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    setEventKey(event.key);
  };

  // Incentive payment amount input field change event.
  const onInputHandleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const incAmount = incentives.find((inc: IIncentivesList) => inc.incentiveId === paymentIncentiveId)?.dueAmount || 0;
    if (Number(value) <= Number(incAmount)) {
      const newVal = handleDecimalInputValue(value);
      if (newVal || incentiveToPay.length === 1 || !incentiveToPay || eventKey === DELETE_KEY) {
        setIncentiveToPay(newVal || '');
      }
      const validateObj = {
        [name]: newVal ? Number(newVal) : 0,
      };
      const errorresult = await validateForm(validateObj, INCENTIVE_PAYMENT_SCHEMA, errorFields);
      setErrorFields(errorresult);
    }
  };

  // Validate payment form.
  const validatePaymentForm = async () => {
    const errorresult = await validateForm({ incentiveToPay: incentiveToPay ? Number(incentiveToPay) : 0 }, INCENTIVE_PAYMENT_SCHEMA, errorFields);
    setErrorFields(errorresult);
    if (Object.keys(errorresult).length === 0) {
      setPaymentConfirmPopUp(true);
    }
  };

  // Process incentive payment.
  const onProcessPayment = async () => {
    const paymentFormData: IIncentivePaymentRequest = {
      incentiveId: paymentIncentiveId,
      paidAmount: parseFloat(incentiveToPay),
    };
    dispatch(updatePayment(paymentFormData));
    setPaymentConfirmPopUp(false);
  };

  // Bind incentive status filter elements.
  const setIncentiveStatusFilter = () => {
    const inectiveStatusOptions: ISelectOptions[] = [];
    Object.values(IncentiveStatus)
      // eslint-disable-next-line no-restricted-globals
      .filter((v) => !isNaN(Number(v)))
      .forEach((val) => {
        inectiveStatusOptions.push({ label: `${IncentiveStatus[Number(val)].at(0)}${IncentiveStatus[Number(val)].slice(1).toLowerCase()}`, value: Number(val) } as ISelectOptions);
      });
    inectiveStatusOptions.unshift(DEFAULT_FILTER_STATUS[0]);
    return (
      <Select
        id="status"
        name="status"
        options={inectiveStatusOptions}
        placeholder="sample"
        dropdownGap={0}
        values={selectedStatus}
        icon={<SortIcon />}
        onChange={(values) => onStatusChange(values)}
      />
    );
  };

  return (
    <div className="content-nav contnt-container-margin">
      <div className="back-navigation">
        <Breadcrumb>
          <Breadcrumb.Item href="#" onClick={() => navigate('/home')}>
            <FormattedMessage id="Menu.Home" />
          </Breadcrumb.Item>
          <Breadcrumb.Item href="#" active>
            <FormattedMessage id="Breadcrump.Subscriptionmanagement" />
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="content-nav-sub">
        <div className="settings-wrap">
          <div className="left-wrap">
            <Menu />
          </div>
          <div className="right-wrap">
            <div className="header d-flex justify-content-between">
              <div className="button-wrap d-flex">
                <OffcanvasMenu />
              </div>
            </div>
            <div className="settings-body cutoff-time">
              <div className="content-filter-main border-0 px-0 pt-0">
                <Row className="align-items-center">
                  <Col xs={12} md={6} lg={4} xl={3} className="no-margin search-auto">
                    <Typeahead
                      id="userSearchInput"
                      ref={inputRef}
                      onInputChange={onUserSearchInputHandleChange}
                      onChange={onUserSearchHandleChange}
                      onKeyDown={onUserSearchHandleKeyDown}
                      options={mappedUsers}
                      placeholder={useIntlMessages('PH.SearchUser')}
                      selected={mappedUsers.filter((x: IMappedUsers) => x.userId === selectedUserId)}
                      filterBy={['firstName', 'lastName']}
                      minLength={oldSearchText.current.length > typeField.length ? oldSearchText.current.length : SEARCH_MIN_CHAR_LENGTH}
                      labelKey={(item: any) => `${item.firstName} ${item.lastName}`}
                      inputProps={{
                        maxLength: INPUT_MAX_LENGTH_200,
                      }}
                      renderMenuItemChildren={(item: any) => (
                        <div>
                          {`${item.firstName} ${item.lastName}`}
                          <div>
                            <small>{item.email}</small>
                          </div>
                        </div>
                      )}
                    >
                      <Search />
                    </Typeahead>
                  </Col>
                  <Col xs={12} md={6} lg={4} xl={3} className="no-margin">
                    {setIncentiveStatusFilter()}
                  </Col>
                </Row>
              </div>
              <Table responsive>
                <thead>
                  <tr>
                    <th className="w-350" onClick={() => changeSortField(IncentivesSortField.SIMILAR_ITEM)} style={{ cursor: 'pointer' }}>
                      <FormattedMessage id="TH.SimilarItem" />
                      {sortField !== IncentivesSortField.SIMILAR_ITEM && <UpDownArrow />}
                      {sortField === IncentivesSortField.SIMILAR_ITEM && sortOrder === SortOrderType.DESC && <DownArrow />}
                      {sortField === IncentivesSortField.SIMILAR_ITEM && sortOrder === SortOrderType.ASC && <UpArrow />}
                    </th>
                    <th className="w-300">
                      <FormattedMessage id="TH.Email" />
                    </th>
                    <th className="w-250">
                      <FormattedMessage id="TH.Restaurant" />
                    </th>
                    <th className="w-170">
                      <FormattedMessage id="TH.NumberofMappedItemItem" />
                    </th>
                    <th className="w-170" onClick={() => changeSortField(IncentivesSortField.COUNT)} style={{ cursor: 'pointer' }}>
                      <FormattedMessage id="TH.NumberofSimilarItem" />
                      {sortField !== IncentivesSortField.COUNT && <UpDownArrow />}
                      {sortField === IncentivesSortField.COUNT && sortOrder === SortOrderType.DESC && <DownArrow />}
                      {sortField === IncentivesSortField.COUNT && sortOrder === SortOrderType.ASC && <UpArrow />}
                    </th>
                    <th className="w-150" onClick={() => changeSortField(IncentivesSortField.INCENTIVE)} style={{ cursor: 'pointer' }}>
                      <FormattedMessage id="TH.Incentive" />
                      {sortField !== IncentivesSortField.INCENTIVE && <UpDownArrow />}
                      {sortField === IncentivesSortField.INCENTIVE && sortOrder === SortOrderType.DESC && <DownArrow />}
                      {sortField === IncentivesSortField.INCENTIVE && sortOrder === SortOrderType.ASC && <UpArrow />}
                    </th>
                    <th className="w-120">
                      <FormattedMessage id="TH.Status" />
                    </th>
                    <th className="w-150">
                      <FormattedMessage id="TH.DateofPayment" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {incentives &&
                    incentives.map((inc: IIncentivesList) => (
                      <tr key={`${inc.incentiveId}-${inc.createdBy}`}>
                        <td>
                          <Link to={`/products/usermappeditems/${inc.createdBy}`} state={{ userName: inc.createdUserName }}>
                            {inc.createdUserName}
                          </Link>
                        </td>
                        <td>{inc.email}</td>
                        <td>{inc.restaurantName}</td>
                        <td>{inc.mappedCount || '-'}</td>
                        <td>{inc.approvedCount || '-'}</td>
                        <td>{inc.incentiveStatus === IncentiveStatus.PAID ? inc.paidAmount || '-' : inc.dueAmount ? `$${inc.dueAmount}` : '-'}</td>
                        <td>
                          {inc.incentiveStatus === IncentiveStatus.PAID && (
                            <Badge bg="secondary" className="badge-due-paid">
                              <FormattedMessage id="Status.Paid" />
                            </Badge>
                          )}
                          {inc.dueAmount && inc.dueAmount > 0 && inc.incentiveStatus === IncentiveStatus.DUE && (
                            <Badge bg="secondary" className="badge-inprogress">
                              <FormattedMessage id="Status.Due" />
                            </Badge>
                          )}
                        </td>
                        <td>
                          {inc.paidOn
                            ? moment(inc.paidOn).local().format('MM/DD/YYYY')
                            : inc.dueAmount &&
                              Number(inc.dueAmount) > 0 &&
                              inc.incentiveStatus &&
                              inc.incentiveStatus === IncentiveStatus.DUE && (
                                <Button
                                  variant="outline-primary"
                                  className="btn-sm min-w-100"
                                  onClick={() => {
                                    onPaymentButtonHandle(inc.incentiveId);
                                  }}
                                >
                                  <FormattedMessage id="Button.Pay" />
                                </Button>
                              )}
                        </td>
                      </tr>
                    ))}
                  {!incentives ||
                    (incentives && incentives.length === 0 && (
                      <tr>
                        <td colSpan={7} className="text-center">
                          <FormattedMessage id="Incentives.Table.Emptymessage" />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              <div className="content-filter-main d-lg-flex justify-content-lg-between">
                {incentives && incentives.length > 0 && (
                  <p className="m-0">
                    {stringFormat(
                      incentivesCount === 1 ? useIntlActionMessages('Incentives.Pagination.SingleItem.Text') : useIntlActionMessages('Incentives.Pagination.Text'),
                      incentives.length > 0 ? ((incentivesCount === 1 ? 1 : pageIndex) - 1) * pageSize + 1 : 0,
                      ((incentivesCount === 1 ? 1 : pageIndex) - 1) * pageSize + incentives.length,
                      incentivesCount,
                    )}
                  </p>
                )}
                {incentives.length > 0 && incentivesCount > pageSize && (
                  <Pagination totalCount={incentivesCount} pageLimit={pageSize} setCurrentPage={(page: number) => setPageIndex(page)} currentPage={pageIndex - 1} prevPage={-1} />
                )}
              </div>
              {(isLoading || incentivePaymentLoading) && <GifLoader />}
            </div>
          </div>
        </div>
      </div>
      {/* Pay incentive popup */}
      <Modal show={payIncentivePopUp} onHide={() => setpayIncentivePopUp(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            <FormattedMessage id="Incentives.Payment.Form.Heading" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-3">
            <FormattedMessage id="Incentives.Payment.Form.ChefLabel" />: <span className="text-blue">{mappedUserName}</span>
          </p>
          <div className="d-flex align-items-center">
            <FormattedMessage id="Incentives.Payment.Form.AmountLabelText" />: <span className="text-blue px-2">$</span>
            <div className="no-margin w-150">
              <InputLabel
                id="incentiveToPay"
                name="incentiveToPay"
                type="text"
                placeholder={useIntlMessages('Incentives.Payment.Amount')}
                value={incentiveToPay}
                onKeyPress={(e) => handleKeyPress(e)}
                onChange={(e) => onInputHandleChange(e)}
              />
            </div>
          </div>
          <div>{errorFields?.incentiveToPay && errorFields?.incentiveToPay !== '' && <span className="error">{errorFields?.incentiveToPay}</span>}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setpayIncentivePopUp(false)}>
            <FormattedMessage id="Button.Cancel" />
          </Button>
          <Button variant="primary" onClick={validatePaymentForm}>
            <FormattedMessage id="Button.Pay" />
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Payment confirm popup */}
      <Modal show={paymentConfirmPopUp} onHide={() => setPaymentConfirmPopUp(false)} size="lg" centered>
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-sm">
            <FormattedMessage id="HD.Incentives.Payment.Confirm" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-3">
            <FormattedMessage id="Incentives.Payment.Confirm.Text" />
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="" onClick={() => setPaymentConfirmPopUp(false)}>
            <FormattedMessage id="Button.Cancel" />
          </Button>
          <Button variant="primary" onClick={onProcessPayment}>
            <FormattedMessage id="Button.OK" />
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default IncentivesList;
