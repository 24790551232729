import React from 'react';

const SMIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23.98" height="23.98" viewBox="0 0 23.98 23.98">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_1" data-name="Rectangle 1" width="23.98" height="23.98" fill="#fff" />
        </clipPath>
      </defs>
      <g id="SM">
        <path
          id="Path_21"
          data-name="Path 21"
          d="M18.643,19.9H4.81a1.78,1.78,0,0,1-1.622-2.51l.045-.1,1.156-1.545A2.789,2.789,0,0,0,4.6,14.687V10.65A7.21,7.21,0,0,1,17.987,6.945L16.273,7.975A5.19,5.19,0,0,0,11.8,5.46H11.8a5.19,5.19,0,0,0-5.2,5.19v4.04a4.783,4.783,0,0,1-.429,1.973l-.045.1L5.274,17.9H18.26l-.771-.872-.06-.125a4.478,4.478,0,0,1-.439-1.909l2-.008a2.468,2.468,0,0,0,.189.935l1.061,1.2.06.124A1.85,1.85,0,0,1,18.646,19.9Z"
          fill="#63707e"
        />
        <path id="Path_22" data-name="Path 22" d="M11.8,5.32A1.88,1.88,0,1,0,9.92,3.44,1.88,1.88,0,0,0,11.8,5.32Z" fill="#63707e" />
        <path id="Path_23" data-name="Path 23" d="M18.4,14.02h-2V8.92h2Z" fill="#63707e" />
        <path id="Path_24" data-name="Path 24" d="M19.95,12.47h-5.1v-2h5.1Z" fill="#63707e" />
        <path id="Path_25" data-name="Path 25" d="M11.63,23.43a3.87,3.87,0,0,1-3.87-3.87h2a1.87,1.87,0,1,0,3.74,0h2a3.87,3.87,0,0,1-3.87,3.87Z" fill="#63707e" />
      </g>
    </svg>
  );
};

export default SMIcon;
