/**
 * @file   src\App.tsx
 * @brief  This file is responsible for routing.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { ErrorBoundary } from 'react-error-boundary';
import { Router, Routes, Route } from './utils/thirdpartyComponents';
import ProtectedRoute from './components/ProtectedRoute';
import Login from './features/Login';
import RecoverPassword from './features/RecoverPassword';
import NewPassword from './features/NewPassword';
import GenericNotFound from './features/GenericNotFound';
import Layout from './components/Layout';
import Home from './features/dashboard/Home';
import VendorList from './features/vendor/List';
import VendorAdd from './features/vendor/Add';
import VendorEdit from './features/vendor/Edit';
import VendorDetails from './features/vendor/Details';
import RestaurantList from './features/restaurant/List';
import RestaurantAdd from './features/restaurant/Add';
import RestaurantMerge from './features/restaurant/Merge';
import RestaurantDetails from './features/restaurant/Details';
import ProductList from './features/product/List';
import Profile from './features/profile/View';
import MappedItems from './features/product/MappedItems';
import SimilarItems from './features/product/SimilarItems';
import User from './features/user/User';
import Role from './features/user/Role';
import NewRole from './features/user/NewRole';
import Subscription from './features/subscription/Subscription';
import Incentives from './features/subscription/Incentives';
import OrderDetails from './features/dashboard/OrderDetails';
import TransactionDetails from './features/dashboard/TransactionDetails';
import Onboarding from './features/onboarding/List';
import VendorPermission from './features/permission/VendorRoles';
import RestaurantPermission from './features/permission/RestaurantRoles';
import UserMappedItems from './features/product/UserMappedItems';
import RolePermissions from './features/permission/PermissionSettings';
import CoBrowse from './features/restaurant/SupportMode';
import ManageEmail from './features/settings/ManageEmail';
import Subscriptions from './features/common/Subscriptions';
import ErrorFallbackComponent from './components/ErrorFallbackComponent';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <Login />
            </ErrorBoundary>
          }
        />
        <Route
          path="/recover"
          element={
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <RecoverPassword />
            </ErrorBoundary>
          }
        />
        <Route
          path="/newpassword"
          element={
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <NewPassword />
            </ErrorBoundary>
          }
        />
        <Route
          element={
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <Layout />
            </ErrorBoundary>
          }
        >
          <Route path="/home">
            <Route
              index
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />
            <Route
              path="orderdetails/:orderGuid"
              element={
                <ProtectedRoute>
                  <OrderDetails />
                </ProtectedRoute>
              }
            />
            <Route
              path="transactiondetails/:invoiceGuid"
              element={
                <ProtectedRoute>
                  <TransactionDetails />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/vendors">
            <Route
              index
              element={
                <ProtectedRoute>
                  <VendorList />
                </ProtectedRoute>
              }
            />
            <Route
              path="add"
              element={
                <ProtectedRoute>
                  <VendorAdd />
                </ProtectedRoute>
              }
            />
            <Route
              path="edit/:vendorId"
              element={
                <ProtectedRoute>
                  <VendorEdit />
                </ProtectedRoute>
              }
            />
            <Route
              path="details/:vendorId"
              element={
                <ProtectedRoute>
                  <VendorDetails />
                </ProtectedRoute>
              }
            />
            <Route
              path="cobrowse/:id"
              element={
                <ProtectedRoute>
                  <CoBrowse />
                </ProtectedRoute>
              }
            />
            <Route
              path="subscriptions/:vendorId"
              element={
                <ProtectedRoute>
                  <Subscriptions />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/restaurants">
            <Route
              index
              element={
                <ProtectedRoute>
                  <RestaurantList />
                </ProtectedRoute>
              }
            />
            <Route
              path="merge"
              element={
                <ProtectedRoute>
                  <RestaurantMerge />
                </ProtectedRoute>
              }
            />
            <Route
              path="add"
              element={
                <ProtectedRoute>
                  <RestaurantAdd />
                </ProtectedRoute>
              }
            />
            <Route
              path="edit/:restaurantId"
              element={
                <ProtectedRoute>
                  <RestaurantAdd />
                </ProtectedRoute>
              }
            />

            <Route
              path="details/:restaurantId"
              element={
                <ProtectedRoute>
                  <RestaurantDetails />
                </ProtectedRoute>
              }
            />
            <Route
              path="cobrowse/:id"
              element={
                <ProtectedRoute>
                  <CoBrowse />
                </ProtectedRoute>
              }
            />
            <Route
              path="subscriptions/:restaurantId"
              element={
                <ProtectedRoute>
                  <Subscriptions />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/products">
            <Route
              index
              element={
                <ProtectedRoute>
                  <ProductList />
                </ProtectedRoute>
              }
            />
            <Route
              path="mappeditems"
              element={
                <ProtectedRoute>
                  <MappedItems />
                </ProtectedRoute>
              }
            />
            <Route
              path="usermappeditems/:mappedUserId"
              element={
                <ProtectedRoute>
                  <UserMappedItems />
                </ProtectedRoute>
              }
            />
            <Route
              path="similaritems/:mappingId"
              element={
                <ProtectedRoute>
                  <SimilarItems />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/profile">
            <Route
              index
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/users">
            <Route
              index
              element={
                <ProtectedRoute>
                  <User />
                </ProtectedRoute>
              }
            />
            <Route
              path="inactiveusers"
              element={
                <ProtectedRoute>
                  <User />
                </ProtectedRoute>
              }
            />
            <Route
              path="roles"
              element={
                <ProtectedRoute>
                  <Role />
                </ProtectedRoute>
              }
            />
            <Route
              path="roles/add"
              element={
                <ProtectedRoute>
                  <NewRole />
                </ProtectedRoute>
              }
            />
            <Route
              path="roles/edit/:roleId"
              element={
                <ProtectedRoute>
                  <NewRole />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/subscription">
            <Route
              index
              element={
                <ProtectedRoute>
                  <Subscription />
                </ProtectedRoute>
              }
            />
            <Route
              path="incentives"
              element={
                <ProtectedRoute>
                  <Incentives />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/onboarding">
            <Route
              index
              element={
                <ProtectedRoute>
                  <Onboarding />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/permissions">
            <Route
              path="vendor"
              element={
                <ProtectedRoute>
                  <VendorPermission />
                </ProtectedRoute>
              }
            />
            <Route
              path="restaurant"
              element={
                <ProtectedRoute>
                  <RestaurantPermission />
                </ProtectedRoute>
              }
            />
            <Route
              path="vendor/add/:businessType/:appType"
              element={
                <ProtectedRoute>
                  <RolePermissions />
                </ProtectedRoute>
              }
            />
            <Route
              path="vendor/edit/:roleId/:businessType/:appType"
              element={
                <ProtectedRoute>
                  <RolePermissions />
                </ProtectedRoute>
              }
            />
            <Route
              path="restaurant/add/:businessType/:appType"
              element={
                <ProtectedRoute>
                  <RolePermissions />
                </ProtectedRoute>
              }
            />
            <Route
              path="restaurant/edit/:roleId/:businessType/:appType"
              element={
                <ProtectedRoute>
                  <RolePermissions />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/settings">
            <Route
              index
              element={
                <ProtectedRoute>
                  <ManageEmail />
                </ProtectedRoute>
              }
            />
          </Route>
        </Route>
        <Route path="/*" element={<GenericNotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
