/**
 * @file   src\index.tsx
 * @brief  This file is used to mount/render your main react component onto root element.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { React, ReactDOM, Provider, IntlProvider, ToastContainer } from './utils/thirdpartyComponents';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/main.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import { store } from './store';
import LocaleMessages from './assets/strings/English.json';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <IntlProvider locale="en" messages={LocaleMessages}>
      <React.StrictMode>
        <App />
        <ToastContainer />
      </React.StrictMode>
    </IntlProvider>
  </Provider>,
);
