/**
 * @file   src\features\profile\View.tsx
 * @brief Component to display and edit admin profile info
 * @date   June, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { Button, Row, Col, Modal, FormattedMessage, useEffect, useState, Link } from '../../utils/thirdpartyComponents';
import { useIntlMessages, useIntlActionMessages, formatPhoneNumber, getFromLocalStorage, setLocalStorage } from '../../utils/helper';
import '../../styles/Settings.scss';
import InputIcon from '../../components/InputIcon';
import PasswordIcon from '../../assets/icons/Password';
import PasswordLabel from '../../components/PasswordIcon';
import InputMaskLabel from '../../components/InputMaskLabel';
import ShowPassIcon from '../../assets/icons/ShowPassword';
import HidePassIcon from '../../assets/icons/HidePassword';
import Camera from '../../assets/icons/Camera';
import EditIcon from '../../assets/icons/Edit';
import FirstName from '../../assets/icons/Profile';
import DefaultProfilePic from '../../assets/ProfilePicture.svg';
import ImageUpload from '../../components/ImageUpload';
import { UPDATE_PASSWORD_SCHEMA, ADMIN_PROFILE_SCHEMA } from '../../validations/userSchema';
import { validateForm } from '../../utils/formValidation';
import { IUpdatePwdForm, IAdminData, IUpdatePwdRequest } from '../../interfaces/userInterface';
import { getAdminProfile, updateAdminProfile, adminPasswordUpdate } from '../../store/actions/userAction';
import { useAppDispatch, useAppSelector } from '../../hooks';
import GifLoader from '../../components/GifLoader';
import { RootState } from '../../store';
import { CountryCodes, PhoneModified, UploaderProps } from '../../utils/enums';
import { MessageToaster } from '../../utils/toastUtil';
import { resetProfileData } from '../../store/slices/userSlice';
import { PASSWORD_MAX_LENGTH, PROFILE_IMAGE_TYPES_SUPPORTED } from '../../utils/constants';
import CropImage from '../../components/CropImage';

// Update password default data
const updatePwdDefaultData: IUpdatePwdForm = {
  oldPassword: '',
  newPassword: '',
  newPasswordConfirm: '',
};
const adminDefault: IAdminData = {
  firstName: '',
  lastName: '',
  name: '',
  roleName: '',
  email: '',
  phone: '',
  image: '',
};
// Toast object creation.
const toast = new MessageToaster();

// Component to display and edit admin profile info
const Profile = () => {
  // Declare action dispatch
  const dispatch = useAppDispatch();

  // Api action calls
  const updateProfileAdmin = (request: any) => dispatch(updateAdminProfile(request));
  const getProfileInfo = () => dispatch(getAdminProfile());
  const updatePassword = (request: IUpdatePwdRequest) => dispatch(adminPasswordUpdate(request));
  // Accessing redux state data
  const { isLoading, isSuccess, adminProfile, profileUpdateLoading, profileUpdateSuccess, errorCode, pwdUpdateLoading, pwdUpdateSuccess, errorMessage } = useAppSelector(
    (state: RootState) => state.user,
  );

  // Component State Variables
  const [passwordUpdateShow, setPasswordUpdateShow] = useState<boolean>(false);
  const [editProfile, setEditProfilePop] = useState<boolean>(false);
  const [pwdErrorFields, setPwdErrorFields] = useState<any>({});
  const [pwdUpdateData, setPwdUpdateData] = useState<IUpdatePwdForm>(updatePwdDefaultData);
  const [oldPasswordShow, setOldPasswordShow] = useState<boolean>(false);
  const [newPasswordShow, setNewPasswordShow] = useState<boolean>(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState<boolean>(false);
  const [adminData, setAdminData] = useState<IAdminData>(adminDefault);
  const [adminImage, setAdminImage] = useState<any>(null);
  const [profileErrorFields, setProfileErrorFields] = useState<any>({});
  const [adminDataBackup, setAdminDataBackup] = useState<IAdminData>(adminDefault);
  const [imgError, setImgError] = useState<string>('');
  const [cropVisible, setCropSection] = useState<boolean>(false);
  const [image, setImage] = useState<string | null>(null);

  // get admin profile data
  useEffect(() => {
    getProfileInfo();
    return () => {
      dispatch(resetProfileData());
    };
  }, []);
  // set admin data from api response
  useEffect(() => {
    if (isSuccess && Object.keys(adminProfile).length > 0) {
      const data = {
        firstName: adminProfile.user.firstName,
        lastName: adminProfile.user.lastName,
        name: `${adminProfile.user.firstName} ${adminProfile.user.lastName}`,
        roleName: adminProfile.user.roleName,
        email: adminProfile.user.email,
        phone: adminProfile.user.phone,
        image: adminProfile.user.profileImage === null ? '' : adminProfile.user.profileImage,
      };
      setAdminData(data);
      setAdminDataBackup(data);
      const storageData = getFromLocalStorage('MCSA_UDT');
      if (storageData) {
        const userImage = storageData.profileImage;
        storageData.profileImage = adminProfile.user?.profileImage || userImage;
        storageData.firstName = adminProfile.user?.firstName || storageData.firstName;
        storageData.lastName = adminProfile.user?.lastName || storageData.lastName;
        localStorage.removeItem('MCSA_UDT');
        setLocalStorage('MCSA_UDT', JSON.stringify(storageData));
      }
    }
  }, [isSuccess, adminProfile]);
  // handle profile update success and failure
  useEffect(() => {
    if (profileUpdateSuccess && errorCode === 0) {
      toast.toastSuccess(errorMessage || useIntlActionMessages('AdminProfile.Edit.Success'));
      getProfileInfo();
    } else if (profileUpdateSuccess && errorCode !== 0) {
      toast.toastError(errorMessage || useIntlActionMessages('AdminProfile.Edit.Failed'));
    }
  }, [profileUpdateSuccess, errorCode]);
  // handle password update success and failure
  useEffect(() => {
    if (pwdUpdateSuccess && errorCode === 0) {
      toast.toastSuccess(errorMessage || useIntlActionMessages('Admin.Password.Edit.Success'));
      setPwdUpdateData(updatePwdDefaultData);
    } else if (pwdUpdateSuccess && errorCode !== 0) {
      toast.toastError(errorMessage || useIntlActionMessages('Unexpected.Error'));
    }
  }, [errorCode, pwdUpdateSuccess]);

  // hide / show old pwd
  const toggleOldPwd = () => {
    setOldPasswordShow(!oldPasswordShow);
  };
  // hide / show new pwd
  const toggleNewPwd = () => {
    setNewPasswordShow(!newPasswordShow);
  };
  // hide / show confirm pwd
  const toggleConfirmPwd = () => {
    setConfirmPasswordShow(!confirmPasswordShow);
  };
  // handle update password form submit
  const updatePasswordSubmit = async () => {
    const errorresult = await validateForm(pwdUpdateData, UPDATE_PASSWORD_SCHEMA, pwdErrorFields);
    setPwdErrorFields(errorresult);
    if (Object.keys(errorresult).length === 0) {
      const userData = getFromLocalStorage('MCSA_UDT');
      const request = {
        oldPassword: pwdUpdateData.oldPassword.trim(),
        newPassword: pwdUpdateData.newPassword.trim(),
        userGuid: userData?.userGuid,
      };
      updatePassword(request);
    }
  };

  // handle update password form fields validation
  const onInputHandleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPwdUpdateData((info) => ({
      ...info,
      [name]: value,
    }));
    const validateObj = {
      [name]: value,
    };
    const errorresult = await validateForm(validateObj, UPDATE_PASSWORD_SCHEMA, pwdErrorFields);
    setPwdErrorFields(errorresult);
  };
  // handle profile update form fields validation
  const onInputHandleChangeProfile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setAdminData((info) => ({
      ...info,
      [name]: value,
    }));
    const validateObj = {
      [name]: value,
    };
    const errorresult = await validateForm(validateObj, ADMIN_PROFILE_SCHEMA, profileErrorFields);
    setProfileErrorFields(errorresult);
  };

  // handle phone number field change
  const onPhoneFieldHandleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const inputVal = value ? value.replace(/\D/g, '') : '';
    setAdminData((info) => ({
      ...info,
      [name]: inputVal,
    }));
    const validateObj = {
      [name]: inputVal,
    };
    const errorresult = await validateForm(validateObj, ADMIN_PROFILE_SCHEMA, profileErrorFields);
    setProfileErrorFields(errorresult);
  };
  // set image selected
  const setPicture = (pic: any) => {
    setAdminImage(pic);
    setImage(pic);
    setCropSection(true);
  };
  // hide profile update form popup
  const hideProfileFormPopup = () => {
    setEditProfilePop(false);
    setAdminData(adminDataBackup);
    setProfileErrorFields({});
    setImgError('');
  };
  // admin update profile api call
  const updateProfileInfo = () => {
    setEditProfilePop(false);
    const formData = new FormData();
    const isPhoneModified = adminData.phone.trim() !== adminDataBackup.phone ? PhoneModified.YES : PhoneModified.NO;
    const userData = getFromLocalStorage('MCSA_UDT');
    const imageFile = adminImage !== '' ? adminImage : null;
    formData.append('firstName', adminData.firstName.trim());
    formData.append('lastName', adminData.lastName.trim());
    formData.append('phoneNo', adminData.phone.trim());
    formData.append('countryCode', CountryCodes.US);
    formData.append('isPhoneModified', isPhoneModified);
    formData.append('userGuid', userData?.userGuid);
    formData.append('file', imageFile as any);
    updateProfileAdmin(formData);
  };

  // handle profile form submit
  const profileFormSubmit = async () => {
    const errorresult = await validateForm(pwdUpdateData, ADMIN_PROFILE_SCHEMA, profileErrorFields);
    setProfileErrorFields(errorresult);
    if (Object.keys(errorresult).length === 0) {
      updateProfileInfo();
    }
  };

  // Crop image cancel event handler.
  const onCropCancelHandler = () => {
    setImage(adminData && adminData.image.length > 0 ? adminData.image : DefaultProfilePic);
    setCropSection(false);
  };

  // Crop image success handler.
  const onImageCropped = (img: any) => {
    setAdminImage(img);
    setImage(img ? URL.createObjectURL(img) : null);
    setCropSection(false);
  };

  return (
    <>
      <div className="content-nav contnt-container-margin">
        <div className="content-nav-sub">
          <div className="settings-wrap">
            <div className="right-wrap w-100">
              <div className="header d-flex justify-content-between">
                <h2>
                  <FormattedMessage id="Profile.HD" />
                </h2>
              </div>
              <div className="settings-body p-0">
                <div className="profile-header d-flex">
                  <div className="profile-photo">
                    <div className="image-wrap position-relative">
                      <img src={adminData && adminData.image.length > 0 ? adminData.image : DefaultProfilePic} alt="Profile" />
                    </div>
                  </div>
                  <div className="profile-details">
                    <p className="name d-flex align-items-center mb-2">
                      <span>{adminData && adminData.name}</span>
                      <Link
                        to="#"
                        className="add mb-0 ms-2 d-flex justify-content-center align-items-center no-margin"
                        onClick={() => {
                          setAdminImage(adminData && adminData.image.length > 0 ? adminData.image : DefaultProfilePic);
                          setImage(adminData && adminData.image.length > 0 ? adminData.image : DefaultProfilePic);
                          setEditProfilePop(true);
                        }}
                      >
                        <EditIcon />
                      </Link>
                    </p>
                    <p className="role mb-1">{adminData && adminData.roleName}</p>
                    <div className="phone">
                      <p className="number mb-1">{adminData && adminData.email}</p>
                      <p className="number mb-1">{adminData && formatPhoneNumber(adminData.phone)}</p>
                    </div>
                  </div>
                </div>
                <div className="profile-body">
                  <div className="profile-inner d-flex justify-content-center">
                    <Col lg={4} md={6} sm={8}>
                      <h1>
                        <FormattedMessage id="Profile.HD.UpdatePassword" />
                      </h1>
                      <Row>
                        <Col>
                          <PasswordLabel
                            name="oldPassword"
                            id="password"
                            type={oldPasswordShow ? 'text' : 'password'}
                            placeholder={useIntlMessages('Profile.CurrentPassword')}
                            icon={<PasswordIcon />}
                            onChange={(e) => onInputHandleChange(e)}
                            value={pwdUpdateData.oldPassword}
                            errorMessage={pwdErrorFields?.oldPassword}
                            showpass={oldPasswordShow ? <HidePassIcon togglePassword={() => toggleOldPwd()} /> : <ShowPassIcon togglePassword={() => toggleOldPwd()} />}
                            maxLength={PASSWORD_MAX_LENGTH}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <PasswordLabel
                            name="newPassword"
                            id="password"
                            type={newPasswordShow ? 'text' : 'password'}
                            placeholder={useIntlMessages('Profile.NewPassword')}
                            icon={<PasswordIcon />}
                            onChange={(e) => onInputHandleChange(e)}
                            value={pwdUpdateData.newPassword}
                            errorMessage={pwdErrorFields?.newPassword}
                            showpass={newPasswordShow ? <HidePassIcon togglePassword={() => toggleNewPwd()} /> : <ShowPassIcon togglePassword={() => toggleNewPwd()} />}
                            maxLength={PASSWORD_MAX_LENGTH}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <PasswordLabel
                            name="newPasswordConfirm"
                            id="password"
                            type={confirmPasswordShow ? 'type' : 'password'}
                            placeholder={useIntlMessages('Profile.RePassword')}
                            icon={<PasswordIcon />}
                            onChange={(e) => onInputHandleChange(e)}
                            value={pwdUpdateData.newPasswordConfirm}
                            errorMessage={pwdUpdateData.newPassword !== pwdUpdateData.newPasswordConfirm ? useIntlActionMessages('Form.Password.NotMatch') : ''}
                            showpass={confirmPasswordShow ? <HidePassIcon togglePassword={() => toggleConfirmPwd()} /> : <ShowPassIcon togglePassword={() => toggleConfirmPwd()} />}
                            maxLength={PASSWORD_MAX_LENGTH}
                          />
                        </Col>
                      </Row>
                      <Button variant="primary" className="mt-3" onClick={updatePasswordSubmit}>
                        <FormattedMessage id="Button.Update" />
                      </Button>
                    </Col>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* show loading state */}
      {(isLoading || profileUpdateLoading || pwdUpdateLoading) && <GifLoader />}
      {/* ########Password Update Popup######## */}
      <Modal show={passwordUpdateShow} onHide={() => setPasswordUpdateShow(false)} centered>
        <Modal.Body>
          <p className="text-center">
            <FormattedMessage id="Profile.PasswordUpdated" />
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="primary min-w-200 ms-4">
            <FormattedMessage id="Button.OK" />
          </Button>
        </Modal.Footer>
      </Modal>

      {/* ########Profile Edit Popup######## */}
      <Modal show={editProfile} onHide={() => hideProfileFormPopup()} centered backdrop="static">
        <Modal.Header closeButton>
          <h4>
            <FormattedMessage id="Profile.HD.Edit" />
          </h4>
        </Modal.Header>
        <Modal.Body className="pt-2">
          {!cropVisible && (
            <div className="mb-4 profile-edit">
              <div className="profile-img-hover">
                <Camera />
              </div>
              <ImageUpload
                setError={(error: string) => setImgError(error)}
                imageFile={image}
                setPicture={setPicture}
                maxSize={Number(UploaderProps.PROFILE_IMAGE_MAX_SIZE)}
                acceptedFileTypes={PROFILE_IMAGE_TYPES_SUPPORTED}
                invalidSizeMessage={useIntlActionMessages('Profile.Image.Upload.Size.Message')}
                invalidTypeMessage={useIntlActionMessages('Profile.Image.Upload.AcceptedType.Error')}
              />
            </div>
          )}
          {cropVisible && (
            <div className="form-group">
              <div className="position-relative">
                <h6>
                  <FormattedMessage id="Profile.Image.Crop.Header" />
                </h6>
                <CropImage image={image} handlerCancel={onCropCancelHandler} setCroppedImage={onImageCropped} />
              </div>
            </div>
          )}
          <span className="error">{imgError}</span>
          <InputIcon
            id="firstName"
            name="firstName"
            type="text"
            placeholder={useIntlMessages('Add.FirstName')}
            icon={<FirstName />}
            value={adminData?.firstName}
            onChange={(e) => onInputHandleChangeProfile(e)}
            errorMessage={profileErrorFields?.firstName}
            maxLength={21}
          />
          <InputIcon
            id="lastName"
            name="lastName"
            type="text"
            placeholder={useIntlMessages('Add.LastName')}
            icon={<FirstName />}
            value={adminData?.lastName}
            onChange={(e) => onInputHandleChangeProfile(e)}
            errorMessage={profileErrorFields?.lastName}
            maxLength={21}
          />
          <InputMaskLabel
            id="phone"
            name="phone"
            type="phone"
            placeholder={useIntlMessages('Add.Phone')}
            mask="(999) 999-9999"
            maxLength={10}
            onChange={(e) => onPhoneFieldHandleChange(e)}
            value={adminData?.phone}
            errorMessage={profileErrorFields?.phone}
          />
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="primary min-w-200 ms-4" onClick={profileFormSubmit} disabled={cropVisible}>
            <FormattedMessage id="Button.Update" />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Profile;
