/**
 * @file   src\features\settings\ManageEmail.tsx
 * @brief  Manage multiple email address for contact.
 * @date   Dec, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { Button, Row, Col, FormattedMessage, useState, useEffect, Modal } from '../../utils/thirdpartyComponents';
import '../../styles/EmailSettings.scss';
import Menu from './Menu';
import OffcanvasMenu from './OffCanvas';
import InputLabel from '../../components/InputLabel';
import Email from '../../components/EmailCard';
import { useIntlActionMessages, useIntlMessages } from '../../utils/helper';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { RootState } from '../../store';
import { IContactEmails, IEmailUpdateData, IEmailUpdateRequest } from '../../interfaces/settingsInterface';
import { resetSettings } from '../../store/slices/settingsSlice';
import { getAllContactEmails, saveContactEmails } from '../../store/actions/settingsAction';
import GifLoader from '../../components/GifLoader';
import { INPUT_MAX_LENGTH_200 } from '../../utils/constants';
import { validateForm } from '../../utils/formValidation';
import { SETTINGS_ADDEMAIL_FORM_SCHEMA } from '../../validations/settingsSchema';
import { MessageToaster } from '../../utils/toastUtil';

// Toast object creation.
const toast = new MessageToaster();
const ManageEmail = () => {
  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Access redux state variables.
  const { isContactEmailsLoading, contactEmails, saveEmailsLoading, saveEmailsSuccess, saveEmailsErrorCode, saveEmailsErrorMessage, inviteVendorInvalidEmails } = useAppSelector(
    (state: RootState) => state.settings,
  );

  // Initialize component stat variables.
  const [newEmail, setNewEmail] = useState<string>('');
  const [emailList, setEmailList] = useState<IContactEmails[] | null>(null);
  const [emailMainList, setEmailMainList] = useState<IContactEmails[] | null>(null);
  const [errorFields, setErrorFields] = useState<any>({});
  const [invalidEmails, setInvalidEmails] = useState<string[]>([]);
  const [invalidEmailPopup, setInvalidEmailPopup] = useState<boolean>(false);

  // Initial component loading..
  useEffect(() => {
    dispatch(getAllContactEmails());
    return () => {
      dispatch(resetSettings());
    };
  }, [dispatch]);

  // Contact emails api response state change.
  useEffect(() => {
    setEmailList(contactEmails);
    setEmailMainList(contactEmails);
  }, [contactEmails]);

  // Save emails api response state change handling.
  useEffect(() => {
    if (saveEmailsSuccess && saveEmailsErrorCode === 0 && saveEmailsErrorMessage) {
      dispatch(getAllContactEmails());
      toast.toastSuccess(saveEmailsErrorMessage);
    } else if (saveEmailsSuccess && saveEmailsErrorCode > 0 && saveEmailsErrorMessage) {
      toast.toastError(saveEmailsErrorMessage);
      if (inviteVendorInvalidEmails.length > 0) {
        setInvalidEmails(inviteVendorInvalidEmails || []);
        setInvalidEmailPopup(true);
      }
    }
  }, [saveEmailsSuccess]);

  // Email input field change event.
  const onInputHandleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setNewEmail(value);
    const validateObj = {
      newEmail: value?.trim() || '',
    };
    const errorresult = await validateForm(validateObj, SETTINGS_ADDEMAIL_FORM_SCHEMA, errorFields);
    setErrorFields(errorresult);
  };

  // Add email click event handler.
  const onAddEmailHandler = async () => {
    const validateObj = {
      newEmail: newEmail.trim(),
    };
    const errorResult = await validateForm(validateObj, SETTINGS_ADDEMAIL_FORM_SCHEMA, errorFields);
    setErrorFields(errorResult);
    if (Object.keys(errorResult).length <= 0) {
      const existingMailList = emailList ? [...emailList] : [];
      const index = existingMailList.findIndex((x: IContactEmails) => x.email?.trim() === newEmail.trim());
      if (index > -1) {
        toast.toastError(useIntlActionMessages('Settings.EmailManagement.AddEmail.ErrorMessage'));
      } else {
        existingMailList.push({ emailId: 0, email: newEmail.trim() } as IContactEmails);
        setEmailList(existingMailList);
        setNewEmail('');
      }
    }
  };

  // Email delete click handler.
  const onDeleteClickHandler = (id: number, email: string) => {
    const existingMailList = emailList ? [...emailList] : [];
    const index = existingMailList.findIndex((mail: IContactEmails) => mail.emailId === id && mail.email === email);
    if (index > -1) {
      existingMailList.splice(index, 1);
      setEmailList(existingMailList);
    }
  };

  // Save email list handler.
  const saveEmails = () => {
    if (emailList) {
      const updateMailData = emailList?.map((item: IContactEmails) => {
        return { systemEmailId: item.emailId, email: item.email } as IEmailUpdateData;
      });
      const updateMailRequest: IEmailUpdateRequest = {
        emails: updateMailData,
      };
      dispatch(saveContactEmails(updateMailRequest));
    }
  };

  return (
    <div className="content-nav contnt-container-margin">
      <div className="content-nav-sub">
        <div className="settings-wrap">
          <div className="left-wrap">
            <h2>
              <FormattedMessage id="Header.MenuSettings" />
            </h2>
            <Menu />
          </div>
          <div className="right-wrap">
            <div className="header d-flex justify-content-between">
              <h2>
                <FormattedMessage id="Settings.EmailManagement.Heading" />
              </h2>
              <div className="button-wrap d-flex">
                <OffcanvasMenu />
              </div>
            </div>
            <div className="settings-body">
              <small>{`${useIntlActionMessages('Settings.EmailManagement.Info1')}`}</small>
              <Row className="mt-5 mb-4">
                <Col lg={4}>
                  <InputLabel
                    id="email"
                    name="email"
                    type="email"
                    placeholder={useIntlMessages('Settings.EmailManagement.AddEmail.Input.PlaceHolder')}
                    value={newEmail}
                    maxLength={INPUT_MAX_LENGTH_200}
                    onChange={(e) => onInputHandleChange(e)}
                    errorMessage={errorFields?.newEmail}
                  />
                </Col>
                <Col>
                  <Button variant="primary" onClick={onAddEmailHandler}>
                    <FormattedMessage id="Settings.EmailManagement.Btn.AddEmail" />
                  </Button>
                </Col>
              </Row>
              <h6 className="text-uppercase border-bottom pb-3">
                <FormattedMessage id="Settings.EmailManagement.AddedEmails.Header" />
              </h6>
              {emailList && emailList.length > 0 && (
                <Row className="mb-4">
                  {emailList.map((mail: IContactEmails) => (
                    <Col xl={4} lg={6} md={6} sm={12}>
                      <Email id={mail.emailId} email={mail.email} onDeleteClickHandler={(id: number, email: string) => onDeleteClickHandler(id, email)} />
                    </Col>
                  ))}
                </Row>
              )}
              {!emailList && (
                <Row className="mb-4">
                  <Col xl={4} lg={6} md={6} sm={12}>
                    <span>
                      <FormattedMessage id="Settings.EmailManagement.NoDataFound" />
                    </span>
                  </Col>
                </Row>
              )}
              {emailList && emailList.length === 0 && (
                <Row className="mb-4">
                  <Col xl={4} lg={6} md={6} sm={12}>
                    <span>
                      <FormattedMessage id="Settings.EmailManagement.NoDataFound" />
                    </span>
                  </Col>
                </Row>
              )}
              {emailList && emailList.length > 0 && (
                <Button variant="primary" onClick={saveEmails} disabled={JSON.stringify(emailList) === JSON.stringify(emailMainList)}>
                  <FormattedMessage id="Settings.EmailManagement.Btn.Save" />
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      {(isContactEmailsLoading || saveEmailsLoading) && <GifLoader />}
      {/* invalid emails popup */}
      {invalidEmailPopup && (
        <Modal show={invalidEmailPopup} onHide={() => setInvalidEmailPopup(false)} centered backdrop="static">
          <Modal.Body>
            {invalidEmails.length > 0 && (
              <p className="text-center">
                <span className="text-center text-red">
                  <FormattedMessage id="Settings.EmailManagement.InvalidEmail" />
                </span>{' '}
              </p>
            )}
            <div className="popover shadow-none">
              <ul>
                {invalidEmails.map((email) => {
                  return <li>{email}</li>;
                })}
              </ul>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" className="btn-modal-footer" onClick={() => setInvalidEmailPopup(false)}>
              <FormattedMessage id="Button.OK" />
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default ManageEmail;
