import React from 'react';

const FileIcon = () => {
  return (
    <svg width="37" height="45" viewBox="0 0 37 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.953 0H5.73602C2.57225 0 0 2.52246 0 5.625V39.375C0 42.4775 2.57225 45 5.73602 45H30.9745C34.1383 45 36.7105 42.4775 36.7105 39.375V13.5L22.953 0ZM32.292 12.375H27.5329C25.6328 12.375 24.0913 10.8633 24.0913 9V4.31543L32.292 12.375ZM34.4161 39.375C34.4161 41.2383 32.8746 42.75 30.9745 42.75H5.73602C3.83596 42.75 2.29441 41.2383 2.29441 39.375V5.625C2.29441 3.76172 3.83596 2.25 5.73602 2.25H21.7969V9C21.7969 12.1025 24.3691 14.625 27.5329 14.625H34.4161V39.375Z"
        fill="#4296C1"
      />
      <path
        d="M28.4127 29.7042C28.379 29.3235 28.0307 27.4085 24.9637 27.5036C22.7954 27.5631 21.8068 27.682 21.4248 27.7534C20.9642 27.23 19.3577 25.3506 18.6611 23.8162C18.8521 23.1738 19.6048 20.5569 19.5711 18.7965C19.5374 17.2026 19.2566 15.3945 17.4029 15.3945H17.3692C16.8299 15.4064 16.403 15.5848 16.0997 15.9417C15.6166 16.5127 15.4818 17.4405 15.6278 19.0701C15.7739 20.6164 16.4142 22.365 16.594 22.8408C16.4479 23.3285 15.875 25.1603 15.1672 26.9683C14.5044 28.6693 13.9988 29.7161 13.8303 30.061C13.2461 30.287 10.8981 31.2267 9.76343 32.3687C8.70739 33.4392 7.75246 34.7001 8.63998 36.0085C8.93208 36.4486 9.4938 36.7103 10.1229 36.7103H10.1454C11.2801 36.7103 12.4934 35.9134 13.4708 34.5335C14.9425 32.44 15.7289 31.0245 15.9649 30.5844C16.5266 30.4179 18.605 29.7993 19.3127 29.6209C19.9194 29.4663 20.6272 29.3235 20.9417 29.2641C21.4922 29.835 23.6829 31.881 25.8624 31.8096C26.8735 31.7739 27.6038 31.4884 28.0307 30.965C28.3228 30.5963 28.4576 30.1562 28.4127 29.7042ZM10.1566 35.0926C10.0106 35.1521 9.85331 35.1283 9.71849 35.045C9.59492 34.9618 9.58368 34.9023 9.60615 34.7952C9.76343 34.0221 11.5385 32.7255 12.6507 32.107C12.0103 33.2608 10.9768 34.8428 10.1566 35.0926ZM17.3018 16.703C17.4253 16.703 17.7174 16.703 17.7287 17.8687C17.7399 18.6419 17.4478 19.9146 17.2231 20.7711C16.976 19.9741 16.8411 19.1414 16.8299 18.2969C16.8299 17.1312 17.122 16.703 17.3018 16.703ZM18.2904 28.479C17.6837 28.6455 17.2007 28.8002 16.8299 28.9429L17.0883 28.3125C17.459 27.4085 17.8298 26.3141 18.0657 25.6004C18.5937 26.4806 19.1779 27.3133 19.8071 28.1103C19.47 28.1935 18.9645 28.3125 18.2904 28.479ZM26.0085 30.0967C25.3569 30.3822 23.9189 29.9421 22.6943 28.9548C23.975 28.7764 25.6265 28.7288 26.1658 29.2046C26.2669 29.2879 26.323 29.4068 26.323 29.5377C26.323 29.8231 26.2219 30.0016 26.0085 30.0967Z"
        fill="#4296C1"
      />
    </svg>
  );
};

export default FileIcon;
