/**
 * @file   src\features\permission\Roles.tsx
 * @brief  Business roles listing page
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { useEffect, Row, Col, FormattedMessage, useState, Button, useNavigate } from '../../utils/thirdpartyComponents';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getFromLocalStorage, useIntlActionMessages } from '../../utils/helper';
import RoleList from '../../components/RoleList';
import NewUser from '../../assets/icons/Add';
import Admin from '../../assets/DropAdminIcon.svg';
import DropCustom from '../../assets/CustomRole.svg';
import SalesPerson from '../../assets/DropSalesPerson.svg';
import SalesManager from '../../assets/DropSalesManager.svg';
import Bookkeeper from '../../assets/DropAssistant.svg';
import CreditDepartment from '../../assets/DropCredit.svg';
import OrderingDepartment from '../../assets/OrderingDepartment.svg';
import Chef from '../../assets/Chef.svg';
import GeneralManager from '../../assets/GeneralManager.svg';
import { getBusinessRoles } from '../../store/actions/rolepermissionAction';
import { resetRole } from '../../store/slices/rolepermissionSlice';
import { RootState } from '../../store';
import GifLoader from '../../components/GifLoader';
import { MessageToaster } from '../../utils/toastUtil';
import { BusinessType, BusinessRoles, AppType } from '../../utils/enums';
import { IBusinessRoleProps, IUserPermRoles } from '../../interfaces/rolePermissionInterface';
import { SUPERADMIN_TYPE } from '../../utils/constants';

// Toast object creation.
const toast = new MessageToaster();

const Role = ({ businessType }: IBusinessRoleProps) => {
  // Navigate object creation.
  const navigate = useNavigate();

  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Access redux state variables.
  const { isLoading, roles, errorCode, errorMessage } = useAppSelector((state: RootState) => state.roleperm);

  // Initialize component stat variables.
  const [userData, setUserData] = useState<any>(null);

  // handle component side effects to call api's at page load.
  useEffect(() => {
    dispatch(getBusinessRoles(businessType));
    const storageData = getFromLocalStorage('MCSA_UDT');
    const userInfo = storageData && storageData !== null ? storageData : null;
    setUserData(userInfo);
    return () => {
      dispatch(resetRole());
    };
  }, [dispatch]);

  // show error message on api failure
  useEffect(() => {
    if (errorCode > 0 && errorMessage) {
      toast.toastError(errorMessage);
    }
  }, [errorCode]);

  // Get enum keys based on values.
  function getKeyByValue(value: string) {
    const indexOfS = Object.values(BusinessRoles).indexOf(value as unknown as BusinessRoles);
    const key = Object.keys(BusinessRoles)[indexOfS];
    return key.toLowerCase();
  }

  // Get icon for each role
  const getIcon = (iconName: string) => {
    let icon;
    switch (iconName) {
      case getKeyByValue(BusinessRoles.ADMIN):
        icon = Admin;
        break;
      case getKeyByValue(BusinessRoles.SALES_PERSON):
        icon = SalesPerson;
        break;
      case getKeyByValue(BusinessRoles.SALES_MANAGER):
        icon = SalesManager;
        break;
      case getKeyByValue(BusinessRoles.ASSISTANT):
        icon = Bookkeeper;
        break;
      case getKeyByValue(BusinessRoles.CREDIT_DEPARTMENT):
        icon = CreditDepartment;
        break;
      case getKeyByValue(BusinessRoles.ORDERING_DEPARTMENT):
        icon = OrderingDepartment;
        break;
      case getKeyByValue(BusinessRoles.CUSTOM_ROLE):
        icon = DropCustom;
        break;
      case getKeyByValue(BusinessRoles.CHEF):
        icon = Chef;
        break;
      case getKeyByValue(BusinessRoles.SOUS_CHEF):
        icon = Chef;
        break;
      case getKeyByValue(BusinessRoles.GENERAL_MANAGER):
        icon = GeneralManager;
        break;
      case getKeyByValue(BusinessRoles.BOOKKEEPER):
        icon = Bookkeeper;
        break;
      default:
        icon = DropCustom;
        break;
    }
    return icon;
  };

  return (
    <div className="content-nav contnt-container-margin">
      <div className="content-nav-sub">
        <div className="content-nav-header d-flex justify-content-between align-items-center">
          <h2>{businessType === BusinessType.VENDOR ? <FormattedMessage id="HD.Vendor.Roles" /> : <FormattedMessage id="HD.Restaurant.Roles" />}</h2>
          <div>
            {userData?.userType === SUPERADMIN_TYPE && (
              <Button
                variant="primary"
                onClick={() => {
                  if (businessType === BusinessType.VENDOR) {
                    navigate(`/permissions/vendor/add/${businessType}/${AppType.VENDOR_RESTAURANT_WEB}`);
                  } else {
                    navigate(`/permissions/restaurant/add/${businessType}/${AppType.VENDOR_RESTAURANT_WEB}`);
                  }
                }}
              >
                <NewUser />
                <FormattedMessage id="Button.NewRole" />
              </Button>
            )}
          </div>
        </div>
        <div className="content-container-padding">
          <Row>
            {roles &&
              roles.map((role: IUserPermRoles) => (
                <Col key={role.roleId} xl={4} lg={6} md={12} sm={12}>
                  <RoleList
                    label={role.roleName}
                    image={getIcon(role.iconFile?.trim())}
                    link={useIntlActionMessages('Link.EditRole')}
                    path={`${businessType === BusinessType.VENDOR ? '/permissions/vendor/edit/' : '/permissions/restaurant/edit/'}${role.roleId}/${businessType}/${
                      AppType.VENDOR_RESTAURANT_WEB
                    }`}
                    roleId={role.roleId}
                    isEditable={userData?.userType === SUPERADMIN_TYPE}
                  />
                </Col>
              ))}
          </Row>
        </div>
      </div>
      {isLoading && <GifLoader />}
    </div>
  );
};
export default Role;
