/**
 * @file   src\utils\menuHelper.ts
 * @brief  This helper file is used to manage/configure menu file.
 * @date   May, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import MenuHome from '../assets/icons/MenuHome';
import MenuUser from '../assets/icons/MenuUser';
import MenuVM from '../assets/icons/MenuVM';
import MenuRM from '../assets/icons/MenuRM';
import MenuBM from '../assets/icons/MenuBM';
import MenuPM from '../assets/icons/MenuPM';
import MenuSM from '../assets/icons/MenuSM';
import MenuRP from '../assets/icons/MenuRoleSetting';
import { useIntlActionMessages } from './helper';
import { MenuPermissions, MenuUrls } from './enums';
import { IMenuItems, IPermissionUrls } from '../interfaces/generalInterface';

// Menu items data array.
export const MenuItems: IMenuItems[] = [
  {
    permissionId: MenuPermissions.DASHBOARD,
    path: MenuUrls.DASHBOARD,
    icon: MenuHome,
    label: useIntlActionMessages('Menu.Dashboard'),
  },
  {
    permissionId: MenuPermissions.USERS,
    path: MenuUrls.USERS,
    icon: MenuUser,
    label: useIntlActionMessages('Menu.Users'),
  },
  {
    permissionId: MenuPermissions.VENDOR_MANAGEMENT,
    path: MenuUrls.VENDORS,
    icon: MenuVM,
    label: useIntlActionMessages('Menu.VM'),
  },
  {
    permissionId: MenuPermissions.RESTAURANT_MANAGEMENT,
    path: MenuUrls.RESTAURANTS,
    icon: MenuRM,
    label: useIntlActionMessages('Menu.RM'),
  },
  {
    permissionId: MenuPermissions.ONBOARDING,
    path: MenuUrls.ONBOARDING,
    icon: MenuBM,
    label: useIntlActionMessages('Menu.BM'),
  },
  {
    permissionId: MenuPermissions.PRODUCT_MANAGEMENT,
    path: MenuUrls.PRODUCTS,
    icon: MenuPM,
    label: useIntlActionMessages('Menu.PM'),
  },
  {
    permissionId: MenuPermissions.ROLE_PERMISSIONS,
    path: MenuUrls.ROLE_PERMISSION,
    icon: MenuRP,
    label: useIntlActionMessages('Menu.RP'),
    subMenu: [
      {
        permissionId: MenuPermissions.ROLE_VENDOR_PERMISSION,
        label: useIntlActionMessages('Menu.RPV'),
        path: MenuUrls.ROLE_PERMISSION_VENDOR,
      },
      {
        permissionId: MenuPermissions.ROLE_RESTAURANT_PERMISSION,
        label: useIntlActionMessages('Menu.RPR'),
        path: MenuUrls.ROLE_PERMISSION_RESTAURANT,
      },
    ],
  },
  {
    permissionId: MenuPermissions.SUBSCRIPTION,
    path: MenuUrls.SUBSCRIPTIONS,
    icon: MenuSM,
    label: useIntlActionMessages('Menu.SM'),
  },
];

// Menu Url & permissions info
export const PermissionUrls: IPermissionUrls[] = [
  {
    permissionId: 601,
    path: MenuUrls.DASHBOARD,
  },
  {
    permissionId: 601,
    path: MenuUrls.DASHBOARD_ORDER_DETAILS,
  },
  {
    permissionId: 601,
    path: MenuUrls.DASHBOARD_TRANSACTION_DETAILS,
  },
  {
    permissionId: 602,
    path: MenuUrls.USERS,
  },
  {
    permissionId: 602,
    path: MenuUrls.USERS_INACTIVE,
  },
  {
    permissionId: 604,
    path: MenuUrls.ROLE,
  },
  {
    permissionId: 606,
    path: MenuUrls.VENDORS,
  },
  {
    permissionId: 606,
    path: MenuUrls.VENDORS_ADD,
  },
  {
    permissionId: 606,
    path: MenuUrls.VENDORS_EDIT,
  },
  {
    permissionId: 606,
    path: MenuUrls.VENDORS_DETAILS,
  },
  {
    permissionId: 606,
    path: MenuUrls.SUBSCRIPTION_LIST_VENDOR,
  },
  {
    permissionId: 607,
    path: MenuUrls.RESTAURANTS,
  },
  {
    permissionId: 607,
    path: MenuUrls.RESTAURANTS_ADD,
  },
  {
    permissionId: 607,
    path: MenuUrls.RESTAURANTS_EDIT,
  },
  {
    permissionId: 607,
    path: MenuUrls.RESTAURANTS_DETAILS,
  },
  {
    permissionId: 607,
    path: MenuUrls.RESTAURANTS_MERGE,
  },
  {
    permissionId: 607,
    path: MenuUrls.SUBSCRIPTION_LIST_RESTAURANT,
  },
  {
    permissionId: 608,
    path: MenuUrls.ONBOARDING,
  },
  {
    permissionId: 609,
    path: MenuUrls.PRODUCTS,
  },
  {
    permissionId: 609,
    path: MenuUrls.PRODUCTS_MAPPED,
  },
  {
    permissionId: 609,
    path: MenuUrls.PRODUCTS_SIMILAR_ITEMS,
  },
  {
    permissionId: 610,
    path: MenuUrls.SUBSCRIPTIONS,
  },
];

// Role permissions page url info.
export const RolePermissionUrls: IPermissionUrls[] = [
  {
    permissionId: MenuPermissions.ROLE_VENDOR_PERMISSION,
    path: MenuUrls.ROLE_PERMISSION_VENDOR,
  },
  {
    permissionId: MenuPermissions.ROLE_RESTAURANT_PERMISSION,
    path: MenuUrls.ROLE_PERMISSION_RESTAURANT,
  },
  {
    permissionId: MenuPermissions.ROLE_VENDOR_PERMISSION,
    path: MenuUrls.ROLE_PERMISSION_VENDOR_ADD,
  },
  {
    permissionId: MenuPermissions.ROLE_VENDOR_PERMISSION,
    path: MenuUrls.ROLE_PERMISSION_VENDOR_EDIT,
  },
  {
    permissionId: MenuPermissions.ROLE_RESTAURANT_PERMISSION,
    path: MenuUrls.ROLE_PERMISSION_RESTAURANT_ADD,
  },
  {
    permissionId: MenuPermissions.ROLE_RESTAURANT_PERMISSION,
    path: MenuUrls.ROLE_PERMISSION_RESTAURANT_EDIT,
  },
];
