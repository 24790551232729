/**
 * @file   src\store\slices\productSlice.ts
 * @brief  This file is responsible for creating product slices to call actions and state management.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { createSlice } from '@reduxjs/toolkit';
import {
  getProductList,
  getProductGroups,
  searchProducts,
  getProductOrigins,
  getProductDetails,
  getMappedItems,
  getOriginalItems,
  getMappedItemDetails,
  getMappedItemsByUser,
  getOriginalItemsByUser,
  getSimilarItemsByUser,
  updateMappedItemStatus,
} from '../actions/productAction';
import { IItem, IProductsSlice } from '../../interfaces/productInterface';
import { useIntlActionMessages } from '../../utils/helper';
import { SimilarItemProcessStatus } from '../../utils/enums';

// Products data Initial state.
const itemsInitialState: IItem = {
  itemId: 0,
  itemGuid: '',
  itemName: '',
  vendorId: 0,
  vendorName: '',
  description: '',
  productGroupName: '',
  originCountryId: 0,
  originCountryName: '',
  originStateId: 0,
  originStateName: '',
  originType: '',
  pack: 0,
  size: 0,
  price: 0,
  pricePerLb: 0,
  stockStatus: 0,
  availableQty: 0,
  onHandInventory: 0,
  unitName: '',
  lowInventory: 0,
  location: '',
  stackability: '',
  skuNo: '',
  image: '',
  mappingId: 0,
  imageUrl: '',
  createdOn: '',
  isVariableWeight: false,
  masterProductGroupId: 0,
  masterProductGroupName: '',
};

// Product slice Initial state.
const vendorState: IProductsSlice = {
  products: [],
  totalCount: 0,
  searchProductList: [],
  searchedTotalCount: 0,
  searchErrorCode: 0,
  searchErrorMessage: null,
  searchLoading: false,
  searchSuccess: false,
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
  productGroups: [],
  productGroupSuccess: false,
  productGroupLoading: false,
  productGroupErrorCode: 0,
  productGroupErrorMessage: null,
  productOrigins: [],
  productOriginsSuccess: false,
  productOriginsLoading: false,
  productOriginsErrorCode: 0,
  productOriginsErrorMessage: null,
  productDetails: { ...itemsInitialState },
  mappedItems: [],
  mappedItemsCount: 0,
  originalItems: [],
  similarItemDetails: {},
  originalItemsLoading: false,
  originalItemsSuccess: false,
  originalItemsErrorCode: 0,
  originalItemsErrorMessage: '',
  userMappedItems: [],
  userMappedItemsTotalCount: 0,
  userMappedItemsLoading: false,
  userMappedItemsSuccess: false,
  userMappedItemsErrorCode: 0,
  userMappedItemsErrorMessage: '',
  originalItemsByUser: [],
  originalItemFetchLoading: false,
  originalItemFechSuccess: false,
  originalItemFetchErrorCode: 0,
  originalItemFetchErrorMessage: '',
  similarItemsByUser: [],
  similarItemFetchLoading: false,
  similarItemFechSuccess: false,
  similarItemFetchErrorCode: 0,
  similarItemFetchErrorMessage: '',
  updateMappedItemStatusLoading: false,
  updateMappedItemStatusSuccess: false,
  updateMappedItemStatusErrorCode: 0,
  updateMappedItemStatusErrorMessage: '',
};

// Config product slice.
const productSlice = createSlice({
  name: 'product',
  initialState: vendorState,
  reducers: {
    resetProductState: (state) => {
      state.errorCode = 0;
      state.errorMessage = null;
      state.isSuccess = false;
      state.isLoading = false;
      state.searchErrorCode = 0;
      state.searchErrorMessage = null;
      state.searchSuccess = false;
      state.searchLoading = false;
      state.productGroupSuccess = false;
      state.productGroupLoading = false;
      state.productGroupErrorCode = 0;
      state.productGroupErrorMessage = null;
      state.productOriginsSuccess = false;
      state.productOriginsLoading = false;
      state.productOriginsErrorCode = 0;
      state.productOriginsErrorMessage = null;
      state.productDetails = null;
      state.originalItemsErrorCode = 0;
      state.originalItemsErrorMessage = null;
      state.originalItemsLoading = false;
      state.originalItemsSuccess = false;
      state.userMappedItemsLoading = false;
      state.userMappedItemsSuccess = false;
      state.userMappedItemsErrorCode = 0;
      state.userMappedItemsErrorMessage = '';
      state.originalItemFetchLoading = false;
      state.originalItemFechSuccess = false;
      state.originalItemFetchErrorCode = 0;
      state.originalItemFetchErrorMessage = '';
      state.similarItemFetchLoading = false;
      state.similarItemFechSuccess = false;
      state.similarItemFetchErrorCode = 0;
      state.similarItemFetchErrorMessage = '';
      state.updateMappedItemStatusLoading = false;
      state.updateMappedItemStatusSuccess = false;
      state.updateMappedItemStatusErrorCode = 0;
      state.updateMappedItemStatusErrorMessage = '';
    },
  },
  extraReducers(builder) {
    // Start product list request.
    builder.addCase(getProductList.pending, (state) => {
      state.isSuccess = false;
      state.isLoading = true;
      state.errorCode = 0;
      state.errorMessage = '';
    });
    // Product list request success.
    builder.addCase(getProductList.fulfilled, (state, action) => {
      const data = action.payload?.data || null;
      const error = action.payload?.error || 0;
      state.products = data?.items || [];
      state.totalCount = data?.totalCount;
      state.isLoading = false;
      state.isSuccess = true;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // Product list error.
    builder.addCase(getProductList.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.isSuccess = false;
      state.isLoading = false;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Product group request start.
    builder.addCase(getProductGroups.pending, (state) => {
      state.productGroupSuccess = false;
      state.productGroupLoading = true;
      state.productGroupErrorCode = 0;
      state.productGroupErrorMessage = '';
    });
    // Product group request success.
    builder.addCase(getProductGroups.fulfilled, (state, action) => {
      const data = action.payload?.data || null;
      const error = action.payload?.error || 0;
      state.productGroups = data?.productGroups || [];
      state.productGroupSuccess = true;
      state.productGroupLoading = false;
      state.productGroupErrorCode = error;
      state.productGroupErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // Product group error.
    builder.addCase(getProductGroups.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.productGroupSuccess = false;
      state.productGroupLoading = false;
      state.productGroupErrorCode = error;
      state.productGroupErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Start search products request.
    builder.addCase(searchProducts.pending, (state) => {
      state.searchSuccess = false;
      state.searchLoading = true;
      state.searchErrorCode = 0;
      state.searchErrorMessage = '';
    });
    // Search products request success.
    builder.addCase(searchProducts.fulfilled, (state, action) => {
      const data = action.payload?.data || null;
      const error = action.payload?.error || 0;
      state.searchProductList = data?.items || [];
      state.searchedTotalCount = data?.totalCount;
      state.searchLoading = false;
      state.searchSuccess = true;
      state.searchErrorCode = error;
      state.searchErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // Search products error.
    builder.addCase(searchProducts.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.searchSuccess = false;
      state.searchLoading = false;
      state.searchErrorCode = error;
      state.searchErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Product origins request start.
    builder.addCase(getProductOrigins.pending, (state) => {
      state.productOriginsSuccess = false;
      state.productOriginsLoading = true;
      state.productOriginsErrorCode = 0;
      state.productOriginsErrorMessage = '';
    });
    // Product origins request success.
    builder.addCase(getProductOrigins.fulfilled, (state, action) => {
      const data = action.payload?.data || null;
      const error = action.payload?.error || 0;
      state.productOrigins = data?.originCountries || [];
      state.productOriginsSuccess = true;
      state.productOriginsLoading = false;
      state.productOriginsErrorCode = error;
      state.productOriginsErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // Product origins error.
    builder.addCase(getProductOrigins.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.productOriginsSuccess = false;
      state.productOriginsLoading = false;
      state.productOriginsErrorCode = error;
      state.productOriginsErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Start product details request.
    builder.addCase(getProductDetails.pending, (state) => {
      state.isSuccess = false;
      state.isLoading = true;
      state.errorCode = 0;
      state.errorMessage = '';
    });
    // Product details request success.
    builder.addCase(getProductDetails.fulfilled, (state, action) => {
      const data = action.payload?.data || null;
      const error = action.payload?.error || 0;
      state.productDetails = data?.itemDetails;
      state.isLoading = false;
      state.isSuccess = true;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // Product details error.
    builder.addCase(getProductDetails.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.isSuccess = false;
      state.isLoading = false;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Start mapped items list request.
    builder.addCase(getMappedItems.pending, (state) => {
      state.isSuccess = false;
      state.isLoading = true;
      state.errorCode = 0;
      state.errorMessage = '';
    });
    // Mapped items list request success.
    builder.addCase(getMappedItems.fulfilled, (state, action) => {
      const data = action.payload?.data || null;
      const error = action.payload?.error || 0;
      state.mappedItems = data?.items || [];
      state.mappedItemsCount = data?.totalCount;
      state.isLoading = false;
      state.isSuccess = true;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // Mapped items list error.
    builder.addCase(getMappedItems.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.isSuccess = false;
      state.isLoading = false;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Start original items list request.
    builder.addCase(getOriginalItems.pending, (state) => {
      state.originalItemsSuccess = false;
      state.originalItemsLoading = true;
      state.originalItemsErrorCode = 0;
      state.originalItemsErrorMessage = '';
    });
    // Original items list request success.
    builder.addCase(getOriginalItems.fulfilled, (state, action) => {
      const data = action.payload?.data || null;
      const error = action.payload?.error || 0;
      state.originalItems = data?.items || [];
      state.originalItemsLoading = false;
      state.originalItemsSuccess = true;
      state.originalItemsErrorCode = error;
      state.originalItemsErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // Original items list error.
    builder.addCase(getOriginalItems.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.isSuccess = false;
      state.isLoading = false;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Start similarItems details request.
    builder.addCase(getMappedItemDetails.pending, (state) => {
      state.isSuccess = false;
      state.isLoading = true;
      state.errorCode = 0;
      state.errorMessage = '';
    });
    // Similar items details request success.
    builder.addCase(getMappedItemDetails.fulfilled, (state, action) => {
      const data = action.payload?.data || null;
      const error = action.payload?.error || 0;
      state.similarItemDetails = data;
      state.isLoading = false;
      state.isSuccess = true;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // Similar items details error.
    builder.addCase(getMappedItemDetails.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.isSuccess = false;
      state.isLoading = false;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Start user mapped items list request.
    builder.addCase(getMappedItemsByUser.pending, (state) => {
      state.userMappedItemsSuccess = false;
      state.userMappedItemsLoading = true;
      state.userMappedItemsErrorCode = 0;
      state.userMappedItemsErrorMessage = '';
    });
    // User mapped items list request success.
    builder.addCase(getMappedItemsByUser.fulfilled, (state, action) => {
      const data = action.payload?.data || null;
      const error = action.payload?.error || 0;
      state.userMappedItems = data?.items || [];
      state.userMappedItemsTotalCount = data?.totalCount;
      state.userMappedItemsLoading = false;
      state.userMappedItemsSuccess = true;
      state.userMappedItemsErrorCode = error;
      state.userMappedItemsErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // User mapped items list error.
    builder.addCase(getMappedItemsByUser.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.userMappedItemsSuccess = false;
      state.userMappedItemsLoading = false;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Start fetch user wise original items list request.
    builder.addCase(getOriginalItemsByUser.pending, (state) => {
      state.originalItemFechSuccess = false;
      state.originalItemFetchLoading = true;
      state.originalItemFetchErrorCode = 0;
      state.originalItemFetchErrorMessage = '';
    });
    // Fetch user wise original items list request success.
    builder.addCase(getOriginalItemsByUser.fulfilled, (state, action) => {
      const data = action.payload?.data || null;
      const error = action.payload?.error || 0;
      state.originalItemsByUser = data?.items || [];
      state.originalItemFetchLoading = false;
      state.originalItemFechSuccess = true;
      state.originalItemFetchErrorCode = error;
      state.originalItemFetchErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // Fetch user wise original items list error.
    builder.addCase(getOriginalItemsByUser.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.originalItemFechSuccess = false;
      state.originalItemFetchLoading = false;
      state.originalItemFetchErrorCode = error;
      state.originalItemFetchErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Start fetch user wise similar items list request.
    builder.addCase(getSimilarItemsByUser.pending, (state) => {
      state.similarItemFechSuccess = false;
      state.similarItemFetchLoading = true;
      state.similarItemFetchErrorCode = 0;
      state.similarItemFetchErrorMessage = '';
    });
    // Fetch user wise similar items list request success.
    builder.addCase(getSimilarItemsByUser.fulfilled, (state, action) => {
      const data = action.payload?.data || null;
      const error = action.payload?.error || 0;
      state.similarItemsByUser = data?.items || [];
      state.similarItemFetchLoading = false;
      state.similarItemFechSuccess = true;
      state.similarItemFetchErrorCode = error;
      state.similarItemFetchErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // Fetch user wise similar items list error.
    builder.addCase(getSimilarItemsByUser.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.similarItemFechSuccess = false;
      state.similarItemFetchLoading = false;
      state.similarItemFetchErrorCode = error;
      state.similarItemFetchErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Start update mapped item status request
    builder.addCase(updateMappedItemStatus.pending, (state) => {
      state.updateMappedItemStatusLoading = true;
      state.updateMappedItemStatusSuccess = false;
      state.updateMappedItemStatusErrorCode = 0;
      state.updateMappedItemStatusErrorMessage = '';
    });
    // Update mapped item status request success.
    builder.addCase(updateMappedItemStatus.fulfilled, (state, { meta, payload }) => {
      const error = payload?.error || 0;
      state.updateMappedItemStatusLoading = false;
      state.updateMappedItemStatusSuccess = true;
      state.updateMappedItemStatusErrorCode = error;
      state.updateMappedItemStatusErrorMessage = useIntlActionMessages(
        error !== 0 ? error.toString() : meta.arg.approveStatus === SimilarItemProcessStatus.APPROVED ? 'Items.Mapping.Status.Approved' : 'Items.Mapping.Status.Declined',
      );
    });
    // Update mapped item status error.
    builder.addCase(updateMappedItemStatus.rejected, (state, action: any) => {
      const { error } = action.payload;
      state.updateMappedItemStatusLoading = false;
      state.updateMappedItemStatusSuccess = false;
      state.updateMappedItemStatusErrorCode = error;
      state.updateMappedItemStatusErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
  },
});
// Export product actions.
export const { resetProductState } = productSlice.actions;
// Export reducer.
export default productSlice.reducer;
