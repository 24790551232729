/**
 * @file   src\utils\helper.ts
 * @brief  This is a general helper file.
 * @date   May, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { useIntl, createIntl, createIntlCache, moment, matchPath } from './thirdpartyComponents';
import LocaleMessages from '../assets/strings/English.json';
import { ISelectedOptions } from '../interfaces/userInterface';
import { DeliveryDays, DateFilterOptions } from './enums';
import Admin from '../assets/DropAdminIcon.svg';
import Onboarding from '../assets/Onboarding.svg';
import DropCustom from '../assets/CustomRole.svg';
import CustomerSupport from '../assets/CustomerSupport.svg';
import { DECIMAL_REGEX_WITH_MAXLENGTH, MONTHS_SHORT_NAMES, GraphColors, WEEK_DAYS, DATE_FORMAT } from './constants';

// Set items to the local storage.
export const setLocalStorage = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

// Get items from the local storage.
export const getFromLocalStorage = (key: string) => {
  const data: any = localStorage.getItem(key);
  return JSON.parse(data);
};

// Localization/Internationalization of messages.
export const useIntlMessages = (id: string) => {
  const intl = useIntl();
  if (id !== '' && id !== undefined) {
    const message = intl.formatMessage({ id });
    return message;
  }
  return '';
};

// Localization/Internationalization of action messages.
export const useIntlActionMessages = (id: string) => {
  const locale = 'en';
  const messages = LocaleMessages;
  const cache = createIntlCache();

  const intl = createIntl(
    {
      locale,
      messages,
    },
    cache,
  );
  if (id !== '' && id !== undefined) {
    const message = intl.formatMessage({ id });
    return message;
  }
  return '';
};

// Format string.
export const stringFormat = (template: string, ...args: any[]) => {
  return template.replace(/{(\d+)}/g, (match, number) => {
    return typeof args[number] !== 'undefined' ? args[number] : match;
  });
};

// Modify & trim string with dots.
export const stringManipulateWithDots = (word: string) => {
  if (word && word.length > 0) {
    return word.substring(0, 35) + (word.length > 35 ? '...' : '');
  }
  return '';
};

// Format phone number string to US format
export const formatPhoneNumber = (phone: string) => {
  let formatedPhoneNumber = phone;
  if (phone && phone !== '' && phone.length >= 10) {
    formatedPhoneNumber = phone.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  }
  return formatedPhoneNumber;
};

// Remove USA from the address string
export const getRestaurantAddress = (name: string) => {
  if (name.endsWith('USA')) {
    const newName = name.slice(0, -5);
    return newName;
  }
  return name;
};

// Configure cutoff time dropdown values.
export const setCutOffTimeIntervals = async () => {
  const startTime = process.env.REACT_APP_CUTOFFTIME_START;
  const endTime = process.env.REACT_APP_CUTOFFTIME_END;
  const interval = Number(process.env.REACT_APP_CUTOFFTIME_INTERVAL);
  const periods = ['AM', 'PM'];
  const startHour = startTime?.split(':')[0] || '';
  const startMin = startTime?.split(':')[1] || '';
  const endHour = endTime?.split(':')[0] || '';
  const endMin = endTime?.split(':')[1] || '';
  let st = Number(startMin) > 0 ? Number(startHour) * Number(startMin) : Number(startHour) * 60;
  let et = Number(endMin) > 0 ? Number(endHour) * Number(endMin) : Number(endHour) * 60;
  const orgSt = st;
  const orgEt = et;
  et = et < st ? 24 * 60 : et;
  const timeArr: ISelectedOptions[] = [];
  for (let i = 0; st <= et; i += 1) {
    const hh = Math.floor(st / 60); // get hours in 0-24 format.
    const mm = st % 60; // get minutes in 0-55 format.
    const hour = hh % 12 === 0 ? 12 : `0${hh % 12}`.slice(-2);
    const min = `0${mm}`.slice(-2);
    const timeString = `${hour}:${min} ${periods[Math.floor(hh / 12)]}`; // pushing data in array in [00:00 - 12:00 AM/PM format]
    if (periods[Math.floor(hh / 12)] !== undefined) timeArr.push({ label: timeString, value: timeString });
    if (orgSt > orgEt && st === 24 * 60) {
      st = 0;
      et = orgEt;
    } else {
      st += interval;
    }
  }
  return timeArr;
};
// Convert local time to utc string.
export function convertLocalTimeToUtcString(value: string) {
  const time24 = moment(value, ['h:mm A']).format('HH:mm:ss');
  const dt = `${moment(new Date()).format('YYYY-MM-DD')} ${time24}`;
  return moment(new Date(dt)).utc().format('hh:mm A');
}

// Convert utc time to local time string.
export function convertUtctimeToLocalTimeString(value: string) {
  const dt = `${moment(new Date()).format('YYYY-MM-DD')} ${value}`;
  return moment(dt, 'YYYY-MM-DD hh:mm:ss').add(moment().utcOffset(), 'minutes').format('hh:mm A');
}

// convert time to Am and Pm
export const timeConvertToAmPm = (time: any) => {
  const hour = time.split(':')[0];
  const min = time.split(':')[1];
  const periods = ['AM', 'PM'];

  const st = Number(min) > 0 ? Number(hour) * 60 + Number(min) : Number(hour) * 60;

  const hh = Math.floor(st / 60); // get hours in 0-24 format.
  const mm = st % 60; // get minutes in 0-55 format.
  const timeString = `${`0${hh % 12 === 0 ? 12 : hh % 12}`.slice(-2)}:${`0${mm}`.slice(-2)} ${periods[Math.floor(hh / 12)]}`; // pushing data in array in [00:00 - 12:00 AM/PM format]

  return timeString;
};
//  Returns delivery days as string from delivery days array
export function deliveryDaysString(days: number[]) {
  let daysString = '';
  const daysArray = [...days];
  const sortedDays = daysArray.sort();
  sortedDays.forEach((item: number) => {
    if (item === DeliveryDays.MON) {
      daysString += ' Mon,';
    } else if (item === DeliveryDays.TUE) {
      daysString += ' Tue,';
    } else if (item === DeliveryDays.WED) {
      daysString += ' Wed,';
    } else if (item === DeliveryDays.THU) {
      daysString += ' Thu,';
    } else if (item === DeliveryDays.FRI) {
      daysString += ' Fri,';
    } else if (item === DeliveryDays.SAT) {
      daysString += ' Sat,';
    }
  });
  if (sortedDays.includes(DeliveryDays.SUN)) {
    daysString += ' Sun';
  }
  const finalString = daysString.replace(/,\s*$/, '');
  return finalString;
}

// To convert time to 24 hour format.
export const timeConvertTo24Hour = (time: any) => {
  let hours = Number(time.match(/^(\d+)/)[1]);
  const minutes = Number(time.match(/:(\d+)/)[1]);
  const AMPM = time.match(/\s(.*)$/)[1];
  if (AMPM === 'PM' && hours < 12) hours += 12;
  if (AMPM === 'AM' && hours === 12) hours -= 12;
  let sHours = hours.toString();
  let sMinutes = minutes.toString();
  if (hours < 10) sHours = `0${sHours}`;
  if (minutes < 10) sMinutes = `0${sMinutes}`;
  return `${sHours}:${sMinutes}:00`;
};

// Get Role Icon Based On Icon Name
export const getIcon = (iconName: string) => {
  let icon;
  switch (iconName) {
    case 'admin':
      icon = Admin;
      break;
    case 'customer_support':
      icon = CustomerSupport;
      break;
    case 'onboarding':
      icon = Onboarding;
      break;
    default:
      icon = DropCustom;
      break;
  }
  return icon;
};

// Handle decimal values only in input text field.
export const handleDecimalInputValue = (value: any) => {
  const regex = DECIMAL_REGEX_WITH_MAXLENGTH;
  return value.match(regex) ? value.match(regex)[0] : null;
};

// Function that build query string
export function buildQueryString(params: any): string {
  const queryString = Object.entries(params)
    .map(([key, value]: [string, any]) => `${key}=${encodeURIComponent(value.toString())}`)
    .join('&');
  return queryString;
}

// Function that formats phone number
export function formatPhoneNo(value: string, pattern: string) {
  let i = -1;
  const v = value.toString();
  return pattern.replace(/#/g, () => {
    i += 1;
    return v[i];
  });
}
// Function that returns month and day for graph x axis labels
export const getDayMonth = (dateString: string) => {
  let dayMonth = '';
  if (dateString !== undefined) {
    const splitArray = dateString.split('T');
    dayMonth = `${splitArray[0].slice(8, 11)}`;
  }
  return dayMonth;
};
// Function that returns month name
export const getMonth = (monthNo: number) => {
  return MONTHS_SHORT_NAMES[monthNo - 1];
};
// Function that returns graph color based on filter
export const getColor = (filter: string): string => {
  let color;
  switch (filter) {
    case DateFilterOptions.THIS_WEEK:
      color = GraphColors.week;
      break;
    case DateFilterOptions.THIS_MONTH:
      color = GraphColors.month;
      break;
    case DateFilterOptions.YEAR:
      color = GraphColors.year;
      break;
    default:
      color = GraphColors.week;
      break;
  }
  return color;
};
// Function that returns week day based on date string
export const getWeekDay = (dateString: string) => {
  let weekday = '';
  if (dateString !== undefined) {
    const date = new Date(dateString);
    weekday = WEEK_DAYS[date.getDay()];
  }
  return weekday;
};

// Get days between two daates.
export const getDaysFromTwoDates = (fromDate: string, toDate: string) => {
  const dateFrom = moment(fromDate, DATE_FORMAT);
  const dateTo = moment(toDate, DATE_FORMAT);
  return dateTo.diff(dateFrom, 'days');
};

// Match exact root path method.
export const checkPathMatched = (data: string[] | string, pathname: string) => {
  return Array.isArray(data) ? !!data.find((path) => matchPath(path, pathname)) : matchPath(data, pathname);
};

// Create image element.
const createImage = (url: string) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues.
    image.src = url;
  });

// Functionality to get cropped image from original image.
export async function getCroppedImg(imageSrc: any, crop: any) {
  const image: any = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  /* set each dimensions to double largest dimension to allow for a safe area for the image to rotate in without being clipped by canvas context */
  canvas.width = 200;
  canvas.height = 200;
  if (ctx) {
    ctx.drawImage(image, crop.x, crop.y, crop.width, crop.height, 0, 0, canvas.width, canvas.height);
  }
  /* As Base64 string */
  // return canvas.toDataURL('image/jpeg');

  /* As a blob */
  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      resolve(file);
    }, 'image/png');
  });
}

// Function which format number by adding comma separators.
export const formatNumberWithCommas = (number: number, isDecimal = true) => {
  return number
    ? number.toLocaleString('en-US', {
        minimumFractionDigits: isDecimal === true ? 2 : 0,
        maximumFractionDigits: isDecimal === true ? 2 : 0,
      })
    : 0;
};
