/**
 * @file   src\features\subscription\Subscription.tsx
 * @brief  Subscription Main Menu Component
 * @date   July, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import '../../styles/Settings.scss';
import Menu from './Menu';
import OffcanvasMenu from './OffCanvas';
import Charges from './Charges';
import { Breadcrumb, FormattedMessage, useNavigate } from '../../utils/thirdpartyComponents';

const GeneralSettings = () => {
  // Navigate object creation.
  const navigate = useNavigate();
  return (
    <div className="content-nav contnt-container-margin">
      <div className="back-navigation">
        <Breadcrumb>
          <Breadcrumb.Item href="#" onClick={() => navigate('/home')}>
            <FormattedMessage id="Menu.Home" />
          </Breadcrumb.Item>
          <Breadcrumb.Item href="#" active>
            <FormattedMessage id="Breadcrump.Subscriptionmanagement" />
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="content-nav-sub">
        <div className="settings-wrap">
          <div className="left-wrap">
            <Menu />
          </div>
          <div className="right-wrap">
            <div className="header d-flex justify-content-between">
              <div className="button-wrap d-flex">
                <OffcanvasMenu />
              </div>
            </div>
            <div className="settings-body cutoff-time">
              <Charges />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralSettings;
