/**
 * @file   src\features\restaurant\Details.tsx
 * @brief  Restaurant Details page.
 * @date   May, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import '../../styles/Vendor.scss';
import { FormattedMessage, Link, useParams, Col, Row, Breadcrumb, Button, useEffect, useNavigate, Badge, useState } from '../../utils/thirdpartyComponents';
import BackIcon from '../../assets/icons/Back';
import { getRestaurantDetails } from '../../store/actions/restaurantAction';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { RootState } from '../../store';
import GifLoader from '../../components/GifLoader';
import { formatPhoneNumber, timeConvertToAmPm, deliveryDaysString, getFromLocalStorage } from '../../utils/helper';
import { resetRestaurantState } from '../../store/slices/restaurantSlice';
import { MessageToaster } from '../../utils/toastUtil';
import { DefaultAdminRoles } from '../../utils/enums';
import ModalWindow from '../../components/ModalWindow';

// Toast object creation.
const toast = new MessageToaster();

// Component to display restaurant details
const RestaurantDetails = () => {
  // Route information variable
  const params = useParams();
  const dispatch = useAppDispatch();
  // Creating navigation object
  const navigate = useNavigate();
  // Access redux store variables
  const { isLoading, restaurantDetail, errorCode, errorMessage } = useAppSelector((state: RootState) => state.restaurant);
  const userData = getFromLocalStorage('MCSA_UDT');

  const [showModal, setShowModal] = useState<boolean>(false);

  // fetch restaurant details
  useEffect(() => {
    dispatch(getRestaurantDetails(Number(params.restaurantId)));

    return () => {
      dispatch(resetRestaurantState());
    };
  }, []);
  // show error message on api failure
  useEffect(() => {
    if (errorCode !== 0 && errorMessage) {
      toast.toastError(errorMessage);
    }
  }, [errorCode, errorMessage]);

  // load resaturant edit page
  const loadEditPage = (evt: React.MouseEvent<HTMLElement>) => {
    evt.preventDefault();
    const editUrl = `/restaurants/edit/${restaurantDetail.restaurant.restaurantId}`;
    navigate(editUrl);
  };
  // redirect to support mode page
  const loadSupportPage = () => {
    navigate(`/restaurants/cobrowse/${params.restaurantId}`);
  };

  return (
    <div className="content-nav contnt-container-margin">
      <div className="back-navigation">
        <Breadcrumb>
          <Breadcrumb.Item href="#" onClick={() => navigate('/home')}>
            <FormattedMessage id="Menu.Home" />
          </Breadcrumb.Item>
          <Breadcrumb.Item href="#" onClick={() => navigate('/restaurants')}>
            <FormattedMessage id="Restaurant.PageHD" />
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{restaurantDetail.restaurant && restaurantDetail.restaurant.restaurantName}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="content-nav-sub">
        <div className="content-nav-header d-lg-flex justify-content-between align-items-center">
          <h2 className="align-items-center">
            <Link to="/restaurants" className="btn-icon">
              <BackIcon />
            </Link>
            {restaurantDetail.restaurant && restaurantDetail.restaurant.restaurantName}
            {restaurantDetail.restaurant && !restaurantDetail.restaurant.isActive && <Badge className="badge-due-high ms-3">Deactivated</Badge>}
          </h2>
          <div>
            <Button variant="outline-primary" className="me-3" onClick={() => navigate(`/restaurants/subscriptions/${Number(params.restaurantId)}`)}>
              <FormattedMessage id="Subscriptions.Link.Label" />
            </Button>
            {(userData.defaultRoleId === null ||
              userData.defaultRoleId === DefaultAdminRoles.ADMIN ||
              userData.defaultRoleId === DefaultAdminRoles.CUSTOMER_SUPPORT ||
              userData.defaultRoleId === DefaultAdminRoles.ONBOARDING) && (
              <Button variant="primary" className="btn-orange" onClick={() => setShowModal(true)}>
                <FormattedMessage id="Button.EnterSupportMode" />
              </Button>
            )}
          </div>
        </div>
        <div className="content-container-padding  d-flex justify-content-center">
          <Col xl={10} lg={10} md={11}>
            <Col className="vendordetails">
              <div className="content-nav-header d-flex justify-content-between align-items-center">
                <h5>
                  <FormattedMessage id="Restaurant.Details.RestaurantInfoHD" />
                </h5>
                <a href="#" onClick={(e) => loadEditPage(e)}>
                  <FormattedMessage id="Vendor.Details.LinkEdit" />
                </a>
              </div>
              <div className="content-container-padding">
                <Row>
                  <Col>
                    <FormattedMessage id="Vendor.Details.AddressLbl" />:
                  </Col>
                  <Col>{restaurantDetail.restaurant && restaurantDetail.restaurant.address}</Col>
                </Row>
                <Row>
                  <Col>
                    <FormattedMessage id="Vendor.Details.DeliveryDaysLbl" />:
                  </Col>
                  <Col>{restaurantDetail.restaurant && restaurantDetail.restaurant.deliveryDaysInfo && deliveryDaysString(restaurantDetail.restaurant.deliveryDaysInfo)}</Col>
                </Row>
                <Row>
                  <Col>
                    <FormattedMessage id="Restaurant.Details.AddressLbl" />:
                  </Col>
                  <Col>
                    {restaurantDetail.restaurant && restaurantDetail.restaurant.deliveryStartTime && timeConvertToAmPm(restaurantDetail.restaurant.deliveryStartTime)} to{' '}
                    {restaurantDetail.restaurant && restaurantDetail.restaurant.deliveryEndTime && timeConvertToAmPm(restaurantDetail.restaurant.deliveryEndTime)}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormattedMessage id="Vendor.Details.EPayLbl" />:
                  </Col>
                  <Col>
                    {restaurantDetail.restaurant && restaurantDetail.restaurant.isEpaymentEnabled ? <FormattedMessage id="Button.Yes" /> : <FormattedMessage id="Button.No" />}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormattedMessage id="Restaurant.Details.ElectronicStatement" />:
                  </Col>
                  <Col>
                    {restaurantDetail.restaurant && restaurantDetail.restaurant.isEstatementEnabled ? <FormattedMessage id="Button.Yes" /> : <FormattedMessage id="Button.No" />}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormattedMessage id="Add.City" />:
                  </Col>
                  <Col>{restaurantDetail.restaurant && restaurantDetail.restaurant.city}</Col>
                </Row>
                <Row>
                  <Col>
                    <FormattedMessage id="Add.State" />:
                  </Col>
                  <Col>{restaurantDetail.restaurant && restaurantDetail.restaurant.state}</Col>
                </Row>
                <Row>
                  <Col>
                    <FormattedMessage id="Add.BusinessEmail" />:
                  </Col>
                  <Col>{restaurantDetail.restaurant && restaurantDetail.restaurant.email}</Col>
                </Row>
                <Row>
                  <Col>
                    <FormattedMessage id="Add.BusinessPhone" />:
                  </Col>
                  <Col>{restaurantDetail.restaurant && formatPhoneNumber(restaurantDetail.restaurant.phone)}</Col>
                </Row>
                <Row>
                  <Col>
                    <FormattedMessage id="Restaurant.Add.AdminFirstName" />:
                  </Col>
                  <Col>{restaurantDetail.restaurant && restaurantDetail.restaurant.adminFirstName}</Col>
                </Row>
                <Row>
                  <Col>
                    <FormattedMessage id="Restaurant.Add.AdminLastName" />:
                  </Col>
                  <Col>{restaurantDetail.restaurant && restaurantDetail.restaurant.adminLastName}</Col>
                </Row>
                <Row>
                  <Col>
                    <FormattedMessage id="Restaurant.Add.AdminEmail" />:
                  </Col>
                  <Col>{restaurantDetail.restaurant && restaurantDetail.restaurant.adminEmail}</Col>
                </Row>
                <Row>
                  <Col>
                    <FormattedMessage id="Restaurant.Add.AdminPhone" />:
                  </Col>
                  <Col>{restaurantDetail.restaurant && formatPhoneNumber(restaurantDetail.restaurant.adminPhone)}</Col>
                </Row>
              </div>
            </Col>
            {isLoading ? <GifLoader /> : null}
            <Col className="vendordetails">
              {/* <div className="content-nav-header d-flex justify-content-between align-items-center">
                <h5>
                  <FormattedMessage id="Vendor.Details.LocationInfoHD" />
                </h5>
                <Link to="/">
                  <FormattedMessage id="Vendor.Details.LinkEdit" />
                </Link>
              </div> */}
              {/* <div className="content-container-padding para-m-0">
                <Row className="align-items-center">
                  <Col xs="auto" className="icon-gray">
                    <LocationIcon />
                  </Col>
                  <Col className="p-0">
                    <p>New York Vendor Distribution Center 1</p>
                    <p className="text-small text-lightgray">Harlem, NY</p>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col xs="auto" className="icon-gray">
                    <LocationIcon />
                  </Col>
                  <Col className="p-0">
                    <p>New York Vendor Distribution Center 1</p>
                    <p className="text-small text-lightgray">Harlem, NY</p>
                  </Col>
                </Row>
              </div> */}
            </Col>
          </Col>
        </div>
      </div>
      {/* Enter support mode confirmation popup */}
      <ModalWindow
        modalShow={showModal}
        className="mx-w-550"
        size="lg"
        onHide={() => {
          setShowModal(false);
        }}
        title="SupportMode.Title"
        body="SupportMode.Body"
        onCancel={() => {
          setShowModal(false);
        }}
        onOkay={() => {
          loadSupportPage();
        }}
      />
    </div>
  );
};

export default RestaurantDetails;
