/**
 * @file   src\components\InputIcon.tsx
 * @brief  Html input component.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React from 'react';
import '../styles/Input.scss';
import { InputProperty } from '../interfaces/generalInterface';

interface InputIconInterface extends InputProperty {
  onChange?: (str: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputIcon = ({ onChange, id, name, type, value, placeholder, disabled, maxLength, icon, errorMessage }: InputIconInterface) => {
  const onIconChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e);
  };
  return (
    <div className="form-group">
      <div className="cust-ic-group position-relative">
        <input
          className={errorMessage && errorMessage !== '' ? 'form-control error' : value && value !== '' ? 'form-control active' : 'form-control'}
          id={id}
          name={name}
          type={type}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          maxLength={maxLength}
          onChange={(event) => onIconChange(event)}
        />
        <span className="icon-wrap">{icon}</span>
        {errorMessage && errorMessage !== '' && <span className="error">{errorMessage}</span>}
      </div>
    </div>
  );
};

export default InputIcon;
InputIcon.defaultProps = {
  onChange: null,
};
