/**
 * @file   src\validations\vendorSchema.ts
 * @brief  This file is responsible for defining vendor validation schemas.
 * @date   May, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import * as yup from 'yup';
import { useIntlActionMessages } from '../utils/helper';
import { EMAIL_REGEX, ZIP_REGEX, PHONE_NUMBER_REGEX } from '../utils/constants';

// Invite vendor form validation schema.
export const INVITE_VENDOR_SCHEMA = yup.object({
  name: yup.string().trim().max(200, useIntlActionMessages('Vendor.Name.MaxLength')).required(useIntlActionMessages('Vendor.Name.Required')),
  email: yup
    .string()
    .trim()
    .matches(EMAIL_REGEX, useIntlActionMessages('Form.Email.Valid'))
    .max(200, useIntlActionMessages('Form.Email.MaxLength'))
    .required(useIntlActionMessages('Email.Required')),
});
// Add/Edit vendor form validation schema.
export const VENDOR_ADD_EDIT_FORM_SCHEMA = yup.object({
  vendorName: yup.string().trim().required(useIntlActionMessages('Vendor.Form.Name.Required')),
  address: yup.string().trim().required(useIntlActionMessages('Vendor.Form.Address.Required')),
  zip: yup.string().trim().matches(ZIP_REGEX, useIntlActionMessages('Vendor.Form.Zip.Invalid')).required(useIntlActionMessages('Vendor.Form.Zip.Required')),
  city: yup.string().trim().required(useIntlActionMessages('Vendor.Form.City.Required')),
  state: yup.string().trim().required(useIntlActionMessages('Vendor.Form.State.Required')),
  businessEmail: yup
    .string()
    .trim()
    .matches(EMAIL_REGEX, useIntlActionMessages('Form.Email.Valid'))
    .max(200, useIntlActionMessages('Form.Email.MaxLength'))
    .required(useIntlActionMessages('Email.Required')),
  businessPhone: yup
    .string()
    .required(useIntlActionMessages('Vendor.Form.PhoneNumber.Required'))
    .matches(PHONE_NUMBER_REGEX, useIntlActionMessages('Vendor.Form.PhoneNumber.Invalid')),
  deliveryDays: yup.array().required(useIntlActionMessages('Vendor.Form.DeliveryDays.Required')).min(1, useIntlActionMessages('Vendor.Form.DeliveryDays.Required')),
  pricingTiers: yup.array().required(useIntlActionMessages('Vendor.Form.PricingTier.Required')),
  selectedTier: yup.number().required(useIntlActionMessages('Vendor.Form.PricingTier.Required')).min(1, useIntlActionMessages('Vendor.Form.PricingTier.Required')),
  firstName: yup.string().trim().required(useIntlActionMessages('Vendor.Form.FirstName.Required')),
  lastName: yup.string().trim().required(useIntlActionMessages('Vendor.Form.LastName.Required')),
  phone: yup.string().required(useIntlActionMessages('Vendor.Form.PhoneNumber.Required')).matches(PHONE_NUMBER_REGEX, useIntlActionMessages('Vendor.Form.PhoneNumber.Invalid')),
});
// Activate, deactivate vendor form validation schema.
export const VENDOR_ACTIVATE_DEACTIVATE_FORM_SCHEMA = yup.object({
  activateText: yup.string().trim().required(useIntlActionMessages('Form.Text.Required')),
});
