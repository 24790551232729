/**
 * @file   src\validations\authSchema.ts
 * @brief  This file is responsible for defining authentication validation schemas.
 * @date   May, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import * as yup from 'yup';
import { useIntlActionMessages } from '../utils/helper';
import { EMAIL_REGEX } from '../utils/constants';

// SignIn form validation schema.
export const SIGNIN_SCHEMA = yup.object({
  email: yup
    .string()
    .trim()
    .matches(EMAIL_REGEX, useIntlActionMessages('Form.Email.Valid'))
    .max(200, useIntlActionMessages('Form.Email.MaxLength'))
    .required(useIntlActionMessages('Form.Email.Required')),
  password: yup.string().trim().required(useIntlActionMessages('Form.Password.Required')),
});
// Send otp form validation schema.
export const SEND_OTP_SCHEMA = yup.object({
  email: yup
    .string()
    .trim()
    .matches(EMAIL_REGEX, useIntlActionMessages('Form.Email.Valid'))
    .max(200, useIntlActionMessages('Form.Email.MaxLength'))
    .required(useIntlActionMessages('Form.Email.Required')),
});
