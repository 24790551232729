/**
 * @file   src\assets\icons\Mail.tsx
 * @brief  Mail icon component.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

const MailIcon = () => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1Zm17 4.238-7.928 7.1L4 7.216V19h16V7.238ZM4.511 5l7.55 6.662L19.502 5H4.511Z" />
    </svg>
  );
};

export default MailIcon;
