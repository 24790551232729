/**
 * @file   src\features\subscription\Menu.tsx
 * @brief  Subscription side menu component
 * @date   July, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { NavLink, FormattedMessage } from '../../utils/thirdpartyComponents';

const Menu = () => {
  return (
    <div className="menu-wrap">
      <NavLink to="/subscription" className={({ isActive }) => (isActive ? 'current' : '')} end>
        <FormattedMessage id="Subscription.Charges" />
      </NavLink>
      <NavLink to="/subscription/incentives" className={({ isActive }) => (isActive ? 'current' : '')} end>
        <FormattedMessage id="Subscription.Similiaritems" />
      </NavLink>
    </div>
  );
};

export default Menu;
