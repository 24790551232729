/**
 * @file   src\features\subscription\OffCanvas.tsx
 * @brief  Subscription side panel component.
 * @date   Aug, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { Navbar, Offcanvas } from '../../utils/thirdpartyComponents';
import Menu from './Menu';

const OffcanvasMenu = () => {
  return (
    <Navbar expand={false}>
      <Navbar.Toggle aria-controls="offcanvasNavbar" />
      <Navbar.Offcanvas id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel" placement="start">
        <Offcanvas.Body>
          <Menu />
        </Offcanvas.Body>
      </Navbar.Offcanvas>
    </Navbar>
  );
};

export default OffcanvasMenu;
