/**
 * @file   src\store\slices\loaderSlice.ts
 * @brief  This file is responsible for creating slices for loader state management.
 * @date   May, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { createSlice } from '@reduxjs/toolkit';
import { ILoader } from '../../interfaces/generalInterface';

// Initial state.
const loaderState: ILoader = {
  previousGif: '',
};

// Config loader slice.
const loaderSlice = createSlice({
  name: 'loader',
  initialState: loaderState,
  reducers: {
    setPreviousGif: (state, action) => {
      state.previousGif = action.payload;
    },
  },
});
export const { setPreviousGif } = loaderSlice.actions;

// Export reducer.
export default loaderSlice.reducer;
