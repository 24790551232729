import { useNavigate, useParams, useEffect, useState, Button, Row, Col, NavDropdown, Modal, FormattedMessage, Link, Select, components } from '../../utils/thirdpartyComponents';
import '../../styles/Role.scss';
import { useIntlActionMessages, useIntlMessages } from '../../utils/helper';
import { useAppDispatch, useAppSelector } from '../../hooks';
import BackIcon from '../../assets/icons/Back';
import InputIcon from '../../components/InputIcon';
import InputCheck from '../../components/ChecBoxLabel';
import FirstName from '../../assets/icons/Profile';
import DropAdmin from '../../assets/DropAdminIcon.svg';
import DropCustom from '../../assets/CustomRole.svg';
import Onboarding from '../../assets/Onboarding.svg';
import CustomerSupport from '../../assets/CustomerSupport.svg';
import { RootState } from '../../store';
import { ISelectOptions } from '../../interfaces/generalInterface';
import { getPermissions, getNotifications, saveRole, getRoleById, deleteRole } from '../../store/actions/roleAction';
import { resetRole } from '../../store/slices/roleSlice';
import { INotificationList, IPermissionList, IRoleAddEditRequest, IRoleDetails, IRoleUsers } from '../../interfaces/roleInterface';
import { DefaultAdminRoles, MenuPermissions, RoleAccess, UserRoles } from '../../utils/enums';
import { validateForm } from '../../utils/formValidation';
import { USER_ROLE_SCHEMA } from '../../validations/userSchema';
import { MessageToaster } from '../../utils/toastUtil';
import GifLoader from '../../components/GifLoader';
import { INPUT_MAX_LENGTH_20 } from '../../utils/constants';

// Customise select field options.
const { Option } = components;
const IconOption = (props: any) => (
  <Option {...props}>
    {/* eslint-disable-next-line react/destructuring-assignment */}
    <img src={props.data.icon} alt={props.data.value} />
  </Option>
);

// Toast object creation.
const toast = new MessageToaster();
const NewRole = () => {
  // Create route param object to access the route parameters.
  const params = useParams();

  // Navigate object creation.
  const navigate = useNavigate();

  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Access redux state variables.
  const {
    isLoading,
    saveRoleSuccess,
    saveRoleErrorCode,
    saveRoleErrorMessage,
    isPermissionSuccess,
    permissions,
    permissionErrorCode,
    isNotificationSuccess,
    notifications,
    notificationErrorCode,
    isDetailsSuccess,
    isDetailsLoading,
    roleDetails,
    detailsErrorCode,
    detailsErrorMessage,
    deleteRoleLoading,
    deleteRoleSuccess,
    deleteRoleErrorCode,
    deleteRoleErrorMessage,
    users,
  } = useAppSelector((state: RootState) => state.role);

  // Initialize component state variables.
  const [role, createRole] = useState<boolean>(false);
  const [deleteUser, deleteUserPop] = useState<boolean>(false);
  const [deleteConfirm, deleteConfirmPop] = useState<boolean>(false);
  const [permissionList, setPermissionList] = useState<ISelectOptions[]>([]);
  const [notificationList, setNotificationList] = useState<ISelectOptions[]>([]);
  const [selectedPermissions, setSelectedPermissions] = useState<number[]>([]);
  const [selectedNotifications, setSelectedNotifications] = useState<number[]>([]);
  const [iconData, setIconData] = useState<any>([]);
  const [roleId, setRoleId] = useState<number>(0);
  const [roleName, setRoleName] = useState<string>('');
  const [iconText, setIconText] = useState<string>('');
  const [errorFields, setErrorFields] = useState<any>({});
  const [previewIcon, setPreviewIcon] = useState<string>(DropAdmin);
  const [deleteSuccess, deleteSuccessPop] = useState<boolean>(false);
  const [isDefaultRole, setDefaultRole] = useState<boolean>(false);

  // Clear Form field data.
  const clearForm = () => {
    setRoleId(0);
    setRoleName('');
    setDefaultRole(false);
    setIconData([]);
    setIconText('');
    setSelectedPermissions([]);
    setSelectedNotifications([]);
    createRole(false);
  };

  // Handle component side effects to call api's at page load.
  useEffect(() => {
    /* Access and set the location url parameters. */
    const rId: number = params.roleId ? Number(params.roleId) : 0;
    dispatch(getPermissions());
    dispatch(getNotifications());
    if (rId !== 0) {
      dispatch(getRoleById(rId));
    }
    return () => {
      clearForm();
      dispatch(resetRole());
    };
  }, [dispatch]);

  // Permissions checkbox configuration when permissions result state change..
  useEffect(() => {
    let permissionOptions: ISelectOptions[] = [];
    if (isPermissionSuccess && permissionErrorCode === 0 && permissions.length > 0) {
      permissionOptions = permissions.map(
        (obj: IPermissionList): ISelectOptions => ({
          label: obj.desciption,
          value: obj.permissionId,
        }),
      );
    }
    setPermissionList(permissionOptions);
  }, [isPermissionSuccess, permissionErrorCode]);

  // Notifications checkbox configuration when notification result state change..
  useEffect(() => {
    let notificationOptions: ISelectOptions[] = [];
    if (isNotificationSuccess && notificationErrorCode === 0 && notifications.length > 0) {
      notificationOptions = notifications.map(
        (obj: INotificationList): ISelectOptions => ({
          label: obj.notificationTypeName,
          value: obj.notificationTypeId,
        }),
      );
    }
    setNotificationList(notificationOptions);
  }, [isNotificationSuccess, notificationErrorCode]);

  // Handle save role response.
  useEffect(() => {
    if (saveRoleSuccess && saveRoleErrorCode === 0) {
      if (roleId > 0 && saveRoleErrorMessage) {
        toast.toastSuccess(saveRoleErrorMessage);
        navigate('/users/roles');
      } else {
        createRole(true);
      }
    } else if (saveRoleErrorCode > 0 && saveRoleErrorMessage) {
      toast.toastError(saveRoleErrorMessage);
    }
  }, [saveRoleSuccess, saveRoleErrorCode, saveRoleErrorMessage]);

  // Handle delete role response.
  useEffect(() => {
    if (deleteRoleSuccess && deleteRoleErrorCode === 0) {
      if (users.length > 0) {
        deleteConfirmPop(false);
        deleteUserPop(true);
      } else {
        deleteConfirmPop(false);
        deleteSuccessPop(true);
      }
    } else if (deleteRoleErrorCode > 0 && deleteRoleErrorMessage) {
      toast.toastError(deleteRoleErrorMessage);
    }
  }, [deleteRoleSuccess, deleteRoleErrorCode, deleteRoleErrorMessage]);

  // Get enum keys based on values.
  function getKeyByValue(value: string) {
    const indexOfS = Object.values(UserRoles).indexOf(value as unknown as UserRoles);
    const key = Object.keys(UserRoles)[indexOfS];
    return key.toLowerCase().trim();
  }

  // Set Role select options.
  const options = [
    { value: getKeyByValue(UserRoles.ADMIN), label: UserRoles.ADMIN.toString(), icon: DropAdmin },
    { value: getKeyByValue(UserRoles.ONBOARDING), label: UserRoles.ONBOARDING.toString(), icon: Onboarding },
    { value: getKeyByValue(UserRoles.CUSTOMER_SUPPORT), label: UserRoles.CUSTOMER_SUPPORT.toString(), icon: CustomerSupport },
    { value: getKeyByValue(UserRoles.CUSTOM_ROLE), label: UserRoles.CUSTOM_ROLE.toString(), icon: DropCustom },
  ];

  // Set role data for editing.
  const setRoleDetails = (response: IRoleDetails | null) => {
    if (response) {
      const selectedData = options.filter((opt: any) => opt.value === response.role?.iconFile?.trim());
      setRoleId(response.role?.roleId || 0);
      setRoleName(response.role?.roleName || '');
      setDefaultRole(response.role?.isDefault);
      setIconData(selectedData);
      setIconText(response.role?.iconFile || '');
      setPreviewIcon(selectedData[0]?.icon || '');
      setSelectedPermissions(response.permissions?.filter((data: any) => data.access === RoleAccess.ACCESS).map((obj: any) => obj.permissionId));
      setSelectedNotifications(response.notifications?.filter((data: any) => data.required).map((obj: any) => obj.notificationTypeId));
    }
  };

  // Role details response state change.
  useEffect(() => {
    if (isDetailsSuccess && detailsErrorCode === 0) {
      setRoleDetails(roleDetails);
    } else if (detailsErrorCode > 0 && detailsErrorMessage) {
      toast.toastError(detailsErrorMessage);
    }
  }, [isDetailsSuccess, detailsErrorCode, detailsErrorMessage, roleDetails]);

  // Permissions checkbox change event.
  const onPermissionCheckChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let newArray = [...selectedPermissions];
    if (selectedPermissions.includes(Number(value))) {
      const itemIndex = selectedPermissions.indexOf(Number(value));
      newArray = selectedPermissions.filter((_, i) => i !== itemIndex);
      /* Clear checkboxes under users and roles if unchecked. */
      if (Number(value) === MenuPermissions.USERS) {
        newArray = newArray.filter((newArr) => ![MenuPermissions.USER_CREATE, MenuPermissions.ROLES, MenuPermissions.ROLES_CREATE].includes(newArr));
      } else if (Number(value) === MenuPermissions.ROLES) {
        newArray = newArray.filter((newArr) => ![MenuPermissions.ROLES_CREATE].includes(newArr));
      }
      setSelectedPermissions(newArray);
    } else {
      newArray.push(Number(value));
      setSelectedPermissions(newArray);
    }
    const validateObj = {
      [name]: newArray,
    };
    const errorresult = await validateForm(validateObj, USER_ROLE_SCHEMA, errorFields);
    setErrorFields(errorresult);
  };

  // Notifications checkbox change event.
  const onNotificationCheckChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let newArray = [...selectedNotifications];
    if (selectedNotifications.includes(Number(value))) {
      const itemIndex = selectedNotifications.indexOf(Number(value));
      newArray = selectedNotifications.filter((_, i) => i !== itemIndex);
      setSelectedNotifications(newArray);
    } else {
      newArray.push(Number(value));
      setSelectedNotifications(newArray);
    }
    const validateObj = {
      [name]: newArray,
    };
    const errorresult = await validateForm(validateObj, USER_ROLE_SCHEMA, errorFields);
    setErrorFields(errorresult);
  };

  // Configure icon to the role select box Option.
  const formatOptionLabel = ({ icon }: any) => (
    <div className="selcted-role-icon">
      <img src={icon} alt="Role icon" />
    </div>
  );

  // Input field change event.
  const onInputHandleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setRoleName(value);
    const validateObj = {
      [name]: value?.trim() || '',
    };
    const errorresult = await validateForm(validateObj, USER_ROLE_SCHEMA, errorFields);
    setErrorFields(errorresult);
  };

  // Handle select icon change.
  const selectIconChange = async (selectedValue: any, fieldName: string) => {
    setIconData([selectedValue]);
    setIconText(selectedValue?.value);
    setPreviewIcon(selectedValue?.icon);
    const validateObj = {
      [fieldName]: selectedValue?.value,
    };
    const errorresult = await validateForm(validateObj, USER_ROLE_SCHEMA, errorFields);
    setErrorFields(errorresult);
  };

  // Submit handler to add new role.
  const onSubmit = async () => {
    const vendorValidationRequest = {
      roleName,
      iconText,
      selectedPermissions,
      selectedNotifications,
    };
    const errorresult = await validateForm(vendorValidationRequest, USER_ROLE_SCHEMA, errorFields);
    setErrorFields(errorresult);
    if (Object.keys(errorresult).length === 0) {
      const saveVendorRequest: IRoleAddEditRequest = {
        roleId,
        roleName: roleName?.trim() || '',
        iconFile: iconText,
        permissionIds: selectedPermissions,
        notificationTypeId: selectedNotifications,
      };
      dispatch(saveRole(saveVendorRequest));
    } else {
      toast.toastError(useIntlActionMessages('Enter.Mandatory'));
    }
  };

  // Check permission checkbox to be enabled/disabled for roles under users.
  const isPermissionDisabled = (permId: number) => {
    let isDisabled;
    switch (permId) {
      case MenuPermissions.USER_CREATE:
        isDisabled = selectedPermissions.includes(MenuPermissions.USERS);
        break;
      case MenuPermissions.ROLES:
        isDisabled = selectedPermissions.includes(MenuPermissions.USERS);
        break;
      case MenuPermissions.ROLES_CREATE:
        isDisabled = selectedPermissions.includes(MenuPermissions.USERS) && selectedPermissions.includes(MenuPermissions.ROLES);
        break;
      default:
        isDisabled = true;
        break;
    }
    return isDisabled;
  };

  return (
    <div className="content-nav contnt-container-margin">
      <div className="back-navigation">
        <Link to="/users/roles" className="back-btn">
          <BackIcon />
          <FormattedMessage id="Link.Back" />
        </Link>
      </div>

      <div className="content-nav-sub">
        <div className="content-nav-header d-flex justify-content-between align-items-center">
          <h2>{Object.keys(params).length > 0 ? <FormattedMessage id="HD.EditRole" /> : <FormattedMessage id="HD.NewRole" />}</h2>
        </div>
        <div className="content-container-padding">
          <p>
            <FormattedMessage id="Role.Fill" />
          </p>
          <Row>
            <Col xs={12} md={6} lg={5} xl={4}>
              <InputIcon
                id="roleName"
                name="roleName"
                type="text"
                value={roleName}
                placeholder={useIntlMessages('PH.RolaName')}
                icon={<FirstName />}
                maxLength={INPUT_MAX_LENGTH_20}
                {...(roleDetails?.role?.defaultRoleId !== DefaultAdminRoles.ADMIN && { onChange: onInputHandleChange })}
                disabled={roleDetails?.role?.defaultRoleId === DefaultAdminRoles.ADMIN}
                errorMessage={errorFields?.roleName}
              />
            </Col>
            <Col md="auto">
              <div className="select-icon-outer position-relative choose-icon-drop">
                <Select
                  id="iconText"
                  name="iconText"
                  options={options}
                  components={{ Option: IconOption }}
                  className="select-with-icon ps-15"
                  placeholder={useIntlMessages('PH.SelectIcon')}
                  classNamePrefix="select_icon"
                  isSearchable={false}
                  formatOptionLabel={formatOptionLabel}
                  value={iconData}
                  isDisabled={roleDetails?.role?.defaultRoleId === DefaultAdminRoles.ADMIN}
                  {...(roleDetails?.role?.defaultRoleId !== DefaultAdminRoles.ADMIN && { onChange: (values) => selectIconChange(values, 'iconText') })}
                />
                <span className="error"> {errorFields?.iconText || ''}</span>
              </div>
            </Col>
          </Row>
          <p className="mb-3 mt-mx-md-top">
            <FormattedMessage id="Role.Looklike" />
          </p>
          <p className="mb-0">
            <FormattedMessage id="Role.PreviewText" />
          </p>
          <div className="d-inline-flex align-items-center role-demo p-3 border">
            <img src={previewIcon} className="me-3" alt="Role icon" />
            <div>{roleName}</div>
          </div>
        </div>
        <NavDropdown.Divider />
        <div className="content-container-padding">
          <h4 className="text-dark">
            <FormattedMessage id="HD.SetupPermission" />
          </h4>
          <Row className="permission-settings mt-4">
            {permissionList.map((perm: ISelectOptions) => (
              <Col xl="auto" lg={4} md={6} className="permission-options-events" key={perm.value}>
                <InputCheck
                  key={`permission_${perm.label}`}
                  id={`permission_${perm.label}`}
                  name="selectedPermissions"
                  label={perm.label.toString()}
                  value={perm.value}
                  {...(isPermissionDisabled(perm.value) && { onChange: onPermissionCheckChange })}
                  checked={selectedPermissions.includes(Number(perm.value))}
                  {...(!isPermissionDisabled(perm.value) && { disabled: true })}
                />
              </Col>
            ))}
            <span className="error"> {errorFields?.selectedPermissions || ''}</span>
          </Row>
          <h4 className="text-dark">
            <FormattedMessage id="HD.EventNotified" />
          </h4>
          <Row className="permission-settings mb-4 mt-4">
            {notificationList.map((notification: ISelectOptions) => (
              <Col xl={3} lg={5} md={6} key={notification.value}>
                <InputCheck
                  key={`notification_${notification.label}`}
                  id={`notification_${notification.label}`}
                  name="selectedNotifications"
                  label={notification.label.toString()}
                  value={notification.value}
                  onChange={onNotificationCheckChange}
                  checked={selectedNotifications.includes(Number(notification.value))}
                />
              </Col>
            ))}
            <span className="error"> {errorFields?.selectedNotifications || ''}</span>
          </Row>
          <Row className="align-items-center">
            <Col xs="auto">
              <Button variant="primary" onClick={onSubmit}>
                <FormattedMessage id="Button.Confirm" />
              </Button>
            </Col>
            {roleId > 0 && !isDefaultRole && (
              <Col xs="auto" className="p-xs-0">
                <Link to="#" onClick={() => deleteConfirmPop(true)} className="red-link">
                  <FormattedMessage id="Link.DeleteRole" />
                </Link>
              </Col>
            )}
          </Row>
          {(isLoading || isDetailsLoading || deleteRoleLoading) && <GifLoader />}
          {/* Create Role Popup */}
          <Modal show={role} onHide={() => createRole(false)} size="sm" centered>
            <Modal.Body>
              <p className="text-center">
                <FormattedMessage id="Role.Text.Created" />
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" className="w-100" onClick={() => navigate('/users/roles')}>
                <FormattedMessage id="Button.OK" />
              </Button>
            </Modal.Footer>
          </Modal>
          {/* User delete popup */}
          {users.length > 0 && (
            <Modal show={deleteUser} onHide={() => deleteUserPop(false)} centered size="lg">
              <Modal.Header>
                <Modal.Title id="example-modal-sizes-title-sm">
                  <FormattedMessage id="HD.DeleteRole" />
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p className="mb-3">
                  <FormattedMessage id="Role.DeleteText" />
                </p>
                <div className="mb-3">
                  {users.map((usr: IRoleUsers) => (
                    <span className="icon-blue me-5">
                      <FirstName />
                      {`${usr.firstName} ${usr.lastName}`}
                    </span>
                  ))}
                </div>
                <p>
                  <FormattedMessage id="Role.TextReassign" />
                </p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" className="w-25" onClick={() => deleteUserPop(false)}>
                  <FormattedMessage id="Button.OK" />
                </Button>
              </Modal.Footer>
            </Modal>
          )}
          {/* Role delete sucess popup */}
          <Modal show={deleteSuccess} onHide={() => deleteSuccessPop(false)} size="sm" centered>
            <Modal.Body>
              <p className="text-center">
                <FormattedMessage id="Role.TextDeleted" />
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={() => navigate('/users/roles')}>
                <FormattedMessage id="Button.OK" />
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Role delete confirm popup */}
          <Modal show={deleteConfirm} onHide={() => deleteConfirmPop(false)} size="lg" centered>
            <Modal.Header>
              <Modal.Title id="example-modal-sizes-title-sm">
                <FormattedMessage id="HD.DeleteRole" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className="mb-3">
                <FormattedMessage id="Role.Delete.Confirm.Text" />
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="" onClick={() => deleteConfirmPop(false)}>
                <FormattedMessage id="Button.Role.Delete.Cancel" />
              </Button>
              <Button variant="primary" onClick={() => dispatch(deleteRole(roleId))}>
                <FormattedMessage id="Button.Role.Delete.Ok" />
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default NewRole;
