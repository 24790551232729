/**
 * @file   src\store\slices\commonSlice.ts
 * @brief  This file is responsible for managing common state data as slice.
 * @date   May, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { createSlice } from '@reduxjs/toolkit';
import { getPlans, getUserSubscriptions } from '../actions/commonAction';
import { ICommonReducerState } from '../../interfaces/generalInterface';
import { useIntlActionMessages } from '../../utils/helper';

// Initial state.
const commonState: ICommonReducerState = {
  plans: [],
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
  subscriptions: [],
  totalSubscriptions: 0,
  isSubscriptionLoading: false,
  isSubscriptionSuccess: false,
  subscriptionErrorCode: 0,
  subscriptionErrorMessage: null,
};

// Config common slice.
const commonSlice = createSlice({
  name: 'common',
  initialState: commonState,
  reducers: {
    resetCommonState: (state) => {
      state.isSubscriptionLoading = false;
      state.isSubscriptionSuccess = false;
      state.subscriptionErrorCode = 0;
      state.subscriptionErrorMessage = null;
    },
  },
  extraReducers(builder) {
    // Start getPlans request.
    builder.addCase(getPlans.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.errorCode = 0;
      state.errorMessage = '';
    });
    // Get plans request success.
    builder.addCase(getPlans.fulfilled, (state, action) => {
      const data = action.payload?.data?.plans || [];
      const error = action.payload?.error || 0;
      state.plans = data;
      state.isLoading = false;
      state.isSuccess = true;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // Get plans user error.
    builder.addCase(getPlans.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.isSuccess = false;
      state.isLoading = false;
      state.errorCode = error;
      state.errorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // Start user subscriptions request.
    builder.addCase(getUserSubscriptions.pending, (state) => {
      state.isSubscriptionSuccess = false;
      state.isSubscriptionLoading = true;
      state.subscriptionErrorCode = 0;
      state.subscriptionErrorMessage = '';
    });
    // User subscriptions request success.
    builder.addCase(getUserSubscriptions.fulfilled, (state, action) => {
      const data = action.payload?.data || null;
      const error = action.payload?.error || 0;
      state.subscriptions = data?.payments || [];
      state.totalSubscriptions = data?.totalCount;
      state.isSubscriptionLoading = false;
      state.isSubscriptionSuccess = true;
      state.subscriptionErrorCode = error;
      state.subscriptionErrorMessage = useIntlActionMessages(error !== 0 ? error.toString() : '');
    });
    // User subscriptions error.
    builder.addCase(getUserSubscriptions.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.isSubscriptionSuccess = false;
      state.isSubscriptionLoading = false;
      state.subscriptionErrorCode = error;
      state.subscriptionErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
  },
});

// Export common actions.
export const { resetCommonState } = commonSlice.actions;

// Export reducer.
export default commonSlice.reducer;
