import React from 'react';
import InputMask from 'react-input-mask';
import '../styles/InputPhone.scss';
import { InputProperty } from '../interfaces/generalInterface';

interface InputPhoneMask extends InputProperty {
  onChange: (str: React.ChangeEvent<HTMLInputElement>) => void;
  mask: string;
}

const InputMaskLabel = ({ onChange, type, label, isMandatory, icon, errorMessage, id, name, value, mask, placeholder, disabled }: InputPhoneMask) => {
  const onInputMaskChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e);
  };

  return (
    <div className="form-group">
      <div className={type === 'phone' ? 'cust-phone-group' : 'ziplabel'}>
        <label htmlFor="numericdiv">
          {label}
          {isMandatory && <span className="mandatory">*</span>}
        </label>
        <div className="phone-wrap position-relative cust-ic-group" id="numericdiv">
          {type === 'phone' && (
            <div className="country-code position-absolute d-flex justify-content-center align-items-center">
              {' '}
              <span>{icon}</span>+1
            </div>
          )}
          <InputMask
            className={errorMessage && errorMessage !== '' ? 'form-control error' : value && value !== '' ? 'form-control active' : 'form-control'}
            id={id}
            name={name}
            type={type}
            value={value}
            mask={mask}
            maskChar=""
            placeholder={placeholder}
            disabled={disabled}
            onChange={onInputMaskChange}
          />

          {errorMessage && errorMessage !== '' && <span className="error">{errorMessage}</span>}
        </div>
      </div>
    </div>
  );
};

export default InputMaskLabel;
