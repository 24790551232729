import React from 'react';

const VMIcon = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.67 18.5V12.97C5.94 13.12 7.22 12.65 8.11 11.72C8.88 12.54 9.96 13 11.08 13C12.21 13 13.28 12.53 14.06 11.73C14.95 12.66 16.22 13.11 17.5 12.98C17.5 12.98 19.11 12.47 19.33 12.31C21.16 11.08 21.71 8.64 20.58 6.76L18.11 2.47C17.94 2.17 17.64 2 17.31 2H4.86C4.54 2 4.23 2.17 4.07 2.46L1.58 6.76C1.21 7.38 1 8.11 1 8.88C1 10.26 1.69 11.54 2.83 12.31V18.51H1.92V20.34H20.25V18.5H4.67ZM3.16 7.69L5.39 3.83H16.78L19 7.67C19.06 7.77 19.11 7.88 19.16 7.99C19.64 9.16 19.08 10.5 17.9 10.98C16.72 11.46 15.39 10.89 14.91 9.72C14.6 8.96 13.52 8.96 13.21 9.72C12.98 10.31 12.51 10.77 11.94 11C10.76 11.47 9.42 10.9 8.96 9.72C8.65 8.96 7.57 8.96 7.26 9.72C6.67 10.7 5.47 11.1 4.43 10.67C3.26 10.2 2.69 8.87 3.16 7.69Z"
        fill="#63707E"
      />
      <path
        d="M17.9632 13.0551C18.3211 12.9816 18.6886 12.9816 19.0367 13.0551C19.0851 13.3585 19.2785 13.6158 19.5493 13.7721C19.8298 13.9283 20.1586 13.9467 20.4584 13.8364C20.7002 14.0938 20.8839 14.3971 21 14.7188C20.7485 14.9118 20.6035 15.1967 20.6035 15.5C20.6035 15.8125 20.7582 16.0974 21 16.2812C20.8839 16.6121 20.7002 16.9062 20.4584 17.1636C20.1586 17.0533 19.8298 17.0809 19.5493 17.2279C19.2689 17.3842 19.0851 17.6415 19.0367 17.9449C18.6789 18.0184 18.3114 18.0184 17.9632 17.9449C17.9149 17.6415 17.7215 17.3842 17.4507 17.2279C17.1702 17.0717 16.8414 17.0533 16.5416 17.1636C16.2998 16.9062 16.1161 16.6029 16 16.2812C16.2515 16.0882 16.3965 15.8033 16.3965 15.5C16.3965 15.1967 16.2515 14.9118 16 14.7188C16.1161 14.3879 16.2998 14.0938 16.5416 13.8364C16.8414 13.9467 17.1702 13.9191 17.4507 13.7721C17.7311 13.625 17.9149 13.3585 17.9632 13.0551ZM18.5048 16.2537C18.94 16.2537 19.2979 15.9136 19.2979 15.5C19.2979 15.0864 18.94 14.7463 18.5048 14.7463C18.0696 14.7463 17.7118 15.0864 17.7118 15.5C17.7118 15.9228 18.0696 16.2537 18.5048 16.2537Z"
        fill="#63707E"
      />
    </svg>
  );
};

export default VMIcon;
