/**
 * @file   src\features\permission\RestaurantRoles.tsx
 * @brief  Restaurant roles listing page
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { BusinessType } from '../../utils/enums';
import Roles from './Roles';

const RestaurantRoles = () => {
  return <Roles businessType={BusinessType.RESTAURANT} />;
};
export default RestaurantRoles;
