/**
 * @file   src\store\actions\restaurantAction.ts
 * @brief  This file is responsible for creating asynchronous restaurant api call.
 * @date   May, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../config/axiosConfig';
import {
  IRestaurantRequest,
  IRestaurantInviteRequest,
  IRestaurantListMerge,
  IMappedRestaurantsListRequest,
  IRestaurantTransactionRequest,
} from '../../interfaces/restaurantInterface';
import { RestaurantApi } from '../../utils/apiUrls';

// List all restaurant api call.
export const restaurantList = createAsyncThunk('/restaurant/restaurantList', async (request: IRestaurantRequest, { rejectWithValue }) => {
  try {
    let param = '';
    param += request.searchText ? `searchText=${encodeURIComponent(request.searchText.replace(/\\/g, '\\\\').replace(/_/g, '\\_').replace(/%/g, '\\%'))}&` : '';
    param += `restaurantId=${request.restaurantId}&`;
    param += `subscriptionPlanID=${request.subscriptionPlanId}&`;
    param += `showDeactivatedOnly=${request.showDeactivateOnly}&`;
    param += `pageSize=${request.pageSize}&`;
    param += `pageOffset=${request.pageIndex}&`;
    param += `sortField=${request.sortField}&`;
    param += `sortOrder=${request.sortOrder}`;
    const { data } = await axios.get(`${RestaurantApi.RESTAURANT_LIST}?${param}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});
// Invite restaurant api call.
export const inviteRestaurants = createAsyncThunk('/restaurant/inviteRestaurants', async (request: IRestaurantInviteRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(RestaurantApi.RESTAURANT_INVITE, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});
// Search restaurants api call.
export const searchRestaurants = createAsyncThunk('/restaurant/searchRestaurants', async (request: IRestaurantRequest, { rejectWithValue }) => {
  try {
    let param = '';
    param += request.searchText ? `searchText=${request.searchText}&` : '';
    param += `restaurantId=${request.restaurantId}&`;
    param += `subscriptionPlanID=${request.subscriptionPlanId}&`;
    param += `showDeactivatedOnly=${request.showDeactivateOnly}&`;
    param += `pageSize=${request.pageSize}&`;
    param += `pageOffset=${request.pageIndex}&`;
    param += `sortField=${request.sortField}&`;
    param += `sortOrder=${request.sortOrder}`;
    const { data } = await axios.get(`${RestaurantApi.RESTAURANT_LIST}?${param}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Get restaurant details api call.
export const getRestaurantDetails = createAsyncThunk('/restaurant/restaurantdetail', async (restaurantId: number, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${RestaurantApi.RESTAURANT_DETAILS}/${restaurantId}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Add restaurant api call.
export const saveRestaurant = createAsyncThunk('/restaurant/saveRestaurant', async (saveRequest: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(RestaurantApi.RESTAURANT_ADD, saveRequest);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Restaurant activate / deactivate api call
export const restaurantActivateDeactivate = createAsyncThunk('/restaurant/restaurantStatusUpdate', async (activateRequest: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(RestaurantApi.RESTAURANT_ACTIVATE_DEACTIVATE, activateRequest);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Restaurant list api call for merge restaurants
export const getRestaurantListForMerge = createAsyncThunk('/restaurant/restaurantlistmerge', async (request: IRestaurantListMerge, { rejectWithValue }) => {
  try {
    let param = '?';
    param += request.searchText ? `searchText=${request.searchText}&` : '';
    param += `restaurantId=${request.restaurantId}&`;
    param += `subscriptionPlanID=${request.subscriptionPlanId}&`;
    param += `pageSize=${request.pageSize}&`;
    param += `pageOffset=${request.pageOffset}&`;
    param += `sortField=${request.sortField}&`;
    param += `sortOrder=${request.sortOrder}`;
    const { data } = await axios.get(`${RestaurantApi.RESTAURANT_LIST_MERGE}${param}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Merge restaurants api call
export const mergeRestaurants = createAsyncThunk('/restaurant/mergeRestaurants', async (mergeRequest: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(RestaurantApi.RESTAURANT_MERGE, mergeRequest);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Get restaurant users by restaurantId api call.
export const getRestaurantUsers = createAsyncThunk('/restaurant/getRestaurantUsers', async (restaurantId: number, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${RestaurantApi.RESTAURANT_USERS}/${restaurantId}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Get mapped restaurants api call.
export const getMappedRestaurants = createAsyncThunk('/restaurant/getMappedRestaurants', async (request: IMappedRestaurantsListRequest, { rejectWithValue }) => {
  try {
    let param = '?';
    param += request.searchText ? `searchText=${request.searchText}&` : '';
    param += `pageOffset=${request.pageSize}`;
    const { data } = await axios.get(`${RestaurantApi.RESTAURANT_MAPPED}${param}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});
// Get status of merged restaurants api call
export const getMergeRestaurantStatus = createAsyncThunk('/restaurant/getMergeRestaurantStatus', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(RestaurantApi.RESTAURANT_MERGE_STATUS);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Clear merge status api call
export const clearMergeStatus = createAsyncThunk('/restaurant/clearMergeStatus', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(RestaurantApi.CLEAR_MERGE_STATUS, {});
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Get merged restaurants using restaurant id api call.
export const getMergedRestaurants = createAsyncThunk('/restaurant/getMergedRestaurants', async (restaurantId: number, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${RestaurantApi.MERGED_RESTAURANTS}/${restaurantId}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// get token for cobrowsing api call
export const getCobrowseToken = createAsyncThunk('/restaurant/getCobrowseToken', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(RestaurantApi.GET_COBROWSE_TOKEN);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// List all restaurant transactions api call.
export const getRestaurantTransactions = createAsyncThunk('/restaurant/getVendorTransactions', async (request: IRestaurantTransactionRequest, { rejectWithValue }) => {
  try {
    let param = '';
    param += `?vendorId=${request.vendorId}&`;
    param += `fromDate=${request.startDate}&`;
    param += `toDate=${request.endDate}&`;
    param += `pageSize=${request.pageSize}&`;
    param += `pageIndex=${request.pageIndex}&`;
    param += `sortField=${request.sortField}&`;
    param += `sortOrder=${request.sortOrder}`;
    const { data } = await axios.get(`${RestaurantApi.RESTAURANT_TRANSACTIONS_LIST}?${param}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});
