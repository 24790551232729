/**
 * @file   src\store\actions\rolepermissionAction.ts
 * @brief  This file is responsible for creating asynchronous role permission based api call.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../config/axiosConfig';
import { RolePermissionsApi } from '../../utils/apiUrls';
import { IRolePermissionSaveRequest, IRolePermissionsRequest } from '../../interfaces/rolePermissionInterface';

// Get all roles for vendor/restaurants api call.
export const getBusinessRoles = createAsyncThunk('/role/getBusinessRoles', async (businessType: number, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${RolePermissionsApi.ROLES_LIST}/${businessType}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Get all role permissions for vendor/restaurants api call.
export const getRoleBasedPermissions = createAsyncThunk('/role/getRoleBasedPermissions', async (request: IRolePermissionsRequest, { rejectWithValue }) => {
  try {
    let param = '';
    param += `roleId=${request.roleId}&`;
    param += `businessType=${request.businessType}`;
    const { data } = await axios.get(`${RolePermissionsApi.ROLES_PERMISSIONS}?${param}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Save vendor/restaurants permissions api call.
export const savePermissions = createAsyncThunk('/role/savePermissions', async (request: IRolePermissionSaveRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(RolePermissionsApi.SAVE_PERMISSIONS, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Delete vendor/restaurants roles and permissions api call.
export const deleteRolePermissions = createAsyncThunk('/role/deleteRolePermissions', async (roleId: number, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${RolePermissionsApi.REMOVE_PERMISSIONS}/${roleId}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});
