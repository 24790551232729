/**
 * @file   Source\MadChef-Web\src\components\CropImage.tsx
 * @author ZCO Engineer
 * @date   Dec, 2023
 * @brief  Image cropping component.
 *
 */

import { useState, useCallback, useEffect, Cropper, Area, Button, FormattedMessage } from '../utils/thirdpartyComponents';
import { getCroppedImg } from '../utils/helper';
import { ICropProps } from '../interfaces/generalInterface';

const CropImage = ({ image, handlerCancel, setCroppedImage }: ICropProps) => {
  const [crop, setCrop] = useState<any>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [file, setFile] = useState<any>('');
  const [croppedPixels, setCroppedAreaPixels] = useState<any>(null);

  // Crop image completion handler.
  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  // Crop image handler.
  const handleCropImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(file, croppedPixels);
      setCroppedImage(croppedImage);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }, [file, croppedPixels, setCroppedImage]);

  // Image props side effect.
  useEffect(() => {
    const convertFileToBase64 = () => {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = () => setFile(reader.result);
    };
    convertFileToBase64();
  }, [image]);

  return (
    <div>
      <div style={{ position: 'relative', width: '100%', height: '220px', background: '#f4f4f4' }}>
        <Cropper
          image={file}
          crop={crop}
          zoom={zoom}
          aspect={4 / 3}
          cropSize={{ width: 200, height: 200 }}
          cropShape="round"
          objectFit="vertical-cover"
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          mediaProps={{ width: '100%', height: 'auto' }}
        />
      </div>
      <div className="text-center mt-3">
        <Button onClick={handlerCancel} variant="secondary" size="sm">
          <FormattedMessage id="Profile.Image.Crop.Confirm.Cancel" />
        </Button>
        <Button onClick={handleCropImage} variant="primary" color="primary" size="sm" className="ms-3">
          <FormattedMessage id="Profile.Image.Crop.Confirm.Label" />
        </Button>
      </div>
    </div>
  );
};

export default CropImage;
