/**
 * @file   src\features\dashboard\VendorSearch.tsx
 * @brief  Provides Vendor Search Component With Autocomplete
 * @date   August, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { useEffect, useState, useRef, createRef, Typeahead } from '../../utils/thirdpartyComponents';
import { RootState } from '../../store';
import { PAGE_SIZE, ENTER_KEY, SEARCH_MIN_CHAR_LENGTH, INPUT_MAX_LENGTH_100 } from '../../utils/constants';
import { useAppDispatch, useAppSelector } from '../../hooks';
import Search from '../../assets/icons/Search';
import { useIntlMessages } from '../../utils/helper';
import { searchVendors, getNewVendorsList } from '../../store/actions/dashboardActions';
import { IVendorRestaurantSearchRequest } from '../../interfaces/dashboardInterface';
import { VendorRestaurantSearchType } from '../../utils/enums';

const pageItemCount: number = process.env.REACT_APP_LIST_PAGE_SIZE !== '' ? Number(process.env.REACT_APP_LIST_PAGE_SIZE) : PAGE_SIZE;
// Vendor search autocomplete component
const VendorSearch = ({ setVendorId, searchType }: any) => {
  // Create action dispatch
  const dispatch = useAppDispatch();
  // Accessing redux state variables
  const { vendorSearchData, vendorSearchList } = useAppSelector((state: RootState) => state.dashboard);
  // Initialize search field ref.
  const oldSearchText = useRef<string>('');

  // Create a ref on search input.
  const inputRef = createRef<any>();
  // Component state variables
  const [searchField, setSearchField] = useState<string>('');
  const [typeField, setTypeField] = useState<string>('');
  const [selectedVendorId, setSelectedVendorId] = useState<number>(0);
  // search for vendors
  useEffect(() => {
    if (searchField.length > 2 && selectedVendorId === 0 && (searchType === VendorRestaurantSearchType.ORDER || searchType === VendorRestaurantSearchType.TRANSACTIONS)) {
      const vendorSearchRequest: IVendorRestaurantSearchRequest = {
        type: searchType,
        searchText: searchField,
      };
      dispatch(searchVendors(vendorSearchRequest));
    } else if (searchField.length >= SEARCH_MIN_CHAR_LENGTH && selectedVendorId === 0 && searchType === VendorRestaurantSearchType.VENDORS) {
      const params = {
        vendorId: 0,
        searchText: searchField,
        fromDate: '',
        toDate: '',
        pageSize: pageItemCount,
        pageIndex: 1,
        sortField: 0,
        sortOrder: 1,
      };
      dispatch(getNewVendorsList(params));
    }
  }, [searchField]);

  // Vendor search input keydown event.
  const onVendorHandleKeyDown = (event: any) => {
    if (event.key === ENTER_KEY) {
      if (inputRef.current) inputRef.current.blur();
      setSearchField(searchField.trim());
    }
  };

  // Vendor textbox selected change event.
  const onVendorHandleChange = (value: any) => {
    if (value[0]) {
      oldSearchText.current = value[0].vendorName;
      setSelectedVendorId(value[0].vendorId);
      setVendorId(value[0].vendorId);
      setSearchField(value[0].vendorName);
      if (inputRef.current) inputRef.current.blur();
    }
  };

  // Vendor textbox search input change event.
  const onVendorInputHandleChange = (value: any) => {
    if (value.trim().length >= SEARCH_MIN_CHAR_LENGTH) {
      setSearchField(value);
    } else if (value.trim().length === 0) {
      setSearchField('');
      setVendorId(0);
    }
    oldSearchText.current = value;
    setTypeField(value);
    setSelectedVendorId(0);
  };

  return (
    <Typeahead
      id="vendorSearch"
      ref={inputRef}
      onInputChange={onVendorInputHandleChange}
      onChange={onVendorHandleChange}
      onKeyDown={onVendorHandleKeyDown}
      options={searchType === VendorRestaurantSearchType.VENDORS ? vendorSearchList : vendorSearchData}
      placeholder={useIntlMessages('Vendor.SearchVendor.Placeholder')}
      selected={
        searchType === VendorRestaurantSearchType.VENDORS
          ? vendorSearchList.filter((x: any) => x.vendorId === selectedVendorId)
          : vendorSearchData.filter((x: any) => x.vendorId === selectedVendorId)
      }
      filterBy={['vendorName']}
      minLength={oldSearchText.current.length > typeField.length ? oldSearchText.current.length : SEARCH_MIN_CHAR_LENGTH}
      labelKey={(vendor: any) => `${vendor.vendorName}`}
      renderMenuItemChildren={(vendor: any) => <div>{vendor.vendorName}</div>}
      inputProps={{
        maxLength: INPUT_MAX_LENGTH_100,
      }}
    >
      <Search />
    </Typeahead>
  );
};

export default VendorSearch;
